<template>
  <el-container class="d-flex select-card">
    <input type="checkbox" :name="id" :value="id" :checked="isChecked" @change="toggleSelect" />
  </el-container>
</template>
<style scoped>
.select-card {
  align-items: flex-start;
  gap: 10px;
}
</style>
<script>
export default {
  name: 'select-area',
  props: {
    id: { type: String, default: '' },
    isChecked: { type: Boolean, default: false },
  },
  methods: {
    toggleSelect() {
      this.$emit('toggle-checked', this.id)
    },
  },
}
</script>
