<template>
  <el-dialog
    custom-class="small-dialog"
    append-to-body
    id="youtube-upload-dialog"
    title="YouTubeURLはこちら"
    :before-close="cancel"
    :visible.sync="isShow"
    width="50%"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="pt-3 pb-1 text-center">
      <el-input
        class="w-90"
        type="textarea"
        :rows="7"
        :value="textarea"
        placeholder="YouTubeURLはこちら"
        v-model="textarea"
      >
      </el-input>

      <div v-if="error" class="error">{{ error }}</div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="validateUrl"> 確定 </el-button>

      <el-button @click="cancel"> 閉じる </el-button>
    </span>
  </el-dialog>
</template>

<style scoped>
.error {
  margin-left: 5%;
  margin-top: 10px;
  text-align: left;
  color: #f56c6c;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pt-3 {
  padding-top: 2rem;
}

.text-center {
  text-align: center;
}

.w-90 {
  width: 90%;
}
</style>

<script>
import _ from 'lodash'

export default {
  name: 'youtube-upload-dialog',
  data: () => ({ isShow: false, loading: false, textarea: '', error: '', index: 0 }),
  created() {
    window.UploadYoutubeDialog = this
  },
  methods: {
    show(index, textarea) {
      this.index = index
      this.textarea = textarea
      this.isShow = true
    },
    cancel() {
      this.error = ''
      this.isShow = false
    },
    validateUrl() {
      this.error = ''
      const urls = this.textarea !== '' && this.textarea !== undefined ? this.textarea.trim().split(/\r?\n+/) : []
      const youtubeUrls = urls.map(url => {
        const metadata = this.getYoutubeMetadata(url)
        if (!metadata) {
          this.error = 'Youtubeの動画のURLにならなければなりません。ご確認ください。'
          return
        }
        return _.assign({}, metadata)
      })
      if (this.checkUniqueId(youtubeUrls)) {
        this.$emit('updateYoutubeData', youtubeUrls, this.index, this.textarea)
        this.error = ''
        this.isShow = false
      } else {
        this.error = 'アップしようとするYouTubeURLが重複しています。'
      }
    },
    getYoutubeId(url) {
      const regExp = /(?:youtube\.com(?:\/watch\?v=|\/embed\/|\/shorts\/)|youtu\.be\/)([^#&?/]{11})/
      const match = url.match(regExp)
      return match ? match[1] : false
    },
    checkUniqueId(youtubeData) {
      const uniqueValues = new Set(youtubeData.map(v => v.youtubeId))
      return !(uniqueValues.size < youtubeData.length)
    },
    getYoutubeMetadata(url) {
      const youtubeId = this.getYoutubeId(url)
      if (!youtubeId) {
        return false
      }
      const rawUrl = url
      const formatedUrl = 'https://www.youtube.com/watch?v=' + youtubeId
      const embedded = 'https://www.youtube.com/embed/' + youtubeId
      return {
        name: formatedUrl,
        youtubeId: youtubeId,
        url: embedded,
        rawUrl: rawUrl,
      }
    },
  },
}
</script>
