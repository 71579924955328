import Vue from 'vue'
import App from '@/app.vue'
import router from '@/router'
import GoogleAuth from '@/plugins/googleAuth'
import Api from '@/plugins/api'
import VueClipboard from 'vue-clipboard2'
import Cognito from '@/plugins/cognito'
import S3 from '@/plugins/s3'
import AWS from 'aws-sdk'
import VuePapaParse from 'vue-papa-parse'

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ja'
import 'element-ui/lib/theme-chalk/index.css'
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import ToggleButton from 'vue-js-toggle-button'
import { Fragment } from 'vue-frag'
import './global.css'

AWS.config.region = 'ap-northeast-1'
Vue.config.productionTip = false
// google auth của khách dùng cho cognito
const googleClientId = '889751336572-r56n83nphbh22re7gcrphkef0onenjvj.apps.googleusercontent.com'

new Promise(resolve => {
  const script = document.createElement('script')
  script.src = 'https://accounts.google.com/gsi/client'
  script.onreadystatechange = script.onload = () =>
    !script.readyState || /loaded|complete/.test(script.readyState) ? setTimeout(resolve, 100) : ''
  document.getElementsByTagName('head')[0].appendChild(script)
})
  .then(async () => {
    return await window.google.accounts.oauth2.initTokenClient({
      client_id: googleClientId,
      scope: 'email profile',
      callback: '',
    })
  })
  .then(googleAuth => {
    Vue.use(Api)
    Vue.use(GoogleAuth, googleAuth)
    Vue.use(Cognito, { AWS })
    Vue.use(S3, { AWS })
    Vue.use(ElementUI, { locale })
    Vue.use(ToggleButton)
    Vue.use(VueClipboard)
    Vue.use(VuePapaParse)
    Vue.use(BootstrapVue)
    Vue.component('Fragment', Fragment)
    new Vue({ router, render: h => h(App) }).$mount('#app')
  })
