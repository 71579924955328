<template>
  <div id="drawer" class="ml-2 mr-2">
    <el-row type="flex" class="ps-1">
      <el-col :span="6">
        <el-row>
          <div class="title font-14">紐付けキー</div>
          <el-input
            v-model="inputProjectName"
            @keyup.enter.native="handleProjectNameConfirm"
            @blur="handleProjectNameConfirm"
            size="mini"
          ></el-input>
        </el-row>

        <el-row>
          <el-tag
            v-for="(tag, i) in projectNames"
            :key="tag"
            size="mini"
            @close="removeProjectNameTag(i)"
            closable
            class="tooltip"
          >
            {{ tag.length > 20 ? tag.substring(0, 20) + '...' : tag }}
            <span v-if="tag.length > 20" class="tooltiptext">{{ tag }}</span>
          </el-tag>
        </el-row>

        <el-row>
          <div class="title font-14">監修ステータス</div>
          <el-checkbox-group v-model="checkedApprovalStatus">
            <el-checkbox v-for="status in approvalStatus" class="w-50" :label="status" :key="status">{{
              status
            }}</el-checkbox>
          </el-checkbox-group>
        </el-row>
      </el-col>
      <el-col :span="7">
        <el-row>
          <div class="title font-14 d-flex justify-content-between">
            ファイル名
            <el-checkbox
              v-model="isExactFilename"
              label="完全一致で検索する"
              class="ml-35"
              style="margin-right: 44px"
            />
          </div>
          <el-col>
            <el-row type="flex">
              <el-input
                type="textarea"
                :rows="1"
                v-model="inputFileName"
                class="font-med h-28 r-border no-resize"
                resize="none"
                @keyup.native="handleDownSpace($event)"
                @blur="handleFileNameConfirm"
                size="mini"
              ></el-input>
              <el-upload
                class="w-10"
                action="#"
                multiple
                :show-file-list="false"
                :auto-upload="false"
                :on-change="onNewFile"
              >
                <el-button
                  type="primary"
                  class="l-border"
                  icon="el-icon-upload"
                  size="mini"
                  style="max-width: 44px"
                ></el-button>
              </el-upload>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <el-tag
            v-for="(tag, i) in fileNames"
            :key="tag"
            size="mini"
            @close="removeFileNameTag(i)"
            class="tooltip"
            closable
          >
            {{ tag.length > 20 ? tag.substring(0, 20) + '...' : tag }}
            <span v-if="tag.length > 20" class="tooltiptext">{{ tag }}</span>
          </el-tag>
        </el-row>

        <el-row>
          <div class="title font-14">拡張子</div>

          <el-select
            v-model="selectedExtensions"
            class="w-100 font-14"
            multiple
            clearable
            filterable
            size="mini"
            placeholder="検索"
          >
            <el-option class="font-14-option" v-for="(r, i) in extensions" :key="i" :label="r" :value="r"> </el-option>
          </el-select>
        </el-row>
      </el-col>
      <el-col :span="10" style="flex: 1">
        <el-row>
          <div class="title font-14">タグ</div>
        </el-row>
        <el-row v-for="(r, i) in tagsTemp" :key="`tags_${i}`" type="flex" align="middle">
          <tag-selection
            :tags="tags"
            :tag-type-id="r.tagTypeId"
            :tag-value="r.tagValue"
            :target-type="r.targetType"
            @remove-tag="removeTag(i)"
            @change-tag-type="id => changeTagInfo(i, id, 'tagTypeId')"
            @change-tag-value="id => changeTagInfo(i, id, 'tagValue')"
          />
        </el-row>

        <el-row type="flex" justify="center" v-if="tagsTemp.length < 5">
          <el-button type="plain" size="mini" @click="addTag">OR</el-button>
        </el-row>
      </el-col>
    </el-row>

    <el-row type="flex" justify="end" class="footer">
      <el-col :span="18"></el-col>
      <el-col :span="7">
        <el-row type="flex" justify="end" class="mr-1">
          <el-button size="medium" type="warning" @click="setLastForm">前回の検索条件を再利用</el-button>
          <el-button size="medium" @click="reset">すべてクリア</el-button>
          <el-button size="medium" type="primary" @click="search">適用</el-button>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style>
#select-bar .el-divider--horizontal {
  margin: 0;
  display: flex;
}
.font-14,
.font-14-option {
  font-size: 14px;
}
.el-radio__label,
.el-tag,
.el-checkbox__label,
.el-textarea__inner,
.el-input__inner,
.el-select-dropdown__empty,
.el-select-dropdown__item {
  font-size: 13px !important ;
}
.font-13,
.font-13-option {
  font-size: 13px;
}

.title .el-checkbox .el-checkbox__label {
  padding-left: 4px;
}
</style>

<style scoped>
.el-col + .el-col {
  margin-left: 20px;
}
.text-black {
  color: #000000;
}

#drawer {
  background-color: #e0e0e0;
  /* padding: 0px 5px 20px; */
  padding-bottom: 10px;
}

.r-border {
  border-radius: 4px 0px 0px 4px !important;
}

.l-border {
  border-radius: 0px 4px 4px 0px !important;
}

.mr-1 {
  margin-right: 10px;
}

.ml-1 {
  margin-left: 10px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 20px;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 10%;
  border-width: 5px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: gray;
  color: #fff;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.ml-2 {
  margin-left: 20px;
}

.ml-35 {
  margin-left: 35px;
}

.ps-1 {
  padding: 0px 20px;
  flex-wrap: wrap;
  background-color: #e0e0e0;
}

.checkbox {
  width: 50%;
  margin-right: 0px;
}
.title {
  font-weight: bold;
  padding: 10px 10px 5px 0px;
  width: 100%;
  color: rgb(44, 62, 80);
  font-family: Arial, Helvetica, sans-serif;
}

.title .el-checkbox .el-checkbox__label {
  padding-left: 4px;
}

.w-100 {
  width: 100%;
}

.w-121 {
  width: 121%;
}

.w-50 {
  width: 50%;
  margin-right: 0px;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.w-32 {
  width: 32%;
}
.w-34 {
  width: 34%;
}

.footer {
  margin-top: 10px;
}
.bg-blue {
  background: #409eff;
  color: #ffffff;
}
.font-big {
  font-size: 20px;
}
.text-right {
  text-align: right;
  float: top;
}
.display-flex {
  height: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 83.67%;
  height: auto;
}

/* !!!IMPORTANT Xoá 2 css này trước khi lên official */

.el-divider {
  background-color: #000000;
  position: relative;
}

.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 7px 0;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  position: absolute;
  left: 5px;
  font-family: element-icons;
  content: '\e6da';
  font-size: 12px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<script>
import _ from 'lodash'
import TagSelection from '@/views/image-movie-master/components/tag-selection.vue'
import store from 'store'
import imageExtensions from '@/config/imageExtensions'
import videoExtensions from '@/config/videoExtensions'
import musicExtensions from '@/config/musicExtensions'
import util from '@/mixins/util'
export default {
  name: 'drawer-asset',
  components: { TagSelection },
  mixins: [util],
  props: {
    tags: { type: Array, default: () => [] },
    selectedPromotionIds: { type: Array, default: () => [] },
  },
  async created() {
    const promotionId = []
    promotionId.push(this.$route.query.promotionIds)
    window.ImageMovieMasterDrawer = this
    const option = { method: 'POST', body: { promotionIds: promotionId.flat(), targetType: 'asset' } }
    const { approvalRequests } = await this.$api.authFetch('/approval_request/list', option)
    this.approvalRequests = approvalRequests
    this.extensions = imageExtensions.concat(videoExtensions).concat(musicExtensions)
  },
  data: () => ({
    displayTypes: [
      { label: 'デフォルト', value: 'default' },
      { label: '顧客承認', value: 'approvalRequest' },
      { label: '制作用ファイル', value: 'psdURL' },
    ],
    submissionStatus: ['未入稿', '入稿依頼済', '入稿済'],
    checkedSubmissionStatus: [],
    // todo: change to common value
    approvalStatus: ['監修未提出', '承認', '監修中', '破棄', '要修正'],
    checkedApprovalStatus: [],
    tagsTemp: [{ tagTypeId: '', tagValue: '', targetType: '' }],
    fileNames: [],
    projectNames: [],
    inputFileName: '',
    isExactFilename: true,
    inputProjectName: '',
    submissionRequests: [],
    selectedSubmissionRequests: [],
    approvalRequests: [],
    selectedApprovalRequests: [],
    q: '',
    extensions: [],
    selectedExtensions: [],
    lastTimeForm: store.get('imageMovieMasterForm'),
  }),

  methods: {
    addTag() {
      this.tagsTemp.push({ tagTypeId: '', tagValue: '', targetType: '' })
    },
    removeTag(index) {
      this.tagsTemp = _.chain(this.tagsTemp)
        .cloneDeep()
        .filter((r, i) => i !== index)
        .map(r => _.assign({}, r))
        .value()

      if (this.tagsTemp.length === 0) this.addTag()
    },
    getItemsLength(key) {
      if (this.form[key].length === 0) return ''
      else return [`select ${this.form[key].length} items`]
    },
    handleFileNameConfirm() {
      if (this.inputFileName !== '') {
        const filtered = _.chain(this.fileNames)
          .concat(util.methods.handleTextAreaInput(this.inputFileName))
          .uniq()
          .value()
        this.fileNames = filtered
        this.inputFileName = ''
      }
    },
    handleProjectNameConfirm() {
      if (this.inputProjectName !== '') {
        this.projectNames.push(this.inputProjectName)
        this.inputProjectName = ''
      }
    },
    removeFileNameTag(index) {
      this.fileNames.splice(index, 1)
    },
    removeProjectNameTag(index) {
      this.projectNames.splice(index, 1)
    },
    changeTagInfo(index, value, key) {
      this.tagsTemp = _.map(this.tagsTemp, (r, i) => {
        if (i !== index) return _.assign({}, r)
        else {
          return _.assign({}, r, { [key]: value })
        }
      })
    },
    getFormParams() {
      const getFormParams = {
        projectNames: this.projectNames,
        submissionRequestIds: this.selectedSubmissionRequests,
        approvalRequestIds: this.selectedApprovalRequests,
        extensions: this.selectedExtensions,
        fileNames: this.fileNames,
        isExactFilename: this.isExactFilename,
        tags: this.officialTag,
        approvalStatus: this.checkedApprovalStatus,
        submissionStatus: this.checkedSubmissionStatus,
      }
      return getFormParams
    },
    search() {
      const getFormParams = this.getFormParams()

      store.set('imageMovieMasterForm', getFormParams)
      this.lastTimeForm = _.cloneDeep(getFormParams)

      const query = _.assign(
        {},
        _.omit(this.$route.query, ['originIds', 'textSetIds', 'creativeIds', 'creativeDraftId']),
        { imageMovieMasterForm: JSON.stringify(getFormParams) }
      )
      this.$router.push({ query })

      this.$emit('get-assets', getFormParams)
    },
    reset() {
      this.fileNames = []
      this.inputFileName = null
      this.isExactFilename = false
      this.projectNames = []
      this.inputProjectName = null
      this.selectedApprovalRequests = []
      this.selectedExtensions = []
      this.selectedSubmissionRequests = []
      this.tagsTemp = [{ tagTypeId: '', tagValue: '', targetType: null }]
      this.checkedApprovalStatus = []
      this.checkedSubmissionStatus = null
    },
    setLastForm() {
      if (this.lastTimeForm !== null) {
        this.projectNames = this.lastTimeForm.projectNames
        this.selectedApprovalRequests = this.lastTimeForm.approvalRequestIds
        this.selectedExtensions = this.lastTimeForm.extensions
        this.fileNames = this.lastTimeForm.fileNames
        this.isExactFilename = this.lastTimeForm.isExactFilename
        this.tagsTemp =
          this.lastTimeForm.tags.length === 0
            ? [{ tagTypeId: '', tagValue: '', targetType: null }]
            : this.lastTimeForm.tags
        this.checkedApprovalStatus = this.lastTimeForm.approvalStatus
      }
    },
    onNewFile({ name }) {
      const filtered = _.chain(this.fileNames).push(name).uniq().value()
      this.fileNames = filtered
    },
    handleDownSpace(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault()
        this.handleFileNameConfirm()
      }
    },
  },
  computed: {
    officialTag() {
      return this.tagsTemp.filter(o => o.tagTypeId != '' && o.tagValue != '')
    },
    disableButtonSearch() {
      const form = {
        projectNames: this.projectNames,
        submissionRequestIds: this.selectedSubmissionRequests,
        approvalRequestIds: this.selectedApprovalRequests,
        extensions: this.selectedExtensions,
        fileNames: this.fileNames,
        tags: this.tagsTemp.filter(o => o.tagTypeId != '' && o.tagValue != ''),
        approvalStatus: this.checkedApprovalStatus,
        submissionStatus: this.checkedSubmissionStatus,
      }
      const isEmpty = Object.values(form).findIndex(formData => formData && !_.isEmpty(formData)) === -1
      return isEmpty
    },
  },
}
</script>
