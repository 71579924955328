<template>
  <section class="page-center h-100" v-loading="loading">
    <div class="page-center-in">
      <div class="logo">
        <el-image class="login-img" :src="require('@/assets/ct_icon_1080x1080.png')" />
        <div class="login-text">Creative Tracking</div>
      </div>
      <form class="sign-box">
        <div class="sign-avatar">
          <el-image class="sign-avatar" :src="require('@/assets/avatar-sign.png')" />
        </div>
        <a href @click.prevent>
          <el-image :src="require('@/assets/btn-gg-signin.png')" @click="onSignIn(false)" />
        </a>
        <div>
          <p class="white-text text-center" @click="onSignIn(true)">アカウント切り替え</p>
        </div>
      </form>
    </div>
  </section>
</template>

<style scoped>
.h-100 {
  height: 100%;
}
.sign-box {
  background-color: rgba(15, 75, 87, 0.5);
  text-align: center;
  width: 100%;
  width: 350px;
  height: 300px;
  margin: 0 auto;
  border: 10px solid #ffffff;
  padding: 20px;
  font-size: 1rem;
}
.white-text {
  color: #ffffff;
  cursor: pointer;
  text-decoration: underline;
}
.logo {
  text-align: center;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  font-size: 1rem;
}
.logo .login-img {
  width: 75px;
  height: 75px;
  border-radius: 10px;
}

.logo .login-text {
  margin: 20px;
  color: #ffffff;
  font-size: 50px;
  border-radius: 10px;
}

.sign-avatar {
  width: 115px;
  height: 100px;
  margin: 0 auto 30px;
}
.center {
  margin: 0 auto;
}
.sign-title {
  font-size: 1.25rem;
  text-align: center;
  margin: 0 0 15px;
  line-height: normal;
}
.page-center-in {
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  display: table-cell;
  vertical-align: middle;
  padding: 15px 0;
}
.page-center {
  background-image: url('https://tech-asset-storage-prod.s3.ap-northeast-1.amazonaws.com/frontend-asset/login_bg.jpg');
  background-size: cover;
  display: table;
  width: 100vw;
  height: 100vh;
}
.text-center {
  text-align: center;
}
.blue {
  color: #409eff;
}
</style>

<script>
import _ from 'lodash'

export default {
  name: 'login',
  data: () => ({ height: window.innerHeight, loading: false }),
  created() {
    window.Login = this
  },
  methods: {
    async onSignIn(isSelect) {
      try {
        this.loading = true
        const isSignedIn = await this.$gAuth.signIn(isSelect)
        this.loading = false

        if (isSignedIn) {
          this.$message.success('ログイン成功')
          if (await this.$gAuth.isSub()) {
            if (window.history.state) {
              this.$router.back()
            } else {
              this.$router.push({
                path: '/text-master',
                query: _.omit(this.$route.query, ['redirect']),
              })
            }
          } else {
            this.$router.push({
              path: this.$route.query.redirect || '/',
              query: _.omit(this.$route.query, ['redirect']),
            })
          }
        }
      } catch (e) {
        console.error(e)
        this.loading = false
        this.$message.error('もう一度ログインしてください')
      }
    },
  },
}
</script>
