<template>
  <el-dialog
    id="regist-text-set-dialog"
    title="テキスト作成"
    fullscreen
    lock-scroll
    :visible.sync="isShow"
    :close-on-press-escape="false"
  >
    <el-tabs type="border-card" v-loading="loading">
      <el-tab-pane label="テキスト作成">
        <el-form class="scroll" :style="{ height: height + 'px' }">
          <el-row class="m-1">
            <el-card shadow="never">
              <el-row class="p-1 vertical-center">
                <el-form id="project-name-form" class="w-100" label-width="100px" label-position="left">
                  <el-form-item label="紐付けキー">
                    <el-input
                      class="w-100"
                      v-model="form.projectName"
                      placeholder="制作依頼に基づいてテキスト作成する場合はAXIS依頼名を入力してください"
                      clearable
                      size="small"
                    />
                  </el-form-item>
                </el-form>
              </el-row>
            </el-card>
          </el-row>

          <el-row class="m-1">
            <span>タグ一括設定</span>

            <span class="ml-1">
              <el-button type="text" @click="setTag('odd-AI')"> odd-AI </el-button>

              <el-button type="text" @click="setNoTag(null)"> 該当なし </el-button>
            </span>
          </el-row>

          <el-row class="m-1" v-for="(textSet, i) in formTextSets" :key="`textSet_${i}`">
            <el-card shadow="never">
              <el-col :span="20" class="p-1 border-r-1">
                <el-row class="vertical-center mb-1">
                  <el-select
                    :value="textSet.adFormat.platformId"
                    placeholder="媒体を選択してください"
                    clearable
                    filterable
                    size="small"
                    class="mr-1"
                    :disabled="!!(textSet.adFormat.platformId && textSet.adFormat.id)"
                    @input="id => changePlatform(id, i)"
                  >
                    <el-option v-for="(r, i) in platforms" :key="i" :label="r.platformName" :value="r.platformId" />
                  </el-select>

                  <el-tooltip
                    v-if="textSet.adFormat.name && countAdFormatName(textSet.adFormat.name)"
                    class="item"
                    effect="dark"
                    :content="textSet.adFormat.name"
                    placement="top-start"
                  >
                    <el-select
                      :value="textSet.adFormat.name"
                      placeholder="広告フォーマットを選択してください"
                      clearable
                      filterable
                      size="small"
                      class="mr-1"
                      id="el-select-ad-format-id"
                      :disabled="
                        textSet.adFormat.platformId && textSet.adFormat.id ? true : !textSet.adFormat.platformId
                      "
                      @input="id => changeAdFormat(id, i)"
                    >
                      <el-option
                        v-for="(r, i) in filterAdFormats(textSet.adFormat.platformId)"
                        :key="i"
                        :label="
                          (textSet.adFormat.platformId === 'GAW' && r.name === '拡張テキスト広告(ETA)'
                            ? '【Odd-AI対応】'
                            : '') + r.name
                        "
                        :value="r.id"
                      />
                    </el-select>
                  </el-tooltip>
                  <el-select
                    v-else
                    :value="textSet.adFormat.name"
                    placeholder="広告フォーマットを選択してください"
                    clearable
                    filterable
                    size="small"
                    class="mr-1"
                    id="el-select-ad-format-id"
                    :disabled="textSet.adFormat.platformId && textSet.adFormat.id ? true : !textSet.adFormat.platformId"
                    @input="id => changeAdFormat(id, i)"
                  >
                    <el-option
                      v-for="(r, i) in filterAdFormats(textSet.adFormat.platformId)"
                      :key="i"
                      :label="
                        (textSet.adFormat.platformId === 'GAW' && r.name === '拡張テキスト広告(ETA)'
                          ? '【Odd-AI対応】'
                          : '') + r.name
                      "
                      :value="r.id"
                    />
                  </el-select>

                  <el-button
                    class="mr-1"
                    size="small"
                    type="info"
                    :disabled="!textSet.adFormat.id"
                    @click="copyText(textSet)"
                  >
                    テキストコピー
                  </el-button>

                  <el-tag v-if="textSet.score" type="warning" class="font-bold mr-1">
                    {{ formatScore(textSet.score) }}
                  </el-tag>

                  <div v-if="textSet.comment">
                    {{ textSet.comment }}
                  </div>
                </el-row>

                <el-row v-if="formTextSets[i].errors">
                  <el-row v-for="(error, i) in textSet.errors" :key="i" class="mb-1 text-red lh-0">
                    {{ error }}
                  </el-row>
                </el-row>

                <el-row v-if="!textSet.labeledTexts || textSet.labeledTexts.length === 0">
                  広告フォーマットを選択してください
                </el-row>

                <el-row v-else>
                  <el-form-item
                    class="float-left w-33"
                    v-for="(labeledText, j) in textSet.labeledTexts"
                    :key="`labeledText_${i}_${j}`"
                  >
                    <el-row>
                      <div class="float-left mr-2">
                        {{ labeledText.name }}
                        <el-tag v-if="labeledText.minLength && labeledText.minLength !== 0" class="required-tag">
                          <span> 必須 </span>
                        </el-tag>

                        <el-tag v-else class="option-tag">
                          <span> 任意 </span>
                        </el-tag>
                      </div>

                      <span class="float-left" @click="addLabeledText(i, j)">
                        <i class="fas fa-plus-circle" />
                      </span>
                    </el-row>

                    <el-row v-if="labeledText.errors.length !== 0">
                      <el-row v-for="(error, i) in labeledText.errors" :key="i" class="mb-1 text-red lh-0">
                        {{ error }}
                      </el-row>
                    </el-row>

                    <div v-if="labeledText.targetErrors.length !== 0" class="mb-1 pr-1">
                      <el-row
                        class="text-red lh-0 mb-10"
                        v-show="
                          !labeledText.targetErrors.every(e => !e.includes('商材NGワードとして設定されています。'))
                        "
                      >
                        NGワードに変更がある場合は<a :href="getLink()" target="_blank">こちら</a
                        >からご確認お願いいたします。
                      </el-row>
                      <el-row v-for="(error, l) in labeledText.targetErrors" :key="l" class="text-red lh-0">
                        {{
                          error.includes('商材NGワードとして設定されています。')
                            ? error.substring(0, error.indexOf('\\n'))
                            : error
                        }}
                        <el-tooltip
                          placement="bottom"
                          v-show="error.includes('商材NGワードとして設定されています。')"
                          effect="dark"
                        >
                          <i class="el-icon-warning icon-ng" />
                          <div slot="content" class="ff-1" :style="{ whiteSpace: 'pre-line', width: '100%' }">
                            {{ error.substring(error.indexOf('\\n') + 2, error.length).replaceAll('\\n', '\n') }}
                          </div>
                        </el-tooltip>
                      </el-row>
                    </div>

                    <div
                      id="text-area"
                      class="pr-1"
                      v-for="(v, k) in labeledText.textValues"
                      :key="`labeledText_${i}_${j}_${k}`"
                    >
                      <el-col class="mb-1" :span="19">
                        <el-row>
                          <div class="float-left w-100">
                            <div v-if="labeledText.ctaPhrases == null">
                              <el-input
                                v-model="labeledText.textValues[k]"
                                show-word-limit
                                type="textarea"
                                class="float-left"
                                :class="{
                                  'border-red':
                                    require('lodash').compact(labeledText.regulationErrors[k]).length !== 0 &&
                                    require('lodash').compact(labeledText.unavailableCharsErrors[k]).length !== 0,
                                }"
                                @input="
                                  v => {
                                    labeledText.textValues[k] = remove_white_and_ctrl_character(v)
                                    labeledText.regulationErrors[k] = checkCharacter(
                                      remove_white_and_ctrl_character(v),
                                      labeledText
                                    )
                                    labeledText.unavailableCharsErrors[k] = checkUnavailalbleChar(
                                      remove_white_and_ctrl_character(v),
                                      labeledText
                                    )
                                    labeledText.crossRuleErrors[k] = checkContinuousTextMaximumLimit(
                                      v,
                                      textSet.adFormat.crossRule,
                                      labeledText
                                    )
                                    textSet.errors = setTextSetError(
                                      textSet,
                                      checkC(textSet.adFormat.crossRule, textSet.labeledTexts)
                                    )
                                  }
                                "
                              />
                            </div>
                            <div v-else>
                              <el-select
                                v-model="labeledText.textValues[k]"
                                style="width: 100%"
                                clearable
                                placeholder="ご選択ください。"
                              >
                                <el-option
                                  v-for="item in labeledText.ctaPhrases"
                                  :key="item"
                                  :label="item"
                                  :value="item"
                                >
                                </el-option>
                              </el-select>
                            </div>
                          </div>

                          <span
                            v-if="
                              labeledText.textValues.length > labeledText.minLength && labeledText.textValues.length > 1
                            "
                            class="badge lh-0"
                            @click="
                              v => {
                                removeLabeledText(i, j, k)
                                formTextSets[i].errors = setTextSetError(
                                  formTextSets[i],
                                  checkC(formTextSets[i].adFormat.crossRule, formTextSets[i].labeledTexts)
                                )
                              }
                            "
                          >
                            <i class="fas fa-times-circle icon-red" />
                          </span>
                        </el-row>

                        <el-row v-if="labeledText.targetErrors.length === 0" class="mt-1">
                          <el-row v-if="labeledText.regulationErrors[k].includes('overLength')" class="text-red lh-2">
                            <span>文字数がオーバーしています。</span>
                            <span v-if="labeledText.containParameter"
                              >広告カスタマイザを使用している場合はそのまま保存ボタンを押してください。</span
                            >
                          </el-row>

                          <el-row
                            v-if="
                              labeledText.regulationErrors[k] && labeledText.regulationErrors[k].includes('underLength')
                            "
                            class="text-red lh-2"
                          >
                            <span>文字数は{{ labeledText.minCharLength }}文字以上でなければいけません。</span>
                            <span v-if="labeledText.containParameter"
                              >広告カスタマイザを使用している場合はそのまま保存ボタンを押してください。</span
                            >
                          </el-row>

                          <el-row v-if="labeledText.regulationErrors[k].includes('lineBreak')" class="text-red lh-2">
                            改行は禁止です
                          </el-row>

                          <el-row v-if="labeledText.regulationErrors[k].includes('emoji')" class="text-red lh-2">
                            絵文字は禁止です
                          </el-row>

                          <el-row v-if="labeledText.regulationErrors[k].includes('kana')" class="text-red lh-2">
                            半角カナは禁止です
                          </el-row>

                          <el-row v-if="labeledText.regulationErrors[k].includes('fullWidth')" class="text-red lh-2">
                            全角文字は禁止です
                          </el-row>
                          <el-row v-if="labeledText.unavailableCharsErrors[k].length !== 0" class="text-red lh-2">
                            「{{
                              labeledText.unavailableCharsErrors[k].join(', ')
                            }}」は使用不可文字として登録されています。
                          </el-row>
                        </el-row>
                      </el-col>

                      <el-col :span="5">
                        <div
                          v-if="labeledText.ctaPhrases == null"
                          class="lh-1 text-right"
                          :class="
                            labeledText.regulationErrors[k].includes('overLength') ||
                            labeledText.regulationErrors[k].includes('underLength')
                              ? 'text-red'
                              : 'text-gray'
                          "
                        >
                          <span>{{ countStrLength(labeledText.textValues[k], labeledText) }}</span>
                          <span>
                            /
                            {{ labeledText.maxCharLength ? labeledText.maxCharLength : 'なし' }}</span
                          >
                        </div>
                      </el-col>
                    </div>
                  </el-form-item>
                </el-row>
              </el-col>

              <el-col v-if="textSet.adFormat.platformId && textSet.adFormat.id" :span="4" class="p-1 text-center">
                <el-checkbox-group v-model="textSet.tags" @change="cancelNoTag(i)">
                  <el-checkbox-button label="odd-AI" class="w-100"> odd-AI </el-checkbox-button>
                </el-checkbox-group>

                <el-checkbox class="mt-2" v-model="textSet.noTags" @change="cancelTag(i)"> 該当なし </el-checkbox>
              </el-col>
            </el-card>

            <div v-show="formTextSets.length !== 1" class="flex">
              <span @click="removeTextSet(i)">
                <i class="badge icon-red font-middle fas fa-times-circle" />
              </span>
            </div>
          </el-row>

          <el-row class="text-center font-big">
            <span @click="addTextSet">
              <i class="fas fa-plus-circle" />
            </span>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <span slot="footer" class="dialog-footer">
      <div class="vertical-center flex-right">
        <el-button
          type="primary"
          @click="regist"
          :disabled="loading || isDisplayRegulationError || isIncludesEmptyTextValues(formTextSets)"
        >
          保存
        </el-button>
      </div>
    </span>
  </el-dialog>
</template>

<style>
#text-area .el-form-item__content {
  line-height: initial;
}
#text-area .border-red .el-textarea__inner {
  border: 0;
}
#odd-ai-form .el-form-item {
  margin-bottom: 0;
}
#odd-ai-form .el-form-item__content {
  width: 100%;
}
#odd-ai-form .el-form-item__label {
  line-height: initial;
  padding: 0;
}
#project-name-form .el-form-item {
  margin-bottom: 0;
}
#regist-text-set-dialog .el-card__body {
  padding: 0px;
}
#regist-text-set-dialog .el-card:not(:last-of-type) {
  margin-bottom: 0.5rem;
}
#regist-text-set-dialog .el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 4px !important;
}
</style>

<style scoped>
.display-flex {
  height: 40px;
  display: flex;
  align-items: center;
}
.border-red {
  border: 1px solid #f56c6c;
  border-radius: 4px;
  box-sizing: border-box;
}
.text-red {
  color: #f56c6c;
}
.text-gray {
  color: #909399;
}
.vertical-center {
  display: flex;
  align-items: center;
}
.flex-right {
  display: flex;
  justify-content: flex-end;
}
.flex {
  display: flex;
}
.badge {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 2;
}
.font-middle {
  font-size: 18px;
}
.font-big {
  font-size: 22px;
}
.ff-1 {
  font-family: Arial, Helvetica, sans-serif;
}
.mb-10 {
  margin-bottom: 10px;
}
.border-r-1 {
  border-right: solid 1px #ebeef5;
}
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
.icon-red {
  color: #f56c6c;
}
.lh-0 {
  line-height: initial;
}
.lh-1 {
  line-height: 3.5rem;
}
.lh-2 {
  line-height: 1.5rem;
}
.m-1 {
  margin: 1rem;
}
.mt-1 {
  margin-top: 0.5rem;
}
.ml-1 {
  margin-left: 0.5rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.mt-2 {
  margin-top: 1rem;
}
.mr-2 {
  margin-right: 1rem;
}
.p-1 {
  padding: 1rem;
}
.pt-1:first-child {
  padding-top: 1rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 5px;
}
.pl-1 {
  padding-left: 1rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 10px;
}
.float-left {
  float: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.w-0 {
  width: 0;
}
.w-33 {
  width: 33%;
}
.w-100 {
  width: 100%;
}
.icon-ng {
  color: #909399;
  font-size: 18px;
}
.font-bold {
  font-weight: bold;
}

.required-tag {
  align-items: center;
  justify-content: center;
  height: 17px;
  width: 40px;
  background-color: #f56c6c;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  padding-left: 9px;
  -webkit-clip-path: polygon(15px 0%, 100% 0%, 100% 100%, 15px 100%, 0 50%);
  clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0 50%);
  line-height: 17px;
}

.option-tag {
  align-items: center;
  justify-content: center;
  height: 17px;
  width: 40px;
  background-color: #00d7bf;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  padding-left: 9px;
  -webkit-clip-path: polygon(15px 0%, 100% 0%, 100% 100%, 15px 100%, 0 50%);
  clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0 50%);
  line-height: 17px;
}
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'

export default {
  name: 'regist-text-set-dialog',
  mixins: [util],
  props: {
    defaultPromotionId: { type: String, default: () => '' },
    adFormats: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
  },
  inject: {
    isInCreateCreativeDialog: {
      from: 'isInCreateCreativeDialog',
      default: false,
    },
  },
  data: () => {
    const numberRule = { type: 'number', message: '数字のみ有効です', trigger: 'change' }
    const rules = { adAccountId: [numberRule], adGroupId: [numberRule] }
    const form = { adAccountId: null, adGroupId: null, projectName: null }
    return {
      isShow: false,
      isCreative: false,
      loading: false,
      adFormat: {},
      formTextSets: [],
      rules,
      form,
      height: window.innerHeight - 34 - 21 - 70,
    }
  },
  created() {
    window.RegistTextSetDialog = this
    window.addEventListener('resize', () => {
      this.height = window.innerHeight - 34 - 50 - 70
    })
  },
  methods: {
    log(mess, data) {
      console.log(mess, data)
    },
    getLink() {
      return `${this.$api.getRegulationDomain()}/advertisers`
    },
    show(adFormat, isCreative) {
      this.adFormat = adFormat
      this.isCreative = isCreative
      this.form = { adAccountId: null, adGroupId: null, projectName: null }
      this.formTextSets = [
        {
          adFormat: this.adFormat || { platformId: '', id: '' },
          labeledTexts: this.adFormat ? this.searchLabeledTexts(this.adFormat.id) : [],
          score: null,
          comment: null,
          errors: [],
          targetErrors: [],
          tags: [],
          noTags: false,
        },
      ]
      this.isShow = true
    },
    cancelTag(index) {
      this.formTextSets = _.map(this.formTextSets, (r, i) => (i === index ? _.assign({}, r, { tags: [] }) : r))
    },
    cancelNoTag(index) {
      this.formTextSets = _.map(this.formTextSets, (r, i) => (i === index ? _.assign({}, r, { noTags: false }) : r))
    },
    setTag(tag) {
      this.formTextSets = _.map(this.formTextSets, r => _.assign({}, r, { tags: [tag], noTags: false }))
    },
    setNoTag() {
      this.formTextSets = _.map(this.formTextSets, r => _.assign({}, r, { tags: [], noTags: true }))
    },
    copyText(textSet) {
      const isEmpty = _.chain(textSet.labeledTexts).map('textValues').flattenDeep().compact().isEmpty().value()

      if (isEmpty) this.$message.warning('入力文字がないのでコピーできません')
      else this.formTextSets.push(_.cloneDeep(textSet))
    },
    addTextSet() {
      this.formTextSets = this.formTextSets.concat([
        {
          adFormat: this.isCreative
            ? _.chain(this.formTextSets).cloneDeep().result('[0].adFormat').value()
            : { platformId: '', id: '' },
          labeledTexts: this.isCreative ? this.searchLabeledTexts(this.adFormat.id) : [],
          score: null,
          comment: null,
          errors: [],
          targetErrors: [],
          tags: [],
          noTags: false,
        },
      ])
    },
    addLabeledText(i, j) {
      this.formTextSets = _.chain(this.formTextSets)
        .cloneDeep()
        .map((r, index) => {
          if (index !== i) return r

          const labeledTexts = _.chain(r.labeledTexts)
            .cloneDeep()
            .map((r2, index2) => {
              if (index2 !== j) return r2
              else if (r2.maxLength <= r2.textValues.length) {
                this.$message.warning(`${r2.name}はこれ以上追加できません`)
                return r2
              } else {
                return _.assign({}, r2, {
                  textValues: _.cloneDeep(r2.textValues).concat(['']),
                  regulationErrors: _.cloneDeep(r2.regulationErrors).concat([[]]),
                  unavailableCharsErrors: _.cloneDeep(r2.unavailableCharsErrors).concat([[]]),
                  crossRuleErrors: _.cloneDeep(r2.crossRuleErrors).concat([[]]),
                })
              }
            })
            .value()

          return _.assign({}, r, { labeledTexts })
        })
        .value()
    },
    removeLabeledText(textSetIndex, labeledTextIndex, textValuesIndex) {
      this.formTextSets = _.chain(this.formTextSets)
        .cloneDeep()
        .map((r, index) => {
          if (index !== textSetIndex) return r

          const labeledTexts = _.chain(r.labeledTexts)
            .cloneDeep()
            .map((labeledText, index2) => {
              if (index2 !== labeledTextIndex) return labeledText

              const textValues = _.filter(labeledText.textValues, (_, textIndex) => textIndex !== textValuesIndex)
              const regulationErrors = _.filter(
                labeledText.regulationErrors,
                (_, errorIndex) => errorIndex !== textValuesIndex
              )
              const unavailableCharsErrors = _.filter(
                labeledText.unavailableCharsErrors,
                (_, unCharErrorIndex) => unCharErrorIndex !== textValuesIndex
              )
              const crossRuleErrors = _.filter(labeledText.crossRuleErrors, (_, index3) => index3 !== textValuesIndex)
              return _.assign(
                {},
                labeledText,
                { textValues },
                { crossRuleErrors },
                { regulationErrors },
                { unavailableCharsErrors }
              )
            })
            .value()

          return _.assign({}, r, { labeledTexts })
        })
        .value()
    },
    removeTextSet(index) {
      this.formTextSets = _.filter(this.formTextSets, (r, i) => i !== index)
    },
    isIncludesEmptyTextValues(gr) {
      return (
        _.filter(gr, ({ labeledTexts }) =>
          labeledTexts.length === 0
            ? false
            : !_.find(labeledTexts, ({ textValues, minLength, containParameter }) =>
                containParameter || !minLength
                  ? false
                  : _.chain(textValues).take(minLength).compact().value().length < minLength
              )
        ).length === 0
      )
    },
    isIncludesEmptyTags(gr) {
      return _.filter(gr, ({ noTags, tags }) => (!noTags ? tags.length === 0 : false)).length !== 0
    },
    async registText(r) {
      const result = await this.$api
        .authFetch('/text_set', { method: 'POST', body: _.omit(r, ['tags', 'noTags']) })
        .then(async result => {
          if (!result.textSetId) return result
          const body = {
            selectedTargetIds: [result.textSetId],
            targetType: 'textSet',
            tagSettings: r.noTags ? [] : [{ tagId: this.oddAiTagId, targetIds: [result.textSetId] }],
          }
          await this.$api.authFetch('/tag/set', { method: 'PUT', body })
          return result
        })
      return result
    },

    async regist() {
      if (this.loading) return

      if (this.isIncludesEmptyTags(this.formTextSets)) {
        this.$alert('デフォルトタグが正しく選択されていません。', '必須チェック')
        return
      }

      if (this.isIncludesEmptyTextValues(this.formTextSets)) {
        this.$alert('必須のテキストが正しく入力されていません。', '必須チェック')
        return
      }

      const form = _.map(this.formTextSets, ({ labeledTexts, adFormat, score = '', tags, noTags }) =>
        _.assign(
          {
            promotionId: this.defaultPromotionId,
            adFormatId: adFormat.id,
            score,
            labeledTexts: _.map(labeledTexts, ({ id, textValues }) => ({
              labelId: id,
              textValues: _.filter(textValues),
            })),
            tags,
            noTags,
          },
          this.form.projectName ? { projectName: this.form.projectName } : {}
        )
      )

      this.loading = true

      const results = []
      for (let i = 0; i < form.length; i++) {
        results.push(await this.registText(form[i]))
      }
      this.loading = false

      const ret = _.filter(results)
      if (ret.length === 0) {
        this.$message.error('登録失敗')
        return
      }

      const errorResults = _.filter(
        ret,
        r => (r.errors && r.errors.length !== 0) || (r.labels && r.labels.length !== 0)
      )
      if (errorResults.length !== 0) {
        this.formTextSets = _.map(this.formTextSets, (r, i) => {
          const tmp = _.assign(
            {},
            r,
            !results[i].errors
              ? {}
              : {
                  errors: results[i].errors.map(x => {
                    return x.labelNames.join(', ').concat(`: ${x.error}`)
                  }),
                }
          ) // ケース①
          if (results[i].errors === 'undefined') return _.assign({}, tmp, r)

          const labeledTexts = _.map(tmp.labeledTexts, r2 => {
            const label = _.find(results[i].labels, r3 => r3.labelId === r2.id)
            if (!label) return _.assign({}, r2)

            const tmp2 = _.assign({}, r2, label.errors.length === 0 ? {} : { errors: label.errors }) // ケース②
            if (label.targets.length === 0) return _.assign({}, tmp2, r2)

            const targetErrors = _.chain(tmp2.textValues)
              .map((v, i) =>
                _.chain(label.targets)
                  .find(({ key }) => {
                    const removeLabelNameKey = key.replace(r2.name + ': ', '')
                    return removeLabelNameKey.substring(0, removeLabelNameKey.lastIndexOf('_')) === v
                  })
                  .result('errors')
                  .map(v => `${i + 1}番目: ${v.replace(r2.name + ': ', '')}`)
                  .value()
              )
              .filter(gr => gr.length !== 0)
              .flattenDeep()
              .value()

            return _.assign({}, tmp2, { targetErrors }) // ケース③
          })
          return _.assign({}, tmp, { labeledTexts })
        })
        return
      }

      const newTextSets = _.filter(ret, r => _.result(r, 'data.textSetId') || _.result(r, 'textSetId'))
      if (newTextSets.length === 0) this.$message.error(_.result(ret, '[0].error'))
      else this.$message.success('登録成功')
      this.isShow = false

      if (this.$route.path === '/creative-regist' || this.isInCreateCreativeDialog) {
        if (newTextSets.length !== 0)
          this.$emit(
            'get-new-text-set',
            _.map(newTextSets, r => _.result(r, 'data') || r)
          )
        else this.$emit('get-new-text-set', results)
      } else if (this.adFormat) this.$emit('add-ad-format', [this.adFormat.id])
      else this.$emit('add-ad-format', _.map(this.formTextSets, 'adFormat.id'))
    },
    formatScore(score) {
      const formatOption = { style: 'percent', minimumFractionDigits: 2 }
      return new Intl.NumberFormat('ja-JP', formatOption).format(score)
    },
    filterAdFormats(platformId) {
      return _.chain(this.adFormats)
        .filter(r => r.platformId === platformId)
        .uniqBy('id')
        .orderBy()
        .value()
    },
    searchLabeledTexts(adFormatId) {
      return _.chain(this.adFormats)
        .find(r => r.id === adFormatId)
        .result('textLabels')
        .map(r => {
          return _.assign({}, r, {
            textValues: _.times(r.minLength || 1, _.stubString),
            regulationErrors: _.times(r.minLength || 1, _.stubArray),
            errors: [],
            targetErrors: [],
            unavailableCharsErrors: _.times(r.minLength || 1, _.stubArray),
            crossRuleErrors: _.times(r.minLength || 1, _.stubArray),
          })
        })
        .orderBy(['labelIndex'])
        .value()
    },
    changePlatform(platformId, index) {
      this.formTextSets = _.map(this.formTextSets, (r, i) => {
        if (i !== index) return _.assign({}, r)
        else return _.assign({}, r, { adFormat: { platformId, id: '' }, labeledTexts: [] })
      })
    },
    changeAdFormat(adFormatId, index) {
      if (!adFormatId) {
        this.formTextSets = _.map(this.formTextSets, (r, i) => {
          if (i !== index) return _.assign({}, r)
          else
            return _.assign({}, r, {
              adFormat: { platformId: r.adFormat.platformId, id: '' },
              labeledTexts: [],
            })
        })
        return
      }

      const adFormat = _.find(this.adFormats, r => r.id === adFormatId) || {
        platformId: '',
        id: '',
      }
      const labeledTexts = this.searchLabeledTexts(adFormatId)
      this.formTextSets = _.map(this.formTextSets, (r, i) => {
        if (i !== index) return _.assign({}, r)
        else return _.assign({}, r, { adFormat, labeledTexts })
      })
    },
    setTextSetError(textSet, creativeRule) {
      let ret = []
      let error = _.map(textSet.labeledTexts, label => _.map(label.crossRuleErrors, error => error))
      _.map(error, x => _.map(x, errors => _.map(errors, err => ret.push(err))))
      return _.concat(_.uniqBy(ret), creativeRule)
    },
  },
  computed: {
    oddAiTagId() {
      return _.chain(this.tags)
        .find(r => r.tagValue === 'Odd-AI')
        .result('tagId')
        .value()
    },
    platforms() {
      return _.chain(this.adFormats)
        .uniqBy('platformId')
        .orderBy(r => _.toLower(r.platformName))
        .value()
    },
    isDisplayRegulationError() {
      return (
        _.chain(this.formTextSets)
          .cloneDeep()
          .filter(({ labeledTexts }) =>
            labeledTexts.length === 0
              ? false
              : _.chain(labeledTexts)
                  .find(({ regulationErrors, containParameter, unavailableCharsErrors }) =>
                    containParameter
                      ? false
                      : !_.chain(regulationErrors).flattenDeep().isEmpty().value() ||
                        !_.chain(unavailableCharsErrors).flattenDeep().isEmpty().value()
                  )
                  .value()
          )
          .value().length !== 0 ||
        !_.every(
          _.map(this.formTextSets, x => x.errors),
          error => error.length === 0
        )
      )
    },
  },
}
</script>
