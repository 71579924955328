<template>
  <div class="custom-loading" :style="{ zIndex: zIndex, background: backgroundCustom }">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<style>
.custom-loading,
.custom-loading div {
  box-sizing: border-box;
}
.custom-loading {
  display: block;
  position: absolute;
  width: 100%; /**/
  height: 100%; /**/
}
.custom-loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 36px;
  height: 36px;
  margin: 8px;
  border: 1.8px solid #409eff;
  border-radius: 50%;
  animation: custom-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #409eff transparent transparent transparent;
}
.custom-loading div:nth-child(1) {
  animation-delay: -0.45s;
}
.custom-loading div:nth-child(2) {
  animation-delay: -0.3s;
}
.custom-loading div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes custom-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<script>
export default {
  name: 'custom-loading',
  props: {
    isFullscreen: { type: Boolean, default: () => false },
    zIndex: { type: Number, default: () => 2000 },
    backgroundTransparent: { type: Boolean, default: () => false },
  },
  computed: {
    backgroundCustom() {
      return this.backgroundTransparent ? '#ffffffdd' : '#fff'
    },
  },
}
</script>
