<template>
  <el-dialog
    custom-class="small-dialog"
    id="status-dialog"
    :title="dialogTitle"
    width="25%"
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="pt-3 pb-1 text-center" v-loading="loading">
      <div class="pb-1">変更するステータスを選んでください</div>

      <el-select v-model="statusValue">
        <el-option v-for="(status, i) in statusOptions" :key="i" :label="status" :value="status" />
      </el-select>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" :disabled="canNotSubmitStatus" @click="changeStatus"> 保存 </el-button>

      <el-button @click="isShow = false"> キャンセル </el-button>
    </span>
  </el-dialog>
</template>

<style scoped>
.pb-1 {
  padding-bottom: 1rem;
}
.pt-3 {
  padding-top: 3rem;
}
.text-center {
  text-align: center;
}
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'
import { CreativeStatus } from '@/mixins/creativeStatus'
import { TargetType } from '@/mixins/targetType'
import { UPDATE_ASSET_TEXT_STATUS_POPUP_TITLE, UPDATE_CR_STATUS_POPUP_TITLE } from '@/mixins/constant'

export default {
  name: 'status-dialog',
  mixins: [util],
  props: {
    selected: { type: Array, default: () => [] },
    targetType: { type: String, default: () => '' },
  },
  data: () => ({
    isShow: false,
    isAssetTextSttUpdated: false,
    loading: false,
    statusValue: '',
    statusOptions: [],
  }),
  created() {
    window.StatusDialog = this

    switch (this.targetType) {
      case 'creative':
        this.statusOptions = [...this.approvalStatusCreativeOptions].filter(x => x !== '入稿依頼済' && x !== '入稿済')
        break
      default:
        this.statusOptions = this.approvalStatusOptions
    }
  },
  methods: {
    show() {
      this.statusValue = ''
      this.isShow = true
      this.isAssetTextSttUpdated = false
    },
    showAssetTextDialog() {
      this.statusValue = ''
      this.isShow = true
      this.isAssetTextSttUpdated = true
    },
    async changeStatus() {
      const updateStatus = async body => {
        this.loading = true
        await this.$api.authFetch('/approval_status', { method: 'PUT', body })
      }

      const buildUpdateSttBody = (targetType, targetData = this.selected) => {
        const approvalStatus =
          targetType === TargetType.CREATIVE
            ? this.convertApprovalStatusOptionsJapaneseToEnglish(this.statusValue)
            : this.statusValue
        return {
          targetType: targetType,
          targetIds: _.map(targetData, r => ({
            targetId: _.result(r, `${targetType}Id`),
          })),
          approvalStatus,
        }
      }

      const buildAssetTextUpdateSttBody = () => {
        // get list of assets and textSet to update
        const assetList = _.chain(this.selected)
          .flatMap(cr => cr.labeledAssets)
          .flatMap(labeledAsset => labeledAsset.assets)
          .uniq()
          .compact()
          .value()
        const textSetList = _.chain(this.selected)
          .map(cr => cr.textSet)
          .uniq()
          .compact()
          .value()

        // build bodies based on asset and textSet list
        let updateReqBodies = []
        if (assetList.length > 0) {
          updateReqBodies.push(buildUpdateSttBody(TargetType.ASSET, assetList))
        }
        if (textSetList.length > 0) {
          updateReqBodies.push(buildUpdateSttBody(TargetType.TEXTSET, textSetList))
        }

        return updateReqBodies
      }

      if (this.targetType === TargetType.CREATIVE) {
        const bodies = this.isAssetTextSttUpdated
          ? buildAssetTextUpdateSttBody()
          : [buildUpdateSttBody(TargetType.CREATIVE)]

        await Promise.all(bodies.map(body => updateStatus(body)))
      } else {
        const body = buildUpdateSttBody(this.targetType)
        await updateStatus(body)
      }

      this.$emit('reload', this.targetType)
      this.loading = false
      this.isShow = false
    },
  },
  watch: {
    isAssetTextSttUpdated() {
      if (this.targetType !== TargetType.CREATIVE) return

      this.statusOptions = this.isAssetTextSttUpdated
        ? this.approvalStatusOptions
        : [...this.approvalStatusCreativeOptions].filter(stt => !CreativeStatus.NO_EDIT_CR.includes(stt))
    },
  },
  computed: {
    dialogTitle() {
      return this.targetType === TargetType.CREATIVE && !this.isAssetTextSttUpdated
        ? UPDATE_CR_STATUS_POPUP_TITLE
        : UPDATE_ASSET_TEXT_STATUS_POPUP_TITLE
    },
    canNotSubmitStatus() {
      return this.statusValue === ''
    },
  },
}
</script>
