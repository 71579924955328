<template>
  <el-dialog
    id="edit-text-set-dialog"
    v-loading="loading"
    :visible.sync="isShow"
    title="テキスト編集"
    fullscreen
    lock-scroll
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form :style="{ height: height + 'px' }" class="scroll">
      <el-card class="m-1" shadow="never">
        <el-col :span="20" class="p-1 border-r-1">
          <el-row>
            <el-tag type="info" class="text-center font-bold mr-1 mb-1">
              {{ platformId }}
            </el-tag>

            <el-tag type="info" class="text-center font-bold mr-1 mb-1">
              {{ adFormatName }}
            </el-tag>

            <el-tag v-if="formTextSet.score" type="warning" class="font-bold mr-1">
              {{ formatScore(formTextSet.score) }}
            </el-tag>

            <div v-if="formTextSet.comment">
              {{ formTextSet.comment }}
            </div>
          </el-row>
          <el-row v-if="formTextSet.errors">
            <el-row
              v-for="(error, i) in formTextSet.errors"
              :key="i"
              class="mb-1 text-red lh-0 mt-2"
              :class="{
                'text-approval-detail': isInApprovalDetail,
              }"
            >
              {{ error.substring(0, 1) === ',' ? error.substring(1) : error }}
            </el-row>
          </el-row>

          <el-row>
            <el-row v-for="(labeledTextArray, k) in chunkedLabeledTexts" :key="k">
              <el-form-item class="float-left w-33" v-for="(labeledText, i) in labeledTextArray" :key="i + k * 3">
                <el-row>
                  <div class="float-left mr-2">
                    {{ labeledText.labelName }}
                    <el-tag
                      class="required-tag"
                      v-if="
                        labeledText.minLength &&
                        labeledText.minLength !== 0 &&
                        (isInApprovalDetail ? formTextSet.textType !== 'Asset' : true)
                      "
                    >
                      <span> 必須 </span>
                    </el-tag>
                    <el-tag v-else class="option-tag">
                      <span> 任意 </span>
                    </el-tag>
                  </div>

                  <span class="float-left" @click="addLabeledText(i + k * 3)">
                    <i class="fas fa-plus-circle" />
                  </span>
                </el-row>

                <div id="text-area" class="pr-1" v-for="(v, j) in labeledText.textValues" :key="`${i + k * 3}_${j}`">
                  <el-col class="mb-1" :span="20">
                    <el-row>
                      <div class="float-left w-100">
                        <div v-if="labeledText.ctaPhrases == null">
                          <el-input
                            v-model="labeledText.textValues[j]"
                            type="textarea"
                            class="float-left"
                            @input="
                              v => {
                                labeledText.textValues[j] = remove_white_and_ctrl_character(v)
                                labeledText.regulationErrors[j] = checkCharacter(
                                  remove_white_and_ctrl_character(v),
                                  labeledText
                                )
                                labeledText.unavailableCharsErrors[j] = checkUnavailalbleChar(
                                  remove_white_and_ctrl_character(v),
                                  labeledText
                                )
                                labeledText.crossRuleErrors[j] = checkContinuousTextMaximumLimit(
                                  v,
                                  adFormat.crossRule,
                                  labeledText,
                                  'edit'
                                )
                                formTextSet.errors = setTextSetError(
                                  formTextSet,
                                  checkC(adFormat.crossRule, formTextSet.labeledTexts, 'edit')
                                )
                              }
                            "
                            :class="{
                              'border-red': !checkCharCountRule(
                                countStrLength(labeledText.textValues[j], labeledText),
                                labeledText.minCharLength,
                                labeledText.maxCharLength
                              ),
                            }"
                          />
                        </div>
                        <div v-else>
                          <el-select
                            v-model="labeledText.textValues[j]"
                            style="width: 100%"
                            clearable
                            placeholder="ご選択ください。"
                            @change="
                              value => {
                                labeledText.crossRuleErrors[j] = checkContinuousTextMaximumLimit(
                                  value,
                                  adFormat.crossRule,
                                  labeledText,
                                  'edit'
                                )
                                formTextSet.errors = setTextSetError(
                                  formTextSet,
                                  checkC(adFormat.crossRule, formTextSet.labeledTexts, 'edit')
                                )
                              }
                            "
                          >
                            <el-option
                              v-for="(item, i) in labeledText.ctaPhrases"
                              :key="`${item}_${i}`"
                              :label="item"
                              :value="item"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>

                      <span
                        v-if="
                          labeledText.textValues.length > labeledText.minLength && labeledText.textValues.length > 1
                        "
                        class="badge lh-0"
                        @click="removeTextArea(i + k * 3, j)"
                      >
                        <i class="fas fa-times-circle icon-red" />
                      </span>
                    </el-row>

                    <el-row class="mt-1">
                      <el-row v-if="labeledText.regulationErrors[j].includes('overLength')" class="text-red lh-2">
                        <span>文字数がオーバーしています。</span>
                        <span v-if="labeledText.containParameter && !isInApprovalDetail"
                          >広告カスタマイザを使用している場合はそのまま保存ボタンを押してください。</span
                        >
                      </el-row>

                      <el-row v-if="labeledText.regulationErrors[j].includes('underLength')" class="text-red lh-2">
                        <span>文字数は{{ labeledText.minCharLength }}文字以上でなければいけません。</span>
                        <span v-if="labeledText.containParameter && !isInApprovalDetail"
                          >広告カスタマイザを使用している場合はそのまま保存ボタンを押してください。</span
                        >
                      </el-row>

                      <el-row v-if="labeledText.regulationErrors[j].includes('lineBreak')" class="text-red lh-2">
                        改行は禁止です
                      </el-row>

                      <el-row v-if="labeledText.regulationErrors[j].includes('emoji')" class="text-red lh-2">
                        絵文字は禁止です
                      </el-row>

                      <el-row v-if="labeledText.regulationErrors[j].includes('kana')" class="text-red lh-2">
                        半角カナは禁止です
                      </el-row>

                      <el-row v-if="labeledText.regulationErrors[j].includes('fullWidth')" class="text-red lh-2">
                        全角文字は禁止です
                      </el-row>
                      <el-row v-if="labeledText.unavailableCharsErrors[j].length !== 0" class="text-red lh-2">
                        「{{ labeledText.unavailableCharsErrors[j].join(', ') }}」は使用不可文字として登録されています。
                      </el-row>
                    </el-row>
                  </el-col>

                  <el-col :span="4">
                    <p
                      v-if="labeledText.ctaPhrases == null"
                      class="lh-1 text-right"
                      :class="
                        checkCharCountRule(
                          countStrLength(labeledText.textValues[j], labeledText),
                          labeledText.minCharLength,
                          labeledText.maxCharLength
                        )
                          ? 'text-gray'
                          : 'text-red'
                      "
                      style="margin: 0; transform: translateY(-2px)"
                    >
                      {{ countStrLength(labeledText.textValues[j], labeledText) }} /
                      {{ labeledText.maxCharLength ? labeledText.maxCharLength : 'なし' }}
                    </p>
                  </el-col>
                </div>
              </el-form-item>
            </el-row>
          </el-row>
        </el-col>

        <el-col :span="4" class="p-1">
          <el-tag type="info" class="text-center font-bold mr-1 mb-1" v-for="(r, i) in formTextSet.tags" :key="i">
            {{ r.tagValue }}
          </el-tag>

          <div class="text-center mt-2 font-big" v-if="!isInApprovalDetail">
            <span @click="openTagDialog">
              <i class="fas fa-plus-circle" />
            </span>
          </div>
        </el-col>
      </el-card>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-dialog :visible.sync="isConfirmShow" title="変更内容の確認" width="50%" center modal class="confirm-dialog">
        <div class="dialog-question">
          <b> 変更内容を保存しますか？ </b>
        </div>
        <ul style="overflow: auto; height: 300px">
          <li v-for="[label, textValuesTuple] of Object.entries(this.changedData)" :key="label">
            <el-divider content-position="left" style="margin: 20px 0">{{ label }}</el-divider>
            <div class="confirm-dialog-header">
              <p style="text-align: center; font-size: 15px; font-weight: 15">変更前</p>
              <p style="text-align: center; font-size: 15px; font-weight: 15">変更後</p>
            </div>
            <div class="confirm-dialog-content">
              <div class="input-compare">
                <div v-if="textValuesTuple[0].length === 0">
                  <h2 style="text-align: center">なし</h2>
                </div>
                <div v-else>
                  <el-input
                    disabled
                    type="textarea"
                    readonly
                    :value="textValue"
                    rows="2"
                    v-for="(textValue, index) in textValuesTuple[0]"
                    :key="index"
                  />
                </div>
              </div>
              <div class="input-compare">
                <div v-if="textValuesTuple[1].length === 0">
                  <h2 style="text-align: center">なし</h2>
                </div>
                <div v-else>
                  <el-input
                    disabled
                    type="textarea"
                    readonly
                    :value="textValue"
                    rows="2"
                    v-for="(textValue, index) in textValuesTuple[1]"
                    :key="index"
                  />
                </div>
              </div>
            </div>
          </li>
        </ul>
        <el-divider />
        <div class="confirm-dialog-button">
          <span>
            <el-button @click="closeConfirmDialog">キャンセル</el-button>
            <el-button type="primary" @click="confirm(createTextDraft)" v-if="isInApprovalDetail"> 確定 </el-button>
            <el-button type="primary" @click="confirm(edit)" v-else> 確定 </el-button>
          </span>
        </div>
      </el-dialog>
      <el-button
        type="primary"
        @click="openConfirmDialog(edit)"
        v-if="!isInApprovalDetail"
        :disabled="isDisplayRegulationError || isIncludesEmptyTextValues([formTextSet])"
      >
        保存
      </el-button>
      <el-button
        type="primary"
        @click="openConfirmDialog(createTextDraft)"
        v-else
        :disabled="isDisplayRegulationError || isIncludesEmptyTextValues([formTextSet])"
      >
        確定
      </el-button>
    </span>
  </el-dialog>
</template>

<style>
.confirm-dialog {
  .input-compare,
  .input-compare > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
  }

  .input-compare:first-child {
    border-right: 1px solid #000000;
  }

  .input-compare textarea {
    width: 300px;
    margin-bottom: 8px;
  }

  .el-dialog {
    border-radius: 10px;
    overflow: hidden;

    .el-dialog__header {
      text-align: left;
    }

    .el-textarea.is-disabled .el-textarea__inner {
      color: #000;
      background: #fff;
      border-width: 2px; /* Độ dày của đường viền */
    }

    .dialog-question {
      padding: 16px;
      display: flex;
      justify-content: center;
    }

    .confirm-dialog-header {
      display: flex;
      justify-content: space-between;
    }

    .confirm-dialog-content el-divider {
      height: 100%;
      display: flex;
    }

    .confirm-dialog-header p {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0; /* Đảm bảo không có margin mặc định */
    }

    .confirm-dialog-button {
      display: flex;
      justify-content: flex-end;
      padding: 16px;
    }

    .confirm-dialog-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;

      .el-icon-right {
        font-size: 40px;
        font-weight: 700;
      }
    }
  }
}

#text-area .el-form-item__content {
  line-height: initial;
}

#text-area .border-red .el-textarea__inner {
  border: 0;
}

#edit-text-set-dialog .el-card__body {
  padding: 0px;
}

#edit-text-set-dialog .el-card:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

.scrollable .el-message-box__content {
  overflow-y: scroll;
  max-height: 500px;
}
</style>

<style scoped>
.border-red {
  border: 1px solid #f56c6c;
  border-radius: 4px;
  box-sizing: border-box;
}
.text-approval-detail {
  font-size: 13px;
  line-height: 30px;
  font-weight: bold;
}
.text-red {
  color: #f56c6c;
}
.text-gray {
  color: #909399;
}
.font-big {
  font-size: 22px;
}
.border-r-1 {
  border-right: solid 1px #ebeef5;
}
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}

.scroll::-webkit-scrollbar {
  display: none;
}

/* Chrome, Safari 対応 */
.icon-red {
  color: #f56c6c;
}

.lh-0 {
  line-height: 0;
}

.lh-1 {
  line-height: 3.5rem;
}

.m-1 {
  margin: 1rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mr-2 {
  margin-right: 1rem;
}

.pt-1:first-child {
  padding-top: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pr-2 {
  padding-right: 5px;
}

.pl-1 {
  padding-left: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 10px;
}

.p-1 {
  padding: 1rem;
}

.float-left {
  float: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.w-0 {
  width: 0;
}

.w-33 {
  width: 33%;
}

.w-100 {
  width: 100%;
}

.font-bold {
  font-weight: bold;
}

.badge {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 2;
}

.required-tag {
  align-items: center;
  justify-content: center;
  height: 17px;
  width: 40px;
  background-color: #f56c6c;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  padding-left: 9px;
  -webkit-clip-path: polygon(15px 0%, 100% 0%, 100% 100%, 15px 100%, 0 50%);
  clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0 50%);
  line-height: 17px;
}

.option-tag {
  align-items: center;
  justify-content: center;
  height: 17px;
  width: 40px;
  background-color: #00d7bf;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  padding-left: 9px;
  -webkit-clip-path: polygon(15px 0%, 100% 0%, 100% 100%, 15px 100%, 0 50%);
  clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0 50%);
  line-height: 17px;
}
</style>

<script>
import _, { isEmpty, clone } from 'lodash'
import util from '@/mixins/util'

export default {
  name: 'edit-text-set-dialog',
  mixins: [util],
  props: {
    platformId: { type: String, default: () => '' },
    adFormatId: { type: String, default: () => '' },
    adFormatName: { type: String, default: () => '' },
    adFormats: { type: Array, default: () => [] },
    checkType: { type: String, default: () => '' },
    adFormat: {
      type: Object,
      default: () => {},
    },
    isMultiple: { type: Boolean, default: () => false },
  },
  inject: {
    isInCreateCreativeDialog: {
      from: 'isInCreateCreativeDialog',
      default: false,
    },
  },
  data() {
    const numberRule = { type: 'number', message: '数字のみ有効です', trigger: 'change' }
    const rules = { adAccountId: [numberRule], adGroupId: [numberRule] }
    const form = { adAccountId: null, adGroupId: null, projectName: null }
    return {
      loading: false,
      isShow: false,
      isConfirmShow: false,
      formTextSet: {},
      formTextSetOriginal: {},
      changedData: {},
      height: window.innerHeight - 34 - 70,
      rules,
      form,
    }
  },
  created() {
    window.EditTextSetDialog = this
    window.addEventListener('resize', () => {
      this.height = window.innerHeight - 34 - 70
    })
  },
  methods: {
    removeTextArea(j, k) {
      this.removeLabeledText(j, k)
      const cloneFormTextSet = clone(this.formTextSet)
      cloneFormTextSet.labeledTexts.forEach(labeledText => {
        labeledText.regulationErrors = []
        labeledText.unavailableCharsErrors = []
        labeledText.crossRuleErrors = []
        labeledText.textValues.forEach((v, j) => {
          labeledText.regulationErrors[j] = this.checkCharacter(this.remove_white_and_ctrl_character(v), labeledText)
          labeledText.unavailableCharsErrors[j] = this.checkUnavailalbleChar(
            this.remove_white_and_ctrl_character(v),
            labeledText
          )
          labeledText.crossRuleErrors[j] = this.checkContinuousTextMaximumLimit(
            v,
            this.adFormat.crossRule,
            labeledText,
            'edit'
          )
        })
      })
      cloneFormTextSet.errors = this.setTextSetError(
        cloneFormTextSet,
        this.checkC(this.adFormat.crossRule, cloneFormTextSet.labeledTexts, 'edit')
      )
      this.formTextSet = cloneFormTextSet
    },
    closeConfirmDialog() {
      this.isConfirmShow = false
    },
    openConfirmDialog(func) {
      this.isConfirmShow = true
      this.detectTextLabelValueChange(func)
    },
    initalizeData(labeledText, type) {
      return labeledText.textValues.map(t => {
        switch (type) {
          case 'regulationErrors': {
            return this.checkCharacter(this.remove_white_and_ctrl_character(t), labeledText)
          }
          case 'unavailableCharsErrors': {
            return this.checkUnavailalbleChar(this.remove_white_and_ctrl_character(t), labeledText)
          }
          case 'crossRuleErrors': {
            return this.checkContinuousTextMaximumLimit(
              this.remove_white_and_ctrl_character(t),
              this.adFormat.crossRule,
              labeledText,
              'edit'
            )
          }
          default:
            return []
        }
      })
    },
    // confirm data is not empty // TODO: find another way to not mutate the props data
    reInitData(textSet) {
      this.formTextSet = {}
      this.adFormat = this.adFormats.find(adformat => adformat.id === textSet.adFormatId)
      this.adFormatId = textSet.adFormatId
      this.adFormatName = textSet.adFormatName
      this.platformId = textSet.platformId
    },
    show(textSet) {
      this.isShow = true
      if (this.isMultiple) this.reInitData(textSet)
      this.formTextSet = _.cloneDeep(textSet)
      const unresolevedLabels = _.chain(this.adFormat.textLabels)
        .map(l => ({
          ...l,
          labelId: l?.id,
          labelName: l?.name,
          textValues: [],
        }))
        .value()
      this.formTextSet.labeledTexts = _.chain(this.formTextSet.labeledTexts)
        .map(r => {
          return _.assign({}, r, {
            regulationErrors: this.initalizeData(r, 'regulationErrors'),
            errors: [],
            targetErrors: [],
            unavailableCharsErrors: this.initalizeData(r, 'unavailableCharsErrors'),
            crossRuleErrors: this.initalizeData(r, 'crossRuleErrors'),
          })
        })
        .concat(unresolevedLabels)
        .uniqBy('labelId')
        .map(r => {
          const labels = _.chain(this.adFormats)
            .find(r => r.id === this.adFormatId)
            .result('textLabels')
            .value()

          const label = _.chain(labels)
            .find(labelT => labelT.id === r.labelId)
            .result('ctaPhrases')
            .value()

          return _.assign({}, r, { ctaPhrases: label })
        })
        .value()
      this.formTextSet.errors = this.setTextSetError(
        this.formTextSet,
        this.checkC(this.adFormat.crossRule, this.formTextSet.labeledTexts, 'edit')
      )
      this.formTextSetOriginal = _.cloneDeep(this.formTextSet)
    },
    openTagDialog() {
      const params = {
        targetType: 'textSet',
        selectedTags: this.selectedTags,
        targetIds: [_.result(this.formTextSet, 'textSetId')],
        isInDialog: 'EditTextSetDialog',
      }
      this.$root.$children[0].$refs.tagDialog.show(params)
    },
    addLabeledText(i) {
      this.formTextSet.labeledTexts = _.chain(this.formTextSet.labeledTexts)
        .map((r, index) => {
          if (index !== i) return r
          else if (r.maxLength <= r.textValues.length) {
            this.$message.warning(`${r.labelName}はこれ以上追加できません`)
            return r
          } else {
            return _.assign({}, r, {
              textValues: _.cloneDeep(r.textValues).concat(['']),
              regulationErrors: _.cloneDeep(r.regulationErrors ? r.regulationErrors : []).concat([[]]),
              unavailableCharsErrors: _.cloneDeep(r.unavailableCharsErrors ? r.unavailableCharsErrors : []).concat([
                [],
              ]),
              crossRuleErrors: _.cloneDeep(r.crossRuleErrors ? r.crossRuleErrors : []).concat([[]]),
            })
          }
        })
        .cloneDeep()
        .value()
    },
    removeLabeledText(i, j) {
      this.formTextSet.labeledTexts = _.chain(this.formTextSet.labeledTexts)
        .map((r, index) =>
          index === i && r.textValues.length > r.minLength
            ? _.assign({}, r, { textValues: _.filter(r.textValues, (r2, index2) => index2 !== j) })
            : r
        )
        .cloneDeep()
        .value()
    },
    async confirm(callback) {
      await callback()
      this.closeConfirmDialog()
    },
    async detectTextLabelValueChange(func) {
      const result = {}

      function compareArrays(array1, array2) {
        // Check if the arrays have the same length
        const hasDifferentSize = array1.length !== array2.length
        // Check if the arrays have different elements
        const hasDifferentElements = array1.some((element, index) => element !== array2[index])

        return hasDifferentSize || hasDifferentElements
      }

      for (const label of this.formTextSet.labeledTexts) {
        const textValues = label.textValues.filter(n => n)
        const original = this.formTextSetOriginal.labeledTexts.find(r2 => r2.labelId === label.labelId)
        if (!original && label.textValues.length > 0) {
          result[label.labelName] = [[], textValues]
        } else {
          const isDifferent = compareArrays(textValues, original.textValues)
          if (isDifferent === true) {
            if (isEmpty(original.textValues)) {
              result[label.labelName] = [[], textValues]
            } else {
              result[label.labelName] = [original.textValues, textValues]
            }
          }
        }
      }

      if (Object.values(result).length === 0) {
        this.closeConfirmDialog()
        await func()
        return
      }
      this.changedData = result
    },
    async edit() {
      const { textSetId, labeledTexts, score = '' } = this.formTextSet
      const form = {
        adFormatId: this.adFormatId,
        textSetId,
        score,
        labeledTexts: _.map(labeledTexts, ({ labelId, textValues }) => ({
          labelId,
          textValues: _.filter(textValues),
        })),
      }

      this.loading = true
      const result = await this.$api.authFetch('/text_set', { method: 'PUT', body: form })
      this.loading = false

      if (!result) {
        this.$message.error('編集失敗')
        return
      }

      if (result && result.errors) {
        let adformatErrors = result.errors.map(adformatError => {
          const labelNames = adformatError.labelNames.join(', ')
          const errorMess = adformatError.error
          return labelNames.concat(': ', errorMess)
        })
        let messages = _.map(result.labels, r => {
          const errLabel = '<div><ul>' + r.errors.map(r => `<li>${r}</li>`).join('') + '</ul></div>'
          let textData = {}
          const errorResponse = _.find(this.formTextSet.labeledTexts, r2 => r.labelId === r2.labelId)
          if (!_.isUndefined(errorResponse)) {
            textData = errorResponse
          } else {
            const unselectedLabel = _.chain(this.adFormat?.textLabels)
              .find(r2 => r.labelId === r2.id)
              .value()
            textData = !_.isEmpty(unselectedLabel)
              ? { labelName: unselectedLabel.name, textValues: [] }
              : {
                  labelName: '',
                  textValues: [],
                }
          }
          const { labelName, textValues } = textData
          const detail =
            '<ul>' +
            r.targets
              .map(target => {
                const errorTextValue = target.key.substring(0, target.key.lastIndexOf('_'))
                const index = textValues.indexOf(errorTextValue)
                const key = `${index + 1}番目`
                return target.errors
                  .map(v => {
                    if (v.includes('商材NGワードとして設定されています。')) {
                      const error = v.substring(0, v.indexOf('\\n'))
                      return `<li>${error.replace(labelName, key)}</li>`
                    } else {
                      return `<li>${v.replace(labelName, key)}</li>`
                    }
                  })
                  .join('')
              })
              .sort()
              .join('') +
            '</ul>'
          return `${labelName}${r.errors.length > 0 ? errLabel : ''}${detail}`
        })
        messages = adformatErrors.concat(messages)
        if (!messages.every(x => !x.includes('商材NGワードとして設定されています。'))) {
          const link = `${this.$api.getRegulationDomain()}/advertisers`
          const NGalert =
            '<div>NGワードに変更がある場合は<a href=' +
            link +
            ' target="_blank">こちら</a>からご確認お願いいたします。</div>'
          messages.unshift(NGalert)
        }
        this.$alert(messages.join('<br>'), 'レギュレーションエラー', {
          dangerouslyUseHTMLString: true,
          customClass: 'scrollable',
        })
        return
      }

      if (result && result.error) {
        if (result.code === 400)
          this.$message({
            message: '検索キャンペーンの広告グループ以外を指定することはできません',
            type: 'error',
          })
        else this.$message({ message: result.message || result.error, type: 'error' })
        return
      }

      this.$message.success('編集成功')
      this.$emit('get-text-sets')
      this.$emit('change-text-set', result)
      this.isShow = false
    },
    isIncludesEmptyTextValues(gr) {
      return (
        _.filter(
          gr,
          ({ labeledTexts, textType }) =>
            textType === 'Asset' ||
            !_.find(labeledTexts, ({ textValues, minLength }) =>
              !minLength ? false : _.chain(textValues).take(minLength).compact().value().length < minLength
            )
        ).length === 0
      )
    },

    async regulateText() {
      const { labeledTexts } = this.formTextSet
      const form = Object.assign(
        {
          promotionId: this.$route.query.promotionIds,
          adFormatId: this.adFormatId,
          checkType: !isEmpty(this.checkType) ? this.checkType : 'Normal',
          labeledTexts: _.reduce(
            labeledTexts,
            (total, { labelId, textValues }) => {
              const textFiltersNotEmpty = textValues.filter(textValue => !isEmpty(textValue))
              if (textFiltersNotEmpty.length > 0) {
                total.push({ labelId, textValues: _.filter(textFiltersNotEmpty) })
              }
              return total
            },
            []
          ),
        },
        this.form.projectName ? { projectName: this.form.projectName } : {}
      )
      this.loading = true
      const results = await this.$api.authFetch('/text_set/regulate', { method: 'POST', body: form })
      this.loading = false
      return results
    },

    async createTextDraft() {
      const compareTextSet = (textSet, otherTextSet) => {
        const result = textSet.labeledTexts.map((text, i) => {
          return (
            !_.isUndefined(otherTextSet.labeledTexts[i]) &&
            _.isEqual(text.textValues, otherTextSet.labeledTexts[i].textValues)
          )
        })
        return _.includes(result, false)
      }

      // compact formTextSet from empty and undefined string
      this.formTextSet = {
        ...this.formTextSet,
        ...{
          labeledTexts: _.map(this.formTextSet.labeledTexts, labeledText => ({
            ...labeledText,
            textValues: _.filter(labeledText.textValues, text => !_.isEmpty(text) && !_.isUndefined(text)),
          })),
        },
      }
      const result = await this.regulateText()
      if (result && result.errors) {
        let adformatErrors = result.errors.map(adformatError => {
          const labelNames = adformatError.labelNames.join(', ')
          const errorMess = adformatError.error
          return labelNames.concat(': ', errorMess)
        })
        let messages = _.map(result.labeledTexts, r => {
          const errLabel = '<div><ul>' + r.errors.map(r => `<li>${r}</li>`).join('') + '</ul></div>'
          let textData = {}
          const errorResponse = _.find(this.formTextSet.labeledTexts, r2 => r.labelId === r2.labelId)
          if (!_.isUndefined(errorResponse)) {
            textData = errorResponse
          } else {
            const unselectedLabel = _.chain(this.adFormat?.textLabels)
              .find(r2 => r.labelId === r2.id)
              .value()
            textData = !_.isEmpty(unselectedLabel)
              ? { labelName: unselectedLabel.name, textValues: [] }
              : {
                  labelName: '',
                  textValues: [],
                }
          }
          const { labelName, textValues } = textData
          const detail =
            '<ul>' +
            r.targets
              .map(target => {
                return target.errors
                  .map(v => {
                    const errorTextValue = target.textValues
                    const index = textValues.indexOf(errorTextValue)
                    const key = `${index + 1}番目`
                    if (v.includes('商材NGワードとして設定されています。')) {
                      const error = v.substring(0, v.indexOf('\\n'))
                      return `<li>${error.replace(labelName, key)}</li>`
                    } else {
                      return `<li>${v.replace(labelName, key)}</li>`
                    }
                  })
                  .join('')
              })
              .sort()
              .join('') +
            '</ul>'
          return `${labelName}${r.errors.length > 0 ? errLabel : ''}${detail}`
        })
        messages = adformatErrors.concat(messages)
        if (!messages.every(x => !x.includes('商材NGワードとして設定されています。'))) {
          const link = `${this.$api.getRegulationDomain()}/advertisers`
          const NGalert =
            '<div>NGワードに変更がある場合は<a href=' +
            link +
            '} target="_blank">こちら</a>からご確認お願いいたします。</div>'
          messages.unshift(NGalert)
        }
        this.$alert(messages.join('<br>'), 'レギュレーションエラー', {
          dangerouslyUseHTMLString: true,
          customClass: 'scrollable',
        })
        return
      } else {
        const { textSetId, labeledTexts, score = '' } = this.formTextSet
        const form = {
          adFormatId: this.adFormatId,
          textSetId,
          score,
          labeledTexts: _.map(labeledTexts, ({ labelId, textValues }) => ({
            labelId,
            textValues: _.filter(textValues),
          })),
        }
        const isDiffFromPrev = compareTextSet(this.formTextSet, this.formTextSetOriginal)
        const newFormTextSet = {
          ...this.formTextSet,
          ...{ approvalStatus: isDiffFromPrev ? '監修未提出' : this.formTextSet.approvalStatus },
          ...{ version: this.formTextSet.version + (isDiffFromPrev ? 1 : 0) },
        }
        this.$emit('get-text-sets-draft', newFormTextSet, form)
        this.isShow = false
      }
    },
    formatScore(score) {
      const formatOption = { style: 'percent', minimumFractionDigits: 2 }
      return new Intl.NumberFormat('ja-JP', formatOption).format(score)
    },
    setTextSetError(textSet, creativeRule) {
      let ret = []
      let error = _.map(textSet.labeledTexts, label => _.map(label.crossRuleErrors, error => error))
      _.map(error, x => _.map(x, errors => _.map(errors, err => ret.push(err))))
      return _.concat(_.uniqBy(ret), creativeRule)
    },
  },
  computed: {
    isInApprovalDetail() {
      return this.$route.path === '/approval-detail' && !this.isInCreateCreativeDialog
    },
    selectedTags() {
      return _.chain(this.formTextSet)
        .result('tags')
        .groupBy('tagId')
        .mapValues(() => ({
          selectedTargetIds: [_.result(this.formTextSet, 'textSetId')],
          isAllSelected: true,
        }))
        .value()
    },
    isDisplayRegulationError() {
      if (_.isEmpty(this.formTextSet)) return false
      return (
        _.chain([this.formTextSet])
          .cloneDeep()
          .filter(({ labeledTexts }) =>
            _.chain(labeledTexts)
              .find(({ regulationErrors, containParameter, unavailableCharsErrors }) =>
                containParameter
                  ? false
                  : !_.chain(regulationErrors).flattenDeep().isEmpty().value() ||
                    !_.chain(unavailableCharsErrors).flattenDeep().isEmpty().value()
              )
              .value()
          )
          .value().length !== 0 ||
        !_.every(
          _.map([this.formTextSet], x => x.errors),
          error => error.length === 0
        )
      )
    },
    chunkedLabeledTexts() {
      return _.chunk(this.formTextSet.labeledTexts, 3)
    },
  },
}
</script>
