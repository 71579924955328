<template>
  <div class="m-1">
    <el-row class="mb-1" type="flex" align="top">
      <el-col>
        <div class="font-mini ml-1 line-1">
          {{ revision.metadata.fileName }}
        </div>

        <div class="float-left font-mini ml-1 line-1">
          {{ revision.metadata.rawFileName }}
        </div>
      </el-col>
    </el-row>

    <image-movie-item
      class="mb-1"
      :url="revision.url"
      :thumbnail-url="revision.thumbnailUrl"
      :type="revision.assetType"
    />

    <el-row :gutter="10">
      <el-col :span="12">
        <el-tag type="info" class="w-100 text-center font-bold"> ver.{{ revision.version }} </el-tag>
      </el-col>

      <el-col :span="12">
        <el-tag
          :type="revision.approvalStatus === '監修未提出' ? 'danger' : 'success'"
          class="w-100 text-center font-bold"
        >
          {{ revision.approvalStatus }}
        </el-tag>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.w-100 {
  width: 100%;
}
.text-center {
  text-align: center;
}
.font-bold {
  font-weight: bold;
}
.m-1 {
  margin: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.ml-1 {
  margin-left: 0.5rem;
}
.line-1 {
  line-height: 18px;
}
.float-left {
  float: left;
}
.font-mini {
  font-size: 10px;
  cursor: default;
}
</style>

<script>
import ImageMovieItem from '@/components/image-movie-item'

export default {
  name: 'version-card',
  components: { ImageMovieItem },
  props: { revision: { type: Object, default: () => ({}) } },
  created() {
    window.VersionCard = this
  },
}
</script>
