<template>
  <div id="text-master-drawer" class="ml-2 mr-2">
    <el-row type="flex" class="ps-1">
      <el-col :span="6">
        <el-row>
          <div class="title font-14">紐付けキー</div>
          <el-input
            v-model="projectName"
            @keyup.enter.native="handleProjectNameConfirm"
            @blur="handleProjectNameConfirm"
            class="w-100 size"
            multiple
            clearable
            filterable
            size="mini"
          >
          </el-input>
        </el-row>
        <el-row>
          <el-tag
            class="size tooltip"
            v-for="(tag, i) in projectNames"
            :key="tag"
            size="mini"
            @close="removeProjectNameTag(i)"
            closable
          >
            {{ tag.length > 20 ? tag.substring(0, 20) + '...' : tag }}
            <span v-if="tag.length > 20" class="tooltiptext">{{ tag }}</span>
          </el-tag>
        </el-row>
        <el-row>
          <div class="title font-14">監修ステータス</div>
          <el-checkbox-group v-model="checkedApprovalStatus">
            <el-checkbox v-for="status in approvalStatus" class="w-50 size" :label="status" :key="status">{{
              status
            }}</el-checkbox>
          </el-checkbox-group>
        </el-row>
      </el-col>
      <el-col :span="7">
        <el-row>
          <div class="title font-14">テキスト本文検索</div>
          <el-input v-model="q" type="textarea" resize="none no-resize" rows="3" size="mini" class="size"></el-input>
        </el-row>
      </el-col>
      <el-col :span="10" style="flex: 1">
        <el-row>
          <div class="title font-14">タグ</div>
        </el-row>
        <el-row v-for="(r, i) in tagsTemp" :key="`tags_${i}`" type="flex" align="middle">
          <tag-selection
            :tags="tags"
            :tag-type-id="r.tagTypeId"
            :tag-value="r.tagValue"
            :target-type="r.targetType"
            @remove-tag="removeTag(i)"
            @change-tag-type="id => changeTagInfo(i, id, 'tagTypeId')"
            @change-tag-value="id => changeTagInfo(i, id, 'tagValue')"
          />
        </el-row>
        <el-row type="flex" justify="center" v-if="tagsTemp.length < 5">
          <el-button type="plain" size="mini" @click="addTag">OR</el-button>
        </el-row>
      </el-col>
    </el-row>

    <el-row type="flex" justify="end" class="footer">
      <el-col :span="18"></el-col>
      <el-col :span="7">
        <el-row type="flex" justify="end" class="mr-1">
          <el-button size="medium" type="warning" @click="setLastForm">前回の検索条件を再利用</el-button>
          <el-button size="medium" @click="reset">すべてクリア</el-button>
          <el-button size="medium" type="primary" @click="search">適用</el-button>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style>
#select-bar .el-divider--horizontal {
  margin: 0;
  display: flex;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 10%;
  border-width: 5px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: gray;
  color: #fff;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>

<style scoped>
.font-14,
.font-14-option {
  font-size: 14px;
}
.el-radio__label,
.el-tag,
.el-checkbox__label,
.el-textarea__inner,
.el-input__inner,
.el-select-dropdown__empty {
  font-size: 13px !important ;
}
.font-13,
.font-13-option {
  font-size: 13px;
}

.el-col + .el-col {
  margin-left: 20px;
}
.text-black {
  color: #000000;
}

#text-master-drawer {
  background-color: #e0e0e0;
  /* padding: 0px 5px 20px; */
  padding-bottom: 10px;
}

.mr-1 {
  margin-right: 10px;
}

.ml-1 {
  margin-left: 10px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 20px;
}

.ml-2 {
  margin-left: 20px;
}

.ps-1 {
  padding: 0px 20px;
  flex-wrap: wrap;
  background-color: #e0e0e0;
}

.checkbox {
  width: 50%;
  margin-right: 0px;
}
.title {
  font-weight: bold;
  padding: 10px 10px 5px 0px;
  width: 100%;
  color: #2c3e50;
  font-family: Arial, Helvetica, sans-serif;
}

.w-100 {
  width: 100%;
}

.w-121 {
  width: 121%;
}

.w-50 {
  width: 50%;
  margin-right: 0px;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.w-32 {
  width: 32%;
}
.w-34 {
  width: 34%;
}

.footer {
  margin-top: 10px;
}
.bg-blue {
  background: #409eff;
  color: #ffffff;
}
.font-big {
  font-size: 20px;
}
.text-right {
  text-align: right;
  float: top;
}
.display-flex {
  height: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 83.67%;
  height: auto;
}

/* !!!IMPORTANT Xoá 2 css này trước khi lên official */

.el-divider {
  background-color: #000000;
  position: relative;
}

.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 7px 0;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  position: absolute;
  left: 5px;
  font-family: element-icons;
  content: '\e6da';
  font-size: 12px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<script>
import _ from 'lodash'
import TagSelection from './tag-selection.vue'
import store from 'store'
export default {
  name: 'drawer-text',
  components: { TagSelection },
  props: {
    tags: { type: Array, default: () => [] },
    selectedPromotionIds: { type: Array, default: () => [] },
  },
  async created() {
    const promotionId = []
    promotionId.push(this.$route.query.promotionIds)
    window.TextMovieMasterDrawer = this
    const option = {
      method: 'POST',
      body: { promotionIds: promotionId.flat(), targetType: 'textSet' },
    }

    const { approvalRequests } = await this.$api.authFetch('/approval_request/list', option)

    this.approvalRequests = approvalRequests
  },
  data: () => ({
    displayTypes: [
      { label: 'デフォルト', value: 'default' },
      { label: '顧客承認', value: 'approvalRequest' },
    ],
    submissionStatus: ['未入稿', '入稿依頼済', '入稿済'],
    checkedSubmissionStatus: [],
    // todo: change to common value
    approvalStatus: ['監修未提出', '承認', '監修中', '破棄', '要修正'],
    checkedApprovalStatus: [],
    tagsTemp: [{ tagTypeId: '', tagValue: '', targetType: 'TextMaster' }],
    q: '',
    projectName: '',
    projectNames: [],
    selectedApprovalRequests: [],
    approvalRequests: [],
    lastTimeForm: store.get('textMasterForm'),
  }),
  mounted() {
    if (this.$route.query.textMasterForm) {
      this.form = JSON.parse(this.$route.query.textMasterForm)
      this.$emit('get-texts', this.getFormParams())
    }

    const query = _.reduce(
      this.$route.query,
      (ret, v, k) => {
        if (!k.match('Form')) return _.assign(ret, { [k]: v })
        else if (!k.match('textMasterForm')) return ret
        else return _.assign(ret, { [k]: v })
      },
      {}
    )
    this.$router.push({ query })
  },

  methods: {
    addTag() {
      this.tagsTemp.push({ tagTypeId: '', tagValue: '', targetType: 'TextMaster' })
    },
    removeTag(index) {
      this.tagsTemp = _.chain(this.tagsTemp)
        .cloneDeep()
        .filter((r, i) => i !== index)
        .map(r => _.assign({}, r))
        .value()

      if (this.tagsTemp.length === 0) this.addTag()
    },
    handleProjectNameConfirm() {
      if (this.projectName !== '') {
        this.projectNames.push(this.projectName)
        this.projectName = ''
      }
    },
    removeProjectNameTag(index) {
      this.projectNames.splice(index, 1)
    },
    changeTagInfo(index, value, key) {
      this.tagsTemp = _.map(this.tagsTemp, (r, i) => {
        if (i !== index) return _.assign({}, r)
        else {
          return _.assign({}, r, { [key]: value, ['targetType']: 'TextMaster' })
        }
      })
    },
    search() {
      const form = this.getFormParams()

      store.set('textMasterForm', form)
      this.lastTimeForm = _.cloneDeep(form)

      const query = _.assign(
        {},
        _.omit(this.$route.query, ['originIds', 'textSetIds', 'creativeIds', 'creativeDraftId']),
        { textMasterForm: JSON.stringify(form) }
      )
      this.$router.push({ query })

      this.$emit('search-text-sets', form)
    },
    reset() {
      this.q = ''
      this.projectNames = []
      this.selectedApprovalRequests = []
      this.tagsTemp = [{ tagTypeId: '', tagValue: '', targetType: null }]
      this.checkedApprovalStatus = []
    },
    setLastForm() {
      if (this.lastTimeForm !== null) {
        this.q = this.lastTimeForm.q
        this.projectNames = this.lastTimeForm.projectNames
        this.selectedApprovalRequests = this.lastTimeForm.approvalRequestIds
        this.tagsTemp =
          this.lastTimeForm.tags.length === 0
            ? [{ tagTypeId: '', tagValue: '', targetType: null }]
            : this.lastTimeForm.tags
        this.checkedApprovalStatus = this.lastTimeForm.approvalStatus
      }
    },
    getFormParams() {
      const officialTag = this.tagsTemp.filter(o => o.tagTypeId != '' && o.tagValue != '')
      return {
        q: this.q,
        projectNames: this.projectNames,
        approvalRequestIds: this.selectedApprovalRequests,
        tags: officialTag,
        approvalStatus: this.checkedApprovalStatus,
      }
    },
  },
}
</script>
