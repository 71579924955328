<template>
  <div id="creative-item" class="p-1">
    <el-row class="ps-1 mb-1 vertical-center">
      <el-select
        :value="platformId"
        placeholder="媒体を選択してください"
        clearable
        filterable
        size="small"
        class="mr-1"
        :disabled="!!(platformId && adFormatId)"
        @input="id => $emit('change-platform-id', id)"
      >
        <el-option v-for="(r, i) in platforms" :key="i" :label="r.platformName" :value="r.platformId" />
      </el-select>

      <el-tooltip
        v-if="adFormatSelectValue && adFormatId && countAdFormatName(adFormatSelectValue)"
        class="item"
        effect="dark"
        :content="adFormatSelectValue.toString()"
        placement="top-start"
      >
        <el-select
          :value="adFormatId"
          placeholder="広告フォーマットを選択してください"
          clearable
          filterable
          size="small"
          class="mr-1"
          id="el-select-ad-format-id"
          :disabled="platformId && adFormatId ? true : !platformId"
          @input="changeAdFormatId"
        >
          <el-option
            v-for="(r, i) in filteredAdFormats"
            :key="i"
            :label="(r.oddAiFlag ? '【Odd-AI対応】' : '') + r.name"
            :value="r.id"
          />
        </el-select>
      </el-tooltip>
      <el-select
        v-else
        :value="adFormatId"
        placeholder="広告フォーマットを選択してください"
        clearable
        filterable
        size="small"
        class="mr-1"
        id="el-select-ad-format-id"
        :disabled="platformId && adFormatId ? true : !platformId"
        @input="changeAdFormatId"
      >
        <el-option
          v-for="(r, i) in filteredAdFormats"
          :key="i"
          :label="(r.oddAiFlag ? '【Odd-AI対応】' : '') + r.name"
          :value="r.id"
        />
      </el-select>
      <el-button class="float-left ml-1" size="small" type="info" @click="$emit('copy-creative')"> コピー </el-button>
      <el-button v-if="!!adFormat.id" class="float-left ml-1" size="small" type="info" @click="openTagDialog">
        <i class="fas fa-tag" /> タグ
      </el-button>
      <tag-area
        v-if="!!adFormat.id"
        :tags="selectedTags"
        createdAt="createdAt"
        updatedAt="updateAt"
        creativeType="creative"
        :is-tag-creative-create="true"
      />
    </el-row>
    <el-row>
      <el-col :span="12" :style="{ marginLeft: '-0.5rem' }">
        <asset-select-dialog
          v-if="adFormatId"
          ref="assetSelectDialog"
          @change-selected-assets="(k, v) => $emit('change-selected-assets', k, v)"
        />
        <div v-if="isErrorLabelSize === true">
          <i class="el-icon-warning error-mark fa-xs"></i>
          <span class="error-text">規定違反があります。</span>
          <el-button type="danger" size="mini" @click="checkLabelSize">
            <span style="font-weight: bolder"> 詳細確認 </span>
          </el-button>
        </div>
      </el-col>
      <el-divider class="h-100 center" direction="vertical" />
      <el-col :span="12">
        <el-form ref="form" :model="formTextSets">
          <el-row class="m-1">
            <el-row v-if="formTextSets.errors">
              <el-row v-for="(error, i) in formTextSets.errors" :key="i" class="mb-1 text-red lh-0">
                {{ error }}
              </el-row>
            </el-row>
            <el-form-item v-for="(labeledText, j) in formTextSets.labeledTexts" :key="`labeledText_${j}`">
              <el-row>
                <div class="float-left mr-2 pl-10">
                  {{ labeledText.name }}
                </div>

                <span class="float-left" @click="addLabeledText(j)">
                  <i class="fas fa-plus-circle" />
                </span>
              </el-row>
              <el-row v-if="labeledText.errors.length !== 0">
                <el-row v-for="(error, i) in labeledText.errors" :key="i" class="mb-1 text-red pl-10 lh-0">
                  {{ error }}
                </el-row>
              </el-row>

              <div v-if="labeledText.targetErrors.length !== 0" class="mb-1 pr-1">
                <el-row
                  class="text-red pl-10 lh-0 mb-10"
                  v-show="!labeledText.targetErrors.every(e => !e.includes('商材NGワードとして設定されています。'))"
                >
                  NGワードに変更がある場合は<a :href="getLink()" target="_blank">こちら</a>からご確認お願いいたします。
                </el-row>
                <el-row v-for="(error, l) in labeledText.targetErrors" :key="l" class="text-red pl-10 lh-0">
                  {{
                    error.includes('商材NGワードとして設定されています。')
                      ? error.substring(0, error.indexOf('\\n'))
                      : error
                  }}
                  <el-tooltip
                    placement="bottom"
                    v-show="error.includes('商材NGワードとして設定されています。')"
                    effect="dark"
                  >
                    <i class="el-icon-warning icon-ng" />
                    <div slot="content" class="ff-1" :style="{ whiteSpace: 'pre-line', width: '100%' }">
                      {{ error.substring(error.indexOf('\\n') + 2, error.length).replaceAll('\\n', '\n') }}
                    </div>
                  </el-tooltip>
                </el-row>
              </div>
              <div
                id="text-area"
                class="pr-1"
                v-for="(v, k) in labeledText.textValues"
                :key="`labeledTextValue_${j}_${k}`"
              >
                <el-col class="pl-10" :span="21">
                  <el-row>
                    <div class="float-left w-100">
                      <div v-if="labeledText.ctaPhrases == null">
                        <el-input
                          v-on:input="isDisplayRegulationError"
                          v-model="labeledText.textValues[k]"
                          show-word-limit
                          type="textarea"
                          class="float-left"
                          :class="{
                            'border-red':
                              require('lodash').compact(labeledText.regulationErrors[k]).length !== 0 &&
                              require('lodash').compact(labeledText.unavailableCharsErrors) &&
                              require('lodash').compact(labeledText.unavailableCharsErrors[k]).length !== 0,
                          }"
                          @input="
                            v => {
                              labeledText.textValues[k] = remove_white_and_ctrl_character(v)
                              labeledText.regulationErrors[k] = checkCharacter(
                                remove_white_and_ctrl_character(v),
                                labeledText
                              )
                              labeledText.unavailableCharsErrors[k] = checkUnavailalbleChar(
                                remove_white_and_ctrl_character(v),
                                labeledText
                              )
                              labeledText.crossRuleErrors[k] = checkContinuousTextMaximumLimit(
                                v,
                                formTextSets.adFormat.crossRule,
                                labeledText
                              )
                              formTextSets.errors = setTextSetError(
                                formTextSets,
                                checkC(formTextSets.adFormat.crossRule, formTextSets.labeledTexts)
                              )
                            }
                          "
                        />
                      </div>
                      <div v-else>
                        <el-select
                          v-model="labeledText.textValues[k]"
                          style="width: 100%"
                          clearable
                          placeholder="ご選択ください。"
                        >
                          <el-option v-for="item in labeledText.ctaPhrases" :key="item" :label="item" :value="item">
                          </el-option>
                        </el-select>
                      </div>
                    </div>

                    <span v-if="labeledText.textValues.length > 1" class="badge lh-0" @click="removeTextArea(j, k)">
                      <i class="fas fa-times-circle icon-red" />
                    </span>
                  </el-row>

                  <el-row v-if="labeledText.targetErrors.length === 0" class="mt-1">
                    <el-row v-if="labeledText.regulationErrors[k].includes('overLength')" class="text-red lh-2">
                      <span>文字数がオーバーしています。</span>
                      <span v-if="labeledText.containParameter"
                        >広告カスタマイザを使用している場合はそのまま保存ボタンを押してください。</span
                      >
                    </el-row>

                    <el-row
                      v-if="labeledText.regulationErrors[k] && labeledText.regulationErrors[k].includes('underLength')"
                      class="text-red lh-2"
                    >
                      <span>文字数は{{ labeledText.minCharLength }}文字以上でなければいけません。</span>
                      <span v-if="labeledText.containParameter"
                        >広告カスタマイザを使用している場合はそのまま保存ボタンを押してください。</span
                      >
                    </el-row>

                    <el-row v-if="labeledText.regulationErrors[k].includes('lineBreak')" class="text-red lh-2">
                      改行は禁止です
                    </el-row>

                    <el-row v-if="labeledText.regulationErrors[k].includes('emoji')" class="text-red lh-2">
                      絵文字は禁止です
                    </el-row>

                    <el-row v-if="labeledText.regulationErrors[k].includes('kana')" class="text-red lh-2">
                      半角カナは禁止です
                    </el-row>

                    <el-row v-if="labeledText.regulationErrors[k].includes('fullWidth')" class="text-red lh-2">
                      全角文字は禁止です
                    </el-row>
                    <el-row
                      v-if="labeledText.unavailableCharsErrors && labeledText.unavailableCharsErrors[k].length !== 0"
                      class="text-red lh-2"
                    >
                      「{{ labeledText.unavailableCharsErrors[k].join(', ') }}」は使用不可文字として登録されています。
                    </el-row>
                  </el-row>
                </el-col>

                <el-col :span="3">
                  <div
                    v-if="labeledText.ctaPhrases == null"
                    class="lh-1 text-right pl-10"
                    :class="{
                      'text-gray': !labeledText.regulationErrors[k].includes('overLength'),
                      'text-red': labeledText.regulationErrors[k].includes('overLength'),
                    }"
                  >
                    <span>{{ countStrLength(labeledText.textValues[k], labeledText) }}</span>
                    <span> / {{ labeledText.maxCharLength ? labeledText.maxCharLength : 'なし' }}</span>
                  </div>
                </el-col>
              </div>
            </el-form-item>
            <!--              </el-row>-->
          </el-row>
        </el-form>
      </el-col>
    </el-row>

    <el-row class="mt-1">
      <reference-url-area
        ref="referenceUrlArea"
        :referenceUrls="referenceUrls"
        @update-reference-urls="urls => this.$emit('update-reference-urls', urls)"
      />
      <div>
        <h4 style="font-size: 14px; margin-bottom: 8px">
          <i class="fa-regular fa-note-sticky"></i>
          備考
        </h4>
        <el-input type="textarea" :rows="2" placeholder="備考" :value="note" @input="v => $emit('change-note', v)" />
      </div>
    </el-row>
    <tag-dialog
      ref="tagDialog"
      :selected-promotion-ids="selectedPromotionIds"
      :tags="tags"
      :tagTypes="tagTypes"
      @reload="$emit('reload')"
      @add-tag-creative="t => addTagCreative(t)"
    />
  </div>
</template>

<style>
#text-area .border-red .el-textarea__inner {
  border: 0;
}
#creative-item .el-divider--vertical {
  margin: 0;
}
</style>

<style scoped>
.border-red {
  border: 1px solid #f56c6c;
  border-radius: 4px;
  box-sizing: border-box;
}
.text-red {
  color: #f56c6c;
}
.text-gray {
  color: #909399;
}
.el-form-item {
  margin-bottom: 0px;
}

.pl-10 {
  padding-left: 10px;
}

.vertical-center {
  display: flex;
  align-items: flex-start;
}

.float-left {
  float: left;
}

.icon-red {
  color: #f56c6c;
}

.lh-0 {
  line-height: initial;
}

.lh-1 {
  line-height: 3.5rem;
}

.lh-2 {
  line-height: 1.5rem;
}

.m-1 {
  margin: 1rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mr-2 {
  margin-right: 1rem;
}

.p-1 {
  padding: 1rem;
}

.pt-1:first-child {
  padding-top: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pr-2 {
  padding-right: 5px;
}

.pl-1 {
  padding-left: 1rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 10px;
}

.float-left {
  float: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.mt-1 {
  margin-top: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.ml-1 {
  margin-left: 10px;
}

.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 2rem;
}

.p-1 {
  padding: 1rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pr-2 {
  padding-right: 5px;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.badge {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 2;
}
</style>

<script>
import _, { clone } from 'lodash'
import util from '@/mixins/util'
import AssetSelectDialog from '@/views/creative-regist-only/creative-item/asset-area/asset-select-dialog'
import TagArea from '@/views/creative-list/ad-format-tab-list/creative-card/main-area/components/tag-area'
import TagDialog from '@/components/tag-dialog'
import ReferenceUrlArea from '@/components/reference-url-area'

export default {
  name: 'creative-item',
  mixins: [util],
  components: {
    AssetSelectDialog,
    TagArea,
    TagDialog,
    ReferenceUrlArea,
  },
  props: {
    defaultPromotionId: { type: String, default: () => '' },
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
    platformId: { type: String, default: () => '' },
    adFormatId: { type: String, default: () => '' },
    type: { type: String, default: () => '' },
    selectedAssets: { type: Array, default: () => [] },
    selectedTextSets: { type: Array, default: () => [] },
    assetErrors: { type: Array, default: () => [] },
    note: { type: String, default: () => '' },
    referenceUrls: { type: Array, default: () => [''] },
    textErrors: { type: Array, default: () => [] },
    textLabels: { type: Array, default: () => [] },
    tagTypes: { type: Array, default: () => [] },
    tagsSelected: { type: Array, default: () => [] },
  },
  data: () => {
    const numberRule = { type: 'number', message: '数字のみ有効です', trigger: 'change' }
    const rules = { adAccountId: [numberRule], adGroupId: [numberRule] }
    const form = { adAccountId: null, adGroupId: null, projectName: null }
    return {
      textType: 'Asset',
      isShow: false,
      isCreative: false,
      loading: false,
      adFormat: {},
      formTextSets: {},
      isErrorLabelSize: false,
      errorLabelSize: [],
      rules,
      form,
      isError: false,
      adFormatElipsis: { type: Boolean, default: false },
      adFormatSelectValue: { type: String, default: '' },
      selectedTags: [],
    }
  },
  created() {
    window.CreativeItem = this
    this.selectedTags = this.tagsSelected
    if (this.selectedAssets.length > 0 || this.selectedTextSets.length > 0) {
      this.show()
    }
  },
  methods: {
    removeTextArea(j, k) {
      this.removeLabeledText(j, k)
      const cloneFormTextSets = clone(this.formTextSets)
      cloneFormTextSets.labeledTexts.forEach(labeledText => {
        const isCtaPhrases = labeledText?.ctaPhrases !== undefined
        labeledText.regulationErrors = []
        labeledText.unavailableCharsErrors = []
        labeledText.crossRuleErrors = []
        labeledText.textValues.forEach((v, k) => {
          if (isCtaPhrases) {
            labeledText.regulationErrors[k] = []
            labeledText.unavailableCharsErrors[k] = []
            labeledText.crossRuleErrors[k] = []
          } else {
            labeledText.regulationErrors[k] = this.checkCharacter(this.remove_white_and_ctrl_character(v), labeledText)
            labeledText.unavailableCharsErrors[k] = this.checkUnavailalbleChar(
              this.remove_white_and_ctrl_character(v),
              labeledText
            )
            labeledText.crossRuleErrors[k] = this.checkContinuousTextMaximumLimit(
              v,
              this.formTextSets.adFormat.crossRule,
              labeledText
            )
          }
        })
      })
      cloneFormTextSets.errors = this.setTextSetError(
        cloneFormTextSets,
        this.checkC(cloneFormTextSets.adFormat.crossRule, cloneFormTextSets.labeledTexts)
      )
      this.formTextSets = cloneFormTextSets
    },
    changeAdFormatId(id) {
      this.$emit('change-ad-format-id', id)
      this.$nextTick(() => {
        const selectAdformat = this.filteredAdFormats.find(r => r.id === id)
        this.adFormatSelectValue = (selectAdformat.oddAiFlag ? '【Odd-AI対応】' : '') + selectAdformat.name
      })
    },

    show() {
      this.isCreative = true
      this.form = { adAccountId: null, adGroupId: null, projectName: null }
      this.formTextSets = {
        adFormat: this.adFormat || { platformId: '', id: '' },
        labeledTexts:
          this.selectedTextSets.length > 0
            ? this.asyncLabeledTexts()
            : this.adFormat
            ? this.searchLabeledTexts(this.adFormat.id)
            : [],
        score: null,
        comment: null,
        errors: [],
        targetErrors: [],
        tags: [],
        noTags: false,
      }
      this.isShow = true
    },

    changeSelectedAssets({ labeledAssets, index, isSplitAsset }) {
      if (isSplitAsset) {
        const first = _.find(labeledAssets, r => r.selected.length !== 0) || _.first(labeledAssets)
        const selectedAssets = _.chain(this.selectedAssets)
          .map((gr, i) => {
            if (i !== index) return _.map(gr, r => r.selected)
            else return _.map(gr, () => [])
          })
          .flattenDeep()
          .concat(first.selected)
          .uniqBy('originId')
          .map(r => [_.assign({}, first, { selected: [r] })])
          .value()

        this.$emit('change-selected-assets', selectedAssets)
      } else if (!this.selectedAssets[index]) {
        const selectedAssets = _.concat(this.selectedAssets, [labeledAssets])
        this.$emit('change-selected-assets', selectedAssets)
      } else {
        const keyByLabeledAssets = _.keyBy(labeledAssets, 'id')
        const targetAssets =
          this.selectedAssets[index].length < labeledAssets.length ? labeledAssets : this.selectedAssets[index]
        const tmpLabeledAssets = _.chain(targetAssets)
          .map(r => {
            const selected = _.chain(keyByLabeledAssets).result(`[${r.id}].selected`).uniqBy('originId').value()
            if (selected.length === 0) return

            return _.assign({}, r, { selected })
          })
          .filter()
          .value()

        const tmpSelectedAssets = _.cloneDeep(this.selectedAssets)
        tmpSelectedAssets[index] = tmpLabeledAssets

        this.$emit('change-selected-assets', _.every(tmpSelectedAssets, _.isEmpty) ? [] : tmpSelectedAssets)
      }
    },
    removeSelected(index) {
      const selectedAssets = _.filter(this.selectedAssets, (r, i) => i !== index)
      this.$emit('change-selected-assets', selectedAssets)
    },

    checkLabelSize() {
      if (this.errorLabelSize.length !== 0) {
        this.$alert(this.errorLabelSize.join(''), '必須チェック', { dangerouslyUseHTMLString: true })
        return
      }
    },
    log(mess, data) {
      console.log(mess, data)
    },
    getLink() {
      return `${this.$api.getRegulationDomain()}/advertisers`
    },
    cancelTag(index) {
      this.formTextSets = _.map(this.formTextSets, (r, i) => (i === index ? _.assign({}, r, { tags: [] }) : r))
    },
    cancelNoTag(index) {
      this.formTextSets = _.map(this.formTextSets, (r, i) => (i === index ? _.assign({}, r, { noTags: false }) : r))
    },
    setTag(tag) {
      this.formTextSets = _.map(this.formTextSets, r => _.assign({}, r, { tags: [tag], noTags: false }))
    },
    setNoTag() {
      this.formTextSets = _.map(this.formTextSets, r => _.assign({}, r, { tags: [], noTags: true }))
    },
    copyText(textSet) {
      const isEmpty = _.chain(textSet.labeledTexts).map('textValues').flattenDeep().compact().isEmpty().value()

      if (isEmpty) this.$message.warning('入力文字がないのでコピーできません')
      else this.formTextSets.push(_.cloneDeep(textSet))
    },
    addTextSet() {
      this.formTextSets = this.formTextSets.concat([
        {
          adFormat: this.isCreative
            ? _.chain(this.formTextSets).cloneDeep().result('[0].adFormat').value()
            : {
                platformId: '',
                id: '',
              },
          labeledTexts: this.isCreative ? this.searchLabeledTexts(this.adFormat.id) : [],
          score: null,
          comment: null,
          errors: [],
          targetErrors: [],
          tags: [],
          noTags: false,
        },
      ])
    },
    addLabeledText(j) {
      this.formTextSets.labeledTexts = _.chain(this.formTextSets.labeledTexts)
        .cloneDeep()
        .map((r2, index2) => {
          if (index2 !== j) return r2
          else if (r2.maxLength <= r2.textValues.length) {
            this.$message.warning(`${r2.name}はこれ以上追加できません`)
            return r2
          } else {
            if (!r2.unavailableCharsErrors) r2.unavailableCharsErrors = Array(r2.textValues.length).fill([])
            return _.assign({}, r2, {
              textValues: _.cloneDeep(r2.textValues).concat(['']),
              regulationErrors: _.cloneDeep(r2.regulationErrors).concat([[]]),
              crossRuleErrors: _.cloneDeep(r2.crossRuleErrors).concat([[]]),
              unavailableCharsErrors: _.cloneDeep(r2.unavailableCharsErrors).concat([[]]),
            })
          }
        })
        .value()
    },
    openTagDialog() {
      const tags = {}
      this.selectedTags
        .map(x => x.tagId)
        .map(tag => {
          tags[tag] = {
            isAllSelected: true,
            selectedTargetIds: [],
          }
        })
      const params = { targetType: 'createCreative', selectedTags: tags, targetIds: [] }
      this.$refs.tagDialog.show(params)
    },
    removeLabeledText(j, k) {
      this.formTextSets.labeledTexts = _.chain(this.formTextSets.labeledTexts)
        .cloneDeep()
        .map((r2, index2) => {
          if (index2 !== j) return r2
          const textValues = _.filter(r2.textValues, (r3, index3) => index3 !== k)
          const crossRuleErrors = _.filter(r2.crossRuleErrors, (r3, index3) => index3 !== k)
          return _.assign({}, r2, { textValues }, { crossRuleErrors })
        })
        .value()
    },
    removeTextSet(index) {
      this.formTextSets = _.filter(this.formTextSets, (r, i) => i !== index)
    },
    isIncludesEmptyTextValues(gr) {
      return (
        _.filter(gr, ({ labeledTexts }) =>
          labeledTexts.length === 0
            ? false
            : !_.find(labeledTexts, ({ textValues, minLength, containParameter }) =>
                containParameter || !minLength
                  ? false
                  : _.chain(textValues).take(minLength).compact().value().length < minLength
              )
        ).length === 0
      )
    },

    removeCreative() {
      if (this.$refs.assetSelectDialog) {
        this.$refs.assetSelectDialog.selected = []
        this.$refs.assetSelectDialog.distributedSelected = []
      }
    },

    copyCreative(i) {
      const creative = _.result(this.creatives, `[${i}]`) || {}

      if (!creative.adFormatId && creative.selectedAssets.length === 0 && creative.selectedTextSets.length === 0) {
        this.$message.warning('入力がないのでコピーできません')
        return
      }

      this.creatives.push(_.cloneDeep(creative))
    },

    async regist() {
      const a = this.formTextSets.labeledTexts
        .map(x => x.textValues)
        .flat()
        .filter(x => x !== '')
      if (a.length === 0) return ''

      const form = Object.assign(
        {
          promotionId: this.defaultPromotionId,
          adFormatId: this.formTextSets.adFormat.id,
          textType: this.textType,
          labeledTexts: _.map(this.formTextSets.labeledTexts, ({ id, textValues }) => ({
            labelId: id,
            textValues: _.filter(textValues),
          })),
        },
        this.form.projectName ? { projectName: this.form.projectName } : {}
      )

      this.loading = true
      const results = await this.$api.authFetch('/text_set', { method: 'POST', body: form })
      this.loading = false

      const x = []
      x.push(this.formTextSets)

      // if (errorResults) {
      this.formTextSets = _.map(x, r => {
        const tmp = _.assign(
          {},
          this.formTextSets,
          !results.errors
            ? {}
            : {
                errors: results.errors.map(x => {
                  return x.labelNames.join(', ').concat(`: ${x.error}`)
                }),
              }
        ) // ケース①
        if (results.errors === 'undefined') return _.assign({}, tmp, r)

        const labeledTexts = _.map(tmp.labeledTexts, r2 => {
          const label = _.find(results.labels, r3 => r3.labelId === r2.id)
          if (!label) return _.assign({}, r2, { targetErrors: [] })

          const tmp2 = _.assign({}, r2, label.errors.length === 0 ? {} : { errors: label.errors }) // ケース②
          if (label.targets.length === 0) return _.assign({}, tmp2, r2)

          const targetErrors = _.chain(tmp2.textValues)
            .map((v, i) =>
              _.chain(label.targets)
                .find(({ key }) => {
                  const removeLabelNameKey = key.replace(r2.name + ': ', '')
                  return removeLabelNameKey.substring(0, removeLabelNameKey.lastIndexOf('_')) === v
                })
                .result('errors')
                .map(v => `${i + 1}番目: ${v.replace(r2.name + ': ', '')}`)
                .value()
            )
            .filter(gr => gr.length !== 0)
            .flattenDeep()
            .value()

          return _.assign({}, tmp2, { targetErrors }) // ケース③
        })
        return _.assign({}, tmp, { labeledTexts })
      })[0]

      this.isShow = false
      if (results.error === '同じ内容のテキストが既に存在しています') {
        return results.data
      }
      return results
    },
    async checkRules() {
      const a = this.formTextSets.labeledTexts
        .map(x => x.textValues)
        .flat()
        .filter(x => x !== '')
      if (a.length === 0) return ''

      const form = Object.assign(
        {
          promotionId: this.defaultPromotionId,
          adFormatId: this.formTextSets.adFormat.id,
          checkType: this.textType,
          labeledTexts: _.map(this.formTextSets.labeledTexts, ({ id, textValues }) => ({
            labelId: id,
            textValues: _.filter(textValues),
          })),
        },
        this.form.projectName ? { projectName: this.form.projectName } : {}
      )

      this.loading = true
      const results = await this.$api.authFetch('/text_set/regulate', { method: 'POST', body: form })
      this.loading = false

      const x = []
      x.push(this.formTextSets)

      this.formTextSets = _.map(x, r => {
        const tmp = _.assign(
          {},
          this.formTextSets,
          !results.errors
            ? {}
            : {
                errors: results.errors.map(x => {
                  return x.labelNames.join(', ').concat(`: ${x.error}`)
                }),
              }
        ) // ケース①
        if (results.errors === 'undefined') return _.assign({}, tmp, r)

        const labeledTexts = _.map(tmp.labeledTexts, r2 => {
          const label = _.find(results.labeledTexts, r3 => r3.labelId === r2.id)
          if (!label) return _.assign({}, r2, { targetErrors: [] })

          const tmp2 = _.assign({}, r2, label.errors.length === 0 ? {} : { errors: label.errors }) // ケース②
          if (label.targets.length === 0) return _.assign({}, tmp2, r2)

          const targetErrors = _.chain(tmp2.textValues)
            .map((v, i) =>
              _.chain(label.targets)
                .find(({ textValues }) => textValues === v)
                .result('errors')
                .map(v => `${i + 1}番目: ${v.replace(r2.name + ': ', '')}`)
                .value()
            )
            .filter(gr => gr.length !== 0)
            .flattenDeep()
            .value()

          return _.assign({}, tmp2, { targetErrors }) // ケース③
        })
        return _.assign({}, tmp, { labeledTexts })
      })[0]

      this.isShow = false
      if (results.error === '同じ内容のテキストが既に存在しています') {
        return results.data
      }
      return results
    },
    filterAdFormats(platformId) {
      return _.chain(this.adFormats)
        .filter(r => r.platformId === platformId)
        .uniqBy('id')
        .orderBy()
        .value()
    },
    searchLabeledTexts(adFormatId) {
      return _.chain(this.adFormats)
        .find(r => r.id === adFormatId)
        .result('textLabels')
        .map(r =>
          _.assign({}, r, {
            textValues: [''],
            regulationErrors: [''],
            errors: [],
            targetErrors: [],
            unavailableCharsErrors: [[]],
            crossRuleErrors: [],
          })
        )
        .orderBy(['labelIndex'])
        .value()
    },
    asyncLabeledTexts() {
      return _.chain(this.selectedTextSets).orderBy(['labelIndex']).value()
    },
    changePlatform(platformId, index) {
      this.formTextSets = _.map(this.formTextSets, (r, i) => {
        if (i !== index) return _.assign({}, r)
        else return _.assign({}, r, { adFormat: { platformId, id: '' }, labeledTexts: [] })
      })
    },
    // isIncludeOddAiFormat() {
    //   return !!_.find(this.formTextSets, r => this.checkOddAiFormat(r.adFormat.platformId, r.adFormat.name))
    // },
    isDisplayRegulationError() {
      const labeledTexts = this.formTextSets.labeledTexts
      this.isError =
        labeledTexts.length === 0
          ? false
          : _.chain(labeledTexts)
              .find(({ regulationErrors, containParameter, unavailableCharsErrors }) =>
                containParameter
                  ? false
                  : !_.chain(regulationErrors).flattenDeep().isEmpty().value() ||
                    !_.chain(unavailableCharsErrors).flattenDeep().isEmpty().value()
              )
              .value()
    },
    addTagCreative(attachTag) {
      this.selectedTags = this.tags.filter(x => _.includes(attachTag, x.tagId))
      this.$emit('change-selected-tag', this.selectedTags)
    },
    setTextSetError(textSet, creativeRule) {
      let ret = []
      let error = _.map(textSet.labeledTexts, label => _.map(label.crossRuleErrors, error => error))
      _.map(error, x => _.map(x, errors => _.map(errors, err => ret.push(err))))
      return _.concat(_.uniqBy(ret), creativeRule)
    },
  },
  watch: {
    tagsSelected() {
      this.selectedTags = this.tagsSelected
    },
    adFormat: {
      handler() {
        return (
          _.find(this.adFormats, r => r.id === this.adFormatId) || {
            assetLabels: [],
            textLabels: [],
          }
        )
      },
      immediate: true,
    },
    platformId() {
      if (this.platformId === 'FBA') this.$emit('change-note', 'マスク対応:　カルーセル最適化:')
      else if (this.platformId === 'GAW' || this.platformId === 'YSS')
        this.$emit('change-note', '※リンク先URLを入力してください※')
      else this.$emit('change-note', '')
    },
    platformName() {
      this.$emit('change-platform-name', this.platformName)
    },
    adFormatName() {
      this.$emit('change-ad-format-name', this.adFormatName)
    },
    selectedAssets() {
      const containZip = _.chain(this.selectedAssets[0].labeledAssets)
        .map(r => r.selected)
        .flatten()
        .map(r => r.metadata.extension)
        .value()
        .includes('zip')
      if (!this.selectedAssets[0].isSplitAsset && !containZip) {
        const errors = _.chain(this.selectedAssets[0].labeledAssets)
          .flatMap(r => {
            let carouselError = null
            if (r.selected.length > r.maxFileNumber)
              carouselError = `<div>${r.name}: 素材は${r.maxFileNumber}つ以下で選択してください</div>`
            return [carouselError]
          })
          .filter()
          .value()
        if (errors.length !== 0) {
          this.isErrorLabelSize = true
          this.errorLabelSize = errors
        } else {
          this.isErrorLabelSize = false
          this.errorLabelSize = []
        }
      }
    },
    isErrorLabelSize() {
      if (this.isErrorLabelSize == true) {
        this.$emit('change-error-labelSize', true)
      } else {
        this.$emit('change-error-labelSize', false)
      }
    },
    adFormatId: {
      async handler() {
        if (this.adFormatId !== '') {
          const adformats = await this.$api.authFetch('/adformats', { method: 'GET' })
          this.adFormat = adformats.adFormats.find(element => element.id === this.adFormatId)
          this.show()

          this.$refs.assetSelectDialog.show(
            this.selectedAssets.length,
            this.selectedPromotionIds,
            this.tags,
            this.adFormatId,
            this.adFormat.assetLabels,
            this.selectedAssets
          )
        }
      },
      immediate: true,
    },

    assetErrors() {
      this.$refs.assetSelectDialog.show(
        this.selectedAssets.length,
        this.selectedPromotionIds,
        this.tags,
        this.adFormatId,
        this.adFormat.assetLabels,
        this.selectedAssets,
        this.assetErrors[0]
      )
      const error = this.assetErrors[0].map(x => x.errors[0]).filter(x => x != undefined)
      if (error.length != 0) {
        this.isErrorLabelSize = true
        this.errorLabelSize = error
      } else {
        this.isErrorLabelSize = false
        this.errorLabelSize = []
      }
    },

    formTextSets: {
      handler(newVal) {
        const isTextRegistered =
          newVal.labeledTexts
            .map(x => x.textValues)
            .flat()
            .filter(x => x !== '').length !== 0 && newVal.errors.length === 0

        this.$emit('async-selected-text-sets', newVal.labeledTexts)
        this.$emit('change-selected-text-sets', isTextRegistered)
      },
      deep: true,
    },
  },
  computed: {
    platforms() {
      return _.chain(this.adFormats)
        .filter(r => r.type === 'Asset')
        .uniqBy('platformId')
        .orderBy(r => _.toLower(r.platformName))
        .value()
    },
    filteredAdFormats() {
      return _.chain(this.adFormats)
        .filter(r => (!this.platformId ? true : r.platformId === this.platformId && r.type === 'Asset'))
        .uniqBy('id')
        .orderBy()
        .value()
    },
    platformName() {
      return _.chain(this.adFormats)
        .find(r => this.platformId === r.platformId)
        .result('platformName')
        .value()
    },
    adFormatName() {
      return _.chain(this.adFormats)
        .find(r => this.adFormatId === r.id)
        .result('name')
        .value()
    },
  },
}
</script>
