<template>
  <el-dialog
    id="tag-dialog"
    title="タグ編集"
    lock-scroll
    fullscreen
    :before-close="close"
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div :style="{ height: height + 'px' }" v-loading="loading" class="p-1">
      <search-form
        :default-promotion-id="selectedPromotionIds[0]"
        :is-show="isShow"
        :tag-types="tagTypes"
        @search="search"
        @reload="$emit('reload')"
      />

      <tag-table
        ref="tagTable"
        :default-promotion-id="selectedPromotionIds[0]"
        :is-show="isShow"
        :filtered-tags="filteredTags"
        :tag-types="tagTypes"
        :selected-tags="selectedTags"
        :target-ids="targetIds"
        @reload="$emit('reload')"
        @change-selected="
          gr => {
            selected = gr
          }
        "
      />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save"> 保存 </el-button>
    </span>
  </el-dialog>
</template>

<style>
#tag-dialog .el-dialog__wrapper {
  overflow: visible;
}
</style>

<style scoped>
.p-1 {
  padding: 30px;
}
</style>

<script>
import _ from 'lodash'
import SearchForm from '@/components/tag-dialog/components/search-form'
import TagTable from '@/components/tag-dialog/components/tag-table'

export default {
  name: 'tag-dialog',
  components: { SearchForm, TagTable },
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    tagTypes: { type: Array, default: () => [] },
  },
  data: () => ({
    loading: false,
    isInDialog: false,
    isShow: false,
    form: { tagTypeId: '', tagValue: '' },
    selected: [],
    selectedTags: [],
    targetIds: [],
    targetType: '',
    height: window.innerHeight - 34 - 70 - 60,
  }),
  created() {
    window.TagDialog = this
  },
  methods: {
    show({ targetType, selectedTags, targetIds, isInDialog }) {
      this.targetType = targetType
      this.selectedTags = selectedTags
      this.targetIds = targetIds
      this.form = { tagTypeId: '', tagValue: '' }
      this.isInDialog = isInDialog
      this.isShow = true
    },
    search(form) {
      this.form = _.cloneDeep(form)
    },
    async close() {
      const first = _.first(this.$refs.tagTable.allTags)
      if (first && (!first.tagTypeName || !first.tagValue)) {
        const ret = await this.$confirm('入力中のタグがありますが、本当に閉じてもよいですか?', '確認', {
          confirmButtonText: 'OK',
          cancelButtonText: 'キャンセル',
          type: 'warning',
        }).catch(e => e)

        if (ret === 'confirm') this.isShow = false
      } else this.isShow = false
    },
    async save() {
      const first = _.first(this.$refs.tagTable.allTags)
      if (first && (!first.tagTypeName || !first.tagValue)) {
        const ret = await this.$confirm('入力中のタグがありますが、そのままタグの紐付けをしてもよいですか?', '確認', {
          confirmButtonText: 'OK',
          cancelButtonText: 'キャンセル',
          type: 'warning',
        }).catch(e => e)

        if (ret === 'cancel') return
      }
      if (this.targetType === 'createCreative') {
        const body = []
        this.selected.map(tag => {
          body.push(tag.tagId)
        })
        this.$emit('add-tag-creative', body)
        this.isShow = false
      } else {
        const body = {
          selectedTargetIds: this.targetIds,
          targetType: this.targetType,
          tagSettings: this.selected,
        }

        this.loading = true
        await this.$api.authFetch('/tag/set', { method: 'PUT', body }).finally(() => {
          this.loading = false
        })
        this.isShow = false

        if (this.$route.path === '/creative-list' || this.targetType === 'creative') {
          const creativeListForm = this.$route.query.creativeListForm
          const params = _.isEmpty(window.CreativeList.query)
            ? creativeListForm
              ? window.CreativeList.$refs.drawer.getFormParams()
              : undefined
            : window.CreativeList.query

          await window.CreativeList.getCreatives(params)
        } else if (this.targetType === 'asset') {
          const params = window.ImageMovieMasterDrawer.getFormParams()
          await window.ImageMovieMaster.getAssets(params)
        } else if (this.targetType === 'textSet') {
          if (this.isInDialog) window[this.isInDialog].isShow = false
          const params = window.TextMovieMasterDrawer.getFormParams()
          await window.TextMaster.getTextSets(params)
        }
      }
    },
  },
  computed: {
    filteredTags() {
      const { tagTypeId, tagValue } = this.form
      return _.chain(this.tags)
        .cloneDeep()
        .filter(r => {
          const tagTypeRet = tagTypeId ? r.tagTypeId === tagTypeId : true
          const tagValueRet = tagValue
            ? _.chain(tagValue)
                .split(/\s/)
                .map(
                  v =>
                    !!(r.tagValue || '').match(v) ||
                    !!(r.tagTypeName || '').match(v) ||
                    !!(r.description || '').match(v)
                )
                .compact()
                .value().length !== 0
            : true
          return tagTypeRet && tagValueRet
        })
        .value()
    },
  },
}
</script>
