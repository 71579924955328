<template>
  <el-dialog
    id="submission-draft-dialog"
    custom-class="small-dialog"
    title="入稿依頼作成"
    lock-scroll
    append-to-body
    width="90%"
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div v-loading="loading">
      <el-form class="p-1">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item id="checkbox" label="入稿依頼先">
              <el-row class="w-100" type="flex" justify="space-around">
                <el-col :span="11">
                  <el-row class="mb-1"><span>TO</span> </el-row>

                  <el-row>
                    <div class="mb-1">検索</div>

                    <el-select
                      v-model="form.to"
                      class="mb-2 w-100"
                      v-on:change="addEmailToBox($event, 'to')"
                      filterable
                    >
                      <el-option v-for="(r, i) in employees" :key="i" :label="r.name" :value="r.id" />
                    </el-select>
                  </el-row>

                  <el-row>
                    <div class="mb-1">手動入力</div>

                    <el-input-tag
                      placeholder="追加したいEmailを入力してください"
                      v-model="optionEmail.to"
                      v-on:input="validateMail($event, 'to')"
                    />
                  </el-row>
                </el-col>

                <el-col :span="11">
                  <el-row class="mb-1">CC</el-row>

                  <el-row>
                    <div class="mb-1">検索</div>

                    <el-select
                      v-model="form.cc"
                      class="mb-2 w-100"
                      v-on:change="addEmailToBox($event, 'cc')"
                      filterable
                    >
                      <el-option v-for="(r, i) in employees" :key="i" :label="r.name" :value="r.id" />
                    </el-select>
                  </el-row>

                  <el-row>
                    <div class="mb-1">手動入力</div>

                    <el-input-tag
                      placeholder="追加したいEmailを入力してください"
                      v-model="optionEmail.cc"
                      v-on:input="validateMail($event, 'cc')"
                    />
                  </el-row>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="入稿依頼名 ※デフォルトで「日付_媒体_入稿依頼作成者」が挿入されます">
              <el-input v-model="form.requestName" />
            </el-form-item>

            <el-form-item label="入稿キャンペーンのWEB/APP" style="margin-top: 20px">
              <div style="color: red" v-if="this.webAppError">入稿キャンペーンのWEB/APPを選択してください。</div>
              <el-radio-group class="w-100" v-model="form.webapp" style="transform: translateY(-50%)">
                <el-radio label="WEB" />
                <el-radio label="APP" />
              </el-radio-group>
            </el-form-item>

            <el-form-item label="注意事項" style="margin-top: 5px">
              <el-radio-group class="w-100" v-model="form.addition" style="transform: translateY(-50%)">
                <el-radio label="期間限定" />
                <el-radio label="配信メニュー指定" />
                <el-radio label="その他" />
              </el-radio-group>
            </el-form-item>

            <el-form-item v-if="form.addition === 'その他'" label="">
              <el-input type="textarea" v-model="form.additionText" />
            </el-form-item>

            <el-form-item label="備考">
              <el-input type="textarea" :rows="9" v-model="form.note" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        :disabled="optionEmail.to.length === 0 || isInvalidEmailTo || isInvalidEmailCc"
        @click="saveDraft"
      >
        入稿依頼
      </el-button>
    </span>

    <complete-draft-dialog ref="completeDraftDialog" />
  </el-dialog>
</template>

<style>
#submission-draft-dialog .el-form-item {
  margin-bottom: initial;
}
#submission-draft-dialog #checkbox .el-form-item__content {
  line-height: 20px;
}
#submission-draft-dialog .el-card__body {
  padding: 10px;
}
.el-input-tag .tag-input {
  margin-top: 4px;
  width: 100% !important;
}
.el-input-tag .el-tag {
  margin-top: 4px;
}
::placeholder {
  color: #caced5;
  opacity: 1; /* Firefox */
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #caced5;
}
</style>

<style scoped>
.required-tag {
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 42px;
  background-color: #f56c6c;
  color: #fff;
  font-size: 12px;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  border-radius: 7px;
  padding-left: 10px;
  -webkit-clip-path: polygon(15px 0%, 100% 0%, 100% 100%, 15px 100%, 0 50%);
  clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0 50%);
  line-height: 19px;
}
.el-input-tag {
  min-height: 150px !important;
}

.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
.p-1 {
  padding: 1rem;
}
.ps-1 {
  padding: 0 1rem;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin-bottom: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.w-100 {
  width: 100%;
}
.h-100px {
  height: 100px;
}
</style>

<script>
import _ from 'lodash'
import URI from 'urijs'
import moment from 'moment'
import CompleteDraftDialog from '@/views/creative-regist/components/complete-draft-dialog'
import { ElInputTag } from '@/views/creative-list/components/el-input-tag/src'

export default {
  name: 'submission-draft-dialog',
  components: {
    CompleteDraftDialog,
    ElInputTag,
  },
  props: {
    creatives: { type: Array, default: () => [] },
  },
  data: () => ({
    isShow: false,
    loading: false,
    form: { requestName: '', note: '', webapp: '', addition: '', additionText: '', to: [], cc: [] },
    employees: [],
    optionEmail: { to: '', cc: '' },
    isInvalidEmailTo: false,
    isInvalidEmailCc: false,
    webAppError: false,
  }),
  async created() {
    window.SubmissionDraftDialog = this

    this.loading = true
    this.employees = _.map(await this.$gAuth.getEmployees(), r => ({ id: r.email, name: r.name }))
    this.loading = false
  },
  methods: {
    async show() {
      const userName = await this.$gAuth.getName()
      const platformIds = _.chain(this.creatives).map('platformId').uniq().join('-').value()
      const submissionRequestName = `${moment().format('YYYYMMDD')}_${platformIds}_${userName}`
      this.isShow = true
      this.form = {
        requestName: submissionRequestName,
        note: '',
        webapp: '',
        addition: '',
        to: [],
        cc: [],
      }
      this.optionEmail = { to: '', cc: '' }
    },
    async saveDraft() {
      if (this.optionEmail.to.length === 0) {
        this.$message.error('TOの選択は必須です。')
        return
      }

      const option = { method: 'POST', body: { creativeDraft: this.creatives } }
      this.loading = true
      const [{ creativeDraftId }, userName] = await Promise.all([
        this.$api.authFetch('/creative/draft', option),
        this.$gAuth.getName(),
      ])

      const platformIds = _.chain(this.creatives).map('platformId').uniq().value()
      if (this.optionEmail.to) {
        this.form.to = _.chain(this.form.to)
          .concat(this.optionEmail.to)
          .uniq()
          .value()
          .filter(el => el)
      }
      if (this.optionEmail.cc) {
        this.form.cc = _.chain(this.form.cc)
          .concat(this.optionEmail.cc)
          .uniq()
          .value()
          .filter(el => el)
      }

      const submissionRequestDraftName = this.form.requestName
        ? `${this.form.requestName}`
        : `${moment().format('YYYYMMDD')}_${platformIds.join('-')}_${userName}`
      const body = {
        creativeDraftId,
        submissionRequestDraftName,
        note: this.form.note,
        webApp: this.form.webapp,
        executionUserId: await this.$gAuth.getEmail(),
        executionUsername: await this.$gAuth.getName(),
        promotions: this.$root.$children[0].selectedPromotions,
        assignUser: _.map(this.form.to, id => _.find(this.employees, r => r.id === id) || { name: '手動入力', id }),
        ccAssignUser: _.map(this.form.cc, id => _.find(this.employees, r => r.id === id) || { name: '手動入力', id }),
        addition: this.form.addition === 'その他' ? this.form.additionText : this.form.addition,
        platformIds,
      }
      if (_.isEmpty(this.form?.webapp) && !_.isEmpty(body.assignUser)) {
        this.loading = false
        this.webAppError = true
        return
      } else {
        this.webAppError = false
      }
      const submissionRequestDraft =
        (await this.$api.authFetch('/submission_request/draft', { method: 'POST', body })) || {}
      this.loading = false
      if (submissionRequestDraft !== true) return
      this.isShow = false
      const uri = new URI(this.$route.fullPath)
      const { promotionIds = '' } = URI.parseQuery(uri.query())
      const query = _.assign({}, promotionIds ? { promotionIds } : {}, { creativeDraftId })
      const url = `${this.$api.frontDomain}/creative-regist?${URI.buildQuery(query)}`
      this.$refs.completeDraftDialog.show(url)
    },
    validateMail(event, type) {
      type === 'to' ? (this.isInvalidEmailTo = false) : (this.isInvalidEmailCc = false)
      const validateEmail = email => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      }
      event.map((v, i) => (event[i] = v.replace(/\s+/g, '')))

      event.map(v => {
        if (!validateEmail(v)) {
          type === 'to' ? (this.isInvalidEmailTo = true) : (this.isInvalidEmailCc = true)
        }
      })
    },
    addEmailToBox(event, type) {
      this.optionEmail[type] = _.chain(this.optionEmail[type])
        .concat(this.form[type])
        .uniq()
        .value()
        .filter(el => el)
      this.form[type] = null
    },
  },
}
</script>
