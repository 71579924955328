<template>
  <div>
    <div id="asset-group" ref="assetGroup" class="font-mini">
      <span @click="$emit('remove-asset', asset.id, asset.assetId)">
        <i
          class="badge icon-red font-middle fa-solid fa-square-xmark fa-sm lh-0"
          :style="{ height: '0.85rem!important' }"
        />
      </span>
      <el-card
        style="border: solid 1px #dcdfe6 !important"
        id="asset-item"
        shadow="never"
        :body-style="{ padding: '8px', 'word-break': 'break-all' }"
        class="font-mini"
      >
        <el-row class="mb-1" type="flex" align="top">
          <el-col>
            <div :style="{ position: 'relative' }">
              <el-tag type="info" size="mini" class="c-black" :style="{ position: 'absolute' }">{{
                asset.name
              }}</el-tag>
              <el-tag type="info" size="mini" class="c-black" :style="{ visibility: 'hidden' }">{{ maxLabel }}</el-tag>
            </div>
            <div class="scroll-4 hidden-scroll">
              <div class="font-mini ml-1 mr-1 line-1">
                {{ asset.metadata.fileName }}
              </div>

              <div class="float-left font-mini ml-1 mr-1 line-1">
                {{ asset.metadata.rawFileName }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div class="asset-h">
            <image-movie-item
              class="mb-1 h-100"
              :url="asset.url"
              :thumbnail-url="asset.thumbnailUrl"
              :type="asset.assetType"
              parentComponent="newAsset"
            />
          </div>
        </el-row>
        <div v-if="this.isError" class="float-right text-center h-10">
          <div class="error-text text-center">規定違反があります。</div>
          <el-button
            class="w-50 plr-5"
            type="danger"
            size="mini"
            @click="
              $alert('<div>' + displayErrors() + '</div>', 'エラー', {
                dangerouslyUseHTMLString: true,
              })
            "
          >
            詳細確認
          </el-button>
        </div>
        <div v-else class="h-10"></div>
      </el-card>
    </div>
  </div>
</template>

<style scoped>
.asset-h {
  height: 146px;
  margin-bottom: 5px;
  justify-content: center;
}
.plr-5 {
  padding: 7px 5px;
}
.mt-1 {
  margin-top: 0.5rem;
}
.ml-1 {
  margin-left: 0.5rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.h-10 {
  height: 27px;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 60%;
}
.delete-icon {
  margin-top: 5px;
}
.h-100 {
  height: 100%;
}
.font-mini {
  font-size: 10px;
  cursor: default;
}
.font-mini2 {
  font-size: 12px;
}
.font-bold {
  font-weight: bold;
}
.float-left {
  float: left;
}
.float-right {
  display: flex;
  align-content: center;
  text-align: center;
}
.line-1 {
  line-height: 18px;
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-center {
  text-align: center;
}
.error-text {
  color: #f56c6c;
  font-size: 10px;
  align-items: center;
  margin: auto;
}
fieldset {
  border: 2px solid #f56c6c;
  border-radius: 5px;
  margin-bottom: 5px;
}
legend {
  color: #f56c6c;
  font-size: 14px;
  font-weight: bold;
}
.badge {
  position: relative;
  right: 15px;
  float: right;
  top: 2px;
  /* left: 10.2rem;  */
  cursor: pointer;
  z-index: 2;
}
.flex {
  display: flex;
  align-items: flex-end;
}
.icon-red {
  color: #f56c6c;
}
.lh-0 {
  line-height: initial;
}
.c-black {
  white-space: break-spaces;
  height: fit-content;
}
.scroll-4 {
  overflow-y: scroll;
  height: 70px;
}
::-webkit-scrollbar {
  display: none;
}
#asset-item .el-checkbox__label {
  display: none;
}
.el-popover {
  word-wrap: break-word !important;
}
</style>

<style scoped>
.tag {
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}
</style>

<script>
import ImageMovieItem from '@/components/image-movie-item'

export default {
  name: 'asset-item',
  components: { ImageMovieItem },
  props: {
    asset: { type: Object, default: () => {} },
    assetErrors: { type: Array, default: () => [] },
    maxLabel: { type: String, default: () => null },
  },
  methods: {
    log(mess, data) {
      console.log(mess, data)
    },
    openItemDialog() {
      if (this.noPreview) return

      const aspectRatio = this.$refs.item.clientWidth / this.$refs.item.clientHeight
      this.$refs.itemDialog.show(aspectRatio)
    },
    displayErrors() {
      return `<ul>
      ${this.assetErrors
        .find(x => x.key === this.asset.originId)
        .errors.map(x => {
          return `<li>${x}</li>`
        })
        .join('<br>')}
       </ul>`
    },
  },
  computed: {
    isError() {
      const targets = this.assetErrors.find(x => x.key === this.asset.originId)
      return targets !== undefined && targets.errors.length !== 0
    },
  },
}
</script>
