<template>
  <section class="d-flex">
    <div :class="`status-select ${this.size === 'full' ? 'full-width' : ''}`">
      <el-popover
        :class="`status-popover`"
        placement="bottom-start"
        :popper-class="`status-popover-dropdown ${this.size == 'full' ? ' full-width' : ''}`"
        trigger="click"
        v-model="visible"
        :disabled="isCreativeHaveNG || disabled"
      >
        <div class="status-menu">
          <div v-for="(status, i) in statusOption" :key="status + i" style="display: flex; justify-content: center">
            <el-tag
              slot="reference"
              :style="getStatusStyle(status)"
              type="info"
              effect="plain"
              class="w-100 font-bold status-item"
              @click="
                () => {
                  changeStatus(status)
                  visible = false
                }
              "
            >
              {{ status }}
            </el-tag>
          </div>
        </div>
        <el-tag
          slot="reference"
          :style="{
            backgroundColor: this.setBackgroundColorApprovalStatus(statusSelected),
            color: this.setColorText(statusSelected),
          }"
          type="info"
          effect="plain"
          class="w-100 font-bold status-button"
        >
          {{ statusSelected }}
          <i v-if="!isCreativeHaveNG && !disabled" class="el-icon-arrow-down el-icon--right" />
        </el-tag>
      </el-popover>
    </div>
  </section>
</template>
<style>
.status-select .el-popover {
  padding: 0px !important;
}
.status-select {
  width: 115px;
}
.status-select.full-width {
  width: 99%;
}
.status-select .status-popover {
  width: 98px;
}
.status-select.full-width .status-popover {
  width: 200px;
}
.status-popover-dropdown {
  padding: 12px 2px !important;
  width: 98px !important;
}
.status-popover-dropdown.full-width {
  padding: 12px 5px !important;
  width: 11% !important;
}

.status-select .status-button {
  width: 115px;
}
.status-select.full-width .status-button {
  width: 99%;
}
.status-select .status-item {
  width: 98px;
}
.status-select.full-width .status-item {
  width: 100%;
}

.status-popover-dropdown .popper__arrow {
  left: calc(50% - 3px) !important;
}
</style>
<style scoped>
.font-bold {
  font-weight: bold;
}

.w-100 {
  width: 100%;
}

.status-button {
  position: relative;
  padding-right: 16px;
  text-align: center;
}

.status-button .el-icon-arrow-down {
  position: absolute;
  top: 30%;
  right: 4%;
}

.status-menu {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  justify-content: center;
}
</style>
<script>
import util from '@/mixins/util'
import { NOT_ABLE_CHANGE_STATUS_MSG } from '@/mixins/constant'
export default {
  name: 'status-area',
  props: {
    statusSelected: {
      type: String,
      default: () => '',
    },
    targetType: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'normal',
    },
    isCreativeHaveNG: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isShowAlertDisabled: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [util],
  data: () => ({
    statusOption: [],
    visible: false,
    NOT_ABLE_CHANGE_STATUS_MSG: NOT_ABLE_CHANGE_STATUS_MSG,
  }),
  created() {
    switch (this.targetType) {
      case 'creative':
        this.statusOption = [...this.approvalStatusCreativeOptions].filter(
          x => x !== '入稿依頼済' && x !== '入稿済' && x !== '監修未提出'
        )
        break

      default:
        this.statusOption = [...this.approvalStatusOptions].filter(x => x !== '入稿済' && x !== '監修未提出')
    }
  },
  methods: {
    log(a, b) {
      console.log(a, b)
    },
    closeStatus() {
      this.visible = false
    },
    getStatusStyle(status) {
      let backgroundColor, color

      switch (status) {
        case '監修中':
          backgroundColor = '#909399'
          color = '#fff'
          break
        case '要修正':
          backgroundColor = '#ef342f'
          color = '#fff'
          break
        case '承認待ち':
          backgroundColor = '#909399'
          color = '#fff'
          break

        case '承認':
          backgroundColor = 'rgb(91, 184, 93)'
          color = '#fff'
          break
        case '入稿可能':
          backgroundColor = 'rgb(91, 184, 93)'
          color = '#fff'
          break

        case '破棄':
          backgroundColor = 'rgba(245,245,245,0.48)'
          color = '#06063096'
          break
        case '入稿不可':
          backgroundColor = 'rgba(245,245,245,0.48)'
          color = '#06063096'
          break

        default:
          backgroundColor = 'white'
          color = 'rgb(6 6 48 / 45%)'
      }

      return {
        backgroundColor,
        color,
        textAlign: 'center',
      }
    },
    changeStatus(status) {
      if (status !== this.statusSelected) {
        this.$emit('change-status', status)
      }
    },
    setColorText(status) {
      switch (status) {
        case '監修中':
          return '#fff'
        case '要修正':
          return '#fff'
        case '承認待ち':
          return '#fff'
        case '入稿依頼済':
          return '#fff'
        case '入稿済':
          return '#fff'

        case '承認':
          return '#fff'
        case '入稿可能':
          return '#fff'

        case '破棄':
          return '#06063096'
        case '入稿不可':
          return '#06063096'

        default:
          return 'rgb(6 6 48 / 45%)'
      }
    },
    setBackgroundColorApprovalStatus(status) {
      switch (status) {
        case '監修中':
          return '#909399'
        case '要修正':
          return '#ef342f'
        case '承認待ち':
          return '#909399'
        case '入稿依頼済':
          return '#ffa500'
        case '入稿済':
          return '#084c6c'

        case '承認':
          return 'rgb(91, 184, 93)'
        case '入稿可能':
          return 'rgb(91, 184, 93)'

        case '破棄':
          return 'rgba(245,245,245,0.48)'
        case '入稿不可':
          return 'rgba(245,245,245,0.48)'

        default:
          return 'white'
      }
    },
  },
}
</script>
