<template>
  <el-row id="the-header" class="h-100" type="flex" align="middle" :gutter="20" v-show="promotionInfo.length > 0">
    <el-col :span="6" style="display: contents">
      <el-image class="flex radius" style="max-width: 30px" :src="require('@/assets/ct_icon_1080x1080.png')">
        <div slot="error" class="image-slot">
          <i class="fas fa-exclamation-triangle" />
          <span class="font-mini">読み込み失敗</span>
        </div>
      </el-image>
      <div class="logo">Creative Tracking</div>
    </el-col>

    <template v-if="headerCondition()">
      <template>
        <!--   select promotionIds-->
        <el-col :span="5">
          <el-select
            v-if="$route.path === '/wall-hitting'"
            class="w-100"
            v-model="selectedAdAccountUID"
            filterable
            clearable
            :loading="loadingSearch"
            remote
            :remote-method="remoteMethod"
            placeholder="プロモーション/アカウント検索"
            style="overflow: auto"
            @clear="emptyFilter()"
            @input="changeAdAccountHistory()"
            @visible-change="watchOption()"
          >
            <div v-infinite-scroll="loadList">
              <el-option-group v-for="(group, idx) in grouping" :key="idx" :label="group.label">
                <el-option
                  v-for="(r, i) in group.promotions"
                  :key="`${idx}_${i}`"
                  :label="`${r.advertiserName}_${r.productName}_${r.platform}_${r.adAccountId}_${r.accountName}`"
                  :value="r.uid"
                />
              </el-option-group>
            </div>
          </el-select>
          <search-tab
            v-else-if="promotionInfo.length > 0"
            :key="keySearch"
            :advertisers="advertisers"
            :selected-ids="selectedIds"
            :promotions-history="promotionsHistory"
            :promotion-info="promotionInfo"
            :is-can-leave="isCanLeave"
            :is-display-confirm="isDisplayConfirm"
            @change-is-display-confirm="i => $emit('change-is-display-confirm', i)"
            @change-selected-ids="
              gr => {
                selectedIds = gr
              }
            "
            @change-promotions-history="
              history => {
                changePromotionHistory(history)
              }
            "
            @change-selected-promotion-name="name => $emit('change-selected-promotion-name', name)"
          />
        </el-col>
        <!--        các element từ sidebar-->
        <div
          v-for="(r, i) in items"
          :key="i"
          :index="r.path"
          @click="changeRoute(r.path)"
          style="cursor: pointer"
          :style="r.filename === 'manual_icon' && 'margin-left: auto;'"
        >
          <el-col class="text-center">
            <el-image
              class="font-gray"
              :class="r.filename === 'odd-ai_logo_white' ? 'w-50px' : 'w-30px'"
              :src="require(`@/assets/${r.filename}.png`)"
              :style="r.filename === 'manual_icon' && 'top: 3px'"
            />

            <div class="font-mini" :id="r.path">
              {{ r.text }}
            </div>
          </el-col>
        </div>
      </template>
    </template>
  </el-row>
</template>

<style>
@font-face {
  font-family: Septenisans-Regular;
  src: url('../../../src/assets/fonts/Septenisans-Regular.otf');
}
.logo {
  margin-left: 5px;
  margin-right: 20px;
  font-size: 25px;
  font-weight: 500;
  font-family: Septenisans-Regular;
}
#the-header .el-badge__content {
  padding: 0 4px;
}

#the-header .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
  padding: 30px 0;
}

#the-sidebar .el-menu-item {
  padding: 0px;
}

#the-sidebar .el-menu-item,
.el-submenu__title {
  white-space: initial;
  line-height: initial;
  height: 74px;
}

#the-sidebar .el-menu {
  background: initial;
}
</style>

<style scoped>
.flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.radius {
  border-radius: 5px;
}

.badge-default {
  position: relative;
  bottom: 10px;
  right: 40px;
  font-weight: bold;
}

.badge {
  position: relative;
  bottom: 10px;
  right: 10px;
}

.w-74px {
  width: 74px;
}

.w-30px {
  width: 30px;
}

.w-50px {
  width: 50px;
}

.font-mini {
  font-size: 10px;
}

.text-center {
  text-align: center;
}

.search-icon {
  border-radius: 25px;
  padding: 10px;
  position: absolute;
  top: 50%;
  font-size: 1.5em;
  vertical-align: middle;
}

.before {
  left: 50px;
}

.after {
  left: 260px;
}
</style>

<script>
import _ from 'lodash'
import SearchTab from '@/components/the-header/promotion-dialog/components/search-tab'
import store from 'store'

export default {
  name: 'the-header',
  components: { SearchTab },
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    advertisers: { type: Array, default: () => [] },
    promotionAccounts: { type: Array, default: () => [] },
    promotionInfo: { type: Array, default: () => [] },
    urlHistory: { type: Object, default: () => null },
    selectedPromotionAccount: { type: Object, default: () => {} },
    isCanLeave: { type: Boolean, default: true },
    isDisplayConfirm: { type: Boolean, default: false },
  },
  data: () => ({
    selectedIds: [],
    options: [],
    filteredOption: [],
    infiniteValue: 100,
    loadingSearch: false,
    selectedAdAccountUID: null,
    selectedAccountId: null,
    selectedPromotionName: null,
    promotionsHistory: store.get('promotionsHistory') || [],
    adAccountHistory: store.get('adAccountHistory') || [],
    keySearch: 'initial',
    grouping: [
      {
        label: '履歴',
        promotions: [],
      },
      {
        label: 'プロモーション一覧',
        promotions: [],
      },
    ],
    isSub: false,
  }),
  async created() {
    window.TheHeader = this
    // const time = Date.now()
    // const healthCheck =  await this.$api.authFetch('/healthCheck'+'?time='+time, {method: 'GET'})
    // if(healthCheck == undefined) {
    //   return
    // }
    const checkEmployee = await this.$gAuth.isSub()
    this.isSub = checkEmployee.isSub ? checkEmployee.isSub : false
    if (this.$route.path === '/wall-hitting' && !this.$route.query.promotionIds) this.selectedAccountId = null
    if (this.$route.path !== '/approval-detail') {
      const query = Object.assign({}, this.$route.query)
      if (query.approvalRequestId) {
        delete query.approvalRequestId
        this.$router.push({ name: this.$route.path.slice(1), query: query })
      }
    }
    if (this.$route.query.promotionIds) {
      this.selectedIds = [this.$route.query.promotionIds]
    }
    this.loading = false
  },
  beforeUpdate() {
    this.items.map(r => {
      this.removeHighlightTab(r.path)
    })
    this.highlightTab()
  },

  methods: {
    async headerCondition() {
      return (
        (this.$route.path !== '/login' && !this.isSub) ||
        (this.isSub && (this.$route.path === '/wall-hitting' || this.$route.path === '/text-master'))
      )
    },
    removeSelected(target) {
      this.selectedIds = _.filter(
        this.selectedIds,
        r2 => r2.id !== target.id || r2.promotion.id !== target.promotion.id
      )
      this.changeSelectedPromotionIds()
    },
    changeSelectedPromotionIds() {
      this.$emit('change-selected-promotion-ids', this.selectedIds)
    },
    reloadSelectedIds() {
      const promotionId = _.first(this.selectedPromotionIds)
      this.selectedAccountId = this.$route.query.adAccountId
        ? this.$route.query.adAccountId
        : _.chain(this.promotionAccounts)
            .find(r => r.promotionId === promotionId)
            .result('adAccountId')
            .value()

      this.selectedIds = [promotionId]
      if (this.selectedAdAccountUID) {
        this.options = this.promotionAccounts.find(x => x.uid === this.selectedAdAccountUID)
          ? this.promotionAccounts.find(x => x.uid === this.selectedAdAccountUID)
          : []
      } else {
        this.options = this.promotionAccounts.find(x => x.adAccountId === this.selectedAccountId)
          ? this.promotionAccounts.find(x => x.adAccountId === this.selectedAccountId)
          : []
      }
    },
    changePromotionHistory(promotionsHistory) {
      this.promotionsHistory = _.chain([promotionsHistory].concat(this.promotionsHistory))
        .uniqBy('promotionId')
        .filter(r => r !== undefined)
        .filter((_, i) => i < 5)
        .value()

      store.set('promotionsHistory', this.promotionsHistory)
    },
    changeAdAccountHistory() {
      if (this.promotionAccounts.findIndex(x => x.uid === this.selectedAdAccountUID) !== -1) {
        this.selectedAccountId = this.promotionAccounts.find(x => x.uid === this.selectedAdAccountUID).adAccountId
        const adAccountHistory = this.promotionAccounts.find(x => x.uid === this.selectedAdAccountUID)
        const newAdAccountHistory = _.chain([adAccountHistory].concat(this.adAccountHistory))
          .uniqBy('uid')
          .filter(r => r !== undefined)
          .filter((_, i) => i < 5)
          .value()

        this.adAccountHistory = newAdAccountHistory

        store.set('adAccountHistory', newAdAccountHistory)
      }
    },
    isNotChangeSelectedIdsPromotionIds() {
      const selectedIdsPromotionIds = this.selectedIds
      if (selectedIdsPromotionIds.length === 0) {
        return true
      } else {
        return (
          _.difference(this.selectedPromotionIds, selectedIdsPromotionIds).length === 0 &&
          _.difference(selectedIdsPromotionIds, this.selectedPromotionIds).length === 0 &&
          _.filter(selectedIdsPromotionIds, (v, i) => v === this.selectedPromotionIds[i]).length ===
            selectedIdsPromotionIds.length
        )
      }
    },
    getPromotionInfo() {
      const a = _.chain(this.advertisers)
        .map(r => {
          const advertiserId = r.id
          const advertiserName = r.name
          return _.map(r.promotions, promo => {
            return {
              advertiserId: advertiserId,
              advertiserName: advertiserName,
              promotionId: promo.id,
              promotionName: promo.name,
              combinedName: advertiserName + ' ' + promo.name,
            }
          })
        })
        .flatten()
        .value()
      return a
    },

    async changeRoute(path) {
      const query =
        path === '/creative-list'
          ? this.$route.query
          : _.omit(this.$route.query, [
              'submissionRequestIds',
              path === '/image-movie-master' && this.$route.path === '/image-movie-master' ? '' : 'originIds',
              path === '/text-master' && this.$route.path === '/text-master' ? '' : 'textSetIds',
              path === '/creative-list' && this.$route.path === '/creative-list' ? '' : 'creativeIds',
              path === '/creative-list' && this.$route.path === '/creative-list' ? '' : 'creativeDraftId',
              this.$route && // clear search form
                (this.$route.path === '/text-master' ? 'textMasterForm' : '',
                _.includes(['/image-movie-master', '/creative-regist', '/creative-regist-only'], this.$route.path)
                  ? 'imageMovieMasterForm'
                  : '',
                this.$route.path === '/creative-list' ? 'creativeListForm' : ''),
            ])

      if (path === '/tableau')
        window.open('https://sepjp-analysis.pyxis-social.com/#/views/ALL_CTX/creativetracking?:iid=1', '_blank')

      if (path === '/manual') {
        try {
          this.$loading({
            lock: true,
            fullscreen: true,
            background: 'hsla(0,0%,100%,.9)',
            customClass: 'cr-list-loading',
          })
          const response = await this.$api.authFetch('/asset/manual', { method: 'GET' })
          const blob = new Blob([response], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          window.open(url, '_blank')
        } catch (error) {
          console.error('There was an error downloading the operation guide:', error)
        } finally {
          this.$loading().close()
        }
        return
      }

      this.$router.push({ path, query })
    },

    removeHighlightTab(path) {
      const defaultTab = document.getElementById(path)
      if (defaultTab) {
        defaultTab.style.color = 'white'
        defaultTab.style.fontWeight = 'inherit'
      }
    },
    highlightTab() {
      const defaultTab = document.getElementById(this.$route.path)
      if (defaultTab) {
        defaultTab.style.color = '#409EFF'
        defaultTab.style.fontWeight = 'bold'
      }
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loadingSearch = true
        this.filteredOption = this.promotionAccounts.filter(item => {
          return item.combinedName.toLowerCase().indexOf(query.toLowerCase()) > -1
        })
        this.options = this.filteredOption.slice(0, 25)
        this.grouping = [
          {
            label: 'プロモーション一覧',
            promotions: this.options,
          },
        ]
        this.infiniteValue = 25
        this.loadingSearch = false
      } else {
        this.grouping = [
          {
            label: '履歴',
            promotions: this.adAccountHistory.filter(x => x !== undefined),
          },
        ]
      }
    },
    loadList() {
      this.infiniteValue = this.infiniteValue + 25
      this.options = this.filteredOption.slice(0, this.infiniteValue)
      this.grouping = [
        {
          label: 'プロモーション一覧',
          promotions: this.options,
        },
      ]
      return
    },
    emptyFilter() {
      this.options = []
      this.filteredOption = []
      this.grouping = [
        {
          label: '履歴',
          promotions: this.adAccountHistory.filter(x => x !== undefined),
        },
      ]
    },
    watchOption() {
      if (this.promotionAccounts.findIndex(x => x.uid === this.selectedAdAccountUID) !== -1) {
        this.selectedAccountId = this.promotionAccounts.find(x => x.uid === this.selectedAdAccountUID).adAccountId
        const adAccountHistory = this.promotionAccounts.find(x => x.uid === this.selectedAdAccountUID)
        const newAdAccountHistory = _.chain([adAccountHistory].concat(this.adAccountHistory))
          .uniqBy('uid')
          .filter(r => r !== undefined)
          .filter((_, i) => i < 5)
          .value()

        this.adAccountHistory = newAdAccountHistory

        if (newAdAccountHistory.length === 0) {
          this.grouping = [
            {
              label: '履歴',
              promotions: this.adAccountHistory.filter(x => x !== undefined),
            },
          ]
        }
      } else {
        this.grouping = [
          {
            label: '履歴',
            promotions: this.adAccountHistory.filter(x => x !== undefined),
          },
        ]
      }
    },
  },
  watch: {
    '$route.path'() {
      this.items.map(r => {
        this.removeHighlightTab(r.path)
      })
      if (this.$route.path !== '/approval-detail') {
        const query = Object.assign({}, this.$route.query)
        if (query.approvalRequestId) {
          delete query.approvalRequestId
          this.$router.push({ name: this.$route.path.slice(1), query: query })
        }
        this.highlightTab()
      }
      if (this.$route.path !== '/wall-hitting') return
      if (!this.$route.query.promotionIds) this.selectedAccountId = null
    },
    selectedIds() {
      if (this.isNotChangeSelectedIdsPromotionIds()) return
      this.changeSelectedPromotionIds()
    },
    advertisers() {
      this.reloadSelectedIds()
    },
    selectedAdAccountUID() {
      const selectedPromotionAccount = _.find(this.promotionAccounts, r => r.uid === this.selectedAdAccountUID)
      this.$emit('change-selected-promotion-account', selectedPromotionAccount)
      if (!_.isEmpty(selectedPromotionAccount))
        this.$emit('change-selected-promotion-ids', [selectedPromotionAccount.promotionId])
    },
    selectedPromotionAccount() {
      this.selectedAdAccountUID = this.selectedPromotionAccount?.uid
      this.selectedAccountId = this.selectedPromotionAccount?.adAccountId
      this.watchOption()
    },
    promotionInfo() {
      if (this.promotionInfo && this.$route.path !== '/approval-detail') {
        this.keySearch = 'test'
        this.highlightTab()
      }
    },
    '$route.query'() {
      const promoId = _.isArray(this.$route.query.promotionIds)
        ? this.$route.query.promotionIds
        : [this.$route.query.promotionIds]
      if (this.selectedIds !== promoId) {
        const promo = this.promotionInfo.find(x => x.promotionId === promoId[0])
        this.changePromotionHistory(promo)
        this.selectedIds = promoId
      }
    },
    urlHistory() {
      this.promotionsHistory = _.chain([this.urlHistory].concat(store.get('promotionsHistory')))
        .uniqBy('promotionId')
        .value()
    },
    selectedAccountId() {
      if (!this.selectedAdAccountUID) {
        const selectedPromotionAccount = _.find(this.promotionAccounts, r => r.adAccountId === this.selectedAccountId)
        this.$emit('change-selected-promotion-account', selectedPromotionAccount)
        if (!_.isEmpty(selectedPromotionAccount))
          this.$emit('change-selected-promotion-ids', [selectedPromotionAccount.promotionId])
      }
    },
  },
  computed: {
    items() {
      return this.isSub
        ? [
            { path: '/text-master', filename: 'text_master_icon', text: 'テキストマスタ' },
            { path: '/manual', filename: 'manual_icon', text: 'マニュアル' },
          ]
        : [
            {
              path: '/image-movie-master',
              filename: 'image_movie_master_icon',
              text: '画像動画マスタ',
            },
            { path: '/text-master', filename: 'text_master_icon', text: 'テキストマスタ' },
            { path: '/creative-regist', filename: 'creative_regist_icon', text: 'CR作成' },
            {
              path: '/creative-regist-only',
              filename: 'creative_asset_icon',
              text: 'アセットCR作成',
            },
            { path: '/creative-list', filename: 'creative_list_icon', text: 'CR一覧' },
            { path: '/tableau', filename: 'tableau', text: 'CRレポート' },
            { path: '/approval-list', filename: 'approval_list_icon', text: '監修依頼一覧' },
            { path: '/manual', filename: 'manual_icon', text: 'マニュアル' },
          ]
    },
    promotionPlaceholder() {
      return this.selectedPromotionName ? this.selectedPromotionName : 'プロモーション選択'
    },
  },
}
</script>
