<template>
  <el-dialog
    id="submission-request-dialog"
    title="入稿依頼作成"
    fullscreen
    append-to-body
    width="100%"
    :visible.sync="isShow"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div v-loading="loading" style="height: 100%; width: 100%" class="scroll-body">
      <el-row class="p-1"> 選択したクリエイティブ </el-row>

      <el-row v-for="(r, i) in groupBySelected" :key="i">
        <el-row class="ps-1">
          <el-divider content-position="left"> {{ r.platformId }} {{ r.adFormatName }} </el-divider>
        </el-row>

        <ad-format-tab-list
          :ad-formats="adFormats"
          :selected="r.selected"
          :ad-format-id="r.adFormatId"
          :creatives="r.creatives"
          :is-in-dialog="true"
          :display-type="'submissionRequest'"
          @change-selected-at-tab="obj => $emit('change-selected-at-tab', obj)"
        />
      </el-row>

      <el-divider class="m-0" />

      <el-row>
        <el-form class="p-1">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item id="checkbox" label="入稿依頼先">
                <el-row class="w-100" type="flex" justify="space-around">
                  <el-col :span="11">
                    <el-row class="mb-1"><span>TO</span> </el-row>

                    <el-row>
                      <div class="mb-1">検索</div>
                      <el-select
                        v-model="form.to"
                        class="mb-2 w-100"
                        v-on:change="addEmailToBox($event, 'to')"
                        filterable
                      >
                        <el-option v-for="(r, i) in employees" :key="i" :label="r.name" :value="r.id" />
                      </el-select>
                    </el-row>

                    <el-row>
                      <div class="mb-1">手動入力</div>
                      <el-input-tag
                        placeholder="追加したいEmailを入力してください"
                        v-model="optionEmail.to"
                        v-on:input="validateMail($event, 'to')"
                      />
                    </el-row>
                  </el-col>

                  <el-col :span="11">
                    <el-row class="mb-1">CC</el-row>

                    <el-row>
                      <div class="mb-1">検索</div>

                      <el-select
                        v-on:change="addEmailToBox($event, 'cc')"
                        v-model="form.cc"
                        class="mb-2 w-100"
                        filterable
                      >
                        <el-option v-for="(r, i) in employees" :key="i" :label="r.name" :value="r.id" />
                      </el-select>
                    </el-row>

                    <el-row>
                      <div class="mb-1">手動入力</div>
                      <el-input-tag
                        placeholder="追加したいEmailを入力してください"
                        v-model="optionEmail.cc"
                        v-on:input="validateMail($event, 'cc')"
                      />
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="入稿依頼名 ※デフォルトで「日付_媒体_入稿依頼作成者」が挿入されます">
                <el-input v-model="form.requestName" />
              </el-form-item>

              <el-form-item label="入稿キャンペーンのWEB/APP" style="margin-top: 20px">
                <div style="color: red" v-if="this.webAppError">入稿キャンペーンのWEB/APPを選択してください。</div>
                <el-radio-group class="w-100" v-model="form.webapp" style="transform: translateY(-50%)">
                  <el-radio label="WEB" />
                  <el-radio label="APP" />]
                </el-radio-group>
              </el-form-item>

              <el-form-item label="注意事項" style="margin-top: 5px">
                <el-radio-group class="w-100" v-model="form.addition" style="transform: translateY(-50%)">
                  <el-radio label="期間限定" />
                  <el-radio label="配信メニュー指定" />
                  <el-radio label="その他" />
                </el-radio-group>
              </el-form-item>

              <el-form-item v-if="form.addition === 'その他'" label="">
                <el-input type="textarea" v-model="form.additionText" />
              </el-form-item>

              <el-form-item label="備考">
                <el-input type="textarea" :rows="9" v-model="form.note" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submissionRequest" :disabled="isInvalidEmailTo || isInvalidEmailCc">
        入稿依頼
      </el-button>
    </span>
  </el-dialog>
</template>

<style>
#submission-request-dialog .el-form-item {
  margin-bottom: initial;
}
#submission-request-dialog #checkbox .el-form-item__content {
  line-height: 20px;
}
.el-input-tag .tag-input {
  margin-top: 4px;
  width: 100% !important;
}
.el-input-tag .el-tag {
  margin-top: 4px;
}
::placeholder {
  color: #caced5;
  opacity: 1; /* Firefox */
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #caced5;
}

.el-loading-mask {
  position: fixed !important;
  width: 100% !important;
  height: 100vh !important;
}
</style>

<style scoped>
.el-input-tag {
  min-height: 150px;
}
/* .scroll-body {overflow-y: scroll} */
.p-1 {
  padding: 1rem;
}
.ps-1 {
  padding: 0 1rem;
}
.m-0 {
  margin: 0 !important;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.w-100 {
  width: 100%;
}
</style>

<script>
import _ from 'lodash'
import URI from 'urijs'
import moment from 'moment'
import AdFormatTabList from '@/views/creative-list/ad-format-tab-list'
import { ElInputTag } from '@/views/creative-list/components/el-input-tag/src'

export default {
  name: 'submission-request-dialog',
  components: {
    AdFormatTabList,
    ElInputTag,
  },
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    selected: { type: Array, default: () => [] },
    groupedCreatives: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
  },
  data: () => ({
    isShow: false,
    loading: false,
    innerHeight: window.innerHeight,
    form: { requestName: '', note: '', webapp: '', addition: '', additionText: '', to: [], cc: [] },
    employees: [],
    optionEmail: { to: '', cc: '' },
    isInvalidEmailTo: false,
    isInvalidEmailCc: false,
    webAppError: false,
  }),
  async created() {
    window.SubmissionRequestDialog = this
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })

    this.loading = true
    this.employees = _.map(await this.$gAuth.getEmployees(), r => ({ id: r.email, name: r.name }))
    this.loading = false
  },
  methods: {
    async show() {
      const userName = await this.$gAuth.getName()
      const platformIdsStr = _.chain(this.selected).map('platformId').uniq().join('-').value()
      const submissionRequestName = `${moment().format('YYYYMMDD')}_${platformIdsStr}_${userName}`
      this.isShow = true
      this.form = {
        requestName: submissionRequestName,
        note: '',
        webapp: '',
        addition: '',
        to: [],
        cc: [],
      }
      this.optionEmail = { to: '', cc: '' }
    },
    submissionRequest: _.debounce(
      async function () {
        this.loading = true
        const userName = await this.$gAuth.getName()
        const platformIdsStr = _.chain(this.selected).map('platformId').uniq().join('-').value()
        if (this.optionEmail.to) {
          this.form.to = _.chain(this.form.to)
            .concat(this.optionEmail.to)
            .uniq()
            .value()
            .filter(el => el)
        }
        if (this.optionEmail.cc) {
          this.form.cc = _.chain(this.form.cc)
            .concat(this.optionEmail.cc)
            .uniq()
            .value()
            .filter(el => el)
        }
        const submissionRequestName = this.form.requestName
          ? `${this.form.requestName}`
          : `${moment().format('YYYYMMDD')}_${platformIdsStr}_${userName}`
        const body = {
          creativeIds: _.map(this.selected, 'creativeId'),
          submissionRequestName,
          note: this.form.note,
          webApp: this.form?.webapp,
          executionUserId: await this.$gAuth.getEmail(),
          executionUsername: await this.$gAuth.getName(),
          promotions: this.$root.$children[0].selectedPromotions,
          assignUser: _.map(this.form.to, id => _.find(this.employees, r => r.id === id) || { name: '手動入力', id }),
          ccAssignUser: _.map(this.form.cc, id => _.find(this.employees, r => r.id === id) || { name: '手動入力', id }),
          addition: this.form.addition === 'その他' ? this.form.additionText : this.form.addition,
        }

        if (_.isEmpty(this.form?.webapp) && !_.isEmpty(body.assignUser)) {
          this.webAppError = true
          return
        } else {
          this.webAppError = false
        }

        const { submissionRequestId: submissionRequestIds = '' } =
          (await this.$api.authFetch('/submission_request', { method: 'POST', body })) || {}
        this.loading = false
        if (!submissionRequestIds) return
        this.isShow = false
        this.$emit('get-creatives')

        const uri = new URI(this.$route.fullPath)
        const { promotionIds = '' } = URI.parseQuery(uri.query())
        const query = _.assign({}, promotionIds ? { promotionIds } : {}, { submissionRequestIds })
        const url = `${this.$api.frontDomain}/creative-list?${URI.buildQuery(query)}`
        this.$emit('open-complete-dialog', url)
      },
      2000,
      { leading: true, trailing: false }
    ),
    validateMail(event, type) {
      type === 'to' ? (this.isInvalidEmailTo = false) : (this.isInvalidEmailCc = false)

      const validateEmail = email => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      }
      event.map((v, i) => (event[i] = v.replace(/\s+/g, '')))
      event.map(v => {
        if (!validateEmail(v)) {
          type === 'to' ? (this.isInvalidEmailTo = true) : (this.isInvalidEmailCc = true)
        }
      })
    },
    addEmailToBox(event, type) {
      this.optionEmail[type] = _.chain(this.optionEmail[type])
        .concat(this.form[type])
        .uniq()
        .value()
        .filter(el => el)
      this.form[type] = null
    },
  },
  computed: {
    height() {
      return this.innerHeight - 34 - 70
    },
    groupBySelected() {
      return _.chain(this.selected)
        .groupBy(r => r.platformId + r.adFormatId)
        .map(gr => {
          const { platformId } = _.first(gr)
          const selectedCreativeIds = _.map(gr, 'creativeId')
          const creatives = _.chain(this.groupedCreatives)
            .filter(r => r.platformId === platformId)
            .map('creatives')
            .flattenDeep()
            .filter(r => _.includes(selectedCreativeIds, r.creativeId))
            .value()

          const {
            adFormat: { id: adFormatId, name: adFormatName },
          } = _.first(creatives)
          return { platformId, adFormatId, adFormatName, selected: gr, creatives }
        })
        .value()
    },
  },
}
</script>
