<template>
  <div :class="`status-select ${this.size === 'full' ? 'full-width' : ''}`">
    <el-popover
      :class="`status-popover`"
      placement="bottom-start"
      :popper-class="`status-popover__dropdown ${this.size == 'full' ? ' full-width' : ''}`"
      trigger="click"
      v-model="visible"
      :disabled="isCreativeHaveNG || disabled"
    >
      <div class="status-popover__menu">
        <div v-for="(status, i) in statusOption" :key="status + i" class="status-popover__menu-item">
          <el-tag
            slot="reference"
            :style="getStatusStyle(status)"
            type="info"
            effect="plain"
            class="w-100 font-bold status-item"
            @click="() => changeStatus(status)"
          >
            {{ status }}
          </el-tag>
        </div>
      </div>
      <el-tag
        slot="reference"
        :style="{
          backgroundColor: this.setBackgroundColorApprovalStatus(statusSelected),
          color: this.setColorText(statusSelected),
          position: 'relative',
          paddingRight: '16px',
          textAlign: 'center',
        }"
        type="info"
        effect="plain"
        class="w-100 font-bold status-button"
      >
        {{ statusSelected }}
        <i
          v-if="!isCreativeHaveNG && !disabled"
          class="el-icon-arrow-down el-icon--right"
          style="position: absolute; top: 30%; right: 4%"
        />
      </el-tag>
    </el-popover>
  </div>
</template>
<style>
.status-select .el-popover {
  padding: 0px !important;
}
.status-select {
  width: 115px;
}
.status-select.full-width {
  width: 99%;
}
.status-select .status-popover {
  width: 98px;
}
.status-select.full-width .status-popover {
  width: 200px;
}
.status-popover__dropdown {
  padding: 12px 2px !important;
  width: 112px !important;
  transform: translateX(-2px);
}
.status-popover__dropdown.full-width {
  padding: 12px 5px !important;
  width: 180px !important;
  transform: translateX(-2px);
}
.status-select .status-button {
  width: 115px;
}
.status-select.full-width .status-button {
  width: 99%;
}
.status-select .status-item {
  width: 98px;
}
.status-select.full-width .status-item {
  width: 100%;
}
.status-popover__dropdown .popper__arrow {
  left: calc(50% - 3px) !important;
}
</style>
<style scoped>
.font-bold {
  font-weight: bold;
}

.w-100 {
  width: 100%;
}

.status-popover__menu {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  justify-content: center;
}

.status-popover__menu-item {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.status-item {
  cursor: pointer;
}

.status-item:disabled {
  cursor: default;
}
</style>
<script>
import util from '@/mixins/util'
import { CreativeStatus } from '@/mixins/creativeStatus'
import { AssetTextStatus } from '@/mixins/assetTextStatus'
export default {
  name: 'status-area',
  props: {
    statusSelected: { type: String, default: () => '' },
    targetType: { type: String, default: '' },
    size: { type: String, default: 'normal' },
    isCreativeHaveNG: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  mixins: [util],
  data: () => ({
    statusOption: [],
    visible: false,
  }),
  created() {
    switch (this.targetType) {
      case 'creative':
        this.statusOption = [...this.approvalStatusCreativeOptions].filter(
          stt => !CreativeStatus.CANNOT_SELECTED_STATUSES_IN_CRS_SCREEN.includes(stt)
        )
        break

      default:
        this.statusOption = [...this.approvalStatusOptions].filter(
          stt => !AssetTextStatus.CANNOT_SELECTED_STATUSES_IN_CRS_SCREEN.includes(stt)
        )
    }
  },
  methods: {
    closeStatus() {
      this.visible = false
    },
    getStatusStyle(status) {
      let backgroundColor, color

      switch (status) {
        case AssetTextStatus.CUSTOMER_CHECKING:
        case AssetTextStatus.CHECKING:
          backgroundColor = '#909399'
          color = '#fff'
          break
        case AssetTextStatus.FIXING:
          backgroundColor = '#ef342f'
          color = '#fff'
          break
        case AssetTextStatus.APPROVED:
        case CreativeStatus.APPROVED:
          backgroundColor = 'rgb(91, 184, 93)'
          color = '#fff'
          break
        case AssetTextStatus.NG:
        case CreativeStatus.NG:
          backgroundColor = 'rgba(245,245,245,0.48)'
          color = '#06063096'
          break
        default:
          backgroundColor = '#fff'
          color = 'rgb(6 6 48 / 45%)'
      }

      return {
        backgroundColor,
        color,
        textAlign: 'center',
      }
    },
    changeStatus(status) {
      if (status !== this.statusSelected) {
        this.$emit('change-status', status)
      }
      this.closeStatus()
    },
    setColorText(status) {
      switch (status) {
        case AssetTextStatus.CUSTOMER_CHECKING:
        case AssetTextStatus.FIXING:
        case AssetTextStatus.CHECKING:
        case AssetTextStatus.SEND_REQUEST_SUBMIT:
        case AssetTextStatus.SUBMITTED:
        case AssetTextStatus.APPROVED:
        case CreativeStatus.APPROVED:
          return '#fff'

        case AssetTextStatus.NG:
        case CreativeStatus.NG:
          return '#06063096'

        default:
          return 'rgb(6 6 48 / 45%)'
      }
    },
    setBackgroundColorApprovalStatus(status) {
      switch (status) {
        case AssetTextStatus.CUSTOMER_CHECKING:
        case CreativeStatus.CHECKING:
          return '#909399'
        case AssetTextStatus.FIXING:
          return '#ef342f'
        case AssetTextStatus.SEND_REQUEST_SUBMIT:
          return '#ffa500'
        case AssetTextStatus.SUBMITTED:
          return '#084c6c'
        case AssetTextStatus.APPROVED:
        case CreativeStatus.APPROVED:
          return 'rgb(91, 184, 93)'
        case AssetTextStatus.NG:
        case CreativeStatus.NG:
          return 'rgba(245,245,245,0.48)'
        default:
          return '#fff'
      }
    },
  },
}
</script>
