<template>
  <el-dialog
    id="rename-dialog"
    custom-class="small-dialog custom-font"
    title="ファイル名変更確認"
    lock-scroll
    append-to-body
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div v-loading="loading" class="p-1">
      <el-form label-position="top" :model="form" :rules="rules">
        <el-form-item label="変更前ファイル名">
          <span>{{ metadata.fileName }}</span>
        </el-form-item>

        <el-form-item class="mt-1" label="変更後ファイル名" prop="newFileName">
          <el-input v-model="form.newFileName" class="w-90 mr-1" />
          .{{ metadata.extension }}
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="rename" :disabled="disable"> 保存 </el-button>
    </span>
  </el-dialog>
</template>

<style>
#rename-dialog .el-form-item__label {
  padding: 0;
}
#rename-dialog .el-form-item__label {
  line-height: initial;
}
#rename-dialog .el-form-item {
  margin-bottom: 0 !important;
}
</style>

<style scoped>
.p-1 {
  padding: 1rem;
}
.w-90 {
  width: 90%;
}
.w-10 {
  width: 10%;
}
.mr-1 {
  margin-right: 0.5rem;
}
.mt-1 {
  margin-top: 1rem;
}
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'
import { INVALID_FILE_NAME } from '@/mixins/constant'

export default {
  name: 'rename-dialog',
  props: { whitelist: { type: Array, default: () => [] } },
  mixins: [util],
  data() {
    const validateNewFileName = (rule, value, callback) => {
      const urlPromotionId = _.isArray(this.$route.query.promotionIds)
        ? this.$route.query.promotionIds[0]
        : this.$route.query.promotionIds
      const isAllocatedPromotion = this.whitelist.includes(parseInt(urlPromotionId))
      if (value === '') {
        this.disable = true
        callback(new Error('必須です'))
      } else if (this.isFileNameInvalid(value)) {
        this.disable = true
        callback(new Error(INVALID_FILE_NAME))
      } else if (isAllocatedPromotion) {
        this.disable = false
      } else if (!value.match(this.assetId)) {
        this.disable = true
        callback(new Error(`変更後ファイル名にはユニークID（${this.assetId}）を含めてください`))
      } else if (!value.match(/_/g)) {
        this.disable = true
        callback(new Error('区切り文字はアンダーバーを使用してください'))
      } else {
        const originIdIndex = value.lastIndexOf(this.assetId)
        const index = originIdIndex + (originIdIndex !== -1 ? this.assetId.length : 0)
        if (!value.match(`_${this.assetId}`) || (value[index] && !value.match(`_${this.assetId}_`))) {
          this.disable = true
          callback(new Error(`ユニークID（${this.assetId}）の区切り文字にアンダーバーを使用してください`))
        } else {
          this.disable = false
          callback()
        }
      }
    }
    const rules = { newFileName: [{ validator: validateNewFileName, trigger: 'change' }] }
    return {
      isShow: false,
      loading: false,
      assetId: '',
      originId: '',
      metadata: {},
      disable: false,
      form: { newFileName: '' },
      rules,
    }
  },
  created() {
    window.RenameDialog = this
  },
  methods: {
    show(originId, assetId, metadata) {
      this.originId = originId
      this.assetId = assetId
      this.metadata = metadata
      this.form.newFileName = this.metadata.fileName.replace(`.${this.metadata.extension}`, '')
      this.isShow = true
    },
    async rename() {
      const body = {
        originId: this.originId,
        fileName: `${this.form.newFileName}.${this.metadata.extension}`,
      }

      this.loading = true
      await this.$api.authFetch('/asset/name', { method: 'PUT', body })
      this.loading = false
      this.isShow = false

      await window.ImageMovieMaster.getAssets()
    },
  },
}
</script>
