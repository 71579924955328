<template>
  <div :id="isOnlyText ? 'only-text-set-area' : 'text-set-area'">
    <template v-if="textSet.labeledTexts.length !== 0">
      <div>
        <div style="display: flex">
          <status-area
            :disabled="!isTextCanEdit"
            :status-selected="textSet.approvalStatus"
            @change-status="status => $emit('change-status-text', textSet.textSetId, status, textSet.version)"
          />
          <el-button
            v-if="textSet.approvalStatus === AssetTextStatus.FIXING && isTextCanEdit"
            type="primary"
            style="padding: 7px; font-size: 13px; margin-left: 1vw"
            @click="updateText()"
          >
            テキスト編集
          </el-button>
        </div>
        <br />
        <span class="thumb-ver mb-1 mt-1" v-text="'v' + textSet.version"></span>
        <el-row class="mt-1" v-for="(gr, i) in formatedLabeledTexts" :key="i" :gutter="10">
          <el-col v-for="(r, j) in gr" :key="j" :span="8">
            <el-row class="p-1 bg-gray">
              {{ r.labelName }}
            </el-row>
            <el-row class="flex mt-1 mb-1 new-line" v-for="(v, j) in omitEmptyText(r.textValues)" :key="j">
              <el-tag class="float-left" type="info" effect="dark" size="mini">
                {{ j + 1 }}
              </el-tag>
              <div class="flex ml-1">{{ v }}</div>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </template>
    <edit-text-set-dialog
      v-if="!['/creative-regist', '/creative-list', '/wall-hitting'].includes($route.path)"
      ref="editTextSetDialog"
      :platform-id="platformId"
      :ad-format-id="adFormatId"
      :ad-format-name="adFormat && adFormat.name ? adFormat.name : ''"
      :ad-formats="adFormats"
      :ad-format="adFormat"
      :check-type="checkType"
      @get-text-sets="$emit('get-text-sets')"
      @get-text-sets-draft="(texts, form) => textsDraft(texts, form, textSet.version)"
    />

    <div v-else class="ms-1">テキストセットなし</div>
  </div>
</template>

<style>
#text-set-area {
  padding: 0 0.5rem;
}

#only-text-set-area {
  padding-right: 0.5rem;
}
</style>

<style scoped>
.tag {
  padding: 0 1.5rem;
}

.new-line {
  word-break: break-word !important;
  white-space: pre-wrap !important;
}

.mt-1:not(:first-of-type) {
  margin-top: 0.5rem;
}

.mb-1:not(:first-of-type) {
  margin-bottom: 0.5rem;
}

.m-1 {
  margin: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.ms-1 {
  margin: 0 0.5rem;
}

.p-1 {
  padding: 0.5rem;
}

.bg-gray {
  background-color: #ebeef5;
}

.flex {
  display: flex;
}

.font-bold {
  font-weight: bold;
}
</style>

<script>
import _, { assign, isEmpty, isUndefined } from 'lodash'
import EditTextSetDialog from '@/views/text-master/ad-format-tab/components/edit-text-set-dialog'
import StatusArea from '@/views/approval-detail/components/ad-format-tab/creative-card/main-area/components/status-area'
import { AssetTextStatus } from '@/mixins/assetTextStatus'

export default {
  name: 'text-set-area',
  props: {
    textSet: { type: Object, default: () => ({}) },
    isOnlyText: { type: Boolean, default: () => false },
    isTextCanEdit: { type: Boolean, default: () => true },
    platformId: { type: String, default: () => '' },
    adFormatName: { type: String, default: () => '' },
    adFormatId: { type: String, default: () => '' },
    adFormats: { type: Array, default: () => [] },
    checkType: { type: String, default: () => '' },
  },
  components: { EditTextSetDialog, StatusArea },
  created() {
    window.TextSetArea = this
    this.textSetDraft = _.cloneDeep(this.textSet)
    this.textBegin = _.cloneDeep(this.textSet)
  },
  data() {
    return {
      textSetDraft: {},
      textBegin: {},
    }
  },

  methods: {
    log(mess, data) {
      console.log(mess, data)
    },
    omitEmptyText(textValues) {
      return textValues.filter(textValue => !isEmpty(textValue))
    },
    updateText() {
      const textDraft = assign({}, this.textSetDraft, {
        labeledTexts:
          this.textSetDraft.labeledTexts &&
          this.textSetDraft.labeledTexts.map(labeledText => {
            return assign({}, labeledText, {
              textValues: labeledText.textValues && this.omitEmptyText(labeledText.textValues),
            })
          }),
      })
      this.$refs.editTextSetDialog.show(textDraft)
    },
    textsDraft(draft, form, version) {
      const isTextSetEqual = this.compareTextSet(draft)
      this.$emit('update-text', draft, form, isTextSetEqual, version)
    },
    compareTextSet(textSet) {
      const result = textSet.labeledTexts.map((text, i) => {
        return (
          !isUndefined(this.textBegin.labeledTexts[i]) &&
          _.isEqual(text.textValues, this.textBegin.labeledTexts[i].textValues)
        )
      })
      return !_.includes(result, false)
    },
  },
  watch: {
    textSet() {
      this.textSetDraft = _.cloneDeep(this.textSet)
    },
  },
  computed: {
    AssetTextStatus() {
      return AssetTextStatus
    },
    adFormat() {
      return _.find(this.adFormats, x => x.id === this.adFormatId)
    },
    formatedLabeledTexts() {
      return _.chunk(this.textSetDraft.labeledTexts, 3)
    },
    setColorText() {
      switch (this.textSet.approvalStatus) {
        case '監修中':
          return '#fff'
        case '要修正':
          return '#fff'
        case '承認待ち':
          return '#fff'

        case '承認':
          return '#fff'
        case '入稿可能':
          return '#fff'

        case '破棄':
          return '#c0c4cc5c'
        case '入稿不可':
          return '#c0c4cc5c'

        case '入稿依頼済':
          return '#fff'
        case '入稿済':
          return '#fff'
        default:
          return 'rgb(6 6 48 / 45%)'
      }
    },
    setBackgroundColorApprovalStatus() {
      switch (this.textSet.approvalStatus) {
        case '監修中':
          return '#909399'
        case '要修正':
          return '#ef342f'
        case '承認待ち':
          return '#909399'

        case '承認':
          return 'rgb(91, 184, 93)'
        case '入稿可能':
          return 'rgb(91, 184, 93)'

        case '破棄':
          return 'rgba(245,245,245,0.48)'
        case '入稿不可':
          return 'rgba(245,245,245,0.48)'

        case '入稿依頼済':
          return '#ffa500'
        case '入稿済':
          return '#084c6c'
        default:
          return 'white'
      }
    },
  },
}
</script>
