<template>
  <el-container id="creative-regist" v-loading="loading">
    <el-main>
      <div class="scroll" :style="{ height: height + 'px' }">
        <el-row class="m-1" v-for="(r, i) in creatives" :key="`creative_${i}`">
          <el-card shadow="never" class="w-100 float-left">
            <creative-item
              :textErrors="textErrors.filter(x => x.creativeIndex === i + 1)"
              :selected-promotion-ids="selectedPromotionIds"
              :tags="tags"
              :tagTypes="tagTypes"
              :ad-formats="adFormats"
              :platform-id="r.platformId"
              :ad-format-id="r.adFormatId"
              :selected-assets="r.selectedAssets"
              :selected-text-sets="r.selectedTextSets"
              :asset-errors="assetErrors[i]"
              :tags-selected="r.tagsSelected"
              :type="type"
              :note="r.note"
              :referenceUrls="r.referenceUrls"
              @change-platform-id="id => changeCreativeItem(i, id, 'platformId')"
              @change-ad-format-id="id => changeCreativeItem(i, id, 'adFormatId')"
              @change-platform-name="name => changeCreativeItem(i, name, 'platformName')"
              @change-ad-format-name="name => changeCreativeItem(i, name, 'adFormatName')"
              @change-selected-assets="gr => changeCreativeItem(i, gr, 'selectedAssets')"
              @change-selected-tag="t => changeCreativeItem(i, t, 'tagsSelected')"
              @change-selected-assets-error="gr => changeAssetErrors(i, gr)"
              @change-selected-text-sets="gr => changeCreativeItem(i, gr, 'selectedTextSets')"
              @is-contain-zip="val => isContainZipOrUrl(val)"
              @change-note="note => changeCreativeItem(i, note, 'note')"
              @update-reference-urls="urls => changeCreativeItem(i, urls, 'referenceUrls')"
              @copy-creative="copyCreative(i)"
              @reload="$emit('get-tags-and-tag-types')"
            />
          </el-card>

          <span class="badge" @click="removeCreative(i)">
            <i class="fas fa-times-circle font-red" />
          </span>

          <div v-if="errors.length !== 0" class="font-mini font-red float-left w-100">
            <el-row v-for="(r, j) in errors" :key="`errors_${i}_${j}`">
              <el-row v-if="r.creativeIndex === i + 1" class="p-1">
                <el-row>【画像グループ】: {{ r.assetIndex }}番目 ✕【テキスト】: {{ r.textSetIndex }}番目</el-row>

                <template v-if="r.errors.length !== 0">
                  <el-row v-for="(error, k) in r.errors" :key="`errors_error_${i}_${j}_${k}`" class="p-inline-2">
                    {{ error.labelNames.join(', ').concat(`: ${error.error}`) }}
                  </el-row>
                </template>

                <template v-if="r.labels.length !== 0">
                  <el-row v-for="(r2, k) in r.labels" :key="`errors_label_${i}_${j}_${k}`">
                    <el-row class="p-inline-2">【{{ r2.labelName }}】</el-row>

                    <template v-if="r2.errors.length !== 0">
                      <el-row v-for="(error, l) in r2.errors" :key="`errors_${i}_${j}_${k}_${l}`" class="p-inline-3">
                        {{ error }}
                      </el-row>
                    </template>

                    <template v-if="r2.targets.length !== 0">
                      <el-row v-for="(r3, l) in r2.targets" :key="`errors_labels_target_${i}_${j}_${k}_${l}`">
                        <template v-if="r3.errors.length !== 0">
                          <el-row class="p-inline-3">【画像】: {{ r3.index }}番目</el-row>
                          <ul>
                            <li
                              v-for="(error, n) in r3.errors"
                              :key="`errors_labels_target_error_${i}_${j}_${k}_${l}_${n}`"
                            >
                              {{ error }}
                            </li>
                          </ul>
                        </template>
                      </el-row>
                    </template>
                  </el-row>
                </template>
              </el-row>
            </el-row>
          </div>
        </el-row>

        <el-row v-if="type === 'new'" class="p-1 text-center font-big">
          <span @click="addCreative">
            <i class="fas fa-plus-circle" />
          </span>
        </el-row>
      </div>

      <el-row type="flex" justify="end" class="bg-black p-1">
        <el-button type="info" :disabled="disableDraft" @click="showSubmissionDraftDialog">
          掛け合わせせずに入稿依頼
        </el-button>
        <el-button type="info" :disabled="disableDraft" @click="saveDraft()"> 一時保存 </el-button>

        <el-button type="info" :disabled="disableRegulate" @click="regulateAsset"> レギュレーションチェック </el-button>

        <el-button type="primary" :disabled="!disableCreative" @click="$refs.comfirmDialog.show()">
          クリエイティブ{{ typeStr }}
        </el-button>

        <el-button type="primary" @click="$refs.uploadAssetsDialog.show()"> 画像/動画をアップロード </el-button>

        <el-button v-if="this.type !== 'new'" @click="goToCreativeList"> キャンセル </el-button>
      </el-row>
    </el-main>

    <submission-draft-dialog ref="submissionDraftDialog" :creatives="creatives" />

    <complete-draft-dialog ref="completeDraftDialog" />

    <upload-assets-dialog
      ref="uploadAssetsDialog"
      :default-promotion-id="selectedPromotionIds[0]"
      :tags="tags"
      :adFormats="adFormats"
    />

    <comfirm-dialog
      ref="comfirmDialog"
      :type-str="typeStr"
      :confirm-messages="confirmMessages"
      :contain-zip-or-url="containZipOrUrl"
      @regist="regist"
    />
  </el-container>
</template>

<style>
#creative-regist .el-card__body {
  padding: 0;
}

#creative-regist .el-main {
  padding: 0;
}

#creative-regist ul {
  margin: 0;
  padding-inline-start: 55px;
}
</style>

<style scoped>
.break_line_message {
  white-space: break-spaces !important;
}

.p-inline-1 {
  padding-inline-start: 10px;
}

.p-inline-2 {
  padding-inline-start: 15px;
}

.p-inline-3 {
  padding-inline-start: 25px;
}

.bg-black {
  background: #004b6a;
}

.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}

.scroll::-webkit-scrollbar {
  display: none;
}

/* Chrome, Safari 対応 */
.w-0 {
  width: 0;
}

.w-100 {
  width: 100%;
}

.h-0 {
  height: 0;
}

.p-1 {
  padding: 1rem;
}

.pr-2 {
  padding-right: 5px;
}

.ml-1 {
  margin-left: 1.5rem;
}

.m-1 {
  margin: 1.5rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-big {
  font-size: 22px;
}

.float-left {
  float: left;
}

.font-red {
  color: #f56c6c !important;
}

.font-mini {
  font-size: 12px;
}

.badge {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 2;
}

.vertical-center {
  display: flex;
  align-items: center;
}
</style>

<script>
import _ from 'lodash'
import URI from 'urijs'
import util from '@/mixins/util'
import CreativeItem from '@/views/creative-regist/creative-item'
import CompleteDraftDialog from '@/views/creative-regist/components/complete-draft-dialog'
import UploadAssetsDialog from '@/components/upload-assets-dialog'
import SubmissionDraftDialog from '@/views/creative-regist/components/submission-draft-dialog'
import ComfirmDialog from '@/views/creative-regist/components/comfirm-dialog'

export default {
  name: 'creative-regist',
  mixins: [util],
  components: {
    CreativeItem,
    CompleteDraftDialog,
    UploadAssetsDialog,
    SubmissionDraftDialog,
    ComfirmDialog,
  },
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    tagTypes: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
  },
  data() {
    const creatives = [
      {
        platformId: '',
        platformName: '',
        adFormatId: '',
        adFormatName: '',
        isPreview: false,
        selectedAssets: [],
        selectedTextSets: [],
        compareCreative: {},
        oddAiFlag: {},
        note: '',
        referenceUrls: [''],
        tagsSelected: [],
      },
    ]
    return {
      loading: false,
      activeNames: [],
      type: 'new',
      errors: [],
      assetErrors: [],
      textErrors: [],
      creatives,
      predictionResult: [],
      accounts: [],
      accountSettings: [],
      selectedAccountSettings: [],
      creativesData: [],
      compareCreativesData: [],
      innerHeight: window.innerHeight,
      selectedCreatives: [],
      containZipOrUrl: false,
    }
  },
  async created() {
    window.CreativeRegist = this

    const urlPromotionId = _.isArray(this.$route.query.promotionIds)
      ? this.$route.query.promotionIds
      : [this.$route.query.promotionIds]

    if (this.$route.query.type) {
      this.type = this.$route.query.type
      if (!this.$route.query.creativeId) this.$router.push({ query: _.omit(this.$route.query, ['type', 'creativeId']) })
    }

    if (this.$route.query.wallHittingResultId || this.$route.query.wallHittingDraftId) {
      this.$router.push({
        query: _.omit(this.$route.query, ['wallHittingResultId', 'wallHittingDraftId', 'page', 'adAccountId']),
      })
    }

    if (this.$route.query.creativeId) {
      const creativeId = this.$route.query.creativeId
      this.$router.push({ query: _.omit(this.$route.query, ['type', 'creativeId']) })

      const creativeListBody = { promotionIds: urlPromotionId, creativeIds: [creativeId] }
      this.loading = true
      const groupedCreatives = _.result(
        await this.$api.authFetch('/creative/list', {
          method: 'POST',
          body: creativeListBody,
        }),
        'groupedCreatives'
      )
      this.loading = false

      this.creatives = _.chain(groupedCreatives)
        .filter(r => _.includes(_.map(r.creatives, 'creativeId'), creativeId))
        .map(({ creatives, platformId }) => {
          const creative = _.find(creatives, r2 => r2.creativeId === creativeId)
          const {
            adFormat: { id: adFormatId, name: adFormatName },
            labeledAssets,
            textSet,
            note,
            referenceUrls,
            tags,
          } = creative
          const assets = _.map(labeledAssets, r => ({
            id: r.labelId,
            name: r.labelName,
            selected: r.assets,
          }))
          const selectedAssets = assets.length === 0 ? [] : [assets]
          const selectedTextSets = !textSet ? [] : [textSet]
          const { oddAiFlag } = _.find(this.adFormats, r2 => r2.platformId === platformId && r2.id === adFormatId) || {}
          return {
            creativeId,
            platformId,
            adFormatId,
            isPreview: false,
            adFormatName,
            selectedAssets,
            selectedTextSets,
            compareCreative: {},
            oddAiFlag,
            note,
            referenceUrls,
            tagsSelected: tags,
          }
        })
        .value()
    }

    if (this.$route.query.creativeIds) this.$router.push({ query: _.omit(this.$route.query, ['creativeIds']) })

    if (this.$route.query.creativeDraftId) {
      //check if is a valid draft for creative-regist
      this.loading = true
      const creativeDraft = _.result(
        await this.$api.authFetch(`/creative/draft/${this.$route.query.creativeDraftId}`, {
          method: 'GET',
        }),
        'creativeDraft'
      )

      const checkCreativeDraftObject = _.findIndex(creativeDraft, r => r.uid)
      if (checkCreativeDraftObject === -1) {
        //uid only for creative-regist-only
        creativeDraft.map(cr => (cr.referenceUrls = cr.referenceUrls ?? [''])) // confirm draft creative have referenceUrls
        this.creatives = creativeDraft
      } else {
        await this.$router.push({ query: _.omit(this.$route.query, ['creativeDraftId']) })
      }
      this.loading = false
    }

    if (this.$route.query.predictionResultId) {
      this.loading = true
      const predictionResult = _.result(
        await this.$api.authFetch(`/creative/prediction_result/${this.$route.query.predictionResultId}`, {
          method: 'GET',
        }),
        'predictionResult'
      )
      this.loading = false

      this.predictionResult = predictionResult
    }

    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })

    const queryObj = _.reduce(this.$route.query, (ret, v, k) => (k.match('Form') ? ret : _.assign(ret, { [k]: v })), {})
    this.$router.push({ query: queryObj })
  },
  methods: {
    async showSubmissionDraftDialog() {
      const regulateResult = await this.regulateAsset()
      if (!regulateResult) this.$refs.submissionDraftDialog.show()
    },
    goToCreativeList() {
      this.$router.push({ path: '/creative-list', query: this.$route.query })
    },
    changeCreativeItem(index, item, key) {
      this.creatives = _.map(this.creatives, (r, i) => {
        if (i !== index) return _.assign({}, r)

        if (key === 'adFormatId') {
          const { oddAiFlag } = _.find(this.adFormats, r2 => r2.platformId === r.platformId && r2.id === item) || {}
          return _.assign({}, r, { [key]: item, oddAiFlag })
        } else return _.assign({}, r, { [key]: item })
      })
    },
    changeAssetErrors(index, item) {
      this.assetErrors = _.map(this.assetErrors, (r, i) => {
        if (i !== index) return _.assign({}, r)

        return item
      })
    },
    isContainZipOrUrl(contain) {
      this.containZipOrUrl = contain
    },
    addCreative() {
      this.creatives.push({
        platformId: '',
        adFormatId: '',
        adFormatName: '',
        isPreview: false,
        selectedAssets: [],
        selectedTextSets: [],
        compareCreative: {},
        note: '',
        referenceUrls: [''],
        oddAiFlag: {},
        tagsSelected: [],
      })
    },
    removeCreative(index) {
      this.creatives = _.chain(this.creatives)
        .cloneDeep()
        .filter((r, i) => i !== index)
        .map(r => _.assign({}, r))
        .value()

      this.errors = []
      this.assetErrors = []

      if (this.creatives.length === 0) this.addCreative()
    },
    copyCreative(i) {
      const creative = _.result(this.creatives, `[${i}]`) || {}

      if (!creative.adFormatId && creative.selectedAssets.length === 0 && creative.selectedTextSets.length === 0) {
        this.$message.warning('入力がないのでコピーできません')
        return
      }

      this.creatives.push(_.cloneDeep(creative))
    },
    calcScore(target, compare) {
      if (!compare) return

      const AorB = target.scoreDefaultTargeting >= compare.scoreDefaultTargeting ? 'A' : 'B'

      if (target.deviationScore >= 55) return '最優先' + AorB
      else if (_.inRange(target.deviationScore, 45, 55)) return '優先' + AorB
      else return 'ストック' + AorB
    },
    async save() {
      const creativeDraftId = await this.saveDraft(true)

      const uri = new URI(this.$api.frontDomain + this.$route.fullPath)
      uri.removeSearch(['imageMovieMasterForm', 'textMasterForm', 'creativeListForm'])
      uri.setSearch({ creativeDraftId })
    },
    async saveDraft(isPredict) {
      const option = { method: 'POST', body: { creativeDraft: this.creatives } }
      if (!isPredict) this.loading = true
      const { creativeDraftId } = await this.$api.authFetch('/creative/draft', option).then(x => {
        if (!isPredict) this.loading = false
        return x
      })
      if (!isPredict) this.loading = false
      if (isPredict) return creativeDraftId

      const uri = new URI(this.$api.frontDomain + this.$route.fullPath)
      uri.removeSearch(['imageMovieMasterForm', 'textMasterForm', 'creativeListForm'])
      uri.setSearch({ creativeDraftId })
      this.$refs.completeDraftDialog.show(uri.toString(), 'creative')
    },
    async regulateAsset() {
      const adFormatIds = this.creatives.flatMap(r => r.adFormatId)
      const data = _.map(adFormatIds, r => {
        const adFormat = this.adFormats.find(x => x.id === r)
        return adFormat ? adFormat : null
      }).filter(x => x !== null)

      const assetLabels = data.map(r => {
        return _.chain(r.assetLabels)
          .filter(r2 => r2.minFileNumber > 0)
          .map(r2 => r2.id)
          .value()
      })
      const bodies = _.chain(this.creatives)
        .map(({ selectedAssets }, i) => ({
          labeledAssets: _.map(selectedAssets, (gr, j) => {
            const diff = _.chain(assetLabels[i])
              .difference(gr.map(r => r.id))
              .value()
              .map(r => {
                return {
                  id: r,
                  selected: [],
                }
              })

            return {
              labeledAssetsKey: `${i + 1}/${j + 1}`,
              labeledAssetsContent: _.map(gr.concat(diff), r => ({
                labelId: r.id,
                originIds: _.map(r.selected, 'originId'),
              })),
            }
          }),
        }))
        .filter(r => r?.labeledAssets?.length !== 0)
        .value()

      this.loading = true
      const assetRegulateResult = await Promise.all(
        _.map(bodies, body =>
          this.$api.authFetch('/asset/regulate', {
            method: 'POST',
            body,
          })
        )
      )
      this.loading = false

      if (_.filter(assetRegulateResult, gr => !_.isEmpty(gr)).length !== 0) {
        const errors = _.chain(assetRegulateResult).map('errors').flattenDeep().value()
        this.assetErrors = _.map(this.creatives, ({ selectedAssets }, i) =>
          _.map(selectedAssets, (gr, j) => {
            const { labeledAssetsContent = [] } =
              _.find(errors, r => _.result(r, 'labeledAssetsKey') === `${i + 1}/${j + 1}`) || {}
            if (labeledAssetsContent.length === 0) return

            return _.chain(gr)
              .map(({ id }) => _.find(labeledAssetsContent, r => r.labelId === id))
              .compact()
              .value()
          })
        )
        await this.$alert('規定エラーがあります。エラーボタンから確認できます。', '確認', {
          type: 'warning',
        })

        return true
      } else {
        this.assetErrors = []
      }
      return
    },
    async regist(isPredict) {
      const urlPromotionId = _.isArray(this.$route.query.promotionIds)
        ? this.$route.query.promotionIds[0]
        : this.$route.query.promotionIds
      if (!isPredict) {
        if (this.type === 'edit' && this.creativesTemp.length > 1) {
          this.$message.error('編集は1素材/1テキストのみ可能です')
          return
        }
        const regulateResult = await this.regulateAsset()
        if (regulateResult) return
      }

      const selectedRequestKeys = _.map(this.selectedCreatives, 'requestKey')
      const baseOmitKey = ['compareCreative', 'isPreview', 'oddAiFlag']
      const omitKey =
        this.type === 'edit'
          ? ['platformId', 'adFormatName', 'referenceUrls']
          : ['creativeId', 'platformId', 'adFormatName']

      const creatives = _.chain(this.creativesTemp)
        .map(r => {
          const validReferenceUrls = (r?.referenceUrls ?? [])
            .map(url => url.replace(/\n+$/, '')) // replace \n to ''
            .filter(url => url.trim() !== '') // remove empty reference urls

          // get reference urls depending on regist type
          const requestingReferenceUrl = { referenceUrls: validReferenceUrls }

          // prepare creative for registration request
          const creative = _.chain(r)
            .omit(baseOmitKey.concat(omitKey)) // remove unnecessary keys
            .assign({}, requestingReferenceUrl) // update reference urls
            .value()

          if (selectedRequestKeys.length === 0)
            return _.assign({}, creative, {
              tagIds: creative.tagsSelected ? creative.tagsSelected.map(tag => tag.tagId).join(',') : [],
            })
          if (!_.includes(selectedRequestKeys, creative.requestKey)) return
          const { score, deviationScore, deviationRank } = _.find(
            this.selectedCreatives,
            r2 => r2.requestKey === creative.requestKey
          )
          return _.assign(
            {},
            creative,
            { score, deviationScore, deviationRank },
            { tagIds: creative.tagsSelected.map(tag => tag.tagId).join(',') }
          )
        })
        .filter()
        .value()
      let result = {}
      if (this.type === 'edit') {
        const option = { method: 'PUT', body: { promotionId: urlPromotionId, creatives } }
        this.loading = true
        result = await this.$api.authFetch('/creative', option)
        if (result.groupedCreatives) {
          const creativeId = result.groupedCreatives[0].creatives.map(x => x.creativeId)
          if (creatives[0].tagsSelected && creatives[0].tagsSelected.length > 0) {
            const tagBody = {
              selectedTargetIds: creativeId,
              targetType: 'creative',
              tagSettings: _.map(creatives[0].tagsSelected, tag => {
                return {
                  tagId: tag.tagId,
                  targetIds: creativeId,
                }
              }),
            }
            await this.$api.authFetch('/tag/set', { method: 'PUT', body: tagBody })
          }
        }
        this.loading = false
      } else {
        const option = {
          method: 'POST',
          body: {
            promotionId: urlPromotionId,
            creatives,
            executionUserId: await this.$gAuth.getEmail(),
            executionUserName: await this.$gAuth.getName(),
          },
        }

        this.loading = true
        result = await this.$api.authFetch('/creative', option).then(x => {
          this.loading = false
          return x
        })
      }
      if (!result) {
        this.$message.error(`${this.typeStr}失敗`)
        return
      }

      if (result.errors) {
        this.textErrors = _.chain(result.errors)
          .map(r => {
            const [creativeIndex, assetIndex, textSetIndex] = r.requestKey
              ? r.requestKey.split('/').map(Number)
              : [1, 1, 1]
            const textSetId = this.creatives[creativeIndex - 1].selectedTextSets[textSetIndex - 1].textSetId
            return { ...r, creativeIndex, textSetIndex, assetIndex, textSetId }
          })
          .orderBy(['textSetIndex'], ['asc'])
          .value()

        await this.$alert('規定エラーがあります。エラーボタンから確認できます。', '確認', {
          type: 'warning',
        })
        return
      }

      if (result && result.errors) {
        this.errors = _.chain(result.errors)
          .map(r => {
            const [creativeIndex, assetIndex, textSetIndex] = r.requestKey.split('/').map(Number)
            const assetGroup = this.creatives[creativeIndex - 1].selectedAssets[assetIndex - 1]
            const labels = _.map(r.labels, label => {
              const { selected, name: labelName } = _.find(assetGroup, ({ id }) => id === label.labelId) || {}
              const targets = _.map(label.targets, target => {
                if (selected.length === 0) return _.assign({}, target)

                const originId = _.first(target.key.split('_'))
                const index = _.findIndex(selected, r2 => r2.originId === originId) + 1
                return _.assign({}, target, { index })
              })
              return _.assign({}, label, { targets, labelName })
            })
            return _.assign({}, r, { labels, creativeIndex, assetIndex, textSetIndex })
          })
          .orderBy(['creativeIndex', 'assetIndex', 'textSetIndex'], ['asc', 'asc', 'asc'])
          .value()
        return
      }
      this.$message.success(`${this.typeStr}成功`)

      const creativeIds = _.chain(result)
        .result('groupedCreatives')
        .map('creatives')
        .flattenDeep()
        .map('creativeId')
        .value()
      this.$router.push({
        path: '/creative-list',
        query: _.assign({}, this.$route.query, { creativeIds }),
      })
    },
  },
  watch: {
    creatives() {
      const containZip = _.chain(this.creatives)
        .map(r => r.selectedAssets)
        .flatten()
        .flatten()
        .map(r => r.selected)
        .flatten()
        .map(r => r.metadata.extension)
        .value()
        .includes('zip')

      const containUrl = _.chain(this.creatives)
        .map(r => r.selectedAssets)
        .flatten()
        .flatten()
        .map(r => r.selected)
        .flatten()
        .map(r => r.metadata.extension)
        .value()
        .includes('youtube')

      this.isContainZipOrUrl(containZip || containUrl)
    },
  },
  computed: {
    height() {
      return this.innerHeight - 60 - 72
    },
    disableDraft() {
      const isValid = this.creatives.every(cr => {
        if (!cr.adFormatId) return false
        return util.methods.isValidReferenceUrls.call(this, cr?.referenceUrls)
      })
      return !isValid
    },
    disableRegulate() {
      return _.filter(this.creatives, r => r.selectedAssets.length !== 0).length === 0
    },
    disableCreative() {
      const judgeSelectedAssets = selectedAssets =>
        _.chain(selectedAssets)
          .cloneDeep()
          .flattenDeep()
          .filter(r2 => (r2.minLength || 0) <= r2.selected.length)
          .value().length !== 0

      const judgeSelectedTextSets = selectedTextSets =>
        _.chain(selectedTextSets)
          .cloneDeep()
          .map('labeledTexts')
          .flattenDeep()
          .filter(r2 => (r2.minLength || 0) <= r2.textValues.length)
          .value().length !== 0
      return this.creatives.every(r => {
        if (!r.adFormatId) return
        const { assetLabels, textLabels } = _.find(this.adFormats, r2 => r2.id === r.adFormatId) || {}
        const isAssetOptional = _.every(assetLabels, r => r.minLength === 0)
        const isAssetsSelected =
          !assetLabels || assetLabels.length === 0 || isAssetOptional
            ? true
            : judgeSelectedAssets(r.selectedAssets, assetLabels)
        const isTextSetsOptional = _.every(textLabels, r => r.minLength === 0)
        const isTextsSelected =
          !textLabels || textLabels.length === 0 || isTextSetsOptional
            ? true
            : judgeSelectedTextSets(r.selectedTextSets, textLabels)
        const isValidReferenceURL = util.methods.isValidReferenceUrls.call(this, r?.referenceUrls)
        return isAssetsSelected && isTextsSelected && isValidReferenceURL
      })
    },
    creativesTemp() {
      return _.chain(this.creatives)
        .map((r, i) =>
          r.selectedAssets.length === 0
            ? [
                _.assign({}, _.omit(r, ['id', 'selectedAssets']), {
                  requestKey: `${i + 1}/0`,
                  labeledAssets: [],
                }),
              ]
            : _.map(r.selectedAssets, (gr, j) => {
                const labeledAssets = _.chain(gr)
                  .filter(r => r.selected.length !== 0)
                  .map(r => ({
                    labelId: r.id,
                    originIds: _.map(r.selected, 'originId'),
                  }))
                  .value()

                return _.assign({}, _.omit(r, ['id', 'selectedAssets']), {
                  requestKey: `${i + 1}/${j + 1}`,
                  labeledAssets,
                })
              })
        )
        .flattenDeep()
        .map(r =>
          r.selectedTextSets.length === 0
            ? [
                _.assign({}, _.omit(r, ['selectedTextSets']), {
                  requestKey: `${r.requestKey}/0`,
                  textSetId: '',
                }),
              ]
            : _.map(r.selectedTextSets, ({ textSetId }, i) =>
                _.assign({}, _.omit(r, ['selectedTextSets']), {
                  requestKey: `${r.requestKey}/${i + 1}`,
                  textSetId,
                })
              )
        )
        .flattenDeep()
        .value()
    },
    confirmMessages() {
      return _.chain(this.creativesTemp)
        .groupBy(r => r.platformId + r.adFormatId)
        .map(gr => {
          const { platformId, adFormatName } = _.first(gr)
          return { platformId, adFormatName, length: gr.length }
        })
        .value()
    },
    typeStr() {
      return { new: '作成', copy: '複製', edit: '編集' }[this.type]
    },
    filteredAccountSettings() {
      return _.filter(this.accountSettings, r => _.includes(this.selectedPlatformIds, r.platformId))
    },
    filteredAccounts() {
      return _.filter(this.accounts, r => _.includes(this.selectedPlatformIds, r.platform))
    },
    selectedPlatformIds() {
      return _.chain(this.creatives).map('platformId').uniq().value()
    },
  },
}
</script>
