import _ from 'lodash'
import approvalStatusOptions from '@/config/approvalStatusOptions'
import approvalStatusCreativeOptions from '@/config/approvalStatusCreativeOptions'
import submissionStatusOptions from '@/config/submissionStatusOptions'
import approvalRequestPriorityOptions from '@/config/approvalRequestPriorityOptions'
import approvalRequestNotifyOptions from '@/config/approvalRequestNotifyOptions'
import imageExtensions from '@/config/imageExtensions'
import videoExtensions from '@/config/videoExtensions'
import musicExtensions from '@/config/musicExtensions'
import moment from 'moment'
import { NUMBER_OF_URL_VALID } from '@/mixins/constant'

/*eslint no-control-regex: "off"*/
export default {
  data() {
    // invalid character list from https://septeni-original.atlassian.net/browse/CV-9547
    const invalidFileNameRegex = new RegExp(/[<>"\\:/\\|?*]/)
    // specialEmojis = ['⚠️', '✅', '✨', '⏰']
    const specialEmojiReg = new RegExp(/\u26a0|\u26a0\ufe0f|\u2705|\u2728|\u23f0/g)
    const japaneseAndFullWidthRegex = new RegExp(
      /[\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3]|[\u3000-\u303F]|[\u3040-\u309F]|[\u30A0-\u30FF]|[\uFF00-\uFFEF]|[\u4E00-\u9FAF]|[\u2605-\u2606]|[\u2190-\u2195]|\u203B/g
    ) // all japanese characters + fullwidth alphanumeric + korean + fullwidth roman
    const whiteAndCtrlCharRegex = new RegExp(/[\u0001-\u0009\u000B-\u001F\u202A\u202B\u202B\u202C\u202D]/g)
    const halfWidthAlphanumericCharRegex = new RegExp(/[\u0020-\u007E]/g)
    const halfKanaReg = new RegExp(/[\uFFE8-\uFFEE\u2985\u2986\u00A2\u00A3\u00A5\u00A6\u00AC\u00AF\u20A9]/g) // 半角判定
    const lineBreakReg = new RegExp(/\r?\n/g) // 改行判定
    const kanaReg = new RegExp(/[\uFF61-\uFF9F]/g) // 半角判定
    const fullWidthReg = new RegExp(/[^\x01-\x7E\xA1-\xDF]/g) // 全角判定
    const extensions = imageExtensions.concat(videoExtensions).concat(musicExtensions)
    const urlReg = new RegExp(
      /(https?|ftp):\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g
    )
    const urlRegForReference = /((http|https):\/\/[\p{L}\p{N}\p{P}\p{S}/-;#~%-]+(?![\w\s?&./;#~%"=-]*>))/gu

    const greeceReg = new RegExp(/[\u0370-\u03ff\u1f00-\u1fff]/g) //greece coptic and greece extended
    return {
      approvalStatusOptions,
      approvalStatusCreativeOptions,
      submissionStatusOptions,
      approvalRequestNotifyOptions,
      approvalRequestPriorityOptions,
      extensions,
      imageExtensions,
      videoExtensions,
      musicExtensions,
      invalidFileNameRegex,
      japaneseAndFullWidthRegex,
      lineBreakReg,
      kanaReg,
      halfKanaReg,
      halfWidthAlphanumericCharRegex,
      fullWidthReg,
      whiteAndCtrlCharRegex,
      minLowSurrogate: '\uDC00',
      maxLowSurrogate: '\uDFFF',
      minHighSurrogate: '\uD800',
      maxHighSurrogate: '\uDBFF',
      specialEmojiReg,
      urlReg,
      urlRegForReference,
      greeceReg,
    }
  },
  methods: {
    lowerCaseFilename(filename) {
      const extension = filename.substr(filename.lastIndexOf('.') + 1).toLowerCase()
      return filename.substr(0, filename.lastIndexOf('.') + 1) + extension
    },
    judgeFileType(extention) {
      if (extention === 'zip') return 'Zip'
      else if (this.musicExtensions.includes(extention)) return 'Music'
      else if (this.videoExtensions.includes(extention)) return 'Video'
      else if (this.imageExtensions.includes(extention)) return 'Image'
      else return 'Url'
    },
    truncate(value, length) {
      if (!value) return ''

      const truncateLength = !length ? 10 : length
      const result = _.reduce(
        value,
        (ret, v) => {
          if (ret.count >= truncateLength) return ret

          if (v.match(this.fullWidthReg)) return { count: (ret.count += 1), text: ret.text + v } // 全角判定
          else return { count: (ret.count += 0.5), text: ret.text + v }
        },
        { count: 0, text: '' }
      )

      return result.text + (result.count <= value.length && truncateLength <= result.count ? '...' : '')
    },
    countStrLength(
      value,
      { charCountLineBreakAs, charCountKanaAs, charCountFullWidthAs, charCountEmojiAs, containUrl, urlAs }
    ) {
      const urls = containUrl ? [...value.matchAll(this.urlReg)] : []
      const finalValue = containUrl ? value.replace(this.urlReg, '') : value
      let ret = containUrl ? urls.length * urlAs : 0
      const characters = Array.from(finalValue).filter(c => c.charCodeAt(0) !== 65039) // 65039 is Unicode character code to indicate that character as an emoji -> remove from character list to count
      const length = _.reduce(
        characters,
        (count, v) => {
          if (this.isIncludesEmoji(v) || this.checkSpecialEmoji(v)) {
            count += charCountEmojiAs
          } else if (v.match(this.lineBreakReg)) {
            if (charCountLineBreakAs) count += charCountLineBreakAs // 改行コード判定
          } else if (v.match(this.kanaReg)) {
            if (charCountKanaAs) count += charCountKanaAs // 半角判定
          } else if (v.match(this.halfWidthAlphanumericCharRegex) || v.match(this.greeceReg)) {
            count += 1
          } else if (v.match(this.halfKanaReg)) {
            count += 1
          } else if (v.match(this.fullWidthReg)) {
            if (v.match(this.japaneseAndFullWidthRegex)) {
              if (charCountFullWidthAs) count += charCountFullWidthAs // 全角判定
            } else {
              count += 1
            }
          } else count += 1
          return count
        },
        0
      )

      return ret + length
    },
    remove_white_and_ctrl_character(text) {
      return text.replaceAll(this.whiteAndCtrlCharRegex, '')
    },
    isFileNameInvalid(name) {
      return name.match(this.invalidFileNameRegex)
    },
    isHighSurrogate(ch) {
      return ch >= this.minHighSurrogate && ch < this.maxHighSurrogate + 1
    },
    isLowSurrogate(ch) {
      return ch >= this.minLowSurrogate && ch < this.maxLowSurrogate + 1
    },
    isIncludesEmoji(value) {
      for (const word of Array.from(value)) {
        const char = word.charAt(0)
        if (this.isHighSurrogate(char) || this.isLowSurrogate(char)) return true
      }
    },
    checkSpecialEmoji(value) {
      return value.match(this.specialEmojiReg)
    },
    checkCharacter(value, labeledText) {
      let ret = []
      if (value.match(this.lineBreakReg) && !labeledText.charCountLineBreakAs) ret.push('lineBreak')
      if ((this.isIncludesEmoji(value) || this.checkSpecialEmoji(value)) && !labeledText.charCountEmojiAs)
        ret.push('emoji')
      if (value.match(this.kanaReg) && !labeledText.charCountKanaAs) ret.push('kana')
      const isHasFullWidth = Array.from(value)
        .map(v => {
          return (
            !v.match(this.lineBreakReg) &&
            !v.match(this.kanaReg) &&
            !this.isIncludesEmoji(v) &&
            !this.checkSpecialEmoji(v) &&
            v.match(this.fullWidthReg) &&
            v.match(this.japaneseAndFullWidthRegex) &&
            !labeledText.charCountFullWidthAs
          )
        })
        .some(x => x === true)
      if (isHasFullWidth) ret.push('fullWidth')
      if (this.countStrLength(value, labeledText) > labeledText.maxCharLength) ret.push('overLength')
      if (value !== '' && this.countStrLength(value, labeledText) < labeledText.minCharLength) ret.push('underLength')
      return ret
    },
    changeFormat(val, column) {
      if (isNaN(val)) return val
      const formatOption = {
        ctr: { style: 'percent', minimumFractionDigits: 7 },
        deviationScore: { style: 'decimal', maximumFractionDigits: 0 },
      }[column]

      if (!formatOption || (!val && val !== 0)) return val
      return new Intl.NumberFormat('ja-JP', formatOption).format(val)
    },
    timeFormat() {
      const m = new Date()
      return (
        m.getFullYear() +
        ('0' + (m.getMonth() + 1)).slice(-2) +
        ('0' + m.getDate()).slice(-2) +
        ('0' + m.getHours()).slice(-2) +
        ('0' + m.getMinutes()).slice(-2) +
        ('0' + m.getSeconds()).slice(-2) +
        ('0' + m.getMilliseconds()).slice(-2)
      )
    },
    datetimeFormat() {
      const m = new Date(
        new Date().toLocaleString('ja-JP', {
          timeZone: 'Asia/Tokyo',
        })
      )
      return (
        m.getFullYear() +
        '-' +
        ('0' + (m.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + m.getDate()).slice(-2) +
        ' ' +
        ('0' + m.getHours()).slice(-2) +
        ':' +
        ('0' + m.getMinutes()).slice(-2) +
        ':' +
        ('0' + m.getSeconds()).slice(-2) +
        ':' +
        ('0' + m.getMilliseconds()).slice(-2)
      )
    },
    getDuplicationItem(lst) {
      return lst.filter((item, index) => lst.indexOf(item) !== index)
    },
    checkCharCountRule(value, min, max) {
      // if no char count min rule or select value length = 0 => true
      // if select value length bigger or equal char count min => true
      // else false
      const checkMin = !min || value === 0 || value >= min

      // if no char count max rule => true
      // if select value length smaller or equal char count max => true
      // else false
      const checkMax = !max || value <= max

      // if check char count min and char count max both true => true
      // else false
      return checkMin && checkMax
    },
    checkUnavailalbleChar(value, labeledText) {
      let ret = []
      if (labeledText.unavailableChars && labeledText.unavailableChars.length !== 0)
        labeledText.unavailableChars.forEach(element => {
          if (value.includes(element)) ret.push(element)
        })
      return ret
    },
    handleTextAreaInput(str) {
      return _.compact(
        str
          .split('\n')
          .join(',')
          .split(',')
          .map(x => x.trim())
      )
    },
    checkContinuousTextMaximumLimit(value, crossRule, labeledText, type) {
      let ret = []
      let continuousRule = crossRule.continuousSymbolRule
      let textSymbolMaximumLimitRule = crossRule.textSymbolMaximumLimitRule
      if (continuousRule.length > 0) {
        if (continuousRule[0].inspectLabels.length !== 0) {
          let rule = _.filter(continuousRule, x =>
            _.includes(
              _.map(x.inspectLabels, label => label.id),
              type ? labeledText.labelId : labeledText.id
            )
          )
          _.map(rule, x =>
            _.map(x.restrictedSymbol, symbol =>
              ret.push(
                this.isContinuous(value, symbol, x.charCountLimit, type ? labeledText.labelName : labeledText.name)
              )
            )
          )
        }
      }
      if (textSymbolMaximumLimitRule.length > 0) {
        if (textSymbolMaximumLimitRule[0].inspectLabels.length !== 0) {
          let rule = _.filter(textSymbolMaximumLimitRule, x =>
            _.includes(
              _.map(x.inspectLabels, label => label.id),
              type ? labeledText.labelId : labeledText.id
            )
          )
          _.map(rule, x =>
            _.map(x.restrictedSymbol, symbol =>
              ret.push(
                this.isTextSymbolMaximumLimitRule(
                  value,
                  symbol,
                  x.charCountLimit,
                  _.map(x.inspectLabels, label => label.name)
                )
              )
            )
          )
        }
      }
      return _.filter(ret, x => x !== '')
    },
    checkC(crossRule, labeledTexts, type) {
      let ret = []
      let creativeSymbolMaximumLimitRule = crossRule.creativeSymbolMaximumLimitRule
      if (creativeSymbolMaximumLimitRule.length > 0) {
        if (creativeSymbolMaximumLimitRule[0].inspectLabels.length !== 0) {
          for (let i = 0; i < creativeSymbolMaximumLimitRule.length; i++) {
            let data = _.map(
              _.filter(labeledTexts, x =>
                _.includes(
                  _.map(creativeSymbolMaximumLimitRule[i].inspectLabels, rule => rule.id),
                  type ? x.labelId : x.id
                )
              ),
              x => x.textValues.join('')
            ).join('')
            _.map(creativeSymbolMaximumLimitRule[i].restrictedSymbol, x =>
              ret.push(
                this.isCreativeSymbolMaximumLimitRule(
                  data,
                  x,
                  creativeSymbolMaximumLimitRule[i].charCountLimit,
                  _.map(creativeSymbolMaximumLimitRule[i].inspectLabels, label => label.name)
                )
              )
            )
          }
        }
      }
      return _.filter(ret, x => x !== '')
    },

    isContinuous(value, restrictSymbol, charCount, name) {
      let ret = ''
      let newRestric =
        '[' + _.map(restrictSymbol, x => this.replaceSymbols(x)).join('') + ']' + '{' + (parseInt(charCount) + 1) + ',}'
      let flags = 'g'
      let regex = new RegExp(newRestric, flags)
      if (regex.test(value))
        ret = name + ':「 ' + restrictSymbol + '」 は' + (parseInt(charCount) + 1) + '回以上連続で使用できません'
      return ret
    },

    replaceSymbols(char) {
      return ['?', '!', '(', ')', '[', ']', '{', '}', '^', '$', '-', '|', '/', '.'].includes(char) ? `\\${char}` : char
    },

    isTextSymbolMaximumLimitRule(value, restrictSymbol, charCount, name) {
      let ret = ''
      let newRestric = '[' + _.map(restrictSymbol, x => this.replaceSymbols(x)).join('') + ']'
      let flags = 'g'
      let regex = new RegExp(newRestric, flags)
      if (regex.test(value)) {
        ret =
          regex[Symbol.match](value).length > charCount
            ? name.join(',') +
              ':1ラベルの1文に' +
              '「 ' +
              restrictSymbol +
              '」は' +
              (parseInt(charCount) + 1) +
              '回以上使用できません。'
            : ''
      }
      return ret
    },

    isCreativeSymbolMaximumLimitRule(value, restrictSymbol, charCount, name) {
      let ret = ''
      let newRestric = '[' + _.map(restrictSymbol, x => this.replaceSymbols(x)).join('') + ']'
      let flags = 'g'
      let regex = new RegExp(newRestric, flags)
      if (regex.test(value)) {
        ret =
          regex[Symbol.match](value).length > charCount
            ? name.join(',') +
              ': クリエイティブに' +
              '「 ' +
              restrictSymbol +
              '」は' +
              (parseInt(charCount) + 1) +
              '回以上使用できません。'
            : ''
      }
      return ret
    },

    valueToCheckCreativeSymbolMaximumLimitRule(labeledTexts, inspectLabelIds) {
      let label = _.map(
        _.filter(labeledTexts, x => _.includes(inspectLabelIds, x.id)),
        x => x.textValues.join('')
      ).join('')
      return label
    },

    getTextWidth(text, font) {
      const canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement('canvas'))
      const context = canvas.getContext('2d')
      context.font = font
      const metrics = context.measureText(text)
      return metrics.width
    },

    getBorderColor(canEdit) {
      return canEdit ? '#ebeef5' : '#d6d6d6'
    },

    countAdFormatName(name) {
      // change adformat fontsize (current: 13px)
      if (name.length) {
        return this.getTextWidth(name, '13px') > 214.326171875
      } else {
        return false
      }
    },
    getExtensionFromS3Url(url) {
      const urlSplit = url.split('.')
      return urlSplit[urlSplit.length - 1]
    },
    formatDate(date) {
      return date ? moment(date).format('YYYY/MM/DD') : ''
    },
    isReferenceError(potentialUrlStr) {
      const urls = potentialUrlStr.match(this.urlRegForReference)
      return urls?.length > NUMBER_OF_URL_VALID
    },

    isValidReferenceUrls(referenceUrls) {
      const isUrlsError = referenceUrls.find(x => this.isReferenceError(x) === true)
      return !isUrlsError
    },

    convertApprovalStatusOptionsJapaneseToEnglish(approvalStatus) {
      switch (approvalStatus) {
        case '承認':
          return 'Approved'
        case '入稿可能':
          return 'Approved'
        case '破棄':
          return 'DeliveryNG'
        case '入稿不可':
          return 'DeliveryNG'
        case '非承認':
          return 'NotApproved'
        case '監修未提出':
          return 'WaitingForApproval'
        case '監修中':
          return 'CustomerChecking'
        case '要修正':
          return 'Fixing'
        case '承認待ち':
          return 'Checking'
        case '入稿依頼済':
          return 'SendSubmitRequest'
        case '入稿済':
          return 'Submitted'
        default:
          return approvalStatus
      }
    },
  },
}
