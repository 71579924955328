<template>
  <el-dialog
    id="copy-text-set-dialog"
    v-loading="loading"
    :visible.sync="isShow"
    title="テキスト複製"
    fullscreen
    lock-scroll
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form class="scroll" :style="{ height: height + 'px' }">
      <el-card class="m-1" shadow="never">
        <el-col :span="20" class="p-1 border-r-1">
          <el-row>
            <el-tag type="info" class="text-center font-bold mr-1 mb-1">
              {{ platformId }}
            </el-tag>

            <el-tag type="info" class="text-center font-bold mr-1 mb-1">
              {{ adFormatName }}
            </el-tag>
          </el-row>

          <el-row v-if="formTextSet.errors">
            <el-row v-for="(error, i) in formTextSet.errors" :key="i" class="mb-1 text-red lh-0 mt-2">
              {{ error }}
            </el-row>
          </el-row>

          <el-row>
            <el-row v-for="(labeledTextArray, k) in require('lodash').chunk(formTextSet.labeledTexts, 3)" :key="k">
              <el-form-item class="float-left w-33" v-for="(labeledText, i) in labeledTextArray" :key="i + k * 3">
                <el-row>
                  <div class="float-left mr-2">
                    {{ labeledText.labelName }}
                    <el-tag v-if="labeledText.minLength && labeledText.minLength !== 0" class="required-tag">
                      <span> 必須 </span>
                    </el-tag>

                    <el-tag v-else class="option-tag">
                      <span> 任意 </span>
                    </el-tag>
                  </div>

                  <span @click="addLabeledText(i + k * 3)">
                    <i class="fas fa-plus-circle" />
                  </span>
                </el-row>

                <div id="text-area" class="pr-1" v-for="(v, j) in labeledText.textValues" :key="`${i + k * 3}_${j}`">
                  <el-col class="mb-1" :span="20">
                    <el-row>
                      <div class="float-left w-100">
                        <div v-if="labeledText.ctaPhrases == null">
                          <el-input
                            v-model="labeledText.textValues[j]"
                            type="textarea"
                            class="float-left"
                            @input="
                              v => {
                                labeledText.textValues[j] = remove_white_and_ctrl_character(v)
                                labeledText.regulationErrors[j] = checkCharacter(
                                  remove_white_and_ctrl_character(v),
                                  labeledText
                                )
                                labeledText.unavailableCharsErrors[j] = checkUnavailalbleChar(
                                  remove_white_and_ctrl_character(v),
                                  labeledText
                                )
                                labeledText.crossRuleErrors[j] = checkContinuousTextMaximumLimit(
                                  v,
                                  adFormat.crossRule,
                                  labeledText,
                                  'copy'
                                )
                                formTextSet.errors = setTextSetError(
                                  formTextSet,
                                  checkC(adFormat.crossRule, formTextSet.labeledTexts, 'copy')
                                )
                              }
                            "
                            :class="{
                              'border-red': !checkCharCountRule(
                                countStrLength(labeledText.textValues[j], labeledText),
                                labeledText.minCharLength,
                                labeledText.maxCharLength
                              ),
                            }"
                          />
                        </div>
                        <div v-else>
                          <el-select
                            v-model="labeledText.textValues[j]"
                            style="width: 100%"
                            clearable
                            placeholder="ご選択ください。"
                            @change="
                              value => {
                                labeledText.crossRuleErrors[j] = checkContinuousTextMaximumLimit(
                                  value,
                                  adFormat.crossRule,
                                  labeledText,
                                  'copy'
                                )
                                formTextSet.errors = setTextSetError(
                                  formTextSet,
                                  checkC(adFormat.crossRule, formTextSet.labeledTexts, 'copy')
                                )
                              }
                            "
                          >
                            <el-option
                              v-for="(item, i) in labeledText.ctaPhrases"
                              :key="`${item}_${i}`"
                              :label="item"
                              :value="item"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>

                      <span
                        v-if="
                          labeledText.textValues.length > labeledText.minLength && labeledText.textValues.length > 1
                        "
                        class="badge lh-0"
                        @click="removeTextArea(i + k * 3, j)"
                      >
                        <i class="fas fa-times-circle icon-red" />
                      </span>
                    </el-row>

                    <el-row class="mt-1">
                      <el-row v-if="labeledText.regulationErrors[j].includes('overLength')" class="text-red lh-2">
                        <span>文字数がオーバーしています。</span>
                        <span v-if="labeledText.containParameter"
                          >広告カスタマイザを使用している場合はそのまま保存ボタンを押してください。</span
                        >
                      </el-row>

                      <el-row v-if="labeledText.regulationErrors[j].includes('underLength')" class="text-red lh-2">
                        <span>文字数は{{ labeledText.minCharLength }}文字以上でなければいけません。</span>
                        <span v-if="labeledText.containParameter"
                          >広告カスタマイザを使用している場合はそのまま保存ボタンを押してください。</span
                        >
                      </el-row>

                      <el-row v-if="labeledText.regulationErrors[j].includes('lineBreak')" class="text-red lh-2">
                        改行は禁止です
                      </el-row>

                      <el-row v-if="labeledText.regulationErrors[j].includes('emoji')" class="text-red lh-2">
                        絵文字は禁止です
                      </el-row>

                      <el-row v-if="labeledText.regulationErrors[j].includes('kana')" class="text-red lh-2">
                        半角カナは禁止です
                      </el-row>

                      <el-row v-if="labeledText.regulationErrors[j].includes('fullWidth')" class="text-red lh-2">
                        全角文字は禁止です
                      </el-row>
                      <el-row v-if="labeledText.unavailableCharsErrors[j].length !== 0" class="text-red lh-2">
                        「{{ labeledText.unavailableCharsErrors[j].join(', ') }}」は使用不可文字として登録されています。
                      </el-row>
                    </el-row>
                  </el-col>

                  <el-col :span="4">
                    <div
                      v-if="labeledText.ctaPhrases == null"
                      class="lh-1 text-right"
                      :class="
                        checkCharCountRule(
                          countStrLength(labeledText.textValues[j], labeledText),
                          labeledText.minCharLength,
                          labeledText.maxCharLength
                        )
                          ? 'text-gray'
                          : 'text-red'
                      "
                    >
                      {{ countStrLength(labeledText.textValues[j], labeledText) }} /
                      {{ labeledText.maxCharLength ? labeledText.maxCharLength : 'なし' }}
                    </div>
                  </el-col>
                </div>
              </el-form-item>
            </el-row>
          </el-row>
        </el-col>

        <el-col :span="4" class="p-1">
          <el-tag type="info" class="text-center font-bold mr-1 mb-1" v-for="(r, i) in formTextSet.tags" :key="i">
            {{ r.tagValue }}
          </el-tag>

          <div class="text-center mt-2 font-big">
            <span @click="openTagDialog">
              <i class="fas fa-plus-circle" />
            </span>
          </div>
        </el-col>
      </el-card>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="copy"
        :disabled="loading || isDisplayRegulationError || isIncludesEmptyTextValues([formTextSet])"
      >
        保存
      </el-button>
    </span>
  </el-dialog>
</template>

<style>
#text-area .el-form-item__content {
  line-height: initial;
}
#text-area .border-red .el-textarea__inner {
  border: 0;
}
#copy-text-set-dialog .el-card__body {
  padding: 0px;
}
#copy-text-set-dialog .el-card:not(:last-of-type) {
  margin-bottom: 0.5rem;
}
</style>

<style scoped>
.border-red {
  border: 1px solid #f56c6c;
  border-radius: 4px;
  box-sizing: border-box;
}
.text-red {
  color: #f56c6c;
}
.text-gray {
  color: #909399;
}
.font-big {
  font-size: 22px;
}
.border-r-1 {
  border-right: solid 1px #ebeef5;
}
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
.icon-red {
  color: #f56c6c;
}
.lh-0 {
  line-height: 0;
}
.lh-1 {
  line-height: 3.5rem;
}
.m-1 {
  margin: 1rem;
}
.ml-1 {
  margin-left: 0.5rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.mt-2 {
  margin-top: 1rem;
}
.mr-2 {
  margin-right: 1rem;
}
.p-1 {
  padding: 1rem;
}
.pr-1 {
  padding-right: 1rem;
}
.float-left {
  float: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.w-0 {
  width: 0;
}
.w-100 {
  width: 100%;
}
.w-33 {
  width: 33%;
}
.h-100 {
  height: 100%;
}
.font-bold {
  font-weight: bold;
}
.badge {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 2;
}
.scrollable .el-message-box__content {
  overflow-y: scroll;
  max-height: 500px;
}

.required-tag {
  align-items: center;
  justify-content: center;
  height: 17px;
  width: 40px;
  background-color: #f56c6c;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  padding-left: 9px;
  -webkit-clip-path: polygon(15px 0%, 100% 0%, 100% 100%, 15px 100%, 0 50%);
  clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0 50%);
  line-height: 17px;
}
.option-tag {
  align-items: center;
  justify-content: center;
  height: 17px;
  width: 40px;
  background-color: #00d7bf;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  padding-left: 9px;
  -webkit-clip-path: polygon(15px 0%, 100% 0%, 100% 100%, 15px 100%, 0 50%);
  clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0 50%);
  line-height: 17px;
}
</style>

<script>
import _, { clone } from 'lodash'
import util from '@/mixins/util'

export default {
  name: 'copy-text-set-dialog',
  mixins: [util],
  props: {
    defaultPromotionId: { type: String, default: () => '' },
    platformId: { type: String, default: () => '' },
    adFormatId: { type: String, default: () => '' },
    adFormatName: { type: String, default: () => '' },
    adFormats: { type: Array, default: () => [] },
    adFormat: { type: Object, default: () => {} },
  },
  data: () => ({
    loading: false,
    isShow: false,
    formTextSet: {},
    height: window.innerHeight - 34 - 70,
  }),
  created() {
    window.RegistTextSetDialog = this
    window.addEventListener('resize', () => {
      this.height = window.innerHeight - 34 - 70
    })
  },
  methods: {
    initalizeData(labeledText, type) {
      return labeledText.textValues.map(t => {
        switch (type) {
          case 'regulationErrors': {
            return this.checkCharacter(this.remove_white_and_ctrl_character(t), labeledText)
          }
          case 'unavailableCharsErrors': {
            return this.checkUnavailalbleChar(this.remove_white_and_ctrl_character(t), labeledText)
          }
          case 'crossRuleErrors': {
            return this.checkContinuousTextMaximumLimit(
              this.remove_white_and_ctrl_character(t),
              this.adFormat.crossRule,
              labeledText,
              'edit'
            )
          }
          default:
            break
        }
      })
    },
    removeTextArea(j, k) {
      this.removeLabeledText(j, k)
      const cloneFormTextSet = clone(this.formTextSet)
      cloneFormTextSet.labeledTexts.forEach(labeledText => {
        labeledText.regulationErrors = []
        labeledText.unavailableCharsErrors = []
        labeledText.crossRuleErrors = []
        labeledText.textValues.forEach((v, j) => {
          labeledText.regulationErrors[j] = this.checkCharacter(this.remove_white_and_ctrl_character(v), labeledText)
          labeledText.unavailableCharsErrors[j] = this.checkUnavailalbleChar(
            this.remove_white_and_ctrl_character(v),
            labeledText
          )
          labeledText.crossRuleErrors[j] = this.checkContinuousTextMaximumLimit(
            v,
            this.adFormat.crossRule,
            labeledText,
            'copy'
          )
        })
      })
      cloneFormTextSet.errors = this.setTextSetError(
        cloneFormTextSet,
        this.checkC(this.adFormat.crossRule, cloneFormTextSet.labeledTexts, 'copy')
      )
      this.formTextSet = cloneFormTextSet
    },
    show(textSet) {
      this.isShow = true
      this.formTextSet = _.cloneDeep(textSet)
      const unresolevedLabels = _.chain(this.adFormat.textLabels)
        .map(l => ({
          ...l,
          labelId: l?.id,
          labelName: l?.name,
          textValues: [],
        }))
        .value()
      this.formTextSet.labeledTexts = _.chain(this.formTextSet.labeledTexts)
        .map(r => {
          return _.assign({}, r, {
            regulationErrors: this.initalizeData(r, 'regulationErrors'),
            errors: [],
            targetErrors: [],
            unavailableCharsErrors: this.initalizeData(r, 'unavailableCharsErrors'),
            crossRuleErrors: this.initalizeData(r, 'crossRuleErrors'),
          })
        })
        .concat(unresolevedLabels)
        .uniqBy('labelId')
        .map(r => {
          const labels = _.chain(this.adFormats)
            .find(r => r.id === this.adFormatId)
            .result('textLabels')
            .value()

          const label = _.chain(labels)
            .find(labelT => labelT.id === r.labelId)
            .result('ctaPhrases')
            .value()

          return _.assign({}, r, { ctaPhrases: label })
        })
        .value()
      this.formTextSet.errors = this.setTextSetError(
        this.formTextSet,
        this.checkC(this.adFormat.crossRule, this.formTextSet.labeledTexts, 'edit')
      )
    },
    openTagDialog() {
      const params = {
        targetType: 'textSet',
        selectedTags: this.selectedTags,
        targetIds: [_.result(this.formTextSet, 'textSetId')],
      }
      this.$root.$children[0].$refs.tagDialog.show(params)
    },
    addLabeledText(i) {
      this.formTextSet.labeledTexts = _.chain(this.formTextSet.labeledTexts)
        .map((r, index) => {
          if (index !== i) return r
          else if (r.maxLength <= r.textValues.length) {
            this.$message.warning(`${r.labelName}はこれ以上追加できません`)
            return r
          } else {
            return _.assign({}, r, {
              textValues: _.cloneDeep(r.textValues).concat(['']),
              regulationErrors: _.cloneDeep(r.regulationErrors ? r.regulationErrors : []).concat([[]]),
              unavailableCharsErrors: _.cloneDeep(r.unavailableCharsErrors ? r.unavailableCharsErrors : []).concat([
                [],
              ]),
              crossRuleErrors: _.cloneDeep(r.crossRuleErrors ? r.crossRuleErrors : []).concat([[]]),
            })
          }
        })
        .cloneDeep()
        .value()
    },
    removeLabeledText(i, j) {
      this.formTextSet.labeledTexts = _.chain(this.formTextSet.labeledTexts)
        .map((r, index) =>
          index === i && r.textValues.length > r.minLength
            ? _.assign({}, r, { textValues: _.filter(r.textValues, (r2, index2) => index2 !== j) })
            : r
        )
        .cloneDeep()
        .value()
    },
    async copy() {
      const { textSetId, labeledTexts } = this.formTextSet
      const form = {
        promotionId: this.defaultPromotionId,
        adFormatId: this.adFormatId,
        textSetId,
        labeledTexts: _.map(labeledTexts, ({ labelId, textValues }) => ({
          labelId,
          textValues: _.filter(textValues),
        })),
      }

      this.loading = true
      const result = await this.$api.authFetch('/text_set', { method: 'POST', body: form })
      this.loading = false

      if (!result) {
        this.$message.error('編集失敗')
        return
      }

      if (result && result.errors) {
        let adformatErrors = result.errors.map(adformatError => {
          const labelNames = adformatError.labelNames.join(', ')
          const errorMess = adformatError.error
          return labelNames.concat(': ', errorMess)
        })

        let messages = _.map(result.labels, r => {
          const errLabel = '<div><ul>' + r.errors.map(r => `<li>${r}</li>`).join('') + '</ul></div>'
          let textData = {}
          const errorResponse = _.find(this.formTextSet.labeledTexts, r2 => r.labelId === r2.labelId)
          if (!_.isUndefined(errorResponse)) {
            textData = errorResponse
          } else {
            const unselectedLabel = _.chain(this.adFormat?.textLabels)
              .find(r2 => r.labelId === r2.id)
              .value()
            textData = !_.isEmpty(unselectedLabel)
              ? { labelName: unselectedLabel.name, textValues: [] }
              : { labelName: '', textValues: [] }
          }
          const { labelName, textValues } = textData
          const detail =
            '<ul>' +
            r.targets
              .map(target => {
                const errorTextValue = target.key.substring(0, target.key.lastIndexOf('_'))
                const index = textValues.indexOf(errorTextValue)
                const key = `${index + 1}番目`
                return target.errors
                  .map(v => {
                    if (v.includes('商材NGワードとして設定されています。')) {
                      const error = v.substring(0, v.indexOf('\\n'))
                      return `<li>${error.replace(labelName, key)}</li>`
                    } else {
                      return `<li>${v.replace(labelName, key)}</li>`
                    }
                  })
                  .join('')
              })
              .sort()
              .join('') +
            '</ul>'

          return `${labelName}${r.errors.length > 0 ? errLabel : ''}${detail}`
        })
        messages = adformatErrors.concat(messages)
        if (!messages.every(x => !x.includes('商材NGワードとして設定されています。'))) {
          const link = `${this.$api.getRegulationDomain()}/advertisers`
          const NGalert =
            '<div>NGワードに変更がある場合は<a href=' +
            link +
            ' target="_blank">こちら</a>からご確認お願いいたします。</div>'
          messages.unshift(NGalert)
        }
        this.$alert(messages.join('<br>'), 'レギュレーションエラー', {
          dangerouslyUseHTMLString: true,
          customClass: 'scrollable',
        })
        return
      }

      this.$message.success('複製成功')
      this.$emit('get-text-sets')
      this.isShow = false
    },
    isIncludesEmptyTextValues(gr) {
      return (
        _.filter(
          gr,
          ({ labeledTexts }) =>
            !_.find(labeledTexts, ({ textValues, minLength }) =>
              !minLength ? false : _.chain(textValues).take(minLength).compact().value().length < minLength
            )
        ).length === 0
      )
    },

    setTextSetError(textSet, creativeRule) {
      let ret = []
      let error = _.map(textSet.labeledTexts, label => _.map(label.crossRuleErrors, error => error))
      _.map(error, x => _.map(x, errors => _.map(errors, err => ret.push(err))))
      return _.concat(_.uniqBy(ret), creativeRule)
    },
  },
  computed: {
    selectedTags() {
      return _.chain(this.formTextSet)
        .result('tags')
        .groupBy('tagId')
        .mapValues(() => ({
          selectedTargetIds: [_.result(this.formTextSet, 'textSetId')],
          isAllSelected: true,
        }))
        .value()
    },
    isDisplayRegulationError() {
      return (
        _.chain([this.formTextSet])
          .cloneDeep()
          .filter(({ labeledTexts }) =>
            _.chain(labeledTexts)
              .find(({ regulationErrors, containParameter, unavailableCharsErrors }) =>
                containParameter
                  ? false
                  : !_.chain(regulationErrors).flattenDeep().isEmpty().value() ||
                    !_.chain(unavailableCharsErrors).flattenDeep().isEmpty().value()
              )
              .value()
          )
          .value().length !== 0 ||
        !_.every(
          _.map([this.formTextSet], x => x.errors),
          error => error.length === 0
        )
      )
    },
  },
}
</script>
