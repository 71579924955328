<template>
  <el-dialog
    id="version-dialog"
    custom-class="small-dialog"
    title="過去ver"
    width="80%"
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div v-loading="loading" class="p-2">
      <div v-if="chunkedRevisions.length !== 0" class="maxh-500px scroll">
        <el-row v-for="(revisions, i) in chunkedRevisions" :key="i">
          <el-col class="p-1" v-for="(revision, j) in revisions" :key="j">
            <version-card :revision="revision" />
          </el-col>
        </el-row>
      </div>

      <el-row v-else class="maxh-500px scroll" type="flex" align="middle">
        <el-col class="text-center font-big"> 過去バージョンはありません </el-col>
      </el-row>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="isShow = false"> 閉じる </el-button>
    </span>
  </el-dialog>
</template>

<style scoped>
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 30px 10px;
}
.maxh-500px {
  max-height: 500px;
}
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
.text-center {
  text-align: center;
}
.font-big {
  font-size: 20px;
}
</style>

<script>
import _ from 'lodash'
import VersionCard from '@/views/text-master/ad-format-tab/version-dialog/components/version-card'

export default {
  name: 'version-dialog',
  components: { VersionCard },
  data: () => ({ loading: false, isShow: false, revisions: [], textSetId: '' }),
  created() {
    window.VersionDialog = this
  },
  methods: {
    async show(textSetId) {
      this.isShow = true
      if (this.textSetId === textSetId) return

      this.textSetId = textSetId
      this.loading = true
      this.revisions = _.chain(await this.$api.authFetch(`/text_set/revision/${textSetId || ''}`, { method: 'GET' }))
        .result('textSets')
        .flattenDeep()
        .value()
      this.loading = false
    },
  },
  computed: {
    chunkedRevisions() {
      return _.chain(this.revisions).orderBy(['version'], ['asc']).chunk(5).value()
    },
  },
}
</script>
