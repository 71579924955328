<template>
  <el-dialog
    v-if="isShow"
    id="select-approval-request-type-dialog"
    custom-class="small-dialog"
    width="30%"
    title="監修依頼"
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    append-to-body
  >
    <div v-loading="loading" class="p-1 text-center">
      <el-row class="mb-1"> どちらの方法で提出するか選択してください </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-button type="primary" @click="close('creative')" style="line-height: 20px">
            掛け合わせた状態の<br />
            クリエイティブで提出
          </el-button>
        </el-col>

        <el-col :span="12">
          <el-button type="primary" @click="close('asset/textSet')" style="line-height: 20px">
            画像/動画とテキストを<br />
            ユニークにして提出
          </el-button>
        </el-col>
      </el-row>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="isShow = false" type="primary"> 閉じる </el-button>
    </span>
  </el-dialog>
</template>

<style scoped>
.p-1 {
  padding: 30px 20px;
}
.mt-1 {
  margin-top: 3rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.text-center {
  text-align: center;
}
.w-100 {
  width: 100%;
}
</style>

<script>
export default {
  name: 'select-approval-request-type-dialog',
  data: () => ({
    isShow: false,
    loading: false,
    viewIndex: 0,
    channels: [],
    filterWord: '',
    type: '',
  }),
  async created() {
    window.SelectApprovalRequestTypeDialog = this
    this.loading = false
  },
  methods: {
    show(type) {
      this.type = type
      this.viewIndex = 1
      this.filterWord = ''
      this.isShow = true
    },
    close(arType) {
      this.isShow = false
      this.$emit('open-approval-request-dialog', arType)
    },
    load() {
      this.viewIndex++
    },
  },
}
</script>
