<template>
  <el-card
    id="asset-item-box"
    shadow="never"
    :body-style="{ padding: '8px 16px', 'word-break': 'break-all' }"
    class="font-mini"
    style="overflow: visible; position: relative; min-width: 230px; min-height: 300px"
  >
    <!--  checkbox function -->
    <el-row type="flex" align="center">
      <div class="action-icon-list">
        <el-checkbox
          v-if="!isDisplay"
          :label="asset.originId"
          :disabled="containError && !onAssetSelectDialog"
          @change="$emit('uncheck-asset', asset.assetId)"
        />
        <div
          v-if="isValidRoute && asset.assetType !== 'Url'"
          @click="$refs.renameDialog.show(asset.originId, asset.assetId, asset.metadata)"
        >
          <i class="fas fa-pen" />
        </div>
        <div v-if="isValidRoute" @click="removeAsset(asset.originId)">
          <i class="fas fa-trash-alt" />
        </div>
      </div>
    </el-row>

    <!--  file name  -->
    <el-row class="mb-1 w-100" type="flex" align="center">
      <el-col>
        <div class="font-mini line-1">
          {{ asset.metadata.fileName }}
        </div>
        <div class="float-left font-mini line-1">
          {{ asset.metadata.rawFileName }}
        </div>
      </el-col>
    </el-row>

    <!-- approval status -->
    <el-row>
      <el-row style="margin-bottom: 1vh">
        <el-col>
          <el-select
            v-loading="loading"
            v-if="![appRoute.CREATIVE_LIST].includes($route.path) && isAssetMaster && isValidRoute"
            v-model="asset.approvalStatus"
            placeholder="Select"
            size="small"
            style="width: 100%"
            @change="status => changeStatus(status)"
          >
            <el-option v-for="(option, i) in approvalStatusOptions" :key="i" :label="option" :value="option" />
          </el-select>

          <el-tag v-else type="info" class="w-100 font-bold">
            {{ asset.approvalStatus }}
          </el-tag>
        </el-col>
      </el-row>
    </el-row>

    <!-- asset -->
    <el-row class="mb-1">
      <image-movie-item class="mb-1" :url="asset.url" :thumbnail-url="asset.thumbnailUrl" :type="asset.assetType" />
    </el-row>

    <!-- tags -->
    <el-row v-if="asset.tags && asset.tags.length !== 0" class="mt-1">
      <span v-for="(tag, i) in asset.tags" :key="`tag_${i}`">
        <el-popover v-if="tag.tagTypeName || tag.description" trigger="hover">
          <div v-if="tag.tagTypeName">カテゴリ: {{ tag.tagTypeName }}</div>
          <div v-if="tag.description">説明: {{ tag.description }}</div>
          <div
            slot="reference"
            class="font-bold mr-1 mb-1 tag tag-small"
            :style="{ background: tag.color || '#909399', color: '#FFFFFF' }"
          >
            {{ tag.tagValue }}
          </div>
        </el-popover>

        <el-tag v-else type="info" class="tag tag-small font-bold mr-1 mb-1">
          {{ tag.tagValue }}
        </el-tag>
      </span>
    </el-row>

    <!-- psdURL -->
    <el-row>
      <el-row class="mb-1" key="psdURL-title"> <i class="fas fa-copy" /> BOX URL </el-row>
      <el-row class="mb-1">
        <el-input
          class="w-100"
          size="mini"
          v-model="psdURL"
          :rows="2"
          @input="
            v => {
              debounceSavePsdUrl(v)
            }
          "
        />
      </el-row>
    </el-row>

    <!-- error -->
    <div v-if="containError" style="position: relative">
      <i class="el-icon-warning error-mark"></i>
      <span class="error-text">規定違反があります。</span>
      <el-button
        type="danger"
        size="mini"
        class="error-detail-btn"
        @click="
          () => {
            isShowErrorDetail = true
            this.$emit('change-id-open-error', asset.assetId)
          }
        "
        >詳細確認</el-button
      >
    </div>

    <el-card :body-style="{ padding: '0' }" v-show="isShowErrorDetail" class="error-detail">
      <div class="el-dialog__header d-flex error-detail__header">
        <p class="error-detail__header-title">規定違反の詳細</p>
        <el-button class="error-detail__header-closeBtn" @click="isShowErrorDetail = false">
          <i class="el-icon-close"></i>
        </el-button>
      </div>
      <div class="error-detail__content">
        <section>
          <p v-for="(error, i) in this.errorList" :key="`error_${i}`" class="color-danger font-bold font-mini2">
            ・{{ error }}
          </p>
        </section>
      </div>
      <div class="d-flex error-detail__controller">
        <el-button type="primary" size="mini" @click="copyError" style="padding: 7px"> 規定違反内容を取得 </el-button>
      </div>
    </el-card>

    <!-- metadata  -->
    <el-row>
      <el-row class="mb-1">
        <el-divider class="m-0 d-flex w-100 divider" />
      </el-row>
      <el-row class="mb-1">
        <metadata-item
          :version="asset.version"
          :metadata="asset.metadata"
          :errorList="errorTagList"
          @open-version-dialog="$refs.versionDialog.show(asset.originId)"
        />
      </el-row>
    </el-row>

    <rename-dialog :whitelist="whitelist" ref="renameDialog" />
    <version-dialog ref="versionDialog" />
  </el-card>
</template>

<style>
* {
  --danger-color: #f56c6c;
}
#asset-item-box .el-checkbox__label {
  display: none;
}

.el-popover {
  word-wrap: break-word !important;
}
.d-flex {
  display: flex;
}

/* error */
.error-mark {
  color: var(--danger-color);
  transform: scale(1.75);
  margin-right: 6px;
}

.error-text {
  color: var(--danger-color);
  font-weight: bold;
  font-size: 12px;
}

.error-detail-btn {
  padding: 4px 6px !important;
  font-size: 12px !important;
  letter-spacing: 0.3px;
}

/* error detail */
.error-detail {
  max-height: 90%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  border: none !important;
  overflow-y: scroll !important;
}

.error-detail::-webkit-scrollbar {
  width: 0;
}

.error-detail__header {
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px !important;
  border-radius: 4px 4px 0 0;
}

.error-detail__header-title {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
}

.error-detail__header-closeBtn {
  background-color: #fff;
  width: 20px;
  height: 20px;
  padding: 0 !important;
  border-radius: 5px;
  overflow: hidden;
  border: none !important;
}

.error-detail__header-closeBtn .el-icon-close {
  font-size: 20px;
  font-weight: 900;
  border-radius: 0;
}

.error-detail__content {
  background-color: #fff;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
}

.error-detail__content p {
  margin: 0 0 8px 0;
}

.error-detail__controller {
  flex-direction: row-reverse;
  position: sticky;
  bottom: 0;
  padding: 5px 8px;
  background: #fff;
  border-radius: 0 0 4px 4px;
}
</style>

<style scoped>
.m-0 {
  margin: 0;
}
.mt-1 {
  margin-top: 0.5rem;
}
.ml-1 {
  margin-left: 0.5rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.font-mini {
  font-size: 10px;
  cursor: default;
}
.font-mini2 {
  font-size: 12px;
}
.font-bold {
  font-weight: bold;
}
.float-left {
  float: left;
}
.line-1 {
  line-height: 18px;
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.action-icon-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 7px;
}
.text-center {
  text-align: center;
}
fieldset {
  border: 2px solid #f56c6c;
  border-radius: 5px;
  margin-bottom: 5px;
}
legend {
  color: #f56c6c;
  font-size: 14px;
  font-weight: bold;
}
.tag {
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}
.tag-small {
  padding: 5px;
  height: auto;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
}
.w-100.divider {
  /* 16px is padding x direction of box */
  width: calc(100% + 16px * 2);
  margin-left: -16px;
}
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'
import MetadataItem from '@/views/image-movie-master/pixel-size-tab/asset-item-box/components/metadata-item'
import ImageMovieItem from '@/components/image-movie-item'
import RenameDialog from '@/views/image-movie-master/pixel-size-tab/asset-item-box/components/rename-dialog'
import VersionDialog from '@/views/image-movie-master/pixel-size-tab/version-dialog'
import { AppRoute } from '@/mixins/appRoute'
import { MessageBox } from 'element-ui'

export default {
  name: 'asset-item-box',
  mixins: [util],
  components: { MetadataItem, ImageMovieItem, RenameDialog, VersionDialog },
  props: {
    asset: { type: Object, default: () => ({}) },
    isDisplay: { type: Boolean, default: () => false },
    isInDialog: { type: Boolean, default: () => false },
    idOpenError: { type: String, default: () => '' },
    whitelist: { type: Array, default: () => [] },
    isAssetMaster: { type: Boolean, default: () => false },
    assetErrors: { type: Array, default: () => [] },
    onAssetSelectDialog: { type: Boolean, default: () => false },
  },
  inject: {
    isInCreateCreativeDialog: {
      from: 'isInCreateCreativeDialog',
      default: false,
    },
  },
  data: () => ({
    notApprovalReasonIndex: null,
    psdURL: '',
    loading: false,
    isShowErrorDetail: false,
    appRoute: AppRoute,
  }),
  created() {
    window.AssetItem = this
    this.psdURL = this.asset.psdUrl
    this.statusValue = this.asset.approvalStatus
    this.init()
  },
  methods: {
    reload() {
      this.$emit('get-assets', window.ImageMovieMasterDrawer.getFormParams())
    },
    init() {
      if (!this.asset.notApprovalReason) return
      this.notApprovalReasonIndex = this.asset.notApprovalReason.length - 1
    },
    async changeStatus(status) {
      this.statusValue = status
      const body = {
        targetType: 'asset',
        targetIds: [{ targetId: this.asset.assetId }],
        approvalStatus: this.statusValue,
      }
      this.loading = true
      await this.$api.authFetch('/approval_status', { method: 'PUT', body })
      this.loading = false

      this.$emit('reload')
    },
    debounceSavePsdUrl: _.debounce(async function (value) {
      await this.savePsd(value)
    }, 1500),
    async savePsd(value) {
      this.loading = true

      // Request to save the psdURL value
      const body = { originId: this.asset.originId, psdUrl: value }
      const res = await this.$api.authFetch('/asset/psd_url', { method: 'POST', body, canTakeRes: true })

      // handle the response
      const RESPONSE_FALSE_CODE = 400
      if (RESPONSE_FALSE_CODE === res.status) {
        this.psdURL = this.asset.psdUrl
      } else {
        this.$emit('change-psd-url', value)
        this.asset.psdUrl = value
      }

      // end of action
      this.loading = false
      this.$emit('reload')
    },
    async removeAsset(originId) {
      const ret = await this.$confirm('画像動画を削除しますか？', '確認', {
        customClass: 'delete-confirmation',
      }).catch(e => e)
      if (ret === 'cancel') return

      this.loading = true
      const res = await this.$api.authFetch('/asset', {
        method: 'DELETE',
        query: { originId: [originId] },
      })

      if (typeof res !== 'undefined') {
        if (res === 'クリエイティブに使用されているので削除できません。') {
          MessageBox.alert(res, 'エラー', {
            showCancelButton: true,
            distinguishCancelAndClose: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'クリエイティブを確認する',
            customClass: 'delete-confirmation',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm' || action === 'close') {
                done()
                this.loading = false
              } else {
                const query = _.assign({}, this.$route.query, { originIds: [originId] })
                const routeData = this.$router.resolve({ name: `creative-list`, query: query })
                window.open(`${window.location.origin}${routeData.href}`, '_blank')
              }
            },
          }).catch(action => {
            if (action == 'close') {
              this.loading = false
            }
          })
        } else {
          this.$emit('get-assets')
          this.loading = false
        }
      } else {
        this.loading = false
        this.$emit('get-assets')
      }
    },
    toError(errorRaw) {
      if (errorRaw) {
        if (errorRaw.includes('Success')) return 'Success'
        if (errorRaw.includes('アスペクト比率')) return 'aspect'
        if (errorRaw.includes('ピクセル')) return 'pixel'
        if (errorRaw.includes('容量')) return 'fileSize'
        if (errorRaw.includes('ファイル名')) return 'fileName'
        if (errorRaw.includes('再生時間')) return 'duration'
        if (errorRaw.includes('音声のエンコード形式')) return 'audioCodec'
        if (errorRaw.includes('映像のエンコード形式')) return 'videoCodec'
        if (errorRaw.includes('音量') || errorRaw.includes('音声')) return 'volume'
      } else return 'Failed'
    },
    copyError() {
      const details = `${this.asset.metadata.rawFileName}: ${this.errorList}`
      navigator.clipboard.writeText(details)
      this.isShowErrorDetail = false
      this.$message.success('コピーしました')
    },
    checkIdError() {
      if (this.asset.assetId !== this.idOpenError) {
        this.isShowErrorDetail = false
      }
    },
  },
  watch: {
    creative: {
      handler() {
        this.init()
      },
      deep: true,
    },
    asset: {
      handler() {
        this.psdURL = this.asset.psdUrl
      },
      deep: true,
    },
    idOpenError() {
      this.checkIdError()
    },
  },
  computed: {
    isValidRoute() {
      return (
        ![this.appRoute.CREATIVE_LIST, this.appRoute.CREATIVE_REGIST].includes(this.$route.path) &&
        !this.isInCreateCreativeDialog
      )
    },
    containError: function () {
      return (
        (this.asset.regulationResult && !this.asset.regulationResult.includes('Success')) ||
        !_.isEmpty(this.assetErrors)
      )
    },
    errorList: function () {
      const errors = _.chain(this.assetErrors)
        .concat(this.asset.regulationResult)
        .filter(error => error !== 'Success')
        .uniqBy()
        .compact()
        .value()
      return errors
    },
    errorTagList: function () {
      return _.map(this.errorList, this.toError)
    },
  },
}
</script>
