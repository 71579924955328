<template>
  <div id="sort-type" class="ml-2 mr-2">
    <el-row type="flex" align="middle" class="ps-1 pd" :style="{ marginLeft: '5px' }">
      <el-button id="text_set" v-on:click="highlightBtn($event)" class="sort_type">
        <span class="bold text-right text-med">テキスト</span>
      </el-button>

      <el-button id="pixel_size" v-on:click="highlightBtn($event)" class="sort_type">
        <span class="bold text-right text-med">ピクセルサイズ</span>
      </el-button>

      <el-button id="ctx_file_name" v-on:click="highlightBtn($event)" class="sort_type">
        <span class="bold text-right text-med">ファイル名</span>
      </el-button>

      <el-button
        :style="{ position: 'absolute', bottom: '0.3rem', right: '0.5rem' }"
        size="small"
        @click="resetSort('df')"
        class="clearButton"
      >
        <span>クリア</span>
      </el-button>
    </el-row>
  </div>
</template>

<style>
#select-bar .el-divider--horizontal {
  margin: 0;
  display: flex;
}
</style>

<style scoped>
.sort_type {
  padding: 1.5rem 2rem;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 83px;
  height: 8vh;
  width: 12vw;
}
.text-black {
  color: #000000;
}

.clearButton:focus {
  display: none;
}

#drawer {
  background-color: lightgrey;
  padding: 0px 20px;
}

#sort-type {
  background-color: #e0e0e0;
  border: 1px solid #e0e0e0;
}

.el-button {
  text-align: left;
}

.text-med {
  font-size: 15px;
}

.mr-1 {
  margin-right: 10px;
}

.ml-1 {
  margin-left: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.pl-2 {
  padding-left: 20px;
}

.pr-2 {
  padding-right: 20px;
}

.ml-2 {
  margin-left: 20px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ps-1 {
  flex-wrap: wrap;
}

.pd {
  padding: 5px;
}

.b-black {
  border: 1px solid black;
}

.bottom {
  margin-top: 10px;
  line-height: 20px;
}
.bg-blue {
  background: #409eff;
  color: #ffffff;
}
.font-big {
  font-size: 20px;
}
.text-right {
  text-align: right;
  float: top;
}
.display-flex {
  height: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 83.67%;
  height: auto;
}
.bold {
  font-weight: bold;
}
.active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
</style>

<script>
export default {
  name: 'sort-type-creative',
  props: {
    sortType: { type: String, default: () => 'df' },
  },

  data: () => ({ sortTypes: ['text_set', 'pixel_size', 'ctx_file_name'] }),
  mounted() {
    const defaultTab = document.getElementById(this.sortType === '' ? 'df' : this.sortType) //DOM element
    if (defaultTab !== null) defaultTab.classList.add('active')
  },
  methods: {
    highlightBtn(event) {
      this.sortTypes.map(type => document.getElementById(type).classList.remove('active'))
      const targetId = event.currentTarget.id
      const activeTab = document.getElementById(targetId)
      activeTab.classList.add('active')
      this.$emit('change-sort-type', event.currentTarget.id)
    },
    resetSort(data) {
      this.sortTypes.map(type => document.getElementById(type).classList.remove('active'))
      this.$emit('change-sort-type', data)
    },
  },
}
</script>
