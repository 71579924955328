<template>
  <div id="asset-area">
    <el-row class="pb-1" :gutter="20">
      <el-col :span="8">
        <el-button
          type="primary"
          round
          size="mini"
          class="w-100"
          :disabled="!adFormatId || adFormat.assetLabels.length === 0"
          @click="$refs.assetSelectDialog.show(selectedAssets.length)"
        >
          画像/動画マスタから選択
        </el-button>
      </el-col>
    </el-row>

    <el-row v-if="selectedAssets.length !== 0" :gutter="10">
      <el-col :style="{ width: '20%' }" v-for="(gr, i) in selectedAssets" :key="i">
        <asset-group
          class="mb-1"
          :selected-asset="gr"
          :asset-errors="assetErrors[i]"
          :max-label="maxLabel"
          @remove-selected="removeSelected(i)"
          @asset-select-dialog-show="gr => $refs.assetSelectDialog.show(i, gr, assetErrors[i])"
        />
      </el-col>
    </el-row>

    <asset-select-dialog
      ref="assetSelectDialog"
      :selected-promotion-ids="selectedPromotionIds"
      :tags="tags"
      :ad-format-id="adFormatId"
      :asset-labels="adFormat.assetLabels"
      @change-selected-assets="changeSelectedAssets"
      @is-contain-zip="val => $emit('is-contain-zip', val)"
    />
  </div>
</template>

<style>
#asset-area .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
  padding: 30px 0;
}
</style>

<style scoped>
.text-center {
  text-align: center;
}
.font-big {
  font-size: 22px;
}
.pl-1 {
  padding-left: 10px;
}
.pr-1 {
  padding-right: 10px;
}
.pb-1 {
  padding-bottom: 1rem;
}
.w-100 {
  width: 100%;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
</style>

<script>
import _ from 'lodash'
import AssetSelectDialog from '@/views/creative-regist/creative-item/asset-area/asset-select-dialog'
import AssetGroup from '@/views/creative-regist/creative-item/asset-area/components/asset-group'

export default {
  name: 'asset-area',
  components: { AssetSelectDialog, AssetGroup },
  data() {
    return {
      maxLabel: { type: String, default: () => null }, // max label send to child component
    }
  },
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    adFormatId: { type: String, default: () => '' },
    adFormat: { type: Object, default: () => ({ assetLabels: [], textLabels: [] }) },
    selectedAssets: { type: Array, default: () => [] },
    assetErrors: { type: Array, default: () => [] },
  },
  created() {
    window.AssetArea = this
  },
  methods: {
    changeSelectedAssets({ labeledAssets, index, isSplitAsset }) {
      if (isSplitAsset) {
        const first = _.find(labeledAssets, r => r.selected.length !== 0) || _.first(labeledAssets)

        const newSelected = _.chain(this.selectedAssets)
          .filter(r => r.length === 1 && r[0].id === first.id)
          .map(r => r[0].selected)
          .flattenDeep()
          .concat(first.selected)
          .uniqBy('originId')
          .map(r => [_.assign({}, first, { selected: [r] })])
          .value()

        const selectedAssets = _.chain(this.selectedAssets)
          .filter(r => !(r.length === 1 && r[0].id === first.id))
          .concat(newSelected)
          .value()

        this.$emit('change-selected-assets', selectedAssets)
      } else if (!this.selectedAssets[index]) {
        const selectedAssets = _.concat(this.selectedAssets, [labeledAssets])
        this.$emit('change-selected-assets', selectedAssets)
      } else {
        const keyByLabeledAssets = _.keyBy(labeledAssets, 'id')
        const targetAssets =
          this.selectedAssets[index].length < labeledAssets.length ? labeledAssets : this.selectedAssets[index]
        const tmpLabeledAssets = _.chain(targetAssets)
          .map(r => {
            const selected = _.chain(keyByLabeledAssets).result(`[${r.id}].selected`).uniqBy('originId').value()
            if (selected.length === 0) return

            return _.assign({}, r, { selected })
          })
          .filter()
          .value()

        const tmpSelectedAssets = _.cloneDeep(this.selectedAssets)
        tmpSelectedAssets[index] = tmpLabeledAssets

        this.$emit('change-selected-assets', _.every(tmpSelectedAssets, _.isEmpty) ? [] : tmpSelectedAssets)
      }
    },
    removeSelected(index) {
      const selectedAssets = _.filter(this.selectedAssets, (r, i) => i !== index)
      const assetErrors = _.filter(this.assetErrors, (r, i) => i !== index)
      this.$emit('change-selected-assets', selectedAssets)
      this.$emit('change-selected-assets-error', assetErrors)
    },
  },
  watch: {
    selectedAssets() {
      const data = _.maxBy(_.flatten(this.setmaxLabel), function (o) {
        return o.length
      })
      this.maxLabel = data
    },
  },
  computed: {
    setmaxLabel() {
      return _.map(this.selectedAssets, gr => {
        return _.flatten(_.uniq(_.flatMap(gr, r => r.name)))
      })
    },
    viewSelectedAssets() {
      return _.map(this.selectedAssets, gr => {
        const filtered = _.filter(gr, r => r.selected.length !== 0)
        const first = _.chain(filtered).first().result('selected').first().value()

        const selectedLength = _.chain(filtered).map('selected').flattenDeep().value().length

        return { labeledAssets: filtered, first, selectedLength }
      })
    },
    chunkedSelectedAsset() {
      return _.chain(this.selectedAssets).cloneDeep().chunk(4).value()
    },
  },
}
</script>
