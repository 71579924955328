import { render, staticRenderFns } from "./asset-group.vue?vue&type=template&id=06b590fc&scoped=true&"
import script from "./asset-group.vue?vue&type=script&lang=js&"
export * from "./asset-group.vue?vue&type=script&lang=js&"
import style0 from "./asset-group.vue?vue&type=style&index=0&id=06b590fc&scoped=true&lang=css&"
import style1 from "./asset-group.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b590fc",
  null
  
)

export default component.exports