<template>
  <el-dialog
    id="upload-result-preview-dialog"
    title="アップロード結果"
    lock-scroll
    append-to-body
    fullscreen
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div v-if="groupByResultAssets" class="scroll" :style="{ height: height + 'px' }">
      <div v-for="[groupName = '記入なし', gr] in Object.entries(groupByResultAssets)" :key="groupName">
        <div class="mb-1">{{ groupName }}</div>

        <el-table border style="width: 100%" :data="gr">
          <el-table-column label="ファイル名" width="150">
            <template
              slot-scope="{
                row: {
                  asset: { name },
                },
              }"
            >
              <span class="font-mini">{{ name }}</span>
            </template>
          </el-table-column>

          <el-table-column label="静止画/動画" width="350">
            <template
              slot-scope="{
                row: {
                  asset: { url, name },
                },
              }"
            >
              <image-movie-item
                :url="url"
                :thumbnail-url="url"
                :type="judgeFileType(name.substr(name.lastIndexOf('.') + 1).toLowerCase() || 'Youtube')"
              />
            </template>
          </el-table-column>

          <el-table-column label="アップロード結果" width="150">
            <template slot-scope="{ row: { isError, regulationErrors } }">
              <span
                :class="{
                  success: !isError && (!regulationErrors || regulationErrors.length === 0),
                  error: isError || (regulationErrors && regulationErrors.length > 0),
                }"
              >
                {{ isError || (regulationErrors && regulationErrors.length > 0) ? 'エラー' : '成功' }}
              </span>
            </template>
          </el-table-column>

          <el-table-column label="処理結果">
            <template slot-scope="{ row: { reason, regulationErrors } }">
              <div class="reason" v-if="regulationErrors && regulationErrors.length > 0">
                <span style="font-weight: 600">
                  アップロードしようとしている画像/動画はすでに登録されているクリエイティブの規定に違反しています。
                  規定を確認し、 修正した上で再度お試しください。
                </span>
                <ul>
                  <li
                    v-for="(regulationError, index) in showCheckRuleError(regulationErrors)"
                    :key="`regulation-error-${index}`"
                    style="display: flex; flex-direction: column"
                  >
                    <span> {{ regulationError.name.trim() }}:</span>
                    <ul style="list-style: disc; margin-left: 3%">
                      <li
                        v-for="(error, idx) in regulationError.errors"
                        :key="`error-${index}-${idx}`"
                        style="color: rgb(255, 56, 56)"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <span v-else>{{ reason }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="isShow = false" type="primary"> 閉じる </el-button>
    </span>
  </el-dialog>
</template>

<style>
#upload-result-preview-dialog .el-dialog__body {
  padding: 30px 20px !important;
}
#upload-result-preview-dialog .el-dialog__body .reason {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>

<style scoped>
.mb-1 {
  margin-bottom: 1rem;
}
.font-mini {
  font-size: 10px;
}
.success {
  color: #67c23a;
}
.error {
  color: #f56c6c;
}
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
</style>

<script>
import ImageMovieItem from '@/components/image-movie-item'
import util from '@/mixins/util'
import { chain } from 'lodash'

export default {
  name: 'upload-result-preview-dialog',
  mixins: [util],
  components: { ImageMovieItem },
  props: {
    adFormats: { type: Array, default: () => [] },
  },
  data: () => ({ isShow: false, innerHeight: window.innerHeight, groupByResultAssets: null }),
  created() {
    window.UploadResultPreviewDialog = this
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })
  },
  methods: {
    show(groupByResultAssets) {
      // this.adFormats.find(adFormat => adFormat.assetLabels.find(assetLabel => assetLabel.id))
      this.groupByResultAssets = groupByResultAssets
      this.isShow = true
    },
    showCheckRuleError(regulationErrors) {
      return chain(regulationErrors)
        .map(regulationError => {
          const adFormat = this.adFormats.find(adFormat => {
            return adFormat.assetLabels.find(assetLabel => assetLabel.id === regulationError.labelId)
          })

          const adFormatName = adFormat ? adFormat.name : ''
          const platformName = adFormat ? adFormat.platformName : ''
          const labelName = adFormat ? adFormat.assetLabels.find(a => a.id === regulationError.labelId).name : ''
          return {
            ...regulationError,
            name: `${platformName} ${adFormatName} ${labelName}`,
            errors: chain(regulationError.errors)
              .orderBy(v => v, 'asc')
              .value(),
          }
        })
        .orderBy(error => error.name.toLowerCase())
        .value()
    },
  },
  computed: {
    height() {
      return this.innerHeight - 40 - 30 - 30 - 65
    },
  },
}
</script>
