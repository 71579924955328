<template>
  <el-container id="approval-card">
    <div class="col-info" :id="approvalRequest.approvalRequestId">
      <div class="info-head">{{ approvalRequest.approvalRequestName }}</div>
      <div class="priority-row">
        <label class="priority-label">優先度</label>
        <span class="priority-box" :style="[priorityColor]">{{ priorityText }}</span>
        <label class="priority-label">対応期日</label>
        <span class="response-date">{{ formatDate(approvalRequest.dueDate) }}</span>
      </div>
      <div class="request-row">
        <label class="request-label wb-keep-all mr_auto">制作物</label>
        <strong class="request-number wb-keep-all mr_auto">{{ approvalRequest.totalStatus }}</strong>
        <label class="request-label mr_auto wb-keep-all">件</label>
        <ul class="request-list">
          <li class="wb-keep-all">
            <span class="evaluate _task">タスク</span>
            <p :style="[statusStyle]">{{ approvalRequest.totalTask }}</p>
          </li>
          <li class="wb-keep-all">
            <span class="evaluate _waiting">待ち</span>
            <p :style="[statusStyle]">{{ approvalRequest.totalWait }}</p>
          </li>
          <li class="wb-keep-all">
            <span class="evaluate _completion">完了</span>
            <p :style="[statusStyle]">{{ approvalRequest.totalDone }}</p>
          </li>
          <li class="wb-keep-all">
            <span class="evaluate _ng">破棄</span>
            <p :style="[statusStyle]">{{ approvalRequest.totalNG }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-thumbnail">
      <p class="col-thumbnail-label">
        <a class="link-preview" href="#">プレビュー</a>
      </p>
      <div class="thumbnail-list">
        <slick class="slider slider-thumbnail" ref="slick" :id="currentPage" :options="slickOptions">
          <div
            class="items"
            v-for="(asset, i) in require('lodash').uniqBy(approvalRequest.representAssets, x => x.link)"
            :key="`${i}${currentPage}`"
          >
            <div
              :class="
                asset.assetType && asset.assetType === 'Zip'
                  ? 'img zip'
                  : asset.assetType && asset.assetType === 'Music'
                  ? 'music img'
                  : 'img'
              "
              style="position: relative"
            >
              <image-movie-item
                :url="asset.link"
                :thumbnail-url="asset.link"
                :file-name="asset.link"
                :type="asset.assetType"
                :no-preview="true"
                style="pointer-events: none"
              />
              <div
                v-if="asset.assetType === 'Video'"
                class="play-button"
                style="
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  background: transparent;
                "
              >
                <i class="fa-regular fa-circle-play" style="color: white; height: 25px"></i>
              </div>
              <div class="carousel-button" v-if="asset.isCarousel">
                <i class="el-icon-copy-document" />
              </div>
            </div>
          </div>
        </slick>
      </div>
    </div>
  </el-container>
</template>
<style>
.col-thumbnail-label {
  float: right;
  align-self: flex-end;
  margin-top: 5px;
}

/* slick  */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

#approval-card .slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  content: '';
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

#approval-card .slider-thumbnail .slick-next {
  right: -20px !important;
}

#approval-card .slider-thumbnail .slick-prev {
  left: -20px !important;
}

#approval-card .slider-thumbnail .slick-arrow {
  height: 20px !important;
  border-radius: 20px;
  top: 50% !important;
  transform: translateY(-50%);
  font-size: initial !important;
}

#approval-card .slider-thumbnail .slick-arrow:hover {
  background-color: #dfdddd !important;
}

#approval-card .slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir='rtl'] #approval-card .slick-slide {
  float: right;
}

#approval-card .slick-slide img {
  display: block;
}

#approval-card .slick-slide.slick-loading img {
  display: none;
}

#approval-card .slick-slide.dragging img {
  pointer-events: none;
}

#approval-card .slick-initialized .slick-slide {
  display: block;
}

#approval-card .slick-loading .slick-slide {
  visibility: hidden;
}

#approval-card .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-track > div {
  display: inline-block;
}

/* .slick-prev::after {
  content: "\f104";
}
.slick-next::after {
  content: "\f105";
} */
#approval-card .slick-prev,
#approval-card .slick-next {
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: absolute;
  top: 48%;
  border: none;
}

#approval-card .slick-dots {
  position: absolute;
  bottom: -35px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  cursor: pointer;
  background-position: 0px -120px;
  margin: 0 8px;
}

#approval-card .slick-dots .slick-active,
#approval-card .slick-dots li:hover {
  background-position: 0px -140px;
}

.slick-dots li button {
  border: 0;
  height: 10px;
  width: 10px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}

#approval-card .col-info {
  width: 37%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 8px 0px 10px;
  padding-right: 8px;
  cursor: pointer;
}

#approval-card .col-info .info-head {
  color: #060630;
  border-bottom: 1px solid #d6d6d6;
  padding: 12px 0 18px;
  font-weight: 600;
}

#approval-card .col-info .priority-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#approval-card .col-info .priority-row .priority-label {
  margin-bottom: 0;
}

#approval-card .col-info .priority-row .priority-box {
  height: 18px;
  width: 52px;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  font-weight: 700;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
  margin: 0 14px;
}

#approval-card .col-info .priority-row .response-date {
  display: inline-block;
  margin-left: 16px;
}

#approval-card .col-info .request-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#approval-card .col-info .request-row .request-label {
  margin-bottom: 0;
  color: #060630;
  font-size: 10px;
  font-weight: 700;
}

#approval-card .col-info .request-row .request-number {
  font-size: 12px;
  font-weight: 700;
  margin-right: 16px;
}

#approval-card .col-info .request-row .request-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: 18px;
  font-weight: 700;
  margin-top: 2px;
}

#approval-card .col-info .request-row .request-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 7px;
  font-size: 15px;
}

#approval-card .col-info .request-row .request-list li .evaluate {
  height: 16px;
  width: 36px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 20px;
  font-weight: 400;
  margin-right: 10px;
  cursor: default;
}

#approval-card .col-info .request-row .request-list li .evaluate._task {
  background-color: #e76867;
}

#approval-card .col-info .request-row .request-list li .evaluate._waiting {
  background-color: #707070;
}

#approval-card .col-info .request-row .request-list li .evaluate._completion {
  background-color: #33e53e;
}

#approval-card .col-info .request-row .request-list li .evaluate._ng {
  background-color: #ccccd3;
}

#approval-card .col-thumbnail {
  width: 62%;
  min-width: 62%;
  background-color: #fff;
  border-radius: 0 10px 10px 0;
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
  position: relative;
}

#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail {
  display: flex;
  padding-left: 4px;
}

#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail .slick-arrow {
  background-color: transparent;
  width: 20px;
  height: 50px;
  top: calc(50% - 25px);
  z-index: 5;
  color: #060630;
  font-size: 18px;
}

#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail .slick-active {
  width: max-content !important;
}

#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail .slick-track {
  display: flex;
  width: min-content !important;
}

#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail .slick-arrow.slick-disabled {
  user-select: none !important;
  visibility: hidden !important;
}

#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail .slick-arrow.slick-next {
  right: -16px;
}

#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail .slick-arrow.slick-prev {
  left: -16px;
}

#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail .items {
  padding: 0 3px;
}

#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail .items .img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 106px;
  width: 106px;
  position: relative;
}

#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail .items > .img:not(.zip):not(.music) {
  background-color: #060630;
}

#approval-card .video-wrap {
  width: 106px !important;
  height: 106px !important;
}

#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail .items .img img,
#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail .items .img iframe {
  display: block;
  max-width: 106px;
  max-height: 106px;
  height: auto;
  width: auto;
  margin: 0;
  padding: 0;
  pointer-events: none;
}

#approval-card .col-thumbnail .link-preview {
  color: #8f9eaf;
  font-weight: 600;
  text-decoration: none;
}

#approval-card .col-thumbnail .link-preview:hover {
  cursor: default;
}

#approval-card .col-thumbnail .play-button {
  border-radius: 5px;
  padding: 1px 3px 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(2, 1.5);
  background-color: white;
}

#approval-card .col-thumbnail .play-button i {
  opacity: 0.5;
}

#approval-card .col-thumbnail .carousel-button {
  background-color: white;
  border-radius: 10px;
  padding: 1px 3px 3px;
  position: absolute;
  bottom: 10%;
  left: 10%;
  transform: scale(1.5, 1.4) rotate(180deg);
}

#approval-card .col-thumbnail .carousel-button i {
  color: #58a2c4;
  font-weight: 900;
}

.fa-solid {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: 'Font Awesome 5 Free';
  font-weight: 300;
}
</style>
<style scoped>
.wb-keep-all {
  word-break: keep-all;
}
.mr_auto {
  margin-right: max(12px, 2%) !important;
}
</style>
<script>
import Slick from 'vue-slick'
import util from '@/mixins/util'
import ImageMovieItem from '@/components/image-movie-item'

export default {
  name: 'approval-card',
  mixins: [util],
  props: {
    approvalRequest: {
      type: Object,
      default: () => {},
    },
    approvalCategory: { type: String, default: () => '' },
    currentPage: { type: Number, default: () => 1 },
    drawerTab: { type: String, default: () => null },
    adFormats: { type: Array, default: () => [] },
    selectedPromotionIds: { type: Array, default: () => [] },
  },
  components: { Slick, ImageMovieItem },
  data() {
    return {
      priorityColor: {
        color: '',
      },
      statusStyle: {
        margin: 0,
        paddingBottom: '2px',
        cursor: 'default',
        fontSize: '12px',
      },
      priorityText: { type: String, default: () => null },
      slickOptions: {
        prevArrow:
          "<button class='slick-prev slick-arrow' aria-label='Previous' type='button'><i class='fa-solid fa-caret-left'></i></button>",
        nextArrow:
          "<button class='slick-next slick-arrow' aria-label='Next' type='button'><i class='fa-solid fa-caret-right'></i></button>",
        infinite: false,
        slidesToShow: 7,
        slidesToScroll: 2,
      },
    }
  },
  beforeUpdate() {
    if (this.$refs.slick) {
      this.$refs.slick.destroy()
    }
  },
  updated() {
    this.$nextTick(function () {
      if (this.$refs.slick) {
        this.$refs.slick.create(this.slickOptions)
      }
    })
  },
  async created() {
    switch (this.approvalRequest.priority) {
      case 2:
        this.priorityColor.color = '#e76867'
        this.priorityText = '高'
        break
      case 1:
        this.priorityColor.color = '#f5a623'
        this.priorityText = '中'
        break
      case 0:
        this.priorityColor.color = '#33e53e'
        this.priorityText = '低'
        break
      default:
        this.priorityText = ''
        break
    }
  },
  methods: {
    log(a, b) {
      console.log(a, b)
    },
  },
  watch: {
    approvalRequest() {
      switch (this.approvalRequest.priority) {
        case 2:
          this.priorityColor.color = '#e76867'
          this.priorityText = '高'
          break
        case 1:
          this.priorityColor.color = '#f5a623'
          this.priorityText = '中'
          break
        case 0:
          this.priorityColor.color = '#33e53e'
          this.priorityText = '低'
          break
        default:
          this.priorityText = ''
          break
      }
    },
  },
}
</script>
