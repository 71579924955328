import { MessageBox } from 'element-ui'
import _ from 'lodash'
import URI from 'urijs'

const stage = 'prod'
const frontDomain = {
  local: 'http://localhost:3001',
  dev: 'https://dev.ctx.hinerankai.net',
  staging: 'https://stg.ctx.hinerankai.net',
  prod: 'https://ctx.hinerankai.net',
}[stage]

const ctxDomain = {
  local: 'http://localhost:8080/api',
  dev: 'https://api.dev-ctx.pyxis-social.com/api',
  staging: 'https://api.stg-ctx.pyxis-social.com/api',
  prod: 'https://api.ctx.pyxis-social.com/api',
}[stage]

const pdDomain = {
  local: 'https://api-ctx.hinerankai.net/dev',
  dev: 'https://api-ctx.hinerankai.net/dev',
  staging: 'https://api-ctx.hinerankai.net/staging',
  prod: 'https://api-ctx.hinerankai.net/prod',
}[stage]

const yarouApiGawDomain = {
  local: 'https://weighted-avg.sep-tech.net/api/v1.0',
  dev: 'https://weighted-avg.sep-tech.net/api/v1.0',
  staging: 'https://weighted-avg.sep-tech.net/api/v1.0',
  prod: 'https://weighted-avg.sep-tech.net/api/v1.0',
}[stage]

const sepBaseDomain = {
  local: 'https://dev.sepbase.jp',
  dev: 'https://dev.sepbase.jp',
  staging: 'https://stg.sepbase.jp',
  prod: 'https://sepbase.jp',
}[stage]

const regulationTerminalDomain = {
  local: 'https://dev.regulation-terminal.hinerankai.net',
  dev: 'https://dev.regulation-terminal.hinerankai.net',
  staging: 'https://stg.regulation-terminal.hinerankai.net',
  prod: 'https://regulation-terminal.hinerankai.net',
}[stage]

export default {
  async install(Vue) {
    const sleep = time => new Promise(resolve => setTimeout(resolve, time))
    Vue.$api = Vue.prototype.$api = {
      frontDomain,
      ctxDomain,
      pdDomain,
      yarouApiGawDomain,
      sepBaseDomain,
      regulationTerminalDomain,
      stage,
      sleep,
      selectDomain(domain, endPoint) {
        if (domain === 'pd') return this.pdDomain + endPoint
        else if (domain === 'yarouGaw') return this.yarouApiGawDomain + endPoint
        else return this.ctxDomain + endPoint
      },
      getSepBaseDomain() {
        return sepBaseDomain
      },
      getRegulationDomain() {
        return regulationTerminalDomain
      },
      async authFetch(endPoint, { method, headers, body = {}, query = {}, domain, canTakeRes = false }) {
        headers = Object.assign({}, headers, { 'Content-Type': 'application/json' })

        endPoint = new URI(endPoint).addQuery(query).toString()

        const option = _.isEmpty(body) ? { method, headers } : { method, headers, body: JSON.stringify(body) }
        const url = this.selectDomain(domain, endPoint)
        try {
          const res = await fetch(url, option)

          if (res.status === 204) return true
          else if (res.status.toString().match(/2[0-9]{2}/)) {
            const contentType = res.headers.get('Content-Type')
            if (contentType.match('application/json') && endPoint.match('odd_ai_version') && _.isEmpty(res)) {
              return res.text()
            }
            if (contentType.match('application/json')) {
              const response = await res.json()
              if (response.warning && response.warning.code === 503) {
                const mess =
                  '<p>ただいまメンテナンス中のため利用停止しております。</p> <p>お問い合わせがありましたら、#cr-workflow-helpまでお願い致します。<p>'
                MessageBox.alert(mess, 'メンテナンスのお知らせ', {
                  customClass: 'delete-confirmation',
                  showClose: false,
                  showConfirmButton: false,
                  dangerouslyUseHTMLString: true,
                })
              } else {
                return response
              }
            } else if (contentType.match('text/plain')) {
              return res.text()
            } else if (contentType.match('application/pdf')) return res.blob()
            else MessageBox.alert('定義されていないContent-Typeです', 'APIエラー')
          } else if (res.status === 403) return res.json()
          else if (res.status === 401) {
            const errorText = await res.text()
            if (errorText === 'Authentication is possible but has failed or not yet been provided.') {
              window.localStorage.setItem('401', 401)
              window.localStorage.removeItem('accessToken')
              MessageBox.alert('セッションの有効期限が切れました。もう一度ログインしてください。', 'エラー')
            }
          } else {
            const errorText = await res.text()
            if (errorText === 'クリエイティブに使用されているので削除できません。') {
              return errorText
            } else {
              MessageBox.alert(errorText, 'エラー', {
                customClass: 'delete-confirmation',
              })
            }
          }

          if (canTakeRes) {
            return res
          }
        } catch (e) {
          console.error(endPoint, e)
          const mess =
            '<p>サーバーに接続できませんでした。</p> <p>インターネット（Zscaler等）の接続を確認し、もう一度お試しください。</p> <p> お問い合わせがありましたら、＃cr-workflow-helpまでお願い致します。</p>'
          MessageBox.alert(mess, 'エラー', {
            customClass: 'delete-confirmation',
            showClose: true,
            beforeClose: () => {
              MessageBox.close()
            },
            showConfirmButton: false,
            dangerouslyUseHTMLString: true,
          })
        }
      },
    }
  },
}
