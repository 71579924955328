<template>
  <div id="reference-url-area" class="">
    <h4 class="reference-url-area--title">
      <i class="fas fa-link"></i>
      遷移先URL
    </h4>
    <div
      v-for="(url, index) in referenceUrls"
      :class="isUrlError(url) ? 'link-group error-group' : 'link-group'"
      :key="index"
    >
      <text-area-input v-if="isOpenable && url" :value-input="url" style="flex: 1" :isDisabled="true" />
      <text-area-input
        v-else-if="isEditable"
        :value-input="url"
        @on-change="v => updateReferenceUrl(v, index)"
        style="flex: 1"
      />
      <section class="url-controller" v-if="isEditable">
        <i class="el-icon-circle-close i-btn url-controller-btn__danger" @click="removeRefUrlAtIndex(index)" />
      </section>
    </div>
    <i class="el-icon-circle-plus-outline i-btn ml-1/2" v-if="isAbleToAddUrl" @click="createNewRefUrl()"></i>
  </div>
</template>

<style>
.reference-url-area--title {
  font-size: 14px;
  margin: 0 0 8px;
}

#reference-url-area .isUrl input {
  border: 1px solid #dcdfe6;
}

#reference-url-area .notUrl input {
  border: 1px solid red;
}

.link-group .el-input input {
  height: 25px !important;
}

.url-controller {
  margin-left: 0.5vw;
  align-self: center;
}

.url-controller-btn__danger {
  color: red !important;
}

.reference-url .reference-url-area--title {
  font-size: 12px;
  font-weight: 500;
}

.reference-url {
  padding-left: 0.5rem;
  border-left: 1px solid #ebeef5;
}

#reference-url-area .clickable {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 0 8px;
  height: 22px;
  line-height: 22px;
  color: #237da7;
}
</style>

<style scoped>
.i-btn {
  color: #0095ff;
  font-size: 20px;
  cursor: pointer;
}
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'
import { MAX_ALLOWED_REFERENCE_URL_NUMBER, NUMBER_OF_URL_VALID, SPECIAL_REFERENCE_URL } from '@/mixins/constant'
import TextAreaInput from '@/components/text-area-input.vue'

export default {
  name: 'reference-url-area',
  mixins: [util],
  props: {
    referenceUrls: { type: Array, default: () => [''] },
    isEditable: { type: Boolean, default: () => true },
    isOpenable: { type: Boolean, default: () => false },
  },
  components: {
    TextAreaInput,
  },
  data: () => ({
    MAX_ALLOWED_REFERENCE_URL_NUMBER: MAX_ALLOWED_REFERENCE_URL_NUMBER,
    SPECIAL_REFERENCE_URL: SPECIAL_REFERENCE_URL,
  }),
  mounted() {
    if (this.isEditable) this.addDefaultUrl()
  },
  methods: {
    updateReferenceUrl(value, index) {
      const newReferenceUrl = _.cloneDeep(this.referenceUrls)
      newReferenceUrl[index] = value
      this.$emit('update-reference-urls', newReferenceUrl)
      this.$emit('auto-update-creative')
    },
    removeRefUrlAtIndex(index) {
      const hasOnlyOneUrl = index === 0 && this.referenceUrls.length === 1
      if (hasOnlyOneUrl) {
        // ~> If has only one nonEmpty url, clear it
        if (this.referenceUrls[0] !== '') {
          this.updateReferenceUrl('', 0)
        }
      } else {
        // ~> If have many urls, remove the url at index
        const newReferenceUrl = _.cloneDeep(this.referenceUrls)
        const isCallAPIUpdate = newReferenceUrl[index] !== ''
        newReferenceUrl.splice(index, 1)
        this.$emit('update-reference-urls', newReferenceUrl)
        if (isCallAPIUpdate) {
          this.$emit('auto-update-creative')
        }
      }
    },
    createNewRefUrl() {
      const newReferenceUrl = _.cloneDeep(this.referenceUrls)
      newReferenceUrl.push('')
      this.$emit('update-reference-urls', newReferenceUrl)
    },
    isUrlError(url) {
      const urls = url.match(this.urlRegForReference)
      return urls?.length > NUMBER_OF_URL_VALID
    },
    addDefaultUrl() {
      const canAddDefaultValue = this.referenceUrls.length === 0 && this.isEditable
      if (canAddDefaultValue) {
        this.createNewRefUrl()
      }
    },
  },
  computed: {
    isAbleToAddUrl() {
      return this.isEditable && this.referenceUrls.length < MAX_ALLOWED_REFERENCE_URL_NUMBER
    },
  },
  watch: {
    referenceUrls() {
      this.addDefaultUrl()
    },
  },
}
</script>
