<template>
  <el-row>
    <el-col :span="24">
      <el-select
        id="test"
        v-model="promotionId"
        ref="promotionSelect"
        class="w-100"
        remote
        :remote-method="handleData"
        @focus="handleData('')"
        filterable
        placeholder="プロモーション選択"
        @change="confirmPromotionChange"
      >
        <el-option-group v-for="group in grouping" :key="group.label" :label="group.label">
          <el-option
            ref="searchBar"
            v-for="item in group.promotions"
            :key="`${item.promotionId}_${group.label}`"
            :label="item.combinedName"
            :value="item.promotionId"
            class="w-770"
          >
            <span style="float: left">{{ item.combinedName }}</span>
            <span style="float: right; color: #63b3ed; font-size: 13px">
              <a :href="buildURL(item.promotionId)" target="_blank" @click.stop="stopPropagation">別タブで開く</a>
            </span>
          </el-option>
        </el-option-group>
      </el-select>
    </el-col>
  </el-row>
</template>

<style scoped>
.newtab {
  color: cornflowerblue;
  position: absolute;
  right: 10px;
}
.w-100 {
  width: 100%;
}
.w-770 {
  min-width: 770px;
}
.h-300px {
  height: 300px;
}
.l-30 {
  left: 30%;
}
.p-1 {
  padding: 0.3rem 0;
}
.mb-2 {
  margin-bottom: 1rem;
}
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.not-click {
  pointer-events: none;
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'

export default {
  name: 'search-tab',
  components: {},
  mixins: [util],
  props: {
    selectedIdsChild: { type: Array, default: () => [] },
    advertisers: { type: Array, default: () => [] },
    promotionsHistory: { type: Array, default: () => [] },
    selectedIds: { type: Array, default: () => [] },
    promotionInfo: { type: Array, default: () => [] },
    isCanLeave: { type: Boolean, default: true },
    isDisplayConfirm: { type: Boolean, default: false },
  },
  data: () => ({
    viewAdvertiserIndex: 0,
    viewPromotionIndex: 0,
    viewPromo: [],
    advertiserWord: '',
    promotionWord: '',
    promotionId: '',
    previousPromotionId: '', // Lưu promotionId trước đó
    viewPromotion: [],
    grouping: [
      {
        label: '履歴',
        promotions: [],
      },
      {
        label: 'プロモーション一覧',
        promotions: [],
      },
    ],
    viewIndex: 0,
    loading: true,
  }),
  created() {
    this.previousPromotionId = this.selectedIds[0]
    this.promotionId = this.selectedIds[0]
    this.grouping = [
      {
        label: '履歴',
        promotions: this.promotionsHistory.filter(x => x !== undefined),
      },
    ]
    window.SearchTab = this
  },
  mounted() {},
  methods: {
    async confirmPromotionChange(newPromotionId) {
      const isOnApprovalDetail = this.$route.path === '/approval-detail'
      const requiresConfirmation = isOnApprovalDetail && !this.isCanLeave
      if (requiresConfirmation) {
        this.$emit('change-is-display-confirm', true)
        try {
          const message = '変更が確定されていません。変更を保存しますか？'
          const ret = await this.$confirm(message, '確認')

          if (ret === 'cancel') {
            this.$refs.promotionSelect.blur()
            this.promotionId = this.previousPromotionId // Khôi phục promotionId trước đó nếu hủy
            this.$emit('change-is-display-confirm', false)
            return
          }
        } catch {
          this.$refs.promotionSelect.blur()
          this.promotionId = this.previousPromotionId // Khôi phục nếu xảy ra lỗi hoặc hủy
          this.$emit('change-is-display-confirm', false)
          return
        }
      }

      // Nếu không cần xác nhận hoặc đã xác nhận, thay đổi promotionId
      this.$refs.promotionSelect.blur()
      this.previousPromotionId = newPromotionId
      this.changePromotionIds(newPromotionId)
    },

    changePromotionIds(selectedPromotionId) {
      const promo = this.promotionInfo.find(x => x.promotionId === this.promotionId)
      this.$emit('change-promotions-history', promo)
      this.$emit('change-selected-ids', [selectedPromotionId])
    },
    stopPropagation() {
      return
    },
    buildURL(promotionId) {
      return `${this.$api.frontDomain}${window.location.pathname}?promotionIds=${promotionId}`
    },
    handleData(query) {
      if (query !== '') {
        this.viewPromo = this.promotionInfo.filter(item => {
          return item.combinedName.toLowerCase().indexOf(query.toLowerCase()) > -1
        })
        this.grouping = [
          {
            label: 'プロモーション一覧',
            promotions: this.viewPromo,
          },
        ]
      } else {
        this.grouping = [
          {
            label: '履歴',
            promotions: this.promotionsHistory.filter(x => x !== undefined),
          },
        ]
      }
    },
  },
  computed: {
    selectedPromotionIds() {
      return _.chain(this.selectedIdsChild)
        .filter('promotion.id')
        .map(r => `${r.id} ${r.promotion.id}`)
        .value()
    },
    selectedAdvertiserIds() {
      return _.chain(this.selectedIdsChild).map('id').uniq().value()
    },
    filteredAdvertisers() {
      const lowerAdvertiserWord = _.toLower(this.advertiserWord)
      return _.chain(this.advertisers)
        .filter(r => _.includes(_.toLower(r.name), lowerAdvertiserWord))
        .map(r => _.omit(r, ['promotions']))
        .sortBy([r => Number(r.id)])
        .value()
    },
    filteredPromotions() {
      const lowerPromotionWord = _.toLower(this.promotionWord)
      return _.chain(this.advertisers)
        .map(r => {
          const promotions = _.chain(r.promotions)
            .filter(r2 => _.includes(_.toLower(r2.name), lowerPromotionWord))
            .sortBy([r => Number(r.id)])
            .value()
          const filteredAdvers = _.includes(_.toLower(r.name), lowerPromotionWord)
          const included = filteredAdvers ? true : promotions.length > 0 ? true : false
          const nPromotions = filteredAdvers ? r.promotions : promotions

          return _.assign({}, r, { included: included, promotions: nPromotions })
        })
        .filter(r => r.included)
        .sortBy([r => Number(r.id)])
        .value()
    },
    viewAdvertisers() {
      return _.filter(this.filteredAdvertisers, (r, i) => i < this.viewAdvertiserIndex * 10)
    },
    viewPromotions() {
      if (_.toLower(this.promotionWord).length === 0) {
        return []
      } else {
        return _.filter(this.filteredPromotions, (r, i) => i < this.viewPromotionIndex * 10)
      }
    },
  },
  watch: {
    selectedIds() {
      if (this.promotionId !== this.selectedIds[0] && this.selectedIds !== undefined) {
        this.promotionId = this.selectedIds[0]
      }
    },
  },
}
</script>
