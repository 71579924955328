<template>
  <el-row>
    <el-col :span="24">
      <el-row :gutter="10">
        <el-col :span="4">
          <status-area
            :status-selected="creative.approvalStatus"
            :target-type="'creative'"
            :size="'full'"
            @change-status="status => $emit('change-status-creative', creative.creativeId, status)"
            :is-creative-have-n-g="isCreativeHaveNG"
            :disabled="isDisableCreativeDropdownStatus"
          />
        </el-col>
        <el-col :span="20">
          <tag-area
            :tags="creative.combinedTags"
            :is-show-alert-disabled="!isCrCanEdit && !isInDialog"
            :createdAt="creative.createdAt"
            :updatedAt="creative.updatedAt"
            :creativeType="creative.creativeType"
          />
        </el-col>
      </el-row>

      <el-row v-if="displayType === 'approvalRequest'" class="mt-1 mb-1">
        <el-col :span="16">
          <text-set-area
            :text-set="creative.textSet"
            :is-only-text="true"
            :can-change-status="canChangeStatus"
            @change-status-text="(textSetId, status) => $emit('change-status-text', textSetId, status)"
          />
        </el-col>

        <el-col :span="8">
          <comment-area
            :creative="creative"
            :is-in-dialog="isInDialog"
            :is-creative-list="isCreativeList"
            :is-update="isUpdate"
            @change-creator-comment="v => $emit('change-creator-comment', v)"
            @change-note="v => $emit('change-note', v)"
            @update-creative="(urls, comment) => $emit('update-creative', urls, comment)"
            @change-reference-url="v => $emit('change-reference-url', v)"
            @get-creatives="$emit('get-creatives')"
            @open-proposed-dialog="(textSetId, adFormatId) => $emit('open-proposed-dialog', textSetId, adFormatId)"
          />
        </el-col>
      </el-row>

      <el-row v-else-if="displayType === 'submissionRequest'" class="mt-1 mb-1">
        <el-col :span="16">
          <text-set-area
            :text-set="creative.textSet"
            :is-only-text="true"
            :can-change-status="canChangeStatus"
            @change-status-text="(textSetId, status) => $emit('change-status-text', textSetId, status)"
          />
        </el-col>

        <el-col :span="8">
          <note-area
            :creative="creative"
            :is-in-dialog="isInDialog"
            :is-can-edit-note="isCrCanEdit"
            @change-note="note => $emit('change-note', note)"
            @edit-note-creatives="(note, creatives) => $emit('edit-note-creatives', note, creatives)"
          />
        </el-col>
      </el-row>

      <el-row v-else class="mt-1 mb-1">
        <el-col :span="16">
          <text-set-area
            :text-set="creative.textSet"
            :is-only-text="true"
            :can-change-status="canChangeStatus"
            :is-text-can-edit="isCrCanEdit"
            @change-status-text="(textSetId, status) => $emit('change-status-text', textSetId, status)"
          />
        </el-col>

        <el-col :span="8">
          <note-area
            :creative="creative"
            :is-in-dialog="isInDialog"
            :is-can-edit-note="isCrCanEdit"
            @change-note="note => $emit('change-note', note)"
            @edit-note-creatives="(note, creatives) => $emit('edit-note-creatives', note, creatives)"
          />
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<style scoped>
.mt-1 {
  margin-top: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}
</style>

<script>
import _ from 'lodash'
import TagArea from '@/views/creative-list/ad-format-tab-list/creative-card/main-area/components/tag-area'
import NoteArea from '@/views/creative-list/ad-format-tab-list/creative-card/main-area/components/note-area'
import CommentArea from '@/views/creative-list/ad-format-tab-list/creative-card/main-area/components/comment-area'
import TextSetArea from '@/views/creative-list/ad-format-tab-list/creative-card/main-area/components/text-set-area'
import StatusArea from '../../creative-card/main-area/components/status-area.vue'
import { AssetTextStatus } from '@/mixins/assetTextStatus'
import { CreativeStatus } from '@/mixins/creativeStatus'
import util from '@/mixins/util'

export default {
  name: 'only-text-main-area',
  components: { TagArea, NoteArea, CommentArea, TextSetArea, StatusArea },
  mixins: [util],
  props: {
    platformId: { type: String, default: () => '' },
    adFormatName: { type: String, default: () => '' },
    creative: { type: Object, default: () => ({}) },
    isInDialog: { type: Boolean, default: () => false },
    isCrCanEdit: { type: Boolean, default: () => true },
    isCreativeList: { type: Boolean, default: () => false },
    isUpdate: { type: Boolean, default: () => false },
    isShow: { type: Boolean, default: () => false },
    bgColor: { type: String, default: () => '' },
    displayType: { type: String, default: () => '' },
  },
  data: () => ({ activeIndex: 0, backgroundColor: null, color: null }),
  created() {
    window.MainArea = this
    this.color = '#ffffff'
  },
  computed: {
    setTextBorderColorSubmitStatus() {
      if (this.creative.submissionStatus.includes('入稿済')) {
        return 'rgb(91, 184, 93)'
      } else if (this.creative.submissionStatus.includes('未入稿')) {
        return '#ef342f'
      } else if (this.creative.submissionStatus === '入稿済') {
        return 'rgb(91, 184, 93)'
      } else {
        return '#ffa500'
      }
    },
    setColorText() {
      switch (this.creative.approvalStatus) {
        case '監修中':
          return '#fff'
        case '要修正':
          return '#fff'
        case '承認待ち':
          return '#fff'
        case '入稿依頼済':
          return '#fff'
        case '入稿済':
          return '#fff'

        case '承認':
          return '#fff'
        case '入稿可能':
          return '#fff'

        case '入稿不可':
          return '#06063096'
        case '破棄':
          return '#06063096'
        default:
          return 'rgb(6 6 48 / 45%)'
      }
    },
    setBackgroundColorApprovalStatus() {
      switch (this.creative.approvalStatus) {
        case '監修中':
          return '#909399'
        case '要修正':
          return '#ef342f'
        case '承認待ち':
          return '#909399'
        case '入稿依頼済':
          return '#ffa500'
        case '入稿済':
          return '#084c6c'

        case '承認':
          return 'rgb(91, 184, 93)'
        case '入稿可能':
          return 'rgb(91, 184, 93)'
        case '破棄':
          return 'rgba(245,245,245,0.48)'

        case '入稿不可':
          return 'rgba(245,245,245,0.48)'

        default:
          return 'white'
      }
    },
    isCreativeHaveNG() {
      const assetStatus = _.uniq(this.creative.assets.flatMap(x => x.approvalStatus))
      return (
        assetStatus.some(stt => stt === AssetTextStatus.NG) ||
        this.creative.textSet.approvalStatus === AssetTextStatus.NG
      )
    },
    isDisableCreativeDropdownStatus() {
      return CreativeStatus.NO_EDIT_CR.includes(this.creative.approvalStatus) || !this.canChangeStatus
    },
    canChangeStatus() {
      const allowedToChangeStatusType = ['default']
      const allowedToChangeStatusRoute = ['/creative-list']
      return (
        allowedToChangeStatusType.includes(this.displayType) && allowedToChangeStatusRoute.includes(this.$route.path)
      )
    },
  },
}
</script>
