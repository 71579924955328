var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"assetArea",style:({ borderColor: _vm.getBorderColor(_vm.isAssetCanEdit || _vm.isInDialog) }),attrs:{"id":"asset-area1"}},[(_vm.creative.assets.length !== 0)?_c('el-row',[(_vm.creative.assets.length === 1)?_c('div',{staticClass:"float-left w-100 word-break"},[_c('status-area',{attrs:{"status-selected":_vm.creative.first.approvalStatus,"disabled":_vm.isStatusAreaDisabled(_vm.isAssetCanEdit)},on:{"change-status":function (status) { return _vm.$emit('change-status-asset', _vm.creative.first.assetId, status); }}}),_c('div',{staticClass:"my-1",style:({ color: '#000' })},[_vm._v(_vm._s(_vm.creative.first.labelName))]),_c('div',{staticClass:"thumb-name"},[_c('span',{staticClass:"thumb-ver",domProps:{"textContent":_vm._s('v' + _vm.creative.first.version)}}),(_vm.isShowFullName)?_c('section',[_c('span',{staticClass:"mb-1 word-break name w-100"},[_vm._v(" "+_vm._s(_vm.creative.first.metadata.fileName)+" ")]),_c('span',{staticClass:"mb-1 word-break name w-100"},[_vm._v(" "+_vm._s(_vm.creative.first.metadata.rawFileName)+" ")])]):_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":_vm.creative.first.metadata.fileName,"placement":"top-start","visible-arrow":false}},[_c('span',{staticClass:"name",style:({
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: '#060630',
              fontSize: '12px',
              fontWeight: 400,
            }),domProps:{"textContent":_vm._s(_vm.creative.first.metadata.fileName)}})])],1),_c('image-movie-item',{staticClass:"float-left w-100 h-100",attrs:{"parentComponent":"creative","url":_vm.creative.first.url,"thumbnail-url":_vm.creative.first.thumbnailUrl,"type":_vm.creative.first.assetType,"isMask":false}})],1):_vm._e(),(_vm.creative.assets.length - 1 !== 0)?_c('div',{staticClass:"float-left w-99 word-break"},[_c('el-carousel',{ref:"assetCarousel",attrs:{"indicator-position":"none","autoplay":false,"loop":false},on:{"change":function (index) { return _vm.$emit('change-active-index', index); }}},_vm._l((_vm.creative.assets),function(ref,k){
            var assetId = ref.assetId;
            var labelName = ref.labelName;
            var url = ref.url;
            var thumbnailUrl = ref.thumbnailUrl;
            var assetType = ref.assetType;
            var approvalStatus = ref.approvalStatus;
            var version = ref.version;
            var ref_metadata = ref.metadata;
            var fileName = ref_metadata.fileName;
            var rawFileName = ref_metadata.rawFileName;
            var isLatest = ref.isLatest;
return _c('el-carousel-item',{key:k},[_c('status-area',{attrs:{"status-selected":approvalStatus,"disabled":_vm.isStatusAreaDisabled(_vm.isAssetCanEdit)},on:{"change-status":function (status) { return _vm.$emit('change-status-asset', assetId, status); }}}),_c('div',{staticClass:"my-1",style:({ color: '#000' })},[_vm._v(_vm._s(labelName))]),_c('div',{staticClass:"thumb-name"},[_c('span',{staticClass:"thumb-ver",domProps:{"textContent":_vm._s('v' + version)}}),(_vm.isShowFullName)?_c('section',[_c('span',{staticClass:"mb-1 word-break name"},[_vm._v(" "+_vm._s(fileName)+" ")]),_c('span',{staticClass:"mb-1 word-break name"},[_vm._v(" "+_vm._s(rawFileName)+" ")])]):_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":_vm.creative.first.metadata.fileName,"placement":"top-start","visible-arrow":false}},[_c('span',{staticClass:"name",style:({
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: '#060630',
                  fontSize: '12px',
                  fontWeight: 400,
                }),domProps:{"textContent":_vm._s(_vm.creative.first.metadata.fileName)}})])],1),_c('div',{staticClass:"carousel__asset",on:{"click":function($event){return _vm.$refs.previewDialog.show(_vm.creative.labeledAssets, _vm.creative.textSet.labeledTexts)}}},[_c('image-movie-item',{staticClass:"w-100 h-100",attrs:{"parentComponent":"creative","url":url,"thumbnail-url":thumbnailUrl,"type":assetType,"no-preview":true,"isMask":true}})],1)],1)}),1)],1):_vm._e()]):_vm._e(),_c('preview-dialog',{ref:"previewDialog",attrs:{"platform-id":_vm.platformId,"ad-format-name":_vm.adFormatName}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }