<template>
  <el-card shadow="never" class="w-100 font-default b-2">
    <div class="float-left w-100">
      <el-col class="p-1" :span="24">
        <el-row
          type="flex"
          v-for="(chunkedLabeledText, i) in require('lodash').chunk(proposedText, 3)"
          :key="`chunkedLabeledText_${i}`"
          class="mb-2"
        >
          <el-col
            v-for="(labeledText, j) in chunkedLabeledText"
            :key="`labeledText_${i}_${j}`"
            :span="8"
            class="mr-2 p-2"
          >
            <el-row class="bg-gray p-2">
              <span>
                {{ labeledText.labelName }}
              </span>
            </el-row>
            <el-row
              class="flex mt-1 mb-1 new-line"
              v-for="(v, l) in labeledText.textValues"
              :key="`labeledText_${i}_${j}_${l}`"
            >
              <el-tag class="float-left" type="info" effect="dark" size="mini">
                {{ l + 1 }}
              </el-tag>

              <div class="flex ml-1">{{ v }}</div>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </div>
  </el-card>
</template>

<style scoped>
.border-red {
  border: solid red;
  border-radius: 10px;
}
.w-100 {
  width: 100%;
}
.flex {
  display: flex;
}
.float-left {
  float: left;
}
.b-2 {
  border: 2px solid #dcdfe6;
}
.text-center {
  text-align: center;
}
.font-bold {
  font-weight: bold;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 5px;
}
.float-left {
  float: left;
}
.bg-gray {
  background-color: #ebeef5;
}
.font-mini {
  font-size: 10px;
}
.font-default {
  font-size: 12px;
}
.ml-1 {
  margin-left: 0.5rem;
}
.mt-1 {
  margin-top: 0.5rem;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
</style>

<script>
export default {
  name: 'proposed-card',
  props: { proposal: { type: Object, default: () => ({}) } },
  data: () => ({ proposedText: [] }),
  created() {
    window.ProposedCard = this
    this.proposedText = this.proposal.labeledTexts.sort((a, b) => {
      return a.labelIndex - b.labelIndex
    })
  },
  watch: {
    proposal() {
      this.proposedText = this.proposal.labeledTexts.sort((a, b) => {
        return a.labelIndex - b.labelIndex
      })
    },
  },
}
</script>
