<template>
  <el-row>
    <el-row :style="{ borderBottom: '1px solid', borderColor: getBorderColor(isCrCanEdit) }">
      <el-col :span="4">
        <div style="display: flex">
          <div v-if="isShowIconWarning" style="margin-left: 5px; align-self: center">
            <el-tooltip
              placement="right-start"
              effect="light"
              popper-class="notifier__tooltip"
              :visible-arrow="false"
              :open-delay="500"
              :content="NOT_ABLE_CHANGE_STATUS_MSG"
            >
              <span class="notifier--warning"><i class="el-icon-warning error-mark"></i></span>
            </el-tooltip>
          </div>
          <div style="width: 100%">
            <status-area
              :status-selected="creative.approvalStatus"
              :target-type="'creative'"
              :size="'full'"
              @change-status="status => $emit('change-status-creative', creative.creativeId, status)"
              :is-show-alert-disabled="!isCrCanEdit"
              :is-creative-have-n-g="isCreativeHaveNG"
              :disabled="isDisableCreativeDropdownStatus"
            />
          </div>
        </div>
      </el-col>
      <el-col :span="20">
        <tag-area
          :tags="creative.combinedTags"
          :is-show-alert-disabled="!isCrCanEdit"
          :createdAt="creative.createdAt"
          :updatedAt="creative.updatedAt"
          :creativeType="creative.creativeType"
        />
      </el-col>
    </el-row>
    <el-col :span="4">
      <asset-area
        :platform-id="platformId"
        :ad-format-name="adFormatName"
        :creative="creative"
        :is-show="isShow"
        :bg-color="bgColor"
        :is-creative-list="isCreativeList"
        :asset-regulate-error="assetRegulateError"
        :is-asset-can-edit="isCrCanEdit"
        :adFormats="adFormats"
        @change-active-index="
          index => {
            activeIndex = index
          }
        "
        @change-status-asset="(assetId, status) => $emit('change-status-asset', assetId, status)"
        @update-ver-asset="
          (file, assetId, skipCheckRule, originId, allOriginIds, labelId) =>
            $emit('update-ver-asset', file, assetId, skipCheckRule, originId, allOriginIds, labelId)
        "
      />
    </el-col>

    <el-col :span="20">
      <el-row> </el-row>

      <el-row class="mt-1 mb-1 ml-1">
        <el-col :span="16">
          <text-set-area
            :text-set="creative.textSet"
            :is-creative-list="isCreativeList"
            :adFormats="adFormats"
            :adFormatName="adFormatName"
            :adFormatId="adFormatId"
            :platformId="platformId"
            :check-type="creative.creativeType"
            :is-text-can-edit="isCrCanEdit"
            @change-status-text="
              (textSetId, status, version) => $emit('change-status-text', textSetId, status, version)
            "
            @update-text="(form, textSet, isEqual, version) => $emit('update-text', textSet, form, isEqual, version)"
          />
        </el-col>

        <el-col :span="8">
          <comment-area
            ref="commentArea"
            :creative="creative"
            :is-in-dialog="isInDialog"
            :is-creative-list="isCreativeList"
            :is-update="isUpdate"
            :is-can-comment="isCrCanEdit"
            @change-creator-comment="v => $emit('change-creator-comment', v)"
            @change-note="v => $emit('change-note', v)"
            @change-reference-url="v => $emit('change-reference-url', v)"
            @get-creatives="$emit('get-creatives')"
            @update-creative="(urls, comment) => $emit('update-creative', urls, comment)"
            @open-proposed-dialog="(textSetId, adFormatId) => $emit('open-proposed-dialog', textSetId, adFormatId)"
          />
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<style scoped>
.w-100 {
  width: 100%;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.font-bold {
  font-weight: bold;
}
</style>

<script>
import AssetArea from '@/views/approval-detail/components/ad-format-tab/creative-card/main-area/components/asset-area'
import TagArea from '@/views/approval-detail/components/ad-format-tab/creative-card/main-area/components/tag-area'
import TextSetArea from '@/views/approval-detail/components/ad-format-tab/creative-card/main-area/components/text-set-area'
import CommentArea from '@/views/approval-detail/components/ad-format-tab/creative-card/main-area/components/comment-area'
import StatusArea from '@/views/approval-detail/components/ad-format-tab/creative-card/main-area/components/status-area'
import _ from 'lodash'
import util from '@/mixins/util'
import { NOT_ABLE_CHANGE_STATUS_MSG } from '@/mixins/constant'

export default {
  name: 'main-area',
  components: { AssetArea, TagArea, TextSetArea, CommentArea, StatusArea },
  mixins: [util],
  props: {
    platformId: { type: String, default: () => '' },
    adFormatName: { type: String, default: () => '' },
    adFormatId: { type: String, default: () => '' },
    adFormats: { type: Array, default: () => [] },
    creative: { type: Object, default: () => ({}) },
    isInDialog: { type: Boolean, default: () => false },
    isCrCanEdit: { type: Boolean, default: () => false },
    isCreativeList: { type: Boolean, default: () => false },
    isShow: { type: Boolean, default: () => false },
    isUpdate: { type: Boolean, default: () => false },
    bgColor: { type: String, default: () => '' },
    assetRegulateError: { type: Array, default: () => [] },
  },
  data: () => ({
    activeIndex: 0,
    NOT_ABLE_CHANGE_STATUS_MSG: NOT_ABLE_CHANGE_STATUS_MSG,
  }),
  created() {
    window.MainArea = this
  },
  computed: {
    isShowIconWarning() {
      const isAssetsLatest =
        !this.creative.assets || this.creative.assets.map(asset => asset.isLatest).every(isLatest => isLatest)
      const isTextLatest = !this.creative.textSet || this.creative.textSet.isLatest
      return (!isAssetsLatest || !isTextLatest) && !this.isCrCanEdit
    },
    setTextBorderColorSubmitStatus() {
      if (this.creative.submissionStatus.includes('入稿済')) {
        return 'rgb(91, 184, 93)'
      } else if (this.creative.submissionStatus.includes('未入稿')) {
        return '#ef342f'
      } else if (this.creative.submissionStatus === '入稿済') {
        return 'rgb(91, 184, 93)'
      } else {
        return '#ffa500'
      }
    },

    isCreativeHaveNG() {
      const assetStatus = _.uniq(this.creative.assets.flatMap(x => x.approvalStatus))
      return _.includes(assetStatus, '破棄') || this.creative.textSet.approvalStatus === '破棄'
    },
    isDisableCreativeDropdownStatus() {
      const SEND_REQUEST_SUBMIT_STATUS = '入稿依頼済'
      const SUBMITED_STATUS = '入稿済'

      // check creative status is sendToSubmit or submited => disable
      switch (this.creative.approvalStatus) {
        case SEND_REQUEST_SUBMIT_STATUS:
          return true
        case SUBMITED_STATUS:
          return true
        default:
          return false
      }
    },

    setColorText() {
      switch (this.creative.approvalStatus) {
        case '監修中':
          return '#fff'
        case '要修正':
          return '#fff'
        case '承認待ち':
          return '#fff'

        case '承認':
          return '#fff'
        case '入稿可能':
          return '#fff'

        case '入稿不可':
          return '#c0c4cc5c'

        case '入稿依頼済':
          return '#fff'
        case '入稿済':
          return '#fff'
        case '破棄':
          return '#c0c4cc5c'
        default:
          return 'rgb(6 6 48 / 45%)'
      }
    },
    setBackgroundColorApprovalStatus() {
      switch (this.creative.approvalStatus) {
        case '監修中':
          return '#909399'
        case '要修正':
          return '#ef342f'
        case '承認待ち':
          return '#909399'

        case '承認':
          return 'rgb(91, 184, 93)'
        case '入稿可能':
          return 'rgb(91, 184, 93)'

        case '入稿不可':
          return 'rgba(245,245,245,0.48)'
        case '破棄':
          return 'rgba(245,245,245,0.48)'

        case '入稿依頼済':
          return '#ffa500'
        case '入稿済':
          return '#084c6c'
        default:
          return 'white'
      }
    },
  },
}
</script>
