var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"d-flex"},[_c('div',{class:`status-select ${this.size === 'full' ? 'full-width' : ''}`},[_c('el-popover',{class:`status-popover`,attrs:{"placement":"bottom-start","popper-class":`status-popover-dropdown ${this.size == 'full' ? ' full-width' : ''}`,"trigger":"click","disabled":_vm.isCreativeHaveNG || _vm.disabled},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"status-menu"},_vm._l((_vm.statusOption),function(status,i){return _c('div',{key:status + i,staticStyle:{"display":"flex","justify-content":"center"}},[_c('el-tag',{staticClass:"w-100 font-bold status-item",style:(_vm.getStatusStyle(status)),attrs:{"slot":"reference","type":"info","effect":"plain"},on:{"click":() => {
                _vm.changeStatus(status)
                _vm.visible = false
              }},slot:"reference"},[_vm._v(" "+_vm._s(status)+" ")])],1)}),0),_c('el-tag',{staticClass:"w-100 font-bold status-button",style:({
          backgroundColor: this.setBackgroundColorApprovalStatus(_vm.statusSelected),
          color: this.setColorText(_vm.statusSelected),
        }),attrs:{"slot":"reference","type":"info","effect":"plain"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.statusSelected)+" "),(!_vm.isCreativeHaveNG && !_vm.disabled)?_c('i',{staticClass:"el-icon-arrow-down el-icon--right"}):_vm._e()])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }