export class CreativeStatus {
  static SEND_REQUEST_SUBMIT = '入稿依頼済'
  static SUBMITTED = '入稿済'
  static NG = '入稿不可'
  static CHECKING = '承認待ち'
  static APPROVED = '入稿可能'
  static WAITING_FOR_APPROVAL = '監修未提出'

  static NO_CREATE_AR = [CreativeStatus.SEND_REQUEST_SUBMIT, CreativeStatus.SUBMITTED, CreativeStatus.NG]

  static NO_EDIT_CR = [CreativeStatus.SEND_REQUEST_SUBMIT, CreativeStatus.SUBMITTED]

  static NO_SELECT_CR_STATUSES = [
    CreativeStatus.SEND_REQUEST_SUBMIT,
    CreativeStatus.SUBMITTED,
    CreativeStatus.WAITING_FOR_APPROVAL,
  ]

  static CANNOT_SELECTED_STATUSES_IN_CRS_SCREEN = [CreativeStatus.SEND_REQUEST_SUBMIT, CreativeStatus.SUBMITTED]

  static NO_DELETE_CR = [CreativeStatus.SEND_REQUEST_SUBMIT, CreativeStatus.SUBMITTED, CreativeStatus.CHECKING]

  static NO_UPDATE_STATUS_CR = [CreativeStatus.SEND_REQUEST_SUBMIT, CreativeStatus.SUBMITTED, CreativeStatus.NG]

  static NOT_UPDATABLE_STATUSES_WHILE_CREATE_AR = [CreativeStatus.SEND_REQUEST_SUBMIT, CreativeStatus.SUBMITTED]
}
