<template>
  <el-row id="comment-area-text" v-loading="loading" :gutter="10">
    <el-col :span="24" style="padding-left: 0">
      <div style="margin-bottom: 1vh">
        <el-select
          v-loading="loading"
          v-if="
            !['/creative-regist', '/creative-regist-only', '/creative-list', '/wall-hitting'].includes($route.path) &&
            isTextMaster
          "
          v-model="textSet.approvalStatus"
          placeholder="Select"
          size="small"
          style="width: 100%"
          @change="changeStatus"
        >
          <el-option v-for="(option, i) in approvalStatusOptions" :key="i" :label="option" :value="option" />
        </el-select>

        <el-tag v-else type="info" class="w-100 font-bold" style="font-weight: bold">
          {{ textSet.approvalStatus }}
        </el-tag>
      </div>
      <el-row class="mb-1"> <i class="fas fa-comments" /> 製作者コメント </el-row>

      <el-row class="mb-1">
        <el-input
          class="w-100"
          type="textarea"
          :rows="7"
          :value="textSet.creatorComment"
          :disabled="displayType !== 'approvalRequest'"
          @input="v => $emit('change-creator-comment', v)"
        />
      </el-row>

      <el-row v-if="displayType === 'approvalRequest' && !isInDialog" type="flex" justify="end">
        <el-button size="mini" type="primary" @click="saveCreatorComment(textSet)"> 保存 </el-button>
      </el-row>
    </el-col>
  </el-row>
</template>

<style>
#comment-area-text {
  padding-left: 0.5rem;
}
</style>

<style scoped>
.w-100 {
  width: 100%;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.8rem;
}
.mt-1 {
  margin-top: 0.8rem;
}
.mb-2 {
  margin-bottom: 0.8rem;
}
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'

export default {
  name: 'comment-area-text',
  mixins: [util],
  props: {
    textSet: { type: Object, default: () => ({}) },
    isInDialog: { type: Boolean, default: () => false },
    displayType: { type: String, default: () => '' },
    adFormatId: { type: String, default: () => '' },
    isTextMaster: { type: Boolean, default: () => false },
  },
  data: () => ({ loading: false, notApprovalReasonIndex: null }),
  created() {
    window.CommentAreaText = this
    this.init()
  },
  methods: {
    async changeStatus() {
      const body = {
        targetType: 'textSet',
        targetIds: [{ targetId: this.textSet.textSetId }],
        approvalStatus: this.textSet.approvalStatus,
      }

      this.loading = true
      await this.$api.authFetch('/approval_status', { method: 'PUT', body })
      this.loading = false

      this.$emit('get-text-sets')
    },
    async saveCreatorComment(textSet) {
      const labeledTexts = _.map(textSet.labeledTexts, r2 => _.pick(r2, ['labelId', 'textValues']))
      const body = {
        textSetId: _.result(textSet, 'textSetId') || '',
        adFormatId: this.adFormatId,
        labeledTexts,
        projectName: _.result(textSet, 'projectName') || '',
        score: _.result(textSet, 'score') || 0,
        creatorComment: _.result(textSet, 'creatorComment') || '',
      }

      this.loading = true
      const result = await this.$api.authFetch('/text_set', { method: 'PUT', body })
      this.loading = false

      if (result && result.errors) {
        this.errors = result.errors.map(x => {
          return x.labelNames.join(', ').concat(`: ${x.error}`)
        })
        await this.$alert('規定エラーがあります。エラーボタンから確認できます。', '確認', {
          type: 'warning',
        })
      } else {
        this.$message.success('登録成功')
        this.$emit('get-text-sets')
      }
    },
    openProposedDialog() {
      this.$emit('open-proposed-dialog')
    },
    init() {
      if (!this.textSet.notApprovalReason) return
      this.notApprovalReasonIndex = this.textSet.notApprovalReason.length - 1
    },
  },
  watch: {
    textSet: {
      handler() {
        this.init()
      },
      deep: true,
    },
  },
}
</script>
