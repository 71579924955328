<template>
  <div :style="[wrapHeight]">
    <div :style="[flexStyle]">
      <div class="search-filter">
        <label class="filter-label" v-if="countApproval > -1">全{{ countApproval }}件</label>
        <el-select class="selectpicker" :value="sortTypeData" :placeholder="sortType" @change="handelPageSort($event)">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            style="text-align: left"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <ul class="list-result">
      <li v-for="(approval, i) in currentApprovalData" :key="i" @click="e => navigateToApprovalDetail(e, approval)">
        <approval-card
          :approval-request="approval"
          :approval-category="approvalCategory"
          :current-page="currentPage"
          :drawerTab="drawerTab"
          :ad-formats="adFormats"
          :selected-promotion-ids="selectedPromotionIds"
          @change_loading="isLoading => $emit('change_loading', isLoading)"
          @reload-data="type => $emit('reload-data', type)"
        />
      </li>
    </ul>
    <el-pagination
      layout="prev, pager, next"
      v-if="this.countApproval > 1"
      :page-size="limitPerPage"
      :pager-count="11"
      :total="countApproval"
      :current-page.sync="currentPageData"
      @current-change="handlePageChange($event)"
    />

    <approval-request-dialog
      ref="approvalRequestDialog"
      :approval-request-draft-data="approvalDetail"
      type="draft"
      :ad-formats="adFormats"
      :selected-promotion-ids="selectedPromotionIds"
      :selected="creativeSelected"
      :items="groupedCreatives"
      @open-complete-dialog="(url, type) => $refs.completeDialog.show(url, type)"
      @reload-data="type => $emit('reload-data', type)"
    />

    <complete-dialog ref="completeDialog" />
  </div>
</template>
<style>
.arlist-page .search-filter {
  margin-bottom: 12px;
  width: 100%;
  padding: 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.arlist-page .search-filter .filter-label {
  color: #605f5f;
  padding-right: 24px;
  margin-bottom: 0;
  font-size: 13px;
  padding-top: 2px;
  font-weight: 600;
}
.arlist-page .search-filter .bootstrap-select .dropdown-toggle {
  font-size: 12px;
  border: 1px solid #d6d6d6;
  color: #237da7;
  font-weight: 700;
}
.arlist-page .search-filter .bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}
.arlist-page .search-filter .bootstrap-select .dropdown-toggle::after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
  content: '\f107';
  border: none;
  font-size: 18px;
  color: #237da7;
}
.arlist-page .list-result {
  padding: 0 5px 5px;
  height: calc(100% - 40px);
  overflow-y: scroll;
}
.arlist-page .list-result::after {
  content: '';
  height: 10px;
  display: block;
}
.arlist-page .list-result > li {
  margin-bottom: 15px;
  background-color: #f2f2f4;
  border-radius: 10px;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  min-height: 155px;
  padding: 8px 8px 8px 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.arlist-page .list-result > li * {
  cursor: pointer;
}
.arlist-page .selectpicker input {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #8f9eaf;
}
</style>
<script>
import _ from 'lodash'
import ApprovalCard from './components/approval-card.vue'
import ApprovalRequestDialog from '@/components/approval-request-dialog'
import CompleteDialog from '@/components/complete-dialog'

export default {
  name: 'approval-list',
  components: { ApprovalCard, ApprovalRequestDialog, CompleteDialog },
  props: {
    sortType: { type: String, default: () => 'priority_desc' },
    limitPerPage: { type: Number, default: () => 100 },
    countApproval: { type: Number, default: () => -1 },
    currentApprovalData: { type: Array, default: () => [] },
    approvalCategory: { type: String, default: () => '' },
    currentPage: { type: Number, default: () => 1 },
    drawerTab: { type: String, default: () => null },
    adFormats: { type: Array, default: () => [] },
    selectedPromotionIds: { type: Array, default: () => [] },
  },
  data() {
    return {
      flexStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      },
      wrapHeight: {
        height: 'calc(100% - 60px)',
        position: 'relative',
      },
      options: [
        {
          value: 'priority_desc',
          label: '優先度順',
        },
        {
          value: 'due_date_asc',
          label: '対応期日が早い順',
        },
        {
          value: 'due_date_desc',
          label: '対応期日が遅い順',
        },
        {
          value: 'created_at_desc',
          label: '新しい依頼順',
        },
        {
          value: 'created_at_asc',
          label: '古い依頼順',
        },
      ],
      currentPageData: 1,
      approvalDetail: { type: Object, default: {} },
    }
  },
  created() {
    if (this.approvalCategory === 'draft' || this.approvalCategory === 'done') {
      this.flexStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }
      this.wrapHeight = {
        ...this.wrapHeight,
        height: 'calc(100% - 40px)',
      }
    }
  },
  methods: {
    async navigateToApprovalDetail(e, approval) {
      if (e.target && !this.checkClickableSlickNavigation(e.target)) {
        if (this.drawerTab === 'draft') {
          const promotionId = this.selectedPromotionIds[0]
          this.$emit('change_loading', true)
          if (promotionId) {
            const approvalDetail = await this.getApprovalDetail(promotionId, approval.approvalRequestId)
            this.approvalDetail = approvalDetail
          }
          await this.$refs.approvalRequestDialog.show('creative')
          this.$emit('change_loading', false)
        } else {
          const query = this.$route.query
          const id = approval.approvalRequestId
          query.approvalRequestId = id
          if (id) {
            this.$router.push({ path: '/approval-detail', query })
          }
        }
      }
    },
    checkClickableSlickNavigation(target) {
      const currentHTMLTag = target.tagName.toLowerCase()
      switch (currentHTMLTag) {
        case 'button':
          return target.className.includes('slick-arrow')
        case 'svg':
          return target.parentElement && target.parentElement.className.includes('slick-arrow')
        case 'path':
          return (
            target.parentElement &&
            target.parentElement.parentElement &&
            target.parentElement.parentElement.className.includes('slick-arrow')
          )
        default:
          return false
      }
    },
    async getApprovalDetail(promotionId, approvalRequestId) {
      const data = await this.$api.authFetch(
        `/approval_request/detail?promotionId=${promotionId}&approvalRequestId=${approvalRequestId}`,
        { method: 'GET' }
      )
      return data
    },
    scrollToTop() {
      const firstApproval = document.querySelector('.list-result > li')
      if (firstApproval) {
        firstApproval.scrollIntoView({ behavior: 'smooth' })
      }
    },
    handlePageChange($event) {
      this.scrollToTop()
      this.$emit('handle-page-change', $event)
    },
    handelPageSort($event) {
      this.scrollToTop()
      this.$emit('handel-page-sort', $event)
    },
  },
  computed: {
    sortTypeData() {
      return this.sortType
    },
    creativeSelected() {
      return _.map(this.approvalDetail.creatives, x => {
        return _.assign({}, x, { platformId: x.platform.id, adFormatId: x.adFormat.id })
      })
    },
    groupedCreatives() {
      return _.chain(this.approvalDetail.creatives)
        .groupBy('platform.id')
        .map(group => ({
          plaformName: _.head(group).platform.name,
          platformId: _.head(group).platform.id,
          creatives: _.map(group, o => _.omit(o, 'platform')),
          selected: group,
        }))
        .value()
    },
  },
  watch: {
    currentPage() {
      this.currentPageData = this.currentPage
    },
    drawerTab() {
      this.scrollToTop()
    },
  },
}
</script>
