<template>
  <el-container id="approval-detail">
    <custom-loading v-show="loading || submitARLoading" :backgroundTransparent="true" />
    <div id="contents">
      <div class="page-wrapper">
        <div class="sidebar">
          <div class="slider-body">
            <div class="sidebar-inner">
              <div class="backpage mb-3">
                <a @click="navigateToApprovalList" class="pointer"
                  ><i class="fas fa-angle-double-left"></i> 監修依頼一覧に戻る</a
                >
              </div>
              <div class="select-approval">
                <el-select
                  ref="approvalSelect"
                  class="dropdown bootstrap-select pointer"
                  :value="approvalRequestId"
                  filterable
                  @change="changeApprovalDetailId($event)"
                >
                  <el-option
                    v-for="item in approvalListOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </div>
              <div class="priority-infor">
                <div class="row row-10 mb-2">
                  <strong class="col-5 default d-flex-v-center">優先度</strong>
                  <div class="col-7 default d-flex-v-center">
                    <el-select
                      class="select-box-dropdown pointer priority_select"
                      placeholder=""
                      :style="`color: ${priorityColor}!important;`"
                      :value="approvalDetailPayload ? approvalDetailPayload.priority : undefined"
                      @change="changeApprovalRequestPriorty($event)"
                    >
                      <el-option
                        v-for="item in priorityOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        :style="`color: ${item.color}; text-align: center`"
                      />
                    </el-select>
                  </div>
                </div>
                <div class="row row-10 mb-2">
                  <strong class="col-5 default created_at d-flex-v-center">依頼日</strong>
                  <div class="col-7 default d-flex-v-center">
                    <p style="width: 90px; text-align: center; font-weight: 500">
                      {{ formatDate(approvalDetail.createdAt) }}
                    </p>
                  </div>
                </div>
                <div class="row row-10 mb-2">
                  <strong class="col-5 default deadline d-flex-v-center">対応期日</strong>
                  <div class="col-7 default d-flex-v-center">
                    <el-date-picker
                      class="custom-date-picker"
                      :editable="false"
                      @change="isNewARDetailData"
                      v-model="approvalDetailPayload.dueDate"
                      type="date"
                      :picker-options="datePickerDueDateOptions"
                      popper-class="due-date-picker"
                      format="yyyy/MM/dd"
                    />
                  </div>
                </div>
                <div class="row row-10 mb-2">
                  <strong class="col-5 default notidate d-flex-v-center">対応期日通知</strong>
                  <div class="col-7 default d-flex-v-center">
                    <el-select
                      class="select-box-dropdown pointer noti_select"
                      placeholder=""
                      :value="approvalDetailPayload ? approvalDetailPayload.notiDate : undefined"
                      @change="changeApprovalDetailNotiDate($event)"
                      clearable
                    >
                      <el-option
                        v-for="item in notiOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        style="text-align: center; font-size: 12px !important; font-weight: bold !important"
                      />
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="sidebar-inner">
              <div class="request-row default">
                <label class="request-label">制作物</label>
                <div>
                  <strong class="request-number">{{ approvalStatusDetail.totalStatus }}</strong>
                  <label class="request-label">件</label>
                </div>
              </div>
              <ul class="view-list default">
                <li>
                  <div class="label-name _task">
                    <span class="name">タスク</span>
                    <span class="text">要修正</span>
                  </div>
                  <div class="result-number">
                    <div class="">
                      <p class="title">画像/動画</p>
                      <p class="content">
                        <span class="number">{{ approvalStatusDetail.totalTask.asset }}</span>
                        <span class="text">件</span>
                      </p>
                    </div>
                    <div class="">
                      <p class="title _text">テキスト</p>
                      <p class="content">
                        <span class="number">{{ approvalStatusDetail.totalTask.text }}</span>
                        <span class="text">件</span>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="label-name _waiting">
                    <span class="name">待ち</span>
                    <span class="text">監修中</span>
                  </div>
                  <div class="result-number">
                    <div class="">
                      <p class="title">画像/動画</p>
                      <p class="content">
                        <span class="number">{{ approvalStatusDetail.totalWait.asset }}</span>
                        <span class="text">件</span>
                      </p>
                    </div>
                    <div class="">
                      <p class="title _text">テキスト</p>
                      <p class="content">
                        <span class="number">{{ approvalStatusDetail.totalWait.text }}</span>
                        <span class="text">件</span>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="label-name _completion">
                    <span class="name">完了</span>
                    <span class="text">承認</span>
                  </div>
                  <div class="result-number">
                    <div class="">
                      <p class="title">画像/動画</p>
                      <p class="content">
                        <span class="number">{{ approvalStatusDetail.totalDone.asset }}</span>
                        <span class="text">件</span>
                      </p>
                    </div>
                    <div class="">
                      <p class="title _text">テキスト</p>
                      <p class="content">
                        <span class="number">{{ approvalStatusDetail.totalDone.text }}</span>
                        <span class="text">件</span>
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="label-name _ng">
                    <span class="name">完了</span>
                    <span class="text">破棄</span>
                  </div>
                  <div class="result-number">
                    <div class="">
                      <p class="title">画像/動画</p>
                      <p class="content">
                        <span class="number">{{ approvalStatusDetail.totalNG.asset }}</span>
                        <span class="text">件</span>
                      </p>
                    </div>
                    <div class="">
                      <p class="title _text">テキスト</p>
                      <p class="content">
                        <span class="number">{{ approvalStatusDetail.totalNG.text }}</span>
                        <span class="text">件</span>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style="width: 85%; height: 100%">
          <div style="display: flex; justify-content: space-between; padding-top: 16px; height: 5%">
            <div style="display: flex; padding-left: 16px; align-items: center">
              <strong class="font-big center">
                {{ selectedCreativeIds.length != 0 ? selectedCreativeIds.length + '件選択済' : '選択なし' }}
              </strong>

              <template class="ml-1">
                <el-divider direction="vertical" />

                <el-button type="text" class="text-black center" @click="selectAll()"> 全件選択 </el-button>

                <el-button
                  :disabled="selectedCreativeIds.length == 0"
                  type="text"
                  class="text-black center"
                  @click="deselectAll()"
                >
                  選択解除
                </el-button>
              </template>
              <template>
                <el-divider direction="vertical" />
                <el-button
                  :disabled="selectedCreativeIds.length == 0 || isCreativeHaveNG || isDisableChangeCreativeStatus"
                  type="text"
                  class="text-black center"
                  @click="$refs.statusDialog.show()"
                >
                  <i class="fas fa-user" /> 入稿ステータス変更
                </el-button>
                <el-divider direction="vertical" />
              </template>
            </div>
            <div style="display: flex; align-self: center; padding-right: 16px">
              <el-button class="create-cr header-action" @click="openCreateCreativeDialog" round>
                CRを作成して追加
              </el-button>
              <el-button class="select-cr header-action" @click="openSelectCreativeDialog" round>
                CR一覧から追加
              </el-button>
            </div>
          </div>
          <div style="height: calc(95% - 16px); padding-bottom: 6%">
            <el-tabs v-model="activeTabName" type="border-card" class="detail-tab">
              <el-tab-pane label="提出CR一覧" name="creative" :closable="false" class="creative-tab">
                <div style="display: flex; justify-content: end">
                  <div style="display: flex; justify-content: end; padding-right: 16px">
                    <el-popover
                      placement="bottom-start"
                      width="430"
                      trigger="click"
                      popper-class="platform-filter-popover"
                      v-model="showCrFilterAdFormat"
                    >
                      <div>
                        <div style="display: flex; padding-left: 12px; padding-right: 12px">
                          <div style="width: 40%; border-right: 1px solid #d6d6d6">
                            <div style="display: flex; margin-bottom: 5px; padding-right: 5px; margin-top: 5px">
                              <span
                                @click="handleCheckAllPlatform"
                                :style="{ color: checkAllPlatform ? '#409EFF' : '#606266', cursor: 'pointer' }"
                                >全て選択</span
                              >
                              <span @click="handleRemovePlatformChecked" style="padding-left: 10px; cursor: pointer"
                                >選択解除</span
                              >
                            </div>
                            <el-checkbox-group v-model="checkedPlatform" @change="handleCheckedPlatformFilter">
                              <div style="display: flex; flex-direction: column; gap: 5px; padding-bottom: 5px">
                                <el-checkbox
                                  v-for="platform in platformsData.map(x => x.platformName)"
                                  :label="platform"
                                  :key="platform"
                                >
                                  <el-tooltip
                                    placement="right"
                                    popper-class="metadata-tooltip"
                                    :content="platform"
                                    :disabled="!isDisplayNameToolTipByLength(platform, 8)"
                                  >
                                    <div>
                                      {{ formatNameByLength(platform, 8) }}
                                    </div>
                                  </el-tooltip>
                                </el-checkbox>
                              </div>
                            </el-checkbox-group>
                          </div>
                          <div style="width: 59%; padding-left: 12px">
                            <div style="display: flex; margin-bottom: 5px; padding-right: 5px; margin-top: 5px">
                              <span
                                @click="handleCheckAllAdFormat"
                                :style="{ color: checkAllAdFormat ? '#409EFF' : '#606266', cursor: 'pointer' }"
                                >全て選択</span
                              >
                              <span @click="handleRemoveAdFormatChecked" style="padding-left: 10px; cursor: pointer"
                                >選択解除</span
                              >
                            </div>
                            <el-checkbox-group
                              v-if="checkedPlatform.length > 0"
                              v-model="checkedAdFormat"
                              @change="handleCheckedAdFormatFilter"
                            >
                              <div style="display: flex; flex-direction: column; gap: 5px; padding-bottom: 5px">
                                <el-checkbox v-for="adFormat in adFormatOptionFilter" :label="adFormat" :key="adFormat">
                                  <el-tooltip
                                    placement="right"
                                    popper-class="metadata-tooltip"
                                    :content="adFormat"
                                    :disabled="!isDisplayNameToolTipByLength(adFormat, 16)"
                                  >
                                    <div>
                                      {{ formatNameByLength(adFormat, 16) }}
                                    </div>
                                  </el-tooltip>
                                </el-checkbox>
                              </div>
                            </el-checkbox-group>
                          </div>
                        </div>
                        <div style="display: flex; justify-content: end; margin: 10px">
                          <el-button type="primary" @click="filterAdFormat" style="margin-top: 5px; padding: 5px 10px">
                            適応
                          </el-button>
                        </div>
                      </div>
                      <el-button slot="reference" class="filter-creative" style="width: 430px; padding-right: 6px">
                        <div style="display: flex; justify-content: space-between">
                          <div>媒体・フォーマットで絞込</div>
                          <i class="el-icon-arrow-down el-icon--right" />
                        </div>
                      </el-button>
                    </el-popover>
                  </div>
                  <div style="display: flex; justify-content: end; padding-right: 16px">
                    <el-popover placement="bottom-start" width="145" trigger="click" v-model="showCrFilterStatus">
                      <div>
                        <div style="display: flex; justify-content: space-between; margin-bottom: 5px">
                          <span
                            @click="handleCheckAllChange"
                            :style="{ color: checkAll ? '#409EFF' : '#606266', cursor: 'pointer' }"
                            >全て選択</span
                          >
                          <span @click="handleRemoveChecked" style="cursor: pointer">選択解除</span>
                        </div>
                        <el-checkbox-group v-model="checkedStatus" @change="handleCheckedCitiesChange">
                          <el-checkbox v-for="status in statusOptionFilter" :label="status" :key="status"
                            >{{ status }}
                          </el-checkbox>
                        </el-checkbox-group>
                      </div>
                      <div style="display: flex; justify-content: end">
                        <el-button type="primary" @click="filterStatus" style="margin-top: 5px; padding: 5px 10px">
                          適応
                        </el-button>
                      </div>
                      <el-button slot="reference" class="filter-creative"
                        >ステータスで絞込 <i class="el-icon-arrow-down el-icon--right"></i
                      ></el-button>
                    </el-popover>
                  </div>
                </div>
                <custom-loading v-show="isFilteringCr" />
                <normal-creatives
                  ref="normalCreative"
                  :selected="creativeSelectedFilter"
                  :ad-formats="adFormats"
                  :grouped-creatives="groupedCreativesFilter"
                  :selected-creative-ids="selectedCreativeIds"
                  :asset-group-data="assetGroupData"
                  :asset-group-attach-files="assetGroupAttachFiles"
                  :asset-regulate-error="assetRegulateError"
                  @change-selected-creative-ids="changeSelectedCreativeIds"
                  @remove-creative="id => removeCreative(id)"
                  @change-creator-comment="
                    (comment, creativeId, platformId) => changeCreatorComment(comment, creativeId, platformId)
                  "
                  @change-note="(note, creativeId, platformId) => changeNote(note, creativeId, platformId)"
                  @change-reference-url="
                    (urls, creativeId, platformId) => changeReferenceUrl(urls, creativeId, platformId)
                  "
                  @change-status-text="(textSetId, status, version) => changeStatusText(textSetId, status, version)"
                  @change-status-asset="(assetId, status) => changeStatusAsset(assetId, status)"
                  @change-status-creative="(creativeId, status) => changeStatusCreative(creativeId, status)"
                  @update-text="(textSet, form, isEqual, version) => updateText(textSet, form, isEqual, version)"
                  @update-ver-asset="
                    (file, assetId, skipCheckRule, originId, allOriginIds, labelId) =>
                      updateVerAsset(file, assetId, skipCheckRule, originId, allOriginIds, labelId)
                  "
                />
              </el-tab-pane>
              <el-tab-pane
                label="制作意図記入（ユニーク）"
                name="comment"
                :closable="false"
                class="uniq-tab"
                style="padding: 10px 10px 0px"
              >
                <uniq-creatives
                  ref="uniqCreatives"
                  :selected="creativeSelectedFilter"
                  :grouped-creatives="groupedCreativesFilter"
                  :selected-creative-ids="selectedCreativeIds"
                  :ad-formats="adFormats"
                  :asset-group-data="assetGroupData"
                  :asset-regulate-error="assetRegulateError"
                  @update-text="(textSet, form, isEqual, version) => updateText(textSet, form, isEqual, version)"
                  @change-selected-at-tab="obj => $emit('change-selected-at-tab', obj)"
                  @change-asset-group-payload="data => changeAssetGroupData(data)"
                  @change-asset-group-addition-attach="
                    (payload, files) => changeAssetGroupAdditionAttachFile(payload, files)
                  "
                  @change-creator-comment-text="
                    (comment, textSetId, platformId, version) =>
                      changeCreativeCommentText(comment, textSetId, platformId, version)
                  "
                  @update-ver-asset="
                    (file, assetId, skipCheckRule, originId, allOriginIds, labelId) =>
                      updateVerAsset(file, assetId, skipCheckRule, originId, allOriginIds, labelId)
                  "
                />
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
      <div>
        <div class="button-area footer-area" style="justify-content: space-between">
          <div class="sidebar-footer" style="margin-left: 10px">
            <img :src="require(`@/assets/sepbase_logo.png`)" alt="" class="sepbase_logo" style="margin-bottom: 5px" />
            <a :href="buildSepBaseDomain" target="_blank" class="sepbase_url d-block" style="color: white"
              >SEPBASE URL
            </a>
            <nav @click="copy">
              <i class="fa-solid fa-link" slot="append" style="font-size: 12px; padding-left: 10px"></i>
            </nav>
          </div>
          <div>
            <el-button class="footer-button" type="primary" @click="submitApprovalRequest()">
              変更を保存・SEPBASE提出
            </el-button>
            <el-button
              :disabled="selectedCreativeIds.length == 0"
              class="footer-button"
              style="margin-right: 30px"
              type="primary"
              @click="openSubmissionRequestDialog()"
              >入稿依頼作成</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <status-dialog
      ref="statusDialog"
      :target-type="'creative'"
      @change-selected-creatives-status="status => changeSelectedCreativesStatus(status)"
    />
    <submission-request-dialog
      ref="submissionRequestDialog"
      :ad-formats="adFormats"
      :selected-promotion-ids="selectedPromotionIds"
      :selected="creativeSubmission"
      :grouped-creatives="groupedCreativesSubmission"
      @open-complete-dialog="
        url => {
          $refs.completeDialog.show(url, '入稿依頼')
          reloadData()
        }
      "
    />
    <complete-dialog ref="completeDialog" />
    <creative-dialog
      ref="creativeDialog"
      :tags="tags"
      :selected-promotion-ids="selectedPromotionIds"
      :ad-formats="adFormats"
      :adFormatId="adFormatId"
      :queryPlatformIds="queryPlatformIds"
      :queryAdFormatIds="queryAdFormatIds"
      :selectedCreative="selectedCreative"
      @submit-creatives="c => submitCreatives(c, SUBMIT_CREATIVE_SOURCE.LIST)"
    />
    <create-creative-dialog
      ref="createCreativeDialog"
      :tags="tags"
      :tag-types="tagTypes"
      :selected-promotion-ids="selectedPromotionIds"
      :ad-formats="adFormats"
      @submit-creatives="c => submitCreatives(c, SUBMIT_CREATIVE_SOURCE.ADD)"
    />
  </el-container>
</template>
<style scoped>
.header-action {
  font-size: 0.8em;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: 0.04em;
  text-align: center;
}
.header-action,
.header-action:hover,
.header-action:focus {
  color: #ffffff;
  background-color: #2684ff;
}
.header-action:hover {
  opacity: 0.9;
  scale: 0.99;
}
</style>
<style>
#approval-detail {
  width: 100%;
}

.due-date-picker {
  transform: scale(0.89) !important;
}

.due-date-picker td.next-month span {
  color: #606266 !important;
}

#approval-detail * {
  box-sizing: border-box;
}

#approval-detail .d-flex-v-center {
  display: flex;
  align-items: center;
}

#approval-detail .default {
  cursor: default;
}

#approval-detail .pointer {
  cursor: pointer;
}

#approval-detail .d-block {
  display: block !important;
}

#approval-detail .detail-tab {
  margin: 16px 16px 0px 16px;
  height: 100%;
  overflow: unset;
}

#approval-detail .el-tabs__header {
  height: 6%;
}

#approval-detail .el-tabs__content {
  height: 94%;
}

#approval-detail .el-tabs__content .creative-tab {
  height: 100%;
  overflow-y: auto;
  padding: 15px;
}

#approval-detail .el-tabs__content .uniq-tab {
  height: 100%;
  padding: 15px;
}

#approval-detail .el-tabs__content .uniq-tab .el-tabs__content {
  overflow-y: auto;
}

#approval-detail .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}

#approval-detail a {
  color: #237da7;
  font-size: 14px;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
  text-decoration: none;
  background-color: transparent;
}

#approval-detail b,
#approval-detail strong {
  font-weight: bolder;
  cursor: default;
}

#approval-detail .el-radio-group {
  width: 100% !important;
}

#approval-detail .filter-creative {
  padding-left: 6px;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 1px;
}

#approval-detail .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

#approval-detail .bootstrap-select {
  width: 100% !important;
  vertical-align: middle;
  margin-bottom: 24px;
}

#approval-detail .custom-date-picker.el-input {
  width: 90px !important;
  cursor: pointer;
}

#approval-detail .custom-date-picker .el-input__inner {
  padding: 0px 5px 0px 5px !important;
  height: 30px;
  font-size: 12px !important;
  font-weight: 500;
  text-align: center;
  color: inherit;
}

#approval-detail .custom-date-picker i {
  display: none;
}

/* #approval-detail .el-date-editor .el-icon-circle-close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 18px;
} */
#approval-detail .bootstrap-select input {
  font-weight: bold;
  border: 1px solid transparent;
  padding: 8px 30px 8px 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.font-big {
  font-size: 20px;
}

.ml-1 {
  margin-left: 10px;
}

.text-black {
  color: #000000 !important;
}

.center {
  display: flex !important;
  align-items: center !important;
}

#approval-detail .bootstrap-select input:hover {
  border-color: #dae0e5;
}

#approval-detail .dropdown {
  position: relative;
}

#approval-detail .button-approval {
  height: 1vh;
  display: flex;
  align-items: center;
}

#approval-detail .mb-3,
#approval-detail .my-3 {
  margin-bottom: 1rem !important;
}

#approval-detail .mb-2,
#approval-detail .my-2 {
  margin-bottom: 0.5rem !important;
}

/*#approval-detail [class*="col-"] {*/
/*  position: relative;*/
/*  width: 100%;*/
/*  padding-right: 15px;*/
/*  padding-left: 15px;*/
/*}*/
#approval-detail .col-5,
#approval-detail .col-7 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

#approval-detail .col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

#approval-detail .filter-select-approval .el-input__inner {
  border: none;
  border-bottom: 1px solid black;
  border-radius: unset;
  padding-left: 0px;
}

#approval-detail .col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  height: 30px;
}

#approval-detail .fa-angle-double-left:before {
  content: '\f100';
}

#approval-detail .row.row-10 {
  margin-right: -5px;
  margin-left: -5px;
}

#approval-detail .row.row-10 > [class*='col-'] {
  padding-left: 5px;
  padding-right: 5px;
}

#approval-detail #contents {
  width: 100%;
  height: calc(100vh - 60px);
  overflow-y: auto;
  position: relative;
}

#approval-detail #contents .bottom-right {
  position: fixed;
  bottom: 1.5rem;
  right: 1rem;
  border: none;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.5);
  color: #fff;
  background-color: #409eff;
  border-radius: 10px;
  font-size: inherit;
  font-weight: 700;
  z-index: 1;
  padding: 20px 30px;
}

#approval-detail #contents .urlInValid {
  background-color: #66b1ff5c !important;
}

#approval-detail #contents .bottom-right:hover {
  transform: scale(1.01);
  background-color: #66b1ff;
}

#approval-detail #contents .urlInValid:hover {
  transform: scale(1.01);
  background-color: #66b1ff5c !important;
}

#approval-detail #contents .bottom-right:active {
  transform: scale(0.99);
}

#approval-detail #contents .bottom-right .icon {
  margin-right: 0.5rem;
}

#approval-detail #contents .page-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

#approval-detail #contents .page-wrapper .sidebar {
  color: #060630;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-width: 230px;
  background-color: #f1f2f5;
  height: 100%;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  /* scrollbar-color: #d2d2d2 #2e3645 !important; */
  scrollbar-width: thin !important;
}

#approval-detail #contents .page-wrapper .sidebar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

#approval-detail #contents .page-wrapper .sidebar::-webkit-scrollbar-track-piece {
  background-color: #c2d2e4;
}

#approval-detail #contents .page-wrapper .sidebar::-webkit-scrollbar-thumb {
  height: 3px;
  background-color: #0a4c95;
}

#approval-detail #contents .page-wrapper .sidebar .sidebar-inner {
  padding: 16px 16px 10px;
}

#approval-detail #contents .page-wrapper .sidebar .bootstrap-select {
  width: 100% !important;
  margin-bottom: 24px;
}

#approval-detail #contents .page-wrapper .sidebar .bootstrap-select .dropdown-toggle {
  padding: 8px 16px 8px 6px;
  font-size: 14px;
  background-color: #fff;
}

#approval-detail #contents .page-wrapper .sidebar .bootstrap-select .dropdown-menu li a {
  padding: 4px 8px;
}

#approval-detail #contents .page-wrapper .sidebar .priority-infor {
  font-size: 12px;
}

#approval-detail #contents .page-wrapper .sidebar .priority-infor .select-box-dropdown {
  height: 18px;
  width: 52px;
  background-color: #fff;
  border-radius: 4px;
  font-weight: 700;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 12px;
}

#approval-detail .select-box-dropdown {
  width: 90px !important;
}

#approval-detail .priority_select input {
  font-weight: bold !important;
}

#approval-detail .select-box-dropdown input {
  color: inherit;
  font-size: 12px !important;
  text-align: center;
}

#approval-detail .select-box-dropdown input,
#approval-detail .select-box-dropdown i {
  height: 30px;
  line-height: 30px;
}

#approval-detail #contents .page-wrapper .sidebar .request-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 10px;
}

#approval-detail #contents .page-wrapper .sidebar .request-row .request-label {
  margin-bottom: 0;
  margin-right: 10px;
  color: #060630;
  font-size: 12px;
  font-weight: 700;
}

#approval-detail #contents .page-wrapper .sidebar .request-row .request-number {
  font-size: 21px;
  font-weight: 700;
  margin-right: 5px;
  color: #616161;
}

#approval-detail #contents .page-wrapper .form-label {
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #060630;
}

#approval-detail #contents .page-wrapper .sidebar .view-list {
  margin-top: 5px;
  margin-bottom: 16px;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li {
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; */
  padding: 4px 0;
  cursor: inherit;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .label-name {
  width: 100%;
  height: 24px;
  border-radius: 4px;
  font-size: 11px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 5px;
  font-weight: 600;
  position: relative;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .label-name._task {
  background-color: #e76867;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .label-name._waiting {
  background-color: #707070;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .label-name._completion {
  background-color: #33e53e;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .label-name._ng {
  background-color: #ccccd3;
  color: #060630;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .label-name .name {
  font-size: 10px;
  /* width: 40%; */
  text-align: left;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .label-name .text {
  /* width: 60%; */
  text-align: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .result-number {
  font-size: 12px;
  color: #060630;
  display: flex;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .result-number div:has(.title) {
  flex: 1;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .result-number div:has(.title):last-child {
  padding-left: 4px;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .result-number div:has(.content):last-child .content {
  border-left: 1px solid #616161a0;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .result-number .title {
  font-size: 10px;
  margin: 0;
  color: #616161;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .result-number .title._text {
  padding-left: 6px;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .result-number .content {
  margin: 0;
  color: #616161;
  text-align: right;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .result-number .content .text {
  font-weight: 600;
}

#approval-detail #contents .page-wrapper .sidebar .view-list li .result-number .number {
  font-size: 15px;
  padding-right: 5px;
  font-weight: 700;
  color: #616161;
}

#approval-detail .sidebar-footer {
  display: flex;
  align-items: center;
  gap: 3px;
}

#approval-detail .sidebar-footer img {
  width: 14px;
  height: 13px;
}

#approval-detail #contents .page-wrapper .main-contents {
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  overflow-y: auto;
  scrollbar-color: #d2d2d2 #2e3645 !important;
  scrollbar-width: thin !important;
}

#approval-detail #contents .page-wrapper .main-contents::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

#approval-detail #contents .page-wrapper® .main-contents::-webkit-scrollbar-track-piece {
  background-color: #c2d2e4;
}

#approval-detail #contents .page-wrapper .main-contents::-webkit-scrollbar-thumb {
  height: 3px;
  background-color: #0a4c95;
}

#approval-detail #contents .sepbase_url:hover {
  text-decoration: underline;
}
.platform-filter-popover {
  padding: 0 !important;
}
</style>
<script>
import _, { groupBy, isEmpty, isUndefined, sortBy, uniqBy } from 'lodash'
import { find } from 'lodash'
import moment from 'moment'
import util from '@/mixins/util'
// import { CreativeStatus } from '@/mixins/creativeStatus'
import NormalCreatives from '@/views/approval-detail/components/creative'
import UniqCreatives from '@/views/approval-detail/components/uniq'
import CreativeDialog from '@/components/creative-dialog'
import axios from 'axios'
import SubmissionRequestDialog from '@/views/creative-list/components/submission-request-dialog'
import StatusDialog from '@/views/approval-detail/components/ad-format-tab/creative-card/main-area/components/status-dialog'
import CompleteDialog from '@/components/complete-dialog'
import CustomLoading from '../../components/custom-loading'
// import { TEXT_EXISTED } from '@/mixins/constant' // comments for ticket CV-8685

export default {
  name: 'approval-detail',
  components: {
    NormalCreatives,
    CreativeDialog,
    CreateCreativeDialog: () => import('@/components/create-creative-dialog'),
    UniqCreatives,
    SubmissionRequestDialog,
    StatusDialog,
    CompleteDialog,
    CustomLoading,
  },
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    tagTypes: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
    adFormatId: { type: String, default: () => '' },
    queryPlatformIds: { type: Array, default: () => [] },
    queryAdFormatIds: { type: Array, default: () => [] },
    isShow: { type: Boolean, default: () => false },
    isInDialog: { type: Boolean, default: () => false },
    selectedCreative: { type: Object, default: () => ({}) },
    isCanLeave: { type: Boolean, default: () => false },
    isDisplayConfirm: { type: Boolean, default: () => false },
  },
  mixins: [util],
  data() {
    const SUBMIT_CREATIVE_SOURCE = Object.freeze({
      LIST: Symbol('list'),
      ADD: Symbol('add'),
    })
    return {
      SUBMIT_CREATIVE_SOURCE,
      loading: false,
      submitARLoading: false,
      firstLoading: true,
      showCrFilterStatus: false,
      showCrFilterAdFormat: false,
      isFilteringCr: true,
      approvalListOptions: { type: Array, default: () => [] },
      notiOptions: [
        {
          value: 0,
          label: '当日',
        },
        {
          value: 1,
          label: '1日前',
        },
        {
          value: 2,
          label: '2日前',
        },
      ],
      checkAll: false,
      // Creative Status by checked by filter selection in ui: It be changed will trigger check statusOptionFilter
      checkedStatus: [],

      statusSaveFilter: [],
      // Set value for approval status of Creative after component created
      statusOptionFilter: [],

      platformsData: [],

      checkAllPlatform: false,
      checkedPlatform: [],
      platformSaveFilter: [],

      checkAllAdFormat: false,
      adFormatOptionFilter: [],
      checkedAdFormat: [],

      adFormatSaveFilter: [],

      isIndeterminate: true,
      priorityOptions: [
        {
          value: 2,
          color: '#e76867',
          label: '高',
        },
        {
          value: 1,
          color: '#f5a623',
          label: '中',
        },
        {
          value: 0,
          color: '#33e53e',
          label: '低',
        },
      ],
      activeTabName: 'comment', //display active tab: either 'comment' or 'creative', default is 'comment'
      datePickerDueDateOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000
        },
      },
      approvalRequestId: { type: String, default: null },
      approvalDetail: { type: Object, default: {} },
      approvalStatusDetail: {
        totalStatus: 0,
        totalTask: {
          asset: 0,
          text: 0,
        },
        totalWait: {
          asset: 0,
          text: 0,
        },
        totalDone: {
          asset: 0,
          text: 0,
        },
        totalNG: {
          asset: 0,
          text: 0,
        },
      },

      approvalDetailPayload: {
        priority: null,
        dueDate: null,
        notiDate: null,
        creatives: null,
      },
      approvalDetailPayloadBase: {
        priority: null,
        dueDate: null,
        notiDate: null,
        creatives: null,
      },
      assetGroupStateData: [],
      assetGroupData: [],
      assetGroupAttachFiles: [],
      selectedCreativeIds: [],
      groupedCreatives: [],
      // textChanges: [], // comments for ticket CV-8685
      // create be checked in checkbox
      creativeSelected: [],
      groupedCreativesFilter: [],
      creativeSelectedFilter: [],
      groupedCreativesBase: [],
      creativeSelectedBase: [],
      creativeSubmission: [],
      groupedCreativesSubmission: [],
      updateTextList: [],
      assetObjects: [],
      assetRegulateError: [],
      assetErrorAssetIds: [],
      creativeAddList: [],
      isNewDataCreative: false,
      isNewApprovalDetailData: false,
      isConfirming: false,
    }
  },
  async created() {
    await this.loadDataAR()
  },

  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
    window.addEventListener('popstate', this.handlePopState)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
    window.removeEventListener('popstate', this.handlePopState)
    const query = _.omit(this.$route.query, ['creativeListForm', 'approvalRequestId'])
    this.$router.push({ query })
  },
  async beforeRouteLeave(to, from, next) {
    if (this.isCanLeave || this.isConfirming || (to.path === '/approval-list' && this.isDisplayConfirm)) {
      this.$emit('change-is-display-confirm', false)
      next() // Allow navigation if no unsaved changes
      return
    }

    this.isConfirming = true // Prevent multiple popups

    const message = '変更が確定されていません、画面を移動しますか？'
    const ret = await this.$confirm(message, '確認').catch(e => e)

    this.isConfirming = false // Reset confirmation state

    if (ret === 'cancel') {
      next(false) // Block route change
    } else {
      next() // Allow route change
    }
  },

  methods: {
    handleBeforeUnload(event) {
      if (!this.isCanLeave) {
        const message = '変更が確定されていません、画面を移動しますか？'
        event.returnValue = message // Show native confirmation popup
        return message
      }
    },

    async handlePopState() {
      if (this.isCanLeave || this.isConfirming) {
        const query = Object.assign({}, this.$route.query)
        const ARId = query.approvalRequestId
        if (ARId !== this.approvalRequestId) {
          this.loadDataAR()
        }
        return
      }
      this.isConfirming = true // Set flag to prevent multiple popups

      const message = '変更が確定されていません、画面を移動しますか？'
      const ret = await this.$confirm(message, '確認').catch(e => e)

      this.isConfirming = false // Reset the flag after confirmation

      if (ret === 'cancel') {
        // Prevent navigation if canceled
        window.history.pushState(null, '', window.location.href)
      } else {
        this.loadDataAR()
      }
    },

    async loadDataAR() {
      window.ApprovalDetail = this
      const query = Object.assign({}, this.$route.query)
      this.loading = true
      this.approvalRequestId = query.approvalRequestId
      const promotionId = this.selectedPromotionIds[0] ? this.selectedPromotionIds[0] : query.promotionIds
      if (promotionId) {
        const approvalDetail = await this.getApprovalDetail(promotionId, this.approvalRequestId)
        const { approvalRequests } = await this.$api.authFetch('/approval_request/list', {
          method: 'POST',
          body: {
            promotionIds: [promotionId],
            targetType: 'creative',
            approvalCategory: 'Official',
          },
        })
        const creative = _.map(approvalDetail.creatives, x => {
          return _.assign({}, x, { platformId: x.platform.id, adFormatId: x.adFormat.id })
        })
        const creativeSelected = _.orderBy(this.modifySelectedData(creative, approvalDetail.assetGroups), 'platformId')
        const assetGroupIds = _.chain(creativeSelected)
          .map(creative => creative.labeledAssets)
          .flattenDeep()
          .map(assetGroup => this.getAssetGroupId(assetGroup)) // get ids
          .uniq()
          .value()
        const assetGroupDatas = assetGroupIds.length > 0 ? await this.getAssetGroupData(assetGroupIds) : [] // get data of the asset groups

        this.assetGroupData = _.chain(assetGroupIds)
          .map(
            id =>
              _.find(assetGroupDatas, data => data.assetGroupId === id) || {
                assetGroupId: id,
                creatorComment: '',
                attachmentFile: [],
                additionFile: [],
              }
          )
          .value()

        // create assetGroupData
        this.approvalDetail = approvalDetail
        this.approvalListOptions = approvalRequests.map(approvalRequest => ({
          value: approvalRequest.approvalRequestId,
          label: approvalRequest.approvalRequestName,
        }))
        const adformatsPlatforms = creativeSelected.map(cr => {
          return {
            adFormatName: cr.adFormat.name,
            platformName: cr.platform.name,
          }
        })
        const groupPlatform = adformatsPlatforms.reduce((acc, { platformName, adFormatName }) => {
          // Find if the platform is already in the result
          let platform = acc.find(item => item.platformName === platformName)

          if (platform) {
            // Add the ad format to the array if it's not already there
            if (!platform.adFormatNames.includes(adFormatName)) {
              platform.adFormatNames.push(adFormatName)
            }
          } else {
            // Add a new platform entry
            acc.push({
              platformName: platformName,
              adFormatNames: [adFormatName],
            })
          }

          return acc
        }, [])
        this.platformsData = groupPlatform
        this.updateApprovalStatusDetail()
      }
      // Set creative status filter after created
      this.$emit('change-is-can-leave', true)
      this.statusOptionFilter = this.approvalStatusCreativeOptions
    },

    isNewCreativeData(creativesBase, creative) {
      if (creative.length !== creativesBase.length) {
        this.isNewDataCreative = true
        return
      }

      const checkCreatives = creative.reduce((result, creative) => {
        const creativeBase = creativesBase.find(x => x.creativeId === creative.creativeId)
        if (creativeBase) {
          result.push(_.isEqual(creativeBase, creative))
        } else {
          result.push(false)
        }
        return result
      }, [])
      this.isNewDataCreative = !_.every(checkCreatives, x => x === true)
    },

    compareARDetail(detailBase, detailNew) {
      // So sánh thuộc tính priority
      if (detailBase.priority !== detailNew.priority) {
        return false
      }

      // So sánh thuộc tính notiDate (cả hai đều phải null hoặc giống nhau)
      if (detailBase.notiDate !== detailNew.notiDate) {
        return false
      }

      // So sánh dueDate chỉ dựa trên ngày, tháng, năm
      const dateBase = new Date(detailBase.dueDate)
      const dateNew = new Date(detailNew.dueDate)
      const dateBaseWithoutTime = new Date(dateBase.getFullYear(), dateBase.getMonth(), dateBase.getDate())
      const dateNewWithoutTime = new Date(dateNew.getFullYear(), dateNew.getMonth(), dateNew.getDate())

      // So sánh dueDate sau khi đã bỏ phần thời gian
      if (dateBaseWithoutTime.getTime() !== dateNewWithoutTime.getTime()) {
        return false
      }

      // Nếu tất cả các điều kiện trên đều đúng, hai object được coi là giống nhau
      return true
    },

    isNewARDetailData() {
      this.isNewApprovalDetailData = !this.compareARDetail(this.approvalDetailPayloadBase, this.approvalDetailPayload)
    },

    selectAll() {
      this.selectedCreativeIds = this.groupedCreativesFilter.flatMap(groupedCreative => {
        return groupedCreative.creatives.map(creative => creative.creativeId)
      })
    },
    deselectAll() {
      this.selectedCreativeIds = []
    },
    changeSelectedCreativeIds(newCreativeIds) {
      this.selectedCreativeIds = newCreativeIds
    },
    removeCreative(id) {
      const newGroupedCreatives = this.groupedCreatives.map(gr => {
        const creatives = _.filter(gr.creatives, creative => creative.creativeId !== id)
        const selected = _.filter(gr.selected, creative => creative.creativeId !== id)
        return _.assign({}, gr, { creatives: creatives }, { selected: selected })
      })
      this.groupedCreatives = _.filter(newGroupedCreatives, gr => gr.selected.length > 0)
      this.creativeSelected = _.filter(this.creativeSelected, creative => creative.creativeId !== id)
      const assetIds = this.creativeSelected
        .flatMap(creative => creative.labeledAssets)
        .flatMap(labeledAssets => labeledAssets.assets)
        .map(asset => asset.assetId)
      this.assetObjects = _.filter(this.assetObjects, a => _.includes(assetIds, a.assetId))
      this.updateApprovalStatusDetail()
    },
    changeNote(note, creativeId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .map(crs => {
          if (crs.platformId !== platformId) return crs

          const creatives = _.map(crs.creatives, creative => {
            if (creative.creativeId !== creativeId) return creative
            return _.assign({}, creative, { note })
          })
          return _.assign({}, crs, { creatives })
        })
        .value()

      if (this.creativeSelected.length !== 0) {
        this.creativeSelected = _.chain(this.creativeSelected)
          .map(cr => {
            if (cr.creativeId !== creativeId) return cr
            return _.assign({}, cr, { note })
          })
          .value()
      }
    },
    changeCreatorComment(creatorComment, creativeId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 =>
            r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { creatorComment })
          )
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.creativeSelected.length !== 0) {
        this.creativeSelected = _.chain(this.creativeSelected)
          .cloneDeep()
          .map(r => (r.creativeId !== creativeId ? r : _.assign({}, r, { creatorComment })))
          .value()
      }
    },
    changeReferenceUrl(referenceUrls, creativeId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 =>
            r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { referenceUrls: referenceUrls })
          )
          return _.assign({}, r, { creatives })
        })
        .value()
      this.creativeSelected = _.chain(this.creativeSelected)
        .cloneDeep()
        .map(r => (r.creativeId !== creativeId ? r : _.assign({}, r, { referenceUrls: referenceUrls })))
        .value()
    },
    openSelectCreativeDialog() {
      this.$refs.creativeDialog.show()
    },
    openCreateCreativeDialog() {
      this.$refs.createCreativeDialog.show()
    },
    isCreativeIdChange() {
      const oldCreativeIds = this.approvalDetail.creatives.map(creative => creative.creativeId)
      const newCreativeIds = this.creativeSelected.map(creative => creative.creativeId)
      return !_.isEqual(oldCreativeIds.sort(), newCreativeIds.sort())
    },
    handleRemoveChecked() {
      this.checkAll = false
      this.checkedStatus = []
      this.isIndeterminate = false
    },
    handleCheckAllChange() {
      this.checkAll = true
      this.checkedStatus = this.statusOptionFilter
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      // TODO: change to manual update status filter list
      let checkedCount = value.length
      this.checkAll = checkedCount === this.statusOptionFilter.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.statusOptionFilter.length
    },

    handleCheckAllPlatform() {
      this.checkAllPlatform = true
      this.checkedPlatform = this.platformsData.map(x => x.platformName)
    },
    handleRemovePlatformChecked() {
      this.checkAllPlatform = false
      this.checkedPlatform = []
    },
    handleCheckedPlatformFilter(value) {
      let checkedCount = value.length
      this.checkAllPlatform = checkedCount === this.platformsData.length
    },

    handleCheckAllAdFormat() {
      this.checkAllAdFormat = true
      this.checkedAdFormat = this.adFormatOptionFilter
    },
    handleRemoveAdFormatChecked() {
      this.checkAllAdFormat = false
      this.checkedAdFormat = []
    },
    handleCheckedAdFormatFilter(value) {
      let checkedCount = value.length
      this.checkAllAdFormat = checkedCount === this.adFormatOptionFilter.length
    },

    updateVerAsset(newFile, assetId, skipCheckRule, originId, allOriginIds, labelId) {
      const file = URL.createObjectURL(newFile)
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          const creatives = _.map(r.creatives, r2 => {
            const labeledAssets = r2.labeledAssets.map(r3 => {
              const assets = r3.assets.map(r4 => {
                return r4.assetId !== assetId
                  ? r4
                  : _.assign(
                      {},
                      r4,
                      { url: file },
                      { thumbnailUrl: file },
                      { approvalStatus: '監修未提出' },
                      { version: r4.version + 1 }
                    )
              })
              return _.assign({}, r3, { assets })
            })
            return _.assign({}, r2, { labeledAssets })
          })
          return _.assign({}, r, { creatives })
        })
        .value()
      this.creativeSelected = _.chain(this.creativeSelected)
        .cloneDeep()
        .map(r => {
          const labeledAssets = r.labeledAssets.map(r2 => {
            const assets = r2.assets.map(r3 => {
              return r3.assetId !== assetId
                ? r3
                : _.assign({}, r3, { url: file }, { approvalStatus: '監修未提出' }, { version: r3.version + 1 })
            })
            return _.assign({}, r2, { assets })
          })
          return _.assign({}, r, { labeledAssets })
        })
        .value()
      const newAssetObjects = _.cloneDeep(this.assetObjects).filter(assetObject => assetObject.originId !== originId)
      newAssetObjects.push({
        assetId: assetId,
        skipCheckRule: skipCheckRule,
        file: newFile,
        originId: originId,
        allOriginIds: allOriginIds,
        labelId: labelId,
      })
      this.assetObjects = newAssetObjects
      const creativesUpdate = _.chain(this.creativeSelected)
        .cloneDeep()
        .filter(x => {
          const listAsset = _.chain(x.labeledAssets).map('assets').flattenDeep().map('assetId').flattenDeep().value()
          return _.includes(listAsset, assetId)
        })
        .value()
      creativesUpdate.map(creative => {
        //check status CR NG, SendSubmitRequest, Submitted
        if (
          creative.approvalStatus !== '入稿不可' &&
          creative.approvalStatus !== '入稿依頼済' &&
          creative.approvalStatus !== '入稿済'
        ) {
          this.changeStatusCreative(creative.creativeId, '承認待ち')
        }
      })
    },
    updateText(newTextSet, form, isEqual, version) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          const creatives = _.map(r.creatives, r2 => {
            const isMatchingTextSet =
              r2.textSet && r2.textSet.textSetId === newTextSet.textSetId && r2.textSet.version === version
            const textSet = isMatchingTextSet ? newTextSet : r2.textSet || undefined

            return _.assign({}, r2, { textSet })
          })
          return _.assign({}, r, { creatives })
        })
        .value()
      this.creativeSelected = _.chain(this.creativeSelected)
        .cloneDeep()
        .map(r => {
          const isMatchingTextSet =
            r.textSet && r.textSet.textSetId === newTextSet.textSetId && r.textSet.version === version
          const textSet = isMatchingTextSet ? newTextSet : r.textSet || undefined
          return _.assign({}, r, { textSet })
        })
        .value()
      if (!isEqual) {
        this.updateTextList = _.filter(
          this.updateTextList,
          x => !this.isCorrectElement(x.textSetId, x.version, form.textSetId, version)
        )
        this.updateTextList.push(form)
      } else {
        this.updateTextList = _.filter(
          this.updateTextList,
          x => !this.isCorrectElement(x.textSetId, x.version, form.textSetId, version)
        )
      }

      const creativesUpdate = _.chain(this.creativeSelected)
        .cloneDeep()
        .filter(x =>
          x.textSet
            ? this.isCorrectElement(x.textSet.textSetId, x.textSet.version, newTextSet.textSetId, version)
            : false
        )
        .value()
      creativesUpdate.map(creative => {
        //check status CR NG, SendSubmitRequest, Submitted
        if (
          creative.approvalStatus !== '入稿不可' &&
          creative.approvalStatus !== '入稿依頼済' &&
          creative.approvalStatus !== '入稿済'
        ) {
          // change text status also change status of creative of this text
          this.changeStatusCreative(creative.creativeId, '承認待ち')
        }
      })
    },

    isCorrectElement(elementCheckId, elementCheckVersion, elementCompareId, elementCompareVersion) {
      return elementCheckId === elementCompareId && elementCheckVersion === elementCompareVersion
    },

    // Status change by ui: change by self creative | change by asset/text of this creative
    changeStatusCreative(creativeId, status) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          const creatives = _.map(r.creatives, r2 =>
            r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { approvalStatus: status })
          )
          return _.assign({}, r, { creatives })
        })
        .value()
      this.creativeSelected = _.chain(this.creativeSelected)
        .cloneDeep()
        .map(r => (r.creativeId !== creativeId ? r : _.assign({}, r, { approvalStatus: status })))
        .value()
      this.updateApprovalStatusDetail()
    },
    updateApprovalStatusDetail() {
      const listAssetStt = _.chain(this.creativeSelected)
        .cloneDeep()
        .flatMapDeep('labeledAssets') // get labeled asset list
        .flatMapDeep('assets') // get asset list
        .filter(asset => asset && asset.assetId) // remove undefined asset or asset has undefined asset id
        .uniqBy('assetId') // get unique asset list by asset id TODO: check unique along with version
        .map('approvalStatus') // get approval status list from asset list
        .value()
      const listTextsetStt = _.chain(this.creativeSelected)
        .cloneDeep()
        .map('textSet') // get text set list
        .filter(textSet => textSet && textSet.textSetId) // remove undefined textSet or textSet has undefined textSet id
        .uniqBy('textSetId') // get unique textSet list by textSet id TODO: check unique along with version
        .map('approvalStatus') // get approval status list from textSet list
        .value()
      const countNG = {
        asset: this.countStatus(listAssetStt, '破棄'),
        text: this.countStatus(listTextsetStt, '破棄'),
      }
      const countTask = {
        asset: this.countStatus(listAssetStt, '要修正'),
        text: this.countStatus(listTextsetStt, '要修正'),
      }
      const countDone = {
        asset: this.countStatus(listAssetStt, '承認'),
        text: this.countStatus(listTextsetStt, '承認'),
      }
      const countWait = {
        asset: this.countStatus(listAssetStt, '監修未提出') + this.countStatus(listAssetStt, '監修中'),
        text: this.countStatus(listTextsetStt, '監修未提出') + this.countStatus(listTextsetStt, '監修中'),
      }
      this.approvalStatusDetail = {
        totalStatus: listAssetStt.length + listTextsetStt.length,
        totalTask: countTask,
        totalWait: countWait,
        totalDone: countDone,
        totalNG: countNG,
      }
    },
    countStatus(listStatus, status) {
      return _.filter(listStatus, x => x == status).length
    },
    // change status of list creative be checked in checkbox of each creative - change status on open dialog
    changeSelectedCreativesStatus(status) {
      this.selectedCreativeIds.map(creativeId => {
        this.changeStatusCreative(creativeId, status)
      })
    },
    // change status of creative's asset: it will trigger change of status of creative also
    changeStatusAsset(assetId, status) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          const creatives = _.map(r.creatives, r2 => {
            const labeledAssets = r2.labeledAssets.map(r3 => {
              const assets = r3.assets.map(r4 => {
                return r4.assetId !== assetId ? r4 : _.assign({}, r4, { approvalStatus: status })
              })
              return _.assign({}, r3, { assets })
            })
            return _.assign({}, r2, { labeledAssets })
          })
          return _.assign({}, r, { creatives })
        })
        .value()
      this.creativeSelected = _.chain(this.creativeSelected)
        .cloneDeep()
        .map(r => {
          const labeledAssets = r.labeledAssets.map(r2 => {
            const assets = r2.assets.map(r3 => {
              return r3.assetId !== assetId ? r3 : _.assign({}, r3, { approvalStatus: status })
            })
            return _.assign({}, r2, { assets })
          })
          return _.assign({}, r, { labeledAssets })
        })
        .value()
      // update the status of creative by changed asset status
      this.updateCreativeStatusByAsset(assetId)
    },
    // change status of creative's text: it will trigger change of status of creative also
    changeStatusText(textSetId, status, version) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          const creatives = _.map(r.creatives, r2 => {
            const isExactText = r2.textSet && r2.textSet.textSetId === textSetId && r2.textSet.version === version
            const text = isExactText ? _.assign({}, r2.textSet, { approvalStatus: status }) : r2.textSet
            return _.assign({}, r2, { textSet: text })
          })
          return _.assign({}, r, { creatives })
        })
        .value()
      this.creativeSelected = _.chain(this.creativeSelected)
        .cloneDeep()
        .map(r => {
          const isExactText = r.textSet && r.textSet.textSetId === textSetId && r.textSet.version === version
          const text = isExactText ? _.assign({}, r.textSet, { approvalStatus: status }) : r.textSet
          return _.assign({}, r, { textSet: text })
        })
        .value()
      // update the status of creative by changed text status
      this.updateCreativeStatusByText(textSetId, version)
    },
    // logic to update creative' status by text's status
    updateCreativeStatusByText(textSetId, version) {
      const creatives = _.chain(this.creativeSelected)
        .cloneDeep()
        .filter(x => x.textSet && x.textSet.textSetId === textSetId && x.textSet.version === version)
        .value()
      // update creative ' status will use all text + asset of the creative
      creatives.map(creative => {
        const assetStatus = _.chain(creative.labeledAssets)
          .cloneDeep()
          .map('assets')
          .flattenDeep()
          .map(asset => asset.approvalStatus)
          .value()
        const textStatus = creative.textSet.approvalStatus
        const listStatus = [...assetStatus, textStatus].filter(x => x.length != 0)
        //check status CR NG, SendSubmitRequest, Submitted
        if (
          creative.approvalStatus !== '入稿不可' &&
          creative.approvalStatus !== '入稿依頼済' &&
          creative.approvalStatus !== '入稿済'
        ) {
          if (
            !_.includes(
              _.map(listStatus, status => status == '承認'),
              false
            )
          ) {
            this.changeStatusCreative(creative.creativeId, '入稿可能')
          } else if (_.includes(listStatus, '破棄')) {
            this.changeStatusCreative(creative.creativeId, '入稿不可')
          } else if (_.includes(listStatus, '要修正')) {
            this.changeStatusCreative(creative.creativeId, '承認待ち')
          } else {
            this.changeStatusCreative(creative.creativeId, '承認待ち')
          }
        }
      })
    },
    // logic to update creative' status by asset's status
    updateCreativeStatusByAsset(assetId) {
      const creatives = _.chain(this.creativeSelected)
        .cloneDeep()
        .filter(x => {
          const listAsset = _.chain(x.labeledAssets).map('assets').flattenDeep().map('assetId').flattenDeep().value()
          return _.includes(listAsset, assetId)
        })
        .value()
      // update creative ' status will use all text + asset of the creative
      creatives.map(creative => {
        const assetStatus = _.chain(creative.labeledAssets)
          .cloneDeep()
          .map('assets')
          .flattenDeep()
          .map(asset => asset.approvalStatus)
          .value()
        const textStatus = creative.textSet ? creative.textSet.approvalStatus : ''
        const listStatus = [...assetStatus, textStatus].filter(x => x.length != 0)
        //check status CR NG, SendSubmitRequest, Submitted
        if (
          creative.approvalStatus !== '入稿不可' &&
          creative.approvalStatus !== '入稿依頼済' &&
          creative.approvalStatus !== '入稿済'
        ) {
          if (
            !_.includes(
              _.map(listStatus, status => status == '承認'),
              false
            )
          ) {
            this.changeStatusCreative(creative.creativeId, '入稿可能')
          } else if (_.includes(listStatus, '破棄')) {
            this.changeStatusCreative(creative.creativeId, '入稿不可')
          } else if (_.includes(listStatus, '要修正')) {
            this.changeStatusCreative(creative.creativeId, '承認待ち')
          } else {
            this.changeStatusCreative(creative.creativeId, '承認待ち')
          }
        }
      })
    },
    async submitCreatives(addCreatives, source) {
      if (addCreatives.length > 0) {
        const creativeIds = this.creativeSelected.map(x => x.creativeId)
        const addCreativesFilter = _.filter(addCreatives, creative => !_.includes(creativeIds, creative.creativeId))
        addCreativesFilter.forEach(creative => {
          this.creativeAddList.push(creative)
        })
        this.loading = true
        const addCreative = addCreativesFilter.flatMap(cr => {
          return cr.labeledAssets.map(labeledAsset => {
            return labeledAsset.assets.reduce(
              (total, asset) => (total === '' ? asset.assetId : total + '_' + asset.assetId),
              ''
            )
          })
        })
        const addCreativeIds = _.chain(addCreative).cloneDeep().omitBy(isEmpty).toArray().value()
        const assetGroups = addCreativeIds.length > 0 ? await this.getAssetGroupData(addCreativeIds) : []
        this.assetGroupData = addCreativeIds.reduce((total, current) => {
          const assetGroupIndex = assetGroups.findIndex(assetGroup => assetGroup.assetGroupId === current)
          total.push(
            assetGroupIndex !== -1
              ? assetGroups[assetGroupIndex]
              : {
                  assetGroupId: current,
                  creatorComment: '',
                  attachmentFile: [],
                  additionFile: [],
                }
          )
          return total
        }, _.cloneDeep(this.assetGroupData))
        const mappedAddedCreativesWithAssetGroups = addCreativesFilter.map(cr => {
          let approvalStatus = cr.approvalStatus
          // only change status if add creative from creative list
          if (source && source === this.SUBMIT_CREATIVE_SOURCE.LIST) {
            approvalStatus =
              cr.approvalStatus !== '入稿依頼済' && cr.approvalStatus !== '入稿済' ? '承認待ち' : cr.approvalStatus
          }
          return {
            ...cr,
            approvalStatus,
            labeledAssets: cr.labeledAssets.map(labeledAsset => {
              const assetGroupId = labeledAsset.assets.reduce(
                (total, asset) => (total === '' ? asset.assetId : total + '_' + asset.assetId),
                ''
              )
              const assetGroupIndex = assetGroups.findIndex(assetGroup => assetGroup.assetGroupId === assetGroupId)
              return {
                ...labeledAsset,
                assetGroup: assetGroupIndex !== -1 ? assetGroups[assetGroupIndex] : {},
              }
            }),
          }
        })
        const groupedAddCreatives = groupBy(mappedAddedCreativesWithAssetGroups, cr => cr.platformId)
        this.groupedCreatives = Object.keys(groupedAddCreatives).reduce((total, platformId) => {
          const currentGroupedCreativeIndex = total.findIndex(val => val.platformId === platformId)
          if (currentGroupedCreativeIndex !== -1) {
            const newCreatives = uniqBy(
              [...total[currentGroupedCreativeIndex].creatives, ...groupedAddCreatives[platformId]],
              cr => cr.creativeId
            )
            const newSelecteds = uniqBy(
              [...total[currentGroupedCreativeIndex].selected, ...groupedAddCreatives[platformId]],
              cr => cr.creativeId
            )
            const newGroupedCreative = {
              ...total[currentGroupedCreativeIndex],
              creatives: newCreatives,
              selected: newSelecteds,
            }
            total.splice(currentGroupedCreativeIndex, 1)
            total.push(newGroupedCreative)
          } else {
            const platform = this.adFormats.find(adFormat => adFormat.platformId === platformId)
            total.push({
              platformId: platformId,
              plaformName: platform.platformName,
              creatives: groupedAddCreatives[platformId],
              selected: groupedAddCreatives[platformId],
            })
          }
          return total
        }, this.groupedCreatives)
        this.creativeSelected = _.orderBy(
          [...this.creativeSelected, ...mappedAddedCreativesWithAssetGroups],
          'platformId'
        )
        this.filterCreatives()
        this.updateApprovalStatusDetail()
        this.loading = false
      }
    },
    async updateVerTextSet() {
      // update version for text
      const textSet = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map('creatives')
        .flattenDeep()
        .map('textSet')
        .omitBy(isUndefined)
        .toArray()
        .value()
      const bodies = _.chain(this.updateTextList)
        .cloneDeep()
        .map(textUpdate => {
          const latestTextSet = _.find(textSet, ts => ts.textSetId === textUpdate.textSetId && ts.isLatest)
          return _.assign({}, textUpdate, { creatorComment: latestTextSet.creatorComment })
        })
        .value()
      for (const body of bodies) {
        await this.$api.authFetch('/text_set', { method: 'PUT', body })
        // comments for ticket CV-8685
        // if (rs?.error === TEXT_EXISTED) {
        //   this.textChanges.push({
        //     oldTextId: body.textSetId,
        //     newText: _.assign({}, body, { textSetId: rs.data.textSetId }),
        //   })
        //   await this.$api.authFetch('/text_set', {
        //     method: 'PUT',
        //     body: _.assign({}, body, { textSetId: rs.data.textSetId }),
        //   })
        // }
      }
      const oldText = _.chain(this.groupedCreativesBase)
        .cloneDeep()
        .map('creatives')
        .flattenDeep()
        .map('textSet')
        .omitBy(isUndefined)
        .toArray()
        .value()
      const newText = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map('creatives')
        .flattenDeep()
        .map(creative => {
          const adFormatId = creative.adFormat.id
          return {
            ...creative.textSet,
            adFormatId: adFormatId,
          }
        })
        .value()
      // update only comment for textSet
      const updateOnlyComment = _.chain(newText)
        .filter(
          x =>
            !_.includes(
              this.updateTextList.map(x => x.textSetId),
              x.textSetId
            )
        )
        .filter(text => {
          const latestTextSet = _.find(oldText, ts => ts.textSetId === text.textSetId && ts.version === text.version)
          return !latestTextSet || latestTextSet.creatorComment !== text.creatorComment
        })
        .value()
      for (const text of updateOnlyComment) {
        const labeledTexts = _.map(text.labeledTexts, r2 => _.pick(r2, ['labelId', 'textValues']))
        const bodyText = {
          textSetId: _.result(text, 'textSetId') || '',
          adFormatId: _.result(text, 'adFormatId') || '',
          labeledTexts,
          projectName: _.result(text, 'projectName') || '',
          score: _.result(text, 'score') || 0,
          creatorComment: _.result(text, 'creatorComment') || '',
        }
        if (bodyText.textSetId !== '') {
          await this.$api.authFetch('/text_set', { method: 'PUT', body: bodyText })
        }
      }
    },
    // DON'T REMOVE ERROR RULE CHECKING
    // objects: assetRegulateError, assetErrorAssetIds
    async loadDataState() {
      window.ApprovalDetail = this
      const query = Object.assign({}, this.$route.query)
      this.approvalRequestId = query.approvalRequestId
      this.loading = true
      const approvalDetail = _.cloneDeep(this.approvalDetail)
      const notiDate =
        approvalDetail.notiDate && approvalDetail.dueDate
          ? this.diffDate(approvalDetail.dueDate, approvalDetail.notiDate)
          : null
      this.approvalDetailPayload = {
        priority: approvalDetail.priority || approvalDetail.priority === 0 ? approvalDetail.priority : null,
        dueDate: approvalDetail.dueDate ? approvalDetail.dueDate : null,
        notiDate: notiDate,
      }
      this.approvalDetail = []
      const creative = _.map(approvalDetail.creatives, x => {
        return _.assign({}, x, { platformId: x.platform.id, adFormatId: x.adFormat.id })
      })
      const creativeSelected = _.orderBy(this.modifySelectedData(creative, approvalDetail.assetGroups), 'platformId')
      const assetGroupDatas = _.cloneDeep(this.assetGroupStateData) // get data of the asset groups
      this.assetGroupData = assetGroupDatas // create assetGroupData
      this.approvalDetail = approvalDetail
      this.groupedCreatives = _.chain(this.approvalDetail.creatives)
        .groupBy('platform.id')
        .map(group => ({
          plaformName: _.head(group).platform.name,
          platformId: _.head(group).platform.id,
          creatives: _.map(group, o => _.omit(o, 'platform')),
          selected: group,
        }))
        .value()
      this.creativeSelected = creativeSelected
      this.creativeAddList = []
      this.updateTextList = []
      this.selectedCreativeIds = []
      this.assetObjects = [] // reset uploaded asset in ui
      this.updateApprovalStatusDetail()
      this.filterCreatives()
      this.isNewARDetailData()
      this.loading = false
    },
    async rollBackData() {
      const ret = await this.$confirm('変更内容がまだ保存されていません。キャンセルしますか？', ' キャンセルの確認', {
        confirmButtonText: 'OK',
        showCancelButton: false,
        type: 'warning',
      }).catch(e => e)
      if (ret === 'confirm') {
        await this.loadDataState().then(() => {
          this.$refs.uniqCreatives.reloadData()
        })
      } else {
        return
      }
    },
    async updateTextStatus() {
      const oldText = _.chain(this.groupedCreativesBase)
        .cloneDeep()
        .map('creatives')
        .flattenDeep()
        .map('textSet')
        .omitBy(isUndefined)
        .filter(t => t.isLatest)
        .toArray()
        .uniqBy(x => x.textSetId)
        .value()
      const newText = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map('creatives')
        .flattenDeep()
        .map('textSet')
        .omitBy(isUndefined)
        .filter(t => t.isLatest)
        .toArray()
        .uniqBy(x => x.textSetId)
        .value()
      const listUpdateStatus = _.chain(newText)
        // comments for ticket CV-8685
        // .map(t => {
        //   const textUpdate = this.textChanges.find(tu => t.oldTextId === tu.textSetId)
        //   if (textUpdate) {
        //     t.textSetId = textUpdate.newText.textSetId
        //   }
        //   return t
        // })
        .filter(text => {
          const textSet = _.find(oldText, x => x.textSetId === text.textSetId)
          return textSet ? textSet.approvalStatus != text.approvalStatus : true
        })
        .filter(x => x.textSetId)
        .value()
      if (listUpdateStatus.length > 0) {
        for (const text of listUpdateStatus) {
          const body = {
            targetType: 'textSet',
            targetIds: [{ targetId: text.textSetId }],
            approvalStatus: text.approvalStatus,
          }
          await this.$api.authFetch('/approval_status', { method: 'PUT', body })
        }
      }
    },
    async updateAssetStatus() {
      const oldAsset = _.chain(this.groupedCreativesBase)
        .cloneDeep()
        .map('creatives')
        .flattenDeep()
        .map('labeledAssets')
        .flattenDeep()
        .map('assets')
        .flattenDeep()
        .omitBy(isUndefined)
        .toArray()
        .uniqBy(x => x.assetId)
        .value()
      const newAsset = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map('creatives')
        .flattenDeep()
        .map('labeledAssets')
        .flattenDeep()
        .map('assets')
        .flattenDeep()
        .omitBy(isUndefined)
        .toArray()
        .uniqBy(x => x.assetId)
        .value()
      const listUpdateStatus = _.chain(newAsset)
        .filter(asset => {
          const assetFind = _.find(oldAsset, x => x.assetId == asset.assetId)
          return assetFind
            ? asset.approvalStatus != assetFind.approvalStatus && !_.includes(this.assetErrorAssetIds, asset.assetId)
            : true
        })
        .value()

      if (listUpdateStatus.length > 0) {
        for (const asset of listUpdateStatus) {
          const body = {
            targetType: 'asset',
            targetIds: [{ targetId: asset.assetId }],
            approvalStatus: asset.approvalStatus,
          }
          await this.$api.authFetch('/approval_status', { method: 'PUT', body })
        }
      }
    },
    async updateCreativeStatus() {
      const newCreative = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map('creatives')
        .flattenDeep()
        .uniqBy(x => x.creativeId)
        .value()
      if (newCreative.length > 0) {
        for (const newCreativeElement of newCreative) {
          const assets = newCreativeElement.labeledAssets.flatMap(x => x.assets)
          const isContain = _.some(
            assets.map(x => x.assetId),
            element => _.includes(this.assetErrorAssetIds, element)
          )
          if (!isContain) {
            const body = {
              targetType: 'creative',
              targetIds: [{ targetId: newCreativeElement.creativeId }],
              approvalStatus: this.convertApprovalStatusOptionsJapaneseToEnglish(newCreativeElement.approvalStatus),
            }
            await this.$api.authFetch('/approval_status', { method: 'PUT', body })
          }
        }
      }
    },
    changeCreativeCommentText(creatorComment, textSetId, platformId, version) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 => {
            if (!r2.textSet || r2.textSet.textSetId !== textSetId || r2.textSet.version !== version) return r2

            const textSet = _.assign({}, r2.textSet, { creatorComment })
            return _.assign({}, r2, { textSet })
          })
          return _.assign({}, r, { creatives })
        })
        .value()
      this.creativeSelected = _.chain(this.creativeSelected)
        .cloneDeep()
        .map(r2 => {
          if (!r2.textSet || r2.textSet.textSetId !== textSetId || r2.textSet.version !== version) return r2
          const textSet = _.assign({}, r2.textSet, { creatorComment })
          return _.assign({}, r2, { textSet })
        })
        .value()
    },
    async putAssetGroup() {
      this.loading = true
      const payload = this.preparePayload(this.assetGroupData)
      if (this.assetGroupAttachFiles.length > 0) {
        let formData = new FormData()
        this.assetGroupAttachFiles.forEach(file => formData.append('file', file))
        formData.append('payload', JSON.stringify(payload))

        await axios
          .request({
            method: 'post',
            url: `${this.$api.ctxDomain}/asset_group/upload`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .catch(err => {
            this.$message.error(err)
          })
      } else await this.submitAssetGroupComment()
    },
    async submitAssetGroupComment() {
      await this.$api.authFetch(`/asset_group`, {
        method: 'POST',
        body: this.assetGroupData,
      })
    },
    async reloadData() {
      window.ApprovalDetail = this
      const query = Object.assign({}, this.$route.query)
      this.approvalRequestId = query.approvalRequestId
      this.loading = true
      const promotionId = this.selectedPromotionIds[0] ? this.selectedPromotionIds[0] : query.promotionIds
      if (promotionId) {
        const approvalDetail = await this.getApprovalDetail(promotionId, this.approvalRequestId)
        const { approvalRequests } = await this.$api.authFetch('/approval_request/list', {
          method: 'POST',
          body: {
            promotionIds: [promotionId],
            targetType: 'creative',
          },
        })
        const creative = _.map(approvalDetail.creatives, x => {
          return _.assign({}, x, { platformId: x.platform.id, adFormatId: x.adFormat.id })
        })
        const creativeSelected = _.orderBy(this.modifySelectedData(creative, approvalDetail.assetGroups), 'platformId')
        const assetGroupIds = _.chain(creativeSelected)
          .map(creative => creative.labeledAssets)
          .flattenDeep()
          .map(assetGroup => this.getAssetGroupId(assetGroup)) // get ids
          .uniq() // uniq id
          .value()
        const assetGroupDatas = assetGroupIds.length > 0 ? await this.getAssetGroupData(assetGroupIds) : [] // get data of the asset groups

        this.assetGroupData = _.chain(assetGroupIds)
          .map(
            id =>
              _.find(assetGroupDatas, data => data.assetGroupId === id) || {
                assetGroupId: id,
                creatorComment: '',
                attachmentFile: [],
                additionFile: [],
              }
          )
          .value() // create assetGroupData
        this.approvalDetail = approvalDetail
        this.approvalListOptions = approvalRequests.map(approvalRequest => ({
          value: approvalRequest.approvalRequestId,
          label: approvalRequest.approvalRequestName,
        }))
        this.groupedCreatives = _.chain(this.approvalDetail.creatives)
          .groupBy('platform.id')
          .map(group => ({
            plaformName: _.head(group).platform.name,
            platformId: _.head(group).platform.id,
            creatives: _.map(group, o => _.omit(o, 'platform')),
            selected: group,
          }))
          .value()
        this.creativeSelected = creativeSelected
      }
      this.$emit('change-is-can-leave', true)
      this.loading = false
    },
    changeAssetGroupData(data) {
      if (_.isEmpty(data)) return
      this.assetGroupData = data
      this.creativeSelected = this.modifySelectedData(this.creativeSelected, data, this.assetGroupAttachFiles)
    },
    async createAssetGroup() {
      const assetGroupIds = _.chain(this.creativeSelected)
        .map(creative => creative.labeledAssets)
        .flattenDeep()
        .map(assetGroup => this.getAssetGroupId(assetGroup)) // get ids
        .uniq() // uniq id
        .value()
      const assetGroupDatas = assetGroupIds.length > 0 ? await this.getAssetGroupData(assetGroupIds) : [] // get data of the asset groups

      this.assetGroupData = _.chain(assetGroupIds)
        .map(
          id =>
            _.find(assetGroupDatas, data => data.assetGroupId === id) || {
              assetGroupId: id,
              creatorComment: '',
              attachmentFile: [],
              additionFile: [],
            }
        )
        .value() // create assetGroupData

      this.creativeSelected = this.modifySelectedData(this.creativeSelected, assetGroupDatas)
    },
    modifySelectedData(selected, assetGroupDatas, additionalFiles = []) {
      return _.chain(selected)
        .map(creative => {
          const labeledAssets = _.map(creative.labeledAssets, assets => {
            const assetGroupId = this.getAssetGroupId(assets)
            const assetGroupData = _.find(assetGroupDatas, data => data.assetGroupId === assetGroupId) || {
              assetGroupId: assetGroupId,
              creatorComment: '',
              attachmentFile: [],
              additionFile: [],
            }
            if (additionalFiles.length > 0) {
              const additionalFileUrls = _.chain(assetGroupData.additionFile)
                .map(fileName => {
                  const file = _.find(additionalFiles, file => file.name === fileName)
                  return file && URL.createObjectURL(file)
                })
                .compact()
                .value()
              assetGroupData.attachmentFile = _.concat(
                this.removeBlobUrl(assetGroupData.attachmentFile || []) || [],
                additionalFileUrls
              )
            } else {
              assetGroupData.attachmentFile = this.removeBlobUrl(assetGroupData.attachmentFile || [])
            } // convert additional files url
            assets.assetGroup = assetGroupData
            return assets
          })
          creative.labeledAssets = labeledAssets
          return creative
        })
        .value() // modify selected data
    },
    removeBlobUrl(urls) {
      return _.filter(urls, url => new URL(url).protocol !== 'blob:')
    },
    getAssetGroupId(assetGroup) {
      return _.chain(assetGroup.assets)
        .map(asset => asset.assetId)
        .join('_')
        .value()
    },
    changeAssetGroupAdditionAttachFile(payload, files) {
      if (_.isEmpty(payload)) return
      this.assetGroupAttachFiles = _.union(files, this.assetGroupAttachFiles || [])
      this.changeAssetGroupData(payload)
    },
    async getAssetGroupData(ids) {
      const res = await this.$api
        .authFetch('/asset_group/list', {
          method: 'POST',
          body: ids,
        })
        .catch(err => {
          this.$message.error(err)
        })
      this.assetGroupStateData = _.chain(this.assetGroupStateData)
        .cloneDeep()
        .concat(res)
        .uniqBy('assetGroupId')
        .value()
      const assetGroupResult = _.cloneDeep(res).map(assetG => {
        const creatorComment = assetG.creatorComment || ''
        const additionFile = assetG.additionFile || []
        return _.assign({}, assetG, { creatorComment: creatorComment }, { additionFile: additionFile })
      })
      return assetGroupResult
    },
    removeUnusedAddtionFile() {
      const attachmentFileNames = _.chain(this.assetGroupData)
        .map(assetGroup => assetGroup.additionFile)
        .flattenDeep()
        .uniq()
        .compact()
        .value()
      const compactAttachFiles = _.chain(this.assetGroupAttachFiles)
        .filter(file => _.includes(attachmentFileNames, file.name))
        .uniqBy('name')
        .value()
      this.assetGroupAttachFiles = compactAttachFiles
    },
    navigateToApprovalList() {
      const query = Object.assign({}, this.$route.query)
      delete query.approvalRequestId
      this.$router.push({ name: 'approval-list', query: query })
    },
    async changeApprovalDetailId(event) {
      const updateRouteAndLoadData = async () => {
        this.$refs.approvalSelect.blur()
        const query = { ...this.$route.query, approvalRequestId: event }
        this.$router.push({ path: '/approval-detail', query })
        await this.loadDataAR()
      }

      if (this.isCanLeave) {
        this.$refs.approvalSelect.blur()
        await updateRouteAndLoadData()
        return
      }

      // display popup confirm when have new data
      const message = '変更が確定されていません、画面を移動しますか？'
      const ret = await this.$confirm(message, '確認').catch(e => e)
      if (ret !== 'cancel') {
        await updateRouteAndLoadData()
      }
      this.$refs.approvalSelect.blur()
    },

    copy() {
      this.$copyText(this.buildSepBaseDomain).then(
        () => this.$message.success('コピーしました'),
        () => this.$message.error('コピーに失敗しました')
      )
    },
    changeApprovalDetailNotiDate($event) {
      this.approvalDetailPayload.notiDate = $event
      this.isNewARDetailData()
    },
    async getApprovalDetail(promotionId, approvalRequestId) {
      const data = await this.$api
        .authFetch(`/approval_request/detail?promotionId=${promotionId}&approvalRequestId=${approvalRequestId}`, {
          method: 'GET',
        })
        .catch(err => {
          this.$message.error(err)
        })
      const notiDate = data.notiDate && data.dueDate ? this.diffDate(data.dueDate, data.notiDate) : null
      this.approvalDetailPayload = {
        priority: data.priority || data.priority === 0 ? data.priority : null,
        dueDate: data.dueDate ? data.dueDate : null,
        notiDate: notiDate,
      }
      this.approvalDetailPayloadBase = {
        priority: data.priority || data.priority === 0 ? data.priority : null,
        dueDate: data.dueDate ? data.dueDate : null,
        notiDate: notiDate,
      }
      return data
    },
    changeApprovalRequestPriorty($event) {
      this.approvalDetailPayload.priority = $event
      this.isNewARDetailData()
    },
    formatDateTime(date) {
      return date ? moment(date).format('YYYY-MM-DDTHH:mm:ss.sssZ') : ''
    },
    diffDate(date1, date2) {
      return moment(date1).diff(moment(date2), 'days')
    },
    async openSubmissionRequestDialog() {
      const isSelectAll = _.isEqual(
        this.creativeSelected.map(creative => creative.creativeId).sort(),
        this.selectedCreativeIds.sort()
      )
      const isIncludesUnapproved = !_.chain(this.creativeSubmission)
        .map('approvalStatus')
        .uniq()
        .every(status => status == '入稿可能' || status === '入稿済' || status === '入稿依頼済')
        .value()
      const creativeIdsAdd = _.chain(this.creativeAddList).cloneDeep().map('creativeId').value()
      const isIncludesNewCreative = _.includes(
        _.chain(this.selectedCreativeIds)
          .cloneDeep()
          .map(creativeId => _.includes(creativeIdsAdd, creativeId))
          .value(),
        true
      )
      if (isIncludesNewCreative) {
        this.$alert(
          '新規追加のクリエイティブがあります。「提出修正」ボタンをクリックしてから再度入稿依頼作成してください。',
          'アラート'
        )
      } else if (isIncludesUnapproved) {
        this.$alert(
          '入稿可能になっていないクリエイティブがありますので、入稿依頼できません。\n' + 'ご確認お願いします。',
          'アラート'
        )
      } else if (!isSelectAll) {
        const ret = await this.$confirm(
          '選択されていないクリエイティブがあります。入稿依頼を作成しますか？',
          '入稿依頼作成の確認',
          {
            confirmButtonText: '作成',
            cancelButtonText: '戻る',
            type: 'warning',
          }
        ).catch(e => e)
        if (ret === 'cancel') {
          return
        } else {
          this.$refs.submissionRequestDialog.show()
        }
      } else {
        this.$refs.submissionRequestDialog.show()
      }
    },
    getAdFormatsFilter() {
      if (this.checkedPlatform.length === 0) return []

      const platforms = this.platformsData.filter(x => _.includes(this.checkedPlatform, x.platformName))
      if (this.checkedAdFormat.length === 0) return platforms.flatMap(o => o.adFormatNames)

      return platforms.flatMap(platform => {
        // Check if any adFormatName in the platform is in the checkedAdFormat
        const hasCheckedFormat = platform.adFormatNames.some(name => this.checkedAdFormat.includes(name))

        // Case 1: If the platform contains no checked ad formats, return all adFormatNames
        if (!hasCheckedFormat) {
          return platform.adFormatNames
        }

        // Case 2: If the platform contains some checked ad formats, return only those in checkedAdFormat
        return platform.adFormatNames.filter(name => this.checkedAdFormat.includes(name))
      })
    },

    formatNameByLength(name, length) {
      if (name.length > length) {
        return name.slice(0, length) + '...'
      }
      return name
    },

    isDisplayNameToolTipByLength(name, length) {
      return name.length > length
    },

    // filter creative list show in ui
    filterCreatives() {
      const adFormatFilters = this.getAdFormatsFilter()
      const statusCheck = this.checkedStatus.length != 0 ? this.checkedStatus : this.statusOptionFilter
      const newGroupedCreatives = this.groupedCreatives.map(gr => {
        const creatives = _.filter(
          gr.creatives,
          creative =>
            _.includes(statusCheck, creative.approvalStatus) &&
            (adFormatFilters.length === 0 || _.includes(adFormatFilters, creative.adFormat.name))
        )
        const selected = _.filter(
          gr.selected,
          creative =>
            _.includes(statusCheck, creative.approvalStatus) &&
            (adFormatFilters.length === 0 || _.includes(adFormatFilters, creative.adFormat.name))
        )
        return _.assign({}, gr, { creatives: creatives }, { selected: selected })
      })
      this.groupedCreativesFilter = _.filter(newGroupedCreatives, gr => gr.creatives.length > 0)
      this.creativeSelectedFilter = sortBy(
        _.filter(
          this.creativeSelected,
          creative =>
            _.includes(statusCheck, creative.approvalStatus) &&
            (adFormatFilters.length === 0 || _.includes(adFormatFilters, creative.adFormat.name))
        ),
        [c => c.platformId.trim().toLowerCase(), c => c.adFormat.name.trim().toLowerCase()]
      )
      const crIdFilter = this.groupedCreativesFilter.flatMap(gr => gr.creatives).map(cr => cr.creativeId)
      this.selectedCreativeIds = this.selectedCreativeIds.filter(id => _.includes(crIdFilter, id))
      this.isFilteringCr = false
      this.loading = false
    },

    filterStatus() {
      this.isFilteringCr = true
      this.showCrFilterStatus = false
      this.statusSaveFilter = this.checkedStatus
      setTimeout(() => this.filterCreatives(), 200) // delay filter process to mount new loading state before
    },
    filterAdFormat() {
      this.isFilteringCr = true
      this.showCrFilterAdFormat = false
      this.adFormatSaveFilter = this.checkedAdFormat
      this.platformSaveFilter = this.checkedPlatform
      setTimeout(() => this.filterCreatives(), 200) // delay filter process to mount new loading state before
    },

    async submitApprovalRequest() {
      const checkApprovalRequestBeforeSubmit = this.checkApprovalRequestBeforeSubmit()
      if (checkApprovalRequestBeforeSubmit) return

      this.submitARLoading = true
      const crRes = await this.updateDataCreative()
      if (crRes) {
        await this.updateCreativeStatus()
        await this.updateApprovalRequestData()

        this.groupedCreativesBase = this.groupedCreatives
        this.creativeSelectedBase = this.creativeSelected
        this.selectedCreativeIds = []
        const query = Object.assign({}, this.$route.query)
        const promotionId = this.selectedPromotionIds[0] ? this.selectedPromotionIds[0] : query.promotionIds
        const approvalDetail = await this.getApprovalDetail(promotionId, this.approvalRequestId)
        this.approvalDetail = approvalDetail
        this.$message.success('修正提出しました。')
      }
      if (this.assetRegulateError && this.assetRegulateError.length > 0) {
        this.$alert(
          'アップロードしようとしている画像/動画はすでに登録されているクリエイティブの規定に違反しています。 「詳細確認」ボタンを押して詳細をご確認ください。',
          'レギュレーション違反',
          {
            confirmButtonText: '閉じる',
            dangerouslyUseHTMLString: true,
          }
        )
      }
      this.submitARLoading = false
    },

    checkApprovalRequestBeforeSubmit() {
      const checkDueDate = this.formatDate(this.approvalDetailPayload.dueDate) >= this.formatDate(Date.now())
      if (!checkDueDate) {
        this.$message.error('対応期日は過去の日付に設定されています。本日または将来の日付を選択してください。')
        return true
      }
    },
    async updateDataCreative() {
      this.assetRegulateError = []
      this.assetErrorAssetIds = []
      const updateCreativeData = (labelAsset, assetsData, uploadAssets, updateAssetGroup, updateAssetGroupState) => {
        let newAssetGroupId = ''
        let check = false
        const assets = assetsData.map(r3 => {
          const replaceAssetIndex = uploadAssets.findIndex(asset => asset.originId === r3.originId)
          if (replaceAssetIndex !== -1) {
            check = true
            const replaceAsset = uploadAssets[replaceAssetIndex]
            newAssetGroupId =
              newAssetGroupId === '' ? replaceAsset.assetId : newAssetGroupId + '_' + replaceAsset.assetId
            return uploadAssets[replaceAssetIndex]
          } else {
            newAssetGroupId = newAssetGroupId === '' ? r3.assetId : newAssetGroupId + '_' + r3.assetId
            return r3
          }
        })
        if (check) {
          const findExistAssetGroup = this.assetGroupData.find(
            r => r.assetGroupId === labelAsset.assetGroup.assetGroupId
          )
          const findExistAssetGroupState = this.assetGroupStateData.find(
            r => r.assetGroupId === labelAsset.assetGroup.assetGroupId
          )
          const newAssetGroup = _.assign({}, findExistAssetGroup, { assetGroupId: newAssetGroupId })
          const newAssetGroupState = _.assign({}, findExistAssetGroupState, {
            assetGroupId: newAssetGroupId,
          })
          updateAssetGroup.push(newAssetGroup)
          updateAssetGroupState.push(newAssetGroupState)
          const assetGroup = _.assign({}, labelAsset.assetGroup, {
            assetGroupId: newAssetGroupId,
          })
          return {
            ...labelAsset,
            assetGroup,
            assets,
          }
        } else {
          return {
            ...labelAsset,
            assets,
          }
        }
      }
      const changeAssetGroup = _.chain(this.assetGroupData)
        .cloneDeep()
        .reduce((total, assetGroup) => {
          const checkAssetGroup = this.assetGroupStateData.find(
            assetGroupState => assetGroupState.assetGroupId === assetGroup.assetGroupId
          )
          const newAssetGroup = {
            ...assetGroup,
            attachmentFile: assetGroup.attachmentFile ? assetGroup.attachmentFile : [],
          }
          if (checkAssetGroup) {
            const oldAssetGroup = {
              ...checkAssetGroup,
              attachmentFile: checkAssetGroup.attachmentFile ? checkAssetGroup.attachmentFile : [],
            }
            if (newAssetGroup.creatorComment !== oldAssetGroup.creatorComment) {
              //check cr comment
              total.push(newAssetGroup)
            }
            if (newAssetGroup.attachmentFile.length !== oldAssetGroup.attachmentFile.length) {
              //check change in attachment file
              total.push(newAssetGroup)
            }
          }
          return total
        }, [])
        .value()
      if (this.assetObjects.length > 0) {
        const form = new FormData()
        const originIdChange = _.chain(this.assetObjects).flatMap('originId').value()
        const assets = _.chain(this.creativeSelected)
          .flatMap('labeledAssets')
          .flatMap('assets')
          .uniqBy('originId')
          .filter(asset => originIdChange.includes(asset.originId))
          .value()
        const wantedStatuses = assets.map(asset => ({
          originId: asset.originId,
          status: this.convertApprovalStatusOptionsJapaneseToEnglish(asset.approvalStatus),
        }))
        form.append(
          'payload',
          JSON.stringify({
            promotionId: Number(this.selectedPromotionIds[0]),
            projectName: '',
            wantedStatuses: wantedStatuses,
          })
        )
        _.map(this.assetObjects, r => {
          form.append('file', r.file)
        })
        const result = await axios.request({
          method: 'post',
          url: `${this.$api.ctxDomain}/asset/upload`,
          data: form,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        const data = result.data.filter(x => !(x.errors && x.errors.length !== 0)).flatMap(v => v.info)
        const regulateAssetError = result.data.filter(x => x.errors && x.errors.length !== 0)
        this.assetRegulateError = regulateAssetError
        this.assetErrorAssetIds = regulateAssetError.map(x => x.info.assetId)

        const isAllAssetsFailed = regulateAssetError.length === this.assetObjects.length
        if (isAllAssetsFailed) {
          // reload approval request data in the interface like the first time accessing the page
          await this.loadDataState()
          this.$refs.uniqCreatives.reloadData()

          return false
        }

        if (data) {
          let updateAssetGroup = []
          const newCreativeData = _.chain(this.creativeSelected)
            .cloneDeep()
            .map(r => {
              const labeledAssets = r.labeledAssets.map(r2 => {
                return updateCreativeData(r2, r2.assets, data, updateAssetGroup, changeAssetGroup)
              })
              return _.assign({}, r, { labeledAssets })
            })
            .value()
          await this.updateAssetGroupData(_.uniqBy([...updateAssetGroup, ...changeAssetGroup], 'assetGroupId'))

          this.creativeSelected = newCreativeData
          this.groupedCreatives = _.chain(this.groupedCreatives)
            .cloneDeep()
            .map(r => {
              return {
                ...r,
                creatives: r.creatives.map(creative =>
                  newCreativeData.find(cr => cr.creativeId === creative.creativeId)
                ),
              }
            })
            .value()
        }
        this.assetObjects = []
      } else {
        const updateAssetGroup = this.assetGroupData.filter(r => r.additionFile && r.additionFile.length > 0)
        await this.updateAssetGroupData(_.uniqBy([...updateAssetGroup, ...changeAssetGroup], 'assetGroupId'))
      }
      await this.updateVerTextSet()
      const updateCreative = await this.updateCreativeData()
      if (updateCreative) {
        return true
      }
      await this.updateTextStatus()
      await this.updateAssetStatus()

      // reset child component's data after update
      this.$refs.uniqCreatives.reloadData()

      return true
    },
    async updateAssetGroupData(updateAssetGroup) {
      if (updateAssetGroup.length > 0) {
        let res = {}
        const data = updateAssetGroup.map(ag => {
          return {
            assetGroupId: ag.assetGroupId,
            attachmentFile: ag.attachmentFile
              ? ag.attachmentFile.filter(assetUrl => new URL(assetUrl).protocol !== 'blob:')
              : [],
            additionFile: ag.additionFile,
            creatorComment: ag.creatorComment,
          }
        })
        if (this.assetGroupAttachFiles.length > 0) {
          const assetGroupForm = new FormData()
          this.assetGroupAttachFiles.forEach(file => assetGroupForm.append('file', file))
          assetGroupForm.append('payload', JSON.stringify(data))
          res = await axios
            .request({
              method: 'post',
              url: `${this.$api.ctxDomain}/asset_group/upload`,
              data: assetGroupForm,
              headers: { 'Content-Type': 'multipart/form-data' },
            })
            .catch(err => {
              this.$message.error(err)
            })
        } else {
          res = await this.$api
            .authFetch(`/asset_group`, {
              method: 'POST',
              body: data,
            })
            .catch(err => {
              this.$message.error(err)
            })
        }
        if (res) {
          const updatedAgResult = res.data || res
          this.assetGroupData = this.updateAgDataWithNewAgData(this.assetGroupData, updatedAgResult)
          this.assetGroupStateData = this.updateAgDataWithNewAgData(this.assetGroupStateData, updatedAgResult)
          this.assetGroupAttachFiles = []
          return data
        }
        return null
      }
      return null
    },
    updateAgDataWithNewAgData(currentAgData, updatedAgData) {
      updatedAgData.map(updatedAg => {
        const agIndex = currentAgData.findIndex(checkingAg => checkingAg.assetGroupId === updatedAg.assetGroupId)
        if (agIndex === -1) {
          currentAgData.push(updatedAg)
        } else {
          currentAgData.splice(agIndex, 1, updatedAg)
        }
      })

      return currentAgData
    },
    async updateApprovalRequestData() {
      let body = {}
      for (const key in this.approvalDetailPayload) {
        switch (key) {
          case 'priority':
            if (
              this.approvalDetailPayload[key] !== null &&
              this.approvalDetailPayload[key] !== this.approvalDetail[key]
            ) {
              body[key] = this.approvalDetailPayload[key]
            } else if (this.approvalDetail[key] !== null) {
              body[key] = this.approvalDetail[key]
            }
            break
          case 'dueDate':
            if (
              this.approvalDetailPayload[key] !== null &&
              this.formatDate(this.approvalDetailPayload[key]) !== this.formatDate(this.approvalDetail[key])
            ) {
              body[key] = this.formatDateTime(this.approvalDetailPayload[key])
            } else if (this.approvalDetail[key] !== null) {
              body[key] = this.approvalDetail[key]
            }
            break
          case 'notiDate':
            body[key] =
              (!this.approvalDetailPayload[key] && this.approvalDetailPayload[key] !== 0) ||
              this.approvalDetailPayload[key] === ''
                ? -1
                : this.approvalDetailPayload[key]
            break
          default:
            break
        }
      }
      if (!isEmpty(body)) {
        // await MessageBox.alert("Update approval detail data", {
        //   confirmButtonText: "OK",
        //   callback: async () => {
        //
        //     if(!res.error){
        //       this.$message.success("Update approval detail success ", res)
        //     }else{
        //       this.$message.error("Update approval detail error ", res)
        //     }
        //   },
        // });
        const newBody = this.isCreativeIdChange()
          ? _.assign({}, body, {
              creatives: this.creativeSelected.map(creative => creative.creativeId),
            })
          : body
        const res = await this.$api.authFetch('/approval_request', {
          method: 'PUT',
          body: {
            id: this.approvalRequestId,
            promotionId: this.selectedPromotionIds[0],
            ownerEmail: this.approvalDetail.ownerEmail,
            name: this.approvalDetail.approvalRequestName,
            event: 'UPDATE_AR',
            approvalCategory: 'Official',
            ccUser: this.approvalDetail.ccUser ? this.approvalDetail.ccUser : '',
            ...newBody,
          },
        })
        if (res.error) {
          this.$message.success('Update approval detail error ', res)
        }
      }
    },
    async updateCreativeData() {
      // comments for ticket CV-8685
      // let creatives = this.creativeSelected
      // // change the text Set associated with creative
      // if (this.textChanges.length > 0) {
      //   const setNewTextSetToCR = cr => {
      //     const isTextIdIncluded = _.includes(
      //       this.textChanges.map(t => t.oldTextId),
      //       cr.textSet.textSetId
      //     )
      //     const isStatusExcluded = !_.includes(CreativeStatus.NO_EDIT_CR, cr.approvalStatus)
      //     const newText = this.textChanges.find(text => text.oldTextId === cr.textSet.textSetId)
      //
      //     if (isTextIdIncluded && isStatusExcluded && newText) {
      //       cr.textSet.textSetId = newText.newText.textSetId
      //     }
      //     return cr
      //   }
      //   creatives = this.creativeSelected.map(setNewTextSetToCR)
      // }

      // filter creative not change referenceUrl or creatorComment or have change TextSet
      const creativeChange = this.creativeSelected.filter(cr => {
        const creativeBase = this.creativeSelectedBase.find(crb => crb.creativeId === cr.creativeId)
        if (creativeBase !== undefined) {
          const isCommentChange = creativeBase.creatorComment !== cr.creatorComment
          const isNoteChanged = creativeBase.note !== cr.note
          const baseUrl = creativeBase.referenceUrls.toString()
          const newUrl = cr.referenceUrls
            .filter(url => url.trim() !== '') // remove elements ''
            .map(url => url.replace(/\n+$/, '')) // remove \n end of reference
            .toString() // toString co compare
          const isReferenceUrlChange = baseUrl !== newUrl
          return (
            isReferenceUrlChange ||
            isCommentChange ||
            // || this.textChanges.length > 0  // comments for ticket CV-8685
            isNoteChanged
          )
        } else return true
      })

      if (creativeChange.length !== 0) {
        const body = _.map(creativeChange, creative => {
          const labeledAssets = _.map(creative.labeledAssets, r2 => ({
            labelId: r2.labelId,
            originIds: _.map(r2.assets, 'originId'),
          }))
          const referenceUrls = _.result(creative, 'referenceUrls')
            .filter(url => url.trim() !== '')
            .map(url => url.replace(/\n+$/, ''))
          const creatorComment = _.result(creative, 'creatorComment') || null
          const creativeBody = {
            creativeId: _.result(creative, 'creativeId') || '',
            creativeType: _.result(creative, 'creativeType') || '',
            adFormatId: _.result(creative, 'adFormat.id') || '',
            textSetId: _.result(creative, 'textSet.textSetId') || '',
            labeledAssets,
            score: _.result(creative, 'score') || null,
            deviationScore: _.result(creative, 'deviationScore') || null,
            deviationRank: _.result(creative, 'deviationRank') || null,
            note: _.result(creative, 'note'),
            creatorComment: creative.submissionStatus === '入稿済' ? creatorComment + 'submitted' : creatorComment,
            referenceUrls: referenceUrls,
          }
          return creativeBody
        })
        const ret = await this.$api.authFetch('/creative', {
          method: 'PUT',
          body: { promotionId: this.selectedPromotionIds[0], creatives: body },
        })
        if (ret && ret.errors) {
          this.$message.error('コメント登録失敗')
        }
        return false
      }
      return false
    },
  },
  computed: {
    priorityColor() {
      const data = find(this.priorityOptions, priority => {
        return priority.value === this.approvalDetailPayload.priority
      })
      return data ? data.color : ''
    },
    isValidReference() {
      const listReferenceUrl = _.chain(this.creativeSelected).flatMap('referenceUrls').uniq().value()
      return util.methods.isValidReferenceUrls.call(this, listReferenceUrl)
    },
    isCreativeHaveNG() {
      const creativeSelected = _.cloneDeep(this.creativeSelected).filter(creative =>
        _.includes(this.selectedCreativeIds, creative.creativeId)
      )
      const assetStatus = _.compact(
        _.uniq(
          creativeSelected
            .flatMap(creative => creative.labeledAssets)
            .flatMap(labelAsset => labelAsset.assets)
            .map(asset => asset.approvalStatus)
        )
      )
      const textStatus = _.compact(
        _.uniq(creativeSelected.map(x => (x.textSet ? x.textSet : {})).map(text => text.approvalStatus))
      )
      return _.includes(assetStatus, '破棄') || _.includes(textStatus, '破棄')
    },
    isDisableChangeCreativeStatus() {
      let isDisable = false
      const SEND_REQUEST_SUBMIT_STATUS = '入稿依頼済'
      const SUBMITED_STATUS = '入稿済'

      // check creative has a status sendToSubmit or submited
      let creativeSelected = _.cloneDeep(this.creativeSelected).filter(creative =>
        _.includes(this.selectedCreativeIds, creative.creativeId)
      )
      let creativeSelectedStatus = _.compact(_.uniq(creativeSelected.map(creative => creative.approvalStatus)))
      if (
        _.includes(creativeSelectedStatus, SEND_REQUEST_SUBMIT_STATUS) ||
        _.includes(creativeSelectedStatus, SUBMITED_STATUS)
      ) {
        isDisable = true
      }

      return isDisable
    },
    buildSepBaseDomain() {
      const domain = this.$api.getSepBaseDomain()
      return domain ? `${domain}/${this.selectedPromotionIds[0]}/approval-requests/${this.approvalRequestId}` : ''
    },
    dueDateChange() {
      return this.approvalDetailPayload.dueDate
    },

    // enableSetNotiDate() {
    //   if(this.approvalDetailPayload?.dueDate === this.approvalDetail?.dueDate){
    //     return this.approvalDetail?.dueDate !== null
    //   } else {
    //     return this.approvalDetailPayload?.dueDate !== null
    //   }
    // }
  },
  watch: {
    checkedPlatform() {
      const adFormatOption = this.platformsData
        .filter(platform => this.checkedPlatform.includes(platform.platformName)) // Filter by platformName
        .flatMap(platform => platform.adFormatNames) // Flatten the adFormatNames into a single array
      if (this.checkedPlatform.length > 0) {
        this.adFormatOptionFilter = adFormatOption
      } else {
        this.adFormatOptionFilter = []
      }
      this.checkedAdFormat = this.checkedAdFormat.filter(ad => _.includes(adFormatOption, ad))
    },

    showCrFilterStatus() {
      if (this.showCrFilterStatus) {
        if (!_.isEqual(this.statusSaveFilter.toString(), this.checkedStatus.toString())) {
          this.checkedStatus = this.statusSaveFilter
        }
      }
    },

    showCrFilterAdFormat() {
      if (this.showCrFilterAdFormat) {
        if (!_.isEqual(this.adFormatSaveFilter.toString(), this.checkedAdFormat.toString())) {
          this.checkedAdFormat = this.adFormatSaveFilter
        }
        if (!_.isEqual(this.platformSaveFilter.toString(), this.checkedPlatform.toString())) {
          this.checkedPlatform = this.platformSaveFilter
        }
      }
    },

    isNewDataCreative() {
      const ableToLeave = !(this.isNewDataCreative || this.isNewApprovalDetailData)
      this.$emit('change-is-can-leave', ableToLeave)
    },

    isNewApprovalDetailData() {
      const ableToLeave = !(this.isNewDataCreative || this.isNewApprovalDetailData)
      this.$emit('change-is-can-leave', ableToLeave)
    },
    // checkedAdFormat() {
    //   this.filterCreatives()
    // },

    selectedCreativeIds() {
      const newGroupedCreatives = this.groupedCreativesBase.map(gr => {
        const creatives = _.filter(gr.creatives, creative => _.includes(this.selectedCreativeIds, creative.creativeId))
        const selected = _.filter(gr.selected, creative => _.includes(this.selectedCreativeIds, creative.creativeId))
        return _.assign({}, gr, { creatives: creatives }, { selected: selected })
      })
      this.groupedCreativesSubmission = _.filter(newGroupedCreatives, gr => gr.selected.length > 0)
      this.creativeSubmission = _.filter(this.creativeSelectedBase, creative =>
        _.includes(this.selectedCreativeIds, creative.creativeId)
      )
    },
    creativeSelected() {
      this.updateApprovalStatusDetail()
      this.filterCreatives()
      this.isNewCreativeData(this.creativeSelectedBase, this.creativeSelected)
    },

    async selectedPromotionIds(oldVal, newVal) {
      if (oldVal[0] !== newVal[0] && newVal.length !== 0) {
        const query = Object.assign({}, this.$route.query)
        delete query.approvalRequestId
        this.$router.push({ path: '/approval-list', query: _.omit(query, ['redirect']) })
      }
    },
    dueDateChange(newVal) {
      if (newVal) {
        this.datePickerNotiDateOptions = {
          ...this.datePickerNotiDateOptions,
          disabledDate(time) {
            return time.getTime() < Date.now() || time.getTime() > newVal
          },
        }
      }
    },

    approvalDetail() {
      this.groupedCreatives = _.chain(this.approvalDetail.creatives)
        .groupBy('platform.id')
        .map(group => ({
          plaformName: _.head(group).platform.name,
          platformId: _.head(group).platform.id,
          creatives: _.map(group, o => _.omit(o, 'platform')),
          selected: group,
        }))
        .sortBy(gr => gr.plaformName)
        .value()
      const creative = _.map(this.approvalDetail.creatives, x => {
        return _.assign({}, x, { platformId: x.platform.id, adFormatId: x.adFormat.id })
      })
      this.creativeSelected = _.orderBy(
        this.modifySelectedData(creative, this.approvalDetail.assetGroups),
        'platformId'
      ).map(r => {
        const referenceUrl = r.referenceUrls.length !== 0 ? r.referenceUrls : ['']
        const creatorComment = r.creatorComment !== null ? r.creatorComment : ''
        const labeledAssets = r.labeledAssets.map(labelAsset => {
          const assetGroup = labelAsset.assetGroup.creatorComment
            ? _.assign({}, labelAsset.assetGroup, { additionFile: [] })
            : _.assign({}, labelAsset.assetGroup, { creatorComment: '' }, { additionFile: [] })
          return _.assign({}, labelAsset, { assetGroup: assetGroup }, { isLabelDeleted: false })
        })
        return _.assign(
          {},
          r,
          { referenceUrls: referenceUrl },
          { creatorComment: creatorComment },
          { labeledAssets: labeledAssets }
        )
      })
      this.groupedCreativesBase = _.cloneDeep(this.groupedCreatives)
      this.creativeSelectedBase = _.cloneDeep(this.creativeSelected)
    },
  },
}
</script>
