var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"id":"item-dialog","title":"プレビュー","visible":_vm.isShow,"close-on-press-escape":false,"append-to-body":""},on:{"update:visible":function($event){_vm.isShow=$event}}},[_c('div',{staticClass:"vertical-center",style:({ height: _vm.height + 'px' })},[_c('el-image',{style:({
        height: '94%',
        maxHeight: _vm.height + 'px',
        maxWidth: _vm.width + 'px',
        margin: 'auto',
        paddingLeft: '2%',
        paddingRight: '2%',
      }),attrs:{"src":_vm.url}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.isShow = false}}},[_vm._v(" 閉じる ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }