<template>
  <div
    v-if="!isDisabled"
    :class="isError ? 'text-editor errorInput' : 'text-editor'"
    ref="inputRef"
    :contenteditable="true"
    @input="handleChange"
    @keydown="handleKeyDown"
    @compositionstart="handleCompositionStart"
    @compositionend="handleCompositionEnd"
  ></div>
  <div
    v-else
    @click="openLinkUrl"
    :class="haveUrl ? 'text-editor cursor-pointer' : 'text-editor'"
    ref="inputRef"
    :contenteditable="false"
    @input="handleChange"
    @keydown="handleKeyDown"
  ></div>
</template>

<style>
.text-editor {
  line-height: 22px;
  width: 91%;
  border: 1px solid #dcdfe6;
  height: auto; /* Adjusted to allow auto-height */
  max-height: 70px; /* Maximum height for the text editor */
  border-radius: 5px;
  padding: 0 15px;
  align-content: center;
  outline: none;
  font-size: 13px;
  overflow-y: auto;
  word-break: break-all;
}

.errorInput {
  border: 1px solid red;
}

.cursor-pointer {
  cursor: pointer;
}

div.text-editor {
  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: #cecac2;
    border-radius: 5px;
    border: 2px solid #ccc;
  }
  &::-webkit-scrollbar {
    width: 5px;
    display: block;
    height: 5px;
  }
}
</style>
<script>
import DOMPurify from 'dompurify'
import util from '@/mixins/util'
import { NUMBER_OF_URL_VALID } from '@/mixins/constant'

export default {
  name: 'TextAreaInput',
  mixins: [util],
  props: {
    className: { type: String, default: () => undefined },
    valueInput: { type: String, default: () => '' },
    placeholder: { type: String, default: () => undefined },
    isDisabled: { type: Boolean, default: () => false },
  },
  data() {
    return {
      NEW_LINE_REGEX: /(\r\n|\n|\r)/g,
      raw: '',
      haveUrl: false,
      isComposing: false,
      isError: false,
      numberOfUrlValid: 1,
    }
  },
  mounted() {
    this.raw = this.valueInput
    const inputElm = this.$refs.inputRef
    inputElm.innerHTML = this.convertToHTML(this.valueInput)
    this.addEventClickForHyperlink()
    const urls = this.valueInput.match(this.urlRegForReference)
    this.isError = urls?.length > NUMBER_OF_URL_VALID
    if (urls) {
      this.haveUrl = true
    }
  },
  methods: {
    handleChange() {
      if (this.isComposing) return
      const targetElem = this.$refs.inputRef
      if (targetElem) {
        const textInput = targetElem.innerText
        this.raw = textInput
        this.setUI(textInput)
        this.addEventClickForHyperlink()
        this.$emit('on-change', textInput)
      }
    },
    sanitizeHtml(html) {
      return DOMPurify.sanitize(html)
    },
    addEventClickForHyperlink() {
      setTimeout(() => {
        const targetElem = this.$refs.inputRef
        if (targetElem) {
          for (let i = 0; i <= targetElem.children.length; i++) {
            const node = targetElem.children[i]
            const isATag = node?.nodeName?.toLowerCase() === 'a'
            if (isATag) {
              const aTag = node
              const url = aTag.textContent ?? ''
              aTag.addEventListener('click', () => window.open(url, '_blank', 'noopener,noreferrer'))
            }
          }
        }
      }, 200)
    },
    convertToHTML(value) {
      const html = this.sanitizeHtml(value) // filter xss of comment
        .replace(
          this.urlRegForReference,
          '<a style="cursor: pointer; text-decoration: underline blue; color: blue">$1</a>'
        )
        .replace(this.NEW_LINE_REGEX, '<br>')

      const isBrAtEnd =
        html.length >= 4 &&
        html.charAt(html.length - 1) === '>' &&
        html.charAt(html.length - 2) === 'r' &&
        html.charAt(html.length - 3) === 'b'
      if (isBrAtEnd) {
        return html
      } else {
        return html + '<br>' // Add extra <br> to prevent double enter when make new line
      }
    },
    setUI(raw) {
      const selection = window.getSelection()
      const inputElm = this.$refs.inputRef

      if (selection && inputElm && selection.rangeCount) {
        // Case: typing on UI
        selection.extend(inputElm, 0)
        let pos = selection.toString().length
        inputElm.innerHTML = this.convertToHTML(raw)
        while (pos-- > 0) {
          selection.modify('move', 'forward', 'character')
        }
      } else if (inputElm) {
        // Case: load old data on update
        inputElm.innerHTML = this.convertToHTML(raw)
      }
    },
    openLinkUrl() {
      const urls = this.valueInput.match(this.urlRegForReference)
      if (urls) {
        //get the first url in the list
        const url = urls[0]
        window.open(url, '_blank', 'noopener,noreferrer')
      }
    },
    handleKeyDown(e) {
      if (e.key === 'Enter' && !this.isComposing) {
        e.stopPropagation()
        e.preventDefault()

        const inputElm = this.$refs.inputRef
        const selection = window.getSelection()

        if (inputElm && selection && selection.focusNode) {
          const brNode = document.createElement('br')
          const range = document.createRange()
          range.setStart(selection.focusNode, selection.focusOffset)
          range.insertNode(brNode)

          if (selection.focusNode !== inputElm) {
            selection.modify('move', 'forward', 'character')
          }
        }
      }
    },
    handleCompositionStart() {
      this.isComposing = true
    },
    handleCompositionEnd() {
      this.isComposing = false
      this.handleChange() // Ensure the final composed character is registered
    },
  },
  watch: {
    valueInput() {
      this.raw = this.valueInput
      this.setUI(this.valueInput)
      const urls = this.valueInput.match(this.urlRegForReference)
      this.isError = urls?.length > NUMBER_OF_URL_VALID
      this.addEventClickForHyperlink()
    },
  },
}
</script>
