<template>
  <el-dialog id="item-dialog" title="プレビュー" :visible.sync="isShow" :close-on-press-escape="false" append-to-body>
    <div class="vertical-center" :style="{ height: height + 'px' }">
      <el-image
        class=""
        :src="url"
        :style="{
          height: '94%',
          maxHeight: height + 'px',
          maxWidth: width + 'px',
          margin: 'auto',
          paddingLeft: '2%',
          paddingRight: '2%',
        }"
      />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="isShow = false" type="primary"> 閉じる </el-button>
    </span>
  </el-dialog>
</template>

<style>
#item-dialog .el-dialog__body {
  font-size: 0px;
}

#item-dialog .el-dialog {
  height: 84vh;
  width: 84vw;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  margin-top: 0 !important;
  display: flex;
  flex-direction: column;
}

#item-dialog .el-dialog__body {
  flex: 1;
}

#item-dialog .el-dialog__body img {
  object-fit: contain;
}

#item-dialog .el-dialog__footer {
  position: static;
}
</style>

<style scoped>
#item-dialog {
  overflow: initial !important;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.vertical-center {
  align-items: center;
  display: flex;
}
</style>

<script>
export default {
  name: 'item-dialog',
  props: {
    url: { type: String, default: () => '' },
  },
  data: () => ({ isShow: false, aspectRatio: 0, innerHeight: window.innerHeight }),
  created() {
    window.ItemDialog = this
  },
  methods: {
    show(aspectRatio) {
      this.aspectRatio = aspectRatio
      this.isShow = true
    },
  },
  computed: {
    height() {
      return this.innerHeight * 0.84 - 45 - 60
    },
    width() {
      return (this.innerHeight * 0.84 - 45 - 60) * this.aspectRatio
    },
  },
}
</script>
