<template>
  <div>
    <div v-if="this.parentComponent === 'creative'">
      <div id="image-movie-item-cr-list">
        <el-image
          v-if="type === 'Zip'"
          ref="item"
          class="asset-size-cr-list"
          :src="require(`@/api/data/imageMovieMaster/zip.jpg`)"
        />

        <img
          v-else-if="type === 'Image'"
          :id="id"
          ref="item"
          class="asset-size-cr-list"
          :src="url"
          @click="openItemDialog"
        />

        <div v-else-if="type === 'Video'" ref="item" class="w-100 video-wrap">
          <video class="asset-size-cr-list" controls controlslist="nodownload" preload="metadata" :src="url" />
          <div class="video-btn" />
        </div>

        <div v-else-if="type === 'Music'" ref="item" class="w-100 video-wrap">
          <video
            class="asset-size-cr-list"
            controls
            controlslist="nodownload"
            :poster="require(`@/assets/audio.png`)"
            preload="metadata"
            :src="url"
            style="width: 80%; padding-top: 30px"
          />
          <div class="video-btn" />
        </div>

        <iframe
          v-else-if="type === 'Url'"
          :class="isMask ? 'asset-size-cr-list isDisableYoutube' : 'asset-size-cr-list'"
          :src="url"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />

        <span v-else class="w-100 h-100"> 対応していないタイプです。 </span>

        <item-dialog ref="itemDialog" :url="url" />
      </div>
    </div>

    <div v-if="this.parentComponent === 'creative-regist'" class="wh-crReg">
      <el-image v-if="type === 'Zip'" ref="item" class="fit" :src="require(`@/api/data/imageMovieMaster/zip.jpg`)" />

      <img v-else-if="type === 'Image'" :id="id" ref="item" class="wh-crReg fit" :src="url" @click="openItemDialog" />

      <div v-else-if="type === 'Video'" ref="item" class="wh-crReg video-wrap">
        <video class="fit-video" controls controlslist="nodownload" preload="metadata" :src="url" @click.prevent />
      </div>

      <div v-else-if="type === 'Music'" ref="item" class="wh-crReg video-wrap">
        <video
          class="fit-video"
          controls
          controlslist="nodownload"
          :poster="require(`@/assets/audio.png`)"
          preload="metadata"
          :src="url"
          @click.prevent
          style="min-height: 100% !important"
        />
      </div>

      <div v-else-if="type === 'Url'">
        <iframe
          :class="isMask ? 'w-100 isDisableYoutube' : 'w-100'"
          :src="url + '?controls=0'"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>

      <span v-else class="w-100 h-100"> 対応していないタイプです。 </span>

      <item-dialog ref="itemDialog" :url="url" />
    </div>

    <div v-if="this.parentComponent === 'wallhitting'">
      <div id="image-movie-item">
        <el-image
          v-if="type === 'Zip'"
          ref="item"
          class="asset-size"
          :src="require(`@/api/data/imageMovieMaster/zip.jpg`)"
        />

        <img v-else-if="type === 'Image'" :id="id" ref="item" class="asset-size" :src="url" @click="openItemDialog" />

        <div v-else-if="type === 'Video'" ref="item" class="w-100 video-wrap">
          <video class="asset-size" controls controlslist="nodownload" preload="metadata" :src="url" />
          <div class="video-btn" />
        </div>

        <div v-else-if="type === 'Music'" ref="item" class="w-100 video-wrap">
          <video
            class="asset-size"
            controls
            controlslist="nodownload"
            :poster="require(`@/assets/audio.png`)"
            preload="metadata"
            :src="url"
          />
          <div class="video-btn" />
        </div>

        <div v-else-if="type === 'Url'">
          <iframe
            :class="isMask ? 'asset-size isDisableYoutube' : 'asset-size'"
            :src="url + '?controls=0'"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>

        <span v-else class="w-100 h-100"> 対応していないタイプです。 </span>

        <item-dialog ref="itemDialog" :url="url" />
      </div>
    </div>
    <div v-if="this.parentComponent === 'wallhitting-upload'">
      <div id="image-movie-item">
        <el-image
          v-if="type === 'Zip'"
          ref="item"
          class="asset-size-upload"
          :src="require(`@/api/data/imageMovieMaster/zip.jpg`)"
        />

        <img
          v-else-if="type === 'Image'"
          :id="id"
          ref="item"
          class="asset-size-upload"
          :src="url"
          @click="openItemDialog"
        />

        <div v-else-if="type === 'Video'" ref="item" class="w-100 video-wrap">
          <video class="asset-size-upload" controls controlslist="nodownload" preload="metadata" :src="url" />
          <div class="video-btn" />
        </div>

        <div v-else-if="type === 'Music'" ref="item" class="w-100 video-wrap">
          <video
            class="asset-size-upload"
            controls
            controlslist="nodownload"
            :poster="require(`@/assets/audio.png`)"
            preload="metadata"
            :src="url"
          />
          <div class="video-btn" />
        </div>

        <div v-else-if="type === 'Url'">
          <iframe
            :class="isMask ? 'asset-size-upload isDisableYoutube' : 'asset-size-upload'"
            :src="url + '?controls=0'"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>

        <span v-else class="w-100 h-100"> 対応していないタイプです。 </span>

        <item-dialog ref="itemDialog" :url="url" />
      </div>
    </div>
    <div v-if="this.parentComponent === 'asset'">
      <div id="image-movie-item">
        <el-image
          v-if="type === 'Zip'"
          ref="item"
          class="asset-size asset-width"
          :src="require(`@/api/data/imageMovieMaster/zip.jpg`)"
        />

        <img
          v-else-if="type === 'Image'"
          :id="id"
          ref="item"
          class="w-100 asset-width"
          :src="url"
          @click="openItemDialog"
        />

        <div v-else-if="type === 'Video'" ref="item" class="w-100 video-wrap asset-width">
          <video
            class="w-100 fit-video"
            :controls="!noPreview"
            controlslist="nodownload"
            preload="metadata"
            :src="url"
            @click.prevent
          />
        </div>

        <div v-else-if="type === 'Music'" ref="item" class="w-100 video-wrap asset-width">
          <video
            class="w-100 fit-video"
            :controls="!noPreview"
            controlslist="nodownload"
            :poster="require(`@/assets/audio.png`)"
            preload="metadata"
            :src="url"
            @click.prevent
          />
        </div>

        <div v-else-if="type === 'Url'">
          <iframe
            :class="isMask ? 'w-100 isDisableYoutube' : 'w-100'"
            :src="url + '?controls=0'"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </div>

        <span v-else class="w-100 h-100"> 対応していないタイプです。 </span>

        <item-dialog ref="itemDialog" :url="url" />
      </div>
    </div>
    <div v-if="this.parentComponent === 'newAsset'" class="wh-assetCr">
      <el-image v-if="type === 'Zip'" ref="item" :src="require(`@/api/data/imageMovieMaster/zip.jpg`)" />

      <img v-else-if="type === 'Image'" :id="id" ref="item" class="wh-assetCr fit" :src="url" @click="openItemDialog" />

      <div v-else-if="type === 'Video'" ref="item" class="wh-assetCr video-wrap">
        <video class="fit-video" controls controlslist="nodownload" preload="metadata" :src="url" @click.prevent />
      </div>

      <div v-else-if="type === 'Music'" ref="item" class="wh-assetCr video-wrap">
        <video
          class="fit-video"
          controls
          controlslist="nodownload"
          :poster="require(`@/assets/audio.png`)"
          preload="metadata"
          :src="url"
          @click.prevent
          style="min-height: 100%"
        />
      </div>

      <div v-else-if="type === 'Url'">
        <iframe
          :class="isMask ? 'w-100 isDisableYoutube' : 'w-100'"
          :src="url + '?controls=0'"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>

      <span v-else class="w-100 h-100"> 対応していないタイプです。 </span>

      <item-dialog ref="itemDialog" :url="url" />
    </div>
  </div>
</template>

<style scoped>
.wh-assetCr {
  width: 100%;
  height: 100%;
  margin: auto;
}
.wh-crReg {
  width: 100%;
  height: 100%;
  margin: auto;
}

.asset-size-cr-list {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}
.asset-size-cr-regist {
  max-height: 18vh;
  max-width: 10.5vw;
}

.isDisableYoutube {
  overflow: hidden;
  pointer-events: none;
}

#image-movie-item-cr-list {
  width: 100%;
  font-size: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
}
#image-movie-item-cr-regist {
  height: 18vh;
  font-size: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.asset-size {
  max-height: 180px;
  max-width: 180px;
}

.asset-width {
  max-width: 170px;
}

.fit {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto;
}

.fit-video {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  min-height: 80%;
}

.asset-size-upload {
  max-height: 18vh;
  max-width: 8.5vw;
}

#image-movie-item {
  font-size: 0;
  text-align: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.video-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}

.video-btn {
  content: '';
  position: absolute;
  top: 10;
  left: 0;
  right: 0;
  bottom: 60px;
  cursor: pointer;
}
</style>

<script>
import ItemDialog from '@/components/image-movie-item/components/item-dialog'

export default {
  name: 'image-movie-item',
  props: {
    url: {
      type: String,
      default: () => '',
    },
    thumbnailUrl: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
    noPreview: {
      type: Boolean,
      default: () => false,
    },
    fileName: {
      type: String,
      default: () => null,
    },
    parentComponent: {
      type: String,
      default: () => 'asset',
    },
    isMask: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    ItemDialog,
  },
  data: () => ({
    loading: false,
    id: null,
  }),
  created() {
    window.ImageMovieItem = this
    this.id = this.generateRandom()
  },
  methods: {
    openItemDialog() {
      if (this.noPreview) return

      const aspectRatio = this.$refs.item.clientWidth / this.$refs.item.clientHeight
      this.$refs.itemDialog.show(aspectRatio)
    },
    refreshPng() {
      const extension = this.fileName
        ? this.fileName.substr(this.fileName.lastIndexOf('.') + 1)
        : this.url.substr(this.url.lastIndexOf('.') + 1)

      if (extension === 'png') {
        const png = document.getElementById(this.id)
        setInterval(() => {
          png.setAttribute('src', this.url)
        }, 6000)
      }
    },
    generateRandom() {
      return (Math.random() + 1).toString(36).substring(7)
    },
  },
  mounted() {
    this.refreshPng()
  },
}
</script>
