<template>
  <div id="ad-format-tab-list" v-loading="loading">
    <div v-if="isInDialog && $route.path === '/approval-detail'">
      <div v-if="adFormatType === 'onlyAsset'" class="scroll" :style="{ minHeight: height - 16 - 32 - 16 + 'px' }">
        <el-row class="m-1" v-for="(creatives, i) in onlyAssetCreatives" :key="`asset_creative_${i}`">
          <el-col :span="14" v-for="(creative, j) in creatives" :key="`asset_creative_${i}_${j}`">
            <only-asset-card
              ref="asset-card"
              :key="creative.creativeId"
              :is-checked="selectedCreativeIds.includes(creative.creativeId)"
              class="ms-1"
              :platform-id="platformId"
              :ad-format-name="adFormatName"
              :creative="creative"
              :adFormatId="adFormatId"
              :adFormats="adFormats"
              :is-in-dialog="isInDialog"
              :selected-creative-id="selectedCreativeId"
              :is-show="isShow"
              :creative-ids="creativeIds"
              @toggle-checked="toggleChecked"
              :asset-regulate-error="assetRegulateError"
              @get-creatives="$emit('get-creatives')"
              @change-query="q => $emit('change-query', q)"
              @remove-creative="id => $emit('remove-creative', id)"
              @change-creator-comment="
                comment => $emit('change-creator-comment', comment, creative.creativeId, platformId)
              "
              @change-reference-url="urls => $emit('change-reference-url', urls, creative.creativeId, platformId)"
              @change-note="note => $emit('change-note', note, creative.creativeId, platformId)"
              @edit-note-creatives="(note, creatives) => $emit('edit-note-creatives', note, creatives)"
              @open-proposed-dialog="(textSetId, adFormatId) => $emit('open-proposed-dialog', textSetId, adFormatId)"
              @change-status-text="(textSetId, status) => $emit('change-status-text', textSetId, status)"
              @change-status-asset="(assetId, status) => $emit('change-status-asset', assetId, status)"
              @change-status-creative="(creativeId, status) => $emit('change-status-creative', creativeId, status)"
              @update-text="(form, textSet, isEqual) => $emit('update-text', textSet, form, isEqual)"
              @update-ver-asset="
                (file, assetId, skipCheckRule, originId, allOriginIds, labelId) =>
                  $emit('update-ver-asset', file, assetId, skipCheckRule, originId, allOriginIds, labelId)
              "
            />
          </el-col>
        </el-row>
      </div>

      <div v-if="adFormatType === 'onlyText'" class="scroll" :style="{ minHeight: height - 16 - 32 - 16 + 'px' }">
        <el-row class="m-1" v-for="(creative, i) in onlyTextCreatives" :key="`creative_text_${i}`">
          <only-text-card
            ref="text-card"
            :key="creative.creativeId"
            :is-checked="selectedCreativeIds.includes(creative.creativeId)"
            :platform-id="platformId"
            :creative="creative"
            :is-in-dialog="isInDialog"
            :selected-creative-id="selectedCreativeId"
            :is-show="isShow"
            :adFormatId="adFormatId"
            :adFormats="adFormats"
            :creative-ids="creativeIds"
            @toggle-checked="toggleChecked"
            @get-creatives="$emit('get-creatives')"
            @change-query="q => $emit('change-query', q)"
            @remove-creative="id => $emit('remove-creative', id)"
            @change-note="note => $emit('change-note', note, creative.creativeId, platformId)"
            @change-creator-comment="
              comment => $emit('change-creator-comment', comment, creative.creativeId, platformId)
            "
            @change-reference-url="urls => $emit('change-reference-url', urls, creative.creativeId, platformId)"
            @change-selected-creative-id="
              v => {
                selectedCreativeId = v
              }
            "
            @open-proposed-dialog="(textSetId, adFormatId) => $emit('open-proposed-dialog', textSetId, adFormatId)"
            @edit-note-creatives="(note, creatives) => $emit('edit-note-creatives', note, creatives)"
            @change-status-text="
              (textSetId, status, version) => $emit('change-status-text', textSetId, status, version)
            "
            @change-status-asset="(assetId, status) => $emit('change-status-asset', assetId, status)"
            @change-status-creative="(creativeId, status) => $emit('change-status-creative', creativeId, status)"
            @update-text="(form, textSet, isEqual, version) => $emit('update-text', textSet, form, isEqual, version)"
            @update-ver-asset="
              (file, assetId, skipCheckRule, originId, allOriginIds, labelId) =>
                $emit('update-ver-asset', file, assetId, skipCheckRule, originId, allOriginIds, labelId)
            "
          />
        </el-row>
      </div>

      <div v-if="adFormatType === 'default'" class="scroll" :style="{ minHeight: height - 16 - 32 - 16 + 'px' }">
        <el-row class="m-1" v-for="(creative, i) in formatedCreatives" :key="`creative_${i}`">
          <creative-card
            ref="creative-card"
            :key="creative.creativeId"
            :is-checked="selectedCreativeIds.includes(creative.creativeId)"
            :platform-id="platformId"
            :adFormatId="adFormatId"
            :adFormats="adFormats"
            :creative="creative"
            :is-in-dialog="isInDialog"
            :selected-creative-id="selectedCreativeId"
            :is-show="isShow"
            :creative-ids="creativeIds"
            :asset-regulate-error="assetRegulateError"
            @toggle-checked="toggleChecked"
            @remove-creative="id => $emit('remove-creative', id)"
            @get-creatives="$emit('get-creatives')"
            @change-query="q => $emit('change-query', q)"
            @change-note="note => $emit('change-note', note, creative.creativeId, platformId)"
            @change-creator-comment="
              comment => $emit('change-creator-comment', comment, creative.creativeId, platformId)
            "
            @change-reference-url="urls => $emit('change-reference-url', urls, creative.creativeId, platformId)"
            @change-selected-creative-id="
              v => {
                selectedCreativeId = v
              }
            "
            @open-proposed-dialog="(textSetId, adFormatId) => $emit('open-proposed-dialog', textSetId, adFormatId)"
            @edit-note-creatives="(note, creatives) => $emit('edit-note-creatives', note, creatives)"
            @change-status-text="
              (textSetId, status, version) => $emit('change-status-text', textSetId, status, version)
            "
            @change-status-asset="(assetId, status) => $emit('change-status-asset', assetId, status)"
            @change-status-creative="(creativeId, status) => $emit('change-status-creative', creativeId, status)"
            @update-text="(form, textSet, isEqual, version) => $emit('update-text', textSet, form, isEqual, version)"
            @update-ver-asset="
              (file, assetId, skipCheckRule, originId, allOriginIds, labelId) =>
                $emit('update-ver-asset', file, assetId, skipCheckRule, originId, allOriginIds, labelId)
            "
          />
        </el-row>
      </div>
    </div>

    <div class="text-center" @click="moveToTop">
      <el-pagination
        class="m-1"
        background
        :current-page.sync="currentPage"
        :page-count="pageCount"
        @current-change="changeCurrentPage"
      />
    </div>
  </div>
</template>

<style scoped>
.m-1 {
  margin: 0rem;
}
.w-100 {
  width: 100%;
}
.scroll {
  overflow-y: unset;
}
.ms-1 {
  margin: 0 5px;
}
</style>

<script>
import _ from 'lodash'
import CreativeCard from '@/views/approval-detail/components/ad-format-tab/creative-card'
import OnlyAssetCard from '@/views/approval-detail/components/ad-format-tab/only-asset-card'
import OnlyTextCard from '@/views/approval-detail/components/ad-format-tab/only-text-card'

export default {
  name: 'ad-format-tab-list',
  components: { CreativeCard, OnlyAssetCard, OnlyTextCard },
  props: {
    selected: { type: Array, default: () => [] },
    platformId: { type: String, default: () => '' },
    adFormatId: { type: String, default: () => '' },
    adFormatName: { type: String, default: () => '' },
    creatives: { type: Array, default: () => [] },
    height: { type: Number, default: () => null },
    isInDialog: { type: Boolean, default: () => false },
    isCreativeList: { type: Boolean, default: () => false },
    isUpdate: { type: Boolean, default: () => false },
    creativeIds: { type: Array, default: () => [] },
    errors: { type: Array, default: () => [] },
    isShow: { type: Boolean, default: () => false },
    sortType: { type: String, default: () => '' },
    adFormats: { type: Array, default: () => [] },
    selectedCreativeIds: { type: Array, default: () => [] },
    assetRegulateError: { type: Array, default: () => [] },
  },
  data: () => ({
    loading: false,
    assetWidth: 250,
    currentPage: 1,
    pageSize: 20,
    selectedCreativeId: null,
  }),
  created() {
    window.AdFormatTabList = this
  },
  methods: {
    log(mess, data) {
      console.log(mess, data)
    },
    moveToTop() {
      if (this.isInDialog) return
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    changeCurrentPage() {
      document.getElementById('scroll-area').scrollTo({ top: 0, behavior: 'smooth' })
    },
    changeSelected(gr) {
      this.$emit('change-selected-at-tab', {
        selected: _.map(gr, v => _.find(this.creatives, r => r.creativeId === v)),
        platformId: this.platformId,
        adFormatId: this.adFormatId,
      })
    },
    toggleChecked(creativeId) {
      if (this.selectedCreativeIds.includes(creativeId)) {
        this.$emit('change-selected-creative-ids', _.without(this.selectedCreativeIds, creativeId))
      } else {
        this.$emit('change-selected-creative-ids', [...this.selectedCreativeIds, creativeId])
      }
    },
    assetErrors(creative) {
      const labelIds = creative.labeledAssets.map(a => a.labelId)
      return this.assetRegulateError.filter(error => labelIds.includes(error.labeledAssetsKey))
    },
  },
  watch: {
    selected() {
      if (this.selected.length === 0) this.selectedCreativeId = null
    },
    selectedCreativeId() {
      const creative = _.find(this.creatives, r => r.creativeId === this.selectedCreativeId)
      this.$emit('change-compare-creative', creative)
    },
    sortType() {
      this.$emit('change-grouped-creatives')
    },
  },
  computed: {
    pageCount() {
      return _.ceil(this.creatives.length / this.pageSize)
    },
    selectedChild() {
      const creativeIds = _.map(this.creatives, 'creativeId')
      return _.chain(this.selected)
        .filter(r => _.includes(creativeIds, r.creativeId))
        .map('creativeId')
        .value()
    },
    formatedCreatives() {
      const data = _.chain(this.creatives)
        .cloneDeep()
        .map(r => {
          const labeledTexts = _.chain(r).result('textSet.labeledTexts').orderBy('index').value()
          const textSet = _.assign({}, r.textSet, { labeledTexts })
          let first
          for (const labeledAsset of r.labeledAssets) {
            first = _.result(labeledAsset, 'assets[0]')
            if (first) break
          }
          const assets = _.chain(r.labeledAssets)
            .map(({ assets, labelName, labelId }) => _.map(assets, r => _.assign({}, r, { labelName, labelId })))
            .flattenDeep()
            .value()
          const error = _.find(this.errors, r2 => r2.creativeId === r.creativeId)
          return _.assign({}, r, { textSet, first, assets, error })
        })
        .filter((r, i) => (this.currentPage - 1) * this.pageSize < i + 1 && i + 1 <= this.currentPage * this.pageSize)
      if (this.sortType === 'df')
        return data.thru(gr => _.orderBy(gr, ['first.metadata.fileName', 'textSet.textSetId'], ['asc', 'asc'])).value()
      else return data.value()
    },
    normalCreatives() {
      return this.formatedCreatives.filter(x => x.textSet.labeledTexts.length !== 0 && x.labeledAssets.length !== 0)
    },
    onlyAssetCreatives() {
      return _.chain(this.formatedCreatives)
        .cloneDeep()
        .filter(x => x.textSet.labeledTexts.length === 0 && x.labeledAssets.length !== 0)
        .chunk(2)
        .value()
    },
    onlyTextCreatives() {
      return this.formatedCreatives.filter(x => x.textSet.labeledTexts.length !== 0 && x.labeledAssets.length === 0)
    },
    adFormatType() {
      const adFormat = _.cloneDeep(this.adFormats).find(x => x.id === this.adFormatId)
      if (adFormat !== undefined && adFormat.assetLabels.length === 0) {
        return 'onlyText'
      } else if (adFormat !== undefined && adFormat.textLabels.length === 0) {
        return 'onlyAsset'
      } else return 'default'
    },
  },
}
</script>
