var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-container',{attrs:{"id":"approval-text-tab"}},[_c('div',{staticClass:"tab-pane fade",attrs:{"id":"pills-4","role":"tabpanel"}},_vm._l((_vm.sortedAdFormat),function(adFormat,i){return _c('div',{key:i},[_c('p',{staticClass:"label-name",staticStyle:{"color":"#303133","font-weight":"500","font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.genTextLabelName(_vm.textSets[adFormat]))+" ")]),_c('ul',{staticClass:"listForm"},_vm._l((_vm.textSets[adFormat]),function(labelText,idx){return _c('li',{key:`${i}_${idx}`,style:({ backgroundColor: !labelText.isLatest ? 'rgba(229, 229, 229, 0.8745098039)' : '' })},[_c('div',{staticClass:"form-body"},[_c('div',[_c('section',{staticClass:"form-title-section",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"form-title",style:({
                    backgroundColor: _vm.backgroundColor(labelText.approvalStatus),
                    color: _vm.textColor(labelText.approvalStatus),
                    height: ['/approval-detail'].includes(_vm.$route.path) ? '30px' : '20px',
                  })},[_vm._v(" "+_vm._s(labelText.approvalStatus)+" ")]),(!labelText.isLatest)?_c('el-tooltip',{attrs:{"placement":"right-start","effect":"light","popper-class":"notifier__tooltip","visible-arrow":false,"open-delay":500,"content":_vm.NOT_ABLE_CHANGE_STATUS_MSG}},[_c('span',{staticClass:"notifier--warning",staticStyle:{"align-self":"center","margin-left":"15px"}},[_c('i',{staticClass:"el-icon-warning error-mark"})])]):_vm._e(),(
                    ['/approval-detail'].includes(_vm.$route.path) &&
                    labelText.approvalStatus === _vm.AssetTextStatus.FIXING &&
                    labelText.isLatest
                  )?_c('el-button',{staticClass:"form-title__button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.updateText(labelText)}}},[_vm._v(" テキスト編集 ")]):_vm._e()],1),_c('div',{staticClass:"form-group",staticStyle:{"min-height":"200px","margin-top":"10px"}},[(labelText.labeledTexts && labelText.labeledTexts.length > 0)?_c('div',{staticClass:"row row-2 mb-2"},_vm._l((labelText.labeledTexts),function(text,id){return _c('div',{key:`${id}_${i}_${idx}`,staticClass:"col-3"},[_c('label',{staticClass:"form-label",attrs:{"id":text.labelId}},[_vm._v(_vm._s(text.labelName))]),(text.textValues.length > 0)?_c('div',_vm._l((text.textValues),function(textValue,index){return _c('div',{key:`${index}_${id}_${i}_${idx}`},[_c('div',{class:`textbox ${textValue.value === '' && 'empty'}`},[_vm._v(" "+_vm._s(textValue.value || '未入力')+" ")])])}),0):_c('div',[_c('div',{staticClass:"textbox empty",staticStyle:{"min-height":"19px"}},[_vm._v("未入力")])])])}),0):_vm._e()])]),_c('div',[_c('div',{staticClass:"thumb-name"},[_c('span',{staticClass:"thumb-ver"},[_vm._v("v"+_vm._s(labelText.version))])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-label"},[_c('i',{staticClass:"fas fa-comments"}),_vm._v(" 制作意図 ")]),_c('textarea',{class:labelText.isLatest ? 'form-control' : 'form-control text-old-version',attrs:{"disabled":!labelText.isLatest},domProps:{"value":labelText.creatorComment},on:{"input":$event =>
                      _vm.updateCreatorComment($event, labelText.textSetId, labelText.platformId, labelText.version)}})])])])])}),0)])}),0),(true)?_c('edit-text-set-dialog',{ref:"editTextSetDialog",attrs:{"platform-id":_vm.textSetDraft.platformId || '',"ad-format-id":_vm.textSetDraft.adFormatId || '',"ad-format-name":_vm.textSetDraft.adFormatName || '',"ad-formats":_vm.adFormats,"ad-format":_vm.adFormat,"is-multiple":true},on:{"get-text-sets-draft":(texts, form) => _vm.textsDraft(texts, form)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }