const defaultBucketName = 'creative-tracking-x-images'

export default {
  install(Vue, { AWS }) {
    const safeS3Access = async (action, param) => {
      if (!AWS.config.credentials) await Vue.$cognito.setCredentials()
      return new AWS.S3({ apiVersion: '2006-03-01' })[action](param).promise()
    }
    Vue.prototype.$s3 = {
      get: async (key, argBucketName) => {
        const param = { Bucket: argBucketName || defaultBucketName, Key: key }
        const { Body, ContentType } = await safeS3Access('getObject', param)

        if (ContentType.match('application/json')) return JSON.parse(Body.toString())
        else if (ContentType.match('image')) return new File([Body.buffer], key, { type: ContentType })
        else return Body
      },
      put: (key, data, argBucketName) => {
        const params = data.type
          ? {
              Bucket: argBucketName || defaultBucketName,
              Key: key,
              ContentType: data.type,
              Body: data,
              ACL: 'public-read-write',
            }
          : {
              Bucket: argBucketName || defaultBucketName,
              Key: key,
              ContentType: 'application/json',
              Body: JSON.stringify(data),
            }
        return safeS3Access('putObject', params)
      },
      delete: (key, argBucketName) =>
        safeS3Access('deleteObject', {
          Bucket: argBucketName || defaultBucketName,
          Key: key,
        }),
    }
  },
}
