<template>
  <el-dialog
    id="asset-dialog"
    title="素材選択"
    lock-scroll
    fullscreen
    append-to-body
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="scroll" :style="{ height: height + 'px' }">
      <el-card
        shadow="never"
        :body-style="{ padding: '8px', 'word-break': 'break-all' }"
        class="m-1"
        v-for="(r, i) in distributedSelected"
        :key="i"
        :style="{ margin: '0.5rem' }"
      >
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.5rem;
            padding-right: 5px;
          "
        >
          <div style="height: 100%">
            <el-tag
              type="info"
              class="font-bold mr-1 mb-1 text-info d-inline-block align-items-center"
              style="cursor: default; margin-bottom: 0px; border: none; background-color: #e8e8eb"
            >
              {{ r.name }}
            </el-tag>

            <el-tag
              type="info"
              class="font-bold mr-1 mb-1 text-info d-inline-block align-items-center"
              style="cursor: default; margin-bottom: 0px; border: none; background-color: #e8e8eb"
            >
              <i
                class="el-icon-picture-outline"
                style="cursor: inherit; transform: scale(1.4, 1.5); margin-right: 2px"
              />
              入稿可能ファイル数 &nbsp;
              <span
                style="cursor: inherit; font-size: inherit"
                :style="{
                  color: checkCarouselRule(
                    r.minFileNumber,
                    r.maxFileNumber,
                    r.selected.filter(asset => asset.metadata.extension !== 'zip' && asset.metadata.extension !== 'Zip')
                      .length
                  )
                    ? '#4fc848'
                    : '#f56c6c',
                }"
              >
                {{
                  r.selected.filter(asset => asset.metadata.extension !== 'zip' && asset.metadata.extension !== 'Zip')
                    .length
                }}/{{ r.maxFileNumber ? r.maxFileNumber : '登録なし' }}
              </span>
              <el-tooltip
                v-if="
                  !checkCarouselRule(
                    r.minFileNumber,
                    r.maxFileNumber,
                    r.selected.filter(asset => asset.metadata.extension !== 'zip' && asset.metadata.extension !== 'Zip')
                      .length
                  )
                "
                class="item"
                effect="dark"
                placement="right"
                :enterable="false"
              >
                <i class="el-icon-warning-outline" style="cursor: pointer; color: #f56c6c" />
                <div slot="content" style="font-weight: 600">
                  入稿可能数
                  <div
                    v-if="r.minFileNumber"
                    style="cursor: default; font-size: 11px; margin: 3px auto; font-weight: inherit"
                  >
                    最小: {{ r.minFileNumber }}
                  </div>
                  <div v-if="r.maxFileNumber" style="cursor: default; font-size: 11px; font-weight: inherit">
                    最大: {{ r.maxFileNumber }}
                  </div>
                </div>
              </el-tooltip>
            </el-tag>
            <el-tag
              v-if="r.aspect && r.aspect.length !== 0"
              type="info"
              class="font-bold mr-1 mb-1 text-info d-inline-block align-items-center"
              style="cursor: default; width: auto; border: none; background-color: #e8e8eb"
            >
              <i class="el-icon-full-screen" style="cursor: inherit; transform: scale(1.4, 1.5); margin-right: 2px" />
              入稿可能アスペクト比 &nbsp;
              <span
                v-for="(a, idx) in r.aspect"
                :key="idx"
                style="cursor: inherit; font-size: inherit; margin-left: 7px"
              >
                {{ formatAspect(a.aspectRatio) }}
                <span
                  :style="{
                    cursor: 'inherit',
                    fontSize: 'inherit',
                    color:
                      findAssetWithAspect(r.id, formatAspect(a.aspectRatio)) &&
                      findAssetWithAspect(r.id, formatAspect(a.aspectRatio)).checkRule !== null &&
                      !containZip
                        ? '#f56c6c'
                        : '#4fc848',
                  }"
                >
                  {{
                    findAssetWithAspect(r.id, formatAspect(a.aspectRatio)) === null
                      ? 0
                      : findAssetWithAspect(r.id, formatAspect(a.aspectRatio)).count
                  }}/{{
                    a.submissionSize && a.submissionSize.max
                      ? a.submissionSize.max
                      : r.maxFileNumber
                      ? r.maxFileNumber
                      : '登録なし'
                  }}
                </span>
                <el-tooltip
                  v-if="
                    findAssetWithAspect(r.id, formatAspect(a.aspectRatio)) &&
                    findAssetWithAspect(r.id, formatAspect(a.aspectRatio)).checkRule !== null &&
                    !containZip
                  "
                  class="item"
                  effect="dark"
                  placement="right"
                  :enterable="false"
                >
                  <i class="el-icon-warning-outline" style="padding-right: 2px; color: #f56c6c" />
                  <div slot="content" style="font-weight: 600">
                    入稿可能数
                    <div
                      v-if="a.submissionSize && a.submissionSize.min"
                      style="cursor: default; font-size: 11px; margin: 3px auto; font-weight: inherit"
                    >
                      最小: {{ a.submissionSize.min }}
                    </div>
                    <div
                      v-if="a.submissionSize && a.submissionSize.max"
                      style="cursor: default; font-size: 11px; font-weight: inherit"
                    >
                      最大: {{ a.submissionSize.max }}
                    </div>
                  </div>
                </el-tooltip>
              </span>
            </el-tag>
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 0.7rem;
              padding-right: 5px;
            "
          >
            <div class="hover-memo" style="cursor: default; font-weight: 600; color: #909399">
              <el-popover placement="bottom-end" width="600" popper-class="memo-popover" trigger="click">
                <div slot="reference" class="text-info" style="cursor: pointer">
                  <i
                    class="fa-solid fa-circle-exclamation"
                    style="cursor: pointer; transform: scale(1.1); font-weight: 700"
                  />
                  要素のメモ
                </div>
                <span class="memo-content">
                  {{ r.memo ? r.memo : 'メモなし' }}
                </span>
              </el-popover>
            </div>
          </div>
        </div>

        <el-row v-if="adjustmentAssetErrors[i] && adjustmentAssetErrors[i].errors.length !== 0">
          <el-row v-for="(error, j) in adjustmentAssetErrors[i].errors" :key="`error_${i}_${j}`" class="detail">
            {{ error }}
          </el-row>
        </el-row>

        <el-checkbox-group :value="r.selected.map(r => r.originId)" @input="gr => changeGroupSelected(gr, i)">
          <el-row :gutter="15">
            <draggable v-model="r.selected">
              <el-col
                :span="4"
                v-for="(asset, j) in r.selected"
                :key="`asset_${i}_${j}`"
                :style="{
                  padding: '10px 5px',
                  height: '100%',
                  ...(r.selected.length - 1 === j && {
                    width: 'min-content',
                  }),
                }"
              >
                <div v-if="r.selected.length - 1 > j">
                  <asset-item
                    :assetErrors="
                      adjustmentAssetErrors[i] &&
                      adjustmentAssetErrors[i].details.length !== 0 &&
                      adjustmentAssetErrors[i].details[j] &&
                      adjustmentAssetErrors[i].details[j].length !== 0
                        ? adjustmentAssetErrors[i].details[j]
                        : []
                    "
                    :asset="asset"
                    :idOpenError="idOpenError"
                    :onAssetSelectDialog="true"
                    @change-id-open-error="assetId => changeIdOpenError(assetId)"
                    @uncheck-asset="assetId => removeSelectedAsset(i, assetId)"
                  />
                </div>

                <div
                  v-else
                  style="display: flex; align-items: center"
                  @dragstart="handleDragStart"
                  @dragend="handleDragEnd"
                  draggable="true"
                >
                  <asset-item
                    :assetErrors="
                      adjustmentAssetErrors[i] &&
                      adjustmentAssetErrors[i].details.length !== 0 &&
                      adjustmentAssetErrors[i].details[j] &&
                      adjustmentAssetErrors[i].details[j].length !== 0
                        ? adjustmentAssetErrors[i].details[j]
                        : []
                    "
                    :asset="asset"
                    :idOpenError="idOpenError"
                    :onAssetSelectDialog="true"
                    @change-id-open-error="assetId => changeIdOpenError(assetId)"
                    @uncheck-asset="assetId => removeSelectedAsset(i, assetId)"
                  />
                  <el-tag
                    type="info"
                    effect="plain"
                    class="font-bold mr-1 mb-1"
                    id="add-box"
                    style="
                      cursor: pointer;
                      display: grid;
                      grid-template-rows: 70px 2fr;
                      height: auto;
                      padding: 20px 27px;
                      margin: 25px 15px 5px 15px;
                      border: 1.2px solid #747474;
                      -webkit-user-drag: none;
                    "
                    @click="$refs.assetDialog.show(i, r.selected, r.extensions, r.id)"
                  >
                    <div style="display: flex; justify-content: center; align-items: center">
                      <i
                        class="el-icon-picture-outline"
                        style="cursor: inherit; font-size: 70px; color: #747474; margin-top: 10px"
                      />
                    </div>
                    <span style="text-align: center; line-height: initial; color: #747474">追加</span>
                  </el-tag>
                </div>
              </el-col>
            </draggable>
            <div
              v-if="r.selected.length === 0"
              style="min-height: 260px; height: 100%; display: flex; align-items: center; -webkit-user-drag: none"
            >
              <el-tag
                type="info"
                effect="plain"
                class="font-bold mr-1 mb-1"
                style="
                  cursor: pointer;
                  display: grid;
                  grid-template-rows: 70px 2fr;
                  height: auto;
                  padding: 20px 27px;
                  margin: 25px 15px 5px 15px;
                  border: 1.2px solid #747474;
                "
                @click="$refs.assetDialog.show(i, r.selected, r.extensions, r.id)"
              >
                <div style="display: flex; justify-content: center; align-items: center">
                  <i
                    class="el-icon-picture-outline"
                    style="cursor: inherit; font-size: 70px; color: #747474; margin-top: 10px"
                  />
                </div>
                <span style="text-align: center; line-height: initial; color: #747474">追加</span>
              </el-tag>
            </div>
          </el-row>
        </el-checkbox-group>
      </el-card>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="danger" v-if="adjustmentAssetErrors.length !== 0" @click="clipboardErrors">
        規定違反内容を取得する
      </el-button>

      <el-button type="primary" @click="close"> 保存 </el-button>
    </span>

    <asset-dialog
      ref="assetDialog"
      :selected-promotion-ids="selectedPromotionIds"
      :tags="tags"
      :ad-format-id="adFormatId"
      :height="height"
      @change-selected-assets="changeSelectedAssets"
    />
  </el-dialog>
</template>

<style>
#asset-dialog .el-tabs__content {
  padding: 0;
}
.memo-popover {
  max-height: 250px;
  overflow-y: scroll;
}
.memo-content {
  white-space: break-spaces;
}
.el-message-box {
  width: 500px !important;
}
.hover-memo {
  color: black;
}
.hover-memo:hover {
  color: blue !important;
  transform: scale(1.05);
}
.hover-memo:active {
  transform: scale(0.98);
}
</style>

<style scoped>
.p-1 {
  padding: 1rem;
}
.pl-1 {
  padding-left: 10px;
}
.pr-1 {
  padding-right: 10px;
}
.m-1 {
  margin: 1rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
.mt-1 {
  margin-top: 0.5rem;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.border-r-1 {
  border-right: 1px solid #ebeef5;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.text-center {
  text-align: center;
}
.font-big {
  font-size: 20px;
}
.font-bold {
  font-weight: bold;
}
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
.detail {
  font-size: 10px;
  color: #f56c6c;
}

.required-tag {
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 42px;
  background-color: #f56c6c;
  color: #fff;
  font-size: 12px;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  border-radius: 7px;
  padding-left: 10px;
  -webkit-clip-path: polygon(15px 0%, 100% 0%, 100% 100%, 15px 100%, 0 50%);
  clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0 50%);
  line-height: 19px;
}
.option-tag {
  align-items: center;
  justify-content: center;
  height: 21px;
  width: 42px;
  background-color: #00d7bf;
  color: #fff;
  font-size: 12px;
  margin-right: 0.5rem;
  margin-left: -0.5rem;
  border-radius: 7px;
  padding-left: 10px;
  -webkit-clip-path: polygon(15px 0%, 100% 0%, 100% 100%, 15px 100%, 0 50%);
  clip-path: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0 50%);
  line-height: 19px;
}
.text-info {
  color: #747474 !important;
  font-size: 12px !important;
}
</style>

<script>
import _ from 'lodash'
import Draggable from 'vuedraggable'
import AssetItem from '@/views/image-movie-master/pixel-size-tab/asset-item'
import AssetDialog from '@/views/creative-regist/creative-item/asset-area/asset-select-dialog/components/asset-dialog'

export default {
  name: 'asset-select-dialog',
  components: { Draggable, AssetItem, AssetDialog },
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    adFormatId: { type: String, default: () => '' },
    assetLabels: { type: Array, default: () => [] },
  },
  data: () => ({
    isShow: false,
    index: null,
    selected: [],
    assetErrors: [],
    distributedSelected: [],
    innerHeight: window.innerHeight,
    idOpenError: '',
  }),
  created() {
    window.AssetSelectDialog = this
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })
  },
  methods: {
    changeIdOpenError(assetId) {
      this.idOpenError = assetId
    },
    show(index, labeledAssets, assetErrors) {
      this.distributedSelected = _.chain(this.assetLabels)
        .map(r => {
          const labeledAsset = _.find(labeledAssets, r2 => r2.id === r.id)
          return _.assign({}, r, labeledAsset || { selected: [] })
        })
        .compact()
        .cloneDeep()
        .value()

      this.selected = []
      this.index = index
      this.assetErrors = assetErrors || []
      this.isShow = true
    },
    changeSelectedAssets({ index, selected }) {
      this.distributedSelected = _.map(this.distributedSelected, (r, i) =>
        _.assign({}, r, i === index ? { selected } : {})
      )
    },
    removeSelectedAsset(index, assetId) {
      this.distributedSelected = _.map(this.distributedSelected, (r, i) =>
        _.assign({}, r, i == index ? { selected: _.filter(r.selected, r2 => r2.assetId !== assetId) } : {})
      )
    },
    changeGroupSelected(selectedIds, index) {
      this.distributedSelected = _.map(this.distributedSelected, (r, i) =>
        _.assign(
          {},
          r,
          i === index ? { selected: _.filter(r.selected, r2 => _.includes(selectedIds, r2.originId)) } : {}
        )
      )
    },
    close() {
      const containZip = _.chain(this.distributedSelected)
        .map(r => r.selected)
        .flatten()
        .map(r => r.metadata.extension)
        .value()
        .includes('zip')

      if (!this.isSplitAsset && !containZip) {
        const errors = _.chain(this.distributedSelected)
          .flatMap((r, index) => {
            let carouselError = null
            if (r.selected.length < r.minFileNumber)
              carouselError = `<div>${r.name}: 素材は${r.minFileNumber}つ以上で選択してください</div>`
            else if (r.selected.length > r.maxFileNumber)
              carouselError = `<div>${r.name}: 素材は${r.maxFileNumber}つ以下で選択してください</div>`
            const submissionErrors = this.calculateAspect[index]?.data.map(aspect => {
              if (aspect.checkRule) return `<div>${r.name}: ${aspect.aspect}${aspect.checkRule}</div>`
              else return null
            })
            return [carouselError, ...submissionErrors]
          })
          .filter()
          .value()

        if (errors.length !== 0) {
          this.$alert(errors.join(''), '必須チェック', { dangerouslyUseHTMLString: true })
          return
        }
      }

      const labeledAssets = _.cloneDeep(this.distributedSelected)
      this.$emit('change-selected-assets', {
        labeledAssets,
        index: this.index,
        isSplitAsset: this.isSplitAsset,
      })
      this.isShow = false
    },
    clipboardErrors() {
      const errors = _.chain(this.distributedSelected)
        .map(r => {
          const assetError = _.find(this.assetErrors, r2 => r2.labelId === r.id)
          if (!assetError) return

          const details = _.chain(r.selected)
            .map(r2 => {
              const { errors = [] } = _.find(assetError.targets, r3 => r3.key === r2.originId) || {}
              if (errors.length === 0) return

              return `${r2.metadata.rawFileName}:${_.join(errors, ',')}`
            })
            .filter()
            .join('\n')
            .value()

          const creativeErrors = assetError.errors.length === 0 ? '' : `${r.name},${assetError.errors.join(',')}\n`

          return creativeErrors + details
        })
        .filter()
        .join('\n')
        .value()

      this.$copyText(errors).then(
        () => this.$message.success('コピーしました'),
        () => this.$message.error('コピーに失敗しました')
      )
    },
    formatAspect(aspect) {
      return !isNaN(aspect?.width) && !isNaN(aspect?.height) ? `${aspect?.width}:${aspect?.height}` : ''
    },
    handleDragStart(e) {
      e.target.querySelector('#add-box').style.display = 'none'
    },
    handleDragEnd(e) {
      e.target.querySelector('#add-box').style.display = 'block'
    },
    findAssetWithAspect(id, aspect) {
      const findAsset = this.calculateAspect.find(item => item.id === id)
      if (findAsset && findAsset.data) {
        const aspectAsset = findAsset.data.find(item => item.aspect === aspect)
        if (aspectAsset) {
          return aspectAsset
        } else {
          return null
        }
      } else return null
    },
    checkCarouselRule(min, max, value) {
      const minRule = !isNaN(min) ? (this.containZip ? true : value >= min) : true
      const maxRule = !isNaN(max) ? (this.isSplitAsset || this.containZip ? true : value <= max) : true
      return minRule && maxRule
    },
  },
  computed: {
    height() {
      return this.innerHeight - 35 - 70
    },
    isSplitAsset() {
      const assetLabels = _.filter(this.assetLabels, 'id')
      if (assetLabels.length === 1) {
        const { maxFileNumber, minFileNumber } = _.first(assetLabels)
        return (maxFileNumber === 1 && minFileNumber === 1) || (maxFileNumber === 1 && minFileNumber === 0)
      } else if (assetLabels.length > 1) {
        const selectedItems = this.distributedSelected.filter(x => x.selected.length > 0)
        if (
          _.every(assetLabels, x => (x.minFileNumber === 0 || x.minFileNumber === undefined) && x.maxFileNumber === 1)
        ) {
          return selectedItems.length === 1
        } else {
          const requiredLabels = assetLabels.filter(x => x.minFileNumber > 0)
          return requiredLabels.length === 1 && selectedItems.filter(x => x.id !== requiredLabels[0].id).length === 0
            ? requiredLabels[0].maxFileNumber === 1
            : false
        }
      } else return false
    },
    adjustmentAssetErrors() {
      if (this.distributedSelected.length === 0 || this.assetErrors.length === 0) return []

      return _.map(this.distributedSelected, r => {
        const assetError = _.find(this.assetErrors, r2 => r2.labelId === r.id)
        if (!assetError) return { errors: [], details: [] }

        const details = _.map(r.selected, r2 =>
          _.chain(assetError.targets)
            .find(r3 => r3.key === r2.originId)
            .result('errors')
            .value()
        )
        return { errors: assetError.errors, details }
      })
    },
    chunkedAsset() {
      return _.chain(this.distributedSelected)
        .map(r => _.chain(r.selected).cloneDeep().chunk(6).value())
        .value()
    },
    containZip() {
      return _.chain(this.distributedSelected)
        .map(r => r.selected)
        .flatten()
        .map(r => r.metadata.extension)
        .value()
        .includes('zip')
    },
    calculateAspect() {
      const selectAspects = _.chain(this.distributedSelected)
        .map(r => {
          const aspect = _.chain(r.selected)
            .map(r2 => (r2.metadata.width / r2.metadata.height).toFixed(2))
            .groupBy()
            .value()
          return {
            id: r.id,
            aspect,
          }
        })
        .value()

      return _.chain(this.distributedSelected)
        .map(r => {
          const selectAspect = _.find(selectAspects, { id: r.id })?.aspect
          return {
            id: r.id,
            data: _.chain(r.aspect)
              .map(aspectRule => {
                const selectAspectLength = _.has(
                  selectAspect,
                  (aspectRule.aspectRatio.width / aspectRule.aspectRatio.height).toFixed(2)
                )
                  ? selectAspect[(aspectRule.aspectRatio.width / aspectRule.aspectRatio.height).toFixed(2)].length
                  : 0
                if (aspectRule.submissionSize) {
                  const min = aspectRule.submissionSize?.min
                  const max = aspectRule.submissionSize?.max
                  if (!_.isUndefined(min) && !_.isUndefined(max)) {
                    return {
                      aspect: this.formatAspect(aspectRule.aspectRatio),
                      count: selectAspectLength,
                      checkRule:
                        selectAspectLength < min
                          ? `の素材は${min}つ以上で選択してください`
                          : selectAspectLength > max
                          ? this.isSplitAsset
                            ? null
                            : `の素材は${max}つ以下で選択してください`
                          : null,
                    }
                  } else if (!_.isUndefined(min) && _.isUndefined(max)) {
                    return {
                      aspect: this.formatAspect(aspectRule.aspectRatio),
                      count: selectAspectLength,
                      checkRule: selectAspectLength < min ? `の素材は${min}つ以上で選択してください` : null,
                    }
                  } else if (_.isUndefined(min) && !_.isUndefined(max)) {
                    return {
                      aspect: this.formatAspect(aspectRule.aspectRatio),
                      count: selectAspectLength,
                      checkRule:
                        selectAspectLength > max
                          ? this.isSplitAsset
                            ? null
                            : `の素材は${max}つ以下で選択してください`
                          : null,
                    }
                  } else {
                    return {
                      aspect: this.formatAspect(aspectRule.aspectRatio),
                      count: selectAspectLength,
                      checkRule: null,
                    }
                  }
                } else {
                  return {
                    aspect: this.formatAspect(aspectRule.aspectRatio),
                    count: selectAspectLength,
                    checkRule: null,
                  }
                }
              })
              .value(),
          }
        })
        .value()
    },
  },
}
</script>
