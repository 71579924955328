<template>
  <div id="select-bar">
    <el-row type="flex" align="middle" class="ps-1">
      <el-col :span="21" class="display-flex">
        <strong class="font-big">
          {{ selected.length === 0 ? ' 選択なし ' : ` ${selected.length}件選択済 ` }}
        </strong>

        <template v-if="isTabsExists" class="ml-1">
          <el-divider direction="vertical" />

          <el-button type="text" class="text-black" @click="$emit('change-selected', true)"> 全件選択 </el-button>

          <el-button type="text" class="text-black" @click="$emit('change-selected', true, true)">
            タブ内全件選択
          </el-button>

          <el-button type="text" class="text-black" :disabled="selected.length === 0" @click="$emit('change-selected')">
            選択解除
          </el-button>
          <el-button type="text" class="text-black" @click="$emit('change-selected', true, true, true)">
            タブ内全件選択解除
          </el-button>
        </template>

        <template
          v-if="
            !['/creative-regist', '/creative-regist-only', '/wall-hitting', '/approval-detail'].includes($route.path)
          "
        >
          <el-divider direction="vertical" />

          <template v-if="$route.path === '/creative-list'">
            <el-button type="text" class="text-black" :disabled="isSttBtnDisabled()" @click="$refs.statusDialog.show()">
              <i class="fa-solid fa-user-group" /> 入稿ステータス変更
            </el-button>
            <el-divider direction="vertical" />
          </template>

          <el-button type="text" class="text-black" :disabled="isSttBtnDisabled()" @click="openChangeStatusDialog">
            <i class="fas fa-user" /> 監修ステータス変更
          </el-button>
          <el-divider direction="vertical" />

          <template v-if="$route.path === '/creative-list'">
            <el-button
              type="text"
              class="text-black"
              :disabled="selected.length === 0"
              @click="$emit('open-snap-dialog')"
            >
              <i class="fas fa-file-excel" /> SNAP出力
            </el-button>
            <el-divider direction="vertical" />
          </template>

          <template v-if="$route.path === '/creative-list'">
            <el-button
              v-if="$route.path === '/creative-list'"
              type="text"
              class="text-black"
              :disabled="selected.length === 0"
              @click="$refs.noteDialog.show()"
            >
              <i class="fas fa-edit" /> 備考編集
            </el-button>
            <el-divider direction="vertical" />
          </template>

          <template v-if="$route.path !== '/creative-list'">
            <el-button
              type="text"
              class="text-black"
              :disabled="selected.length === 0"
              @click="$emit('go-to-creative-list')"
            >
              <i class="fas fa-eye" /> この{{
                $route.path === '/image-movie-master' ? '画像動画' : 'テキスト'
              }}を使っているCRを表示
            </el-button>
            <el-divider direction="vertical" />
          </template>

          <template
            v-if="
              $route.path === '/image-movie-master' ||
              $route.path === '/text-master' ||
              $route.path === '/creative-list'
            "
          >
            <el-button
              type="text"
              class="text-black"
              :disabled="selected.length === 0"
              @click.prevent="$emit('download-selected')"
            >
              <i class="fas fa-download" /> ダウンロード
            </el-button>
            <el-divider direction="vertical" />
          </template>

          <el-button type="text" class="text-black" :disabled="selected.length === 0" @click="$emit('open-tag-dialog')">
            <i class="fas fa-tag" /> タグ
          </el-button>
          <el-divider direction="vertical" />
        </template>

        <template
          v-if="
            $route.path === '/creative-list' || $route.path === '/text-master' || $route.path === '/image-movie-master'
          "
        >
          <el-button type="text" class="text-black" :disabled="selected.length === 0" @click="$emit('delete-selected')">
            <i class="fas fa-trash-alt" /> 削除
          </el-button>
          <el-divider direction="vertical" />
        </template>

        <template v-if="targetType === 'asset' && isCreateCr">
          <el-button type="text" class="text-black" @click="toggleDisplayError">
            <div v-if="this.displayErrorAsset" key="hide-display-error">
              <i class="fas fa-check-circle" /> 規定違反素材非表示
            </div>
            <div v-else key="show-display-error"><i class="far fa-check-circle" /> 規定違反素材表示</div>
          </el-button>
          <el-divider direction="vertical" />
        </template>
      </el-col>

      <el-col :span="3" class="text-right no-margin">
        <div id="drop-down" :style="{ display: 'flex', justifyContent: 'end' }">
          <el-button
            v-if="$route.path === '/creative-list' || $route.path.includes('/approval-detail')"
            type="text"
            :class="isSortTypeOpen ? 'active-button pd' : 'text-black pd'"
            @click="$emit('toggle-sort-type')"
          >
            <i class="el-icon-sort" :style="{ fontWeight: 'bold' }" /> 並べ替え
            <i v-if="!isSortTypeOpen" key="abc" class="el-icon-caret-bottom"></i>
            <i v-if="isSortTypeOpen" key="abc" class="el-icon-caret-top"></i>
          </el-button>

          <el-button
            type="text"
            :class="isSearchOpen ? 'active-button pd' : 'text-black pd'"
            @click="$emit('toggle-search')"
          >
            <i key="search" class="fas fa-search" /> 検索
            <i v-if="!isSearchOpen" key="abc" class="el-icon-caret-bottom"></i>
            <i v-if="isSearchOpen" key="abc" class="el-icon-caret-top"></i>
          </el-button>
        </div>
      </el-col>

      <status-dialog
        ref="statusDialog"
        :selected="selected"
        :target-type="targetType"
        @reload="targetType => $emit('reload', targetType)"
      />

      <note-dialog ref="noteDialog" :selected="selected" @edit-note="v => $emit('edit-note', v)" />
    </el-row>

    <el-row v-if="!isTabOpen" class="ml-2 mr-2">
      <el-divider />
    </el-row>
  </div>
</template>

<style>
#select-bar .el-divider--horizontal {
  margin: 0;
  display: flex;
}
</style>

<style scoped>
.text-black {
  color: #000000;
}
.el-button + .el-button {
  margin-left: 0px;
  padding-left: 10px;
}

.pd {
  padding-left: 5px;
  padding-right: 5px;
}
.mr-1 {
  margin-right: 10px;
}

.ml-1 {
  margin-left: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.ml-2 {
  margin-left: 20px;
}

.ps-1 {
  padding: 0px 20px;
  flex-wrap: wrap;
}

.bg-blue {
  background: #409eff;
  color: #ffffff;
}
.font-big {
  font-size: 20px;
}
.text-right {
  text-align: right;
  float: top;
}
.display-flex {
  height: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 87.5%;
  height: auto;
}
.active-button {
  color: black;
  background: #e0e0e0;
  border-color: #e0e0e0;
  border-radius: 5px 5px 0px 0px;
}
.active-button:hover {
  color: black;
  background: #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 5px 5px 0px 0px;
}
.active-button:focus {
  color: black;
  background: #e0e0e0;
  border-color: #e0e0e0;
  border-radius: 5px 5px 0px 0px;
}
</style>

<script>
import StatusDialog from '@/components/select-bar/components/status-dialog'
import NoteDialog from '@/components/select-bar/components/note-dialog'
import _ from 'lodash'
import { TargetType } from '@/mixins/targetType'
import { CreativeStatus } from '@/mixins/creativeStatus'
import { AssetTextStatus } from '@/mixins/assetTextStatus'

export default {
  name: 'select-bar',
  components: { StatusDialog, NoteDialog },
  props: {
    selected: { type: Array, default: () => [] },
    targetType: { type: String, default: () => '' },
    isTabsExists: { type: Boolean, default: () => false },
    displayType: { type: String, default: () => '' },
    displayErrorAsset: { type: Boolean, default: () => true },
    isSearchOpen: { type: Boolean, default: () => false },
    isSortTypeOpen: { type: Boolean, default: () => false },
  },
  inject: {
    isInCreateCreativeDialog: {
      from: 'isInCreateCreativeDialog',
      default: false,
    },
  },
  data: () => ({
    isContainCRAsset: false,
  }),
  created() {
    window.SelectBar = this
  },
  methods: {
    toggleDisplayError() {
      this.$emit('is-display-error-asset', !this.displayErrorAsset)
    },
    openChangeStatusDialog() {
      if (this.$route.path === '/creative-list') this.$refs.statusDialog.showAssetTextDialog()
      else this.$refs.statusDialog.show()
    },
    isSttBtnDisabled() {
      return this.selected.length === 0 || this.isCreativeHaveNG || this.isCreativeSttDisabled
    },
  },
  computed: {
    isCreateCr() {
      return (
        this.$route.path === '/creative-regist' ||
        this.$route.path === '/creative-regist-only' ||
        this.isInCreateCreativeDialog
      )
    },
    isTabOpen() {
      return this.isSearchOpen || this.isSortTypeOpen
    },
    selectedAssetList() {
      if (this.selected.length > 0 && this.targetType === TargetType.CREATIVE) {
        return _.compact(
          _.uniq(this.selected.flatMap(cr => cr.labeledAssets).flatMap(labeledAsset => labeledAsset.assets))
        )
      }
      return []
    },
    selectedTextSetList() {
      if (this.selected.length > 0 && this.targetType === TargetType.CREATIVE) {
        return _.compact(_.uniq(this.selected.map(cr => cr.textSet)))
      }
      return []
    },
    isCreativeHaveNG() {
      if (this.selected.length > 0 && this.targetType === TargetType.CREATIVE) {
        const isAssetContainNG = this.selectedAssetList.some(asset => asset.approvalStatus === AssetTextStatus.NG)
        const isTextSetContainNG = this.selectedTextSetList.some(text => text.approvalStatus === AssetTextStatus.NG)
        return isAssetContainNG || isTextSetContainNG
      } else return false
    },
    isSelectedHaveNonLatest() {
      if (this.selected.length > 0 && this.targetType === TargetType.CREATIVE) {
        const isSelectedHaveOldAsset = this.selectedAssetList.some(asset => !asset.isLatest)
        const isSelectedHaveOldText = this.selectedTextSetList.some(text => !text.isLatest)
        return isSelectedHaveOldAsset || isSelectedHaveOldText
      }
      return true
    },
    isCreativeSttDisabled() {
      let isDisable = false

      // if creative ,check creative has a status sendToSubmit or submited
      if (this.selected.length > 0 && this.targetType === TargetType.CREATIVE) {
        const creativeStatuses = _.compact(_.uniq(this.selected.map(x => x.approvalStatus)))
        isDisable = creativeStatuses.some(stt => CreativeStatus.NO_EDIT_CR.includes(stt))
      }
      return isDisable
    },
  },
}
</script>
