<template>
  <el-dialog
    id="proposed-dialog"
    title="テキスト修正案"
    fullscreen
    lock-scroll
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div v-loading="loading" class="p-2">
      <div v-if="this.chunkedProposals.length !== 0" class="scroll" :style="{ height: height + 'px' }">
        <div class="f-r new-line">
          ＊テキスト修正案を選択することによって、テキストが更新されます。1つの修正案しか選択できません。
        </div>
        <el-row v-for="(proposals, i) in chunkedProposals" :key="i">
          <el-col class="p-1" v-for="(proposal, j) in proposals" :key="j">
            <el-row class="center" type="flex" justify="center">
              <el-col :span="1" class="center"><el-radio v-model="radioLabel" :label="i * 5 + j"></el-radio></el-col>
              <el-col :span="23"><proposed-card :proposal="proposal" /></el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" :disabled="radioLabel === null" @click="edit">適用</el-button>
      <el-button type="info" @click="closeDialog"> キャンセル </el-button>
    </span>
  </el-dialog>
</template>

<style scoped>
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 20px 10px;
}
.maxh-800px {
  max-height: 800px;
}
.f-r {
  color: red;
}
.scroll {
  overflow-y: scroll !important;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
.text-center {
  text-align: center;
}
.center {
  align-items: center;
}
.font-big {
  font-size: 20px;
}
</style>

<style>
#proposed-dialog .el-radio__inner {
  width: 24px !important;
  height: 24px !important;
  margin-left: 5px;
  border: 2px solid #dcdfe6;
}

#proposed-dialog .el-radio__inner::after {
  width: 7px !important;
  height: 7px !important;
}

#proposed-dialog .el-radio__label {
  display: none;
}

.new-line {
  word-break: break-word !important;
  white-space: pre-wrap !important;
}
.scrollable .el-message-box__content {
  overflow-y: scroll;
  max-height: 500px;
}
</style>

<script>
import _ from 'lodash'
import ProposedCard from '@/views/text-master/ad-format-tab/proposed-text-dialog/component/proposed-text-card.vue'

export default {
  name: 'proposed-text-dialog',
  components: { ProposedCard },
  props: {
    adFormatIdParent: { type: String, default: () => '' },
    adFormat: { type: Object, default: () => {} },
  },
  data: () => ({
    loading: false,
    isShow: false,
    proposedText: [],
    textSetId: '',
    radioLabel: null,
    adFormatId: '',
    height: window.innerHeight - 54 - 41 - 70,
  }),
  created() {
    window.ProposedTextDialog = this
  },
  methods: {
    async show(textSetId, adFormatIdCreative = '') {
      this.isShow = true
      this.textSetId = textSetId
      this.adFormatId = this.adFormatIdParent ? this.adFormatIdParent : adFormatIdCreative
      this.loading = true
      this.proposedText = _.chain(
        await this.$api.authFetch(`/proposed_text/${this.textSetId || ''}`, { method: 'GET' })
      )
        .result('groupedProposedText')
        .flattenDeep()
        .value()
      this.loading = false
    },
    closeDialog() {
      this.radioLabel = null
      this.isShow = false
    },
    async edit() {
      const labeledTexts = JSON.parse(JSON.stringify(this.proposedText[this.radioLabel])).labeledTexts
      const form = {
        adFormatId: this.adFormatId,
        textSetId: this.textSetId,
        score: '',
        labeledTexts: labeledTexts.map(label => _.omit(label, ['labelName', 'labelIndex'])),
      }

      this.loading = true
      const result = await this.$api.authFetch('/text_set', { method: 'PUT', body: form })
      this.loading = false

      if (!result) {
        this.$message.error('編集失敗')
        return
      }

      if (result && result.errors) {
        let adformatErrors = result.errors.map(adformatError => {
          const labelNames = adformatError.labelNames.join(', ')
          const errorMess = adformatError.error
          return labelNames.concat(': ', errorMess)
        })

        let messages = _.map(result.labels, r => {
          const errLabel = '<div><ul>' + r.errors.map(r => `<li>${r}</li>`).join('') + '</ul></div>'
          let textData = {}
          const errorResponse = _.find(labeledTexts, r2 => r.labelId === r2.labelId)
          if (!_.isUndefined(errorResponse)) {
            textData = errorResponse
          } else {
            const unselectedLabel = _.chain(this.adFormat?.textLabels)
              .find(r2 => r.labelId === r2.id)
              .value()
            textData = !_.isEmpty(unselectedLabel)
              ? { labelName: unselectedLabel.name, textValues: [] }
              : { labelName: '', textValues: [] }
          }
          const { labelName, textValues } = textData
          const detail =
            '<ul>' +
            r.targets
              .map(target => {
                const errorTextValue = target.key.substring(0, target.key.lastIndexOf('_'))
                const index = textValues.indexOf(errorTextValue)
                const key = `${index + 1}番目`
                return target.errors
                  .map(v => {
                    if (v.includes('商材NGワードとして設定されています。')) {
                      const error = v.substring(0, v.indexOf('\\n'))
                      return `<li>${error.replace(labelName, key)}</li>`
                    } else {
                      return `<li>${v.replace(labelName, key)}</li>`
                    }
                  })
                  .join('')
              })
              .sort()
              .join('') +
            '</ul>'
          return `${labelName}${r.errors.length > 0 ? errLabel : ''} ${detail}`
        })
        messages = adformatErrors.concat(messages)
        if (!messages.every(x => !x.includes('商材NGワードとして設定されています。'))) {
          const link = `${this.$api.getRegulationDomain()}/advertisers`
          const NGalert =
            '<div>NGワードに変更がある場合は<a href=' +
            link +
            ' target="_blank">こちら</a>からご確認お願いいたします。</div>'
          messages.unshift(NGalert)
        }
        this.$alert(messages.join('<br>'), 'レギュレーションエラー', {
          dangerouslyUseHTMLString: true,
          customClass: 'scrollable',
        })
        return
      }

      if (result && result.error) {
        if (result.code === 400)
          this.$message({
            message: '検索キャンペーンの広告グループ以外を指定することはできません',
            type: 'error',
          })
        else this.$message({ message: result.message || result.error, type: 'error' })
        return
      }

      this.$message.success('編集成功')
      if (this.$route.path === '/creative-list') {
        this.$emit('get-creatives')
      } else if (this.$route.path === '/text-master') {
        this.$emit('get-text-sets')
      }

      this.isShow = false
    },
  },
  computed: {
    chunkedProposals() {
      return _.chain(this.proposedText).chunk(5).value()
    },
  },
}
</script>
