<template>
  <el-dialog
    id="select-asset-specs-dialog"
    top="81px"
    width="300px"
    :visible.sync="isShow"
    :modal="false"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div v-loading="loading" class="p-2">
      <el-input v-model="filterWord" class="mb-2" suffix-icon="fas fa-search" />

      <el-checkbox-group class="h-300px scroll" v-model="selected">
        <el-checkbox class="p-1 w-100" :label="musicFilePixel"> 音楽 ({{ this.musicFileCount }}件) </el-checkbox>
        <el-checkbox class="p-1 w-100" :label="zipFilePixel"> zip ({{ this.zipFileCount }}件) </el-checkbox>
        <el-checkbox class="p-1 w-100" :label="youtubeUrlPixel">
          Youtube動画 ({{ this.youtubeUrlCount }}件)
        </el-checkbox>
        <el-checkbox class="p-1 w-100" v-for="(r, i) in filteredAssetSpecs" :key="i" :label="r.pixelSize">
          {{ r.pixelSize.width }}×{{ r.pixelSize.height }} ({{ r.number }}件)
        </el-checkbox>
      </el-checkbox-group>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="commit"> 適用 </el-button>
    </span>
  </el-dialog>
</template>

<style>
#select-asset-specs-dialog .el-dialog {
  margin-left: 80px;
}
#select-asset-specs-dialog .el-dialog__header {
  padding: 0;
}
</style>

<style scoped>
.w-100 {
  width: 100%;
}
.h-300px {
  height: 300px;
}
.p-1 {
  padding: 0.3rem 0;
}
.p-2 {
  padding: 20px;
}
.mb-2 {
  margin-bottom: 1rem;
}
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
</style>

<script>
import _ from 'lodash'

export default {
  name: 'select-asset-specs-dialog',
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    assetSpecs: { type: Array, default: () => [] },
    zipFileCount: { type: Number, default: () => 0 },
    youtubeUrlCount: { type: Number, default: () => 0 },
    musicFileCount: { type: Number, default: () => 0 },
  },
  data: () => ({
    isShow: false,
    loading: false,
    filterWord: '',
    selected: [],
    zipFilePixel: { width: 0, height: 1 },
    youtubeUrlPixel: { width: 0, height: 2 },
    musicFilePixel: { width: 0, height: 3 },
  }),
  created() {
    window.SelectAssetSpecsDialog = this
  },
  methods: {
    show() {
      this.selected = []
      this.isShow = true
    },
    commit() {
      this.isShow = false
      this.$emit('add-asset-specs', this.selected)
    },
  },
  computed: {
    filteredAssetSpecs() {
      const lowerFilterWord = _.toLower(this.filterWord)
      return _.filter(this.assetSpecs, r =>
        _.includes(_.toLower(`${r.pixelSize.width}×${r.pixelSize.height}`), lowerFilterWord)
      )
    },
  },
}
</script>
