<template>
  <div id="normal-creatives">
    <div v-for="(r, i) in groupBySelected" :key="i">
      <div class="ps-1">
        <el-divider content-position="left"> {{ r.platformId }} | {{ r.adFormatName }} </el-divider>
      </div>

      <ad-format-tab-list
        :adFormats="adFormats"
        :selected="r.selected"
        :ad-format-id="r.adFormatId"
        :platform-id="r.platformId"
        :creatives="r.selected"
        :is-in-dialog="true"
        display-type="approvalRequest"
        @change-selected-at-tab="obj => $emit('change-selected-at-tab', obj)"
        @change-creator-comment="
          (comment, creativeId, platformId) => $emit('change-creator-comment', comment, creativeId, platformId)
        "
        @change-note="(note, creativeId, platformId) => $emit('change-note', note, creativeId, platformId)"
        @change-reference-url="
          (comment, creativeId, platformId) => $emit('change-reference-url', comment, creativeId, platformId)
        "
      />
    </div>
  </div>
</template>

<style scoped>
.p-1 {
  padding: 1rem;
}
.ps-1 {
  padding: 0 1rem;
}
</style>

<script>
import _ from 'lodash'
import AdFormatTabList from '@/views/creative-list/ad-format-tab-list'

export default {
  name: 'normal-creatives',
  components: { AdFormatTabList },
  props: {
    selected: { type: Array, default: () => [] },
    groupedCreatives: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
    assetGroupData: { type: Array, default: () => [] },
    assetGroupAttachFiles: { type: Array, default: () => [] },
  },
  async created() {
    window.NormalCreatives = this
  },
  methods: {
    log(v) {
      console.log(v)
    },
  },
  computed: {
    groupBySelected() {
      return _.chain(this.selected)
        .groupBy('adFormatId')
        .map(gr => {
          const {
            adFormat: { id: adFormatId, name: adFormatName },
            platformId,
          } = _.first(gr)
          const selectedCreativeIds = _.map(gr, 'creativeId')
          const platformName = this.groupedCreatives[0].plaformName
          const creatives = _.chain(this.groupedCreatives)
            .map('creatives')
            .flattenDeep()
            .filter(r => _.includes(selectedCreativeIds, r.creativeId))
            .value()

          return { platformId, platformName, adFormatId, adFormatName, selected: gr, creatives }
        })
        .value()
    },
  },
}
</script>
