<template>
  <div id="normal-creatives">
    <div class="" v-if="groupBySelected.length == 0">絞り込み条件に該当するデータがありません。</div>
    <div v-else v-for="(r, i) in groupBySelected" :key="i">
      <div class="ps-1">
        <el-divider content-position="left"> {{ r.platformId }} | {{ r.adFormatName }} </el-divider>
      </div>

      <ad-format-tab-list
        ref="adFormatTab"
        :adFormats="adFormats"
        :selected="r.selected"
        :ad-format-id="r.adFormatId"
        :platform-id="r.platformId"
        :creatives="r.selected"
        :adFormatName="r.adFormatName"
        :is-in-dialog="true"
        :selected-creative-ids="selectedCreativeIds"
        :asset-regulate-error="assetRegulateError"
        @change-selected-creative-ids="arr => $emit('change-selected-creative-ids', arr)"
        @remove-creative="id => $emit('remove-creative', id)"
        @change-selected-at-tab="obj => $emit('change-selected-at-tab', obj)"
        @change-creator-comment="
          (comment, creativeId, platformId) => $emit('change-creator-comment', comment, creativeId, platformId)
        "
        @change-note="(note, creativeId, platformId) => $emit('change-note', note, creativeId, platformId)"
        @change-reference-url="
          (urls, creativeId, platformId) => $emit('change-reference-url', urls, creativeId, platformId)
        "
        @change-status-text="(textSetId, status, version) => $emit('change-status-text', textSetId, status, version)"
        @change-status-asset="(assetId, status) => $emit('change-status-asset', assetId, status)"
        @change-status-creative="(creativeId, status) => $emit('change-status-creative', creativeId, status)"
        @update-text="(form, textSet, isEqual, version) => $emit('update-text', textSet, form, isEqual, version)"
        @update-ver-asset="
          (file, assetId, skipCheckRule, originId, allOriginIds, labelId) =>
            $emit('update-ver-asset', file, assetId, skipCheckRule, originId, allOriginIds, labelId)
        "
      />
    </div>
  </div>
</template>

<style scoped>
.p-1 {
  padding: 1rem;
}
.ps-1 {
  padding: 0 1rem;
}
</style>

<script>
import _ from 'lodash'
import AdFormatTabList from '@/views/approval-detail/components/ad-format-tab/index'

export default {
  name: 'normal-creative',
  components: { AdFormatTabList },
  props: {
    selected: { type: Array, default: () => [] },
    groupedCreatives: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
    assetGroupData: { type: Array, default: () => [] },
    assetGroupAttachFiles: { type: Array, default: () => [] },
    selectedCreativeIds: { type: Array, default: () => [] },
    assetRegulateError: { type: Array, default: () => [] },
  },
  async created() {
    window.NormalCreatives = this
  },
  computed: {
    groupBySelected() {
      return _.chain(this.selected)
        .groupBy('adFormatId')
        .map(gr => {
          const {
            adFormat: { id: adFormatId, name: adFormatName },
            platformId,
          } = _.first(gr)
          const selectedCreativeIds = _.map(gr, 'creativeId')
          const platformName = this.groupedCreatives[0].plaformName
          const creatives = _.chain(this.groupedCreatives)
            .map('creatives')
            .flattenDeep()
            .filter(r => _.includes(selectedCreativeIds, r.creativeId))
            .value()

          return { platformId, platformName, adFormatId, adFormatName, selected: gr, creatives }
        })
        .value()
    },
  },
}
</script>
