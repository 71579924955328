<template>
  <div id="creative-card">
    <el-card
      :shadow="selectedCreativeId === creative.creativeId ? 'always' : 'never'"
      :class="{ select: selectedCreativeId === creative.creativeId }"
      :style="{ backgroundColor: !isCrCanEdit && !isInDialog ? 'rgba(229, 229, 229, 0.8745098039)' : '' }"
      class="font-mini w-100"
    >
      <el-row type="flex" class="m-1 mb-0.5">
        <menu-area
          v-if="!isInDialog"
          class="mr-1"
          :creative-id="creative.creativeId"
          :creative-ids="creativeIds"
          :submission-status="creative.submissionStatus"
          :approval-status="creative.approvalStatus"
          :creativeType="creative.creativeType"
          @get-creatives="$emit('get-creatives')"
          @change-query="q => $emit('change-query', q)"
        />
        <el-radio
          v-else-if="isInDialog && $route.path === '/creative-regist'"
          :value="selectedCreativeId"
          :label="creative.creativeId"
        />

        <div
          class="w-100"
          @click="
            isInDialog && $route.path === '/creative-regist'
              ? $emit('change-selected-creative-id', creative.creativeId)
              : null
          "
        >
          <main-area
            class="w-100"
            :platform-id="platformId"
            :ad-format-name="adFormatName"
            :creative="creative"
            :is-in-dialog="isInDialog"
            :is-show="isShow"
            :is-update="isUpdate"
            :display-type="displayType"
            :is-creative-list="isCreativeList"
            :is-cr-can-edit="isCrCanEdit"
            @get-creatives="$emit('get-creatives')"
            @update-creative="(urls, comment) => $emit('update-creative', urls, comment)"
            @change-note="
              note => {
                !isInDialog && debounceUpdateCreative()
                $emit('change-note', note)
              }
            "
            @change-creator-comment="v => $emit('change-creator-comment', v)"
            @change-reference-url="v => $emit('change-reference-url', v)"
            @edit-note-creatives="(note, creatives) => $emit('edit-note-creatives', note, creatives)"
            @open-proposed-dialog="(textSetId, adFormatId) => $emit('open-proposed-dialog', textSetId, adFormatId)"
            @change-status-text="(textSetId, status) => $emit('change-status-text', textSetId, status)"
            @change-status-asset="(assetId, status) => $emit('change-status-asset', assetId, status)"
            @change-status-creative="(creativeId, status) => $emit('change-status-creative', creativeId, status)"
          />
        </div>
      </el-row>
      <div style="margin-right: 0.5rem; margin-bottom: 0.5rem">
        <reference-url-area
          class="reference-url"
          :reference-urls="creative.referenceUrls"
          :is-editable="isCrCanEdit && displayType !== 'submissionRequest'"
          :is-openable="displayType === 'submissionRequest' || !isCrCanEdit"
          @auto-update-creative="!isInDialog && debounceUpdateCreative()"
          @update-reference-urls="urls => $emit('change-reference-url', urls)"
        />
      </div>
    </el-card>
  </div>
</template>

<style>
#creative-card .el-radio__label {
  display: none;
}
#creative-card .el-radio {
  margin-right: 0.5rem;
}
#creative-card .el-card__body {
  padding: 0px;
}
#creative-card .el-card.is-always-shadow {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 50%);
}
</style>

<style scoped>
.divider-css {
  height: 1px;
  margin: 0 1rem 1rem 1rem;
}
.float-left {
  float: left;
}
.font-mini {
  font-size: 12px;
}
.w-5 {
  width: 5%;
}
.w-95 {
  width: 95%;
}
.w-100 {
  width: 100%;
}
.m-1 {
  margin: 1rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
</style>

<script>
import MenuArea from '@/views/creative-list/ad-format-tab-list/creative-card/components/menu-area'
import MainArea from '@/views/creative-list/ad-format-tab-list/creative-card/main-area'
import ReferenceUrlArea from '@/components/reference-url-area.vue'
import _ from 'lodash'
import util from '@/mixins/util'
import { CreativeStatus } from '@/mixins/creativeStatus'

export default {
  name: 'creative-card',
  components: { ReferenceUrlArea, MenuArea, MainArea },
  mixins: [util],
  props: {
    platformId: { type: String, default: () => '' },
    adFormatName: { type: String, default: () => '' },
    creative: { type: Object, default: () => ({}) },
    isInDialog: { type: Boolean, default: () => false },
    isCreativeList: { type: Boolean, default: () => false },
    isUpdate: { type: Boolean, default: () => false },
    selectedCreativeId: { type: String, default: () => '' },
    isShow: { type: Boolean, default: () => false },
    creativeIds: { type: Array, default: () => [] },
    displayType: { type: String, default: () => '' },
  },
  created() {
    window.CreativeCard = this
  },
  methods: {
    debounceUpdateCreative: _.debounce(async function () {
      await this.updateCreative()
    }, 1500),

    async updateCreative() {
      const labeledAssets = _.map(this.creative.labeledAssets, labeledAsset => ({
        labelId: labeledAsset.labelId,
        originIds: _.map(labeledAsset.assets, 'originId'),
      }))
      const referenceUrls = _.result(this.creative, 'referenceUrls')
        .filter(url => url.trim() !== '')
        .map(url => url.replace(/\n+$/, ''))
      const isValid = util.methods.isValidReferenceUrls.call(this, referenceUrls)
      if (isValid) {
        const creatives = [
          {
            creativeId: _.result(this.creative, 'creativeId') || '',
            adFormatId: _.result(this.creative, 'adFormat.id') || '',
            textSetId: _.result(this.creative, 'textSet.textSetId') || '',
            labeledAssets,
            score: _.result(this.creative, 'score') || null,
            deviationScore: _.result(this.creative, 'deviationScore') || null,
            deviationRank: _.result(this.creative, 'deviationRank') || null,
            note: _.result(this.creative, 'note') || '',
            creatorComment: _.result(this.creative, 'creatorComment') || null,
            referenceUrls,
          },
        ]
        this.$emit('edit-note-creatives', this.creative.note, creatives)
      }
    },
  },
  computed: {
    isCrCanEdit() {
      return !CreativeStatus.NO_EDIT_CR.includes(this.creative.approvalStatus)
    },
  },
}
</script>
