<template>
  <div id="menu-area" v-loading="loading" class="text-center font-initial">
    <div class="mb-1">
      <!--      disable when Creative have status Submitted, SendSubmitRequest and NG in Approval Request Detail Screen -->
      <el-checkbox :disabled="$route.path == '/approval-detail' && isCRStatusNotCreateAR()" :label="creativeId" />
    </div>

    <div v-if="creativeType !== 'Asset'" class="mb-1" @click="goToCreativeRegist('copy')">
      <i class="fas fa-copy" />
    </div>

    <div v-if="isCrCanEdit() && creativeType !== 'Asset'" @click="goToCreativeRegist('edit')" class="mb-1">
      <i class="fas fa-edit" />
    </div>

    <div @click="removeCreative(creativeId)">
      <i class="fas fa-trash-alt" />
    </div>
  </div>
</template>

<style>
#menu-area .el-checkbox__label {
  display: none;
}
</style>

<style scoped>
.text-center {
  text-align: center;
}
.font-initial {
  font-size: initial;
}
.ml-1 {
  margin-left: 1rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
</style>

<script>
import _ from 'lodash'
import { CreativeStatus } from '@/mixins/creativeStatus'

export default {
  name: 'menu-area',
  props: {
    creativeId: { type: String, default: () => '' },
    creativeIds: { type: Array, default: () => [] },
    submissionStatus: { type: String, default: () => '' },
    approvalStatus: { type: String, default: () => '' },
    creativeType: { type: String, default: () => '' },
  },
  data: () => ({ loading: false }),
  created() {
    window.MenuArea = this
  },
  methods: {
    goToCreativeRegist(type) {
      const newQuery = _.assign({}, _.omit(this.$route.query, ['submissionRequestIds']), {
        type,
        creativeId: this.creativeId,
      })
      this.$router.push({ path: '/creative-regist', query: newQuery })
    },

    isCRStatusNotCreateAR() {
      return CreativeStatus.NO_CREATE_AR.includes(this.approvalStatus)
    },

    isCrCanEdit() {
      return !CreativeStatus.NO_EDIT_CR.includes(this.approvalStatus)
    },

    isCRCanRemove() {
      return !CreativeStatus.NO_DELETE_CR.includes(this.approvalStatus)
    },

    async removeCreative(creativeId) {
      const ret = await this.$confirm('クリエイティブを削除しますか?', '確認').catch(e => e)
      if (ret === 'cancel') return
      if (!this.isCRCanRemove()) {
        let message = ''
        switch (this.approvalStatus) {
          case CreativeStatus.SUBMITTED:
            message = '入稿済のクリエイティブなので、削除できません。'
            break
          case CreativeStatus.SEND_REQUEST_SUBMIT:
            message = '入稿依頼済みのクリエイティブなので、削除できません。'
            break
          case CreativeStatus.CHECKING:
            message = '承認待ちのクリエイティブがありますので、削除できません。'
            break
          default:
            break
        }
        this.$confirm(message, '確認', {
          showCancelButton: false,
        }).catch(e => e)
      } else {
        this.loading = true
        await this.$api.authFetch('/creative', {
          method: 'DELETE',
          query: { creativeId: [creativeId] },
        })
        this.loading = false
        const creativeIds = this.creativeIds.length === 0 ? [] : _.filter(this.creativeIds, v => v !== creativeId)
        if (creativeIds.length !== 0) this.$emit('change-query', { creativeIds })
        this.$emit('get-creatives')
      }
    },
  },
}
</script>
