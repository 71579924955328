<template>
  <el-dialog
    id="select-ad-format-dialog"
    top="81px"
    width="50%"
    :visible.sync="isShow"
    :modal="false"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-row class="p-1" :gutter="20">
      <el-col :span="5" style="padding: 0 10px">
        <el-input v-model="platformWord" class="mb-2" suffix-icon="fas fa-search" />

        <el-checkbox-group class="h-300px scroll" v-model="selectedPlatformIds" @change="changeSelectedAdFormatIds">
          <el-checkbox class="ps-1 w-100" v-for="(r, i) in filteredPlatforms" :key="i" :label="r.platformId">
            {{ r.platformName }}
          </el-checkbox>
        </el-checkbox-group>
      </el-col>

      <el-col :span="19" style="padding: 0; padding-right: 10px" v-if="selectedPlatformIds.length !== 0">
        <el-input v-model="adFormatWord" class="mb-2" suffix-icon="fas fa-search" />

        <el-checkbox-group class="h-300px scroll" v-model="selectedAdFormatIds">
          <el-checkbox class="ps-1 w-100" v-for="(r, i) in filteredAdFormats" :key="i" :label="r.id" style="margin: 0">
            {{ r.platformName }} {{ r.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="commit"> 適用 </el-button>
    </span>
  </el-dialog>
</template>

<style>
#select-ad-format-dialog .el-dialog {
  margin-left: 80px;
}
#select-ad-format-dialog .el-dialog__header {
  padding: 0;
}
</style>

<style scoped>
.w-100 {
  width: 100%;
}
.h-300px {
  height: 300px;
}
.p-1 {
  padding: 1rem;
}
.ps-1 {
  padding: 0.3rem 0;
}
.mb-2 {
  margin-bottom: 1rem;
}
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
</style>

<script>
import _ from 'lodash'

export default {
  name: 'select-ad-format-dialog',
  props: {
    adFormats: { type: Array, default: () => [] },
  },
  data: () => ({
    isShow: false,
    platformWord: '',
    adFormatWord: '',
    selectedPlatformIds: [],
    selectedAdFormatIds: [],
  }),
  created() {
    window.SelectAdformatDialog = this
  },
  methods: {
    show() {
      this.selectedPlatformIds = []
      this.selectedAdFormatIds = []
      this.isShow = true
    },
    commit() {
      this.isShow = false
      this.$emit('add-ad-format', this.selectedAdFormatIds)
    },
    changeSelectedAdFormatIds() {
      this.selectedAdFormatIds = _.chain(this.viewAdFormats)
        .filter(r => _.includes(this.selectedPlatformIds, r.platformId) && _.includes(this.selectedAdFormatIds, r.id))
        .map('id')
        .value()
    },
  },
  computed: {
    viewAdFormats() {
      return this.$route.path === '/creative-list' || this.$route.path === '/approval-detail'
        ? this.adFormats
        : _.filter(this.adFormats, r => r.textLabels.length !== 0)
    },
    filteredPlatforms() {
      const lowerPlatformWord = _.toLower(this.platformWord)
      return _.chain(this.viewAdFormats)
        .uniqBy('platformId')
        .filter(
          r => _.toLower(r.platformId).match(lowerPlatformWord) || _.toLower(r.platformName).match(lowerPlatformWord)
        )
        .orderBy('platformId')
        .value()
    },
    filteredAdFormats() {
      const lowerAdFormatWord = _.toLower(this.adFormatWord)
      return _.chain(this.viewAdFormats)
        .filter(
          r =>
            (this.selectedPlatformIds.length === 0 ? false : _.includes(this.selectedPlatformIds, r.platformId)) &&
            (lowerAdFormatWord ? false : _.includes(_.toLower(r.name), lowerAdFormatWord))
        )
        .orderBy(['platformId', 'name'])
        .value()
    },
  },
}
</script>
