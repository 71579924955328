var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"id":"text-master"}},[_c('el-col',{attrs:{"span":24}},[_c('select-bar',{attrs:{"selected":_vm.selected,"is-tabs-exists":_vm.tabs.length !== 0,"display-type":_vm.displayType,"is-search-open":_vm.isDrawerShow,"target-type":"textSet"},on:{"reload":function (d) { return _vm.getTextSets(); },"go-to-creative-list":_vm.goToCreativeList,"change-selected":_vm.changeSelected,"open-tag-dialog":_vm.openTagDialog,"open-approval-request-dialog":function($event){return _vm.$refs.approvalRequestDialog.show('textSet')},"toggle-search":_vm.toggleSearch,"toggle-display-type":_vm.toggleDisplayType,"delete-selected":_vm.deleteSelected,"download-selected":function (e) { return _vm.downloadFile(e); }}}),_c('el-collapse-transition',[_c('drawer-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDrawerShow),expression:"isDrawerShow"}],ref:"drawer",attrs:{"tags":_vm.tags,"selected-promotion-ids":_vm.selectedPromotionIds},on:{"search-text-sets":_vm.getTextSets}})],1),(
        _vm.isNotCreateCreative ||
        ((_vm.$route.path === _vm.appRoute.CREATIVE_REGIST || _vm.isInCreateCreativeDialog) && _vm.tabs.length !== 0)
      )?_c('el-main',[_c('el-tabs',{attrs:{"type":"border-card"},on:{"tab-remove":function (name) { return _vm.removeTab(name, true); },"tab-click":_vm.clickTab},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[(_vm.isNotCreateCreative)?_c('el-tab-pane',{staticClass:"p-15px",attrs:{"name":"add","label":"add"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('i',{staticClass:"fas fa-plus-circle"})]),_c('div',{style:({ height: _vm.tabHeight + 'px' })},[_c('div',[_vm._v("【スタート方法】")]),_c('ul',[_c('li',[_vm._v("TEXT 新規追加: 画面右下の「テキスト新規作成」ボタン")]),_c('li',[_vm._v(" 広告フォーマットを指定して表示: "),_c('i',{staticClass:"fas fa-plus-circle"}),_vm._v(" ボタンを押して媒体･広告フォーマットを選択 ")]),_c('li',[_vm._v("詳細検索: サイドバーの検索ボタン（検索条件無しで「検索」を押すと全件表示）")])])])]):_vm._e(),_vm._l((_vm.tabs),function(r,j){return _c('el-tab-pane',{key:j,attrs:{"id":"inner-tabs","name":r.platformId,"label":r.platformId,"closable":_vm.isNotCreateCreative,"lazy":""}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('i',{staticClass:"fas fa-home"}),_vm._v(" "+_vm._s(r.platformId)+" ")]),_c('el-tabs',{attrs:{"type":"border-card"},on:{"tab-remove":function (name) { return _vm.removeTab(name, false, r.platformId); }},model:{value:(r.activeName),callback:function ($$v) {_vm.$set(r, "activeName", $$v)},expression:"r.activeName"}},_vm._l((r.textSets),function(ref,i){
      var name = ref.name;
      var adFormatId = ref.adFormatId;
      var platformId = ref.platformId;
return _c('el-tab-pane',{key:i,attrs:{"name":name,"label":name,"closable":_vm.isNotCreateCreative,"lazy":""}},[_c('ad-format-tab',{attrs:{"display-type":"default","isSmallTag":true,"isShowVersionAndStatus":true,"selected":_vm.selected,"selected-promotion-ids":_vm.selectedPromotionIds,"platform-id":platformId,"ad-format-id":adFormatId,"ad-format-name":name,"ad-formats":_vm.adFormats,"text-sets":_vm.groupByTextSets[adFormatId],"height":_vm.tabHeight},on:{"change-selected-at-tab":_vm.changeSelectedAtTab,"get-text-sets":_vm.getTextSets,"change-creator-comment":_vm.changeCreatorComment}})],1)}),1)],1)})],2),(_vm.isNotCreateCreative)?_c('el-button',{staticClass:"create-new",on:{"click":function($event){return _vm.$refs.registTextSetDialog.show()}}},[_c('i',{staticClass:"far fa-file-alt",staticStyle:{"font-size":"larger"}}),_vm._v(" テキスト新規作成 ")]):_vm._e()],1):_vm._e()],1),(_vm.isNotCreateCreative)?_c('select-ad-format-dialog',{ref:"selectAdFormatDialog",attrs:{"ad-formats":_vm.adFormats},on:{"add-ad-format":_vm.addAdFormat}}):_vm._e(),_c('approval-request-dialog',{ref:"approvalRequestDialog",attrs:{"selected-promotion-ids":_vm.selectedPromotionIds,"selected":_vm.selected,"items":_vm.groupedTextSets},on:{"change-selected-at-tab":_vm.changeSelectedAtTab,"reload":_vm.getTextSets,"open-complete-dialog":function (url, type) { return _vm.$refs.completeDialog.show(url, type); },"change-creator-comment":_vm.changeCreatorComment}}),_c('complete-dialog',{ref:"completeDialog"}),(_vm.isNotCreateCreative)?_c('regist-text-set-dialog',{ref:"registTextSetDialog",attrs:{"default-promotion-id":_vm.selectedPromotionIds[0],"ad-formats":_vm.adFormats,"tags":_vm.tags},on:{"add-ad-format":_vm.addAdFormat}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }