<template>
  <el-row id="version-status-item" :gutter="10">
    <!-- asset -->
    <div
      class="d-flex"
      style="flex-direction: column"
      v-if="
        [
          appRoute.CREATIVE_REGIST,
          appRoute.CREATIVE_REGIST_ONLY,
          appRoute.CREATIVE_LIST,
          appRoute.IMAGE_MOVIE_MASTER,
          appRoute.APPROVAL_DETAIL,
        ].includes($route.path) && targetType === 'asset'
      "
    >
      <!-- version -->
      <el-tooltip placement="top-start" popper-class="metadata-tooltip" :open-delay="500" content="バージョン">
        <el-tag type="info" class="w-fit text-center font-bold" size="mini" @click="openVersionDialog">
          v.{{ version }}
        </el-tag>
      </el-tooltip>

      <!-- aspect -->
      <el-tooltip
        placement="top-start"
        popper-class="metadata-tooltip"
        :open-delay="500"
        content="アスペクト比"
        v-if="metadata.aspectRatio && metadata.aspectRatio !== '0:3'"
      >
        <div
          class=""
          style="margin-top: 4px"
          v-bind:style="[errorList.includes('aspect') ? { color: 'var(--danger-color)' } : { color: '#909399' }]"
        >
          <i class="el-icon-full-screen font-bold"></i>
          <span class="metadata-text">
            {{ metadata.aspectRatio }}
          </span>
        </div>
      </el-tooltip>

      <!-- pixel -->
      <el-tooltip
        placement="top-start"
        popper-class="metadata-tooltip"
        :open-delay="500"
        content="ピクセルサイズ"
        v-if="metadata.width && metadata.height"
      >
        <div
          class=""
          style="margin-top: 4px"
          v-bind:style="[errorList.includes('pixel') ? { color: 'var(--danger-color)' } : { color: '#909399' }]"
        >
          <i class="fa-solid fa-image"></i>
          <span class="metadata-text"> {{ metadata.width }}x{{ metadata.height }} </span>
        </div>
      </el-tooltip>

      <!-- filesize -->
      <el-tooltip
        placement="top-start"
        popper-class="metadata-tooltip"
        :open-delay="500"
        content="容量"
        v-if="metadata.fileSize"
      >
        <div
          class=""
          style="margin-top: 4px"
          v-bind:style="[errorList.includes('fileSize') ? { color: 'var(--danger-color)' } : { color: '#909399' }]"
        >
          <i class="fa-regular fa-file"></i>
          <span class="metadata-text">
            {{ toRound(fileSize) }}
          </span>
        </div>
      </el-tooltip>

      <!-- file name size -->
      <el-tooltip
        placement="top-start"
        popper-class="metadata-tooltip"
        :open-delay="500"
        content="ファイル名文字数"
        v-if="metadata.fileName"
      >
        <div
          class=""
          style="margin-top: 4px"
          v-bind:style="[errorList.includes('fileName') ? { color: 'var(--danger-color)' } : { color: '#909399' }]"
        >
          <i class="el-icon-edit font-bold"></i>
          <span class="metadata-text"> {{ String(metadata.fileName).length }} 字 </span>
        </div>
      </el-tooltip>

      <!-- duration -->
      <el-tooltip
        placement="top-start"
        popper-class="metadata-tooltip"
        :open-delay="500"
        content="秒数"
        v-if="metadata.duration"
      >
        <div
          class=""
          style="margin-top: 4px"
          v-bind:style="[errorList.includes('duration') ? { color: 'var(--danger-color)' } : { color: '#909399' }]"
        >
          <i class="el-icon-time"></i>
          <span class="metadata-text"> {{ toFixed(metadata.duration) }} 秒 </span>
        </div>
      </el-tooltip>

      <!-- audio codec -->
      <el-tooltip
        placement="top-start"
        popper-class="metadata-tooltip"
        :open-delay="500"
        content="音声エンコード"
        v-if="metadata.audioCodec"
      >
        <div
          class=""
          style="margin-top: 4px"
          v-bind:style="[errorList.includes('audioCodec') ? { color: 'var(--danger-color)' } : { color: '#909399' }]"
        >
          <i class="fa-solid fa-music"></i>
          <span class="metadata-text">
            {{ String(metadata.audioCodec).toUpperCase() }}
          </span>
        </div>
      </el-tooltip>

      <!-- video codec -->
      <el-tooltip
        placement="top-start"
        popper-class="metadata-tooltip"
        :open-delay="500"
        content="動画エンコード"
        v-if="metadata.videoCodec"
      >
        <div
          class=""
          style="margin-top: 4px"
          v-bind:style="[errorList.includes('videoCodec') ? { color: 'var(--danger-color)' } : { color: '#909399' }]"
        >
          <i class="fa-solid fa-video"></i>
          <span class="metadata-text">
            {{ String(metadata.videoCodec).toUpperCase() }}
          </span>
        </div>
      </el-tooltip>

      <!-- audio volume -->
      <el-tooltip
        placement="top-start"
        popper-class="metadata-tooltip"
        :open-delay="500"
        content="音量"
        v-if="![NaN, null, undefined].includes(metadata.volume)"
      >
        <div
          class=""
          style="margin-top: 4px"
          v-bind:style="[errorList.includes('volume') ? { color: 'var(--danger-color)' } : { color: '#909399' }]"
        >
          <i class="fa-solid fa-volume-high"></i>
          <span class="metadata-text"> {{ toRound(Number(metadata.volume).toFixed(1)) }} dB </span>
        </div>
      </el-tooltip>
    </div>
    <!-- other -->
    <el-row
      :gutter="10"
      v-if="
        ![
          appRoute.CREATIVE_REGIST_ONLY,
          appRoute.CREATIVE_LIST,
          appRoute.IMAGE_MOVIE_MASTER,
          appRoute.APPROVAL_DETAIL,
        ].includes($route.path) && isShowVersionStatus
      "
    >
      <el-col :span="12">
        <el-tag type="info" class="w-100 text-center font-bold" @click="openVersionDialog"> ver.{{ version }} </el-tag>
      </el-col>
      <el-col :span="12">
        <el-select
          v-loading="loading"
          v-if="
            ![
              appRoute.CREATIVE_REGIST,
              appRoute.CREATIVE_REGIST_ONLY,
              appRoute.CREATIVE_LIST,
              appRoute.APPROVAL_DETAIL,
            ].includes($route.path)
          "
          v-model="statusValue"
          placeholder="Select"
          size="small"
          @change="changeStatus"
        >
          <el-option v-for="(option, i) in approvalStatusOptions" :key="i" :label="option" :value="option" />
        </el-select>
        <el-tag v-else type="info" class="w-100 text-center font-bold">
          {{ approvalStatus }}
        </el-tag>
      </el-col>
    </el-row>
  </el-row>
</template>

<style scoped>
.w-100 {
  width: 100%;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.text-center {
  text-align: center;
}
.font-bold {
  font-weight: bold;
}

.metadata-text {
  font-weight: bold;
}
</style>

<style>
.w-fit {
  width: fit-content;
}
.d-inline-block {
  display: inline-block;
}
.color-danger {
  color: var(--danger-color) !important;
}

.el-tooltip__popper.is-dark.metadata-tooltip {
  font-size: 10px !important;
  padding: 4px 8px;
  margin-bottom: 4px;
}
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'
import { AppRoute } from '@/mixins/appRoute'

export default {
  name: 'version-status-item',
  mixins: [util],
  props: {
    targetType: { type: String, default: () => '' },
    approvalStatus: { type: String, default: () => '' },
    targetId: { type: String, default: () => '' },
    version: { type: Number, default: () => null },
    metadata: { type: Object, default: () => {} },
    errorList: { type: Array, default: () => [] },
    isShowVersionStatus: { type: Boolean, default: () => true },
  },
  data: () => ({ loading: false, statusValue: '', appRoute: AppRoute }),
  created() {
    window.VersionStatusItem = this
    this.statusValue = this.approvalStatus
  },
  beforeUpdate() {
    this.statusValue = this.approvalStatus
  },
  methods: {
    async changeStatus() {
      const body = {
        targetType: this.targetType,
        targetIds: [{ targetId: this.targetId }],
        approvalStatus: this.statusValue,
      }

      this.loading = true
      await this.$api.authFetch('/approval_status', { method: 'PUT', body }).finally(() => {
        this.loading = false
      })

      this.$emit('reload')
    },
    openVersionDialog() {
      if (_.includes(['/image-movie-master', '/text-master'], this.$route.path)) this.$emit('open-version-dialog')
    },
    toRound(floatingPoint) {
      return floatingPoint % 1 == 0 ? Math.trunc(floatingPoint) : floatingPoint
    },
    toFixed(floatingPoint) {
      return String(floatingPoint).match(/^-?\d+(?:\.[0-9]?[1-9]?)?/)[0]
    },
  },
  watch: {
    approvalStatus() {
      this.statusValue = this.approvalStatus
    },
  },
  computed: {
    fileNameLength() {
      const names = String(this.metadata.fileName).substring(
        0,
        this.metadata.fileName.indexOf('.' + this.metadata.extension)
      )
      return names.length
    },
    fileSize() {
      const fileSizeRaw = this.metadata.fileSize
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
      const i = Math.floor(Math.log(fileSizeRaw) / Math.log(1024))
      return `${parseFloat((fileSizeRaw / Math.pow(1024, i)).toFixed(1))} ${sizes[i]}`
    },
  },
}
</script>
