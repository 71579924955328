<template>
  <el-row id="search-form" v-loading="loading" type="flex" justify="space-around" class="mb-1" :gutter="10">
    <el-col :span="4">
      <el-select class="w-100" v-model="form.tagTypeId" placeholder="カテゴリ" clearable filterable>
        <el-option v-for="(r, i) in tagTypes" :key="i" :label="r.tagTypeName" :value="r.tagTypeId" />
      </el-select>
    </el-col>

    <el-col :span="8">
      <el-input v-model="form.tagValue" placeholder="カテゴリ･内容･説明(自由入力 スペースでOR検索)" clearable />
    </el-col>

    <el-col :span="2">
      <el-button class="w-100" type="primary" @click="search"> 検索 </el-button>
    </el-col>

    <el-col :span="8">
      <el-input v-model="newTagType" placeholder="作成するカテゴリ" clearable />
    </el-col>

    <el-col :span="2">
      <el-button class="w-100" type="primary" :disabled="!newTagType" @click="createCategory"> 作成 </el-button>
    </el-col>
  </el-row>
</template>

<style scoped>
.w-100 {
  width: 100%;
}
.mb-1 {
  margin-bottom: 20px;
}
</style>

<script>
import _ from 'lodash'

export default {
  name: 'seach-form',
  props: {
    defaultPromotionId: { type: String, default: () => '' },
    isShow: { type: Boolean, default: () => false },
    tagTypes: { type: Array, default: () => [] },
  },
  data: () => ({ loading: false, form: { tagTypeId: '', tagValue: '' }, newTagType: '' }),
  created() {
    window.SeachForm = this
  },
  methods: {
    search() {
      this.$emit('search', _.cloneDeep(this.form))
    },
    async createCategory() {
      const body = {
        tagTypeName: this.newTagType,
        promotionId: this.defaultPromotionId,
      }

      this.loading = true
      await this.$api.authFetch('/tag/type', { method: 'POST', body }).finally(() => {
        this.loading = false
      })

      this.newTagType = ''
      this.$emit('reload')
    },
  },
  watch: {
    isShow() {
      if (!this.isShow) return

      this.form = { tagTypeId: '', tagValue: '' }
      this.newTagType = ''
    },
  },
}
</script>
