<template>
  <el-container id="approval-list" v-loading="loading">
    <div id="contents" class="arlist-page drawer-tab">
      <div class="wrap">
        <div>
          <ul class="list-button">
            <li>
              <div :class="buttonStyle('official')" @click="openApprovalDrawer('official')">
                進行中
                <span class="icon">
                  <i class="fas fa-list-check"></i>
                </span>
              </div>
            </li>
            <li>
              <div :class="buttonStyle('draft')" @click="openApprovalDrawer('draft')">
                一時保存中
                <span class="icon">
                  <i class="fas fa-save"></i>
                </span>
              </div>
              <span class="nottif" v-if="this.countApprovalDraft && this.countApprovalDraft !== -1">{{
                this.countApprovalDraft
              }}</span>
            </li>
            <li>
              <div :class="buttonStyle('done')" @click="openApprovalDrawer('done')">
                完了済み
                <span class="icon">
                  <i class="fas fa-check-circle"></i>
                </span>
              </div>
            </li>
          </ul>
        </div>
        <!-- </div> -->
        <approval-data
          v-if="drawerTab.toLowerCase() === 'done'"
          :sortType="sortTypeDone"
          approvalCategory="done"
          :countApproval="countApprovalDone"
          :currentApprovalData="currentApprovalDoneData"
          :currentPage="currentApprovalDonePage"
          :limitPerPage="limitPerPage"
          :drawerTab="drawerTab"
          @handel-page-sort="value => handelPageSort(value, 'done')"
          @handle-page-change="$event => handlePageChange($event, 'done')"
        />
        <approval-data
          v-else-if="drawerTab.toLowerCase() === 'draft'"
          :sortType="sortTypeDraft"
          approvalCategory="draft"
          :countApproval="countApprovalDraft"
          :currentApprovalData="currentApprovalDraftData"
          :currentPage="currentApprovalDraftPage"
          :limitPerPage="limitPerPage"
          :drawerTab="drawerTab"
          :ad-formats="adFormats"
          :selected-promotion-ids="selectedPromotionIds"
          @reload-data="type => reloadData(type)"
          @change_loading="isLoading => changeLoading(isLoading)"
          @handel-page-sort="value => handelPageSort(value, 'draft')"
          @handle-page-change="$event => handlePageChange($event, 'draft')"
        />
        <approval-data
          v-else
          :sortType="sortTypeOfficial"
          approvalCategory="official"
          :countApproval="countApprovalOfficial"
          :currentApprovalData="currentApprovalOfficialData"
          :currentPage="currentApprovalOfficialPage"
          :limitPerPage="limitPerPage"
          :drawerTab="drawerTab"
          @handel-page-sort="value => handelPageSort(value, 'official')"
          @handle-page-change="$event => handlePageChange($event, 'official')"
        />
      </div>
    </div>
  </el-container>
</template>
<style scoped>
.wrap {
  width: 1300px;
  margin: 0 auto;
  padding: 0 30px;
}
.arlist-page .wrap {
  height: calc(100% - 30px);
}
</style>
<style>
@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;600;700&display=swap');

img {
  border: none;
  outline: none;
  max-width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul li {
  margin: 0;
  padding: 0;
}

.clearfix::after {
  clear: both;
  display: block;
  content: '';
}

.v-modal {
  z-index: 2 !important;
  height: 100%;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
button:focus {
  outline: none;
}
button:focus {
  outline: none;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

table {
  width: 100%;
}

#approval-list {
  min-width: 1300px;
  font-family: 'Noto Sans JP', 'Meiryo', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro';
  background-color: #fff;
  color: #616161;
  font-weight: 400;
  font-size: 13px;
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1.3;
  position: relative;
  overflow-y: hidden;
  height: 100%;
}

.btn-primary {
  background-color: #237da7;
  border: none;
  color: #fff;
}

.row.row-8 {
  margin-right: -4px;
  margin-left: -4px;
}
.row.row-8 > [class*='col-'] {
  padding-left: 4px;
  padding-right: 4px;
}

.row.row-10 {
  margin-right: -5px;
  margin-left: -5px;
}
.row.row-10 > [class*='col-'] {
  padding-left: 5px;
  padding-right: 5px;
}

.row.row-16 {
  margin-right: -8px;
  margin-left: -8px;
}
.row.row-16 > [class*='col-'] {
  padding-left: 8px;
  padding-right: 8px;
}

.row.row-20 {
  margin-right: -10px;
  margin-left: -10px;
}
.row.row-20 > [class*='col-'] {
  padding-left: 10px;
  padding-right: 10px;
}

.row.row-40 {
  margin-right: -20px;
  margin-left: -20px;
}
@media (max-width: 767px) {
  .row.row-40 {
    margin-right: -8px;
    margin-left: -8px;
  }
}
.row.row-40 > [class*='col-'] {
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 767px) {
  .row.row-40 > [class*='col-'] {
    padding-left: 8px;
    padding-right: 8px;
  }
}
/*** page ***/
/*
  Created on : 06-29-2023, 13:00:00
*/
/***----- arlistPage -----***/
.arlist-page {
  height: calc(100vh - 100px);
  width: 100%;
  padding: 15px 0 10px;
  overflow-y: auto;
  position: relative;
}
.drawer-tab {
  height: calc(100vh - 85px) !important;
}
.arlist-page .arlist-drawer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.arlist-page .arlist-drawer .drawer-wrap {
  height: 100%;
  padding: 15px 90px 10px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.arlist-page .arlist-drawer .drawer-wrap::-webkit-scrollbar {
  display: none;
}
.arlist-page .arlist-drawer .drawer-wrap .drawer-close {
  font-family: 'Noto Sans JP', 'Meiryo', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro';
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  align-self: center;
  padding-bottom: 10px;
  cursor: pointer;
}
.arlist-page .arlist-drawer .drawer-wrap .drawer-close:hover {
  text-decoration: underline;
}
.arlist-page .el-pagination {
  height: 25px;
  text-align: center;
}
.arlist-page .btn-link {
  background-color: #409eff;
  color: #fff;
  display: inline-block;
  width: max-content;
  padding: 8px 20px;
  font-weight: 500;
  border: none;
  height: 35px;
  font-size: 13px;
  text-decoration: none;
  border-radius: 0.25rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
}
.arlist-page .btn-link:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.arlist-page .list-button {
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  gap: 20px;
}
.arlist-page .list-button li {
  position: relative;
  width: calc(15% - 20px);
}
.arlist-page .list-button li .nottif {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: block;
  position: absolute;
  right: -12px;
  top: -12px;
  color: #fff;
  background-color: #e76867;
  font-size: 11px;
  text-align: center;
  line-height: 22px;
  cursor: default;
}
.arlist-page .list-button li .button-content {
  font-size: inherit;
  cursor: pointer;
  -webkit-transition: all ease 0.3s;
  -o-transition: all ease 0.3s;
  transition: all ease 0.3s;
  position: relative;
  padding-left: 20%;
  height: 35px;
  width: 80%;
  background-color: #f2f2f4;
  color: #237da7;
  text-align: center;
  -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 13px;
}
.arlist-page .list-button li .active,
.arlist-page .list-button li .button-content:hover {
  background-color: #237da7;
  color: #fff;
}
.arlist-page .list-button li .button-content .icon {
  position: absolute;
  font-size: 20px;
  left: 30px;
  top: calc(50% - 14px);
}
.due-date-picker .el-date-table .el-date-table__row td.disabled span {
  color: #c0c4cc !important;
}
#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail .slick-arrow.slick-disabled {
  visibility: visible !important;
  color: #bebebe;
}
#approval-card .col-thumbnail .thumbnail-list .slider-thumbnail .slick-arrow.slick-disabled:hover {
  background-color: #fff !important;
}
</style>
<script>
import ApprovalData from '@/views/approval-list/components/approval-data'
import { slice, isEmpty, startCase } from 'lodash'

export default {
  name: 'approval-list',
  components: { ApprovalData },
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
  },
  data() {
    return {
      // data
      totalApprovalDone: {},
      totalApprovalDraft: {},
      totalApprovalOfficial: {},

      // count
      countApprovalDone: -1,
      countApprovalDraft: -1,
      countApprovalOfficial: -1,

      //index
      currentApprovalDoneIndex: 1,
      currentApprovalDraftIndex: 1,
      currentApprovalOfficialIndex: 1,

      //page
      currentApprovalDonePage: 1,
      currentApprovalDraftPage: 1,
      currentApprovalOfficialPage: 1,

      //sort type
      sortTypeDone: 'priority_desc',
      sortTypeDraft: 'priority_desc',
      sortTypeOfficial: 'priority_desc',

      //other settings
      limitPerIndex: 1000, // limitPerIndex should bigger or equal limitPerPage
      limitPerPage: 20, //limitPerPage should be a divisor of limitPerIndex
      drawerTab: null,
      loading: false,

      renderComponent: true,
    }
  },
  async created() {
    window.ApprovalList = this
    this.getData()
    const drawrTabSession = sessionStorage.getItem('drawerTab')
    this.drawerTab = drawrTabSession && drawrTabSession !== 'null' ? drawrTabSession : 'official'
  },
  beforeDestroy() {
    sessionStorage.removeItem('drawerTab')
  },
  methods: {
    async getData(mode = 'default') {
      const query = Object.assign({}, this.$route.query)
      if (query.creativeListForm) {
        delete query.creativeListForm
        this.$router.replace({ query })
      }
      if (this.$route.query.promotionIds) {
        this.loading = true
        await Promise.all([
          await this.getApprovalWithPagination(this.currentApprovalDonePage, this.limitPerIndex, 'done', mode),
          await this.getApprovalWithPagination(this.currentApprovalDraftPage, this.limitPerIndex, 'draft', mode),
          await this.getApprovalWithPagination(this.currentApprovalOfficialPage, this.limitPerIndex, 'official', mode),
        ])

        this.loading = false
      }
    },
    async reloadData(type) {
      await this.getData('replace')
      this.drawerTab = type
    },
    async getApprovalWithPagination(page, limit, approvalCategory, mode = 'default') {
      let sortType = 'priority_desc'
      switch (approvalCategory) {
        case 'done':
          sortType = this.sortTypeDone
          break
        case 'draft':
          sortType = this.sortTypeDraft
          break
        case 'official':
          sortType = this.sortTypeOfficial
          break
        default:
          break
      }

      const data = await this.$api.authFetch('/approval_request/pagination', {
        method: 'POST',
        body: {
          promotionIds: isEmpty(this.selectedPromotionIds)
            ? [this.$route.query.promotionIds]
            : [this.selectedPromotionIds[0]],
          page: Math.floor(((page - 1) * this.limitPerPage) / limit) + 1,
          limit: limit,
          approvalCategory: startCase(approvalCategory),
          sortType: sortType,
        },
      })
      switch (approvalCategory) {
        case 'done':
          if (data && (!(data.currentPageIndex in this.totalApprovalDone) || mode === 'replace')) {
            this.totalApprovalDone = {
              ...this.totalApprovalDone,
              [data.currentPageIndex]: data.approvalRequests,
            }
            this.countApprovalDone = data.totalRecord
          }
          break
        case 'draft':
          if (data && (!(data.currentPageIndex in this.totalApprovalDraft) || mode === 'replace')) {
            this.totalApprovalDraft = {
              ...this.totalApprovalDraft,
              [data.currentPageIndex]: data.approvalRequests,
            }
            this.countApprovalDraft = data.totalRecord
          }
          break
        case 'official':
          if (data && (!(data.currentPageIndex in this.totalApprovalOfficial) || mode === 'replace')) {
            this.totalApprovalOfficial = {
              ...this.totalApprovalOfficial,
              [data.currentPageIndex]: data.approvalRequests,
            }
            this.countApprovalOfficial = data.totalRecord
          }
          break
        default:
          break
      }
      return data
    },
    async openApprovalDrawer(type) {
      this.drawerTab = type
    },
    async handleCheckIndex(index, data, page, type) {
      if (!(index.toString() in data)) {
        this.loading = true
        await this.getApprovalWithPagination(page, this.limitPerIndex, type)
        this.loading = false
      }
    },
    async handlePageChange(page, type) {
      const approvalIndex = Math.floor(((page - 1) * this.limitPerPage) / this.limitPerIndex) + 1
      switch (type) {
        case 'done':
          this.currentApprovalDoneIndex = approvalIndex
          this.currentApprovalDonePage = page
          await this.handleCheckIndex(approvalIndex, this.totalApprovalDone, page, type)
          break
        case 'draft':
          this.currentApprovalDraftIndex = approvalIndex
          this.currentApprovalDraftPage = page
          await this.handleCheckIndex(approvalIndex, this.totalApprovalDraft, page, type)
          break
        case 'official':
          this.currentApprovalOfficialIndex = approvalIndex
          this.currentApprovalOfficialPage = page
          await this.handleCheckIndex(approvalIndex, this.totalApprovalOfficial, page, type)
          break
        default:
          break
      }
    },
    async handelPageSort(value, type) {
      switch (type) {
        case 'draft':
          this.sortTypeDraft = value
          this.totalApprovalDraft = {}
          this.loading = true
          await this.getApprovalWithPagination(this.currentApprovalDraftPage, this.limitPerIndex, type)
          this.loading = false
          break
        case 'done':
          this.sortTypeDone = value
          this.totalApprovalDone = {}
          this.loading = true
          await this.getApprovalWithPagination(this.currentApprovalDonePage, this.limitPerIndex, type)
          this.loading = false
          break
        case 'official':
          this.sortTypeOfficial = value
          this.totalApprovalOfficial = {}
          this.loading = true
          await this.getApprovalWithPagination(this.currentApprovalOfficialPage, this.limitPerIndex, type)
          this.loading = false
          break
        default:
          break
      }
    },
    closeDrawer(type) {
      this.drawerTab = null
      switch (type) {
        case 'draft':
          this.currentApprovalDraftPage = 1
          break
        case 'done':
          this.currentApprovalDonePage = 1
          break
        case 'official':
          this.currentApprovalOfficialPage = 1
          break
        default:
          break
      }
    },
    buttonStyle(type) {
      return this.drawerTab.toLowerCase() === type.toLowerCase() ? 'button-content active' : 'button-content'
    },
    changeLoading(isLoading) {
      this.loading = isLoading
    },
  },
  computed: {
    currentApprovalDoneData() {
      const startIndex =
        (this.currentApprovalDonePage - 1) * this.limitPerPage -
        this.limitPerIndex * (this.currentApprovalDoneIndex - 1)
      const endIndex =
        this.currentApprovalDonePage * this.limitPerPage - this.limitPerIndex * (this.currentApprovalDoneIndex - 1)
      return slice(this.totalApprovalDone[this.currentApprovalDoneIndex.toString()], startIndex, endIndex)
    },
    currentApprovalDraftData() {
      const startIndex =
        (this.currentApprovalDraftPage - 1) * this.limitPerPage -
        this.limitPerIndex * (this.currentApprovalDraftIndex - 1)
      const endIndex =
        this.currentApprovalDraftPage * this.limitPerPage - this.limitPerIndex * (this.currentApprovalDraftIndex - 1)
      return slice(this.totalApprovalDraft[this.currentApprovalDraftIndex.toString()], startIndex, endIndex)
    },
    currentApprovalOfficialData() {
      const startIndex =
        (this.currentApprovalOfficialPage - 1) * this.limitPerPage -
        this.limitPerIndex * (this.currentApprovalOfficialIndex - 1)
      const endIndex =
        this.currentApprovalOfficialPage * this.limitPerPage -
        this.limitPerIndex * (this.currentApprovalOfficialIndex - 1)
      return slice(this.totalApprovalOfficial[this.currentApprovalOfficialIndex.toString()], startIndex, endIndex)
    },
    drawer() {
      return this.drawerTab !== null
    },
  },
  watch: {
    async selectedPromotionIds() {
      if (!isEmpty(this.selectedPromotionIds)) {
        this.loading = true
        ;(this.totalApprovalDone = {}),
          (this.totalApprovalDraft = {}),
          (this.totalApprovalOfficial = {}),
          // count
          (this.countApprovalDone = -1),
          (this.countApprovalDraft = -1),
          (this.countApprovalOfficial = -1),
          //index
          (this.currentApprovalDoneIndex = 1),
          (this.currentApprovalDraftIndex = 1),
          (this.currentApprovalOfficialIndex = 1),
          //page
          (this.currentApprovalDonePage = 1),
          (this.countApprovalDraftPage = 1),
          (this.countApprovalOfficialPage = 1),
          //sort type
          (this.sortTypeDone = 'priority_desc'),
          (this.sortTypeDraft = 'priority_desc'),
          (this.sortTypeOfficial = 'priority_desc'),
          await Promise.all([
            await this.getApprovalWithPagination(this.currentApprovalDonePage, this.limitPerIndex, 'done'),
            await this.getApprovalWithPagination(this.currentApprovalDraftPage, this.limitPerIndex, 'draft'),
            await this.getApprovalWithPagination(this.currentApprovalOfficialPage, this.limitPerIndex, 'official'),
          ])
        this.loading = false
      }
    },
    drawerTab() {
      sessionStorage.setItem('drawerTab', this.drawerTab)
    },
  },
}
</script>
