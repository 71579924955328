const MAX_ALLOWED_REFERENCE_URL_NUMBER = 5
const SPECIAL_REFERENCE_URL = '' // empty string
const UPDATE_CR_STATUS_POPUP_TITLE = '入稿ステータス変更'
const UPDATE_ASSET_TEXT_STATUS_POPUP_TITLE = '監修ステータス変更'
const NOT_ABLE_CHANGE_STATUS_MSG =
  '※入稿済み、入稿依頼済みステータスのCRは編集不可のため、修正内容が反映されませんでした。'

// comments for ticket CV-8685
// const TEXT_EXISTED = '同じ内容のテキストが既に存在しています'

const NUMBER_OF_URL_VALID = 1

const INVALID_FILE_NAME = 'ファイル名には　\u{005C} / : * ? " < > |　が入力できません。'

export {
  MAX_ALLOWED_REFERENCE_URL_NUMBER,
  SPECIAL_REFERENCE_URL,
  NUMBER_OF_URL_VALID,
  UPDATE_ASSET_TEXT_STATUS_POPUP_TITLE,
  UPDATE_CR_STATUS_POPUP_TITLE,
  NOT_ABLE_CHANGE_STATUS_MSG,
  INVALID_FILE_NAME,
  // , TEXT_EXISTED // comments for ticket CV-8685
}
