<template>
  <el-dialog
    id="asset-dialog"
    title="素材選択"
    lock-scroll
    fullscreen
    append-to-body
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <image-movie-master
      ref="imageMovieMaster"
      :selected-promotion-ids="selectedPromotionIds"
      :tags="tags"
      :is-creative="true"
      :ad-format-id="adFormatId"
      :label-id="labelId"
      :extensions="extensions"
      @change-selected-assets="changeSelectedAssets"
    />

    <span v-if="selected.length > 0" slot="footer" class="dialog-footer">
      <el-button type="primary" @click="close"> 選択 </el-button>
    </span>
  </el-dialog>
</template>
<style>
#el-dialog__footer el-dialog__footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
</style>
<script>
import _ from 'lodash'
import ImageMovieMaster from '@/views/image-movie-master'

export default {
  name: 'asset-dialog',
  components: { ImageMovieMaster },
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    adFormatId: { type: String, default: () => '' },
  },
  data: () => ({ isShow: false, index: null, selected: [], extensions: [], labelId: null }),
  created() {
    window.AssetDialog = this
  },
  methods: {
    async show(index, selected, extensions, labelId) {
      const listOriginIds = _.map(selected, 'originId')
      this.index = index
      this.selected = selected
      this.extensions = extensions
      this.labelId = labelId
      this.isShow = true
      await this.$nextTick()

      await this.$refs.imageMovieMaster.reset()
      this.$refs.imageMovieMaster.changeSelectedForCreativeRegist(listOriginIds)
      this.$refs.imageMovieMaster.initOldSelected(selected)
    },
    close() {
      this.$emit('change-selected-assets', { index: this.index, selected: this.selected })
      this.isShow = false
    },
    changeSelectedAssets(gr) {
      const selectedAssets = _.chain(this.selected)
        .map(r => _.find(gr, r2 => r2.originId === r.originId))
        .compact()
        .value()

      const selectedOriginIds = _.map(selectedAssets, 'originId')
      const newSelectedAssets = _.filter(gr, r => !_.includes(selectedOriginIds, r.originId))

      this.selected = _.concat(selectedAssets, newSelectedAssets)
    },
  },
}
</script>
