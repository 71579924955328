import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/login'
import ImageMovieMaster from '@/views/image-movie-master'
import TextMaster from '@/views/text-master'
import CreativeRegist from '@/views/creative-regist'
import CreativeRegistOnly from '@/views/creative-regist-only'
import CreativeList from '@/views/creative-list'
import Tableau from '@/views/tableau'
import ApprovalList from '@/views/approval-list'
import ApprovalDetail from '@/views/approval-detail'

//this.$router.pushした時に重複しないように加工
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: '/image-movie-master',
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/image-movie-master',
      name: 'image-movie-master',
      component: ImageMovieMaster,
      meta: { requiresAuth: true },
    },
    {
      path: '/text-master',
      name: 'text-master',
      component: TextMaster,
      meta: { requiresAuth: true },
    },
    {
      path: '/creative-regist',
      name: 'creative-regist',
      component: CreativeRegist,
      meta: { requiresAuth: true },
    },
    {
      path: '/creative-regist-only',
      name: 'creative-regist-only',
      component: CreativeRegistOnly,
      meta: { requiresAuth: true },
    },
    {
      path: '/creative-list',
      name: 'creative-list',
      component: CreativeList,
      meta: { requiresAuth: true },
    },
    {
      path: '/tableau',
      name: 'tableau',
      component: Tableau,
      meta: { requiresAuth: true },
    },
    {
      path: '/approval-list',
      name: 'approval-list',
      component: ApprovalList,
      meta: { requiresAuth: true },
    },
    {
      path: '/approval-detail',
      name: 'approval-detail',
      component: ApprovalDetail,
      meta: { requiresAuth: true },
    },
  ],
})

export default router
