var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"id":"comment-area","gutter":10}},[_c('div',[_c('div',[_c('div',[_c('label',{staticStyle:{"font-weight":"bolder"}},[_c('i',{staticClass:"fa-solid fa-comments"}),_vm._v(" 制作意図")]),_c('div',{staticClass:"creative-comment",staticStyle:{"display":"flex","margin-bottom":"2vh"}},[_c('div',{staticStyle:{"width":"20%","float":"left"}},[_vm._v("掛け合わせ")]),_c('div',{staticStyle:{"width":"80%","float":"left"}},[_c('el-input',{staticStyle:{"min-width":"100%","max-width":"100%"},attrs:{"type":"textarea","value":_vm.creative.creatorComment,"disabled":_vm.creative.submissionStatus === '入稿済',"rows":3},on:{"input":v => _vm.$emit('change-creator-comment', v)}})],1)]),_c('div',{staticClass:"asset-comment",staticStyle:{"display":"flex","margin-bottom":"10px"}},[_c('div',{staticStyle:{"width":"20%"}},[_vm._v("画像/動画")]),_c('div',{staticStyle:{"flex-grow":"1"}},[_c('el-input',{class:`no-resize ${_vm.creatorCommentAsset.replaceAll('\n', '').length ? '' : 'commentNone'}`,staticStyle:{"min-width":"100%","max-width":"100%"},attrs:{"readonly":"","disabled":"","type":"textarea","resize":"none","rows":3,"value":_vm.creatorCommentAsset.replaceAll('\n', '').length ? _vm.creatorCommentAsset : '制作意図記入(ユニーク)'}}),_c('div',{staticClass:"thumbnail-list",staticStyle:{"white-space":"nowrap","display":"flex","gap":"2px"}},_vm._l((_vm.attachmentFileList),function(asset,i){return _c('span',{key:`a_${i}_${_vm.creative.creativeId}`,staticClass:"items",staticStyle:{"width":"45px","aspect-ratio":"1/1","position":"relative","align-items":"center !important","display":"flex"}},[_c('div',{staticStyle:{"width":"45px","aspect-ratio":"1/1","display":"flex","justify-content":"center","background-color":"#060630","cursor":"pointer"},on:{"click":() => {
                      _vm.openItemDialog(asset, `attach_image_${_vm.creative.creativeId}_${i}`)
                    }}},[_c('img',{staticStyle:{"max-height":"100%","max-width":"100%","margin":"auto"},attrs:{"src":asset,"id":`attach_image_${_vm.creative.creativeId}_${i}`}})])])}),0)],1)]),_c('div',{staticClass:"text-comment",staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"width":"20%","float":"left"}},[_vm._v("テキスト")]),_c('div',{staticStyle:{"width":"80%","float":"left"}},[_c('el-input',{class:`no-resize ${
                _vm.creative.textSet.creatorComment != null && _vm.creative.textSet.creatorComment.length != 0
                  ? ''
                  : 'commentNone'
              }`,staticStyle:{"min-width":"100%","max-width":"100%"},attrs:{"readonly":"","disabled":"","type":"textarea","rows":3,"resize":"none","value":_vm.creative.textSet.creatorComment != null && _vm.creative.textSet.creatorComment.length != 0
                  ? _vm.creative.textSet.creatorComment
                  : '制作意図記入(ユニーク)'}})],1)]),_c('div',{staticClass:"creative-comment",staticStyle:{"display":"flex","margin-top":"10px"}},[_c('div',{staticStyle:{"width":"23%"}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" 備考")])]),_c('div',{staticStyle:{"flex-grow":"1"}},[_c('el-input',{staticStyle:{"min-width":"100%","max-width":"100%"},attrs:{"type":"textarea","value":_vm.creative.note,"rows":2},on:{"input":v => _vm.$emit('change-note', v)}})],1)])])]),_c('item-dialog',{ref:"itemDialog",attrs:{"url":_vm.previewingImage}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }