<template>
  <div id="tag-table">
    <el-table
      v-loading="loading"
      border
      style="width: 100%"
      :height="height"
      :data="viewTags"
      v-infinite-scroll="loadTags"
    >
      <el-table-column type="selection" width="55">
        <template slot-scope="{ row }">
          <el-checkbox
            :value="row.selected"
            :indeterminate="!row.selected && row.indeterminate"
            @change="changeSelected(row)"
          />
        </template>
      </el-table-column>

      <el-table-column label="カテゴリ" width="200" sortable sort-by="tagTypeName">
        <template slot-scope="{ $index, row }">
          <el-select
            v-if="$index === selectIndex"
            class="w-100"
            v-model="form.tagTypeId"
            placeholder="カテゴリ"
            clearable
            filterable
            size="small"
          >
            <el-option v-for="(r, i) in tagTypes" :key="i" :label="r.tagTypeName" :value="r.tagTypeId" />
          </el-select>

          <template v-else>
            {{ row.tagTypeName }}
          </template>
        </template>
      </el-table-column>

      <el-table-column label="内容" sortable sort-by="tagValue">
        <template slot-scope="{ $index, row }">
          <template v-if="$index === selectIndex">
            <el-row v-if="form.tagTypeId === 'period'" class="w-100" type="flex" justify="space-around">
              <el-col :span="11">
                <el-date-picker
                  v-model="date.start"
                  class="w-100"
                  type="date"
                  placeholder="開始日"
                  format="yyyyMMdd"
                  value-format="yyyyMMdd"
                  size="small"
                />
              </el-col>

              <el-col :span="11">
                <el-date-picker
                  v-model="date.end"
                  class="w-100"
                  type="date"
                  placeholder="終了日"
                  format="yyyyMMdd"
                  value-format="yyyyMMdd"
                  size="small"
                />
              </el-col>
            </el-row>

            <el-input v-else v-model="form.tagValue" placeholder="内容" size="small" />
          </template>

          <template v-else>
            {{ row.tagValue }}
          </template>
        </template>
      </el-table-column>

      <el-table-column label="説明">
        <template slot-scope="{ $index, row }">
          <el-input v-if="$index === selectIndex" v-model="form.description" placeholder="説明" size="small" />

          <template v-else>
            {{ row.description }}
          </template>
        </template>
      </el-table-column>

      <el-table-column label="色" width="65" align="center">
        <template slot-scope="{ $index, row }">
          <el-color-picker v-if="$index === selectIndex" v-model="form.color" />

          <el-color-picker v-else :value="row.color" disabled />
        </template>
      </el-table-column>

      <el-table-column fixed="right" width="120">
        <template slot-scope="{ $index, row }">
          <template v-if="!row.isDefault">
            <el-col :span="12" class="text-center">
              <span v-show="$index === selectIndex" @click="save($index, row)">
                <i class="fas fa-save fa-lg" />
              </span>
              <span v-show="$index !== selectIndex" @click="edit($index, row)">
                <i class="fas fa-pen fa-lg" />
              </span>
            </el-col>

            <el-col :span="12" class="text-center">
              <span v-show="$index === selectIndex" @click="cancel($index, row)" style="color: #f56c6c">
                <i class="fas fa-times-circle fa-lg" />
              </span>
              <span v-show="$index !== selectIndex" @click="remove($index, row)">
                <i class="fas fa-trash-alt fa-lg" />
              </span>
            </el-col>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <div class="text-center mt-2 font-big h-30px" @click="add">
      <i class="fas fa-plus-circle" />
    </div>
  </div>
</template>

<style scoped>
.text-center {
  text-align: center;
}
.font-big {
  font-size: 22px;
}
.mt-2 {
  margin-top: 1rem;
}
.w-100 {
  width: 100% !important;
}
.h-30px {
  height: 30px;
}
</style>

<script>
import _ from 'lodash'

export default {
  name: 'tag-table',
  props: {
    defaultPromotionId: { type: String, default: () => '' },
    isShow: { type: Boolean, default: () => false },
    filteredTags: { type: Array, default: () => [] },
    tagTypes: { type: Array, default: () => [] },
    selectedTags: { type: Object, default: () => ({}) },
    targetIds: { type: Array, default: () => ({}) },
  },
  data: () => {
    const emptyTag = { tagId: '', tagTypeId: '', tagValue: '', description: '', color: '' }
    return {
      loading: false,
      selectIndex: null,
      allTags: [],
      selected: [],
      form: emptyTag,
      emptyTag,
      date: { start: '', end: '' },
      viewIndex: 0,
      innerHeight: window.innerHeight,
    }
  },
  created() {
    window.TagTable = this
    this.init()
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })
  },
  async mounted() {
    await this.$nextTick()
    document
      .getElementById('tag-table')
      .getElementsByClassName('el-table__header-wrapper')
      .item(0)
      .getElementsByClassName('el-table-column--selection')
      .item(0)
      .getElementsByClassName('el-checkbox__input')
      .item(0).style.display = 'none'
  },
  methods: {
    log() {
      console.log(this.defaultPromotionId)
    },
    loadTags() {
      this.viewIndex++
    },
    init() {
      if (this.isShow) {
        this.selected = _.map(this.selectedTags, (r, k) => ({
          tagId: k,
          targetIds: r.selectedTargetIds,
        }))

        this.allTags = _.cloneDeep(this.filteredTags)
        this.createAllTableTags()
      } else this.selected = []

      if (this.selectIndex !== null) {
        this.allTags = _.filter(this.allTags, (r, i) => i !== this.selectIndex)
        this.selectIndex = null
      }
    },
    createAllTableTags(filteredTags) {
      this.allTags = _.map(filteredTags || this.allTags, r => {
        const result = _.find(this.selected, ({ tagId }) => tagId === r.tagId)
        if (result) {
          if (result.targetIds.length === this.targetIds.length)
            return _.assign({}, r, {
              selected: true,
              indeterminate: false,
              selectedTargetIds: this.targetIds,
            })
          else
            return _.assign({}, r, {
              selected: false,
              indeterminate: true,
              selectedTargetIds: result.targetIds,
            })
        } else return _.assign({}, r, { selected: false, indeterminate: false, selectedTargetIds: [] })
      })
    },
    async save(index, row) {
      if (!this.form.tagTypeId || !this.form.tagValue) {
        this.$alert('カテゴリと内容は必須です', '必須項目チェック')
        return
      }
      const method = row.tagId ? 'PUT' : 'POST'
      const body =
        method === 'POST'
          ? _.chain(this.form).omit(['tagId']).assign({ promotionId: this.defaultPromotionId }).value()
          : this.form

      this.loading = true
      await this.$api.authFetch('/tag', { method, body }).finally(() => {
        this.loading = false
      })

      this.$emit('reload')
      this.cancel(index, row)
    },
    cancel(index, row = {}) {
      this.selectIndex = null
      this.form = { tagId: '', tagTypeId: '', tagValue: '', description: '', color: '' }
      this.date = { start: '', end: '' }
      if (!row.tagId) this.allTags = _.filter(this.allTags, (r, i) => i !== index)
    },
    edit(index, row) {
      const first = _.first(this.allTags)
      if (!first.tagId) this.allTags = _.filter(this.allTags, 'tagId')

      this.form = _.pick(row, ['tagId', 'tagTypeId', 'tagValue', 'description', 'color'])
      if (row.tagTypeId === 'period') {
        const [start, end] = _.split(row.tagValue, '-')
        this.date = { start, end }
      }
      this.selectIndex = index
    },
    async remove(index, { tagId }) {
      const message = '※注意※タグが紐付いているクリエイティブ/画像・動画/テキストからもタグが削除されます。'
      const ret = await this.$confirm(message, '確認').catch(e => e)
      if (ret === 'cancel') return

      this.loading = true
      await this.$api.authFetch('/tag', { method: 'DELETE', query: { tagId: [tagId] } }).finally(() => {
        this.loading = false
      })

      this.$emit('reload')
    },
    async add() {
      const first = _.first(this.allTags)
      if (first && (!first.tagTypeName || !first.tagValue)) {
        this.selectIndex = 0
        return
      }

      this.allTags = [_.assign({}, this.emptyTag, { selected: false })].concat(this.allTags)
      this.cancel(0, { tagId: 'cancel' })
      this.selectIndex = 0

      await this.$nextTick()
      const elements = document.getElementById('tag-table').getElementsByTagName('tbody')
      elements.forEach(e => e.scrollIntoView(true))
    },
    changeSelected(row) {
      const selectedTagIds = _.map(this.selected, 'tagId')
      if (!row.selected) {
        if (_.includes(selectedTagIds, row.tagId)) {
          this.selected = _.map(this.selected, r =>
            _.assign({}, r, r.tagId === row.tagId ? { targetIds: this.targetIds } : {})
          )
        } else
          this.selected = this.selected.concat({
            tagId: row.tagId,
            targetIds: _.cloneDeep(this.targetIds),
          })
      } else this.selected = _.filter(this.selected, r => r.tagId !== row.tagId)

      this.createAllTableTags()
    },
  },
  watch: {
    date: {
      handler() {
        this.form.tagValue = !this.date.start && !this.date.end ? '' : `${this.date.start || ''}-${this.date.end || ''}`
      },
      deep: true,
    },
    filteredTags() {
      this.viewIndex = 1
      this.createAllTableTags(this.filteredTags)
    },
    allTags() {
      const viewedSelected = _.chain(this.allTags)
        .filter(r => r.selected || r.indeterminate)
        .map(r => ({
          tagId: r.tagId,
          targetIds: r.selected ? this.targetIds : r.selectedTargetIds,
        }))
        .value()

      const viewedTagIds = _.map(this.allTags, 'tagId')
      const behindSelected = _.chain(this.selectedTags)
        .map((r, k) => ({ tagId: k, targetIds: r.selectedTargetIds }))
        .filter(r => !_.includes(viewedTagIds, r.tagId))
        .value()

      this.$emit('change-selected', _.concat(viewedSelected, behindSelected))
    },
    isShow() {
      this.init()
    },
  },
  computed: {
    height() {
      return this.innerHeight - 54 - 30 - 60 - 46 - 30 - 70
    },
    // 段階的にロードするためのデータ 操作はallTagsで行なうこと
    viewTags() {
      return _.filter(this.allTags, (r, i) => i < this.viewIndex * 10)
    },
  },
}
</script>
