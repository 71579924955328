<template>
  <div
    class="el-input-tag input-tag-wrapper"
    :class="[size ? 'el-input-tag--' + size : '', action === 'approval-request' ? 'font-size-13' : '']"
    @click="focusTagInput"
  >
    <el-tag
      class="tooltip"
      v-for="(tag, idx) in innerTags"
      v-bind="$attrs"
      :key="tag"
      :size="size"
      :closable="!readOnly"
      :disable-transitions="false"
      :type="[validateEmail(tag) ? '' : 'danger']"
      @close="remove(idx)"
    >
      {{ tag.length > 25 ? tag.substring(0, 25) + '...' : tag }}
      <span v-if="tag.length > 25" class="tooltiptext">{{ tag }}</span>
    </el-tag>
    <input
      v-if="!readOnly"
      class="tag-input"
      :placeholder="[innerTags.length !== 0 ? '' : placeholder]"
      @input="inputTag"
      :value="newTag"
      @keydown.delete.stop="removeLastTag"
      @keydown="addNew"
      @blur="addNew"
    />
  </div>
</template>

<script>
export default {
  name: 'ElInputTag',
  props: {
    value: {
      type: String,
      default: () => '',
    },
    addTagOnKeys: {
      type: Array,
      default: () => ['Enter', 'Comma', 'Tab'],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: () => 'default',
    },
    arrayCondition: {
      type: Array,
      default: () => [],
    },
    size: String,
    placeholder: String,
  },
  data() {
    return {
      newTag: '',
      innerTags: [...this.value],
    }
  },
  watch: {
    value() {
      this.innerTags = [...this.value]
    },
  },
  methods: {
    validateEmail(email) {
      if (this.action === 'approval-request') {
        return this.arrayCondition.includes(String(email).toLowerCase())
      } else {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      }
    },
    focusTagInput() {
      if (this.readOnly || !this.$el.querySelector('.tag-input')) {
        return
      } else {
        this.$el.querySelector('.tag-input').focus()
      }
    },
    inputTag(ev) {
      this.newTag = ev.target.value
    },
    addNew(e) {
      if (e && !this.addTagOnKeys.includes(e.code) && e.type !== 'blur') {
        return
      }
      if (e) {
        e.stopPropagation()
        e.preventDefault()
      }
      let addSuccess = false
      if (this.newTag.includes('，') || this.newTag.includes(',') || this.newTag.includes('、')) {
        const re = /\s*,\s*|\s*，\s*|\s*、\s*/g
        this.newTag.split(re).forEach(item => {
          if (this.addTag(item.trim())) {
            addSuccess = true
          }
        })
      } else {
        if (this.addTag(this.newTag.trim())) {
          addSuccess = true
        }
      }
      if (addSuccess) {
        this.tagChange()
        this.newTag = ''
      }
    },
    addTag(tag) {
      tag = tag.replace(/\s+/g, '')
      if (tag) {
        this.innerTags.push(tag)
        this.innerTags = [...new Set(this.innerTags)]
        return true
      }
      return false
    },
    remove(index) {
      this.innerTags.splice(index, 1)
      this.tagChange()
    },
    removeLastTag() {
      if (this.newTag) {
        return
      }
      this.innerTags.pop()
      this.tagChange()
    },
    tagChange() {
      this.$emit('input', this.innerTags)
    },
  },
}
</script>

<style scoped>
.el-form-item.is-error .el-input-tag {
  border-color: #f56c6c;
}

.input-tag-wrapper {
  position: relative;
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  outline: none;
  padding: 0 10px 0 5px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}

.el-tag {
  margin-right: 4px;
}

.tag-input {
  background: transparent;
  border: 0;
  font-size: inherit;
  outline: none;
  padding-left: 0;
  width: 100px;
}

.el-input-tag {
  min-height: 42px;
}

.el-input-tag--mini {
  min-height: 28px;
  line-height: 28px;
  font-size: 12px;
}

.el-input-tag--small {
  min-height: 32px;
  line-height: 32px;
}

.el-input-tag--medium {
  min-height: 36px;
  line-height: 36px;
}
.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 10%;
  border-width: 5px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: gray;
  color: #fff;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.font-size-13 {
  font-size: 13px !important;
}
</style>
