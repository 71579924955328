<template>
  <div id="pixel-size-tab" v-loading="loading">
    <el-checkbox-group :value="selectedChild" @input="changeSelected">
      <div id="scroll-area" class="scroll" :style="{ minHeight: height - 16 - 32 - 16 + 'px' }">
        <el-row class="mb-2" v-for="(assets, i) in chunkedAssets" :key="`assset_${i}`">
          <el-col :span="4" style="width: 20%" v-for="asset in assets" :key="`asset_${asset.assetId}`">
            <asset-item-box
              class="ms-1"
              :asset="asset"
              :whitelist="whitelist"
              :is-in-dialog="isInDialog"
              :is-display="isDisplay"
              :isDisplayStatus="true"
              :is-asset-master="assetMaster"
              :idOpenError="idOpenError"
              @open-version-dialog="originId => $refs.versionDialog.show(originId)"
              @change-id-open-error="assetId => changeIdOpenError(assetId)"
              @get-assets="$emit('get-assets')"
              @change-creator-comment="v => $emit('change-creator-comment', v, asset.originId, asset.aspectRatio)"
              @change-approval-status="v => $emit('change-approval-status', v, asset.assetId, asset.aspectRatio)"
              @change-psd-url="v => $emit('change-psd-url', v, asset.originId, asset.aspectRatio)"
            />
          </el-col>
        </el-row>
      </div>
    </el-checkbox-group>

    <div class="text-center">
      <el-pagination
        class="mt-1"
        background
        :current-page.sync="currentPage"
        :page-count="pageCount"
        @current-change="changeCurrentPage"
      />
    </div>
  </div>
</template>

<style scoped>
.text-center {
  text-align: center;
}
.mt-1 {
  margin-top: 1rem;
}
.ms-1 {
  margin: 0 5px;
}
.mb-2 {
  margin-bottom: 1rem;
}
.mb-2:last-child {
  margin-bottom: 0;
}
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'
import AssetItemBox from '@/views/image-movie-master/pixel-size-tab/asset-item-box'

export default {
  name: 'pixel-size-tab',
  mixins: [util],
  components: { AssetItemBox },
  props: {
    selected: { type: Array, default: () => [] },
    oldSelected: { type: Array, default: () => [] },
    assets: { type: Array, default: () => [] },
    pixelSize: { type: Object, default: () => ({}) },
    height: { type: Number, default: () => null },
    isDisplay: { type: Boolean, default: () => false },
    isInDialog: { type: Boolean, default: () => false },
    displayType: { type: String, default: () => '' },
    aspectRatio: { type: String, default: () => '' },
    whitelist: { type: Array, default: () => [] },
    assetMaster: { type: Boolean, default: () => true },
  },
  data: () => ({ loading: false, currentPage: 1, pageSize: 60, idOpenError: '' }),
  created() {
    window.PixelSizeTab = this
  },
  methods: {
    changeIdOpenError(assetId) {
      this.idOpenError = assetId
    },

    changeSelected(gr) {
      const selected = _.chain(this.formatedAssets)
        .filter(r => _.includes(gr, r.originId))
        .map(r => _.pick(r, ['originId', 'assetId']))
        .orderBy('originId')
        .value()

      this.$emit('change-selected-at-tab', { selected, pixelSize: this.pixelSize })
    },
    changeCurrentPage() {
      document.getElementById('scroll-area').scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
  computed: {
    pageCount() {
      return _.ceil(this.assets.length / this.pageSize)
    },
    chunkedAssets() {
      return _.chain(this.assets)
        .cloneDeep()
        .filter((r, i) => (this.currentPage - 1) * this.pageSize <= i + 1 && i + 1 <= this.currentPage * this.pageSize)
        .map(r => _.assign({}, r, !r.aspectRatio ? { aspectRatio: this.aspectRatio } : {}))
        .chunk(5)
        .value()
    },
    selectedChild() {
      const originIds = _.chain(this.formatedAssets).map('originId').value()
      return _.chain(this.selected)
        .filter(r => _.includes(originIds, r.originId))
        .map('originId')
        .value()
    },
    formatedAssets() {
      return _.chain(this.assets).concat(this.oldSelected).uniqBy().compact().value()
    },
  },
}
</script>
