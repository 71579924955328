var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"id":"upload-confirmation-preview-dialog","title":"画像/動画アップロード","lock-scroll":"","append-to-body":"","fullscreen":"","visible":_vm.isShow,"show-close":false,"close-on-press-escape":false,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.isShow=$event}}},[(true)?_c('div',{staticClass:"scroll",style:({ height: _vm.height + 'px' })},[_c('div',{staticClass:"warning-text"},[_vm._v(" アップロードしようとしているファイルの中に、バージョンアップ・ファイル名変更が発生するものがあります。そのままアップロードするにはチェックを入れてください。 ")]),_c('el-table',{ref:"confirmAssetTable",staticClass:"confirmation-box table-margin",attrs:{"border":"","data":_vm.comparisionContent},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"label":"アップロード予定の画像動画","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var currentURL = ref_row.currentURL;
var currentFileName = ref_row.currentFileName;
return [_c('span',[_vm._v(_vm._s(currentFileName))]),_c('image-movie-item',{attrs:{"url":currentURL,"thumbnail-url":currentURL,"type":_vm.judgeFileType(currentFileName.substr(currentFileName.lastIndexOf('.') + 1))}})]}}],null,false,1812157090)}),_c('el-table-column',{attrs:{"label":"1つ前のバージョン","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var existedURL = ref_row.existedURL;
var existedFileName = ref_row.existedFileName;
return [(existedURL !== null)?_c('div',[_c('span',[_vm._v(_vm._s(existedFileName))]),_c('image-movie-item',{attrs:{"url":existedURL,"thumbnail-url":existedURL,"type":_vm.judgeFileType(existedFileName.substr(existedFileName.lastIndexOf('.') + 1))}})],1):_vm._e()]}}],null,false,1704415220)}),_c('el-table-column',{attrs:{"label":"処理結果","prop":"reason"}})],1)],1):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleConfirm}},[_vm._v(" アップロード確定 ")]),_c('el-button',{on:{"click":_vm.handleCancel}},[_vm._v(" 閉じる ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }