<template>
  <div id="asset-area1" ref="assetArea" :style="{ borderColor: getBorderColor(isAssetCanEdit || isInDialog) }">
    <el-row v-if="creative.assets.length !== 0">
      <div class="float-left w-100 word-break" v-if="creative.assets.length === 1">
        <status-area
          :status-selected="creative.first.approvalStatus"
          :disabled="isStatusAreaDisabled(isAssetCanEdit)"
          @change-status="
            status => $emit('change-status-asset', creative.first.assetId, status, creative.first.version)
          "
        />
        <div class="my-1" :style="{ color: '#000' }">{{ creative.first.labelName }}</div>
        <div class="thumb-name">
          <span class="thumb-ver" v-text="'v' + creative.first.version"></span>
          <section v-if="isShowFullName">
            <span class="mb-1 word-break name w-100">
              {{ creative.first.metadata.fileName }}
            </span>
            <span class="mb-1 word-break name w-100">
              {{ creative.first.metadata.rawFileName }}
            </span>
          </section>
          <el-tooltip
            class="item"
            effect="light"
            :content="creative.first.metadata.fileName"
            placement="top-start"
            :visible-arrow="false"
            v-else
          >
            <span
              class="name"
              v-text="creative.first.metadata.fileName"
              :style="{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: '#060630',
                fontSize: '12px',
                fontWeight: 400,
              }"
            ></span>
          </el-tooltip>
        </div>
        <image-movie-item
          class="float-left w-100 h-100"
          parentComponent="creative"
          :url="creative.first.url"
          :thumbnail-url="creative.first.thumbnailUrl"
          :type="creative.first.assetType"
          :isMask="false"
        />
      </div>

      <div class="float-left w-99 word-break" v-if="creative.assets.length - 1 !== 0">
        <el-carousel
          ref="assetCarousel"
          indicator-position="none"
          :autoplay="false"
          :loop="false"
          @change="index => $emit('change-active-index', index)"
        >
          <el-carousel-item
            v-for="(
              {
                assetId,
                labelName,
                url,
                thumbnailUrl,
                assetType,
                approvalStatus,
                version,
                metadata: { fileName, rawFileName },
                isLatest,
              },
              k
            ) in creative.assets"
            :key="k"
          >
            <status-area
              :status-selected="approvalStatus"
              :disabled="isStatusAreaDisabled(isAssetCanEdit)"
              @change-status="status => $emit('change-status-asset', assetId, status, version)"
            />
            <div class="my-1" :style="{ color: '#000' }">{{ labelName }}</div>
            <div class="thumb-name">
              <span class="thumb-ver" v-text="'v' + version"></span>
              <section v-if="isShowFullName">
                <span class="mb-1 word-break name">
                  {{ fileName }}
                </span>
                <span class="mb-1 word-break name">
                  {{ rawFileName }}
                </span>
              </section>
              <el-tooltip
                class="item"
                effect="light"
                :content="creative.first.metadata.fileName"
                placement="top-start"
                :visible-arrow="false"
                v-else
              >
                <span
                  class="name"
                  v-text="creative.first.metadata.fileName"
                  :style="{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#060630',
                    fontSize: '12px',
                    fontWeight: 400,
                  }"
                ></span>
              </el-tooltip>
            </div>
            <div
              @click="$refs.previewDialog.show(creative.labeledAssets, creative.textSet.labeledTexts)"
              class="carousel__asset"
            >
              <image-movie-item
                class="w-100 h-100"
                parentComponent="creative"
                :url="url"
                :thumbnail-url="thumbnailUrl"
                :type="assetType"
                :no-preview="true"
                :isMask="true"
              />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-row>

    <preview-dialog ref="previewDialog" :platform-id="platformId" :ad-format-name="adFormatName" />
  </div>
</template>

<style>
#asset-area1 {
  padding-top: 0.5rem;
  border-right: 1px solid;
  padding-right: 4px;
}

#asset-area1 .el-carousel__arrow {
  background-color: rgba(31, 45, 61);
  top: 78%;
}

#asset-area1 .el-carousel__container {
  height: 100%;
  min-height: 350px;
  overflow: hidden;
}

#asset-area1 .el-carousel__item {
  height: max-content;
  display: flex;
  flex-direction: column;
}

#asset-area1 .el-carousel__item > * {
  padding: 0 2px;
}

#asset-area1 .el-carousel__item .carousel__asset {
  flex: 1;
}

#asset-area1 .el-carousel__item .carousel__asset > div.w-100.h-100 {
  display: flex;
  align-items: flex-end;
}

#asset-area1 .el-carousel__item .carousel__asset > div.w-100.h-100 > div {
  width: 100%;
}

#asset-area1 .el-carousel__item .carousel__asset .video-wrap {
  display: flex;
  align-items: center;
}

#asset-area1 .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
  padding: 30px 0;
}

.el-tooltip__popper.is-light {
  border: none !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
}
</style>

<style scoped>
.my-1 {
  margin: 0.5rem 0;
}

.w-99 {
  width: 99%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.float-left {
  float: left;
}

.word-break {
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
}

.thumb-name {
  align-items: start;
  flex-wrap: nowrap;
}

.thumb-name:has(.word-break) {
  white-space: pre-wrap;
}

.thumb-name section {
  margin: 0;
  display: inline-block;
  flex: 1;
  white-space: pre-wrap;
}

.thumb-name .name {
  color: #060630;
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 12px;
}
</style>

<script>
import ImageMovieItem from '@/components/image-movie-item'
import PreviewDialog from '@/views/creative-list/ad-format-tab-list/components/preview-dialog'
import StatusArea from './status-area.vue'
import { NOT_ABLE_CHANGE_STATUS_MSG } from '@/mixins/constant'
import util from '@/mixins/util'

export default {
  name: 'asset-area',
  mixins: [util],
  components: {
    PreviewDialog,
    ImageMovieItem,
    StatusArea,
  },
  props: {
    platformId: {
      type: String,
      default: () => '',
    },
    adFormatName: {
      type: String,
      default: () => '',
    },
    creative: {
      type: Object,
      default: () => ({}),
    },
    isShow: {
      type: Boolean,
      default: () => false,
    },
    isInDialog: {
      type: Boolean,
      default: () => false,
    },
    isAssetCanEdit: {
      type: Boolean,
      default: () => true,
    },
    isShowFullName: {
      type: Boolean,
      default: () => false,
    },
    canChangeStatus: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    assetWidth: 250,
    backgroundColor: null,
    color: null,
    isCreativeListComp: true,
    NOT_ABLE_CHANGE_STATUS_MSG: NOT_ABLE_CHANGE_STATUS_MSG,
  }),
  created() {
    window.AssetArea = this
    window.addEventListener('resize', () => {
      this.changeAssetHeight()
    })
    this.color = '#ffffff'
  },
  async mounted() {
    await this.$nextTick()
    this.changeAssetHeight()
    if (this.isShowFullName) {
      this.changeCarouselHeight()
    }
  },
  watch: {
    async isShow() {
      if (!this.isShow) return

      await this.$nextTick()
      this.changeAssetHeight()
      if (this.isShowFullName) {
        this.changeCarouselHeight()
      }
    },
  },
  methods: {
    changeAssetHeight() {
      if (!this.$refs.assetArea) return
      this.assetWidth = this.$refs.assetArea.clientWidth
    },
    changeCarouselHeight() {
      // if creative has less than 2 assets mean there is no carousel
      if (this.creative.assets.length < 2) return

      // check if carousel is existed
      const carouselRef = this.$refs.assetCarousel
      if (!carouselRef) return

      // get max asset item height
      const bufferHeight = 30
      const carouselItemHeights = carouselRef.$children.map(item => item.$el.offsetHeight)
      const itemHeight = Math.max(...carouselItemHeights) + bufferHeight

      if (itemHeight === bufferHeight) return
      // set carousel height based on child item
      carouselRef.$el.style.height = `${itemHeight}px`
      for (const item of carouselRef.$children) {
        item.$el.style.height = `${itemHeight}px`
      }
    },
    setColorText(status) {
      switch (status) {
        case '監修中':
          return '#fff'
        case '要修正':
          return '#fff'
        case '承認待ち':
          return '#fff'
        case '入稿依頼済':
          return '#fff'
        case '入稿済':
          return '#fff'

        case '承認':
          return '#fff'
        case '入稿可能':
          return '#fff'

        case '入稿不可':
          return '#06063096'
        case '破棄':
          return '#06063096'

        default:
          return 'rgb(6 6 48 / 45%)'
      }
    },
    setBackgroundColorApprovalStatus(status) {
      switch (status) {
        case '監修中':
          return '#909399'
        case '要修正':
          return '#ef342f'
        case '承認待ち':
          return '#909399'
        case '入稿依頼済':
          return '#ffa500'
        case '入稿済':
          return '#084c6c'

        case '承認':
          return 'rgb(91, 184, 93)'
        case '入稿可能':
          return 'rgb(91, 184, 93)'
        case '破棄':
          return 'rgba(245,245,245,0.48)'

        case '入稿不可':
          return 'rgba(245,245,245,0.48)'

        default:
          return 'white'
      }
    },
    isStatusAreaDisabled(isLatest) {
      return !this.canChangeStatus || !isLatest
    },
  },
}
</script>
