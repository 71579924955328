<template>
  <el-dialog
    id="creative-dialog"
    title="クリエイティブ選択"
    lock-scroll
    fullscreen
    :before-close="close"
    :visible.sync="isShowDialog"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-container :style="{ height: drawerHeight - 12 + 'px', overflowY: 'unset' }" v-loading="loading">
      <el-col :span="24" class="dialog-body">
        <div>
          <select-bar
            v-if="!isInDialog"
            :selected="selected"
            :is-tabs-exists="tabs.length !== 0"
            :is-in-dialog="isInDialog"
            :sort-type="sortType"
            :is-search-open="isDrawerShow"
            :is-sort-type-open="isSortTypeDrawerShow"
            :is-pop-up="true"
            target-type="creative"
            @reload="reload"
            @change-selected="changeSelected"
            @edit-note="editNote"
            @change-sort-type="v => (sortType = v)"
            @toggle-search="toggleSearch"
            @toggle-sort-type="toggleSortType"
            @delete-selected="deleteSelected"
          />

          <el-collapse-transition>
            <drawer
              v-show="isDrawerShow"
              ref="drawer"
              :tags="tags"
              :selected-promotion-ids="selectedPromotionIds"
              @get-creatives="getCreatives"
            />
          </el-collapse-transition>

          <el-collapse-transition>
            <sort-type-creative
              v-if="isSortTypeDrawerShow"
              class="expandable"
              :sort-type="sortType"
              @toggle-sort-type="toggleSortType"
              @change-sort-type="v => (sortType = v)"
            />
          </el-collapse-transition>
        </div>

        <el-main>
          <el-tabs
            v-model="activeTab"
            type="border-card"
            @tab-remove="name => removeTab(name, true)"
            @tab-click="clickTab"
          >
            <el-tab-pane name="add" label="add" class="p-15px">
              <span slot="label">
                <i class="fas fa-plus-circle" />
              </span>

              <div :style="{ height: tabHeight - 25 + 'px' }">
                <div>【スタート方法】</div>
                <ul>
                  <li>
                    広告フォーマットを指定して表示:
                    <i class="fas fa-plus-circle" /> ボタンを押して媒体･広告フォーマットを選択
                  </li>
                  <li>詳細検索: サイドバーの検索ボタン（検索条件無しで「検索」を押すと全件表示）</li>
                </ul>
              </div>
            </el-tab-pane>

            <el-tab-pane v-for="(r, i) in tabs" :key="i" :name="r.platformId" :label="r.platformId" lazy closable>
              <span slot="label">
                <i class="fas fa-home" />
                {{ r.platformId }}
              </span>

              <el-tabs
                v-model="r.activeName"
                type="border-card"
                @tab-remove="name => removeTab(name, false, r.platformId)"
              >
                <el-tab-pane
                  v-for="({ name, adFormatId, platformId }, i) in r.creatives"
                  :key="i"
                  :name="name"
                  :label="name"
                  :closable="$route.path !== '/creative-regist'"
                  lazy
                >
                  <ad-format-tab-list
                    :ad-formats="adFormats"
                    :selected="selectedDraft"
                    :platform-id="platformId"
                    :ad-format-id="adFormatId"
                    :ad-format-name="name"
                    :creatives="groupByCreatives[adFormatId]"
                    :height="tabHeight"
                    :creative-ids="query.creativeIds || []"
                    :errors="errors"
                    :is-in-dialog="isInDialog"
                    :is-show="isShow"
                    :is-update="isUpdate"
                    :is-creative-list="true"
                    :display-type="'default'"
                    :sort-type="sortType"
                    @update-creative="updateCreatorCommentAndReferenceUrl"
                    @change-grouped-creatives="changeGroupedCreatives"
                    @change-query="q => (query = q)"
                    @change-reference-url="(urls, crId, platformId) => changeReferenceUrl(urls, crId, platformId)"
                    @change-creator-comment="changeCreatorComment"
                    @change-selected-at-tab="changeSelectedAtTab"
                    @get-creatives="getCreatives"
                    @change-note="changeNote"
                    @reset="reset"
                    @edit-note-creatives="editNoteCreatives"
                    @change-compare-creative="r => $emit('change-compare-creative', r)"
                    @open-proposed-dialog="
                      (textSetId, adFormatId) => $refs.proposedTextCreativeDialog.show(textSetId, adFormatId)
                    "
                  />
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
          </el-tabs>
        </el-main>
      </el-col>
    </el-container>
    <select-ad-format-dialog ref="selectAdFormatDialog" :ad-formats="adFormats" @add-ad-format="addAdFormat" />

    <span v-if="this.selected.length !== 0" slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitCreatives"> 監修依頼に追加 </el-button>
    </span>
  </el-dialog>
</template>

<style>
#creative-dialog .el-main {
  padding: 10px 20px 45px 20px !important;
}

#creative-dialog .el-tabs__content {
  padding: 0;
}

#creative-dialog > .el-dialog > .el-dialog__footer {
  position: fixed;
  width: 100%;
}
</style>

<style scoped>
.el-date-editor.el-input {
  width: 155%;
}

.p-15px {
  padding: 15px;
}

.footer {
  padding: 10px 20px 10px;
}
</style>

<script>
import SelectAdFormatDialog from '@/components/select-ad-format-dialog'
import SelectBar from '@/components/select-bar'
import AdFormatTabList from '@/views/creative-list/ad-format-tab-list'
import Drawer from '@/views/creative-list/components/drawer'
import SortTypeCreative from '@/views/creative-list/components/sort-type'
import util from '@/mixins/util'
import _ from 'lodash'
import { CreativeStatus } from '@/mixins/creativeStatus'

export default {
  name: 'creative-dialog',
  components: {
    SelectAdFormatDialog,
    SelectBar,
    AdFormatTabList,
    Drawer,
    SortTypeCreative,
  },
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
    adFormatId: { type: String, default: () => '' },
    queryPlatformIds: { type: Array, default: () => [] },
    queryAdFormatIds: { type: Array, default: () => [] },
    isShow: { type: Boolean, default: () => false },
    isInDialog: { type: Boolean, default: () => false },
    selectedCreative: { type: Object, default: () => ({}) },
  },
  mixins: [util],
  data: () => ({
    isShowDialog: false,
    bodies: null,
    loading: false,
    isSortTypeDrawerShow: false,
    isDrawerShow: false,
    isUpdate: false,
    selected: [],
    selectedDraft: [],
    groupedCreatives: [],
    tabs: [],
    allTabs: [],
    activeTab: 'add',
    innerHeight: window.innerHeight,
    destination: {},
    approvalCreatives: [],
    items: [],
    groupedCreativesDraft: [],
    query: {},
    adFormatIds: [],
    errors: [],
    sortType: 'df',
    isContainAssetCR: false,
  }),
  async created() {
    window.CreativeDialog = this
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })
  },
  methods: {
    submitCreatives() {
      // check status Creative is includes status can't create Approval Request
      const creativeStatuses = this.selected.map(cr => cr.approvalStatus)
      const cannotCreateAR = creativeStatuses.find(status => CreativeStatus.NO_CREATE_AR.includes(status)) !== undefined
      const isStatusTextNG = _.chain(this.selected).map('textSet').map('approvalStatus').uniq().includes('破棄').value()
      const isStatusAssetNG = _.chain(this.selected)
        .map('labeledAssets')
        .flattenDeep()
        .map('assets')
        .flattenDeep()
        .map('approvalStatus')
        .uniq()
        .includes('破棄')
        .value()
      if (cannotCreateAR || isStatusAssetNG || isStatusTextNG) {
        this.$alert(
          '入稿不可のステータスを持っているCR・素材・テキストがありますので、 監修依頼に追加できません。\n' +
            'ご確認お願いします。',
          'アラート'
        )
        return true
      } else {
        this.$emit('submit-creatives', this.selected)
        this.close()
      }
    },
    show() {
      this.isShowDialog = true
      this.bodies = null
      this.isSortTypeDrawerShow = false
      this.isDrawerShow = false
      this.isUpdate = false
      this.selected = []
      this.selectedDraft = []
      this.groupedCreatives = []
      this.tabs = []
      this.allTabs = []
      this.activeTab = 'add'
      this.destination = {}
      this.approvalCreatives = []
      this.items = []
      this.groupedCreativesDraft = []
      this.adFormatIds = []
      this.errors = []
      this.sortType = 'df'
      this.isContainAssetCR = false
    },
    toggleSearch() {
      this.isSortTypeDrawerShow = false
      this.isDrawerShow = !this.isDrawerShow
    },
    toggleSortType() {
      this.isDrawerShow = false
      this.isSortTypeDrawerShow = !this.isSortTypeDrawerShow
    },
    close() {
      const query = Object.assign({}, this.$route.query)
      if (query.creativeListForm) {
        delete query.creativeListForm
        this.$router.replace({ query })
      }
      this.$refs.drawer.reset()
      this.isSortTypeDrawerShow = false
      this.isDrawerShow = false
      this.isShowDialog = false
    },
    changeCreatorComment(creatorComment, creativeId, platformId) {
      this.groupedCreativesDraft = _.chain(this.groupedCreativesDraft)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 =>
            r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { creatorComment })
          )
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selectedDraft = _.chain(this.selectedDraft)
          .cloneDeep()
          .map(r => (r.creativeId !== creativeId ? r : _.assign({}, r, { creatorComment })))
          .value()
      }
    },
    changeReferenceUrl(urls, creativeId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 =>
            r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { referenceUrls: urls })
          )
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selected = _.chain(this.selected)
          .cloneDeep()
          .map(r => (r.creativeId !== creativeId ? r : _.assign({}, r, { referenceUrls: urls })))
          .value()
      }
    },
    changeCreatorCommentAsset(creatorComment, originId, labelId, platformId, creativeId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 => {
            if (r2.creativeId !== creativeId) return r2

            const labeledAssets = _.map(r2.labeledAssets, r3 => {
              if (r3.labelId !== labelId) return r3
              const assets = _.map(r3.assets, r4 =>
                r4.originId !== originId ? r4 : _.assign({}, r4, { creatorComment })
              )
              return _.assign({}, r3, { assets })
            })
            return _.assign({}, r2, { labeledAssets })
          })
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selected = _.chain(this.selected)
          .cloneDeep()
          .map(r => {
            const labeledAssets = _.map(r.labeledAssets, r2 => {
              if (r2.labelId !== labelId) return r2
              const assets = _.map(r2.assets, r3 =>
                r3.originId !== originId ? r3 : _.assign({}, r3, { creatorComment })
              )
              return _.assign({}, r2, { assets })
            })
            return _.assign({}, r, { labeledAssets })
          })
          .value()
      }
    },
    changeCreatorCommentText(creatorComment, textSetId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 => {
            if (!r2.textSet || r2.textSet.textSetId !== textSetId) return r2

            const textSet = _.assign({}, r2.textSet, { creatorComment })
            return _.assign({}, r2, { textSet })
          })
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selected = _.chain(this.selected)
          .cloneDeep()
          .map(r2 => {
            if (!r2.textSet || r2.textSet.textSetId !== textSetId) return r2
            const textSet = _.assign({}, r2.textSet, { creatorComment })
            return _.assign({}, r2, { textSet })
          })

          .value()
      }
    },
    changeNote(note, creativeId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 => (r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { note })))
          return _.assign({}, r, { creatives })
        })
        .value()
    },
    async editNote(note) {
      const creatives = _.map(this.selected, r => {
        const { creatives } = _.find(this.groupedCreatives, r2 => r.platformId === r2.platformId)
        const creative = _.find(creatives, r2 => r.creativeId === r2.creativeId)
        const labeledAssets = _.map(creative.labeledAssets, r2 => ({
          labelId: r2.labelId,
          originIds: _.map(r2.assets, 'originId'),
        }))
        return {
          creativeId: _.result(creative, 'creativeId') || '',
          creativeType: _.result(creative, 'creativeType') || '',
          adFormatId: _.result(creative, 'adFormat.id') || '',
          textSetId: _.result(creative, 'textSet.textSetId') || '',
          labeledAssets,
          score: _.result(creative, 'score') || null,
          deviationScore: _.result(creative, 'deviationScore') || null,
          deviationRank: _.result(creative, 'deviationRank') || null,
          note,
          creatorComment: _.result(creative, 'creatorComment') || null,
        }
      })

      await this.editNoteCreatives(note, creatives)
    },
    async editNoteCreatives(note, creatives) {
      const urlPromotionId = _.isArray(this.$route.query.promotionIds)
        ? this.$route.query.promotionIds[0]
        : this.$route.query.promotionIds
      this.loading = true
      const result = await this.$api.authFetch('/creative', {
        method: 'PUT',
        body: { promotionId: urlPromotionId, creatives },
      })
      this.loading = false
      this.selected = this.selected.map(cr => {
        // creatives only have 1 element show [0] to get it for update
        if (creatives[0].creativeId == cr.creativeId) {
          cr.note = note
        }
        return cr
      })

      if (result && result.errors) {
        this.errors = result.errors.map(x => {
          return x.labelNames.join(', ').concat(`: ${x.error}`)
        })
        await this.$alert('規定エラーがあります。エラーボタンから確認できます。', '確認', {
          type: 'warning',
        })
      } else this.getCreatives()
    },
    updateListCreative(selected, groupCreative) {
      this.selected = selected
      this.groupedCreatives = groupCreative
      this.isUpdate = !this.isUpdate
    },
    async deleteSelected() {
      const ret = await this.$confirm('クリエイティブを削除しますか?', '確認').catch(e => e)
      if (ret === 'cancel') return
      const param = _.map(this.selected, 'creativeId')
      this.loading = true
      const res = await this.$api.authFetch('/creative', {
        method: 'DELETE',
        query: { creativeId: param },
      })
      const creativeIds = _.chain(this.query.creativeIds)
        .filter(x => !param.includes(x))
        .value()
      this.query = _.assign({}, this.query, { creativeIds: creativeIds })
      if (typeof res !== 'undefined') {
        this.selected = []
        await this.getCreatives()
      }

      this.loading = false
    },
    changeSelectedAtTab({ selected, platformId, adFormatId }) {
      const selectedDraft = _.map(selected, v =>
        _.find(this.groupByCreativesOld[adFormatId], r => r.creativeId === v.creativeId)
      )
      const formatedSelected = _.map(selectedDraft, r => _.assign({}, r, { platformId, adFormatId }))
      if (this.selected.length === 0) {
        this.selected = formatedSelected
        return
      }

      this.selected = _.chain(this.selected)
        .filter(r => r.adFormatId !== adFormatId)
        .concat(formatedSelected)
        .uniqBy('creativeId')
        .value()
    },
    changeSelected(isAll, isOnlyTab, isDeselectTab) {
      if (!isAll) {
        this.selected = []
        return
      }

      const selectedAdFormatIds = isOnlyTab
        ? _.chain(this.tabs)
            .filter(r => r.platformId === this.activeTab)
            .map(r => _.filter(r.creatives, r2 => r2.name === r.activeName))
            .flattenDeep()
            .map('adFormatId')
            .filter()
            .uniq()
            .value()
        : _.chain(this.tabs).map('creatives').flattenDeep().map('adFormatId').filter().uniq().value()

      const creativeList = _.chain(this.allTabs)
        .map(r => _.map(r.creatives, r2 => ({ platformId: r.platformId, adFormatId: r2.adFormatId })))
        .flattenDeep()
        .filter(r => _.includes(selectedAdFormatIds, r.adFormatId))
        .map(r => _.map(this.groupByCreatives[r.adFormatId], r2 => _.assign({}, r, r2)))
        .flattenDeep()
        .map(r => {
          const asset = _.result(r, 'labeledAssets[0].assets[0]')
          return _.assign({}, r, { asset })
        })
        .orderBy(['asset.metadata.fileName', 'textSet.textSetId'], ['asc', 'asc'])
        .value()

      const creativesCanCreateAR = creativeList.filter(cr => !CreativeStatus.NO_CREATE_AR.includes(cr.approvalStatus))

      this.selected = isDeselectTab
        ? this.selected.filter(x => {
            return !_.includes(
              creativesCanCreateAR.map(y => _.isEqual(y.creativeId, x.creativeId)),
              true
            )
          })
        : creativesCanCreateAR
    },
    clickTab({ label }) {
      if (label === 'add') this.$refs.selectAdFormatDialog.show()
    },
    async removeTab(targetName, isParent, platformId) {
      this.tabs = []
      await this.$nextTick()

      if (isParent) {
        this.tabs = _.filter(this.allTabs, r => r.platformId !== targetName)
        if (this.activeTab === targetName) {
          this.activeTab =
            _.map(this.tabs, 'platformId').length === 0 ? 'add' : _.chain(this.tabs).map('platformId').first().value()
        }
      } else {
        const parentNames = _.map(this.allTabs, 'platformId')
        this.tabs = _.chain(this.allTabs)
          .map(r => {
            const childrenNames = _.map(r.creatives, 'name')
            const activeName =
              r.activeName === targetName
                ? r.activeName
                : childrenNames.length === 0
                ? ''
                : childrenNames[_.indexOf(childrenNames, targetName) + 1]
            const creatives = _.filter(r.creatives, r2 => r2.name !== targetName)
            return _.assign({}, r, { activeName, creatives })
          })
          .filter(r => r.creatives.length !== 0)
          .value()

        if (this.activeName !== platformId) {
          this.activeTab = parentNames.length === 0 ? 'add' : parentNames[_.indexOf(parentNames, platformId)]
        }

        this.tabs = _.chain(this.allTabs)
          .mapValues(r => {
            const creatives = _.filter(r.creatives, r2 => r2.name !== targetName)
            return creatives.length === 0 ? {} : _.assign({}, r, { creatives })
          })
          .omitBy(_.isEmpty)
          .value()
      }

      this.allTabs = _.cloneDeep(this.tabs)
      if (_.isEmpty(this.tabs)) return

      const keyBySelectedAdFormatIds = _.chain(this.tabs)
        .map('creatives')
        .flattenDeep()
        .uniqBy('adFormatId')
        .keyBy('adFormatId')
        .value()

      this.selected = _.filter(this.selected, r => keyBySelectedAdFormatIds[r.adFormatId])
    },
    addAdFormat(gr) {
      this.adFormatIds = _.chain(this.tabs)
        .map('creatives')
        .flattenDeep()
        .map('adFormatId')
        .uniq()
        .thru(ret => (!gr ? ret : _.concat(ret, gr)))
        .filter()
        .uniq()
        .value()

      if (this.adFormatIds.length === 0) {
        this.groupedCreatives = []
        this.tabs = []
        this.allTabs = []
        this.activeTab = 'add'
        return
      }

      this.getCreatives()
    },
    async reload() {
      await this.getCreatives()
      this.selected = _.map(this.selected, r => {
        const { approvalStatus } = _.find(this.groupByCreatives[r.adFormatId], r2 => r2.creativeId === r.creativeId)
        return _.assign({}, r, { approvalStatus })
      })
    },
    getCreativeBody(params) {
      if (!params) {
        params = _.assign(
          {},
          { adFormatIds: this.adFormatIds },
          _.isEmpty(this.query) ? this.$refs.drawer.getFormParams() : this.query
        )
        this.$router.push({
          query: _.assign(
            {},
            {
              promotionIds: this.$route.query.promotionIds,
              approvalRequestId: this.$route.query.approvalRequestId,
            },
            this.query
          ),
        })
      } else if (this.adFormatId) params = _.assign(params, { adFormatIds: [this.adFormatId] })
      else if (!params || _.isEmpty(params))
        params = { adFormatIds: _.chain(this.tabs).map('adFormatId').filter().uniq().value() }
      return params
    },
    async getCreatives(params) {
      const urlPromotionId = _.isArray(this.$route.query.promotionIds)
        ? this.$route.query.promotionIds
        : [this.$route.query.promotionIds]

      const newParams = this.getCreativeBody(params)
      const isEmpty = Object.values(newParams).findIndex(bd => !_.isEmpty(bd)) === -1

      const convertedApprovalStatusParams = newParams.approvalStatus
        ? newParams.approvalStatus.map(value => this.convertApprovalStatusOptionsJapaneseToEnglish(value))
        : []
      const convertedParams = { ...newParams, approvalStatus: [...convertedApprovalStatusParams] }

      const body = _.assign({}, convertedParams, {
        promotionIds: this.selectedPromotionIds.length !== 0 ? this.selectedPromotionIds : urlPromotionId,
      })

      this.loading = true
      this.groupedCreatives = !isEmpty
        ? _.result(
            await this.$api.authFetch('/creative/list', {
              method: 'POST',
              body,
            }),
            'groupedCreatives'
          )
        : []
      this.loading = false

      if (this.groupedCreatives.length === 0) this.$alert('結果は0件です', '検索結果')

      this.tabs = _.chain(this.groupedCreatives)
        .map(({ platformId, creatives }) =>
          _.map(creatives, ({ adFormat: { id, name } }) => ({ platformId, adFormatId: id, name }))
        )
        .flattenDeep()
        .groupBy('name')
        .toArray()
        .flattenDeep()
        .uniqBy(r => r.adFormatId + r.platformId)
        .groupBy('platformId')
        .map((gr, platformId) => {
          const creatives = _.orderBy(gr, ['name'])
          const activeName =
            _.chain(this.tabs)
              .find(r => r.platformId === platformId)
              .result('activeName')
              .value() || _.result(creatives, '[0].name')
          return { platformId, activeName, creatives }
        })
        .orderBy(['platformId'])
        .value()

      this.allTabs = _.cloneDeep(this.tabs)
      if (this.groupedCreatives.length === 0) this.activeTab = 'add'
      else if (this.activeTab === 'add') this.activeTab = _.result(this.tabs, '[0].platformId')
      else if (!_.chain(this.allTabs).map('platformId').includes(this.activeTab).value()) {
        this.activeTab = _.chain(this.allTabs).last().result('platformId').value()
      }
    },
    async reset(isNotGetCreatives) {
      if (this.$route.path !== '/creative-regist' && !isNotGetCreatives) return

      this.selected = []
      if (!isNotGetCreatives) {
        await this.getCreatives({
          adFormatIds: this.adFormatId ? [this.adFormatId] : this.queryAdFormatIds,
          promotionIds: this.selectedPromotionIds,
        })
      }
    },
    convertArr(arr) {
      return arr
        .map(k => {
          return k
        })
        .join('\n')
    },
    updateCreatorCommentAndReferenceUrl(referenceUrls, creatorComment, creativeId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 =>
            r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { creatorComment })
          )
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selected = _.chain(this.selected)
          .cloneDeep()
          .map(r => (r.creativeId !== creativeId ? r : _.assign({}, r, { creatorComment })))
          .value()
      }
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 =>
            r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { referenceUrls: referenceUrls })
          )
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selected = _.chain(this.selected)
          .cloneDeep()
          .map(r => (r.creativeId !== creativeId ? r : _.assign({}, r, { referenceUrls: referenceUrls })))
          .value()
      }
    },
    formatTime(time) {
      return time < 10 ? '0' + time : time
    },
    getCurrentTime() {
      const now = new Date()
      return `${now.getFullYear().toString()}${this.formatTime(now.getMonth() + 1)}${this.formatTime(
        now.getDate()
      )}${this.formatTime(now.getUTCHours() + 9)}${this.formatTime(now.getMinutes())}${this.formatTime(
        now.getSeconds()
      )}`
    },
    getPlatformName(platformId) {
      return _.chain(this.adFormats)
        .find(r => platformId === r.platformId)
        .result('platformName')
        .value()
    },

    changeGroupedCreatives() {
      this.groupedCreativesDraft = _.chain(this.groupedCreativesDraft)
        .cloneDeep()
        .map(r => {
          switch (this.sortType) {
            case 'text_set': {
              const creatives = _.orderBy(
                r.creatives,
                [
                  function (r2) {
                    if (r2.textSet) {
                      return _.reduce(
                        r2.textSet.labeledTexts,
                        function (sum, r3) {
                          return _.reduce(
                            r3.textValues,
                            function (sum2, r4) {
                              return sum2 + r4.toLowerCase()
                            },
                            sum
                          )
                        },
                        ''
                      )
                    } else return ''
                  },
                  function (r2) {
                    if (r2.textSet)
                      return _.reduce(
                        r2.textSet.labeledTexts,
                        function (sum, r3) {
                          if (r3.textValues.length !== 0)
                            return _.reduce(
                              r3.textValues,
                              function (sum2, r4) {
                                return sum2 + r4.length
                              },
                              sum
                            )
                          else return 1
                        },
                        0
                      )
                    else return 0
                  },
                  'createdAt',
                  'creativeId',
                ],
                ['asc', 'asc', 'asc', 'asc']
              )
              return _.assign({}, r, { creatives })
            }
            case 'pixel_size': {
              const creatives = _.orderBy(
                r.creatives,
                [
                  function (r2) {
                    if (r2.labeledAssets) {
                      const assetTypes = _.chain(r2.labeledAssets)
                        .flatMap('assets')
                        .flatMap('assetType')
                        .uniq()
                        .sort()
                        .value()
                      if (
                        _.isEqual(assetTypes, ['Url', 'Zip']) ||
                        _.isEqual(assetTypes, ['Url']) ||
                        _.isEqual(assetTypes, ['Zip'])
                      ) {
                        return _.reduce(
                          r2.labeledAssets,
                          function (sum, r3) {
                            return _.reduce(
                              r3.assets,
                              function (sum2) {
                                return sum2 + 1
                              },
                              sum
                            )
                          },
                          99999
                        )
                      } else return 1
                    } else return 0
                  },
                  function (r2) {
                    return _.chain(r2.labeledAssets)
                      .flatMap('assets')
                      .flatMap('metadata')
                      .flatMap(item => {
                        if (item.width !== undefined) return `${item.width}x${item.height}`
                        else return ''
                      })
                      .uniq()
                      .orderBy()
                      .join('')
                      .value()
                      .toString()
                  },
                  'createdAt',
                  'updatedAt',
                ],
                ['asc', 'asc', 'asc', 'asc']
              )
              return _.assign({}, r, { creatives })
            }
            case 'ctx_file_name': {
              const creatives = _.orderBy(
                r.creatives,
                [
                  function (r2) {
                    if (r2.labeledAssets)
                      return _.reduce(
                        r2.labeledAssets,
                        function (sum, r3) {
                          if (r3.assets && r3.assets.length > 0)
                            return _.reduce(
                              r3.assets,
                              function (sum2, r4) {
                                return sum2 + r4.metadata.fileName.toLowerCase()
                              },
                              sum
                            )
                          else return sum
                        },
                        ''
                      )
                    else return ''
                  },
                  function (r2) {
                    if (r2.labeledAssets)
                      return _.reduce(
                        r2.labeledAssets,
                        function (sum, r3) {
                          if (r3.assets && r3.assets.length > 0)
                            return _.reduce(
                              r3.assets,
                              function (sum2, r4) {
                                return sum2 + r4.metadata.fileName.length
                              },
                              sum
                            )
                          else return sum
                        },
                        0
                      )
                    else return 0
                  },
                  'createdAt',
                  'creativeId',
                ],
                ['asc', 'asc', 'asc', 'asc']
              )
              return _.assign({}, r, { creatives })
            }
            default:
              return _.assign({}, r)
          }
        })
        .value()
    },
  },
  watch: {
    groupedCreatives() {
      this.items = _.cloneDeep(this.groupedCreatives)
      this.groupedCreativesDraft = _.cloneDeep(this.groupedCreatives)
    },

    selectedPromotionIds() {
      if (this.tabs.length === 0) return
      this.getCreatives(this.adFormatId ? { adFormatIds: [this.adFormatId] } : undefined)
    },
    selected() {
      this.approvalCreatives = _.cloneDeep(this.selected)
      this.selectedDraft = _.cloneDeep(this.selected)
      if (this.$route.path !== '/creative-regist') return

      if (this.selected.length === 0) return
      else if (this.selected.length >= 2) {
        this.selected = [_.first(this.selected)]
        this.$alert('比較元クリエイティブの選択は1つのみです', '選択制御')
        return
      }

      const { adFormatId, creativeId } = _.first(this.selected)
      const selectedCreative = _.find(this.groupByCreatives[adFormatId], r2 => r2.creativeId === creativeId)
      this.$emit('change-selected-creative', selectedCreative)
    },
    isShow() {
      if (this.$route.path === '/creative-regist') this.isDrawerShow = this.isShow
    },
  },
  computed: {
    tabHeight() {
      const height = this.innerHeight - 60 - 41 - 41 - 10 - 20 - (this.isDrawerShow ? 45 + 185 : 0) - 50

      return this.tabs.length === 0 ? height : height - 41
    },
    drawerHeight() {
      return this.innerHeight - 60 - 32
    },
    allPlatforms() {
      return _.chain(this.adFormats).map('platformId').uniq().value()
    },
    groupByCreatives() {
      this.changeGroupedCreatives()
      return _.chain(this.groupedCreativesDraft).map('creatives').flattenDeep().groupBy('adFormat.id').value()
    },
    groupByCreativesOld() {
      this.changeGroupedCreatives()
      return _.chain(this.groupedCreatives).map('creatives').flattenDeep().groupBy('adFormat.id').value()
    },
    tagIdByTargets() {
      return _.chain(this.groupedCreatives)
        .map('creatives')
        .flattenDeep()
        .map(r => _.map(r.tags, r2 => _.assign({}, r2, { targetId: r.creativeId })))
        .flattenDeep()
        .groupBy('tagId')
        .map(gr => ({
          targetIds: _.map(gr, 'targetId'),
          tagId: _.first(gr).tagId,
        }))
        .value()
    },
    selectedTags() {
      const targetIdsAll = _.map(this.selected, 'creativeId')
      return _.chain(this.selected)
        .map(r =>
          _.chain(this.tagIdByTargets)
            .filter(r2 => _.includes(r2.targetIds, r.creativeId))
            .map(r2 => ({ targetId: r.creativeId, tagId: r2.tagId }))
            .value()
        )
        .flattenDeep()
        .groupBy('tagId')
        .mapValues(gr => {
          const selectedTargetIds = _.map(gr, 'targetId')
          return {
            selectedTargetIds,
            isAllSelected: selectedTargetIds.length === targetIdsAll.length,
          }
        })
        .value()
    },
  },
}
</script>
