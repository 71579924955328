<template>
  <el-dialog
    id="error-dialog"
    lock-scroll
    append-to-body
    title="注意"
    :visible.sync="isShow"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div>アップロードした静止画/動画にエラーが発生しました。</div>
    <div>｢アップロード結果｣ボタンからエラーの原因を確認してください。</div>
    <div>※エラー以外の静止画/動画は正常に取り込まれています。</div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="close"> アップロード結果 </el-button>

      <el-button @click="isShow = false"> 閉じる </el-button>
    </span>
  </el-dialog>
</template>

<style>
#error-dialog .el-dialog__body {
  padding: 30px 20px !important;
}
</style>

<script>
export default {
  name: 'error-dialog',
  data: () => ({ isShow: false, result: null }),
  created() {
    window.ErrorwDialog = this
  },
  methods: {
    show(result) {
      this.isShow = true
      this.result = result
    },
    close() {
      this.$emit('show-preview', this.result)
      this.isShow = false
    },
  },
}
</script>
