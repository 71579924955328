<template>
  <div id="text-set-area">
    <el-row class="pb-1" :gutter="20">
      <el-col :span="4">
        <el-button round size="mini" class="w-100" :disabled="selectedTextSets.length === 0" @click="changeAllCollapse">
          {{ activeNames.length === 0 ? '全展開' : '全格納' }}
        </el-button>
      </el-col>

      <el-col :span="8">
        <el-button
          type="primary"
          round
          size="mini"
          class="w-100"
          :disabled="!adFormatId || adFormat.textLabels.length === 0"
          @click="$refs.textSetDialog.show()"
        >
          テキストマスタから選択
        </el-button>
      </el-col>

      <el-col :span="8">
        <el-button
          type="primary"
          round
          size="mini"
          class="w-100"
          :disabled="!adFormatId || adFormat.textLabels.length === 0"
          @click="$refs.registTextSetDialog.show(adFormat, true)"
        >
          テキスト新規作成
        </el-button>
      </el-col>
    </el-row>

    <el-row v-if="selectedTextSets.length !== 0" class="pb-1">
      <el-collapse v-model="activeNames">
        <div v-for="(r, i) in selectedTextSets" :key="i" class="float-left w-100">
          <el-collapse-item :name="`${r.textSetId}_${i}`">
            <el-row class="w-100" slot="title">
              <el-col :span="22">
                <el-row>
                  <el-col :span="textErrors.length === 0 ? 4 : 5">
                    <i class="fas fa-file-alt" />
                    <span class="ml-1">テキスト{{ i + 1 }}</span>
                    <el-tag
                      v-if="textErrors.find(t => t.textSetId === r.textSetId) !== undefined"
                      class="ml-10 error"
                      type="danger"
                      size="small"
                      @click="alertErrors(i + 1)"
                      >エラー</el-tag
                    >
                  </el-col>
                  <template v-if="!activeNames.includes(`${r.textSetId}_${i}`)">
                    <el-col :span="textErrors.length === 0 ? 10 : 9">
                      <span class="ml-1">
                        <el-tag type="info" size="small" effect="plain">
                          {{ textHeaders[i].first.labelName }}
                        </el-tag>
                        <span class="ml-1">{{ truncate(textHeaders[i].first.value) }}</span>
                      </span>
                    </el-col>

                    <el-col v-if="textHeaders[i].second" :span="9">
                      <span class="ml-1">
                        <el-tag type="info" size="small" effect="plain">
                          {{ textHeaders[i].second.labelName }}
                        </el-tag>
                        <span class="ml-1">{{ truncate(textHeaders[i].second.value) }}</span>
                      </span>
                    </el-col>
                  </template>
                </el-row>
              </el-col>
              <el-col :span="1">
                <span
                  @click="
                    () => {
                      $refs.editTextSetDialog.show(selectedTextSets[i])
                      toggleCollapse(`${r.textSetId}_${i}`)
                    }
                  "
                >
                  <i class="fas fa-edit" />
                </span>
              </el-col>

              <el-col :span="1">
                <span @click="removeSelected(r)">
                  <i class="fas fa-times-circle icon-red" />
                </span>
              </el-col>
            </el-row>

            <ad-format-tab
              :selected-promotion-ids="selectedPromotionIds"
              :ad-format-id="adFormatId"
              :text-sets="[r]"
              :is-display="true"
            />
          </el-collapse-item>
        </div>
      </el-collapse>
    </el-row>

    <text-set-dialog
      ref="textSetDialog"
      :selected-promotion-ids="selectedPromotionIds"
      :tags="tags"
      :ad-format-id="adFormatId"
      :height="height"
      @change-selected-text-sets="changeSelectedTextSets"
    />

    <regist-text-set-dialog
      ref="registTextSetDialog"
      :default-promotion-id="selectedPromotionIds[0]"
      :ad-formats="adFormats"
      :tags="tags"
      @get-new-text-set="changeSelectedTextSets"
    />

    <edit-text-set-dialog
      ref="editTextSetDialog"
      :platform-id="platformId"
      :ad-format-id="adFormatId"
      :ad-format-name="adFormat && adFormat.name ? adFormat.name : ''"
      :ad-formats="adFormats"
      :ad-format="adFormat"
      @change-text-set="gr => editText(gr)"
    />
  </div>
</template>

<style>
#text-set-area .el-collapse-item__content {
  padding-bottom: 5px;
}
#text-set-area .el-collapse-item {
  margin-bottom: 0;
}
/* .el-message-box {width: 30% !important;} */
</style>

<style scoped>
.icon-red {
  color: #f56c6c;
}
.bg-dark {
  color: #d4d4d4;
}
.w-0 {
  width: 0;
}
.w-100 {
  width: 100%;
}
.pl-1 {
  padding-left: 10px;
}
.pr-1 {
  padding-right: 10px;
}
.pb-1 {
  padding-bottom: 1rem;
}
.ml-1 {
  margin-left: 0.5rem;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px !important;
}
.float-left {
  float: left;
}
.text-center {
  text-align: center;
}
.font-big {
  font-size: 22px;
}
.error {
  margin: 0px;
  background-color: #f56c6c !important;
  border-color: #f56c6c !important;
  color: #ffffff !important;
}
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'
import TextSetDialog from '@/views/creative-regist/creative-item/text-set-area/components/text-set-dialog'
import EditTextSetDialog from '@/views/text-master/ad-format-tab/components/edit-text-set-dialog'
import AdFormatTab from '@/views/text-master/ad-format-tab'
import RegistTextSetDialog from '@/views/text-master/regist-text-set-dialog'

export default {
  name: 'text-set-area',
  components: { TextSetDialog, AdFormatTab, RegistTextSetDialog, EditTextSetDialog },
  mixins: [util],
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    adFormatId: { type: String, default: () => '' },
    adFormats: { type: Array, default: () => [] },
    adFormat: { type: Object, default: () => ({ assetLabels: [], textLabels: [] }) },
    selectedTextSets: { type: Array, default: () => [] },
    textErrors: { type: Array, default: () => [] },
    platformId: { type: String, default: () => '' },
  },
  data: () => ({ activeNames: [], innerHeight: window.innerHeight }),
  created() {
    window.TextSetArea = this
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })
  },
  methods: {
    log(mess, data) {
      console.log(mess, data)
    },
    changeSelectedTextSets(gr) {
      const selectedTextSets = _.uniqBy(this.selectedTextSets.concat(gr), 'textSetId')
      this.$emit('change-selected-text-sets', selectedTextSets)
    },
    toggleCollapse(name) {
      setTimeout(() => {
        this.activeNames = _.chain(this.activeNames).push(name).uniq().value()
      }, 500)
    },
    changeAllCollapse() {
      if (this.activeNames.length !== 0) this.activeNames = []
      else this.activeNames = _.map(this.selectedTextSets, (r, i) => `${r.textSetId}_${i}`)
    },
    removeSelected({ textSetId }) {
      const selectedTextSets = _.filter(this.selectedTextSets, r => r.textSetId !== textSetId)
      this.$emit('change-selected-text-sets', selectedTextSets)
    },
    editText(textSet) {
      const selectedTextSets = _.map(this.selectedTextSets, text => {
        if (text.textSetId === textSet.textSetId) {
          return textSet
        } else {
          return text
        }
      })
      this.$emit('change-selected-text-sets', selectedTextSets)
    },
    alertErrors(textSetIndex) {
      let result = this.textErrors.filter(r => r.textSetIndex === textSetIndex)[0]
      let adformatErrors = result.errors.map(adformatError => {
        const labelNames = adformatError.labelNames.join(', ')
        const errorMess = adformatError.error
        return labelNames.concat(': ', errorMess)
      })

      let messages = _.map(result.labels, r => {
        const errLabel = '<div><ul>' + r.errors.map(r => `<li>${r}</li>`).join('') + '</ul></div>'
        let textData = {}
        const errorResponse = _.find(
          this.selectedTextSets[textSetIndex - 1].labeledTexts,
          r2 => r.labelId === r2.labelId
        )
        if (!_.isUndefined(errorResponse)) {
          textData = errorResponse
        } else {
          const unselectedLabel = _.chain(this.adFormat.textLabels)
            .find(r2 => r.labelId === r2.id)
            .value()
          textData = !_.isEmpty(unselectedLabel)
            ? { labelName: unselectedLabel.name, textValues: [] }
            : { labelName: '', textValues: [] }
        }
        const { labelName, textValues } = textData
        const detail =
          '<ul>' +
          r.targets
            .map(target => {
              const errorTextValue = target.key.substring(0, target.key.lastIndexOf('_'))
              const index = textValues.indexOf(errorTextValue)
              const key = `${index + 1}番目`
              return target.errors
                .map(v => {
                  if (v.includes('商材NGワードとして設定されています。')) {
                    const error = v.substring(0, v.indexOf('\\n'))
                    return `<li>${error.replace(labelName, key)}</li>`
                  } else {
                    return `<li>${v.replace(labelName, key)}</li>`
                  }
                })
                .join('')
            })
            .sort()
            .join('') +
          '</ul>'
        return `${labelName}${r.errors.length > 0 ? errLabel : ''}${detail}`
      })
      messages = adformatErrors.concat(messages)
      if (!messages.every(x => !x.includes('商材NGワードとして設定されています。'))) {
        const link = `${this.$api.getRegulationDomain()}/advertisers`
        const NGalert =
          '<div>NGワードに変更がある場合は<a href=' +
          link +
          ' target="_blank">こちら</a>からご確認お願いいたします。</div>'
        messages.unshift(NGalert)
      }
      this.$alert(messages.join('<br>'), 'エラー', {
        dangerouslyUseHTMLString: true,
        customClass: 'scrollable',
      })
      return
    },
  },
  computed: {
    height() {
      return this.innerHeight - 55 - 50
    },
    textHeaders() {
      return _.map(this.selectedTextSets, r => {
        const textSets = _.orderBy(r.labeledTexts, ['labelIndex'], ['asc'])
        const first = {
          labelName: _.result(textSets, '[0].labelName'),
          value: _.result(textSets, '[0].textValues[0]'),
        }
        const second = _.result(textSets, '[1]')
          ? {
              labelName: _.result(textSets, '[1].labelName'),
              value: _.result(textSets, '[1].textValues[0]'),
            }
          : null
        return _.assign({ first }, { second } || {})
      })
    },
  },
}
</script>
