<template>
  <div id="drawer" class="ml-2 mr-2">
    <el-row type="flex" class="ps-1">
      <el-col :span="4">
        <el-row>
          <div class="title pdt-17 font-14">監修ステータス</div>
          <el-checkbox-group v-model="checkedApprovalStatus">
            <el-checkbox
              v-for="(status, i) in approvalStatusCreativeOptions"
              :class="{ 'w-60': i % 2 == 0 } || { 'w-40': i % 2 == 1 }"
              :label="status"
              :key="status"
            >
            </el-checkbox>
          </el-checkbox-group>
        </el-row>
      </el-col>
      <el-col :span="5">
        <el-row>
          <div class="title font-14 d-flex justify-content-between">
            ファイル名
            <el-checkbox
              v-model="isExactFilename"
              label="完全一致で検索する"
              class="ml-35"
              style="margin-right: 30px"
            />
          </div>
          <el-col>
            <el-row type="flex">
              <el-input
                type="textarea"
                :rows="1"
                v-model="inputFileName"
                class="font-med h-28 r-border no-resize"
                resize="none"
                @keyup.native="handleDownSpace($event)"
                @blur="handleFileNameConfirm"
                size="mini"
              ></el-input>
              <el-upload
                class="w-10"
                action="#"
                multiple
                :show-file-list="false"
                :auto-upload="false"
                :on-change="onNewFile"
              >
                <el-button
                  type="primary"
                  class="l-border"
                  icon="el-icon-upload"
                  size="mini"
                  style="max-width: 44px"
                ></el-button>
              </el-upload>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-tag
            v-for="(tag, i) in fileName"
            :key="tag"
            size="mini"
            class="font-med tooltip"
            @close="removeFileNameTag(i)"
            closable
          >
            {{ tag.length > 20 ? tag.substring(0, 20) + '...' : tag }}
            <span v-if="tag.length > 20" class="tooltiptext"> {{ tag }} </span>
          </el-tag>
        </el-row>
        <el-row>
          <div class="title font-14">テキスト本文検索</div>
          <el-input
            v-model="q"
            type="textarea"
            class="font-med no-resize"
            resize="none"
            rows="3"
            size="mini"
          ></el-input>
        </el-row>
      </el-col>
      <el-col :span="9">
        <el-row>
          <div class="title font-14">タグ</div>
        </el-row>
        <el-row v-for="(r, i) in tagsTemp" :key="`tags_${i}`" type="flex" align="middle">
          <tag-selection
            :tags="tags"
            :tag-type-id="r.tagTypeId"
            :tag-value="r.tagValue"
            :target-type="r.targetType"
            @remove-tag="removeTag(i)"
            @change-tag-type="id => changeTagInfo(i, id, 'tagTypeId')"
            @change-tag-value="id => changeTagInfo(i, id, 'tagValue')"
            @change-target-type="target => changeTagInfo(i, target, 'targetType')"
          />
        </el-row>

        <el-row type="flex" justify="center" v-if="tagsTemp.length < 5">
          <el-button class="ml-15" type="plain" size="mini" @click="addTag">OR</el-button>
        </el-row>
      </el-col>
      <el-col :span="4">
        <el-row>
          <div class="title font-14">監修依頼</div>
          <el-select
            v-model="selectedApprovalRequests"
            class="w-100 font-med font-13"
            multiple
            filterable
            size="mini"
            placeholder="検索"
            @visible-change="getApproval"
            :no-data-text="loadApprovalRequests ? '読み込み中...' : 'データなし'"
          >
            <el-option
              class="font-13-option"
              v-for="(r, i) in approvalRequests"
              :key="i"
              :label="r.approvalRequestName"
              no-data-text="ggggg"
              :value="r.approvalRequestId"
            >
            </el-option>
          </el-select>
        </el-row>
        <el-row>
          <div class="title font-14">入稿依頼</div>
          <el-select
            v-model="selectedSubmissionRequests"
            class="w-100 font-med font-13"
            multiple
            filterable
            size="mini"
            placeholder="検索"
            @visible-change="getSubmission"
            :no-data-text="loadSubmissionRequests ? '読み込み中...' : 'データなし'"
          >
            <el-option
              class="font-13-option"
              v-for="(r, i) in submissionRequests"
              :key="i"
              :label="r.submissionRequestName"
              :value="r.submissionRequestId"
            >
            </el-option>
          </el-select>
        </el-row>
        <el-row>
          <div class="title font-14">作成日</div>
          <el-row id="date-picker">
            <el-col :span="9">
              <el-date-picker
                v-model="startDate"
                class="w-155 font-13"
                type="date"
                placeholder="開始日"
                size="mini"
                format="yyyyMMdd"
                value-format="yyyyMMdd"
              />
            </el-col>
            <el-col :span="6" class="ml-0" justify="center" align="middle">
              <i class="el-icon-minus b-0" />
            </el-col>
            <el-col :span="9" class="ml-0">
              <el-date-picker
                class="w-155 font-13"
                v-model="endDate"
                type="date"
                placeholder="終了日"
                format="yyyyMMdd"
                value-format="yyyyMMdd"
                size="mini"
              />
            </el-col>
          </el-row>
        </el-row>
      </el-col>
    </el-row>

    <el-row type="flex" justify="end" class="footer">
      <el-col :span="18"></el-col>
      <el-col :span="7">
        <el-row type="flex" justify="end" class="mr-1">
          <el-button size="medium" type="warning" @click="setLastForm">前回の検索条件を再利用</el-button>
          <el-button size="medium" @click="reset">すべてクリア</el-button>
          <el-button size="medium" type="primary" @click="search">適用</el-button>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style>
#select-bar .el-divider--horizontal {
  margin: 0;
  display: flex;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 10%;
  border-width: 5px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: gray;
  color: #fff;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.font-14,
.font-14-option {
  font-size: 14px;
}

.el-radio__label,
.el-tag,
.el-checkbox__label,
.el-textarea__inner,
.el-input__inner,
.el-select-dropdown__empty {
  font-size: 13px !important;
}

.font-13,
.font-13-option {
  font-size: 13px;
}

.title .el-checkbox .el-checkbox__label {
  padding-left: 4px;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}
</style>

<style scoped>
.ml-0 {
  margin-left: 0px !important;
}

.w-90 {
  width: 89% !important;
}

.w-10 {
  width: 10%;
}

.r-border {
  border-radius: 4px 0px 0px 4px !important;
}

.l-border {
  border-radius: 0px 4px 4px 0px !important;
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  position: absolute;
  left: 5px;
  font-family: element-icons;
  content: '\e6da';
  font-size: 12px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-col + .el-col {
  margin-left: 20px;
}

.text-black {
  color: #000000;
}

#drawer {
  background-color: #e0e0e0;
  /* padding: 0px 5px 20px; */
  padding-bottom: 10px;
}

.mr-1 {
  margin-right: 10px;
}

.b-0 {
  bottom: 0px;
  padding-top: 20%;
}

.ml-1 {
  margin-left: 10px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 20px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-35 {
  margin-left: 35px;
}

.ps-1 {
  padding: 0px 20px;
  flex-wrap: wrap;
  background-color: #e0e0e0;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 140px;
}

.checkbox {
  width: 50%;
  margin-right: 0px;
}

.el-checkbox {
  margin-right: 0px;
}

.el-radio {
  margin-right: 0px;
  font-size: 13px;
}

.title {
  font-weight: bold;
  padding: 10px 10px 5px 0px;
  width: 100%;
}

.title {
  font-weight: bold;
  padding: 10px 10px 5px 0px;
  width: 100%;
}

.pdt-17 {
  padding-top: 17px;
}

.w-100 {
  width: 121%;
}

.w-24 {
  width: 33.33%;
  margin-right: 0px;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.w-32 {
  width: 32%;
}

.w-155 {
  width: 155% !important;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.footer {
  margin-top: 10px;
}

.bg-blue {
  background: #409eff;
  color: #ffffff;
}

.font-big {
  font-size: 20px;
}

.text-right {
  text-align: right;
  float: top;
}

.display-flex {
  height: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 83.67%;
  height: auto;
}

/* !!!IMPORTANT Xoá 2 css này trước khi lên official */

.el-divider {
  background-color: #000000;
  position: relative;
}

.el-divider--horizontal {
  display: block;
  height: 1px;
  width: 100%;
  margin: 7px 0;
}

.el-icon-minus::before {
  display: none;
}

.ml-15 {
  margin-left: -15px;
}
</style>

<script>
import _ from 'lodash'
import TagSelection from '@/components/tag-selection.vue'
import store from 'store'
import util from '@/mixins/util'

export default {
  name: 'drawer',
  components: { TagSelection },
  mixins: [util],
  props: {
    tags: { type: Array, default: () => [] },
    selectedPromotionIds: { type: Array, default: () => [] },
  },
  async created() {
    const promotionId = []
    promotionId.push(this.$route.query.promotionIds)
  },
  data: () => ({
    displayTypes: [
      { label: 'デフォルト', value: 'default' },
      {
        label: '顧客承認',
        value: 'approvalRequest',
      },
      { label: '制作用ファイル', value: 'psdURL' },
    ],
    submissionStatus: ['未入稿', '入稿依頼済', '入稿済'],
    checkedSubmissionStatus: null,
    checkedApprovalStatus: [],
    tagsTemp: [{ tagTypeId: '', tagValue: '', targetType: '' }],
    loadApprovalRequests: false,
    loadSubmissionRequests: false,
    inputFileName: '',
    fileName: [],
    isExactFilename: true,
    submissionRequests: [],
    selectedSubmissionRequests: [],
    approvalRequests: [],
    selectedApprovalRequests: [],
    q: '',
    startDate: null,
    endDate: null,
    lastTimeForm: store.get('creativeListForm'),
  }),

  methods: {
    async getApproval(isOpen) {
      if (isOpen && this.approvalRequests.length === 0) {
        this.loadApprovalRequests = true
        const promotionId = []
        promotionId.push(this.$route.query.promotionIds)
        const option = {
          method: 'POST',
          body: { promotionIds: promotionId.flat(), targetType: 'creative' },
        }

        const { approvalRequests } = await this.$api.authFetch('/approval_request/list', option)

        this.approvalRequests = approvalRequests
        this.loadApprovalRequests = false
      }
    },
    async getSubmission(isOpen) {
      if (isOpen && this.submissionRequests.length === 0) {
        this.loadSubmissionRequests = true
        const promotionId = []
        promotionId.push(this.$route.query.promotionIds)
        const option = {
          method: 'POST',
          body: { promotionIds: promotionId.flat(), targetType: 'creative' },
        }

        const { submissionRequests } = await this.$api.authFetch('/submission_request/list', option)

        this.submissionRequests = submissionRequests
        this.loadSubmissionRequests = false
      }
    },
    addTag() {
      this.tagsTemp.push({ tagTypeId: '', tagValue: '', targetType: null })
    },
    removeTag(index) {
      this.tagsTemp = _.chain(this.tagsTemp)
        .cloneDeep()
        .filter((r, i) => i !== index)
        .map(r => _.assign({}, r))
        .value()

      if (this.tagsTemp.length === 0) this.addTag()
    },
    handleFileNameConfirm() {
      if (this.inputFileName !== '') {
        const filtered = _.chain(this.fileName)
          .concat(util.methods.handleTextAreaInput(this.inputFileName))
          .uniq()
          .value()
        this.fileName = filtered
        this.inputFileName = ''
      }
    },
    removeFileNameTag(index) {
      this.fileName.splice(index, 1)
    },
    changeTagInfo(index, value, key) {
      this.tagsTemp = _.map(this.tagsTemp, (r, i) => {
        if (i !== index) return _.assign({}, r)
        else {
          return _.assign({}, r, { [key]: value })
        }
      })
    },
    search() {
      const form = this.getFormParams()

      store.set('creativeListForm', form)
      this.lastTimeForm = _.cloneDeep(form)

      if (this.$route.path === '/creative-list') delete window.CreativeList.query.creativeIds
      const query = _.assign(
        {},
        _.omit(this.$route.query, ['originIds', 'textSetIds', 'creativeIds', 'creativeDraftId']),
        { creativeListForm: JSON.stringify(form) }
      )
      this.$router.push({ query })

      this.$emit('get-creatives', form)
    },
    reset() {
      this.q = ''
      this.selectedApprovalRequests = []
      this.fileName = []
      this.inputFileName = ''
      this.isExactFilename = false
      this.selectedSubmissionRequests = []
      this.tagsTemp = [{ tagTypeId: '', tagValue: '', targetType: null }]
      this.approvalRequests = []
      this.submissionRequests = []
      this.checkedApprovalStatus = []
      this.checkedSubmissionStatus = null
      this.startDate = null
      this.endDate = null
    },
    setLastForm() {
      if (this.lastTimeForm !== null) {
        this.q = this.lastTimeForm.q
        this.selectedApprovalRequests = this.lastTimeForm.approvalRequestIds
        this.fileName = this.lastTimeForm.fileNames
        this.isExactFilename = this.lastTimeForm.isExactFilename
        this.selectedSubmissionRequests = this.lastTimeForm.submissionRequestIds
        this.tagsTemp =
          this.lastTimeForm.tags.length === 0
            ? [
                {
                  tagTypeId: '',
                  tagValue: '',
                  targetType: null,
                },
              ]
            : this.lastTimeForm.tags
        this.checkedApprovalStatus = this.lastTimeForm.approvalStatus
        this.checkedSubmissionStatus = this.lastTimeForm.submissionStatus
        this.startDate = this.lastTimeForm.createdAt.from
        this.endDate = this.lastTimeForm.createdAt.to
      }
    },
    getFormParams() {
      const form = {
        q: this.q,
        submissionRequestIds: this.selectedSubmissionRequests,
        approvalRequestIds: this.selectedApprovalRequests,
        fileNames: this.fileName,
        isExactFilename: this.isExactFilename,
        tags: this.officialTag,
        approvalStatus: this.checkedApprovalStatus,
        submissionStatus: this.checkedSubmissionStatus,
        createdAt: { from: this.startDate, to: this.endDate },
      }
      return form
    },

    divisibleByThree(num) {
      return num % 3 === 0
    },
    onNewFile({ name }) {
      const filtered = _.chain(this.fileName).push(name).uniq().value()
      this.fileName = filtered
    },
    handleDownSpace(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault()
        this.handleFileNameConfirm()
      }
    },
  },
  watch: {
    selectedPromotionIds() {
      this.approvalRequests = []
      this.submissionRequests = []
    },
  },
  computed: {
    officialTag() {
      return this.tagsTemp.filter(o => o.tagTypeId != '' && o.tagValue != '')
    },
    disableButtonSearch() {
      const form = {
        q: this.q,
        submissionRequestIds: this.selectedSubmissionRequests,
        approvalRequestIds: this.selectedApprovalRequests,
        fileNames: this.fileName,
        isExactFilename: this.isExactFilename,
        tags: this.tagsTemp.filter(o => o.tagTypeId != '' && o.tagValue != ''),
        approvalStatus: this.checkedApprovalStatus,
        submissionStatus: this.checkedSubmissionStatus,
        from: this.startDate,
        to: this.endDate,
      }
      const isEmpty = Object.values(form).findIndex(formData => formData && !_.isEmpty(formData)) === -1
      return isEmpty
    },
  },
}
</script>
