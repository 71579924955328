<template>
  <el-dialog
    id="complete-dialog"
    :title="`${type}作成`"
    custom-class="small-dialog"
    width="30%"
    lock-scroll
    append-to-body
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-row type="flex" justify="center" class="p-1"> {{ type }}を作成しました </el-row>

    <el-row class="p-1">
      <el-input :value="url">
        <el-button slot="append" icon="fas fa-link" @click="copy" />
      </el-input>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button @click="isShow = false" type="primary"> 閉じる </el-button>
    </span>
  </el-dialog>
</template>

<style scoped>
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
.p-1 {
  padding: 1rem;
}
.ps-1 {
  padding: 0 1rem;
}
.m-0 {
  margin: 0 !important;
}
</style>

<script>
export default {
  name: 'complete-dialog',
  data: () => ({ isShow: false, url: '', type: '' }),
  created() {
    window.CompleteDialog = this
  },
  methods: {
    show(url, type) {
      this.url = url
      this.type = type
      this.isShow = true
    },
    copy() {
      this.$copyText(this.url).then(
        () => this.$message.success('コピーしました'),
        () => this.$message.error('コピーに失敗しました')
      )
    },
  },
}
</script>
