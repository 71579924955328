<template>
  <el-dialog
    id="text-set-dialog"
    title="テキストセット選択"
    lock-scroll
    fullscreen
    append-to-body
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane label="テキストを選択" name="select">
        <text-master
          ref="textMaster"
          :selected-promotion-ids="selectedPromotionIds"
          :tags="tags"
          :ad-format-id="adFormatId"
          @change-selected-text-sets="
            gr => {
              selected = gr
            }
          "
        />
      </el-tab-pane>

      <el-tab-pane label="選択したテキスト" name="assign">
        <el-row v-if="selected.length === 0" class="p-1" type="flex" align="middle" :style="{ height: height + 'px' }">
          <el-col class="text-center font-big"> テキストを選択してください </el-col>
        </el-row>

        <ad-format-tab
          v-else
          :selected="selected"
          :selected-promotion-ids="selectedPromotionIds"
          :ad-format-id="adFormatId"
          :text-sets="selected"
          :height="height - 30"
          @change-selected-at-tab="changeSelected"
        />
      </el-tab-pane>
    </el-tabs>

    <span v-if="selected.length > 0" slot="footer" class="dialog-footer">
      <el-button type="primary" v-if="activeTab === 'assign'" @click="close"> 保存 </el-button>
      <el-button type="primary" v-else @click="activeTab = 'assign'"> 次へ </el-button>
    </span>
  </el-dialog>
</template>

<style>
#text-set-dialog .el-tabs__content {
  padding: 0;
}
</style>

<style scoped>
.p-1 {
  padding: 1rem;
}
.pl-1 {
  padding-left: 10px;
}
.pr-1 {
  padding-right: 10px;
}
.border-r-1 {
  border-right: 1px solid #d6d6d6;
}
.w-100 {
  width: 100%;
}
.text-center {
  text-align: center;
}
.font-big {
  font-size: 20px;
}
</style>

<script>
import _ from 'lodash'
import TextMaster from '@/views/text-master'
import AdFormatTab from '@/views/text-master/ad-format-tab'

export default {
  name: 'text-set-dialog',
  components: { TextMaster, AdFormatTab },
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    adFormatId: { type: String, default: () => '' },
    height: { type: Number, default: () => null },
  },
  data: () => ({ isShow: false, activeTab: 'select', selected: [] }),
  created() {
    window.TextSetDialog = this
  },
  methods: {
    async show() {
      this.selected = []
      this.activeTab = 'select'
      this.isShow = true

      await this.$nextTick()
      this.$refs.textMaster.reset()
    },
    changeSelected({ selected }) {
      this.selected = _.filter(this.selected, r => _.includes(selected, r.textSetId))
      this.$refs.textMaster.changeSelectedForCreativeRegist(_.map(this.selected, 'textSetId'))
    },
    close() {
      this.$emit('change-selected-text-sets', this.selected)
      this.isShow = false
    },
  },
}
</script>
