<template>
  <div id="ad-format-tab" v-loading="loading">
    <el-checkbox-group :value="selectedChild" @input="changeSelected">
      <div id="scroll-area" class="scroll" :style="{ minHeight: height - 20 + 'px' }">
        <el-row class="pt-1 pl-1 pr-1 pb-1" v-for="(textSet, i) in orderByTextSets" :key="`textSet_${i}`">
          <el-col>
            <el-card shadow="never" class="w-100 font-mini">
              <div v-if="!isDisplay" class="w-3 float-left text-center font-initial">
                <div class="p-1">
                  <div class="mb-1">
                    <el-checkbox :label="textSet.textSetId" />
                  </div>

                  <template v-if="displayType === 'default'">
                    <div v-if="isValidRoute" class="mb-1" @click="$refs.copyTextSetDialog.show(orderByTextSets[i])">
                      <i class="fas fa-copy" />
                    </div>

                    <div v-if="isValidRoute" class="mb-1" @click="$refs.editTextSetDialog.show(orderByTextSets[i])">
                      <i class="fas fa-edit" />
                    </div>

                    <div v-if="isValidRoute" @click="removeTextSet(textSet.textSetId)">
                      <i class="fas fa-trash-alt" />
                    </div>
                  </template>
                </div>
              </div>

              <div class="float-left" :class="{ 'w-97': !isDisplay, 'w-100': isDisplay }">
                <el-col :span="displayType === 'default' ? (isDisplay ? 24 : 20) : 20" class="p-1">
                  <el-row
                    type="flex"
                    v-for="(chunkedLabeledText, j) in require('lodash').chunk(textSet.labeledTexts, 3)"
                    :key="`chunkedLabeledText_${i}_${j}`"
                    class="mb-2 w-100"
                  >
                    <el-col
                      v-for="(labeledText, k) in chunkedLabeledText"
                      :key="`labeledText_${i}_${j}_${k}`"
                      :span="8"
                      class="pr-1"
                    >
                      <el-row class="p-2 bg-gray">
                        {{ labeledText.labelName }}
                      </el-row>

                      <el-row
                        class="flex mt-1 mb-1 new-line"
                        v-for="(v, l) in labeledText.textValues"
                        :key="`labeledText_${i}_${j}_${k}_${l}`"
                      >
                        <el-tag class="float-left" type="info" effect="dark" size="mini">
                          {{ l + 1 }}
                        </el-tag>

                        <div class="flex ml-1">{{ v }}</div>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-col>

                <el-col v-if="!isDisplay" :span="4" class="p-1">
                  <comment-area-text
                    v-if="displayType === 'approvalRequest'"
                    :text-set="textSet"
                    :is-in-dialog="isInDialog"
                    :display-type="displayType"
                    :ad-format-id="adFormatId"
                    :is-text-master="textMaster"
                    @change-creator-comment="
                      v =>
                        $emit(
                          'change-creator-comment',
                          v,
                          textSet.textSetId,
                          platformId || textSet.platformId || textSet.adFormat.platformId
                        )
                    "
                    @get-text-sets="$emit('get-text-sets')"
                    @open-proposed-dialog="$refs.proposedTextDialog.show(textSet.textSetId, adFormatId)"
                  />
                  <template v-if="displayType !== 'approvalRequest' || isShowVersionAndStatus">
                    <version-status-item
                      v-if="
                        (![appRoute.CREATIVE_REGIST].includes($route.path) && !isInCreateCreativeDialog) ||
                        isShowVersionAndStatus
                      "
                      class="mb-1"
                      target-type="textSet"
                      :version="textSet.version"
                      :approval-status="textSet.approvalStatus"
                      :target-id="textSet.textSetId"
                      @reload="$emit('get-text-sets')"
                      @open-version-dialog="$refs.versionDialog.show(textSet.textSetId)"
                      @change-approval-status="
                        v =>
                          $emit(
                            'change-approval-status',
                            v,
                            textSet.textSetId,
                            platformId || textSet.platformId || textSet.adFormat.platformId
                          )
                      "
                    />

                    <el-row v-if="textSet.score" class="mb-1">
                      <el-tag type="danger" class="font-bold w-100 vertical-center">
                        <img class="pr-2" style="height: 10px" :src="require('@/assets/odd-ai_logo.png')" />
                        CTR {{ formatScore(textSet.score) }}
                      </el-tag>
                    </el-row>

                    <el-row v-if="textSet.tags.length !== 0">
                      <span v-for="(tag, j) in textSet.tags" :key="`tag_${i}_${j}`">
                        <el-popover v-if="tag.tagTypeName || tag.description" trigger="hover">
                          <div v-if="tag.tagTypeName">カテゴリ: {{ tag.tagTypeName }}</div>
                          <div v-if="tag.description">説明: {{ tag.description }}</div>
                          <div
                            slot="reference"
                            :class="`mr-1 mb-1 tag  ${isSmallTag ? 'tag-small' : 'font-bold '}`"
                            :style="{ background: tag.color || '#909399', color: '#FFFFFF' }"
                          >
                            {{ tag.tagValue }}
                          </div>
                        </el-popover>

                        <el-tag v-else type="info" class="font-bold mr-1 mb-1">
                          {{ tag.tagValue }}
                        </el-tag>
                      </span>
                    </el-row>
                  </template>
                </el-col>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-checkbox-group>

    <div v-if="!isDisplay" class="text-center">
      <el-pagination
        class="mt-1 mb-1"
        background
        :current-page.sync="currentPage"
        :page-count="pageCount"
        @current-change="changeCurrentPage"
      />
    </div>

    <copy-text-set-dialog
      v-if="isValidRoute"
      ref="copyTextSetDialog"
      :default-promotion-id="selectedPromotionIds[0]"
      :platform-id="platformId"
      :ad-format-id="adFormatId"
      :ad-format-name="adFormatName"
      :ad-formats="adFormats"
      :ad-format="adFormat"
      @get-text-sets="$emit('get-text-sets')"
    />

    <edit-text-set-dialog
      v-if="isValidRoute"
      ref="editTextSetDialog"
      :platform-id="platformId"
      :ad-format-id="adFormatId"
      :ad-format-name="adFormatName"
      :ad-formats="adFormats"
      :ad-format="adFormat"
      @get-text-sets="$emit('get-text-sets')"
    />

    <version-dialog ref="versionDialog" />
    <proposed-text-dialog @get-text-sets="$emit('get-text-sets')" :ad-format="adFormat" ref="proposedTextDialog" />
  </div>
</template>

<style>
#ad-format-tab .el-card__body {
  padding: 0px;
}
#ad-format-tab .el-checkbox__label {
  display: none;
}
</style>

<style scoped>
.tag {
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}

.tag-small {
  padding: 5px;
  height: auto;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
}
.new-line {
  white-space: pre-wrap !important;
}
.flex {
  display: flex;
}
.badge {
  position: relative;
  bottom: 9px;
  right: 10px;
}
.icon-red {
  color: #f56c6c;
}
.font-middle {
  font-size: 18px;
}
.p-1 {
  padding: 10px;
}
.p-2 {
  padding: 0.5rem;
}
.pt-1:first-child {
  padding-top: 1rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 5px;
}
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 10px;
}
.pb-1 {
  padding-bottom: 1rem;
}
.bg-dark {
  background: #004b6a;
}
.scroll {
  overflow-y: scroll;
}
ol {
  padding-inline-start: 1.25rem;
  margin-block-start: 0.5rem;
  margin-block-end: 0;
}
.font-mini {
  font-size: 12px;
}
.font-initial {
  font-size: initial;
}
.w-3 {
  width: 3%;
}
.w-97 {
  width: 97%;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.float-left {
  float: left;
}
.m-1 {
  margin: 1rem;
}
.mt-1 {
  margin-top: 0.5rem;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
.ml-1 {
  margin-left: 0.5rem;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.mr-2 {
  margin-right: 1rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.text-center {
  text-align: center;
}
.font-bold {
  font-weight: bold;
}
.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg-gray {
  background-color: #ebeef5;
}
</style>

<script>
import _ from 'lodash'
import VersionStatusItem from '@/components/version-status-item'
import EditTextSetDialog from '@/views/text-master/ad-format-tab/components/edit-text-set-dialog'
import CopyTextSetDialog from '@/views/text-master/ad-format-tab/components/copy-text-set-dialog'
import VersionDialog from '@/views/text-master/ad-format-tab/version-dialog'
import CommentAreaText from '@/views/text-master/ad-format-tab/components/comment-area-text'
import ProposedTextDialog from '@/views/text-master/ad-format-tab/proposed-text-dialog'
import { AppRoute } from '@/mixins/appRoute'
import { MessageBox } from 'element-ui'

export default {
  name: 'ad-format-tab',
  components: {
    VersionStatusItem,
    EditTextSetDialog,
    CopyTextSetDialog,
    VersionDialog,
    CommentAreaText,
    ProposedTextDialog,
  },
  props: {
    selected: { type: Array, default: () => [] },
    selectedPromotionIds: { type: Array, default: () => [] },
    platformId: { type: String, default: () => '' },
    adFormatId: { type: String, default: () => '' },
    adFormatName: { type: String, default: () => '' },
    textSets: { type: Array, default: () => [] },
    height: { type: Number, default: () => null },
    isDisplay: { type: Boolean, default: () => false },
    isInDialog: { type: Boolean, default: () => false },
    displayType: { type: String, default: () => '' },
    adFormats: { type: Array, default: () => [] },
    textMaster: { type: Boolean, default: () => true },
    isSmallTag: { type: Boolean, default: () => false },
    isShowVersionAndStatus: { type: Boolean, default: () => false },
  },
  inject: {
    isInCreateCreativeDialog: {
      from: 'isInCreateCreativeDialog',
      default: false,
    },
  },
  data: () => ({
    loading: false,
    currentPage: 1,
    pageSize: 50,
    notApprovalReasonIndex: null,
    adFormat: {},
    appRoute: AppRoute,
  }),
  created() {
    window.AdFormatTab = this
    this.adFormat = _.find(this.adFormats, { id: this.adFormatId })
  },
  methods: {
    async removeTextSet(textSetId) {
      const ret = await this.$confirm('テキストを削除しますか?', '確認', {
        customClass: 'delete-confirmation',
      }).catch(e => e)
      if (ret === 'cancel') return

      this.loading = true
      const res = await this.$api
        .authFetch('/text_set', { method: 'DELETE', query: { textSetId: [textSetId] } })
        .finally(x => {
          this.loading = false
          return x
        })
      if (typeof res !== 'undefined') {
        if (res === 'クリエイティブに使用されているので削除できません。') {
          MessageBox.alert(res, 'エラー', {
            showCancelButton: true,
            distinguishCancelAndClose: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'クリエイティブを確認する',
            customClass: 'delete-confirmation',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm' || action === 'close') {
                done()
                this.loading = false
              } else {
                const query = _.assign({}, this.$route.query, { textSetIds: [textSetId] })
                const routeData = this.$router.resolve({ name: `creative-list`, query: query })
                window.open(`${window.location.origin}${routeData.href}`, '_blank')
              }
            },
          }).catch(action => {
            if (action == 'close') {
              this.loading = false
            }
          })
        } else {
          this.loading = false
          this.$emit('get-text-sets')
        }
      } else {
        this.loading = false
        this.$emit('get-text-sets')
      }
    },
    changeSelected(gr) {
      this.$emit('change-selected-at-tab', {
        selected: _.orderBy(gr),
        adFormatId: this.adFormatId,
        platformId: this.platformId,
        adFormatName: this.adFormatName,
      })
    },
    openTextSetDialog(index, type) {
      const textSet = _.cloneDeep(this.orderByTextSets[index])
      if (type === 'copy') this.$refs.copyTextSetDialog.show(textSet)
      else this.$refs.editTextSetDialog.show(textSet)
    },
    formatScore(score) {
      const formatOption = { style: 'percent', minimumFractionDigits: 2 }
      return new Intl.NumberFormat('ja-JP', formatOption).format(score)
    },
    changeCurrentPage() {
      document.getElementById('scroll-area').scrollTo({ top: 0, behavior: 'smooth' })
    },
  },
  computed: {
    isValidRoute() {
      return (
        !this.isInCreateCreativeDialog &&
        ![this.appRoute.CREATIVE_REGIST, this.appRoute.CREATIVE_LIST].includes(this.$route.path)
      )
    },
    pageCount() {
      return _.ceil(this.textSets.length / this.pageSize)
    },
    selectedChild() {
      const textSetIds = _.map(this.textSets, 'textSetId')
      return _.chain(this.selected)
        .filter(r => _.includes(textSetIds, r.textSetId))
        .map('textSetId')
        .value()
    },
    orderByTextSets() {
      const filteredData = _.chain(this.textSets)
        .sort(function (a, b) {
          function toTime(time_str) {
            if (typeof time_str === 'undefined') {
              return 0
            }
            const year = time_str.slice(0, 4)
            const month = time_str.slice(4, 6)
            const day = time_str.slice(6, 8)
            const hour = time_str.slice(8, 10)
            const minute = time_str.slice(10, 12)
            const second = time_str.slice(12, 14)
            return new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}Z`)
          }

          const time_a = toTime(a.updatedAt)
          const time_b = toTime(b.updatedAt)

          if (time_a > time_b) return -1
          if (time_a < time_b) return 1

          return 0
        })
        .filter((r, i) => (this.currentPage - 1) * this.pageSize <= i + 1 && i + 1 <= this.currentPage * this.pageSize)
        .value()

      return filteredData
    },
  },
  watch: {
    //update adFormat when adFormatId change
    adFormatId() {
      this.adFormat = _.find(this.adFormats, { id: this.adFormatId })
    },
  },
}
</script>
