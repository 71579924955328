<template>
  <el-dialog
    id="copy-error-dialog"
    title="クリエイティブ情報"
    width="80%"
    lock-scroll
    append-to-body
    :visible.sync="isShow"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div>
      <div class="mb-1">
        コピー失敗。下記から手動でコピーしてください。
        <span class="font-red">※下記テキストエリアをクリックしてから、Ctrl A + Ctrl Cを推奨</span>
      </div>

      <el-input type="textarea" :rows="20" :value="data" />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="isShow = false" type="primary"> 閉じる </el-button>
    </span>
  </el-dialog>
</template>

<style>
#copy-error-dialog .el-dialog__body {
  padding: 20px !important;
}
</style>

<style scoped>
.mb-1 {
  margin-bottom: 1rem;
}
.font-red {
  color: #f56c6c;
}
</style>

<script>
export default {
  name: 'copy-error-dialog',
  data: () => ({ isShow: false, data: null }),
  created() {
    window.CopyErrorDialog = this
  },
  methods: {
    show(data) {
      this.data = data
      this.isShow = true
    },
  },
}
</script>
