<template>
  <div id="asset-area" ref="assetArea" :style="{ borderColor: getBorderColor(isAssetCanEdit) }">
    <el-row
      class="d-flex"
      v-if="creativeData.assets && creativeData.assets.length !== 0"
      style="margin-right: 5px; flex-direction: column"
    >
      <div class="float-left w-100 word-break" v-if="creativeData.assets.length === 1">
        <status-area
          :status-selected="creativeData.first.approvalStatus"
          :disabled="!isAssetCanEdit"
          @change-status="status => $emit('change-status-asset', creativeData.first.assetId, status)"
        />
        <div class="my-1" :style="{ color: '#000' }">{{ creativeData.first.labelName }}</div>
        <el-tooltip
          class="item"
          effect="light"
          :content="`${creativeData.first.metadata.fileName}\n${creativeData.first.metadata.rawFileName}`"
          placement="right"
          :visible-arrow="false"
        >
          <div>
            <div class="thumb-name d-flex">
              <span class="thumb-ver" v-text="'v' + creativeData.first.version"></span>
              <p class="name" v-text="creativeData.first.metadata.fileName" />
            </div>
            <div class="thumb-name d-flex">
              <p class="name last" v-text="creativeData.first.metadata.rawFileName" />
            </div>
          </div>
        </el-tooltip>
        <div class="image-container d-inline-block">
          <image-movie-item
            class="float-left w-100 h-100 image"
            parentComponent="creative"
            :url="creativeData.first.url"
            :thumbnail-url="creativeData.first.url"
            :type="creativeData.first.assetType"
            :isMask="true"
          />
          <div class="after" v-if="canUpVerAsset(creativeData.first.approvalStatus, creativeData.first.assetType)">
            <el-upload
              ref="attachSingleAsset"
              class="drag-upload"
              drag
              action="#"
              :show-file-list="false"
              :auto-upload="false"
              :on-change="e => changeSingleAsset(e, creativeData.assets[0].labelId)"
            >
            </el-upload>
            <div class="upload-asset">
              <i class="el-icon-upload" style="font-size: 60px" @click="attachSingleAsset" />
            </div>
          </div>
        </div>
      </div>

      <div class="float-left w-100 word-break" v-if="creativeData.assets.length - 1 !== 0">
        <el-carousel
          indicator-position="none"
          :autoplay="false"
          :loop="false"
          height="400px"
          @change="
            index => {
              $emit('change-active-index', index)
              closeStatusArea()
            }
          "
        >
          <el-carousel-item
            v-for="(
              {
                assetId,
                labelId,
                labelName,
                url,
                thumbnailUrl,
                assetType,
                approvalStatus,
                version,
                metadata: { fileName, rawFileName },
                isLatest,
              },
              k
            ) in creativeData.assets"
            :key="k"
          >
            <status-area
              ref="asset-carousel"
              :status-selected="approvalStatus"
              :disabled="!isAssetCanEdit"
              @change-status="status => $emit('change-status-asset', assetId, status)"
            />
            <div class="my-1" :style="{ color: '#000' }">{{ labelName }}</div>
            <el-tooltip
              class="item"
              effect="light"
              :content="`${fileName}\n${rawFileName}`"
              placement="right"
              :visible-arrow="false"
            >
              <div>
                <div class="thumb-name d-flex">
                  <span class="thumb-ver" v-text="'v' + version"></span>
                  <p class="name" v-text="fileName" />
                </div>
                <div class="thumb-name d-flex">
                  <p class="name last" v-text="rawFileName" />
                </div>
              </div>
            </el-tooltip>
            <div>
              <div>
                <div class="image-container">
                  <image-movie-item
                    class="w-100 h-100"
                    parentComponent="creative"
                    :url="url"
                    :thumbnail-url="thumbnailUrl"
                    :type="assetType"
                    :no-preview="true"
                    :isMask="true"
                  />
                  <div class="after" v-if="canUpVerAsset(approvalStatus, assetType)">
                    <el-upload
                      ref="attachSingleAsset"
                      class="drag-upload"
                      drag
                      action="#"
                      :show-file-list="false"
                      :auto-upload="false"
                      :on-change="e => changeAsset(e, k, labelId)"
                    >
                    </el-upload>
                    <div class="upload-asset">
                      <i class="el-icon-upload" style="font-size: 60px" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div v-if="regulateError.length !== 0" class="text-center d-flex" style="justify-content: space-between">
        <p style="color: #f56c6c; font-weight: bold; margin: 0; cursor: default; padding-left: 15px">
          <i class="el-icon-warning error-mark" style="font-size: 8px; padding-bottom: 4px"></i
          >規定違反<br />があります。
        </p>
        <el-button class="w-30" type="danger" size="small" style="font-weight: bold" @click="openRegulateDialog">
          詳細確認
        </el-button>
      </div>
    </el-row>
    <upload-result-preview-dialog ref="assetRegulateDialog" :adFormats="adFormats" />

    <preview-dialog ref="previewDialog" :platform-id="platformId" :ad-format-name="adFormatName" />
  </div>
</template>

<style>
#asset-area {
  padding-top: 0.5rem;
  border-right: 1px solid #ebeef5;
}

#asset-area .el-carousel__arrow {
  background-color: rgba(31, 45, 61);
  top: 65%;
}

#asset-area .drag-upload {
  position: absolute;
  z-index: 24;
  width: 100%;
  height: 100%;
}

#asset-area .image-container .video-wrap {
  height: 100%;
  display: flex;
}

#asset-area .image-container .video-wrap video {
  width: 100%;
}

#asset-area .drag-upload .el-upload {
  width: 100%;
  height: 100%;
}

#asset-area .drag-upload .el-upload .el-upload-dragger {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}

#asset-area .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
  padding: 30px 0;
}

.el-tooltip__popper.is-light {
  border: none !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
}

#asset-area .image-container {
  position: relative;
  width: 100%;
}

#asset-area .image-container .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  color: #fff;
}

#asset-area .image-container:hover .after {
  position: absolute;
  display: block;
  background: rgba(0, 0, 0, 0.4);
  border: none;
}

#asset-area .upload-asset {
  display: none;
  z-index: 2;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

#asset-area .image-container:hover .upload-asset {
  display: block;
}

.el-tooltip__popper {
  white-space: pre;
  max-height: 100px;
}
</style>

<style scoped>
.mb-2 {
  margin-bottom: 1rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.my-1 {
  margin: 0.5rem 0;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.text-center {
  text-align: center;
}

.font-bold {
  font-weight: bold;
}

.float-left {
  float: left;
}

.word-break {
  word-wrap: break-word;
}

.font-mini-2 {
  font-size: 10px;
}

.line-1 {
  line-height: 18px;
}

.sumitted {
  background-color: #5bb85d;
  color: red;
}

.thumb-name {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.thumb-name p.name {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #060630;
  font-size: 12px;
  font-weight: 400;
}

.thumb-name p.name.last {
  margin-top: 0 !important;
  margin-left: 20px;
}
</style>

<script>
import ImageMovieItem from '@/components/image-movie-item'
import PreviewDialog from '@/views/creative-list/ad-format-tab-list/components/preview-dialog'
import UploadResultPreviewDialog from '@/components/upload-assets-dialog/components/upload-result-preview-dialog'
import StatusArea from '@/views/approval-detail/components/ad-format-tab/creative-card/main-area/components/status-area'
import _ from 'lodash'
import { AssetType } from '@/mixins/assetType'
import { AssetTextStatus } from '@/mixins/assetTextStatus'
import util from '@/mixins/util'

export default {
  name: 'asset-area',
  components: {
    PreviewDialog,
    UploadResultPreviewDialog,
    ImageMovieItem,
    StatusArea,
  },
  mixins: [util],
  props: {
    platformId: {
      type: String,
      default: () => '',
    },
    adFormatName: {
      type: String,
      default: () => '',
    },
    creative: {
      type: Object,
      default: () => ({}),
    },
    isShow: {
      type: Boolean,
      default: () => false,
    },
    isAssetCanEdit: {
      type: Boolean,
      default: () => true,
    },
    assetRegulateError: {
      type: Array,
      default: () => [],
    },
    adFormats: { type: Array, default: () => [] },
  },
  data: () => ({
    assetWidth: 250,
    backgroundColor: null,
    color: null,
    isCreativeListComp: true,
    creativeData: {},
    attachAssetData: {},
  }),
  created() {
    window.AssetArea = this
    window.addEventListener('resize', () => this.changeAssetHeight())
    this.creativeData = _.cloneDeep(this.creative)
    this.color = '#ffffff'
  },
  async mounted() {
    await this.$nextTick()
    this.changeAssetHeight()
  },
  watch: {
    async isShow() {
      if (!this.isShow) return

      await this.$nextTick()
      this.changeAssetHeight()
    },
    creative() {
      this.creativeData = _.cloneDeep(this.creative)
    },
  },
  methods: {
    openRegulateDialog() {
      const test = this.regulateError.map(x => {
        return {
          asset: _.assign(x.info, { name: x.fileName }),
          groupName: '',
          isError: false,
          reason: x.type,
          regulationErrors: x.errors,
        }
      })
      const assetErrorResult = _.groupBy(test, 'groupName')
      this.$refs.assetRegulateDialog.show(assetErrorResult)
    },
    log(v) {
      console.log(v)
    },
    closeStatusArea() {
      const statusArea = this.$refs['asset-carousel']
      if (statusArea) {
        statusArea.forEach(x => x.closeStatus())
      }
    },
    openPreview(status, labeledAssets, labeledTexts) {
      if (status !== '要修正') {
        this.$refs.previewDialog.show(labeledAssets, labeledTexts)
      }
    },
    attachSingleAsset() {
      const fileInputElement = this.$refs.attachSingleAsset
      fileInputElement.click()
    },
    attachAsset(id, k) {
      const fileInputElement = this.attachAssetData[k]
      fileInputElement.click()
    },
    changeAssetHeight() {
      if (!this.$refs.assetArea) return
      this.assetWidth = this.$refs.assetArea.clientWidth
    },
    getOriginIdsFromLabel(labelAssets, labelId) {
      const labelAsset = labelAssets.find(labelAsset => labelAsset.labelId === labelId)
      return labelAsset ? labelAsset.assets.map(a => a.originId) : []
    },
    changeAsset(e, k, labelId) {
      const file = e.raw
      const oldName = this.creativeData.assets[k].metadata.rawFileName
      const newName = file.name
      const skipCheckRule = false //TODO if check rule asset
      if (oldName !== newName) {
        this.$alert(
          'アップロードしている画像動画のファイル名が選択している画像動画のファイル名と統一していません。更新できません。再度ご確認お願い致します。',
          'アラート',
          { type: 'warning' }
        )
      } else {
        this.$emit(
          'update-ver-asset',
          file,
          this.creativeData.assets[k].assetId,
          skipCheckRule,
          this.creativeData.assets[k].originId,
          this.getOriginIdsFromLabel(this.creativeData.labeledAssets, labelId),
          labelId
        )
      }
    },
    changeSingleAsset(e, labelId) {
      const file = e.raw
      const oldName = this.creativeData.first.metadata.rawFileName
      const newName = file.name
      const skipCheckRule = false //TODO if check rule asset
      if (oldName !== newName) {
        this.$alert(
          'アップロードしている画像動画のファイル名が選択している画像動画のファイル名と統一していません。更新できません。再度ご確認お願い致します。',
          'アラート',
          { type: 'warning' }
        )
      } else {
        this.$emit(
          'update-ver-asset',
          file,
          this.creativeData.first.assetId,
          skipCheckRule,
          this.creativeData.first.originId,
          this.getOriginIdsFromLabel(this.creativeData.labeledAssets, labelId),
          labelId,
          this.creativeData.creativeId
        )
      }
    },
    setColorText(status) {
      switch (status) {
        case '監修中':
          return '#fff'
        case '要修正':
          return '#fff'
        case '承認待ち':
          return '#fff'
        case '入稿依頼済':
          return '#fff'
        case '入稿済':
          return '#fff'

        case '承認':
          return '#fff'
        case '入稿可能':
          return '#fff'

        case '破棄':
          return '#c0c4cc5c'
        case '入稿不可':
          return '#c0c4cc5c'

        default:
          return 'rgb(6 6 48 / 45%)'
      }
    },

    setBackgroundColorApprovalStatus(status) {
      switch (status) {
        case '監修中':
          return '#909399'
        case '要修正':
          return '#ef342f'
        case '承認待ち':
          return '#909399'
        case '入稿依頼済':
          return '#ffa500'
        case '入稿済':
          return '#084c6c'

        case '承認':
          return 'rgb(91, 184, 93)'
        case '入稿可能':
          return 'rgb(91, 184, 93)'

        case '破棄':
          return 'rgba(245,245,245,0.48)'
        case '入稿不可':
          return 'rgba(245,245,245,0.48)'

        default:
          return 'white'
      }
    },
    canUpVerAsset(approvalStatus, assetType) {
      return approvalStatus === AssetTextStatus.FIXING && assetType !== AssetType.URL && this.isAssetCanEdit
    },
    isDisabledStatusArea(isLatest) {
      return !isLatest
    },
  },
  computed: {
    regulateError() {
      return this.assetRegulateError.filter(x =>
        _.includes(
          this.creative.assets.map(x => x.originId),
          x.info.originId
        )
      )
    },
  },
}
</script>
