<template>
  <el-row id="comment-area" v-loading="loading" :gutter="10">
    <div>
      <div>
        <div>
          <label style="font-weight: bolder"><i class="fa-solid fa-comments"></i> 制作意図</label>
          <div style="display: flex; margin-bottom: 2vh" class="creative-comment">
            <div style="width: 20%; float: left">掛け合わせ</div>
            <div style="width: 80%; float: left">
              <el-input
                type="textarea"
                style="min-width: 100%; max-width: 100%"
                :value="creative.creatorComment"
                :disabled="creative.submissionStatus === '入稿済'"
                :rows="3"
                @input="v => $emit('change-creator-comment', v)"
              >
              </el-input>
            </div>
          </div>
          <div style="display: flex; margin-bottom: 10px" class="asset-comment">
            <div style="width: 20%">画像/動画</div>
            <div style="flex-grow: 1">
              <el-input
                readonly
                disabled
                type="textarea"
                resize="none"
                :rows="3"
                style="min-width: 100%; max-width: 100%"
                :class="`no-resize ${creatorCommentAsset.replaceAll('\n', '').length ? '' : 'commentNone'}`"
                :value="
                  creatorCommentAsset.replaceAll('\n', '').length ? creatorCommentAsset : '制作意図記入(ユニーク)'
                "
              >
              </el-input>
              <div class="thumbnail-list" style="white-space: nowrap; display: flex; gap: 2px">
                <span
                  class="items"
                  v-for="(asset, i) in attachmentFileList"
                  :key="`a_${i}_${creative.creativeId}`"
                  style="
                    width: 45px;
                    aspect-ratio: 1/1;
                    position: relative;
                    align-items: center !important;
                    display: flex;
                  "
                >
                  <div
                    style="
                      width: 45px;
                      aspect-ratio: 1/1;
                      display: flex;
                      justify-content: center;
                      background-color: #060630;
                      cursor: pointer;
                    "
                    @click="
                      () => {
                        openItemDialog(asset, `attach_image_${creative.creativeId}_${i}`)
                      }
                    "
                  >
                    <img
                      :src="asset"
                      :id="`attach_image_${creative.creativeId}_${i}`"
                      style="max-height: 100%; max-width: 100%; margin: auto"
                    />
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div style="display: flex" class="text-comment">
            <div style="width: 20%; float: left">テキスト</div>
            <div style="width: 80%; float: left">
              <el-input
                readonly
                disabled
                type="textarea"
                :rows="3"
                :class="`no-resize ${
                  creative.textSet.creatorComment != null && creative.textSet.creatorComment.length != 0
                    ? ''
                    : 'commentNone'
                }`"
                resize="none"
                style="min-width: 100%; max-width: 100%"
                :value="
                  creative.textSet.creatorComment != null && creative.textSet.creatorComment.length != 0
                    ? creative.textSet.creatorComment
                    : '制作意図記入(ユニーク)'
                "
              >
              </el-input>
            </div>
          </div>
          <div style="display: flex; margin-top: 10px" class="creative-comment">
            <div style="width: 23%"><i class="fas fa-edit" /> 備考</div>
          </div>
          <div style="flex-grow: 1">
            <el-input
              type="textarea"
              style="min-width: 100%; max-width: 100%"
              :value="creative.note"
              :rows="2"
              @input="v => $emit('change-note', v)"
            >
            </el-input>
          </div>
        </div>
      </div>
    </div>

    <item-dialog ref="itemDialog" :url="previewingImage" />
  </el-row>
</template>

<style>
.link-group .el-input input {
  height: 25px !important;
}
.asset-comment p {
  margin-top: 0px;
}

#comment-area textarea {
  color: #606266 !important;
}

#comment-area .commentNone textarea {
  color: #c0c4cc !important;
}

#comment-area {
  padding-left: 0.5rem;
}

#comment-area .slick-track {
  display: flex;
}

#comment-area .slick-list.draggable {
  width: -webkit-fill-available !important;
}

#comment-area .slick-slide {
  width: fit-content !important;
}

#comment-area .slick-slide > div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#comment-area .slick-prev,
#comment-area .slick-next {
  cursor: pointer;
  position: absolute;
  border: none;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: none;
}
#comment-area .isUrl input {
  border: 1px solid black;
}

#comment-area .notUrl input {
  border: 1px solid red;
}

#comment-area .isUrl input {
  border-color: #dcdfe6;
}
#comment-area .slick-prev {
  left: 0;
  transform: translate(-16px, -50%);
}

#comment-area .slick-next {
  right: 0;
  transform: translate(16px, -50%);
}

.thumb-items:hover {
  cursor: pointer;
}

.thumb-items img {
  padding: 2px !important;
}

.active-item {
  border: 1px #ff4444 solid;
}

#comment-area .thumbnail-list {
  padding-top: 1vh;
}

#comment-area .slick-arrow {
  height: 24px !important;
  width: 24px !important;
  background-color: transparent;
  border: none;
  z-index: 5;
  color: #000;
  font-size: 18px;
}

#comment-area .slick-arrow:disabled {
  background-color: rgba(0, 0, 0, 0.2);
  color: #ddd;
  cursor: no-drop;
}

#comment-area .slick-slide .items img {
  padding: 2px;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
#comment-area .slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  content: '';
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
#comment-area .slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir='rtl'] #comment-area .slick-slide {
  float: right;
}
#comment-area .slick-slide img {
  display: block;
}
#comment-area .slick-slide.slick-loading img {
  display: none;
}
#comment-area .slick-slide.dragging img {
  pointer-events: none;
}
#comment-area .slick-initialized .slick-slide {
  display: block;
}
#comment-area .slick-loading .slick-slide {
  visibility: hidden;
}
#comment-area .slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-track > div {
  display: inline-block;
}
#comment-area .slick-prev,
#comment-area .slick-next {
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: absolute;
  top: 48%;
  border: none;
}
#comment-area .slick-dots {
  position: absolute;
  bottom: -35px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}
#comment-area .slick-dots li {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  cursor: pointer;
  background-position: 0px -120px;
  margin: 0 8px;
}
#comment-area .slick-dots .slick-active,
#comment-area .slick-dots li:hover {
  background-position: 0px -140px;
}
#comment-area .slick-dots li button {
  border: 0;
  height: 10px;
  width: 10px;
  outline: none;
  line-height: 0;
  font-size: 0;
  color: transparent;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}
#comment-area .slider-thumbnail .slick-arrow {
  background-color: transparent;
  width: 20px;
  height: 50px;
  z-index: 5;
  color: #060630;
  font-size: 18px;
}
#comment-area .slider-thumbnail .slick-active {
  width: max-content !important;
}
#comment-area .slider-thumbnail .slick-track {
  display: flex;
}
#comment-area .no-resize ::-webkit-scrollbar {
  width: 5px;
  display: block;
  height: 5px;
}
#comment-area .no-resize ::-webkit-scrollbar-thumb {
  background-color: #cecac2; /* Màu của thanh cuộn (scroll thumb) */
  border-radius: 5px; /* Bo góc scroll thumb */
  border: 2px solid #ccc; /* Không hỗ trợ padding, margin, transition nên dùng viền cùng màu nên để padding scroll thumb */
}
#comment-area .slider-thumbnail .slick-arrow.slick-disabled {
  user-select: none !important;
  visibility: hidden !important;
}
#comment-area .slider-thumbnail .items {
  padding: 0 3px;
}
#comment-area .slider-thumbnail .items .img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 106px;
  width: 106px;
  position: relative;
}
#comment-area .slider-thumbnail .items > .img {
  background-color: #060630;
}
#comment-area .slider-thumbnail .items .img img,
#comment-area .slider-thumbnail .items .img iframe {
  display: block;
  max-width: 106px;
  max-height: 106px;
  height: auto;
  width: auto;
  margin: 0;
  padding: 0;
  pointer-events: none;
}
#comment-area {
  border-left: 1px solid #ebeef5;
}
</style>

<style scoped>
.w-100 {
  width: 100%;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
.mb-2 {
  margin-bottom: 0.8rem;
}
</style>

<script>
import _ from 'lodash'
import ItemDialog from '@/components/image-movie-item/components/item-dialog'

export default {
  name: 'comment-area',
  components: {
    ItemDialog,
  },
  props: {
    creative: { type: Object, default: () => ({}) },
    isInDialog: { type: Boolean, default: () => false },
    isCreativeList: { type: Boolean, default: () => false },
    isUpdate: { type: Boolean, default: () => false },
  },
  data: () => ({
    loading: false,
    notApprovalReasonIndex: null,
    previewingImage: '',
  }),
  created() {
    window.CommentArea = this
    this.init()
  },
  methods: {
    init() {
      if (!this.creative.notApprovalReason) return
      this.notApprovalReasonIndex = this.creative.notApprovalReason.length - 1
    },

    openItemDialog(url, image_id) {
      const image = document.getElementById(image_id)
      const imageAspect = image.naturalWidth / image.naturalHeight
      this.previewingImage = url
      this.$refs.itemDialog.show(imageAspect)
    },
  },
  computed: {
    creatorCommentAsset() {
      return this.creative.labeledAssets
        .map(asset => {
          return asset.assetGroup && asset.assetGroup.creatorComment
            ? asset.labelName + ' | ' + asset.assetGroup.creatorComment
            : ''
        })
        .filter(comment => comment.length > 0)
        .join('\n')
    },

    attachmentFileList() {
      const attachmentFileList = _.chain(this.creative.labeledAssets)
        .cloneDeep()
        .map('assetGroup')
        .map('attachmentFile')
        .flattenDeep()
        .compact()
        .value()
      return attachmentFileList
    },
  },
  watch: {
    creative: {
      handler() {
        this.init()
      },
      deep: true,
    },
  },
}
</script>
