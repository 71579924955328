<template>
  <div id="note-area">
    <el-row class="mb-1"> <i class="fas fa-edit" /> 備考 </el-row>

    <el-row class="mb-1">
      <el-input
        class="w-100"
        type="textarea"
        :disabled="isInDialog || !isCanEditNote"
        :rows="5"
        :value="creative.note"
        @input="note => $emit('change-note', note)"
      />
    </el-row>
  </div>
</template>

<style>
#note-area {
  padding-left: 0.5rem;
}
</style>

<style scoped>
.w-100 {
  width: 100%;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
</style>

<script>
export default {
  name: 'note-area',
  props: {
    creative: { type: Object, default: () => ({}) },
    isInDialog: { type: Boolean, default: () => false },
    isCanEditNote: { type: Boolean, default: () => true },
  },
  created() {
    window.NoteArea = this
  },
}
</script>
