<template>
  <fragment style="width: 100%; height: 100%">
    <el-container id="creative-list">
      <el-col :span="24">
        <div>
          <select-bar
            v-if="!isInDialog"
            :selected="selected"
            :is-tabs-exists="tabs.length !== 0"
            :is-in-dialog="isInDialog"
            :sort-type="sortType"
            :is-search-open="isDrawerShow"
            :is-sort-type-open="isSortTypeDrawerShow"
            target-type="creative"
            @reload="reload"
            @change-selected="changeSelected"
            @edit-note="editNote"
            @open-tag-dialog="openTagDialog"
            @open-snap-dialog="$refs.snapDialog.show()"
            @open-approval-request-dialog="openSelectTypeApprovalRequest"
            @open-submission-request-dialog="openSubmissionRequestDialog"
            @change-sort-type="
              v => {
                sortType = v
              }
            "
            @toggle-search="toggleSearch"
            @toggle-sort-type="toggleSortType"
            @delete-selected="deleteSelected"
            @download-selected="e => downloadFile(e)"
          />

          <el-collapse-transition>
            <drawer
              v-show="isDrawerShow"
              ref="drawer"
              :tags="tags"
              :selected-promotion-ids="selectedPromotionIds"
              @get-creatives="getCreatives"
            />
          </el-collapse-transition>

          <el-collapse-transition>
            <sort-type-creative
              v-if="isSortTypeDrawerShow"
              class="expandable"
              :sort-type="sortType"
              @toggle-sort-type="toggleSortType"
              @change-sort-type="
                v => {
                  sortType = v
                }
              "
            />
          </el-collapse-transition>

          <el-main>
            <el-tabs
              v-model="activeTab"
              type="border-card"
              @tab-remove="name => removeTab(name, true)"
              @tab-click="clickTab"
            >
              <el-tab-pane name="add" label="add" class="p-15px">
                <span slot="label">
                  <i class="fas fa-plus-circle" />
                </span>

                <div :style="{ height: tabHeight - 30 + 'px' }">
                  <div>【スタート方法】</div>
                  <ul>
                    <li>
                      広告フォーマットを指定して表示:
                      <i class="fas fa-plus-circle" /> ボタンを押して媒体･広告フォーマットを選択
                    </li>
                    <li>詳細検索: サイドバーの検索ボタン（検索条件無しで「検索」を押すと全件表示）</li>
                  </ul>
                </div>
              </el-tab-pane>

              <el-tab-pane v-for="(r, i) in tabs" :key="i" :name="r.platformId" :label="r.platformId" lazy closable>
                <span slot="label">
                  <i class="fas fa-home" />
                  {{ r.platformId }}
                </span>

                <el-tabs
                  v-model="r.activeName"
                  type="border-card"
                  @tab-remove="name => removeTab(name, false, r.platformId)"
                  @tab-click="clickAdformatTab"
                >
                  <el-tab-pane
                    v-for="{ name, adFormatId, platformId } in r.creatives"
                    :key="`${platformId}_${name}`"
                    :name="name"
                    :label="name"
                    :closable="$route.path !== '/creative-regist'"
                    lazy
                  >
                    <ad-format-tab-list
                      :ref="`adFormat_${name}`"
                      :ad-formats="adFormats"
                      :selected="selectedDraft"
                      :platform-id="platformId"
                      :ad-format-id="adFormatId"
                      :ad-format-name="name"
                      :creatives="groupByCreatives[adFormatId]"
                      :height="tabHeight"
                      :creative-ids="query.creativeIds || []"
                      :errors="errors"
                      :is-in-dialog="isInDialog"
                      :is-show="isShow"
                      :is-update="isUpdate"
                      :is-creative-list="true"
                      :display-type="displayType"
                      :sort-type="sortType"
                      @update-creative="updateCreatorCommentAndReferenceUrl"
                      @change-grouped-creatives="changeGroupedCreatives"
                      @change-query="
                        q => {
                          query = q
                        }
                      "
                      @change-reference-url="(urls, crId, platformId) => changeReferenceUrl(urls, crId, platformId)"
                      @change-creator-comment="changeCreatorComment"
                      @change-selected-at-tab="changeSelectedAtTab"
                      @get-creatives="getCreatives"
                      @change-note="changeNote"
                      @reset="reset"
                      @edit-note-creatives="editNoteCreatives"
                      @change-compare-creative="r => $emit('change-compare-creative', r)"
                      @open-proposed-dialog="
                        (textSetId, adFormatId) => $refs.proposedTextCreativeDialog.show(textSetId, adFormatId)
                      "
                      @change-status-text="(textSetId, status) => changeStatusText(textSetId, status)"
                      @change-status-asset="(assetId, status) => changeStatusAsset(assetId, status)"
                      @change-status-creative="(creativeId, status) => changeStatusCreative(creativeId, status)"
                    />
                  </el-tab-pane>
                </el-tabs>
              </el-tab-pane>
            </el-tabs>
          </el-main>
        </div>
        <div>
          <div class="button-area footer-area">
            <el-button
              class="footer-button"
              type="primary"
              @click="openSelectTypeApprovalRequest"
              :disabled="!selected.length > 0"
              >監修依頼作成</el-button
            >
            <el-button
              class="footer-button"
              style="margin-right: 30px"
              type="primary"
              @click="openSubmissionRequestDialog"
              :disabled="!selected.length > 0"
              >入稿依頼作成</el-button
            >
          </div>
        </div>
      </el-col>

      <select-ad-format-dialog ref="selectAdFormatDialog" :ad-formats="adFormats" @add-ad-format="addAdFormat" />

      <snap-dialog
        ref="snapDialog"
        :selected-promotion-ids="selectedPromotionIds"
        :selected="selected"
        @change-selected-at-tab="changeSelectedAtTab"
        @reload="reload"
      />

      <complete-dialog ref="completeDialog" />
      <proposed-text-dialog :ad-format-id="adFormatId" @get-creatives="getCreatives" ref="proposedTextCreativeDialog" />

      <select-approval-request-type-dialog
        ref="selectApprovalRequestTypeDialog"
        @open-approval-request-dialog="arType => openApprovalRequestDialog(arType)"
      />
    </el-container>

    <approval-request-dialog
      ref="approvalRequestDialog"
      :ad-formats="adFormats"
      :selected-promotion-ids="selectedPromotionIds"
      :selected="approvalCreatives"
      :items="items"
      @change-selected-at-tab="changeSelectedAtTab"
      @reload="reload"
      @open-complete-dialog="(url, type) => $refs.completeDialog.show(url, type)"
      @change-creator-comment="changeCreatorComment"
      @change-creator-comment-asset="changeCreatorCommentAsset"
      @change-creator-comment-text="changeCreatorCommentText"
      @update-list-creative="updateListCreative"
    />

    <submission-request-dialog
      ref="submissionRequestDialog"
      :ad-formats="adFormats"
      :selected-promotion-ids="selectedPromotionIds"
      :selected="selected"
      :grouped-creatives="groupedCreatives"
      @change-selected-at-tab="changeSelectedAtTab"
      @get-creatives="reload"
      @open-complete-dialog="url => $refs.completeDialog.show(url, '入稿依頼')"
    />
  </fragment>
</template>

<style>
#creative-list .el-main {
  padding: 10px 20px 60px 20px !important;
}

#creative-list .el-tabs__content {
  padding: 0;
}

.cr-list-loading {
  top: 60px !important;
}
</style>

<style scoped>
.p-15px {
  padding: 15px;
}
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'
import SelectAdFormatDialog from '@/components/select-ad-format-dialog'
import SelectBar from '@/components/select-bar'
import Drawer from '@/views/creative-list/components/drawer'
import SortTypeCreative from '@/views/creative-list/components/sort-type'
import AdFormatTabList from '@/views/creative-list/ad-format-tab-list'
import SubmissionRequestDialog from '@/views/creative-list/components/submission-request-dialog'
import ApprovalRequestDialog from '@/components/approval-request-dialog'
import SnapDialog from '@/views/creative-list/snap-dialog'
import CompleteDialog from '@/components/complete-dialog'
import SelectApprovalRequestTypeDialog from '@/views/creative-list/components/select-approval-request-type-dialog'
import ProposedTextDialog from '@/views/text-master/ad-format-tab/proposed-text-dialog'
import * as XLSX from 'xlsx'
import { CreativeStatus } from '@/mixins/creativeStatus'
import { AssetTextStatus } from '@/mixins/assetTextStatus'

export default {
  name: 'creative-list',
  components: {
    SelectAdFormatDialog,
    SelectBar,
    AdFormatTabList,
    SubmissionRequestDialog,
    SnapDialog,
    ApprovalRequestDialog,
    CompleteDialog,
    SelectApprovalRequestTypeDialog,
    ProposedTextDialog,
    Drawer,
    SortTypeCreative,
  },
  mixins: [util],
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
    adFormatId: { type: String, default: () => '' },
    queryPlatformIds: { type: Array, default: () => [] },
    queryAdFormatIds: { type: Array, default: () => [] },
    isShow: { type: Boolean, default: () => false },
    isInDialog: { type: Boolean, default: () => false },
    selectedCreative: { type: Object, default: () => ({}) },
  },
  data: () => ({
    bodies: null,
    loading: false,
    isSortTypeDrawerShow: false,
    isDrawerShow: false,
    isUpdate: false,
    selected: [],
    selectedDraft: [],
    groupedCreatives: [],
    tabs: [],
    allTabs: [],
    activeTab: 'add',
    innerHeight: window.innerHeight,
    destination: {},
    approvalCreatives: [],
    items: [],
    groupedCreativesDraft: [],
    query: {},
    adFormatIds: [],
    errors: [],
    displayType: 'default',
    sortType: 'df',
    isContainAssetCR: false,
  }),
  async created() {
    window.CreativeList = this
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })

    if (this.$route.query.creativeDraftId) this.$router.push({ query: _.omit(this.$route.query, ['creativeDraftId']) })

    if (this.$route.query.wallHittingResultId || this.$route.query.wallHittingDraftId) {
      this.$router.push({
        query: _.omit(this.$route.query, ['wallHittingResultId', 'wallHittingDraftId', 'page', 'adAccountId']),
      })
    }

    if (this.$route.query.submissionRequestIds || this.$route.query.submissionRequestId) {
      this.query.submissionRequestIds = [
        this.$route.query.submissionRequestIds || this.$route.query.submissionRequestId,
      ]
      await this.getCreatives()
    }

    if (this.$route.query.approvalRequestIds || this.$route.query.approvalRequestId) {
      this.query.approvalRequestIds = [this.$route.query.approvalRequestIds || this.$route.query.approvalRequestId]
      this.displayType = 'approvalRequest'
      await this.getCreatives()
    }

    if (this.$route.query.originIds) {
      this.query.originIds = _.isArray(this.$route.query.originIds)
        ? this.$route.query.originIds
        : [this.$route.query.originIds]
      await this.getCreatives()

      this.$router.push({ query: _.omit(this.$route.query, ['originIds']) })
    }

    if (this.$route.query.textSetIds) {
      this.query.textSetIds = _.isArray(this.$route.query.textSetIds)
        ? this.$route.query.textSetIds
        : [this.$route.query.textSetIds]
      await this.getCreatives()

      this.$router.push({ query: _.omit(this.$route.query, ['textSetIds']) })
    }

    if (this.$route.query.creativeIds) {
      this.query.creativeIds = _.isArray(this.$route.query.creativeIds)
        ? this.$route.query.creativeIds
        : [this.$route.query.creativeIds]
      await this.getCreatives()
    }

    if (this.queryPlatformIds.length !== 0 && this.queryAdFormatIds.length !== 0) {
      this.query.platformIds = this.queryPlatformIds
      this.query.adFormatIds = this.queryAdFormatIds
      await this.getCreatives()
    }
  },
  async mounted() {
    if (this.$route.path === '/creative-regist') {
      await this.$api.sleep(100)
      this.isDrawerShow = this.isShow
    }
  },
  beforeRouteLeave(to, from, next) {
    try {
      this.$loading().close()
    } catch (e) {
      console.error(e)
    } finally {
      next()
    }
  },
  methods: {
    toggleSearch() {
      this.isSortTypeDrawerShow = false
      this.isDrawerShow = !this.isDrawerShow
    },
    toggleSortType() {
      this.isDrawerShow = false
      this.isSortTypeDrawerShow = !this.isSortTypeDrawerShow
    },
    openSelectTypeApprovalRequest() {
      // check status Creative is includes status can't create Approval Request
      const creativeStatuses = this.selected.map(cr => cr.approvalStatus)
      const cannotCreateAR = creativeStatuses.find(status => CreativeStatus.NO_CREATE_AR.includes(status)) !== undefined

      const isStatusTextNG = _.chain(this.selected).map('textSet').map('approvalStatus').uniq().includes('破棄').value()
      const isStatusAssetNG = _.chain(this.selected)
        .map('labeledAssets')
        .flattenDeep()
        .map('assets')
        .flattenDeep()
        .map('approvalStatus')
        .uniq()
        .includes('破棄')
        .value()

      if (cannotCreateAR || isStatusAssetNG || isStatusTextNG) {
        this.$alert(
          '入稿不可のステータスを持っているCR・素材・テキストがありますので、 監修依頼作成 できません。\n' +
            'ご確認お願いします。',
          'アラート'
        )
      } else {
        this.$refs.selectApprovalRequestTypeDialog.show()
      }
    },
    async openSubmissionRequestDialog() {
      const isIncludesUnapproved = !_.chain(this.selected)
        .map('approvalStatus')
        .uniq()
        .every(status => status == '入稿可能' || status === '入稿済' || status === '入稿依頼済')
        .value()
      const allAdFormatIds = _.chain(this.tabs).map('creatives').flattenDeep().map('adFormatId').filter().uniq().value()

      const allSelected = _.chain(this.allTabs)
        .map(r => _.map(r.creatives, r2 => ({ electedlatformId: r.platformId, adFormatId: r2.adFormatId })))
        .flattenDeep()
        .filter(r => _.includes(allAdFormatIds, r.adFormatId))
        .map(r => _.map(this.groupByCreatives[r.adFormatId], r2 => _.assign({}, r, r2)))
        .flattenDeep()
        .map(r => {
          const asset = _.result(r, 'labeledAssets[0].assets[0]')
          return _.assign({}, r, { asset })
        })
        .orderBy(['asset.metadata.fileName', 'textSet.textSetId'], ['asc', 'asc'])
        .value()
      if (isIncludesUnapproved) {
        this.$alert(
          '入稿可能になっていないクリエイティブがありますので、入稿依頼できません。\n' + 'ご確認お願いします。',
          'アラート'
        )
      } else {
        if (allSelected.length !== this.selected.length) {
          const ret = await this.$confirm(
            '選択されていないクリエイティブがあります。入稿依頼を作成しますか？',
            '入稿依頼作成の確認',
            {
              confirmButtonText: '作成',
              cancelButtonText: '戻る',
              type: 'warning',
            }
          ).catch(e => e)
          if (ret === 'cancel') {
            return
          } else this.$refs.submissionRequestDialog.show()
        }
        this.$refs.submissionRequestDialog.show()
      }
    },
    openApprovalRequestDialog(arType) {
      this.$refs.approvalRequestDialog.show(arType)
    },
    changeCreatorComment(creatorComment, creativeId, platformId) {
      this.groupedCreativesDraft = _.chain(this.groupedCreativesDraft)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 =>
            r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { creatorComment })
          )
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selectedDraft = _.chain(this.selectedDraft)
          .cloneDeep()
          .map(r => (r.creativeId !== creativeId ? r : _.assign({}, r, { creatorComment })))
          .value()
      }
    },
    changeReferenceUrl(urls, creativeId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 =>
            r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { referenceUrls: urls })
          )
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selected = _.chain(this.selected)
          .cloneDeep()
          .map(r => (r.creativeId !== creativeId ? r : _.assign({}, r, { referenceUrls: urls })))
          .value()
      }
    },
    changeCreatorCommentAsset(creatorComment, originId, labelId, platformId, creativeId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 => {
            if (r2.creativeId !== creativeId) return r2

            const labeledAssets = _.map(r2.labeledAssets, r3 => {
              if (r3.labelId !== labelId) return r3
              const assets = _.map(r3.assets, r4 =>
                r4.originId !== originId ? r4 : _.assign({}, r4, { creatorComment })
              )
              return _.assign({}, r3, { assets })
            })
            return _.assign({}, r2, { labeledAssets })
          })
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selected = _.chain(this.selected)
          .cloneDeep()
          .map(r => {
            const labeledAssets = _.map(r.labeledAssets, r2 => {
              if (r2.labelId !== labelId) return r2
              const assets = _.map(r2.assets, r3 =>
                r3.originId !== originId ? r3 : _.assign({}, r3, { creatorComment })
              )
              return _.assign({}, r2, { assets })
            })
            return _.assign({}, r, { labeledAssets })
          })
          .value()
      }
    },
    changeCreatorCommentText(creatorComment, textSetId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 => {
            if (!r2.textSet || r2.textSet.textSetId !== textSetId) return r2

            const textSet = _.assign({}, r2.textSet, { creatorComment })
            return _.assign({}, r2, { textSet })
          })
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selected = _.chain(this.selected)
          .cloneDeep()
          .map(r2 => {
            if (!r2.textSet || r2.textSet.textSetId !== textSetId) return r2
            const textSet = _.assign({}, r2.textSet, { creatorComment })
            return _.assign({}, r2, { textSet })
          })

          .value()
      }
    },
    changeNote(note, creativeId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 => (r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { note })))
          return _.assign({}, r, { creatives })
        })
        .value()
    },

    async changeStatusAsset(assetId, status) {
      this.loading = true
      const body = {
        targetType: 'asset',
        targetIds: [{ targetId: assetId }],
        approvalStatus: status,
      }
      await this.$api.authFetch('/approval_status', { method: 'PUT', body })
      let affectedCrIds = []
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(groupedCr => {
          const creatives = _.map(groupedCr.creatives, cr => {
            const labeledAssets = cr.labeledAssets.map(labeledAsset => {
              const assets = labeledAsset.assets.map(asset => {
                if (asset.assetId === assetId) {
                  affectedCrIds.push(cr.creativeId)
                  return _.assign({}, asset, { approvalStatus: status })
                }
                return asset
              })
              return _.assign({}, labeledAsset, { assets })
            })
            return _.assign({}, cr, { labeledAssets })
          })
          return _.assign({}, groupedCr, { creatives })
        })
        .value()

      // update the status of creative by changed asset status
      const uniqueAffectedCrIds = _.uniq(affectedCrIds)
      this.updateCrStatusByCrIds(uniqueAffectedCrIds)
      this.loading = false
    },

    async changeStatusText(updatingTextSetId, status) {
      this.loading = true
      const body = {
        targetType: 'textSet',
        targetIds: [{ targetId: updatingTextSetId }],
        approvalStatus: status,
      }
      await this.$api.authFetch('/approval_status', { method: 'PUT', body })

      let affectedCrIds = []
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(groupedCr => {
          const creatives = _.map(groupedCr.creatives, cr => {
            let text = cr.textSet
            if (text?.textSetId === updatingTextSetId) {
              text = _.assign({}, cr.textSet, { approvalStatus: status })
              affectedCrIds.push(cr.creativeId)
            }
            return _.assign({}, cr, { textSet: text })
          })
          return _.assign({}, groupedCr, { creatives })
        })
        .value()

      // update the status of creative by changed text status
      const uniqueAffectedCrIds = _.uniq(affectedCrIds)
      this.updateCrStatusByCrIds(uniqueAffectedCrIds)

      this.loading = false
    },

    async changeStatusCreative(creativeId, status, canRequestUpdate = true) {
      this.loading = true
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(groupedCr => {
          const creatives = _.map(groupedCr.creatives, cr =>
            cr.creativeId === creativeId ? _.assign({}, cr, { approvalStatus: status }) : cr
          )
          return _.assign({}, groupedCr, { creatives })
        })
        .value()
      if (canRequestUpdate) {
        const body = {
          targetType: 'creative',
          targetIds: [{ targetId: creativeId }],
          approvalStatus: this.convertApprovalStatusOptionsJapaneseToEnglish(status),
        }
        await this.$api.authFetch('/approval_status', { method: 'PUT', body })
      }
      this.loading = false
    },
    // Update status of creatives within id list by its components' status
    updateCrStatusByCrIds(crIdList) {
      const updateCrSttByComponentStatus = (creative, listStatus) => {
        if (listStatus.some(stt => stt === AssetTextStatus.NG)) {
          this.changeStatusCreative(creative.creativeId, CreativeStatus.NG, false)
        } else if (listStatus.some(stt => stt === AssetTextStatus.FIXING)) {
          this.changeStatusCreative(creative.creativeId, CreativeStatus.CHECKING, false)
        } else if (listStatus.every(stt => stt === AssetTextStatus.APPROVED)) {
          this.changeStatusCreative(creative.creativeId, CreativeStatus.APPROVED, false)
        } else {
          this.changeStatusCreative(creative.creativeId, CreativeStatus.WAITING_FOR_APPROVAL, false)
        }
      }
      const updateableCrList = this.groupedCreatives
        .flatMap(groupedCr => groupedCr.creatives)
        .filter(
          cr => crIdList.includes(cr.creativeId) && !CreativeStatus.NO_UPDATE_STATUS_CR.includes(cr.approvalStatus)
        )
      updateableCrList.map(creative => {
        const assetStatus = _.chain(creative.labeledAssets)
          .cloneDeep()
          .map('assets')
          .flattenDeep()
          .map(asset => asset.approvalStatus)
          .value()
        const textStatus = creative.textSet ? creative.textSet.approvalStatus : ''
        const listStatus = [...assetStatus, textStatus].filter(x => x.length != 0)

        updateCrSttByComponentStatus(creative, listStatus)
      })
    },
    async editNote(note) {
      const creatives = _.map(this.selected, r => {
        const { creatives } = _.find(this.groupedCreatives, r2 => r.platformId === r2.platformId)
        const creative = _.find(creatives, r2 => r.creativeId === r2.creativeId)
        const labeledAssets = _.map(creative.labeledAssets, r2 => ({
          labelId: r2.labelId,
          originIds: _.map(r2.assets, 'originId'),
        }))
        return {
          creativeId: _.result(creative, 'creativeId') || '',
          creativeType: _.result(creative, 'creativeType') || '',
          adFormatId: _.result(creative, 'adFormat.id') || '',
          textSetId: _.result(creative, 'textSet.textSetId') || '',
          labeledAssets,
          score: _.result(creative, 'score') || null,
          deviationScore: _.result(creative, 'deviationScore') || null,
          deviationRank: _.result(creative, 'deviationRank') || null,
          note,
          creatorComment: _.result(creative, 'creatorComment') || null,
        }
      })

      await this.editNoteCreatives(note, creatives)
    },
    async editNoteCreatives(note, creatives) {
      const urlPromotionId = _.isArray(this.$route.query.promotionIds)
        ? this.$route.query.promotionIds[0]
        : this.$route.query.promotionIds
      this.loading = true
      const result = await this.$api.authFetch('/creative', {
        method: 'PUT',
        body: { promotionId: urlPromotionId, creatives },
      })
      this.loading = false

      if (result && result.errors) {
        this.errors = result.errors.map(x => {
          return x.labelNames.join(', ').concat(`: ${x.error}`)
        })
        await this.$alert('規定エラーがあります。エラーボタンから確認できます。', '確認', {
          type: 'warning',
        })
      } else this.getCreatives()
    },
    updateListCreative(selected, groupCreative) {
      this.selected = selected
      this.groupedCreatives = groupCreative
      this.isUpdate = !this.isUpdate
      this.changeGroupedCreativeTypeView()
    },
    openTagDialog() {
      const params = {
        targetType: 'creative',
        selectedTags: this.selectedTags,
        targetIds: _.map(this.selected, 'creativeId'),
      }
      this.$root.$children[0].$refs.tagDialog.show(params)
    },
    async deleteSelected() {
      const ret = await this.$confirm('クリエイティブを削除しますか?', '確認').catch(e => e)
      if (ret === 'cancel') return
      const isSelectedCreativeHasStatusSubmitted = this.isSelectedCreativeHasStatus(CreativeStatus.SUBMITTED)
      const isSelectedCreativeHasStatusSendSubmit = this.isSelectedCreativeHasStatus(CreativeStatus.SEND_REQUEST_SUBMIT)
      const isSelectedCreativeHasStatusChecking = this.isSelectedCreativeHasStatus(CreativeStatus.CHECKING)
      if (isSelectedCreativeHasStatusSubmitted) {
        this.$confirm('入稿済のクリエイティブなので、削除できません。', '確認', {
          showCancelButton: false,
        }).catch(e => e)
      } else if (isSelectedCreativeHasStatusSendSubmit) {
        this.$confirm('入稿依頼済みのクリエイティブなので、削除できません。', '確認', {
          showCancelButton: false,
        }).catch(e => e)
      } else if (isSelectedCreativeHasStatusChecking) {
        this.$confirm('承認待ちのクリエイティブがありますので、削除できません。', '確認', {
          showCancelButton: false,
        }).catch(e => e)
      } else {
        const param = _.map(this.selected, 'creativeId')
        this.loading = true
        const res = await this.$api.authFetch('/creative', {
          method: 'DELETE',
          query: { creativeId: param },
        })
        const creativeIds = _.chain(this.query.creativeIds)
          .filter(x => !param.includes(x))
          .value()
        this.query = _.assign({}, this.query, { creativeIds: creativeIds })
        if (typeof res !== 'undefined') {
          this.selected = []
          await this.getCreatives()
        }
        this.loading = false
      }
    },

    isSelectedCreativeHasStatus(approvalStatus) {
      const creatives = this.selected.find(cr => cr.approvalStatus === approvalStatus)
      return creatives !== undefined
    },

    changeSelectedAtTab({ selected, platformId, adFormatId }) {
      const selectedDraft = _.map(selected, v =>
        _.find(this.groupByCreativesOld[adFormatId], r => r.creativeId === v.creativeId)
      )
      const formatedSelected = _.map(selectedDraft, r => _.assign({}, r, { platformId, adFormatId }))
      if (this.selected.length === 0) {
        this.selected = formatedSelected
        return
      }

      this.selected = _.chain(this.selected)
        .filter(r => r.adFormatId !== adFormatId)
        .concat(formatedSelected)
        .uniqBy('creativeId')
        .value()
    },
    changeSelected(isAll, isOnlyTab, isDeselectTab) {
      if (!isAll) {
        this.selected = []
        return
      }

      const selectedAdFormatIds = isOnlyTab
        ? _.chain(this.tabs)
            .filter(r => r.platformId === this.activeTab)
            .map(r => _.filter(r.creatives, r2 => r2.name === r.activeName))
            .flattenDeep()
            .map('adFormatId')
            .filter()
            .uniq()
            .value()
        : _.chain(this.tabs).map('creatives').flattenDeep().map('adFormatId').filter().uniq().value()

      const creativeList = _.chain(this.allTabs)
        .map(r => _.map(r.creatives, r2 => ({ platformId: r.platformId, adFormatId: r2.adFormatId })))
        .flattenDeep()
        .filter(r => _.includes(selectedAdFormatIds, r.adFormatId))
        .map(r => _.map(this.groupByCreatives[r.adFormatId], r2 => _.assign({}, r, r2)))
        .flattenDeep()
        .map(r => {
          const asset = _.result(r, 'labeledAssets[0].assets[0]')
          return _.assign({}, r, { asset })
        })
        .orderBy(['asset.metadata.fileName', 'textSet.textSetId'], ['asc', 'asc'])
        .value()

      this.selected = isDeselectTab
        ? this.selected.filter(x => {
            return !_.includes(
              creativeList.map(y => _.isEqual(y.creativeId, x.creativeId)),
              true
            )
          })
        : creativeList
    },
    clickTab({ label }) {
      if (label === 'add') this.$refs.selectAdFormatDialog.show()
      else if (this.tabs) {
        const activeAdformat = this.tabs.find(tab => tab.platformId === label)
        if (activeAdformat) {
          this.clickAdformatTab({ label: activeAdformat.activeName })
        }
      }
    },
    clickAdformatTab({ label }) {
      const adFormatRef = this.$refs[`adFormat_${label}`]
      if (adFormatRef && adFormatRef[0]) adFormatRef[0].show()
    },
    async removeTab(targetName, isParent, platformId) {
      this.tabs = []
      await this.$nextTick()

      if (isParent) {
        this.tabs = _.filter(this.allTabs, r => r.platformId !== targetName)
        if (this.activeTab === targetName) {
          this.activeTab =
            _.map(this.tabs, 'platformId').length === 0 ? 'add' : _.chain(this.tabs).map('platformId').first().value()
        }
      } else {
        const parentNames = _.map(this.allTabs, 'platformId')
        this.tabs = _.chain(this.allTabs)
          .map(r => {
            const childrenNames = _.map(r.creatives, 'name')
            const activeName =
              r.activeName === targetName
                ? r.activeName
                : childrenNames.length === 0
                ? ''
                : childrenNames[_.indexOf(childrenNames, targetName) + 1]
            const creatives = _.filter(r.creatives, r2 => r2.name !== targetName)
            return _.assign({}, r, { activeName, creatives })
          })
          .filter(r => r.creatives.length !== 0)
          .value()

        if (this.activeName !== platformId) {
          this.activeTab = parentNames.length === 0 ? 'add' : parentNames[_.indexOf(parentNames, platformId)]
        }

        this.tabs = _.chain(this.allTabs)
          .mapValues(r => {
            const creatives = _.filter(r.creatives, r2 => r2.name !== targetName)
            return creatives.length === 0 ? {} : _.assign({}, r, { creatives })
          })
          .omitBy(_.isEmpty)
          .value()
      }

      this.allTabs = _.cloneDeep(this.tabs)
      if (_.isEmpty(this.tabs)) return

      const keyBySelectedAdFormatIds = _.chain(this.tabs)
        .map('creatives')
        .flattenDeep()
        .uniqBy('adFormatId')
        .keyBy('adFormatId')
        .value()

      this.selected = _.filter(this.selected, r => keyBySelectedAdFormatIds[r.adFormatId])
    },
    addAdFormat(gr) {
      this.adFormatIds = _.chain(this.tabs)
        .map('creatives')
        .flattenDeep()
        .map('adFormatId')
        .uniq()
        .thru(ret => (!gr ? ret : _.concat(ret, gr)))
        .filter()
        .uniq()
        .value()

      if (this.adFormatIds.length === 0) {
        this.groupedCreatives = []
        this.tabs = []
        this.allTabs = []
        this.activeTab = 'add'
        return
      }

      this.getCreatives()
    },
    async reload() {
      await this.getCreatives()
      this.selected = _.map(this.selected, r => {
        const { approvalStatus } = _.find(this.groupByCreatives[r.adFormatId], r2 => r2.creativeId === r.creativeId)
        return _.assign({}, r, { approvalStatus })
      })
    },
    getCreativeBody(params) {
      if (!params) {
        params = _.assign(
          {},
          { adFormatIds: this.adFormatIds },
          _.isEmpty(this.query) ? this.$refs.drawer.getFormParams() : this.query
        )
        if (this.$route.path !== '/creative-regist')
          this.$router.push({
            query: _.assign({}, { promotionIds: this.$route.query.promotionIds }, this.query),
          })
      } else if (this.adFormatId) params = _.assign(params, { adFormatIds: [this.adFormatId] })
      else if (!params || _.isEmpty(params))
        params = { adFormatIds: _.chain(this.tabs).map('adFormatId').filter().uniq().value() }
      return params
    },
    async getCreatives(params) {
      const urlPromotionId = _.isArray(this.$route.query.promotionIds)
        ? this.$route.query.promotionIds
        : [this.$route.query.promotionIds]

      const newParams = this.getCreativeBody(params)
      const convertedApprovalStatusParams = newParams.approvalStatus
        ? newParams.approvalStatus.map(value => this.convertApprovalStatusOptionsJapaneseToEnglish(value))
        : []
      const convertedParams = { ...newParams, approvalStatus: [...convertedApprovalStatusParams] }

      const isEmpty = Object.values(newParams).findIndex(bd => !_.isEmpty(bd)) === -1
      const body = _.assign({}, convertedParams, {
        promotionIds: this.selectedPromotionIds.length !== 0 ? this.selectedPromotionIds : urlPromotionId,
      })
      this.loading = true
      this.groupedCreatives = !isEmpty
        ? _.result(
            await this.$api.authFetch('/creative/list', {
              method: 'POST',
              body,
            }),
            'groupedCreatives'
          )
        : []
      await this.changeGroupedCreativeTypeView()
      this.loading = false

      if (this.groupedCreatives.length === 0) this.$alert('結果は0件です', '検索結果')

      this.tabs = _.chain(this.groupedCreatives)
        .map(({ platformId, creatives }) =>
          _.map(creatives, ({ adFormat: { id, name } }) => ({ platformId, adFormatId: id, name }))
        )
        .flattenDeep()
        .groupBy('name')
        .toArray()
        .flattenDeep()
        .uniqBy(r => r.adFormatId + r.platformId)
        .groupBy('platformId')
        .map((gr, platformId) => {
          const creatives = _.orderBy(gr, ['name'])
          const activeName =
            _.chain(this.tabs)
              .find(r => r.platformId === platformId)
              .result('activeName')
              .value() || _.result(creatives, '[0].name')
          return { platformId, activeName, creatives }
        })
        .orderBy(['platformId'])
        .value()

      this.allTabs = _.cloneDeep(this.tabs)
      if (this.groupedCreatives.length === 0) this.activeTab = 'add'
      else if (this.activeTab === 'add') this.activeTab = _.result(this.tabs, '[0].platformId')
      else if (!_.chain(this.allTabs).map('platformId').includes(this.activeTab).value()) {
        this.activeTab = _.chain(this.allTabs).last().result('platformId').value()
      }
    },
    async reset(isNotGetCreatives) {
      if (this.$route.path !== '/creative-regist' && !isNotGetCreatives) return

      this.selected = []
      if (!isNotGetCreatives) {
        await this.getCreatives({
          adFormatIds: this.adFormatId ? [this.adFormatId] : this.queryAdFormatIds,
          promotionIds: this.selectedPromotionIds,
        })
      }
    },
    initializeColumn(adFormatId) {
      const adFormatChosen = this.adFormats.find(x => x.id === adFormatId)
      const textLabels = adFormatChosen.textLabels.sort((a, b) => {
        return a.labelIndex - b.labelIndex
      })
      const assetLabels = adFormatChosen.assetLabels.sort((a, b) => {
        return a.labelIndex - b.labelIndex
      })

      const textLabelsColumn = textLabels.map(x => x.name)
      const assetCFMLabelColumn = assetLabels.map(x => {
        return `${x.name}【使用不可の元ファイル名】`
      })
      const assetLabelColumn = assetLabels.map(x => x.name)
      return ['商材', 'プラットフォーム', '広告フォーマット名']
        .concat(assetLabelColumn)
        .concat(textLabelsColumn)
        .concat(['OddAi予測結果', '制作者コメント', '監修ステータス', '入稿ステータス', 'タグ', '非承認コメント'])
        .concat(assetCFMLabelColumn)
        .concat('クリエイティブタイプ')
    },
    convertArr(arr) {
      return arr
        .map(k => {
          return k
        })
        .join('\n')
    },
    buildXlsxdata(creativeList, adFormatId) {
      const adFormatChosen = this.adFormats.find(x => x.id === adFormatId)
      const textLabels = adFormatChosen.textLabels.sort((a, b) => {
        return a.labelIndex - b.labelIndex
      })
      const assetLabels = adFormatChosen.assetLabels.sort((a, b) => {
        return a.labelIndex - b.labelIndex
      })
      return creativeList.map(creative => {
        const textValueObj = []
        const assetValueObj = []
        const assetCFMValueObj = []
        const labeledTexts = creative.textSet ? creative.textSet.labeledTexts : []
        textLabels.forEach(label => {
          const existLabeledText = labeledTexts.find(x => x.labelName === label.name)
          if (!existLabeledText) {
            textValueObj.push('')
          } else {
            textValueObj.push(this.convertArr(existLabeledText.textValues))
          }
        })

        const labeledAsset = creative.labeledAssets
        assetLabels.forEach(label => {
          const existLabeledAsset = labeledAsset.find(x => x.labelName === label.name)
          if (!existLabeledAsset) {
            assetValueObj.push('')
            assetCFMValueObj.push('')
          } else {
            assetValueObj.push(this.convertArr(existLabeledAsset.assets.map(x => x.metadata.fileName)))
            assetCFMValueObj.push(this.convertArr(existLabeledAsset.assets.map(x => x.metadata.rawFileName)))
          }
        })

        return [this.$root.$children[0].selectedPromotions[0].name, creative.platformId, creative.adFormatName]
          .concat(assetValueObj)
          .concat(textValueObj)
          .concat([
            creative.score ? creative.score : '',
            creative.creatorComment ? creative.creatorComment : '',
            creative.approvalStatus ? creative.approvalStatus : '',
            creative.submissionStatus ? creative.submissionStatus : '',
            creative.tags ? this.convertArr(creative.combinedTags.map(x => x.tagValue)) : '',
            creative.notApprovalReason ? this.convertArr(creative.notApprovalReason) : '',
          ])
          .concat(assetCFMValueObj)
          .concat(creative.creativeType === 'Asset' ? 'アセット' : '')
      })
    },
    formatDateXlsx(date) {
      const year = date.substring(0, 4)
      const month = date.substring(4, 6)
      const day = date.substring(6, 8)
      const hour = date.substring(8, 10)
      const minute = date.substring(10, 12)
      const second = date.substring(12, 14)

      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    },
    updateCreatorCommentAndReferenceUrl(referenceUrls, creatorComment, creativeId, platformId) {
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 =>
            r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { creatorComment })
          )
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selected = _.chain(this.selected)
          .cloneDeep()
          .map(r => (r.creativeId !== creativeId ? r : _.assign({}, r, { creatorComment })))
          .value()
      }
      this.groupedCreatives = _.chain(this.groupedCreatives)
        .cloneDeep()
        .map(r => {
          if (r.platformId !== platformId) return r

          const creatives = _.map(r.creatives, r2 =>
            r2.creativeId !== creativeId ? r2 : _.assign({}, r2, { referenceUrls: referenceUrls })
          )
          return _.assign({}, r, { creatives })
        })
        .value()

      if (this.selected.length !== 0) {
        this.selected = _.chain(this.selected)
          .cloneDeep()
          .map(r => (r.creativeId !== creativeId ? r : _.assign({}, r, { referenceUrls: referenceUrls })))
          .value()
      }
    },
    formatTime(time) {
      return time < 10 ? '0' + time : time
    },
    getCurrentTime() {
      const now = new Date()
      return `${now.getFullYear().toString()}${this.formatTime(now.getMonth() + 1)}${this.formatTime(
        now.getDate()
      )}${this.formatTime(now.getUTCHours() + 9)}${this.formatTime(now.getMinutes())}${this.formatTime(
        now.getSeconds()
      )}`
    },
    async downloadFile() {
      const workbook = XLSX.utils.book_new()
      const urlPromotionId = _.isArray(this.$route.query.promotionIds)
        ? this.$route.query.promotionIds[0]
        : this.$route.query.promotionIds

      const creativeIds = this.selected.map(x => x.creativeId)
      const shortlistUrl = _.result(
        await this.$api.authFetch('/short_link', {
          method: 'POST',
          body: { creativeIds: creativeIds, promotionId: urlPromotionId },
        }),
        'url'
      )

      const assetSheet = [['画像動画はこちら→', shortlistUrl]]
      const worksheet = XLSX.utils.aoa_to_sheet(assetSheet)
      worksheet['B1'].l = { Target: shortlistUrl }
      XLSX.utils.book_append_sheet(workbook, worksheet, '画像動画Zipダウンロード')
      this.xlsxData.forEach(x => XLSX.utils.book_append_sheet(workbook, x.sheet, x.platformName))
      const fileName = `${this.getCurrentTime()}_${this.$root.$children[0].selectedPromotions[0].name}_creative.xlsx`
      XLSX.writeFileXLSX(workbook, fileName)
    },
    getPlatformName(platformId) {
      return _.chain(this.adFormats)
        .find(r => platformId === r.platformId)
        .result('platformName')
        .value()
    },
    async changeGroupedCreativeTypeView() {
      if (this.displayType === 'approvalRequest') {
        const ids = _.filter(
          _.uniq(
            _.chain(this.groupedCreatives)
              .map('creatives')
              .flattenDeep()
              .map('labeledAssets')
              .flattenDeep()
              .map(x => x.assets.map(x => x.assetId).join('_'))
              .value()
          ),
          x => x !== ''
        )
        let result = []
        // get asset group if Creatives have asset
        if (ids.length > 0) {
          this.loading = true
          await this.$api
            .authFetch('/asset_group/list', {
              method: 'POST',
              body: ids,
            })
            .then(assetGroups => (result = assetGroups))
            .finally(() => {
              this.loading = false
            })
        }
        this.groupedCreatives = _.chain(this.groupedCreatives)
          .cloneDeep()
          .map(r => {
            const creatives = _.map(r.creatives, r2 => {
              const labeledAssets = _.map(r2.labeledAssets, r3 => {
                const assetGroupId = _.chain(r3.assets)
                  .cloneDeep()
                  .map(x => x.assetId)
                  .join('_')
                  .value()
                const assetGroup = _.find(result, x => {
                  return x.assetGroupId === assetGroupId
                })
                return assetGroup === undefined ? r3 : _.assign({}, r3, { assetGroup })
              })
              return _.assign({}, r2, { labeledAssets })
            })
            return _.assign({}, r, { creatives })
          })
          .value()
      }
    },

    changeGroupedCreatives() {
      this.groupedCreativesDraft = _.chain(this.groupedCreativesDraft)
        .cloneDeep()
        .map(r => {
          switch (this.sortType) {
            case 'text_set': {
              const creatives = _.orderBy(
                r.creatives,
                [
                  function (r2) {
                    if (r2.textSet) {
                      return _.reduce(
                        r2.textSet.labeledTexts,
                        function (sum, r3) {
                          return _.reduce(
                            r3.textValues,
                            function (sum2, r4) {
                              return sum2 + r4.toLowerCase()
                            },
                            sum
                          )
                        },
                        ''
                      )
                    } else return ''
                  },
                  function (r2) {
                    if (r2.textSet)
                      return _.reduce(
                        r2.textSet.labeledTexts,
                        function (sum, r3) {
                          if (r3.textValues.length !== 0)
                            return _.reduce(
                              r3.textValues,
                              function (sum2, r4) {
                                return sum2 + r4.length
                              },
                              sum
                            )
                          else return 1
                        },
                        0
                      )
                    else return 0
                  },
                  'createdAt',
                  'creativeId',
                ],
                ['asc', 'asc', 'asc', 'asc']
              )
              return _.assign({}, r, { creatives })
            }
            case 'pixel_size': {
              const creatives = _.orderBy(
                r.creatives,
                [
                  function (r2) {
                    if (r2.labeledAssets) {
                      const assetTypes = _.chain(r2.labeledAssets)
                        .flatMap('assets')
                        .flatMap('assetType')
                        .uniq()
                        .sort()
                        .value()
                      if (
                        _.isEqual(assetTypes, ['Url', 'Zip']) ||
                        _.isEqual(assetTypes, ['Url']) ||
                        _.isEqual(assetTypes, ['Zip'])
                      ) {
                        return _.reduce(
                          r2.labeledAssets,
                          function (sum, r3) {
                            return _.reduce(
                              r3.assets,
                              function (sum2) {
                                return sum2 + 1
                              },
                              sum
                            )
                          },
                          99999
                        )
                      } else return 1
                    } else return 0
                  },
                  function (r2) {
                    return _.chain(r2.labeledAssets)
                      .flatMap('assets')
                      .flatMap('metadata')
                      .flatMap(item => {
                        if (item.width !== undefined) return `${item.width}x${item.height}`
                        else return ''
                      })
                      .uniq()
                      .orderBy()
                      .join('')
                      .value()
                      .toString()
                  },
                  'createdAt',
                  'updatedAt',
                ],
                ['asc', 'asc', 'asc', 'asc']
              )
              return _.assign({}, r, { creatives })
            }
            case 'ctx_file_name': {
              const creatives = _.orderBy(
                r.creatives,
                [
                  function (r2) {
                    if (r2.labeledAssets)
                      return _.reduce(
                        r2.labeledAssets,
                        function (sum, r3) {
                          if (r3.assets && r3.assets.length > 0)
                            return _.reduce(
                              r3.assets,
                              function (sum2, r4) {
                                return sum2 + r4.metadata.fileName.toLowerCase()
                              },
                              sum
                            )
                          else return sum
                        },
                        ''
                      )
                    else return ''
                  },
                  function (r2) {
                    if (r2.labeledAssets)
                      return _.reduce(
                        r2.labeledAssets,
                        function (sum, r3) {
                          if (r3.assets && r3.assets.length > 0)
                            return _.reduce(
                              r3.assets,
                              function (sum2, r4) {
                                return sum2 + r4.metadata.fileName.length
                              },
                              sum
                            )
                          else return sum
                        },
                        0
                      )
                    else return 0
                  },
                  'createdAt',
                  'creativeId',
                ],
                ['asc', 'asc', 'asc', 'asc']
              )
              return _.assign({}, r, { creatives })
            }
            default:
              return _.assign({}, r)
          }
        })
        .value()
    },
    checkAdFormatTabVisible(activeName, currentName) {
      return this.isShow || activeName === currentName
    },
  },
  watch: {
    loading() {
      if (this.loading) {
        this.$loading({
          lock: true,
          fullscreen: true,
          background: 'hsla(0,0%,100%,.9)',
          customClass: 'cr-list-loading',
        })
      } else {
        this.$loading().close()
      }
    },
    groupedCreatives() {
      this.items = _.chain(this.groupedCreatives)
        .cloneDeep()
        .orderBy([r => r.platformId + r.adFormatId])
        .value()
      this.groupedCreativesDraft = _.cloneDeep(this.groupedCreatives)
      if (this.selected.length > 0) {
        const selectedIds = this.selected.map(cr => cr.creativeId)
        const newSelectedData = this.groupedCreatives.flatMap(groupedCr =>
          groupedCr.creatives.filter(cr => selectedIds.includes(cr.creativeId))
        )
        const newSelected = this.selected.map(({ creativeId, platformId, adFormatId }) => {
          const selectedCrData = newSelectedData.find(cr => cr.creativeId === creativeId)
          return {
            platformId,
            adFormatId,
            ...selectedCrData,
          }
        })
        this.selected = newSelected
      }
    },
    async displayType() {
      await this.changeGroupedCreativeTypeView()
    },

    selectedPromotionIds() {
      if (this.tabs.length === 0) return
      this.getCreatives(this.adFormatId ? { adFormatIds: [this.adFormatId] } : undefined)
      this.approvalCreative
    },
    selected() {
      this.approvalCreatives = _.chain(this.selected)
        .cloneDeep()
        .orderBy([r => r.platformId + r.adFormatId])
        .value()
      this.selectedDraft = _.cloneDeep(this.selected)
      if (this.$route.path !== '/creative-regist') return

      if (this.selected.length === 0) return
      else if (this.selected.length >= 2) {
        this.selected = [_.first(this.selected)]
        this.$alert('比較元クリエイティブの選択は1つのみです', '選択制御')
        return
      }

      const { adFormatId, creativeId } = _.first(this.selected)
      const selectedCreative = _.find(this.groupByCreatives[adFormatId], r2 => r2.creativeId === creativeId)
      this.$emit('change-selected-creative', selectedCreative)
    },
    isShow() {
      if (this.$route.path === '/creative-regist') this.isDrawerShow = this.isShow
    },
  },
  computed: {
    tabHeight() {
      const height =
        this.$route.path === '/creative-regist'
          ? this.innerHeight - 55 - 20 - 57 - 16 - 21 - 16 - 50 - 10 - 39 - 39 - 20 - 70
          : this.innerHeight - 60 - 41 - 41 - 10 - 20 - (this.isDrawerShow ? 45 + 185 : 0)

      return this.tabs.length === 0 ? height : height - 41
    },
    drawerHeight() {
      return this.$route.path === '/creative-regist'
        ? this.innerHeight - 55 - 20 - 57 - 16 - 21 - 16 - 50 - 16 - 16 - 20 - 20 - 70
        : this.innerHeight - 60 - 32
    },
    allPlatforms() {
      return _.chain(this.adFormats).map('platformId').uniq().value()
    },
    groupByCreatives() {
      this.changeGroupedCreatives()
      return _.chain(this.groupedCreativesDraft).map('creatives').flattenDeep().groupBy('adFormat.id').value()
    },
    groupByCreativesOld() {
      this.changeGroupedCreatives()
      return _.chain(this.groupedCreatives).map('creatives').flattenDeep().groupBy('adFormat.id').value()
    },
    tagIdByTargets() {
      return _.chain(this.groupedCreatives)
        .map('creatives')
        .flattenDeep()
        .map(r => _.map(r.tags, r2 => _.assign({}, r2, { targetId: r.creativeId })))
        .flattenDeep()
        .groupBy('tagId')
        .map(gr => ({
          targetIds: _.map(gr, 'targetId'),
          tagId: _.first(gr).tagId,
        }))
        .value()
    },
    selectedTags() {
      const targetIdsAll = _.map(this.selected, 'creativeId')
      return _.chain(this.selected)
        .map(r =>
          _.chain(this.tagIdByTargets)
            .filter(r2 => _.includes(r2.targetIds, r.creativeId))
            .map(r2 => ({ targetId: r.creativeId, tagId: r2.tagId }))
            .value()
        )
        .flattenDeep()
        .groupBy('tagId')
        .mapValues(gr => {
          const selectedTargetIds = _.map(gr, 'targetId')
          return {
            selectedTargetIds,
            isAllSelected: selectedTargetIds.length === targetIdsAll.length,
          }
        })
        .value()
    },
    xlsxData() {
      const selectedWithSheetName = _.chain(this.selected)
        .cloneDeep()
        .groupBy('platformId')
        .mapValues(x => _.chain(x).groupBy('adFormatId').value())
        .value()

      const workbookData = []
      for (const sheetName in selectedWithSheetName) {
        const data = selectedWithSheetName[sheetName]
        const textSheetData = []
        for (const adFormatId in data) {
          const column = this.initializeColumn(adFormatId)
          const content = this.buildXlsxdata(
            data[adFormatId].map(x => {
              const platformId = x.platformId
              const adFormatName = x.adFormat.name
              return _.assign({}, x, { platformId: platformId }, { adFormatName: adFormatName })
            }),
            adFormatId
          )
          textSheetData.push(column)
          content.forEach(x => textSheetData.push(x))
          textSheetData.push([])
        }

        const worksheet = XLSX.utils.aoa_to_sheet(textSheetData)
        const sheetData = { sheet: worksheet, platformName: this.getPlatformName(sheetName) }
        workbookData.push(sheetData)
      }
      return workbookData
    },
  },
}
</script>
