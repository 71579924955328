<template>
  <div :id="isOnlyText ? 'only-text-set-area' : 'text-set-area'">
    <template v-if="textSet.labeledTexts.length !== 0">
      <div>
        <div>
          <status-area
            :status-selected="textSet.approvalStatus"
            :disabled="!canChangeStatus || !isTextCanEdit"
            @change-status="status => $emit('change-status-text', textSet.textSetId, status, textSet.version)"
          />
        </div>
        <br />
        <span class="thumb-ver mb-1 mt-1" v-text="'v' + textSet.version"></span>
        <el-row class="mt-1" v-for="(gr, i) in formatedLabeledTexts" :key="i" :gutter="10">
          <el-col v-for="(r, j) in gr" :key="j" :span="8">
            <el-row class="p-1 bg-gray">
              {{ r.labelName }}
            </el-row>
            <el-row class="flex mt-1 mb-1 new-line" v-for="(v, j) in r.textValues" :key="j">
              <el-tag class="float-left" type="info" effect="dark" size="mini">
                {{ j + 1 }}
              </el-tag>
              <div class="flex ml-1">{{ v }}</div>
            </el-row>
          </el-col>
        </el-row>
      </div>
    </template>

    <div v-else class="ms-1">テキストセットなし</div>
  </div>
</template>

<style>
#text-set-area {
  padding: 0 0.5rem;
}

#only-text-set-area {
  padding-right: 0.5rem;
}
</style>

<style scoped>
.tag {
  padding: 0 1.5rem;
}

.new-line {
  word-break: break-word !important;
  white-space: pre-wrap !important;
}

.mt-1:not(:first-of-type) {
  margin-top: 0.5rem;
}

.mb-1:not(:first-of-type) {
  margin-bottom: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.ms-1 {
  margin: 0 0.5rem;
}

.p-1 {
  padding: 0.5rem;
}

.bg-gray {
  background-color: #ebeef5;
}

.flex {
  display: flex;
}
</style>

<script>
import _ from 'lodash'
import StatusArea from './status-area.vue'

export default {
  name: 'text-set-area',
  components: { StatusArea },
  props: {
    textSet: { type: Object, default: () => ({}) },
    isOnlyText: { type: Boolean, default: () => false },
    isTextCanEdit: { type: Boolean, default: () => true },
    canChangeStatus: { type: Boolean, default: () => false },
  },
  created() {
    window.TextSetArea = this
  },
  computed: {
    formatedLabeledTexts() {
      return _.chunk(this.textSet.labeledTexts, 3)
    },
    setColorText() {
      switch (this.textSet.approvalStatus) {
        case '監修中':
          return '#fff'
        case '要修正':
          return '#fff'
        case '承認待ち':
          return '#fff'
        case '入稿依頼済':
          return '#fff'
        case '入稿済':
          return '#fff'

        case '承認':
          return '#fff'
        case '入稿可能':
          return '#fff'

        case '破棄':
          return '#06063096'
        case '入稿不可':
          return '#06063096'

        default:
          return 'rgb(6 6 48 / 45%)'
      }
    },
    setBackgroundColorApprovalStatus() {
      switch (this.textSet.approvalStatus) {
        case '監修中':
          return '#909399'
        case '要修正':
          return '#ef342f'
        case '承認待ち':
          return '#909399'
        case '入稿依頼済':
          return '#ffa500'
        case '入稿済':
          return '#084c6c'

        case '承認':
          return 'rgb(91, 184, 93)'
        case '入稿可能':
          return 'rgb(91, 184, 93)'

        case '破棄':
          return 'rgba(245,245,245,0.48)'
        case '入稿不可':
          return 'rgba(245,245,245,0.48)'

        default:
          return 'white'
      }
    },
  },
}
</script>
