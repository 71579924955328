<template>
  <el-dialog
    id="upload-confirmation-preview-dialog"
    title="画像/動画アップロード"
    lock-scroll
    append-to-body
    fullscreen
    :visible.sync="isShow"
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div v-if="true" class="scroll" :style="{ height: height + 'px' }">
      <div class="warning-text">
        アップロードしようとしているファイルの中に、バージョンアップ・ファイル名変更が発生するものがあります。そのままアップロードするにはチェックを入れてください。
      </div>
      <el-table
        border
        class="confirmation-box table-margin"
        :data="comparisionContent"
        ref="confirmAssetTable"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column label="アップロード予定の画像動画" width="350">
          <template slot-scope="{ row: { currentURL, currentFileName } }">
            <span>{{ currentFileName }}</span>
            <image-movie-item
              :url="currentURL"
              :thumbnail-url="currentURL"
              :type="judgeFileType(currentFileName.substr(currentFileName.lastIndexOf('.') + 1))"
            />
          </template>
        </el-table-column>

        <el-table-column label="1つ前のバージョン" width="350">
          <template slot-scope="{ row: { existedURL, existedFileName } }">
            <div v-if="existedURL !== null">
              <span>{{ existedFileName }}</span>
              <image-movie-item
                :url="existedURL"
                :thumbnail-url="existedURL"
                :type="judgeFileType(existedFileName.substr(existedFileName.lastIndexOf('.') + 1))"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column label="処理結果" prop="reason" />
      </el-table>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleConfirm" type="primary"> アップロード確定 </el-button>
      <el-button @click="handleCancel"> 閉じる </el-button>
    </span>
  </el-dialog>
</template>

<style>
#upload-result-preview-dialog .el-dialog__body {
  padding: 30px 20px !important;
}
</style>

<style scoped>
.mb-1 {
  margin-bottom: 1rem;
}
.font-mini {
  font-size: 10px;
}
.success {
  color: #67c23a;
}
.error {
  color: #f56c6c;
}
.warning-text {
  margin-left: 18px;
  color: red;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
}
.table-margin {
  width: 98%;
  margin-left: 18px;
  margin-right: 18px;
}
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
</style>

<script>
import ImageMovieItem from '@/components/image-movie-item'
import util from '@/mixins/util'

export default {
  name: 'confirmation-asset-preview',
  mixins: [util],
  components: { ImageMovieItem },
  data: () => ({
    isShow: false,
    innerHeight: window.innerHeight,
    comparisionContent: [],
    newAndExisted: [],
    multipleVal: [],
  }),
  created() {
    window.ConfirmationAssetPreview = this
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })
  },
  methods: {
    async show(uploadingAssets, existingAssets) {
      this.newAndExisted = existingAssets.filter(x => ['既存', '新規作成'].includes(x.confirmationType))
      const filteredExistingAsset = existingAssets.filter(x => !['既存', '新規作成'].includes(x.confirmationType))
      const filteredUploadingAsset = uploadingAssets.filter(x => !this.newAndExisted.map(x => x.id).includes(x.id))
      this.comparisionContent = filteredUploadingAsset.map(curAsset => {
        const existingAsset = filteredExistingAsset.find(x => x.id === curAsset.id)
        const existingFileName = existingAsset.content ? existingAsset.content.fileName : null
        const existingURL = existingAsset.content ? existingAsset.content.url : null
        return {
          id: curAsset.id,
          currentFileName: util.methods.lowerCaseFilename(curAsset.currentFileName),
          currentURL: curAsset.currentURL,
          existedFileName: existingFileName,
          existedURL: existingURL,
          reason: existingAsset.confirmationType,
        }
      })
      this.isShow = true
    },
    handleSelectionChange(val) {
      this.multipleVal = val
    },

    async handleConfirm() {
      const newAndExistedUID = this.newAndExisted.map(x => x.id)
      const selectedUID = this.multipleVal.map(x => x.id)
      const totalUID = newAndExistedUID.concat(selectedUID)
      await this.$emit('confirm-uploading-assets', totalUID)
      this.isShow = false
    },

    handleCancel() {
      this.isShow = false
      this.comparisionContent = []
      this.newAndExisted = []
      this.multipleVal = []
    },
  },
  computed: {
    height() {
      return this.innerHeight - 25 - 30 - 50
    },
  },
}
</script>
