<template>
  <div id="menu-area" v-loading="loading" class="text-center font-initial">
    <div class="mb-1">
      <el-checkbox :label="creativeId" />
    </div>

    <div v-if="creativeType !== 'Asset'" class="mb-1" @click="goToCreativeRegist('copy')">
      <i class="fas fa-copy" />
    </div>

    <div
      v-if="submissionStatus !== '入稿済' && creativeType !== 'Asset'"
      @click="goToCreativeRegist('edit')"
      class="mb-1"
    >
      <i class="fas fa-edit" />
    </div>

    <div @click="removeCreative(creativeId)">
      <i class="fas fa-trash-alt" />
    </div>
  </div>
</template>

<style>
#menu-area .el-checkbox__label {
  display: none;
}
</style>

<style scoped>
.text-center {
  text-align: center;
}
.font-initial {
  font-size: initial;
}
.ml-1 {
  margin-left: 1rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mb-1 {
  margin-bottom: 0.5rem;
}
</style>

<script>
import _ from 'lodash'

export default {
  name: 'menu-area',
  props: {
    creativeId: { type: String, default: () => '' },
    creativeIds: { type: Array, default: () => [] },
    submissionStatus: { type: String, default: () => '' },
    creativeType: { type: String, default: () => '' },
  },
  data: () => ({ loading: false }),
  created() {
    window.MenuArea = this
  },
  methods: {
    goToCreativeRegist(type) {
      const newQuery = _.assign({}, _.omit(this.$route.query, ['submissionRequestIds']), {
        type,
        creativeId: this.creativeId,
      })
      this.$router.push({ path: '/creative-regist', query: newQuery })
    },
    async removeCreative(creativeId) {
      const ret = await this.$confirm('クリエイティブを削除しますか?', '確認').catch(e => e)
      if (ret === 'cancel') return

      this.loading = true
      await this.$api.authFetch('/creative', { method: 'DELETE', query: { creativeId: [creativeId] } })
      this.loading = false

      const creativeIds = this.creativeIds.length === 0 ? [] : _.filter(this.creativeIds, v => v !== creativeId)
      if (creativeIds.length !== 0) this.$emit('change-query', { creativeIds })
      this.$emit('get-creatives')
    },
  },
}
</script>
