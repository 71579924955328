<template>
  <div id="only-asset-card">
    <el-card
      :shadow="selectedCreativeId === creative.creativeId ? 'always' : 'never'"
      :class="{ select: selectedCreativeId === creative.creativeId }"
      class="font-mini w-100 mr-1"
      :style="{ backgroundColor: !isCrCanEdit ? 'rgba(229, 229, 229, 0.8745098039)' : '' }"
    >
      <el-row type="flex" class="m-1 mb-0.5">
        <menu-area
          v-if="!isInDialog"
          class="mr-1"
          :creative-id="creative.creativeId"
          :creative-ids="creativeIds"
          :submission-status="creative.submissionStatus"
          :creativeType="creative.creativeType"
          @get-creatives="$emit('get-creatives')"
          @change-query="q => $emit('change-query', q)"
        />
        <el-radio
          v-else-if="isInDialog && $route.path === '/creative-regist'"
          :value="selectedCreativeId"
          :label="creative.creativeId"
        />

        <div
          class="w-100 d-flex"
          style="gap: 5px"
          @click="
            isInDialog && $route.path === '/creative-regist'
              ? $emit('change-selected-creative-id', creative.creativeId)
              : null
          "
        >
          <div>
            <select-card
              :id="creative.creativeId"
              :is-checked="isChecked"
              @toggle-checked="id => $emit('toggle-checked', id)"
            />
            <div style="margin: 3px; font-size: 15px" @click="removeCreative(creative.creativeId)">
              <i class="fas fa-trash-alt" />
            </div>
          </div>
          <main-area
            ref="creativeData"
            class="w-100"
            :platform-id="platformId"
            :ad-format-name="adFormatName"
            :creative="creative"
            :is-in-dialog="isInDialog"
            :is-show="isShow"
            :display-type="displayType"
            :is-update="isUpdate"
            :is-creative-list="isCreativeList"
            :asset-regulate-error="assetRegulateError"
            :adFormats="adFormats"
            :is-cr-can-edit="isCrCanEdit"
            @get-creatives="$emit('get-creatives')"
            @change-note="note => $emit('change-note', note)"
            @change-creator-comment="v => $emit('change-creator-comment', v)"
            @update-creative="(urls, comment) => $emit('update-creative', urls, comment)"
            @change-reference-url="v => $emit('change-reference-url', v)"
            @edit-note-creatives="(note, creatives) => $emit('edit-note-creatives', note, creatives)"
            @open-proposed-dialog="(textSetId, adFormatId) => $emit('open-proposed-dialog', textSetId, adFormatId)"
            @change-status-text="(textSetId, status) => $emit('change-status-text', textSetId, status)"
            @change-status-asset="(assetId, status) => $emit('change-status-asset', assetId, status)"
            @change-status-creative="(creativeId, status) => $emit('change-status-creative', creativeId, status)"
            @update-text="(form, textSet, isEqual) => $emit('update-text', textSet, form, isEqual)"
            @update-ver-asset="
              (file, assetId, skipCheckRule, originId, allOriginIds, labelId) =>
                $emit('update-ver-asset', file, assetId, skipCheckRule, originId, allOriginIds, labelId)
            "
          />
        </div>
      </el-row>
      <div style="margin-right: 0.5rem; margin-bottom: 1.5rem">
        <reference-url-area
          class="reference-url"
          :reference-urls="creative.referenceUrls"
          :is-editable="isCrCanEdit && displayType !== 'submissionRequest'"
          :is-openable="displayType === 'submissionRequest' || !isCrCanEdit"
          @update-reference-urls="urls => $emit('change-reference-url', urls)"
        />
      </div>
    </el-card>
  </div>
</template>

<style>
#only-asset-card .el-radio__label {
  display: none;
}
#only-asset-card .el-radio {
  margin-right: 0.5rem;
}
#only-asset-card .el-card__body {
  padding: 0px;
}
#only-asset-card .el-card.is-always-shadow {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 50%);
}
</style>

<style scoped>
.divider-css {
  height: 1px;
  margin: 0 1rem 1rem 1rem;
}
.float-left {
  float: left;
}
.font-mini {
  font-size: 12px;
}
.w-5 {
  width: 5%;
}
.w-95 {
  width: 95%;
}
.w-100 {
  width: 100%;
}
.m-1 {
  margin: 1rem;
}
.mr-1 {
  margin-right: 0.5rem;
}
</style>

<script>
import MenuArea from '@/views/approval-detail/components/ad-format-tab/creative-card/components/menu-area'
import MainArea from '@/views/approval-detail/components/ad-format-tab/only-asset-card/components/main-area'
import SelectCard from '@/views/approval-detail/components/ad-format-tab/select-card'
import ReferenceUrlArea from '@/components/reference-url-area.vue'
import { CreativeStatus } from '@/mixins/creativeStatus'

export default {
  name: 'only-asset-card',
  computed: {
    isCrCanEdit() {
      return !CreativeStatus.NO_EDIT_CR.includes(this.creative.approvalStatus)
    },
  },
  components: { ReferenceUrlArea, MenuArea, SelectCard, MainArea },
  props: {
    isChecked: { type: Boolean, default: () => false },
    platformId: { type: String, default: () => '' },
    adFormatName: { type: String, default: () => '' },
    creative: { type: Object, default: () => ({}) },
    isInDialog: { type: Boolean, default: () => false },
    isCreativeList: { type: Boolean, default: () => false },
    isUpdate: { type: Boolean, default: () => false },
    selectedCreativeId: { type: String, default: () => '' },
    isShow: { type: Boolean, default: () => false },
    creativeIds: { type: Array, default: () => [] },
    displayType: { type: String, default: () => '' },
    assetRegulateError: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
  },
  created() {
    window.CreativeCard = this
  },
  methods: {
    async removeCreative(id) {
      const ret = await this.$confirm('クリエイティブを承認依頼から削除しますか？', '確認', {
        confirmButtonText: 'OK',
        cancelButtonText: 'キャンセル',
        type: 'warning',
      }).catch(e => e)
      if (ret === 'confirm') {
        this.$emit('remove-creative', id)
      } else {
        return
      }
    },
  },
}
</script>
