import { render, staticRenderFns } from "./submission-request-dialog.vue?vue&type=template&id=03b56988&scoped=true&"
import script from "./submission-request-dialog.vue?vue&type=script&lang=js&"
export * from "./submission-request-dialog.vue?vue&type=script&lang=js&"
import style0 from "./submission-request-dialog.vue?vue&type=style&index=0&lang=css&"
import style1 from "./submission-request-dialog.vue?vue&type=style&index=1&id=03b56988&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03b56988",
  null
  
)

export default component.exports