<template>
  <div>
    <el-row type="flex" class="wrap">
      <div
        :body-style="{ padding: '8px', 'word-break': 'break-all' }"
        class="m-3"
        v-for="(r, i) in distributedSelected"
        :key="i"
      >
        <el-button
          type="primary"
          size="mini"
          class="b-5"
          @click="$refs.assetDialog.show(i, r.selected, r.extensions, r.id)"
        >
          {{ r.name }}追加
        </el-button>
      </div>
    </el-row>

    <el-row :gutter="10">
      <div v-for="asset in chunkedAsset" :key="asset.assetId + asset.name">
        <el-col :style="{ width: '20%', paddingRight: '0!important' }" class="mt-1">
          <asset-item
            :style="{ width: '105%' }"
            :asset="asset"
            @remove-asset="removeAsset"
            :asset-errors="errors(asset.name)"
            :max-label="maxLabel"
          />
          <span class="flex"></span>
        </el-col>
      </div>
    </el-row>

    <asset-dialog
      ref="assetDialog"
      :selected-promotion-ids="selectedPromotionIds"
      :tags="tags"
      :ad-format-id="adFormatId"
      :height="height"
      @change-selected-assets="changeSelectedAssets"
    />
    <span>&nbsp;</span>
  </div>
</template>

<style>
#asset-dialog .el-tabs__content {
  padding: 0;
}
</style>

<style scoped>
.m-3 {
  margin: 0.2rem;
}
.mt-1 {
  margin-top: 20px;
}
.b-5 {
  border-radius: 5px;
}
.wrap {
  flex-wrap: wrap;
}
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
.detail {
  font-size: 10px;
  color: #f56c6c;
}

legend {
  color: #f56c6c;
  font-size: 14px;
  font-weight: bold;
}
.badge {
  position: relative;
  top: -5px;
  left: 10px;
  /* left: 10.2rem;  */
  z-index: 2;
}
.flex {
  display: flex;
}
.icon-red {
  color: #f56c6c;
}
</style>

<script>
import _ from 'lodash'
import AssetDialog from '@/views/creative-regist-only/creative-item/asset-area/asset-select-dialog/components/asset-dialog'
import AssetItem from '@/views/creative-regist-only/components/asset-display'

export default {
  name: 'asset-select-dialog',
  components: { AssetDialog, AssetItem },
  data: () => ({
    isShow: false,
    index: null,
    selected: [],
    assetErrors: [],
    distributedSelected: [],
    innerHeight: window.innerHeight,
    tags: [],
    adFormatId: '',
    assetLabels: [],
    selectedPromotionIds: [],
    maxLabel: { type: String, default: () => null },
  }),
  created() {
    window.AssetSelectDialog = this
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })
  },
  methods: {
    log(mess, data) {
      console.log(mess, data)
    },
    show(index, selectedPromotionIds, tags, adFormatId, assetLabels, labeledAssets, assetErrors) {
      this.selectedPromotionIds = selectedPromotionIds
      this.tags = tags
      this.adFormatId = adFormatId
      this.assetLabels = assetLabels

      this.distributedSelected = _.chain(this.assetLabels)
        .map(
          r =>
            _.find(labeledAssets ? labeledAssets[0].labeledAssets : labeledAssets, r2 => r2.id === r.id) ||
            _.assign({}, r, { selected: [] })
        )
        .compact()
        .cloneDeep()
        .value()

      this.selected = []
      this.index = index
      this.assetErrors = assetErrors || []
    },
    changeSelectedAssets({ index, selected }) {
      this.distributedSelected = _.map(this.distributedSelected, (r, i) =>
        _.assign({}, r, i == index ? { selected: _.uniqBy(_.concat(selected, r.selected), 'assetId') } : {})
      )
    },
    changeGroupSelected(selectedIds, index) {
      this.distributedSelected = _.map(this.distributedSelected, (r, i) =>
        _.assign(
          {},
          r,
          i === index ? { selected: _.filter(r.selected, r2 => _.includes(selectedIds, r2.originId)) } : {}
        )
      )
    },
    removeAsset(labelId, assetId) {
      this.distributedSelected = _.chain(this.distributedSelected)
        .map(r => {
          if (r.id === labelId) {
            return _.assign({}, r, { selected: r.selected.filter(x => x.assetId !== assetId) })
          } else {
            return r
          }
        })
        .value()
    },
    close() {
      const labeledAssets = _.cloneDeep(this.distributedSelected)
      this.$emit('change-selected-assets', {
        labeledAssets,
        index: this.index,
        isSplitAsset: false,
      })
      this.isShow = false
    },
    clipboardErrors() {
      const errors = _.chain(this.distributedSelected)
        .map(r => {
          const assetError = _.find(this.assetErrors, r2 => r2.labelId === r.id)
          if (!assetError) return

          const details = _.chain(r.selected)
            .map(r2 => {
              const { errors = [] } = _.find(assetError.targets, r3 => r3.key === r2.originId) || {}
              if (errors.length === 0) return

              return `${r2.metadata.fileName},${r2.metadata.rawFileName}:${_.join(errors, ',')}`
            })
            .filter()
            .join('\n')
            .value()

          const creativeErrors = assetError.errors.length === 0 ? '' : `${r.name},${assetError.errors.join(',')}\n`

          return creativeErrors + details
        })
        .filter()
        .join('\n')
        .value()

      this.$copyText(errors).then(
        () => this.$message.success('コピーしました'),
        () => this.$message.error('コピーに失敗しました')
      )
    },

    errors(name) {
      const labelId = this.distributedSelected.find(x => x.name === name).id
      const labeledErrors = labelId ? this.assetErrors.find(x => x.labelId === labelId) : ''
      return this.assetErrors.length > 0 && labeledErrors !== undefined && labeledErrors !== ''
        ? labeledErrors.targets
        : []
    },
  },
  watch: {
    assetLabels() {
      const data = _.maxBy(_.flatten(this.setmaxLabel), function (o) {
        return o.length
      })
      this.maxLabel = data
    },
    distributedSelected() {
      this.close()
    },
  },
  computed: {
    setmaxLabel() {
      return _.map(this.assetLabels, gr => {
        return gr.name
      })
    },
    height() {
      return this.innerHeight - 35 - 70
    },
    adjustmentAssetErrors() {
      if (this.distributedSelected.length === 0 || this.assetErrors.length === 0) return []

      return _.map(this.distributedSelected, r => {
        const assetError = _.find(this.assetErrors, r2 => r2.labelId === r.id)
        if (!assetError) return { errors: [], details: [] }

        const details = _.map(r.selected, r2 =>
          _.chain(assetError.targets)
            .find(r3 => r3.key === r2.originId)
            .result('errors')
            .value()
        )
        return { errors: assetError.errors, details }
      })
    },
    chunkedAsset() {
      return _.chain(this.distributedSelected)
        .map(r =>
          _.map(r.selected, x => {
            return _.assign({}, r, x)
          })
        )
        .flatten()
        .value()
    },
  },
}
</script>
