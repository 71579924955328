<template>
  <div class="w-100">
    <el-select
      :value="tagTypeId"
      class="w-49 font-13"
      clearable
      filterable
      size="mini"
      placeholder="カテゴリ"
      @input="id => $emit('change-tag-type', id)"
    >
      <el-option
        class="font-13-option"
        v-for="item in tagTypeIdMapping"
        :key="item.tagTypeId"
        :label="item.tagTypeName"
        :value="item.tagTypeId"
      >
      </el-option>
    </el-select>
    <el-select
      :value="tagValue"
      class="w-44 font-13"
      clearable
      filterable
      size="mini"
      placeholder="内容"
      @input="value => $emit('change-tag-value', value)"
    >
      <el-option
        class="font-13-option"
        v-for="item in tagGroup[tagTypeId]"
        :key="item.tagValue"
        :label="item.tagValue"
        :value="item.tagValue"
      >
      </el-option>
    </el-select>
    <i class="el-icon-delete-solid" @click="$emit('remove-tag')"></i>
  </div>
</template>

<style scoped>
.mt-1 {
  margin-top: 15px;
}

.w-100 {
  width: 100%;
}

.p-1 {
  padding: 1rem;
}

.ptb-1 {
  padding: 0.3rem 0;
}

.pl-2 {
  padding-left: 14px;
}

.w-100 {
  width: 100%;
}

.w-24 {
  width: 33.33%;
  margin-right: 0px;
}

.font-13,
.font-13-option {
  font-size: 13px;
}

.w-44 {
  width: 44%;
}

.w-50 {
  width: 50%;
}

.w-49 {
  width: 49%;
  margin-right: 1%;
}

.w-32 {
  width: 32%;
}

.w-34 {
  width: 34%;
}

.el-input {
  width: 98%;
}
</style>

<script>
import _ from 'lodash'

export default {
  name: 'tag-selection',
  props: {
    tags: { type: Array, default: () => [] },
    selectedTags: { type: Array, default: () => [] },
    tagTypeId: { type: String, default: () => '' },
    tagValue: { type: String, default: () => '' },
  },
  computed: {
    tagGroup() {
      return _.groupBy(this.tags, 'tagTypeId')
    },
    tagTypeIdMapping() {
      const mapping = _.map(this.tags, tag => _.pick(tag, ['tagTypeId', 'tagTypeName']))
      return _.uniqBy(mapping, 'tagTypeId')
    },
  },
}
</script>
