<template>
  <div id="creative-item" class="p-1">
    <el-row class="ps-1 mb-1 vertical-center">
      <el-select
        :value="platformId"
        placeholder="媒体を選択してください"
        clearable
        filterable
        size="small"
        class="mr-1"
        :disabled="!!(platformId && adFormatId)"
        @input="id => $emit('change-platform-id', id)"
      >
        <el-option v-for="(r, i) in platforms" :key="i" :label="r.platformName" :value="r.platformId" />
      </el-select>

      <el-tooltip
        v-if="adFormatName && adFormatId && countAdFormatName(adFormatSelectValue + adFormatName)"
        class="item"
        effect="dark"
        :content="adFormatSelectValue + adFormatName"
        placement="top-start"
      >
        <el-select
          :value="adFormatId"
          placeholder="広告フォーマットを選択してください"
          clearable
          filterable
          size="small"
          class="mr-1"
          id="el-select-ad-format-id"
          :disabled="platformId && adFormatId ? true : !platformId"
          @input="changeAdFormatId"
        >
          <el-option
            v-for="(r, i) in filteredAdFormats"
            :key="i"
            :label="(r.oddAiFlag ? '【Odd-AI対応】' : '') + r.name"
            :value="r.id"
          />
        </el-select>
      </el-tooltip>
      <el-select
        v-else
        :value="adFormatId"
        placeholder="広告フォーマットを選択してください"
        clearable
        filterable
        size="small"
        class="mr-1"
        id="el-select-ad-format-id"
        :disabled="platformId && adFormatId ? true : !platformId"
        @input="changeAdFormatId"
      >
        <el-option
          v-for="(r, i) in filteredAdFormats"
          :key="i"
          :label="(r.oddAiFlag ? '【Odd-AI対応】' : '') + r.name"
          :value="r.id"
        />
      </el-select>
      <el-button class="float-left ml-1" size="small" type="info" @click="$emit('copy-creative')"> コピー </el-button>
      <el-button v-if="!!adFormat.id" class="float-left ml-1" size="small" type="info" @click="openTagDialog">
        <i class="fas fa-tag" /> タグ
      </el-button>
      <tag-area
        v-if="!!adFormat.id"
        :tags="selectedTags"
        createdAt="createdAt"
        updatedAt="updateAt"
        creativeType="creative"
        :is-tag-creative-create="true"
      />
    </el-row>

    <el-row>
      <el-col class="pr-1" :span="12">
        <asset-area
          :selected-promotion-ids="selectedPromotionIds"
          :tags="tags"
          :ad-format-id="adFormatId"
          :ad-format="adFormat"
          :selected-assets="selectedAssets"
          :asset-errors="assetErrors"
          @change-selected-assets="gr => $emit('change-selected-assets', gr)"
          @change-selected-assets-error="gr => $emit('change-selected-assets-error', gr)"
          @is-contain-zip="val => $emit('is-contain-zip', val)"
        />
      </el-col>

      <el-divider class="h-100 center" direction="vertical" />

      <el-col class="pl-1" :span="12">
        <text-set-area
          :textErrors="textErrors"
          :selected-promotion-ids="selectedPromotionIds"
          :tags="tags"
          :ad-format-id="adFormatId"
          :platform-id="platformId"
          :ad-formats="adFormats"
          :ad-format="adFormat"
          :selected-text-sets="selectedTextSets"
          @change-selected-text-sets="gr => $emit('change-selected-text-sets', gr)"
        />
      </el-col>
    </el-row>

    <el-row class="mt-1">
      <reference-url-area
        :referenceUrls="referenceUrls"
        @update-reference-urls="urls => this.$emit('update-reference-urls', urls)"
      />
      <div>
        <h4 style="font-size: 14px; margin-bottom: 8px">
          <i class="fa-regular fa-note-sticky"></i>
          備考
        </h4>
        <el-input type="textarea" :rows="2" placeholder="備考" :value="note" @input="v => $emit('change-note', v)" />
      </div>
    </el-row>
    <tag-dialog
      ref="tagDialog"
      :selected-promotion-ids="selectedPromotionIds"
      :tags="tags"
      :tagTypes="tagTypes"
      @reload="$emit('reload')"
      @add-tag-creative="t => addTagCreative(t)"
    />
  </div>
</template>

<style>
#creative-item .el-divider--vertical {
  margin: 0;
}
</style>

<style scoped>
.vertical-center {
  display: flex;
  align-items: flex-start;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.mt-1 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.ml-1 {
  margin-left: 10px;
}
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 2rem;
}
.p-1 {
  padding: 1rem;
}
.pl-1 {
  padding-left: 1rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 5px;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.badge-default {
  width: 0;
  position: relative;
  bottom: 10px;
  right: 40px;
  font-weight: bold;
}
.badge {
  width: 0;
  position: relative;
  bottom: 10px;
  right: 5px;
}
</style>

<script>
import _ from 'lodash'
import util from '@/mixins/util'
import AssetArea from '@/views/creative-regist/creative-item/asset-area'
import TextSetArea from '@/views/creative-regist/creative-item/text-set-area'
import TagDialog from '@/components/tag-dialog'
import TagArea from '@/views/creative-list/ad-format-tab-list/creative-card/main-area/components/tag-area'
import ReferenceUrlArea from '@/components/reference-url-area'

export default {
  name: 'creative-item',
  mixins: [util],
  components: { AssetArea, TextSetArea, TagDialog, TagArea, ReferenceUrlArea },
  data: function () {
    return {
      adFormatSelectValue: { type: String, default: '' },
      selectedTags: [],
    }
  },
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    tags: { type: Array, default: () => [] },
    tagTypes: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
    platformId: { type: String, default: () => '' },
    adFormatId: { type: String, default: () => '' },
    type: { type: String, default: () => '' },
    selectedAssets: { type: Array, default: () => [] },
    selectedTextSets: { type: Array, default: () => [] },
    assetErrors: { type: Array, default: () => [] },
    note: { type: String, default: () => '' },
    referenceUrls: { type: Array, default: () => [''] },
    textErrors: { type: Array, default: () => [] },
    tagsSelected: { type: Array, default: () => [] },
  },
  created() {
    window.CreativeItem = this
    this.selectedTags = this.tagsSelected
  },
  methods: {
    changeAdFormatId(id) {
      const selectAdformat = this.filteredAdFormats.find(r => r.id === id)
      this.adFormatSelectValue = selectAdformat.oddAiFlag ? '【Odd-AI対応】' : ''
      this.$emit('change-ad-format-id', id)
    },
    openTagDialog() {
      const tags = {}
      this.selectedTags
        .map(x => x.tagId)
        .map(tag => {
          tags[tag] = {
            isAllSelected: true,
            selectedTargetIds: [],
          }
        })
      const params = { targetType: 'createCreative', selectedTags: tags, targetIds: [] }
      this.$refs.tagDialog.show(params)
    },
    addTagCreative(attachTag) {
      this.selectedTags = this.tags.filter(x => _.includes(attachTag, x.tagId))
      this.$emit('change-selected-tag', this.selectedTags)
    },
  },
  watch: {
    tagsSelected() {
      this.selectedTags = this.tagsSelected
    },
    platformId() {
      if (this.keepNote) this.$emit('change-note', this.note)
      else if (this.platformId === 'FBA') this.$emit('change-note', 'マスク対応: カルーセル最適化:')
      else if (this.platformId === 'GAW' || this.platformId === 'YSS')
        this.$emit('change-note', '※リンク先URLを入力してください※')
      else this.$emit('change-note', '')
    },
    platformName() {
      this.$emit('change-platform-name', this.platformName)
    },
    adFormatName() {
      this.$emit('change-ad-format-name', this.adFormatName)
    },
    adFormatId() {
      const selectAdformat = this.filteredAdFormats.find(r => r.id === this.adFormatId)
      this.adFormatSelectValue = selectAdformat && selectAdformat.oddAiFlag ? '【Odd-AI対応】' : ''
    },
  },
  computed: {
    adFormat() {
      return _.find(this.adFormats, r => r.id === this.adFormatId) || { assetLabels: [], textLabels: [] }
    },
    platforms() {
      return _.chain(this.adFormats)
        .uniqBy('platformId')
        .orderBy(r => _.toLower(r.platformName))
        .value()
    },
    filteredAdFormats() {
      return _.chain(this.adFormats)
        .filter(r => (!this.platformId ? true : r.platformId === this.platformId))
        .uniqBy('id')
        .orderBy()
        .value()
    },
    platformName() {
      return _.chain(this.adFormats)
        .find(r => this.platformId === r.platformId)
        .result('platformName')
        .value()
    },
    adFormatName() {
      return _.chain(this.adFormats)
        .find(r => this.adFormatId === r.id)
        .result('name')
        .value()
    },
    keepNote() {
      const platformList = ['FBA', 'YSS', 'GAW']
      const defaultNote = ['', 'マスク対応: カルーセル最適化:', '※リンク先URLを入力してください※']
      return !platformList.includes(this.platformId) || !defaultNote.includes(this.note)
    },
  },
}
</script>
