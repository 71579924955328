export default {
  async install(Vue, { AWS }) {
    Vue.$cognito = {
      clearCachedId: () => AWS.config.credentials.clearCachedId(),
      setCredentials: async () => {
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: 'ap-northeast-1:5b05d768-65d7-4ab9-bfb8-960e05d64605',
          LoginId: await Vue.$gAuth.getEmail(),
          Logins: { 'accounts.google.com': await Vue.$gAuth.getIdToken() },
        })

        return AWS.config.credentials.getPromise()
      },
    }
  },
}
