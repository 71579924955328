<template>
  <el-dialog
    :element-loading-spinner="0"
    id="upload-assets-dialog"
    title="画像/動画アップロード"
    lock-scroll
    append-to-body
    fullscreen
    :visible.sync="isShow"
    :before-close="cancel"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div v-loading="loading" class="p-1 scroll" :style="{ height: height + 'px' }">
      <el-row class="mb-1" v-for="(uploadAsset, i) in uploadAssets" :key="i">
        <el-card shadow="never">
          <el-form>
            <el-form-item class="mb-1">
              <el-input
                v-model="uploadAsset.projectName"
                placeholder="後で検索しやすいような画像/動画をまとめるキーを入力してください。BOXのフォルダ名のようなイメージです"
              />
            </el-form-item>

            <el-form-item class="mb-1">
              <span>タグ一括設定</span>

              <span class="ml-1">
                <el-button type="text" @click="setTag('odd-AI')"> odd-AI </el-button>
                <el-button type="text" @click="setNoTag(null)"> 該当なし </el-button>
                <el-button
                  class="youtube-upload-btn"
                  :key="i"
                  type="primary"
                  size="small"
                  plain
                  @click="openYoutubeDialog(i)"
                >
                  <i class="fas fa-link"></i>YouTubeURLはこちら
                </el-button>
              </span>
            </el-form-item>

            <el-form-item>
              <el-upload
                drag
                multiple
                ref="upload"
                action="#"
                list-type="picture-card"
                :file-list="uploadAsset.assets"
                :auto-upload="false"
                :on-change="(file, fileList) => addFile(i, fileList)"
              >
                <i class="el-icon-upload" />

                <div slot="file" slot-scope="{ file }">
                  <div class="title mb-2">
                    {{ lowerCaseFilename(file.name) }}
                  </div>
                  <image-movie-item
                    class="el-upload-list__item-thumbnail"
                    :url="file.url"
                    :thumbnail-url="file.url"
                    :file-name="lowerCaseFilename(file.name)"
                    :type="judgeFileType(file.name.substr(file.name.lastIndexOf('.') + 1).toLowerCase())"
                  />

                  <el-row class="p-1 text-center">
                    <el-checkbox-group v-model="file.oddAiTag" @change="cancelNoTag(i, file)">
                      <el-checkbox-button label="odd-AI" class="w-100"> odd-AI </el-checkbox-button>
                    </el-checkbox-group>

                    <el-checkbox-group class="mt-1" v-model="file.soundTag" @change="cancelNoTag(i, file)">
                    </el-checkbox-group>

                    <el-checkbox class="mt-1" v-model="file.noTags" @change="cancelTag(i, file)">
                      該当なし
                    </el-checkbox>

                    <el-row
                      v-if="file.oddAiTag.length === 0 && file.soundTag.length === 0 && !file.noTags"
                      class="mt-1 icon-red"
                    >
                      タグを選択してください。
                    </el-row>
                    <el-row v-if="file.duplicate" class="mt-1 icon-red"> 重複しているものが存在しています。 </el-row>
                  </el-row>

                  <span class="flex">
                    <span @click="removeFile(i, file)">
                      <i class="badge icon-red font-middle fas fa-times-circle" />
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
          </el-form>
        </el-card>

        <div class="flex">
          <span @click="removeUploadAssets(i)">
            <i class="badge icon-red font-middle fas fa-times-circle" />
          </span>
        </div>
      </el-row>
      <el-row class="text-center font-big">
        <span @click="addUploadAssets">
          <i class="fas fa-plus-circle" />
        </span>
      </el-row>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button
        type="info"
        :disabled="Object.keys(groupByResultAssets).length === 0"
        @click="$refs.uploadResultPreviewDialog.show(groupByResultAssets)"
      >
        アップロード結果
      </el-button>

      <el-button type="primary" :disabled="!isAssets && !isUrls" @click="askForConfirmation"> アップロード </el-button>
    </span>

    <upload-result-preview-dialog ref="uploadResultPreviewDialog" :adFormats="adFormats" />
    <confirmation-asset-preview
      ref="confirmationAssetPreviewDialog"
      @confirm-uploading-assets="v => confirmUploadingAsset(v)"
    />

    <error-dialog ref="errorDialog" @show-preview="result => $refs.uploadResultPreviewDialog.show(result)" />

    <youtube-upload-dialog ref="youtubeUploadDialog" :data="youtubeDialogData" @updateYoutubeData="buildBodyUrls" />

    <el-dialog
      custom-class="progress-modal"
      :visible.sync="dialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :modal="false"
      width="50%"
      top="30vh"
    >
      <span slot="title">
        <el-row>
          <el-col class="progress-title"> 処理中 </el-col>
          <el-col :span="3" style="margin-top: 15px">
            <div class="col-3">
              <div class="snippet" data-title=".dot-flashing">
                <div class="stage">
                  <div class="dot-flashing"></div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </span>
      <span>
        <div id="estimate-time">{{ estimateTime }}</div>
        <el-progress id="progress-bar" :percentage="percentage" :stroke-width="20" :text-inside="true" />
      </span>
    </el-dialog>
  </el-dialog>
</template>

<style>
.progress-modal {
  border-radius: 8px !important;
}

.progress-modal .el-dialog__header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.progress-title {
  color: white;
  width: fit-content !important;
  margin-left: 10px;
  font-size: 18px;
  margin-right: 15px;
}

.dot-flashing {
  position: relative;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: black;
  color: black;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -10px;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: black;
  color: black;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 10px;
  width: 3px;
  height: 3px;
  border-radius: 5px;
  background-color: black;
  color: black;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: black;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

#progress-bar {
  padding: 10px;
}

#estimate-time {
  text-align: right;
  margin-right: 10px;
  margin-top: 5px;
}

#upload-assets-dialog .el-form-item {
  margin-bottom: 0 !important;
}

#upload-assets-dialog .el-form-item__content {
  line-height: 0 !important;
}

#upload-assets-dialog .el-upload-list--picture-card .el-upload-list__item {
  overflow: visible !important;
  display: inline-table !important;
}

#upload-assets-dialog .el-upload-dragger {
  height: 420px !important;
  width: 148px !important;
}

#upload-assets-dialog .el-upload-dragger .el-icon-upload {
  margin: 185px 0;
}

#upload-assets-dialog .el-upload--picture-card {
  height: 420px !important;
}

#upload-assets-dialog .el-upload-list--picture-card .el-upload-list__item {
  height: 420px !important;
  margin-bottom: 0 !important;
  border: 0 !important;
}

#upload-assets-dialog div[tabindex] {
  border: 0 !important;
}

#upload-assets-dialog .el-message-box__header {
  background: #004b6a !important;
}

#upload-assets-dialog .el-message-box__title {
  color: #ffffff !important;
}

#upload-assets-dialog .el-message-box__footer {
  background: #004b6a !important;
}

#upload-assets-dialog .el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 4px !important;
}
</style>

<style scoped>
.flex {
  display: flex;
}

.badge {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 2;
}

.icon-red {
  color: #f56c6c;
}

.font-middle {
  font-size: 18px;
}

.font-big {
  font-size: 22px;
}

.text-center {
  text-align: center;
}

.p-1 {
  padding: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.mb-1 {
  margin-bottom: 20px !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.title {
  font-size: 10px;
  line-height: 18px !important;
}

.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}

.scroll::-webkit-scrollbar {
  display: none;
}

.youtube-upload-btn {
  margin-top: 10px;
  float: right;
}

/* Chrome, Safari 対応 */
</style>

<script>
import _ from 'lodash'
import UploadResultPreviewDialog from '@/components/upload-assets-dialog/components/upload-result-preview-dialog'
import ErrorDialog from '@/components/upload-assets-dialog/components/error-dialog'
import YoutubeUploadDialog from '@/components/upload-assets-dialog/components/youtube-upload-dialog'
import ImageMovieItem from '@/components/image-movie-item'
import util from '@/mixins/util'
import axios from 'axios'
import ConfirmationAssetPreview from './components/confirmation-asset-preview.vue'

export default {
  name: 'upload-assets-dialog',
  mixins: [util],
  components: {
    ImageMovieItem,
    UploadResultPreviewDialog,
    ErrorDialog,
    ConfirmationAssetPreview,
    YoutubeUploadDialog,
  },
  props: {
    defaultPromotionId: { type: String, default: () => '' },
    tags: { type: Array, default: () => [] },
    adFormats: { type: Array, default: () => [] },
  },
  data() {
    const url = `${this.$api.ctxDomain}/asset/upload`
    return {
      isShow: false,
      loading: false,
      innerHeight: window.innerHeight,
      url,
      uploadAssets: [],
      uploadYoutubeUrls: [],
      groupByResultAssets: {},
      percentage: 0,
      estimateTime: '',
      dialogVisible: false,
      confirmationElements: [],
      uploadingInfo: [],
      confirmedUploads: [],
      youtubeDialogData: [],
      assetResult: [],
      urlResult: [],
    }
  },
  created() {
    window.UploadAssetsDialog = this
    window.addEventListener('resize', () => {
      this.innerHeight = window.innerHeight
    })
  },
  methods: {
    show() {
      this.isShow = true
      this.uploadAssets = [{ projectName: '', assets: [] }]
      this.uploadYoutubeUrls = [{ projectName: '', urls: [], tags: null }]
      this.groupByResultAssets = {}
    },
    cancel() {
      this.isShow = false
      this.$refs.upload.map(x => x.clearFiles())
      this.uploadYoutubeUrls = [{ projectName: '', urls: [] }]
      this.groupByResultAssets = {}
      this.confirmationElements = []
      this.uploadingInfo = []
      this.confirmedUploads = []
      this.youtubeDialogData = []
      this.assetResult = []
      this.urlResult = []
    },
    cancelTag(index, file) {
      this.uploadAssets = _.map(this.uploadAssets, (r, i) => {
        if (i !== index) return r

        const assets = _.map(r.assets, r2 =>
          r2.uid !== file.uid
            ? r2
            : _.assign({}, r2, {
                oddAiTag: [],
                soundTag: [],
                noTags: true,
              })
        )
        return _.assign({}, r, { assets })
      })
    },
    cancelNoTag(index, file) {
      this.uploadAssets = _.map(this.uploadAssets, (r, i) => {
        if (i !== index) return r

        const assets = _.map(r.assets, r2 => (r2.uid !== file.uid ? r2 : _.assign({}, r2, { noTags: false })))
        return _.assign({}, r, { assets })
      })
    },
    setTag(tag) {
      this.uploadAssets = _.map(this.uploadAssets, r => {
        const assets = _.map(r.assets, r2 =>
          _.assign({}, r2, { noTags: false }, tag === 'odd-AI' ? { oddAiTag: ['odd-AI'] } : { soundTag: ['sound'] })
        )
        return _.assign({}, r, { assets })
      })
    },
    setNoTag() {
      this.uploadAssets = _.map(this.uploadAssets, r => {
        const assets = _.map(r.assets, r2 => _.assign({}, r2, { oddAiTag: [], soundTag: [], noTags: true }))
        return _.assign({}, r, { assets })
      })
    },
    addUploadAssets() {
      this.uploadAssets.push({ projectName: '', assets: [] })
      this.uploadYoutubeUrls.push({ projectName: '', urls: [] })
    },
    format(percentage) {
      return percentage === 100 ? 'Completed' : `Remaining:` + `${this.estimateTime}`
    },
    removeUploadAssets(index) {
      this.$refs.upload.map(x => x.clearFiles())
      this.uploadAssets = _.filter(this.uploadAssets, (r, i) => i !== index)
      this.uploadYoutubeUrls = _.filter(this.uploadYoutubeUrls, (r, i) => i !== index)
      this.youtubeDialogData = _.filter(this.youtubeDialogData, (r, i) => i !== index)
      if (this.uploadAssets.length === 0) this.addUploadAssets()
    },
    async extractXmpMetadataTitle(file) {
      const musicMetadata = require('music-metadata-browser')
      const array = await file.raw.arrayBuffer().then(buff => {
        return new Uint8Array(buff)
      })

      try {
        const metadata = await musicMetadata.parseBuffer(array)
        return _.assign({}, file, { xmpMetadataTitle: metadata.common.title })
      } catch {
        this.$refs.errorDialog.show(this.generateErrorResult('【sound】xmp_metadata_titleを抽出できません。'))
        this.cancel()
      }
    },
    extractExtension(name) {
      return name.substring(name.lastIndexOf('.') + 1)
    },
    addFile(index, fileList) {
      this.uploadAssets[index].assets = _.map(fileList, file => {
        const emptyTag = { oddAiTag: [], soundTag: [], noTags: false }
        return _.assign({}, file, file.oddAiTag ? {} : emptyTag)
      })
    },

    async buildBodyConfirm() {
      const confirmationElements = Promise.all(
        this.onlyAssets.flatMap(uploadAssets => {
          const assets = uploadAssets.assets
          return assets.map(async asset => {
            const hash = await this.getHash('SHA-512', asset.raw)
            return {
              id: String(asset.uid),
              fileName: asset.name,
              hash: hash,
            }
          })
        })
      )

      this.uploadingInfo = this.onlyAssets.flatMap(uploadAssets => {
        return uploadAssets.assets.map(asset => {
          return {
            id: String(asset.uid),
            currentFileName: asset.name,
            currentURL: asset.url,
          }
        })
      })

      return {
        promotionId: Number(this.defaultPromotionId),
        confirmationElements: await confirmationElements,
      }
    },
    removeFile(index, file) {
      this.uploadAssets[index].assets = _.filter(this.uploadAssets[index].assets, r => r !== file)
      if (this.youtubeDialogData[index])
        this.youtubeDialogData[index] = this.youtubeDialogData[index]
          .replace(file.rawUrl, '')
          .split('\n')
          .filter(e => e.trim().length)
          .join('\n')
    },
    isIncludesEmptyTags(uploadAssets) {
      const isEmptyTagAsset =
        _.filter(uploadAssets, r =>
          _.find(r.assets, r2 => r2.oddAiTag.length === 0 && r2.soundTag.length === 0 && !r2.noTags)
        ).length !== 0
      const isEmptyTagUrl =
        _.filter(uploadAssets, r =>
          _.find(r.urls, r2 => r2.oddAiTag.length === 0 && r2.soundTag.length === 0 && !r2.noTags)
        ).length !== 0
      return isEmptyTagAsset || isEmptyTagUrl
    },

    overSizeLimit() {
      return this.allUploadingAssets.map(x => x.size).reduce((prev, next) => prev + next) > 3221225472 //3GB
    },

    overFileNumberLimit() {
      return this.allUploadingAssets.length > 60 //60 files
    },

    checkDuplication(arr) {
      return new Set(arr).size !== arr.length
    },

    getDuplicationAsset(confirm_el) {
      const duplicateHash = util.methods.getDuplicationItem(confirm_el.map(x => x.hash))
      const duplicateFileName = util.methods.getDuplicationItem(confirm_el.map(x => x.fileName))
      return confirm_el
        .filter(asset => duplicateHash.includes(asset.hash) || duplicateFileName.includes(asset.fileName))
        .map(x => x.id)
    },

    async askForConfirmation() {
      if (this.isIncludesEmptyTags(this.uploadAssets)) {
        this.$alert('タグが正しく入力されていません', '必須チェック')
        this.checked = true
        return
      }

      if (this.overSizeLimit()) {
        this.$refs.errorDialog.show(this.generateErrorResult('リクエストの上限（3GB）を超えました。'))
        this.cancel()
        return
      }

      if (this.overFileNumberLimit()) {
        this.$refs.errorDialog.show(this.generateErrorResult('リクエストの枚数を60枚以下してください。'))
        this.cancel()
        return
      }

      const body = await this.buildBodyConfirm()
      const containDuplicateFileName = this.checkDuplication(body.confirmationElements.map(x => x.fileName))
      const containDuplicateHash = this.checkDuplication(body.confirmationElements.map(x => x.hash))

      if (containDuplicateFileName || containDuplicateHash) {
        this.$refs.errorDialog.show(
          this.generateDuplicationErrorResult(this.getDuplicationAsset(body.confirmationElements))
        )
        this.cancel()
        return
      }
      this.loading = true
      const res = _.result(
        await this.$api
          .authFetch('/asset/confirmation', {
            method: 'POST',
            body: body,
          })
          .finally(() => (this.loading = false)),
        'confirmation'
      )
      const containsPartialUpdate = res.filter(x => !['既存', '新規作成'].includes(x.confirmationType))
      if (containsPartialUpdate.length === 0) {
        this.confirmedUploads = res.map(x => x.id)
        await this.showUploadResult()
      } else {
        this.$refs.confirmationAssetPreviewDialog.show(this.uploadingInfo, res)
      }
    },
    async confirmUploadingAsset(value) {
      this.confirmedUploads = value
      if (value.length || this.isUrls) this.showUploadResult()
      else {
        this.$refs.confirmationAssetPreviewDialog.handleCancel()
        this.loading = false
      }
    },

    generateErrorResult(error) {
      const groupedUploadAssets = _.chain(this.uploadAssets)
        .groupBy('projectName')
        .map((gr, projectName) => {
          const assets = _.chain(gr).map('assets').flattenDeep().value()
          return { projectName, assets }
        })
        .filter(x => x.assets.length !== 0)
        .value()

      const bodies = _.map(groupedUploadAssets, r => {
        const form = new FormData()
        form.append(
          'payload',
          JSON.stringify({
            promotionId: Number(this.defaultPromotionId),
            projectName: r.projectName,
          })
        )
        _.forEach(r.assets, asset => form.append('file', asset.raw))

        return _.assign({}, r, { form })
      })

      const errorObj = _.map(bodies, (r, i) => {
        const reason = error
        const { projectName: groupName, assets } = groupedUploadAssets[i]
        return _.map(assets, asset => {
          return { isError: true, regulationErrors: [], reason, groupName, asset }
        })
      })

      const ret = _.flattenDeep(errorObj)
      return _.groupBy(ret, 'groupName')
    },

    generateDuplicationErrorResult(uid_lst) {
      const groupedUploadAssets = _.chain(this.uploadAssets)
        .groupBy('projectName')
        .map((gr, projectName) => {
          const assets = _.chain(gr).map('assets').flattenDeep().value()
          return { projectName, assets }
        })
        .filter(x => x.assets.length !== 0)
        .value()

      const bodies = _.map(groupedUploadAssets, r => {
        const form = new FormData()
        form.append(
          'payload',
          JSON.stringify({
            promotionId: Number(this.defaultPromotionId),
            projectName: r.projectName,
          })
        )
        _.forEach(r.assets, asset => form.append('file', asset.raw))

        return _.assign({}, r, { form })
      })

      const errorObj = _.map(bodies, (r, i) => {
        const reason =
          'アップしている各ファイルには同じファイル名・同じ内容を持っているものが存在していますので、再度アップしてください。'
        const { projectName: groupName, assets } = groupedUploadAssets[i]
        return _.map(assets, asset => {
          if (uid_lst.includes(asset.uid.toString())) {
            return { isError: true, regulationErrors: [], reason, groupName, asset }
          } else {
            return { isError: true, regulationErrors: [], reason: '', groupName, asset }
          }
        })
      })

      const ret = _.flattenDeep(errorObj)
      return _.groupBy(ret, 'groupName')
    },

    async upload() {
      let results = []

      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
      }

      const convertEstimateTime = time => {
        const date = new Date(null)
        date.setSeconds(time) // specify value for SECONDS here
        const result = date.toISOString().substr(11, 8)
        this.estimateTime = `残り` + result
      }
      const addExtendTime = async () => {
        convertEstimateTime(5)
      }

      if (this.confirmedUploads.length === 0) {
        this.loading = false
        ;(this.confirmationElements = []), (this.uploadingInfo = []), (this.confirmedUploads = [])
      } else {
        const grouped = _.chain(this.onlyAssets)
          .groupBy('projectName')
          .map((gr, projectName) => {
            const assets = _.chain(gr)
              .map('assets')
              .flattenDeep()
              .filter(x => this.confirmedUploads.includes(String(x.uid)))
              .value()
            return { projectName, assets }
          })
          .filter(x => x.assets.length !== 0)
          .value()

        const groupedUploadAssets = await Promise.all(
          _.map(grouped, async gr => {
            const extractedAssets = await Promise.all(
              _.map(gr.assets, async asset => {
                return this.extractExtension(asset.raw.name) === 'mp4'
                  ? await this.extractXmpMetadataTitle(asset)
                  : asset
              })
            )
            return _.assign({}, gr, { assets: extractedAssets })
          })
        )

        const bodies = _.map(groupedUploadAssets, r => {
          const form = new FormData()
          form.append(
            'payload',
            JSON.stringify({
              promotionId: Number(this.defaultPromotionId),
              projectName: r.projectName,
            })
          )
          _.forEach(r.assets, asset => form.append('file', asset.raw))

          return _.assign({}, r, { form })
        })

        const createTagSettings = r => {
          const tagSettings = _.chain(r.tags)
            .filter(r2 => !_.includes([this.oddAiTagId, this.soundTagId], r2.tagId))
            .map(r2 => ({ tagId: r2.tagId, targetIds: [r.originId] }))
            .value()
          if (r.noTags) {
            if (String(r.name).toLowerCase().startsWith('ugcl_'))
              tagSettings.push({ tagId: this.ugclTagId, targetIds: [r.originId] })
            return tagSettings
          }
          if (String(r.name).toLowerCase().startsWith('ugcl_'))
            tagSettings.push({ tagId: this.ugclTagId, targetIds: [r.originId] })
          if (r.oddAiTag.length !== 0) tagSettings.push({ tagId: this.oddAiTagId, targetIds: [r.originId] })
          if (r.soundTag.length !== 0) tagSettings.push({ tagId: this.soundTagId, targetIds: [r.originId] })
          return tagSettings
        }
        this.dialogVisible = true
        this.loading = true
        results = await Promise.all(
          _.map(bodies, async (r, i) => {
            let timeStarted = Date.now()
            const res = await axios
              .request({
                method: 'post',
                url: `${this.$api.ctxDomain}/asset/upload`,
                data: r.form,
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: async loadingEvent => {
                  const uploadPercentage = Math.floor((loadingEvent.loaded / loadingEvent.total) * 100)
                  if (uploadPercentage >= 95) {
                    this.percentage = 95
                  } else this.percentage = uploadPercentage

                  const timeElapsed = Date.now() - timeStarted
                  const uploadSpeed = loadingEvent.loaded / (timeElapsed / 1000)
                  const estimateTime = Math.floor((loadingEvent.total - loadingEvent.loaded) / uploadSpeed)
                  if (estimateTime === 0) {
                    await addExtendTime()
                  } else {
                    convertEstimateTime(estimateTime + 5)
                  }
                },
              })
              .then(async res => {
                convertEstimateTime(0)
                this.percentage = 100
                await sleep(1000)
                return res
              })
              .catch(error => {
                this.loading = false
                return error.response.data
              })
            this.dialogVisible = false
            this.loading = false
            if ((await res).status === 200) {
              const result = (await res).data
              const targets = _.map(
                result.filter(r => !r.errors || r.errors.length === 0),
                r2 => {
                  const data = this.mapAssetWithXMP(groupedUploadAssets).find(x => x.name === r2.fileName)
                  return _.assign({}, data, {
                    tags: r2.info.tags,
                    originId: r2.info.originId,
                    assetId: r2.info.assetId,
                  })
                }
              )

              await Promise.all(
                _.map(targets, async r => {
                  const body = {
                    selectedTargetIds: [r.originId],
                    targetType: 'asset',
                    tagSettings: createTagSettings(r),
                  }
                  await this.$api.authFetch('/tag/set', { method: 'PUT', body })
                })
              )
              await Promise.all(
                _.filter(targets, t => t.xmpMetadataTitle !== '' && t.xmpMetadataTitle !== undefined).map(async r => {
                  const body = {
                    assetId: r.assetId,
                    xmpMetadataTitle: r.xmpMetadataTitle,
                  }
                  await this.$api.authFetch('/asset/xmp_metadata_title', { method: 'PUT', body })
                })
              )

              const { projectName: groupName, assets } = groupedUploadAssets[i]
              return _.map(assets, asset => {
                asset.url = _.find(result, r => r.fileName === util.methods.lowerCaseFilename(asset.name)).info.url
                const {
                  type: reason = '',
                  info,
                  errors,
                } = _.find(result, r => r.fileName === util.methods.lowerCaseFilename(asset.name)) || {}
                return { isError: !info, regulationErrors: errors, reason, groupName, asset }
              })
            } else {
              const reason = await res
              const { projectName: groupName, assets } = groupedUploadAssets[i]
              return _.map(assets, asset => ({
                isError: true,
                regulationErrors: [],
                reason,
                groupName,
                asset,
              }))
            }
          })
        )
      }
      return results
    },
    mapAssetWithXMP(groupAsset) {
      return _.flatMap(groupAsset, assets => {
        return assets.assets
      })
    },
    async showUploadResult() {
      if (this.isAssets) this.assetResult = await this.upload()
      if (this.isUrls) this.urlResult = await this.uploadUrls()
      this.loading = false

      const ret = _.flattenDeep([...(this.assetResult || []), ...(this.urlResult || [])])
      this.groupByResultAssets = _.groupBy(ret, 'groupName')
      if (_.filter(ret, 'isError').length !== 0) {
        this.$refs.errorDialog.show(this.groupByResultAssets)
      } else this.$refs.uploadResultPreviewDialog.show(this.groupByResultAssets)
      this.cancel()
      this.estimateTime = ''
      this.percentage = 0
    },
    async getHash(algorithm, data) {
      const arrayBuffer = await data.arrayBuffer()
      const msgUint8 = new Uint8Array(arrayBuffer)
      const hashBuffer = await crypto.subtle.digest(algorithm, msgUint8)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
    },

    async uploadUrls() {
      if (this.isIncludesEmptyTags(this.uploadAssets)) {
        this.$alert('タグが正しく入力されていません', '必須チェック')
        this.checked = true
        return
      }

      const createTagSettings = r => {
        const tagSettings = _.chain(r.tags)
          .filter(r2 => !_.includes([this.oddAiTagId, this.soundTagId], r2.tagId))
          .map(r2 => ({ tagId: r2.tagId, targetIds: [r.originId] }))
          .value()
        if (r.noTags) return tagSettings

        if (r.oddAiTag.length !== 0) tagSettings.push({ tagId: this.oddAiTagId, targetIds: [r.originId] })
        if (r.soundTag.length !== 0) tagSettings.push({ tagId: this.soundTagId, targetIds: [r.originId] })
        return tagSettings
      }

      const groupedUploadYoutubeUrls = _.map(this.onlyUrls, r => {
        const urls = r.assets.map(x => {
          return _.assign({}, { url: x.url, name: x.rawUrl, youtubeId: this.getYoutubeId(x.name) })
        })
        return {
          projectName: r.projectName,
          urls: urls,
          promotionId: Number(this.defaultPromotionId),
        }
      })

      this.loading = true
      const youtubeRes = await Promise.all(
        groupedUploadYoutubeUrls.map(async (body, i) => {
          const result = await this.$api.authFetch('/asset/url_upload', {
            method: 'POST',
            body: body,
          })

          const result2 = await result
          const targets = _.map(result2, (r2, j) =>
            _.assign({}, this.onlyUrls[i].assets[j], {
              tags: r2.info.tags,
              originId: r2.info.originId,
            })
          )
          await Promise.all(
            _.map(targets, async r => {
              const body = {
                selectedTargetIds: [r.originId],
                targetType: 'asset',
                tagSettings: createTagSettings(r),
              }
              await this.$api.authFetch('/tag/set', { method: 'PUT', body })
            })
          )
          const { projectName: groupName, urls } = groupedUploadYoutubeUrls[i]
          return _.map(urls, asset => {
            const { type: reason = '', info } =
              _.find(result, r => this.getYoutubeId(r.fileName) === this.getYoutubeId(asset.name)) || {}
            return { isError: !info, regulationErrors: [], reason, groupName, asset }
          })
        })
      ).finally(result => {
        this.loading = false
        return result
      })
      this.loading = false
      this.youtubeDialogData = []
      return youtubeRes
    },

    buildBodyUrls(urls, index, textarea) {
      this.uploadAssets[index].assets = this.uploadAssets[index].assets.filter(asset => !asset.media)
      const urlDisplay = urls.map(url => {
        return _.assign({}, { name: url.name, url: url.url, media: 'Url', rawUrl: url.rawUrl })
      })
      this.uploadAssets[index].assets = this.uploadAssets[index].assets.concat(
        _.map(urlDisplay, file => {
          const emptyTag = { oddAiTag: [], soundTag: [], noTags: false }
          return _.assign({}, file, file.oddAiTag ? {} : emptyTag)
        })
      )

      this.uploadYoutubeUrls[index].urls = urls
      this.youtubeDialogData[index] = textarea
    },
    openYoutubeDialog(index) {
      this.$refs.youtubeUploadDialog.show(index, this.youtubeDialogData[index])
    },
    getYoutubeId(url) {
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = url.match(regExp)
      return match && match[7].length === 11 ? match[7] : false
    },
    async checkUploadingDuplication() {
      const body = await this.buildBodyConfirm()
      const duplicate_uid = this.getDuplicationAsset(body.confirmationElements)
      this.uploadAssets = this.uploadAssets.map(element => {
        const checkedAsset = element.assets.map(asset => {
          if (duplicate_uid.includes(asset.uid.toString())) {
            return _.assign({}, asset, { duplicate: true })
          } else {
            return _.assign({}, asset, { duplicate: false })
          }
        })
        return _.assign({}, { projectName: element.projectName }, { assets: checkedAsset })
      })
    },
  },
  watch: {
    allUploadingAssets: {
      handler(newAssets, oldAssets) {
        const oldAssetCount = oldAssets.length
        const newAssetCount = newAssets.length
        if (oldAssetCount !== newAssetCount) this.checkUploadingDuplication()
      },
      deep: true,
    },
  },
  computed: {
    onlyAssets() {
      return _.map(this.uploadAssets, r => {
        return {
          assets: r.assets.filter(asset => !asset.media),
          projectName: r.projectName,
        }
      })
    },
    onlyUrls() {
      return _.map(this.uploadAssets, r => {
        return {
          assets: r.assets.filter(asset => asset.media),
          projectName: r.projectName,
        }
      })
    },

    oddAiTagId() {
      return _.chain(this.tags)
        .find(r => r.tagValue === 'Odd-AI')
        .result('tagId')
        .value()
    },
    soundTagId() {
      return _.chain(this.tags)
        .find(r => r.tagValue === 'サウンド検証')
        .result('tagId')
        .value()
    },
    ugclTagId() {
      return _.chain(this.tags)
        .find(r => r.tagValue === 'UGCL')
        .result('tagId')
        .value()
    },
    isAssets() {
      const uploadAssets = _.map(this.uploadAssets, r => {
        return r.assets.filter(asset => !asset.media)
      })
      return !_.every(uploadAssets, r => r.length === 0)
    },
    isUrls() {
      return !_.every(this.uploadYoutubeUrls, r => r.urls.length === 0)
    },
    height() {
      return this.innerHeight - 35 - 16 - 16 - 70
    },
    allUploadingAssets() {
      return _.chain(this.uploadAssets)
        .map(x => x.assets)
        .flatten()
        .value()
    },
  },
}
</script>
