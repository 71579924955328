<template>
  <div id="snap-dialog">
    <el-dialog
      title="SNAP出力"
      custom-class="small-dialog"
      width="30%"
      lock-scroll
      append-to-body
      :visible.sync="isShow"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div v-loading="loading">
        <el-row type="flex" justify="center" class="pt-1">
          <i class="fas fa-exclamation-triangle font-big icon-red" />
        </el-row>

        <el-row class="p-1">
          <el-col>入稿ステータスが<strong>入稿済</strong>になります。</el-col>
          <el-col>クリップボードにクリエイティブ情報がコピーされます。</el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="outputSnap"> SNAP出力 </el-button>
        <el-button @click="isShow = false"> キャンセル </el-button>
      </span>
    </el-dialog>

    <copy-error-dialog ref="copyErrorDialog" />
  </div>
</template>

<style scoped>
.p-1 {
  padding: 1rem;
}

.pt-1 {
  padding-top: 1rem;
}

.font-big {
  font-size: 60px;
}

.icon-red {
  color: #f56c6c;
}
</style>

<script>
import _ from 'lodash'
import CopyErrorDialog from '@/views/creative-list/snap-dialog/components/copy-error-dialog'

export default {
  name: 'snap-dialog',
  props: {
    selectedPromotionIds: { type: Array, default: () => [] },
    selected: { type: Array, default: () => [] },
  },
  components: { CopyErrorDialog },
  data: () => ({ isShow: false, loading: false }),
  created() {
    window.SnapDialog = this
  },
  methods: {
    show() {
      this.isShow = true
    },
    async outputSnap() {
      const body = {
        promotionIds: this.selectedPromotionIds,
        creativeIds: _.map(this.selected, 'creativeId'),
      }
      this.loading = true
      const ret = await this.$api.authFetch('/creative/export', { method: 'POST', body })
      this.loading = false

      if (!ret) {
        this.isShow = false
        return
      }

      try {
        await navigator.clipboard.writeText(ret)
        this.$emit('reload')
        this.$message.success('コピーしました')
      } catch {
        this.$refs.copyErrorDialog.show(ret)
      }
      this.isShow = false
    },
  },
}
</script>
