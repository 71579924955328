<template>
  <el-dialog
    id="note-ialog"
    custom-class="small-dialog"
    title="備考編集"
    width="80%"
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="p-3" v-loading="loading">
      <div class="pb-1">一括編集したい備考を入力してください</div>

      <el-input type="textarea" :rows="7" placeholder="備考" v-model="note" />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="edit"> 保存 </el-button>
      <el-button @click="isShow = false"> キャンセル </el-button>
    </span>
  </el-dialog>
</template>

<style scoped>
.pb-1 {
  padding-bottom: 1rem;
}
.p-3 {
  padding: 3rem;
}
.text-center {
  text-align: center;
}
</style>

<script>
export default {
  name: 'note-dialog',
  props: {
    selected: { type: Array, default: () => [] },
  },
  data: () => ({ isShow: false, loading: false, note: '' }),
  created() {
    window.NoteDialog = this
  },
  methods: {
    show() {
      this.note = ''
      this.isShow = true
    },
    edit() {
      this.$emit('edit-note', this.note)
      this.isShow = false
    },
  },
}
</script>
