var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"id":"upload-result-preview-dialog","title":"アップロード結果","lock-scroll":"","append-to-body":"","fullscreen":"","visible":_vm.isShow,"close-on-press-escape":false,"close-on-click-modal":false},on:{"update:visible":function($event){_vm.isShow=$event}}},[(_vm.groupByResultAssets)?_c('div',{staticClass:"scroll",style:({ height: _vm.height + 'px' })},_vm._l((Object.entries(_vm.groupByResultAssets)),function([groupName = '記入なし', gr]){return _c('div',{key:groupName},[_c('div',{staticClass:"mb-1"},[_vm._v(_vm._s(groupName))]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"border":"","data":gr}},[_c('el-table-column',{attrs:{"label":"ファイル名","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function({
              row: {
                asset: { name },
              },
            }){return [_c('span',{staticClass:"font-mini"},[_vm._v(_vm._s(name))])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"静止画/動画","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function({
              row: {
                asset: { url, name },
              },
            }){return [_c('image-movie-item',{attrs:{"url":url,"thumbnail-url":url,"type":_vm.judgeFileType(name.substr(name.lastIndexOf('.') + 1).toLowerCase() || 'Youtube')}})]}}],null,true)}),_c('el-table-column',{attrs:{"label":"アップロード結果","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function({ row: { isError, regulationErrors } }){return [_c('span',{class:{
                success: !isError && (!regulationErrors || regulationErrors.length === 0),
                error: isError || (regulationErrors && regulationErrors.length > 0),
              }},[_vm._v(" "+_vm._s(isError || (regulationErrors && regulationErrors.length > 0) ? 'エラー' : '成功')+" ")])]}}],null,true)}),_c('el-table-column',{attrs:{"label":"処理結果"},scopedSlots:_vm._u([{key:"default",fn:function({ row: { reason, regulationErrors } }){return [(regulationErrors && regulationErrors.length > 0)?_c('div',{staticClass:"reason"},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(" アップロードしようとしている画像/動画はすでに登録されているクリエイティブの規定に違反しています。 規定を確認し、 修正した上で再度お試しください。 ")]),_c('ul',_vm._l((_vm.showCheckRuleError(regulationErrors)),function(regulationError,index){return _c('li',{key:`regulation-error-${index}`,staticStyle:{"display":"flex","flex-direction":"column"}},[_c('span',[_vm._v(" "+_vm._s(regulationError.name.trim())+":")]),_c('ul',{staticStyle:{"list-style":"disc","margin-left":"3%"}},_vm._l((regulationError.errors),function(error,idx){return _c('li',{key:`error-${index}-${idx}`,staticStyle:{"color":"rgb(255, 56, 56)"}},[_vm._v(" "+_vm._s(error)+" ")])}),0)])}),0)]):_c('span',[_vm._v(_vm._s(reason))])]}}],null,true)})],1)],1)}),0):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.isShow = false}}},[_vm._v(" 閉じる ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }