// approval status for asset and textSet
export class AssetTextStatus {
  static APPROVED = '承認'
  static NOT_APPROVED = '非承認'
  static WAITING_FOR_APPROVAL = '監修未提出'
  static CUSTOMER_CHECKING = '監修中'
  static CHECKING = '承認待ち'
  static NG = '破棄'
  static FIXING = '要修正'
  static SUBMITTED = '入稿済'
  static SEND_REQUEST_SUBMIT = '入稿依頼済'

  static NO_SELECT_STATUSES = [AssetTextStatus.SUBMITTED, AssetTextStatus.WAITING_FOR_APPROVAL]

  static CANNOT_SELECTED_STATUSES_IN_CRS_SCREEN = [AssetTextStatus.SUBMITTED]
}
