<template>
  <el-dialog
    custom-class="small-dialog"
    id="status-dialog"
    title="入稿ステータス変更"
    width="25%"
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="pt-3 pb-1 text-center" v-loading="loading">
      <div class="pb-1">変更するステータスを選んでください</div>

      <el-select v-model="statusValue">
        <el-option v-for="(status, i) in statusOptions" :key="i" :label="status" :value="status" />
      </el-select>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" :disabled="canNotSubmitStatus" @click="changeStatus"> 保存 </el-button>

      <el-button @click="isShow = false"> キャンセル </el-button>
    </span>
  </el-dialog>
</template>

<style scoped>
.pb-1 {
  padding-bottom: 1rem;
}

.pt-3 {
  padding-top: 3rem;
}

.text-center {
  text-align: center;
}
</style>

<script>
import util from '@/mixins/util'

export default {
  name: 'status-dialog',
  mixins: [util],
  props: {
    targetType: { type: String, default: () => '' },
  },
  data: () => ({
    isShow: false,
    loading: false,
    statusValue: '',
    statusOptions: [],
  }),
  created() {
    window.StatusDialog = this
    switch (this.targetType) {
      case 'creative':
        this.statusOptions = [...this.approvalStatusCreativeOptions].filter(
          x => x !== '入稿依頼済' && x !== '入稿済' && x !== '監修未提出'
        )
        break
      default:
        this.statusOptions = [...this.approvalStatusOptions].filter(x => x !== '入稿済')
    }
  },
  methods: {
    show() {
      this.statusValue = ''
      this.isShow = true
    },
    async changeStatus() {
      this.$emit('change-selected-creatives-status', this.statusValue)
      this.isShow = false
    },
  },
  computed: {
    canNotSubmitStatus() {
      return this.statusValue === ''
    },
  },
}
</script>
