<template>
  <el-dialog
    id="preview-dialog"
    :title="`プレビュー - ${this.platformId} ${this.adFormatName}`"
    fullscreen
    lock-scroll
    append-to-body
    :visible.sync="isShow"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="scroll p-1" :style="{ height: height + 'px' }">
      <el-row v-if="error && error.errors.length !== 0">
        <el-row v-for="(error, i) in error.errors" :key="`error_${i}`" class="detail">
          {{ error }}
        </el-row>
      </el-row>

      <el-row class="w-100" v-for="(r, i) in labeledAssets" :key="i">
        <el-row v-if="adjustmentAssetErrors[i] && adjustmentAssetErrors[i].errors.length !== 0">
          <el-row v-for="(error, j) in adjustmentAssetErrors[i].errors" :key="`error_${i}_${j}`" class="detail">
            {{ error }}
          </el-row>
        </el-row>

        <el-divider content-position="left">
          {{ r.labelName }}
        </el-divider>

        <el-row :gutter="15">
          <el-col :span="4" class="mb-2" v-for="(asset, j) in r.assets" :key="`asset_${i}_${j}`">
            <asset-item
              :asset="asset"
              :idOpenError="idOpenError"
              @change-id-open-error="assetId => changeIdOpenError(assetId)"
            />

            <el-row
              v-if="
                adjustmentAssetErrors[i] &&
                adjustmentAssetErrors[i].details.length !== 0 &&
                adjustmentAssetErrors[i].details[j] &&
                adjustmentAssetErrors[i].details[j].length !== 0
              "
              class="mt-1"
            >
              <el-row
                class="detail"
                v-for="(detail, k) in adjustmentAssetErrors[i].details[j]"
                :key="`detail_${i}_${j}_${k}`"
              >
                {{ detail }}
              </el-row>
            </el-row>
          </el-col>
        </el-row>
      </el-row>

      <el-divider content-position="left"> テキスト </el-divider>

      <el-row class="w-100">
        <el-row v-if="labeledTexts.length === 0" class="text-center"> テキストなし </el-row>

        <el-row
          v-else
          type="flex"
          v-for="(chunkedLabeledText, i) in require('lodash').chunk(labeledTexts, 3)"
          :key="`chunkedLabeledText_${i}`"
          class="mb-2"
        >
          <el-col v-for="(labeledText, j) in chunkedLabeledText" :key="`labeledText_${i}_${j}`" :span="8" class="mr-2">
            <el-row>
              {{ labeledText.labelName }}
            </el-row>
            <ol>
              <li class="new-line" v-for="(v, k) in labeledText.textValues" :key="`labeledText_${i}_${j}_${k}`">
                {{ v }}
              </li>
              <!-- 上記は改行するとスペースが入ってしまう為、このまま -->
            </ol>
          </el-col>
        </el-row>
      </el-row>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="isShow = false" type="primary"> 閉じる </el-button>
    </span>
  </el-dialog>
</template>

<style>
#preview-dialog .el-card__body {
  padding: 0px;
}
</style>

<style scoped>
.border-red {
  border: solid red;
  border-radius: 10px;
}
.p-1 {
  padding: 1rem;
}
.font-big {
  font-size: 60px;
}
.icon-red {
  color: #f56c6c;
}
.scroll {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE, Edge 対応 */
  scrollbar-width: none; /* Firefox 対応 */
}
.scroll::-webkit-scrollbar {
  display: none;
} /* Chrome, Safari 対応 */
.w-100 {
  width: 100%;
}
.font-mini {
  font-size: 10px;
}
.mt-1 {
  margin-top: 0.5rem;
}
.mr-2 {
  margin-right: 1rem;
}
.mb-2 {
  margin-bottom: 1rem;
}
.float-left {
  float: left;
}
.new-line {
  white-space: pre-wrap !important;
}
.text-center {
  text-align: center;
}
.detail {
  font-size: 10px;
  color: #f56c6c;
}
</style>

<script>
import _ from 'lodash'
import AssetItem from '@/views/image-movie-master/pixel-size-tab/asset-item'

export default {
  name: 'preview-dialog',
  components: { AssetItem },
  props: {
    platformId: { type: String, default: () => '' },
    adFormatName: { type: String, default: () => '' },
  },
  data: () => ({
    isShow: false,
    labeledAssets: [],
    labeledTexts: [],
    error: null,
    height: window.innerHeight - 35 - 16 - 16 - 70,
    idOpenError: '',
  }),
  created() {
    window.PreviewDialog = this
    window.addEventListener('resize', () => {
      this.height = window.innerHeight - 35 - 16 - 16 - 70
    })
  },
  methods: {
    changeIdOpenError(assetId) {
      this.idOpenError = assetId
    },
    show(labeledAssets, labeledTexts, error) {
      this.labeledAssets = labeledAssets
      this.labeledTexts = labeledTexts
      this.error = error
      this.isShow = true
    },
  },
  computed: {
    adjustmentAssetErrors() {
      if (this.labeledAssets.length === 0 || !this.error) return []

      return _.map(this.labeledAssets, r => {
        const assetError = _.find(this.error.labels, r2 => r2.labelId === r.labelId)
        if (!assetError) return { errors: [], details: [] }

        const details = _.map(r.assets, r2 =>
          _.chain(assetError.targets)
            .find(r3 => r3.key.split('_')[0] === r2.originId)
            .result('errors')
            .value()
        )
        return { errors: this.error.errors, details }
      })
    },
    chunkedAsset() {
      return _.chain(this.labeledAssets)
        .map(r => _.chain(r.assets).cloneDeep().chunk(6).value())
        .value()
    },
  },
}
</script>
