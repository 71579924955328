export default {
  async install(Vue, googleAuth) {
    Vue.$gAuth = Vue.prototype.$gAuth = {
      // legacy for checking Google token (depricated)
      decode(token) {
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
        )
        return JSON.parse(jsonPayload)
      },
      isIdTokenExpired(token = window.localStorage.getItem('idToken')) {
        if (token) {
          const userInfo = this.decode(token)
          return userInfo.exp - Math.round(new Date().getTime() / 1000) <= 900
        } else throw new Error('Invalid token')
      },
      async refreshIdToken() {
        const noti = await new Promise((res, rej) => {
          window.google.accounts.id.prompt(noti => {
            const checker = setInterval(() => {
              if (
                (noti.isNotDisplayed() && noti.getNotDisplayedReason() === 'suppressed_by_user') ||
                noti.isSkippedMoment()
              ) {
                rej('onetap_suppressed')
              }
              if (noti.getMomentType() !== 'display') {
                res('Token refreshed')
                clearInterval(checker)
              }
            }, 100)
          })
          console.log(noti)
        })
      },
      async getUserInfo() {
        if (window.localStorage.getItem('accessToken')) {
          let user = await Vue.$api.authFetch('/token/user', {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
            },
          })
          return user
        } else return null
      },
      async getToken(body) {
        const resGetToken = await Vue.$api.authFetch('/token/init', { method: 'POST', body })
        if (resGetToken) {
          window.localStorage.setItem('accessToken', resGetToken.token)
        } else {
          window.localStorage.setItem('getToken', 'getToken')
          this.signOut()
        }
      },
      async refreshToken() {
        const resRefreshToken = await Vue.$api.authFetch('/token/refresh', {
          method: 'GET',
          headers: { Authorization: `Bearer ${window.localStorage.getItem('accessToken')}` },
        })
        if (resRefreshToken) {
          window.localStorage.setItem('accessToken', resRefreshToken.token)
        } else {
          const resRefreshTokenAgain = await Vue.$api.authFetch('/token/refresh', {
            method: 'GET',
            headers: { Authorization: `Bearer ${window.localStorage.getItem('accessToken')}` },
          })
          if (resRefreshTokenAgain) {
            window.localStorage.setItem('accessToken', resRefreshTokenAgain.token)
          } else {
            this.signOut()
          }
        }
        return true
      },
      async isSub() {
        const sessionEmail = window.sessionStorage.getItem('email')
        return await Vue.$api.authFetch('/verify_email', {
          method: 'POST',
          body: { email: sessionEmail ? sessionEmail : await this.getEmail() },
        })
      },
      async getIdToken() {
        return window.localStorage.getItem('idToken')
      },
      async getEmail() {
        const profile = await this.getUserInfo()
        return profile ? profile.email : ''
      },
      async getName() {
        const profile = await this.getUserInfo()
        return profile ? profile.name : ''
      },
      async isSignedIn(query = {}) {
        if (window.localStorage.getItem('accessToken')) {
          return await this.checkUser(query)
        } else return false
      },
      async getEmployees() {
        const employees = Vue.$api.authFetch('/employees', {
          method: 'GET',
          headers: { Authorization: 'auth' },
        })
        const subEmployeeCheck = await this.isSub()
        const subEmployee =
          subEmployeeCheck && subEmployeeCheck.isExisted && subEmployeeCheck.isSub && subEmployeeCheck.isSub === true
            ? [subEmployeeCheck.employee]
            : []
        return employees.then(ret => ret.concat(subEmployee))
      },
      async getEmployeeByMail() {
        const employeeCheck = await this.isSub()
        if (employeeCheck && employeeCheck.isExisted) return employeeCheck.employee
        else null
      },
      async checkUser() {
        if (window.localStorage.getItem('accessToken')) {
          const user = await this.getEmployeeByMail()
          if (user) {
            await this.refreshToken()
            return true
          }
          this.signOut()
          return false
        } else {
          this.signOut()
          return false
        }
      },
      async signIn(isSelect) {
        // decided prompt type
        let prompt = isSelect ? 'select_account' : ''

        /**
         *  get user's data from google by token after prompt ->  create token and check user
         * -> decided if user if valid -> return message by resolve or reject it
         */
        let isSignedIn = await new Promise((resolve, reject) => {
          googleAuth.callback = async resp => {
            fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${resp.access_token}`).then(response =>
              response
                .json()
                .then(async res => {
                  if (res.error === undefined) {
                    window.sessionStorage.setItem('email', res.email)
                    let username = res.name
                      ? res.name
                      : await this.getEmployeeByMail().then(user => (user ? user.name : undefined))
                    window.sessionStorage.setItem('username', username)
                    await this.getToken({ email: res.email, name: username })
                    const isValidUser = await this.checkUser()
                    if (isValidUser) {
                      window.sessionStorage.clear()
                      resolve('Login successfully!')
                    } else reject('定義されたユーザーではありません')
                  } else reject('Failed to authenticate google user')
                })
                .catch(e => {
                  console.error('CANNOT LOGIN BECAUSE: ', e)
                  reject('Login failed')
                })
            )
            if (resp.error !== undefined) {
              console.error(resp)
              reject('An error has occurred while fetching token!')
            }
          }
          googleAuth.error_callback = errorRes => {
            console.error(errorRes)
            reject('Login cancelled!')
          }
          googleAuth.requestAccessToken({ prompt })
        })
        window.sessionStorage.clear()
        return isSignedIn
      },
      signOut: () => {
        window.sessionStorage.clear()
        window.localStorage.removeItem('idToken')
        window.localStorage.removeItem('accessToken')
        window.sessionStorage.clear()
      },
    }
  },
}
