<template>
  <el-card shadow="never" class="w-100 font-mini">
    <div class="float-left w-100">
      <el-col class="p-1" :span="18">
        <el-row
          type="flex"
          v-for="(chunkedLabeledText, i) in require('lodash').chunk(revision.labeledTexts, 3)"
          :key="`chunkedLabeledText_${i}`"
          class="mb-2"
        >
          <el-col
            v-for="(labeledText, j) in chunkedLabeledText"
            :key="`labeledText_${i}_${j}`"
            :span="8"
            class="mr-2 p-2"
            :class="{ 'border-red': labeledText.isLabelDeleted }"
          >
            <el-row>
              <span class="mr-1">
                {{ labeledText.labelName }}
              </span>

              <el-tag size="mini" type="danger" v-if="labeledText.isLabelDeleted"> 媒体入稿不可 </el-tag>
            </el-row>
            <ol>
              <li class="new-line" v-for="(v, k) in labeledText.textValues" :key="`labeledText_${i}_${j}_${k}`">
                {{ v }}
              </li>
              <!-- 上記は改行するとスペースが入ってしまう為、このまま -->
            </ol>
          </el-col>
        </el-row>
      </el-col>

      <el-col class="p-1" :span="6">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-tag type="info" class="w-100 text-center font-bold"> ver.{{ revision.version }} </el-tag>
          </el-col>

          <el-col :span="12">
            <el-tag
              :type="revision.approvalStatus === '監修未提出' ? 'danger' : 'success'"
              class="w-100 text-center font-bold"
            >
              {{ revision.approvalStatus }}
            </el-tag>
          </el-col>
        </el-row>
      </el-col>
    </div>
  </el-card>
</template>

<style scoped>
.border-red {
  border: solid red;
  border-radius: 10px;
}
.w-100 {
  width: 100%;
}
.text-center {
  text-align: center;
}
.font-bold {
  font-weight: bold;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 5px;
}
.float-left {
  float: left;
}
.font-mini {
  font-size: 10px;
}
</style>

<script>
export default {
  name: 'version-card',
  props: { revision: { type: Object, default: () => ({}) } },
  created() {
    window.VersionCard = this
  },
}
</script>
